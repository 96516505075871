import { useContext } from 'react'
import {
  Box,
  Button,
  SimpleGrid,
  HStack,
  VStack,
  StackDivider,
  Flex,
  Text,
  MenuItem,
  MenuDivider,
  Link,
  useDisclosure,
  Tooltip,
} from '@chakra-ui/react'
import { NavLink as RouterLink } from 'react-router-dom'
import { Material as MaterialType } from '../../types'
import { format } from 'date-fns'
import { CommentList } from './../Post'
import { dateFormat } from '../../constants'
import { HoverMenu, parentHoverCss } from '../Menu'
import { RiPencilFill } from 'react-icons/ri'
import { FaTrash } from 'react-icons/fa'
import { MaterialModal } from '../Modal'
import ClassWorkContext from '../../pages/ClassWork/ClassWorkContext'
import { ConfirmationModal } from '../../components/Modal'
import { EDIT_MATERIAL } from '../../client/queries'
import { useMutation } from '@apollo/client'
import {
  useSuccessToast,
  useErrorToast,
  dateInPast,
  isLargerThanTablet,
  utcToLocale,
} from '../../utils'
import { useAlamContext } from '../../client'
import { RenderForUser } from '../SharedComponents'
import { getFullName } from '../../utils'
import { FileList } from '../../components/File'
import { Group, Material as MaterialIcon, Member } from '../Icons'

export interface MaterialdItemType {
  material: MaterialType
  context?: string
  materialAssignmentRefetch: () => void
  groupId?: string
}

export const Material = ({
  context = 'classwork',
  material = {
    id: '',
    title: '',
    description: '',
    schedule: '',
    learningStyle: ['visual', 'aural', 'read', 'kinesthetic'],
    classId: '',
    topicId: '',
    isShown: true,
    inserted_at: '',
    comments: [],
    materialUsers: [],
    materialGroups: [],
    user: {
      firstName: '',
      lastName: '',
    },
    attachments: [],
  },
  materialAssignmentRefetch,
  groupId,
}: MaterialdItemType): JSX.Element => {
  const { classUser } = useAlamContext()
  const { topics, classUsers, getClassTopics } = useContext(ClassWorkContext)
  const materialUsersCount = material.materialUsers.length
  const materialGroupsCount = material.materialGroups.length

  const dateSchedule = new Date(material.schedule)
  const today = new Date()
  const pastSchedule = dateInPast(dateSchedule, today)

  const {
    isOpen: materialIsOpen,
    onClose: materialOnClose,
    onOpen: materialOnOpen,
  } = useDisclosure()

  const {
    isOpen: deleteMaterialIsOpen,
    onClose: deleteMaterialOnClose,
    onOpen: deleteMaterialOnOpen,
  } = useDisclosure()

  const {
    isOpen: expandedIsOpen,
    onClose: expandedOnClose,
    onOpen: expandedOnOpen,
  } = useDisclosure()

  const [archiveMaterial] = useMutation(EDIT_MATERIAL, {
    variables: {
      id: material.id,
      changes: {
        archived_at: 'NOW()',
      },
    },
  })

  const handleDeleteMaterial = () => {
    try {
      archiveMaterial().then(() => {
        getClassTopics()
        deleteMaterialOnClose()
        materialAssignmentRefetch()
        useSuccessToast({ message: 'Material deleted' })
      })
    } catch (err) {
      useErrorToast({ message: 'Deleting material failed' })
    }
  }

  const viewUrl = groupId
    ? `/classes/${classUser?.class?.id}/groups/${groupId}/materials/${material.id}`
    : `/classes/${classUser?.class?.id}/materials/${material.id}`

  return (
    <>
      <ConfirmationModal
        isOpen={deleteMaterialIsOpen}
        onClose={deleteMaterialOnClose}
        title={`Are you sure you want to delete ${material.title}?`}
        message={`All comments and data will also be deleted`}
        onSubmit={handleDeleteMaterial}
      />
      <MaterialModal
        isOpen={materialIsOpen}
        topics={topics}
        students={classUsers}
        material={material}
        materialAssignmentRefetch={materialAssignmentRefetch}
        onClose={materialOnClose}
      />
      {expandedIsOpen ? (
        <VStack
          bg='white.1'
          layerStyle='card.board-updated-shadow'
          w='100%'
          divider={<StackDivider layerStyle='divider' />}
          lineHeight='24px'
          letterSpacing='-0.01rem'
        >
          <Box
            bg='white.1'
            layerStyle='card.expanded'
            w='100%'
            px={4}
            pt={4}
            pb={2}
            css={parentHoverCss}
          >
            <RenderForUser
              roles={['admin', 'teacher', 'creator']}
              context={context}
            >
              <HoverMenu top={4} right={2}>
                <>
                  <MenuItem icon={<RiPencilFill />} onClick={materialOnOpen}>
                    Edit
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem icon={<FaTrash />} onClick={deleteMaterialOnOpen}>
                    Delete
                  </MenuItem>
                </>
              </HoverMenu>
            </RenderForUser>
            <HStack spacing={4} h='100%' w='100%'>
              {isLargerThanTablet() && (
                <MaterialIcon width='20' height='20' viewBox='8 8 24 24' />
              )}

              <Flex direction='column'>
                <Tooltip
                  label={material.title}
                  aria-label={material.title}
                  placement='top-start'
                >
                  <Text
                    isTruncated
                    w={{ base: '24ch', lg: '48ch' }}
                    textStyle='h5'
                    onClick={expandedIsOpen ? expandedOnClose : expandedOnOpen}
                    cursor='pointer'
                    lineHeight='24px'
                  >
                    {material.title}
                  </Text>
                </Tooltip>
                <HStack
                  spacing={3}
                  divider={<Box layerStyle='bulletpoint' />}
                  flexDirection={{ base: 'column', md: 'row' }}
                  alignItems={{ base: 'flex-start', md: 'center' }}
                >
                  <HStack
                    spacing={3}
                    divider={<Box layerStyle='bulletpoint' />}
                  >
                    {materialUsersCount === 0 &&
                      materialGroupsCount === 0 &&
                      classUser?.role !== 'student' && (
                        <Text textStyle='body.1' color='grey.1'>
                          Not Assigned
                        </Text>
                      )}

                    {(materialUsersCount >= 1 || materialGroupsCount >= 1) &&
                      classUser?.role !== 'student' && (
                        <SimpleGrid columns={2} gap={2} color='black.1'>
                          <Flex align='center'>
                            <Text fontSize='14px' lineHeight='16px' mr={1}>
                              {materialUsersCount}
                            </Text>
                            <Member
                              width='16'
                              height='16'
                              viewBox='8 8 24 24'
                              fill='#010002'
                            />
                          </Flex>
                          <Flex align='center'>
                            <Text fontSize='14px' lineHeight='16px' mr={1}>
                              {materialGroupsCount}
                            </Text>
                            <Group
                              width='16'
                              height='16'
                              viewBox='8 8 24 24'
                              fill='#010002'
                            />
                          </Flex>
                        </SimpleGrid>
                      )}

                    {classUser?.role === 'student' && (
                      <Text
                        textStyle='body.1'
                        color='grey.1'
                        noOfLines={1}
                        isTruncated
                      >
                        {getFullName(material?.user)}
                      </Text>
                    )}
                  </HStack>

                  <HStack
                    spacing={3}
                    divider={<Box layerStyle='bulletpoint' />}
                  >
                    {material.comments?.length === 0 ? (
                      <Text textStyle='body.1' color='grey.1'>
                        No Comments
                      </Text>
                    ) : (
                      <Text textStyle='body.1' color='black.1'>
                        {material.comments?.length > 1
                          ? `${material.comments.length} comments`
                          : `${material.comments.length} comment`}
                      </Text>
                    )}

                    {!pastSchedule && classUser?.role !== 'student' && (
                      <Text textStyle='body.1' color='black.1'>
                        Scheduled{' '}
                        {format(utcToLocale(material?.schedule), 'MMM d, y')}
                      </Text>
                    )}
                  </HStack>

                  {/* <Text textStyle='body.1' color='grey.1'>
                  {format(new Date(material.inserted_at), dateFormat)}
                </Text> */}
                </HStack>
              </Flex>
            </HStack>
          </Box>

          {material.description && (
            <Box w='100%' px={6} py={2} textStyle='body.1'>
              {material.description}
            </Box>
          )}

          {material.attachments.length >= 1 && (
            <FileList existingFiles={material.attachments} isReadOnly />
          )}

          <Box w='100%'>
            {material.comments?.length >= 1 ? (
              <CommentList comments={material.comments} />
            ) : (
              <Text textStyle='body.1' color='grey.1' px={4} py={2}>
                0 comment
              </Text>
            )}
          </Box>

          <Box w='100%' px={4} pb={4} pt={2}>
            <Flex w='100%' direction='row' justify='flex-end'>
              <Link to={viewUrl} as={RouterLink}>
                <Button variant='base.primary' textStyle='button' minW='72px'>
                  View Material
                </Button>
              </Link>
            </Flex>
          </Box>
        </VStack>
      ) : (
        <Box
          layerStyle='card.board-updated-shadow'
          bg='white.1'
          w='100%'
          h={{ base: 'auto', md: '72px' }}
          p={4}
          css={parentHoverCss}
          lineHeight='24px'
          letterSpacing='0.02em'
        >
          <RenderForUser
            roles={['admin', 'teacher', 'creator']}
            context={context}
          >
            <HoverMenu top={4} right={2}>
              <>
                <MenuItem icon={<RiPencilFill />} onClick={materialOnOpen}>
                  Edit
                </MenuItem>
                <MenuDivider />
                <MenuItem icon={<FaTrash />} onClick={deleteMaterialOnOpen}>
                  Delete
                </MenuItem>
              </>
            </HoverMenu>
          </RenderForUser>
          <HStack spacing={4} h='100%' w='100%'>
            {isLargerThanTablet() && (
              <MaterialIcon width='20' height='20' viewBox='8 8 24 24' />
            )}

            <Flex direction='column'>
              <Tooltip
                label={material.title}
                aria-label={material.title}
                placement='top-start'
              >
                <Text
                  isTruncated
                  w={{ base: '24ch', lg: '48ch' }}
                  textStyle='h5'
                  onClick={expandedOnOpen}
                  cursor='pointer'
                  lineHeight='24px'
                >
                  {material.title}
                </Text>
              </Tooltip>
              <HStack
                spacing={3}
                divider={<Box layerStyle='bulletpoint' />}
                flexDirection={{ base: 'column', md: 'row' }}
                alignItems={{ base: 'flex-start', md: 'center' }}
              >
                <HStack spacing={3} divider={<Box layerStyle='bulletpoint' />}>
                  {materialUsersCount === 0 &&
                    materialGroupsCount === 0 &&
                    classUser?.role !== 'student' && (
                      <Text textStyle='body.1' color='grey.1'>
                        Not Assigned
                      </Text>
                    )}

                  {(materialUsersCount >= 1 || materialGroupsCount >= 1) &&
                    classUser?.role !== 'student' && (
                      <SimpleGrid columns={2} gap={2} color='black.1'>
                        <Flex align='center'>
                          <Text fontSize='14px' lineHeight='16px' mr={1}>
                            {materialUsersCount}
                          </Text>
                          <Member
                            width='16'
                            height='16'
                            viewBox='8 8 24 24'
                            fill='#010002'
                          />
                        </Flex>
                        <Flex align='center'>
                          <Text fontSize='14px' lineHeight='16px' mr={1}>
                            {materialGroupsCount}
                          </Text>
                          <Group
                            width='16'
                            height='16'
                            viewBox='8 8 24 24'
                            fill='#010002'
                          />
                        </Flex>
                      </SimpleGrid>
                    )}

                  {classUser?.role === 'student' && (
                    <Text
                      textStyle='body.1'
                      color='grey.1'
                      noOfLines={1}
                      isTruncated
                    >
                      {getFullName(material?.user)}
                    </Text>
                  )}
                </HStack>

                <HStack spacing={3} divider={<Box layerStyle='bulletpoint' />}>
                  {material.comments?.length === 0 ? (
                    <Text textStyle='body.1' color='grey.1'>
                      No Comments
                    </Text>
                  ) : (
                    <Text textStyle='body.1' color='black.1'>
                      {material.comments?.length > 1
                        ? `${material.comments.length} comments`
                        : `${material.comments.length} comment`}
                    </Text>
                  )}

                  {!pastSchedule && classUser?.role !== 'student' && (
                    <Text textStyle='body.1' color='black.1'>
                      Scheduled{' '}
                      {format(utcToLocale(material?.schedule), 'MMM d, y')}
                    </Text>
                  )}
                </HStack>

                {/* <Text textStyle='body.1' color='grey.1'>
                  {format(new Date(material.inserted_at), dateFormat)}
                </Text> */}
              </HStack>
            </Flex>
          </HStack>
        </Box>
      )}
    </>
  )
}

export default Material
