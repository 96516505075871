import { useState } from 'react'
import { Flex, Box, Text } from '@chakra-ui/react'
import { DropdownCheckbox } from '../Input'
import { SideNavList } from './index'
import { Submission } from '../../types'

export type SubmissionSideNavProps = {
  turnedInSubmissions: Submission[]
  missingSubmissions: Submission[]
  submissionsFor: 'students' | 'groups'
  containerDisplay?: string
}

export const SubmissionSideNav = ({
  turnedInSubmissions,
  missingSubmissions,
  submissionsFor,
  containerDisplay = 'flex',
}: SubmissionSideNavProps): JSX.Element => {
  const [filteredStatus, setFilteredStatus] = useState<string[]>([
    'turnedIn',
    'missing',
  ])
  const filterOptions = [
    { display: 'Turned In', value: 'turnedIn' },
    { display: 'Missing', value: 'missing' },
  ]

  return (
    <Flex
      direction='column'
      overflow='hidden'
      h='assignmentContainerHeight'
      maxW={{ base: '100%', sm: '248px' }}
      minW={{ base: '100%', sm: '248px' }}
      pos='absolute'
      right='0'
      top={{ base: '144px', sm: '128px' }}
      display={containerDisplay}
    >
      <Flex h='100%' w='100%' pos='sticky' top='0' direction='column'>
        <Flex
          h='64px'
          px='16px'
          justify='space-between'
          align='center'
          w='100%'
          py='12px'
          bg='white.1'
        >
          <Text textStyle='h5' textTransform='capitalize' mr={2}>
            {submissionsFor}
          </Text>

          <DropdownCheckbox
            onChange={setFilteredStatus}
            allTitle='All'
            emptyTitle='None'
            indeterminateTitle='All'
            options={filterOptions}
            values={filteredStatus}
            {...{
              menuButtonProps: {
                w: 'auto',
                textStyle: 'button',
                color: 'primary.1',
                variant: 'tab.selector',
                height: '40px',
              },
            }}
          />
        </Flex>

        {filteredStatus.includes('turnedIn') && (
          <SideNavList title='Turned In' submissions={turnedInSubmissions} />
        )}

        {filteredStatus.includes('missing') && (
          <SideNavList title='Missing' submissions={missingSubmissions} />
        )}
      </Flex>
    </Flex>
  )
}
