import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  IconButton,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { BiChevronLeft } from 'react-icons/bi'
import { v4 } from 'uuid'
import { useClassWorkContext } from '../../pages/ClassWork/ClassWorkContext'
import { Assignment } from '../../types'
import { SideNav, AssignmentInfoForm } from '../Assignment'
import { AssignmentReviewForm, LearningReviewForm } from '../Exam'
import { isIPhone } from '../../utils'

export type AssignmentModalProps = {
  isOpen: boolean
  onClose: () => void
  type: 'create' | 'edit'
  assignment?: Assignment
}

export type FormType = {
  value: string
  display: string
  showInSideNav: boolean
}

export type FormsType = Record<string, FormType>
export type ModalState = {
  title: string
  showQuestionHeader: boolean
  onClickBack: () => void
}

export const AssignmentModal = ({
  isOpen,
  onClose,
  type,
  assignment,
}: AssignmentModalProps): JSX.Element => {
  const defaultFormsState = {
    info: {
      value: 'assignmentInfo',
      display: 'Assignment Info',
      showInSideNav: false,
    },
    assignmentReview: {
      value: 'assignmentReview',
      display: 'Assignment Review',
      showInSideNav: true,
    },
    learningReview: {
      value: 'learningReview',
      display: 'Learning Review',
      showInSideNav: true,
    },
  }
  const [forms, setForms] = useState<FormsType>(defaultFormsState)
  const [selectedForm, setSelectedForm] = useState('assignmentInfo')
  // for new assignments
  const [assignmentId, setAssignmentId] = useState<string>(v4())
  const [modalState, setModalState] = useState<ModalState>({
    title: '',
    showQuestionHeader: false,
    onClickBack: () => {},
  })
  const { getClassTopics, materialAssignmentRefetch } = useClassWorkContext()
  const title = type === 'create' ? 'Create Assignment' : 'Edit Assignment'

  const assignmentReview = assignment?.exams.find(
    ({ type }) => type === 'assignment_review'
  )

  const learningReview = assignment?.exams.find(
    ({ type }) => type === 'learning_review'
  )

  const resetForm = () => {
    setAssignmentId(v4())
    setForms(defaultFormsState)
    setSelectedForm(forms.info.value)
  }

  const handleCloseModal = () => {
    onClose()
    resetForm()
  }

  const onAssignmentInfoCreate = ({
    withAssignmentReview,
    withLearningReview,
  }: {
    withAssignmentReview: boolean
    withLearningReview: boolean
  }) => {
    setForms({
      ...forms,
      assignmentReview: {
        ...forms.assignmentReview,
        showInSideNav: !!withAssignmentReview,
      },
      learningReview: {
        ...forms.learningReview,
        showInSideNav: !!withLearningReview,
      },
    })

    if (withAssignmentReview) {
      setSelectedForm(forms.assignmentReview.value)
    } else if (withLearningReview) {
      setSelectedForm(forms.learningReview.value)
    } else {
      onClose()
      resetForm()
    }
  }

  const assignmentReviewOnSubmit = () => {
    getClassTopics()
    materialAssignmentRefetch()

    if (forms.learningReview.showInSideNav) {
      setSelectedForm(forms.learningReview.value)
    } else {
      onClose()
      resetForm()
    }
  }

  const learningReviewOnSubmit = () => {
    onClose()
    resetForm()
    getClassTopics()
    materialAssignmentRefetch()
  }

  const assignmentReviewSubmitText = assignment?.id
    ? 'Save'
    : forms.learningReview.showInSideNav
    ? 'Next'
    : 'Finish'

  const learningReviewSubmitText = assignment?.id ? 'Save' : 'Finish'

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCloseModal}
      size='3xl'
      variant='withDivider'
    >
      <ModalOverlay />
      <ModalContent
        my={{ base: '0px !important', md: '3.75rem !important' }}
        borderRadius={{ base: '0px', md: '12px' }}
      >
        {modalState.showQuestionHeader ? (
          <>
            <ModalHeader>{modalState.title}</ModalHeader>
            <IconButton
              fontSize='20px'
              aria-label='Go back'
              variant='icon.button'
              icon={<BiChevronLeft />}
              onClick={modalState.onClickBack}
              pos='absolute'
              top={4}
              insetInlineEnd='unset'
              left={2}
              paddingBottom='4px'
            />
          </>
        ) : (
          <>
            <ModalHeader>{title}</ModalHeader>
            <ModalCloseButton />
          </>
        )}

        <ModalBody p='0' minH='705px'>
          <Flex w='100%' minH='705px' direction={{ base: 'column', md: 'row' }}>
            {selectedForm !== forms.info.value && (
              <SideNav selectedForm={selectedForm} forms={forms} />
            )}

            <ModalFormContent
              value={forms.info.value}
              selectedForm={selectedForm}
            >
              <Flex w='100%' px={6} pt={6} pb={1}>
                <AssignmentInfoForm
                  onClose={onClose}
                  type={type}
                  assignment={assignment}
                  onAssignmentInfoCreate={onAssignmentInfoCreate}
                  newAssignmentId={assignmentId}
                  learningReview={learningReview}
                  assignmentReview={assignmentReview}
                />
              </Flex>
            </ModalFormContent>

            <ModalFormContent
              value={forms.assignmentReview.value}
              selectedForm={selectedForm}
            >
              <AssignmentReviewForm
                assignmentId={assignment?.id || assignmentId}
                setModalState={setModalState}
                display={forms.assignmentReview.display}
                onSubmit={assignmentReviewOnSubmit}
                assignmentReview={assignmentReview}
                submitText={assignmentReviewSubmitText}
              />
            </ModalFormContent>

            <ModalFormContent
              value={forms.learningReview.value}
              selectedForm={selectedForm}
            >
              <LearningReviewForm
                assignmentId={assignment?.id || assignmentId}
                setModalState={setModalState}
                display={forms.learningReview.display}
                onSubmit={learningReviewOnSubmit}
                learningReview={learningReview}
                submitText={learningReviewSubmitText}
              />
            </ModalFormContent>
          </Flex>
          {
            // make sure button doesn't get blocked by IOS browser bottom bar
            isIPhone() && <Flex h='100px'></Flex>
          }
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

const ModalFormContent = ({
  value,
  selectedForm,
  children,
}: {
  value: string
  selectedForm: string
  children: JSX.Element
}) => {
  return value === selectedForm ? children : null
}
