import { AiFillFile } from 'react-icons/ai'
import {
  FaFilePdf,
  FaFileAudio,
  FaFileArchive,
  FaFileCsv,
  FaFileImage,
  FaFileVideo,
} from 'react-icons/fa'
import {
  RiFileExcel2Fill,
  RiFilePpt2Fill,
  RiFileWord2Fill,
} from 'react-icons/ri'
import { GoogleDocs } from '../Icons'
import { Icon, Flex } from '@chakra-ui/react'

export type FileIconProps = {
  extension: string
  removeBorder?: boolean
  iconProps?: Record<string, any>
}

export const FileIcon = ({
  extension,
  removeBorder,
  iconProps,
}: FileIconProps): JSX.Element => {
  switch (extension) {
    case 'doc':
    case 'docx':
      return (
        <Flex
          borderColor='wordblue'
          borderWidth='2px'
          borderStyle='solid'
          p='10px'
          borderRadius='4.5px'
        >
          <Icon as={RiFileWord2Fill} color='wordblue' />
        </Flex>
      )
    case 'xls':
    case 'xlsx':
      return (
        <Flex
          borderColor='excelgreen'
          borderWidth='2px'
          borderStyle='solid'
          p='10px'
          borderRadius='4.5px'
        >
          <Icon as={RiFileExcel2Fill} color='excelgreen' />
        </Flex>
      )
    case 'ppt':
    case 'pptx':
    case 'ppsx':
      return (
        <Flex
          borderColor='pptorange'
          borderWidth='2px'
          borderStyle='solid'
          p='10px'
          borderRadius='4.5px'
        >
          <Icon as={RiFilePpt2Fill} color='pptorange' />
        </Flex>
      )
    case 'pdf':
      return (
        <Flex
          borderColor='pdfred'
          borderWidth='2px'
          borderStyle='solid'
          p='10px'
          borderRadius='4.5px'
        >
          <Icon as={FaFilePdf} color='pdfred' />
        </Flex>
      )
    case 'mp3':
    case 'mp4':
      return (
        <Flex
          borderColor='primary.1'
          borderWidth='2px'
          borderStyle='solid'
          p='10px'
          borderRadius='4.5px'
        >
          <Icon as={FaFileAudio} color='primary.1' />
        </Flex>
      )
    case 'zip':
    case 'tar':
    case 'gz':
    case '7z':
      return (
        <Flex
          borderColor='primary.1'
          borderWidth='2px'
          borderStyle='solid'
          p='10px'
          borderRadius='4.5px'
        >
          <Icon as={FaFileArchive} color='primary.1' />
        </Flex>
      )
    case 'csv':
      return (
        <Flex
          borderColor='primary.1'
          borderWidth='2px'
          borderStyle='solid'
          p='10px'
          borderRadius='4.5px'
        >
          <Icon as={FaFileCsv} color='primary.1' />
        </Flex>
      )
    case 'png':
    case 'svg':
    case 'jpeg':
    case 'jpg':
    case 'gif':
      return (
        <Flex
          borderColor={removeBorder ? 'none' : 'primary.1'}
          borderWidth={removeBorder ? 'none' : '2px'}
          borderStyle={removeBorder ? 'none' : 'solid'}
          borderRadius={removeBorder ? 'none' : '4.5px'}
          py='10px'
          pl='8px'
          pr='10px'
        >
          <Icon as={FaFileImage} color='primary.1' {...iconProps} />
        </Flex>
      )
    case 'webm':
    case 'mkv':
    case 'flv':
    case 'mov':
    case 'avi':
    case 'wmv':
    case 'mpeg':
    case 'mpg':
      return (
        <Flex
          borderColor='primary.1'
          borderWidth='2px'
          borderStyle='solid'
          p='10px'
          borderRadius='4.5px'
        >
          <Icon as={FaFileVideo} color='primary.1' />
        </Flex>
      )
    default:
      return (
        <Flex
          borderColor='primary.1'
          borderWidth='2px'
          borderStyle='solid'
          p='10px'
          borderRadius='4.5px'
        >
          <Icon as={AiFillFile} color='primary.1' />
        </Flex>
      )
  }
}

export default FileIcon
