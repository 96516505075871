import { Flex, Text } from '@chakra-ui/react'

export interface NotEnoughDataProps {
  containerProps?: Record<string, any>
  textProps?: Record<string, any>
  display?: string
}

export const NotEnoughData = ({
  containerProps = {},
  textProps = {},
  display = 'Not enough data',
}: NotEnoughDataProps) => {
  return (
    <Flex
      align='center'
      justify='center'
      h='416px'
      w='100%'
      {...containerProps}
    >
      <Text
        textStyle='body.2'
        color='black.3'
        fontSize='16px'
        fontWeight='600'
        {...textProps}
      >
        {display}
      </Text>
    </Flex>
  )
}
