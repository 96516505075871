import { uploadLimit } from '../constants'

export const validateSpaceOnly = (value: string): boolean | string =>
  !/^\s+$/g.test(value) || 'Please enter valid characters'

export const validateUrl = (value: string): boolean | string => {
  const url = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator

  return !!url.test(value) || 'Please enter valid url'
}

export const validateHttpUrl = (value: string): boolean | string => {
  let url

  try {
    url = new URL(value)
  } catch (_) {
    return false || 'Please enter valid url'
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const isSpaceOnly = (value: string) => /^\s+$/g.test(value)

export const validateResponseQuestion = ({
  value,
  minLength = 2,
}: {
  value: string
  minLength?: number
}) => {
  if (validateSpaceOnly(value) !== true) {
    return validateSpaceOnly(value)
  }

  if (value.length < minLength) {
    return `Enter a choice longer than ${minLength - 1} character`
  }

  return false
}

export const hasNumber = (value: string): boolean => /\d/.test(value)

export const uniqueClassNameValidation = (
  className: string,
  currentClassNames: string[]
): boolean | string => {
  return !currentClassNames.includes(className) || 'Name already exists!'
}

export const validateClassCode = (
  classCode: string,
  existingClassCodes: string[]
): boolean | string => {
  if (classCode.length >= 6) {
    return (
      existingClassCodes.includes(classCode) || 'This class does not exist.'
    )
  }

  return true
}

export const fileSizeValidation = (file: File) => {
  if (file.size > uploadLimit) {
    return {
      code: 'file-size-too-large',
      message: `${file.name} is larger than 50MB`,
    }
  }
  return null
}
