import React from 'react'
import { Comment } from './index'
import {
  Flex,
  VStack,
  Button,
  useDisclosure,
  ScaleFade,
} from '@chakra-ui/react'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import { Comment as CommentProps } from '../../types'

export type CommentListProps = {
  comments: CommentProps[]
  bg?: string
  indented?: boolean
  isBulletinModal?: boolean
  refetch?: () => void
  commentProps?: Record<string, any>
  textProps?: Record<string, any>
  isExpanded?: boolean
  customOnClose?: () => void
  leftIconProps?: Record<string, any>
  buttonProps?: Record<string, any>
}

export const CommentList = ({
  comments,
  bg = 'white',
  indented = false,
  isBulletinModal = false,
  refetch,
  commentProps = {},
  isExpanded = false,
  customOnClose,
  textProps = {},
  leftIconProps,
  buttonProps,
}: CommentListProps): JSX.Element | null => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const commentNumber = comments.length

  const handleOnHide = () => {
    if (customOnClose) {
      customOnClose()
    } else {
      onClose()
    }
  }

  return comments.length >= 1 ? (
    <Flex w='100%' pt={2} px='16px' direction='column'>
      <VStack spacing='2'>
        {comments.map((comment, index) => {
          if (index === 0) {
            return (
              <Comment
                {...comment}
                indented={indented}
                key={index}
                refetch={refetch}
                commentProps={commentProps}
                textProps={textProps}
                isBulletinModal={isBulletinModal}
              />
            )
          }

          if (index >= 1 && (isOpen || isExpanded)) {
            return (
              <Comment
                indented={indented}
                key={index}
                {...comment}
                refetch={refetch}
                textProps={textProps}
                isBulletinModal={isBulletinModal}
              />
            )
          }
        })}

        <Flex w='100%'>
          {commentNumber > 1 && isOpen && !isExpanded && (
            <Button
              leftIcon={<MdKeyboardArrowUp />}
              variant='icon.button'
              background={bg}
              _hover={{ bg: bg }}
              textStyle='overline'
              textTransform='uppercase'
              onClick={handleOnHide}
              fontSize='10px'
              pl='0.5rem'
            >
              hide comment{commentNumber > 2 && 's'}
            </Button>
          )}

          {commentNumber > 1 && !isOpen && !isExpanded && (
            <Button
              leftIcon={<MdKeyboardArrowDown />}
              variant='icon.button'
              background={bg}
              _hover={{ bg: bg }}
              textStyle='overline'
              textTransform='uppercase'
              onClick={onOpen}
              fontSize='10px'
              height='2rem'
              pl='0.5rem'
            >
              show {commentNumber - 1} comment{commentNumber > 2 && 's'}
            </Button>
          )}

          {isExpanded && (
            <Button
              leftIcon={<MdKeyboardArrowUp {...leftIconProps} />}
              variant='icon.button'
              background={bg}
              _hover={{ bg: bg }}
              textStyle='overline'
              textTransform='uppercase'
              onClick={handleOnHide}
              fontSize='10px'
              height='2rem'
              {...buttonProps}
            >
              hide comment{commentNumber > 1 && 's'}
            </Button>
          )}
        </Flex>
      </VStack>
    </Flex>
  ) : null
}

export default CommentList
