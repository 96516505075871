import React from 'react'
import {
  Box,
  Text,
  VStack,
  Flex,
  StackDivider,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react'
import { useDisclosure } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { CREATE_POST_COMMENT } from '../../client/queries'
import { useMutation } from '@apollo/client'
import { RiPushpin2Fill } from 'react-icons/ri'
import { FaTrash } from 'react-icons/fa'
import { DecoratedLink } from '../Styled'
import { HoverMenu, parentHoverCss } from '../Menu'
import { EditPostModal } from '../Modal'
import { CommentList } from './index'
import { PostHeading, AnnouncementHeading } from '../Heading'
import { Post as PostType } from '../../types'
import { v4 as uuid } from 'uuid'
import { getFullName, useErrorToast, useSuccessToast } from '../../utils'
import { useAlamContext } from '../../client'
import { RiPencilFill } from 'react-icons/ri'
import Linkify from 'react-linkify'
import { RenderForUser } from '../SharedComponents'
import { ConfirmationModal } from '../Modal'
import { FileList } from '../File'
import { UPDATE_POST } from '../../client/queries'
import { IoMdDocument } from 'react-icons/io'
import { CommentForm } from '../Forms'
import { Pin } from '../Icons'

type PostProps = {
  post: PostType
  handleDeletePost: (postId: string, onCloseDeletePost: () => void) => void
  isGroup?: boolean
}

export const FormWrapper = styled(Box)`
  width: 100%;
  .form {
    width: 100%;
  }
`

export const Post = ({
  post,
  handleDeletePost,
  isGroup = false,
}: PostProps): JSX.Element => {
  const {
    id,
    type,
    user,
    inserted_at,
    content = '',
    comments,
    isPinned,
    title,
    schedule,
    attachments,
  } = post
  const { firstName, lastName, avatar, classUsers } = user
  const postClassUserRole = (classUsers && classUsers[0].role) || 'student'
  const currentUser = useAlamContext().user
  const { classUser } = useAlamContext()
  const [createComment, { loading: commentIsSubmitting }] = useMutation(
    CREATE_POST_COMMENT
  )
  const name = getFullName({ firstName, lastName })
  const {
    isOpen: isOpen,
    onOpen: onOpenEditPost,
    onClose: onCloseEditPost,
  } = useDisclosure()

  const {
    isOpen: showDeletePost,
    onOpen: onOpenDeletePost,
    onClose: onCloseDeletePost,
  } = useDisclosure()

  const [unpinPost, { loading: unpinPostIsLoading }] = useMutation(
    UPDATE_POST,
    {
      variables: {
        id: id,
        changes: {
          is_pinned: false,
        },
      },
      onCompleted: () => {
        useSuccessToast({
          title: 'Post unpinned',
          icon: IoMdDocument,
        })
      },
      onError: (error) => {
        console.warn(error)
        useErrorToast({ message: 'Unpin Post failed' })
      },
    }
  )

  const handleUnpinPost = () => {
    if (!unpinPostIsLoading) {
      unpinPost()
    }
  }

  const handleSubmitComment = ({ content }: { content: string }) => {
    createComment({
      variables: {
        id: uuid(),
        content,
        user_id: currentUser.id,
        post_id: id,
      },
    })
      .then(() => {
        useSuccessToast({ message: 'Comment was created' })
      })
      .catch(() => {
        useErrorToast({ message: 'Creating comment failed' })
      })
  }

  return (
    <Box
      w='100%'
      layerStyle='card.base'
      borderRadius={{ base: '0', lg: '12px' }}
      css={parentHoverCss}
      boxShadow='rgb(0 0 0 / 10%) 0px 4px 6px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px'
    >
      <Box display='hidden' id={id} pos='absolute' top='calc(-64px - 1.5rem)' />
      <EditPostModal
        id={id}
        isOpen={isOpen}
        onClose={onCloseEditPost}
        title={title}
        schedule={schedule}
        type={type}
        isPinned={isPinned}
        content={content}
        attachments={attachments}
        isGroup={isGroup}
      />

      <ConfirmationModal
        isOpen={showDeletePost}
        onClose={onCloseDeletePost}
        title='Are you sure?'
        message={`${firstName}'s post will be deleted and no longer visible to anyone in the class.`}
        onSubmit={() => handleDeletePost(id, onCloseDeletePost)}
      />

      <RenderForUser
        roles={
          ['admin', 'teacher', 'creator'].includes(postClassUserRole)
            ? ['admin', 'creator']
            : ['admin', 'teacher', 'creator']
        }
        userIds={[user.id]}
      >
        <HoverMenu top={3} right={2}>
          <>
            {isPinned && (
              <RenderForUser
                roles={['admin', 'teacher', 'creator']}
                userIds={[user.id]}
              >
                <MenuItem icon={<RiPushpin2Fill />} onClick={handleUnpinPost}>
                  Unpin
                </MenuItem>
                <MenuDivider />
              </RenderForUser>
            )}
            <RenderForUser userIds={[user.id]}>
              <MenuItem icon={<RiPencilFill />} onClick={onOpenEditPost}>
                Edit
              </MenuItem>
              <MenuDivider />
            </RenderForUser>
            <RenderForUser
              roles={
                ['admin', 'teacher', 'creator'].includes(postClassUserRole)
                  ? ['admin', 'creator']
                  : ['admin', 'teacher', 'creator']
              }
              userIds={[user.id]}
            >
              <MenuItem icon={<FaTrash />} onClick={() => onOpenDeletePost()}>
                Delete
              </MenuItem>
            </RenderForUser>
          </>
        </HoverMenu>
      </RenderForUser>

      <Flex direction='column'>
        <VStack divider={<StackDivider borderColor='grey.border' />}>
          <Flex w='100%' px={4} pt={4} pb={2} align='center'>
            {isPinned && (
              <Box mr='12px'>
                <Pin width='16' height='16' viewBox='8 8 24 24' />
              </Box>
            )}

            {type === 'announcement' ? (
              <AnnouncementHeading
                title={title}
                firstName={firstName}
                lastName={lastName}
                inserted_at={inserted_at}
                type={type}
                showCommentIcon={false}
              />
            ) : (
              <PostHeading
                firstName={firstName}
                lastName={lastName}
                inserted_at={inserted_at}
                type='post'
                avatar={avatar}
              />
            )}
          </Flex>

          {content && (
            <Flex w='100%' px={4} py={4}>
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <DecoratedLink target='_blank' href={decoratedHref} key={key}>
                    {decoratedText}
                  </DecoratedLink>
                )}
              >
                <Text
                  textStyle='subtitle'
                  fontSize='16px'
                  wordBreak='break-word'
                  whiteSpace='pre-line'
                >
                  {content}
                </Text>
              </Linkify>
            </Flex>
          )}

          {attachments.length >= 1 && (
            <FileList existingFiles={attachments} isReadOnly />
          )}

          {comments?.length >= 1 && <CommentList comments={comments} />}

          <Flex w='100%' px={4} pb={4} pt={2}>
            <CommentForm
              user={currentUser}
              isSubmitting={commentIsSubmitting}
              onSubmit={handleSubmitComment}
              avatarSize='md'
            />
          </Flex>
        </VStack>
      </Flex>
    </Box>
  )
}

export default Post
