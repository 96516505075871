import { useAlamContext } from '../../client'

export type RenderForUserProps = {
  roles?: string[]
  userIds?: string[]
  context?: string
  children: any
}

export const RenderForUser = ({
  roles = [],
  context = 'classwork',
  children,
  userIds = [],
}: RenderForUserProps) => {
  const { classUser, user } = useAlamContext()

  return (userIds.includes(user.id) || roles.includes(classUser?.role)) &&
    context === 'classwork' ? (
    <>{children}</>
  ) : null
}

export default RenderForUser
