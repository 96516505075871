import { Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { ExamArrangement } from '../../types'
import { Question } from './Question'

export type QuestionsProps = {
  examArrangements?: ExamArrangement[]
  placeholderText: string
  changeModalHeaderToSelected: () => void
}

export const Questions = ({
  examArrangements = [],
  placeholderText,
  changeModalHeaderToSelected,
}: QuestionsProps): JSX.Element => {
  return examArrangements.length === 0 ? (
    <Flex
      p='24px'
      borderBottom={{ base: '.5px solid', md: 'none' }}
      borderColor='primary.2'
    >
      <Text textStyle='body.1' color='black.4'>
        {placeholderText}
      </Text>
    </Flex>
  ) : (
    <Flex
      direction='column'
      py='16px'
      borderBottom='.5px solid'
      borderColor='primary.2'
    >
      {examArrangements.map((examArrangement) => (
        <Question
          examArrangement={examArrangement}
          key={examArrangement.id}
          changeModalHeaderToSelected={changeModalHeaderToSelected}
        />
      ))}
    </Flex>
  )
}
