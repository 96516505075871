import { gql } from '@apollo/client'
import { userDisplayFields } from './index'

export const sessionFields = gql`
  ${userDisplayFields}
  fragment sessionFields on sessions {
    id
    name
    description
    user_id
    session_users {
      id
      user {
        bio
        email
        provider
        ...userDisplayFields
        learningStyle: learning_style
        classUsers: class_users(where: { class_id: { _eq: $class_id } }) {
          role
        }
      }
    }
    documents(order_by: { index: asc }) {
      id
      index
      file_id
      name
      url
      user {
        ...userDisplayFields
        email
      }
    }
    links(
      order_by: { inserted_at: desc }
      where: { archived_at: { _is_null: true } }
    ) {
      id
      name
      url
      inserted_at
    }
    attachments(order_by: { inserted_at: desc }) {
      id
      url
      file
      inserted_at
    }
  }
`
