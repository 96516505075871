import React from 'react'
import { IconAlamProps } from './Type'

export const Send = ({
  fill,
  width,
  height,
  viewBox,
}: IconAlamProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M9 29L30 20L9 11V18L24 20L9 22V29Z' fill={fill} />
  </svg>
)

Send.defaultProps = {
  fill: '#17053D',
  width: '40',
  height: '40',
  viewBox: '0 0 40 40',
}
