import { Flex, Text, VStack, StackDivider } from '@chakra-ui/react'
import { QuestionGraphProps } from './QuestionCard'
import { constructTextQuestionDataLimited, getFullName } from '../../utils'
import { AlamAvatar } from '../Avatar'
import { format } from 'date-fns'
import { NotEnoughData } from './NotEnoughData'

export const TextQuestionGraph = ({
  question,
}: QuestionGraphProps): JSX.Element => {
  const textQuestionData = constructTextQuestionDataLimited(question, 5)

  const renderDate = (date: string | undefined) => {
    if (!date) return 'N/A'

    return format(new Date(date), 'MM/dd/yy')
  }

  return (
    <>
      <VStack
        divider={<StackDivider borderColor='primary.2' my='0px !important' />}
      >
        {textQuestionData?.length > 0 &&
          textQuestionData?.map((data) => {
            return (
              <Flex w='100%' h='60px' align='center'>
                <Flex w='181px' h='100%' align='center' p='0px 0px 0px 24px'>
                  <AlamAvatar size='sm' mr='8px' src={data?.user?.avatar} />
                  <Flex direction='column'>
                    <Text
                      fontFamily="'Nunito Sans', sans-serif"
                      fontStyle='normal'
                      fontSize='12px'
                      lineHeight='16px'
                      fontWeight='700'
                      letterSpacing='-0.01em'
                      color='black.1'
                      maxW='calc(181px - 24px - 32px - 20px)'
                      isTruncated
                    >
                      {getFullName(data?.user)}
                    </Text>
                    <Text
                      fontFamily="'Nunito Sans', sans-serif"
                      fontStyle='normal'
                      fontSize='10px'
                      lineHeight='16px'
                      fontWeight='600'
                      letterSpacing='-0.02em'
                      color='grey.1'
                      maxW='calc(181px - 24px - 32px - 20px)'
                      isTruncated
                    >
                      {renderDate(data?.answers[0]?.inserted_at)}
                    </Text>
                  </Flex>
                </Flex>
                <Text
                  grow='1'
                  maxW='878px'
                  noOfLines={2}
                  fontFamily="'Nunito Sans', sans-serif"
                  fontStyle='normal'
                  fontSize='12px'
                  lineHeight='18px'
                  fontWeight='normal'
                  letterSpacing='-0.01em'
                  color='black.1'
                >
                  {data?.answers[0]?.text}
                </Text>
              </Flex>
            )
          })}
        {textQuestionData && textQuestionData?.length < 1 && <NotEnoughData />}
      </VStack>
    </>
  )
}
