import { SimpleGrid } from '@chakra-ui/react'
import { Session } from '../../types'
import { Layout } from '../Layout'
import { TopNavItemProps } from '../TopNav'
import { Files, SessionChat } from './index'

export type SessionSpacePage = {
  id: string
  sessionId: string
  topNavLeft: TopNavItemProps[]
  topNavCenter: TopNavItemProps[]
  session: Session
  refetch: () => void
  setSession: (session: Session) => void
  sessionFolderId?: string
  setSessionFolderId: (folderId: string) => void
}

export const SessionSpacePage = ({
  id,
  sessionId,
  topNavCenter,
  topNavLeft,
  session,
  refetch,
  setSession,
  sessionFolderId,
  setSessionFolderId,
}: SessionSpacePage): JSX.Element => {
  return (
    <Layout showSideNav topNavLeft={topNavLeft} topNavCenter={topNavCenter}>
      <SimpleGrid
        templateColumns={{
          base: '1fr',
          sm: '1fr 3fr',
          md: '280px 3fr',
          lg: '336px 3fr',
        }}
      >
        <Files
          session={session}
          refetch={refetch}
          setSession={setSession}
          sessionFolderId={sessionFolderId}
          setSessionFolderId={setSessionFolderId}
        />

        <SessionChat
          classId={id}
          sessionId={sessionId}
          sessionUsers={session.session_users}
        />
      </SimpleGrid>
    </Layout>
  )
}

export default SessionSpacePage
