import { useState } from 'react'
import { ChatHeading } from '../Chat'
import { ManagePeopleItems } from './index'
import { SidebarHeading } from '../Heading'
import { FaUserPlus, FaTrash } from 'react-icons/fa'
import { ClassUser } from '../../types'
import { Flex, Text, IconButton, useDisclosure } from '@chakra-ui/react'
import { useMutation, useQuery, useLazyQuery } from '@apollo/client'
import {
  CREATE_USERS,
  DELETE_PEOPLE,
  GET_CLASS_CODE,
  INVITE_USER,
  GET_USERS,
} from '../../client/queries'
import {
  useErrorToast,
  useSuccessToast,
  isMobileDevice,
  buildURL,
} from '../../utils'
import { ClassInvite, ClassInviteInput } from '../Forms'
import { v4 as uuid } from 'uuid'
import { useParams } from 'react-router-dom'
import { ConfirmationModal } from '../Modal'
import { useAlamContext } from '../../client'


export type ManagePeopleProps = {
  managePeopleStudents: ClassUser[]
  managePeopleTeachers: ClassUser[]
  managePeopleAdmins: ClassUser[]
}

export const ManagePeople = ({
  managePeopleStudents,
  managePeopleTeachers,
  managePeopleAdmins,
}: ManagePeopleProps) => {
  const [selected, setSelected] = useState<string[]>([])
  const [isInviting, setIsInviting] = useState(false)
  const {
    isOpen: showClassInvite,
    onOpen: onOpenClassInvite,
    onClose: onCloseClassInvite,
  } = useDisclosure()
  const {
    isOpen: deleteIsOpen,
    onOpen: deleteOnOpen,
    onClose: deleteOnClose,
  } = useDisclosure()

  const context = useAlamContext()
  console.log('context: ', context)
  const { id: classId } = useParams<{ id: string }>()
  const [createUsers] = useMutation(
    CREATE_USERS,
    {
      onError: (err) => {
        console.warn('onError: ', err)
        onCloseClassInvite()
        useErrorToast({ message: 'Creating users failed.' })
      },
    }
  )
  const [getUsers] = useLazyQuery(GET_USERS)

  const [inviteUsers] = useMutation(
    INVITE_USER,
    {
      onError: (err) => {
        console.warn('onError: ', err)
        onCloseClassInvite()
        useErrorToast({ message: 'Invite user failed.' })
      },
    }

  )

  const handleChange = (newSelected: string[]) => {
    setSelected(newSelected)
  }

  const isSelected = (id: string) => selected.includes(id)

  const countSelected = selected.length

  const [deletePeople] = useMutation(DELETE_PEOPLE, {
    onCompleted: () => {
      setSelected([])
      deleteOnClose()
    },
  })

  const { data } = useQuery(GET_CLASS_CODE, {
    variables: {
      id: classId,
    },
  })
  const currentClass = data?.classes

  const handleDeletePeople = () => {
    deletePeople({
      variables: {
        user_ids: selected,
      },
    })
      .then(() => {
        useSuccessToast({ message: 'Successfully deleted people' })
      })
      .catch((error) => {
        console.warn(error)
      })
  }

  const handleClassInviteSubmit = async ({
    teachers,
    students,
  }: ClassInviteInput) => {
    setIsInviting(true)

    await Promise.all([
      handleInviteTeachers(teachers),
      handleInviteStudents(students),
    ])
      .then(() => {
        useSuccessToast({ message: 'Invited successfully' })
      })
      .catch((error) => {
        if (error.message === 'User has not accepted class invite') {
          useErrorToast({
            title: 'User has not accepted class invite',
            message:
              'The user has been added to the class but has not accepted the class invite',
          })
        } else {
          useErrorToast({ message: error.message })
        }
      })

    setIsInviting(false)
    onCloseClassInvite()
  }

  const handleInviteTeachers = async (teachers: string) => {
    if (teachers) {
      await handleCreateUsers(teachers)
      const emails = teachers.split(',')
      const getTeachersPromise = await getUsers({ variables: { emails }})
      const inviteTeachersPromise = await handleInviteUsers(
        getTeachersPromise?.data?.users,
        'teacher'
      )
      return inviteTeachersPromise
    }
  }


  const handleInviteStudents = async (students: string) => {
    if (students) {
      await handleCreateUsers(students)
      const emails = students.split(',')
      const getStudentsPromise = await getUsers({
        variables: { emails },
      })
      const inviteStudentsPromise = await handleInviteUsers(
        getStudentsPromise?.data?.users,
        'student'
      )
      return inviteStudentsPromise
    }
  }

  const handleCreateUsers = async (emails: string) => {
    try {
      const userParams = emails.split(',').map((email) => ({
        id: uuid(),
        email,
        updated_at: 'NOW()',
        inserted_at: 'NOW()',
      }))

      const createUsersPromise = await createUsers({
        variables: {
          objects: userParams,
        },
      })
      return createUsersPromise
    } catch (error) {
      console.warn(error)
      useErrorToast({ message: `User wasn't created` })
    }
  }

  const handleInviteUsers = async (users: { id: string }[], role: string) => {
    try {
      const userInvitePromises = await Promise.all(
        users?.map(async ({ id }) =>
          inviteUsers({
            variables: {
              class_id: classId,
              user_id: id,
              role,
              send_invite: true,
            },
          })
        )
      )
      return userInvitePromises
    } catch (error) {
      console.warn(error)
      useErrorToast({ message: `User invite failed` })
    }
  }

  return (
    <>
      <ConfirmationModal
        isOpen={deleteIsOpen}
        onClose={deleteOnClose}
        title={`Remove Users (${countSelected})`}
        message={`Removed users will no longer have access to the class and all materials or assignments permissions will be removed.`}
        onSubmit={handleDeletePeople}
        confirmButtonText='REMOVE'
      />
      {currentClass &&
        currentClass.map(({ code }: { code: string }, index: string) => (
          <ClassInvite
            isOpen={showClassInvite}
            onClose={onCloseClassInvite}
            onSubmit={handleClassInviteSubmit}
            isSubmitting={isInviting}
            isManagePeople
            inviteLink={buildURL({
              host: window.location.host,
              path: `/join/${code}`,
            })}
            key={index}
          />
        ))}
      {!isMobileDevice() && (
        <>
          <ChatHeading title='Classroom' />
          <SidebarHeading
            title='Manage People'
            bg='primary.1'
            color='white.1'
            titleProps={{ fontWeight: '600', letterSpacing: '0.1rem' }}
            padding='16px'
          />
        </>
      )}
      <SidebarHeading
        titleWithIcon
        title='Class Invite'
        icon={FaUserPlus}
        ariaLabel='invite class'
        onClick={onOpenClassInvite}
        containerProps={
          isMobileDevice()
            ? {
                padding: '8px 16px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                h: '56px',
              }
            : {
                mb: '16px',
                padding: '12px 16px',
              }
        }
        iconProps={
          isMobileDevice()
            ? {
                maxW: '17.6px',
                maxH: '12.8px',
                w: '17.6px',
                h: '12.8px',
              }
            : {}
        }
        titleProps={
          isMobileDevice()
            ? {
                fontSize: '14px',
                lineHeight: '21px',
                fontWeight: '400',
                letterSpacing: '-0.01em',
              }
            : {
                fontSize: '14px',
                lineHeight: '21px',
                letterSpacing: 0,
              }
        }
      />

      <ManagePeopleItems
        classUsers={managePeopleAdmins}
        title='Admins'
        selected={selected}
        handleChange={handleChange}
        isSelected={isSelected}
      />
      <ManagePeopleItems
        classUsers={managePeopleTeachers}
        title='Instructors'
        selected={selected}
        handleChange={handleChange}
        isSelected={isSelected}
      />
      <ManagePeopleItems
        classUsers={managePeopleStudents}
        title='Students'
        selected={selected}
        handleChange={handleChange}
        isSelected={isSelected}
      />

      {countSelected > 0 && (
        <Flex
          w='248px'
          h='48px'
          backgroundColor='primary.1'
          color='white.1'
          pos='fixed'
          bottom='0'
          right='0'
          align='center'
          justify='space-between'
          pl='16px'
        >
          <Text textStyle='body.semibold'>{countSelected} selected</Text>
          <IconButton
            aria-label='delete account'
            icon={<FaTrash />}
            backgroundColor='transparent'
            fontSize='16px'
            _hover={{
              backgroundColor: 'transparent',
            }}
            color='white'
            onClick={deleteOnOpen}
          />
        </Flex>
      )}
    </>
  )
}

export default ManagePeople
