import { Text, Flex, Icon } from '@chakra-ui/react'
import { React } from '@ungap/global-this'
import { HiUserGroup } from 'react-icons/hi'
import { Submission } from '../../types'
import { getFullName, getFirstAndLastInitial } from '../../utils'
import { AlamAvatar } from '../Avatar'

export type SideNavItemProps = {
  setSelectedSubmission: React.Dispatch<Submission>
  selectedSubmission?: Submission
  submission: Submission
  grading?: string
  rubric?: string
  setStatus: React.Dispatch<string>
  groupId?: string
}

export const SideNavItem = ({
  submission,
  setSelectedSubmission,
  selectedSubmission,
  grading,
  rubric,
  setStatus,
  groupId,
}: SideNavItemProps): JSX.Element => {
  const { user = undefined, grades, group } = submission
  const grade = grades[0]?.grade
  const selected = submission.id === selectedSubmission?.id

  const handleSelectSubmission = () => {
    setSelectedSubmission(submission)
    setStatus(submission?.status)
  }

  const getDisplayGrade = () => {
    if (!grade) {
      return (
        <Text textStyle='body.1' color={selected ? 'white.1' : 'black.2'}>
          Ungraded
        </Text>
      )
    }

    return rubric === 'numerical' ? (
      <Text
        textStyle='body.semibold'
        color={selected ? 'white.1' : 'primary.1'}
      >
        {grade}/{grading}
      </Text>
    ) : (
      <Text
        textStyle='body.semibold'
        color={selected ? 'white.1' : 'primary.1'}
      >
        {grade}
      </Text>
    )
  }

  return (
    <Flex
      h='56px'
      px='16px'
      align='center'
      justify='space-between'
      onClick={handleSelectSubmission}
      bg={selected ? '#4204C3' : 'white.1'}
      cursor='pointer'
    >
      <Flex h='100%' align='center'>
        {groupId ? (
          <GroupItem name={group?.name} selected={selected} />
        ) : (
          <StudentItem
            avatar={user?.avatar}
            name={getFullName(user)}
            initials={getFirstAndLastInitial(user)}
            selected={selected}
          />
        )}
      </Flex>

      <Flex h='100%' align='center'>
        {getDisplayGrade()}
      </Flex>
    </Flex>
  )
}

type StudentItem = {
  name: string
  selected: boolean
  initials: string
  avatar?: string
}

const StudentItem = ({ name, selected, initials, avatar }: StudentItem) => (
  <>
    <AlamAvatar mr='8px' size='md' name={initials} src={avatar} />
    <Text textStyle='body.semibold' color={selected ? 'white.1' : 'primary.1'}>
      {name}
    </Text>
  </>
)

type GroupItem = { selected: boolean; name?: string }

const GroupItem = ({ selected, name }: GroupItem) => (
  <>
    <Icon
      mr='15px'
      as={HiUserGroup}
      color={selected ? 'white.1' : 'primary.1'}
      mb='4px'
    />
    <Text textStyle='body.semibold' color={selected ? 'white.1' : 'primary.1'}>
      {name}
    </Text>
  </>
)
