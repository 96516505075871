import { Box } from '@chakra-ui/react'
import { compareDesc } from 'date-fns'
import {
  Attachment,
  SessionDocument as DocumentType,
  SessionLink as LinkType,
} from '../../types'
import { CustomListBox } from '../Styled'
import { Document } from './index'
import AttachmentLinkSorter from './AttachmentLinkSorter'

export type DocumentsProps = {
  documents: DocumentType[]
  refetch: () => void
  attachments: Attachment[]
  links: LinkType[]
}

export const Documents = ({
  documents,
  refetch,
  attachments,
  links,
}: DocumentsProps) => {
  const uploaded = [...attachments, ...links]
  const sortedAttachmentLink = uploaded.sort((attachment, link) => {
    const attachmentDate = new Date(attachment.inserted_at)
    const linkDate = new Date(link.inserted_at)

    return compareDesc(attachmentDate, linkDate)
  })

  return documents.length >= 1 ||
    attachments.length >= 1 ||
    links?.length >= 1 ? (
    <Box
      width='100%'
      borderRadius='12px'
      py='24px'
      px='16px'
      bg='white.1'
      mb='24px'
    >
      <CustomListBox>
        {documents.map((document) => (
          <Document document={document} refetch={refetch} />
        ))}
        {sortedAttachmentLink.map((upload) => (
          <AttachmentLinkSorter uploaded={upload} refetch={refetch} />
        ))}
      </CustomListBox>
    </Box>
  ) : null
}

export default Documents
