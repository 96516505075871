import React from 'react'
import { IconAlamProps } from './Type'

export const Group = ({
  fill,
  width,
  height,
  viewBox,
}: IconAlamProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M20 14C19.2044 14 18.4413 14.3161 17.8787 14.8787C17.3161 15.4413 17 16.2044 17 17C17 17.7956 17.3161 18.5587 17.8787 19.1213C18.4413 19.6839 19.2044 20 20 20C20.7956 20 21.5587 19.6839 22.1213 19.1213C22.6839 18.5587 23 17.7956 23 17C23 16.2044 22.6839 15.4413 22.1213 14.8787C21.5587 14.3161 20.7956 14 20 14ZM14 16.17C13.337 16.17 12.7011 16.4334 12.2322 16.9022C11.7634 17.3711 11.5 18.007 11.5 18.67C11.5 18.9983 11.5647 19.3234 11.6903 19.6267C11.8159 19.93 12.0001 20.2056 12.2322 20.4378C12.4644 20.6699 12.74 20.8541 13.0433 20.9797C13.3466 21.1053 13.6717 21.17 14 21.17C14.88 21.17 15.65 20.71 16.09 20.03C15.42 19.18 15 18.15 15 17C15 16.8 15 16.6 15.04 16.4C14.72 16.25 14.37 16.17 14 16.17ZM26 16.17C25.63 16.17 25.28 16.25 24.96 16.4C25 16.6 25 16.8 25 17C25 18.15 24.58 19.18 23.91 20.03C24.35 20.71 25.12 21.17 26 21.17C26.3283 21.17 26.6534 21.1053 26.9567 20.9797C27.26 20.8541 27.5356 20.6699 27.7678 20.4378C27.9999 20.2056 28.1841 19.93 28.3097 19.6267C28.4353 19.3234 28.5 18.9983 28.5 18.67C28.5 18.3417 28.4353 18.0166 28.3097 17.7133C28.1841 17.41 27.9999 17.1344 27.7678 16.9022C27.5356 16.6701 27.26 16.4859 26.9567 16.3603C26.6534 16.2347 26.3283 16.17 26 16.17ZM20 22C18 22 14 23 14 25V27H26V25C26 23 22 22 20 22ZM12.67 22.97C11 23.26 9 24.04 9 25.33V27H12V25C12 24.22 12.29 23.53 12.67 22.97ZM27.33 22.97C27.71 23.53 28 24.22 28 25V27H31V25.33C31 24.04 29 23.26 27.33 22.97Z'
      fill={fill}
    />
  </svg>
)

Group.defaultProps = {
  fill: '#17053D',
  width: '40',
  height: '40',
  viewBox: '0 0 40 40',
}
