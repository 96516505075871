import { useState, useEffect } from 'react'
import { Layout } from '../../components/Layout'
import { useAlamContext } from '../../client'
import {
  Container,
  Flex,
  VStack,
  IconButton,
  ButtonGroup,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuDivider,
  MenuItem,
  useDisclosure,
  Button,
  Box,
  Tooltip,
} from '@chakra-ui/react'
import { TopicInput, TopicForm, AssignmentInput } from '../../components/Forms'
import { MaterialModal } from '../../components/Modal'
import { Materials } from '../../components/Material'
import { Assignments, AssignmentModal } from '../../components/Assignment'
import { MdRemoveRedEye } from 'react-icons/md'
import { IoEarOutline } from 'react-icons/io5'
import { useLazyQuery, useMutation, useSubscription } from '@apollo/client'
import {
  GET_CLASS_TOPICS,
  GET_CLASS_MATERIALS,
  GET_CLASS_ASSIGNMENTS,
  CREATE_TOPIC,
  CREATE_ASSIGNMENT,
  CREATE_ATTACHMENT,
  generateAssignmentUsers,
  generateStudentSubmissions,
  GET_PENDING_CLASSWORK_REVIEWS,
} from '../../client/queries'
import { Topics, TopicFilter } from '../../components/Topic'
import { v4 as uuid } from 'uuid'
import {
  useSuccessToast,
  useErrorToast,
  filterMaterials,
  isMobileDevice,
} from '../../utils'
import { RenderForUser } from '../../components/SharedComponents'
import ClassWorkContext from './ClassWorkContext'
import {
  AuralIcon,
  Kinesthetic,
  Read,
  VisualIcon,
} from '../../components/Icons'
import { routes } from '../../constants'
import { ExamIndicators } from '../../components/Exam'

export type ClassWorkProps = {
  id: string
}

export interface handleTopicOnCreateProps {
  topic: TopicInput
  assignment?: AssignmentInput
}

const ClassWorkPage = ({ id }: ClassWorkProps): JSX.Element => {
  const [topics, setTopics] = useState([])
  const [assignments, setAssignments] = useState([])
  const [materials, setMaterials] = useState([])
  const [preference, setPreference] = useState<string[]>([])
  const [groups, setGroups] = useState([])
  const { classUser, user, students } = useAlamContext()

  useSubscription(GET_CLASS_MATERIALS, {
    variables: {
      class_id: id,
      user_id: user.id,
    },
    onSubscriptionData: ({
      subscriptionData: {
        data: { materials },
      },
    }) => {
      setMaterials(materials)
    },
  })

  useSubscription(GET_CLASS_ASSIGNMENTS, {
    variables: {
      class_id: id,
      user_id: user.id,
    },
    onSubscriptionData: ({
      subscriptionData: {
        data: { assignments },
      },
    }) => {
      setAssignments(assignments)
    },
  })

  const [getClassTopics] = useLazyQuery(GET_CLASS_TOPICS, {
    variables: { class_id: id, user_id: user.id },
    onCompleted: (data) => {
      const topics = data?.topics?.length || 0
      const newTopicFilter = Array(topics).fill(true)
      setTopicFilter(newTopicFilter)

      setTopics(data?.topics || [])
      setGroups(data?.groups || [])
    },
    fetchPolicy: 'cache-and-network',
  })
  const { data: pendingClassReviews } = useSubscription(
    GET_PENDING_CLASSWORK_REVIEWS,
    {
      variables: { class_id: id, user_id: user.id },
    }
  )

  const materialAssignmentRefetch = () => {}

  const classWorkState = {
    topics,
    classUsers: students,
    setTopics,
    getClassTopics,
    groups,
    materialAssignmentRefetch,
  }

  const [topicFilter, setTopicFilter] = useState<boolean[]>([])
  const {
    isOpen: topicIsOpen,
    onClose: topicOnClose,
    onOpen: topicOnOpen,
  } = useDisclosure()
  const {
    isOpen: assignmentIsOpen,
    onClose: assignmentOnClose,
    onOpen: assignmentOnOpen,
  } = useDisclosure()
  const {
    isOpen: materialIsOpen,
    onClose: materialOnClose,
    onOpen: materialOnOpen,
  } = useDisclosure()

  const [createTopic, { loading: topicIsSubmitting }] = useMutation(
    CREATE_TOPIC
  )

  const [createAssignment, { loading: assignmentIsSubmitting }] = useMutation(
    CREATE_ASSIGNMENT
  )
  const [createAttachment, { loading: attachmentIsSubmitting }] = useMutation(
    CREATE_ATTACHMENT
  )

  useEffect(() => {
    getClassTopics()
  }, [])

  useEffect(() => {
    const initialPreference = (classUser?.role === 'student' &&
      user?.learningStyle &&
      user?.learningStyle?.length > 1 &&
      user?.learningStyle) || ['visual', 'aural', 'read', 'kinesthetic']
    setPreference(initialPreference)
  }, [user, classUser])

  const topNavLeft = [{ type: 'text' as const, text: classUser?.class?.name }]

  const baseTopNavCenter = [
    { type: 'link' as const, url: `/classes/${id}`, text: 'Home' },
    {
      type: 'link' as const,
      url: `/classes/${id}/classwork`,
      text: 'Classwork',
    },
  ]

  const topNavCenter = isMobileDevice()
    ? [
        ...baseTopNavCenter,
        {
          type: 'link' as const,
          url: `/classes/${id}/people`,
          text: 'People',
        },
      ]
    : baseTopNavCenter

  const handleTopicFilterOnChange = (checked: boolean, index: number): void => {
    const newTopicFilter = [...topicFilter]
    newTopicFilter[index] = checked
    setTopicFilter(newTopicFilter)
  }

  const handlePreferenceChange = (buttonPreference: string) => {
    if (preference.includes(buttonPreference)) {
      setPreference(preference.filter((value) => value != buttonPreference))
    } else {
      setPreference([...preference, buttonPreference])
    }
  }

  const submitAssignmentAttachments = async (
    assignmentId: string,
    files: File[]
  ) => {
    try {
      return Promise.all(
        files?.map((file) =>
          createAttachment({
            variables: {
              assignmentId,
              file,
            },
          })
        )
      )
    } catch (err) {
      useErrorToast({ message: 'Upload failed' })
      return err
    }
  }

  const handleTopicOnCreate = ({
    topic: { name },
    assignment,
  }: handleTopicOnCreateProps) => {
    try {
      const newTopicId = uuid()
      const newAssignmentId = uuid()
      const mutations = assignment
        ? [
            createTopic({
              variables: {
                topic_id: newTopicId,
                class_id: id,
                name,
              },
            }),
            createAssignment({
              variables: {
                id: newAssignmentId,
                name: assignment?.name,
                description: assignment?.description,
                schedule: assignment?.schedule,
                due_date: assignment?.dueDateStr,
                rubric: assignment?.rubric,
                grading: assignment?.grading,
                topic_id: newTopicId,
                is_visible: assignment?.isVisible,
                user_id: user.id,
                assignment_users: generateAssignmentUsers(
                  assignment?.assignmentUsers
                ),
                add_new_users: true,
                add_new_groups: false,
                assignment_groups: [],
                submissions: [
                  ...generateStudentSubmissions(
                    assignment?.assignmentUsers,
                    newAssignmentId
                  ),
                ],
                class_id: id,
                type: assignment?.type,
              },
            }),
            submitAssignmentAttachments(newAssignmentId, assignment?.files),
          ]
        : [
            createTopic({
              variables: {
                topic_id: newTopicId,
                class_id: id,
                name,
              },
            }),
          ]

      Promise.all(mutations).then(() => {
        getClassTopics()
        topicOnClose()
        useSuccessToast({
          title: 'Topic created',
          message: 'Topic was successfully created',
        })
      })
    } catch (error) {
      useErrorToast({ message: `Topic wasn't created` })
    }
  }

  const isPreferenceActive = (buttonPreference: string): string => {
    return preference.includes(buttonPreference)
      ? 'icon.button'
      : 'icon.button.inactive'
  }

  return (
    <ClassWorkContext.Provider value={classWorkState}>
      <AssignmentModal
        isOpen={assignmentIsOpen}
        onClose={assignmentOnClose}
        type='create'
      />
      <TopicForm
        onClose={topicOnClose}
        onSubmit={handleTopicOnCreate}
        isOpen={topicIsOpen}
        isSubmitting={topicIsSubmitting}
        title='Create Topic'
        submitText='Create'
        submittingText='Creating'
        name=''
        isCreate={true}
      />
      <MaterialModal
        isOpen={materialIsOpen}
        topics={topics}
        students={students}
        materialAssignmentRefetch={materialAssignmentRefetch}
        onClose={materialOnClose}
      />
      <Layout
        showSideNav
        topNavLeft={topNavLeft}
        topNavCenter={topNavCenter}
        redirectNonExistingClass
        hasRightNav={routes.classwork.hasRightNav}
      >
        <Container
          maxW={{ base: '100%', md: '520px', lg: '520px', xl: '668px' }}
          py={{ base: '24px', lg: '32px' }}
          px={{ base: '16px', lg: '32px' }}
          h='auto'
        >
          <Flex direction='column' w='100%' h='100%'>
            {pendingClassReviews && pendingClassReviews.exams.length > 0 && (
              <ExamIndicators
                exams={pendingClassReviews.exams}
                containerProps={{
                  mb: '40px',
                }}
              />
            )}

            <VStack spacing={10} h='100%'>
              <Flex
                w='100%'
                justify={
                  ['admin', 'teacher', 'creator'].includes(classUser?.role)
                    ? 'space-between'
                    : 'center'
                }
                direction={{ base: 'column', xl: 'row' }}
              >
                <RenderForUser roles={['admin', 'teacher', 'creator']}>
                  <Box mb={{ base: '16px', xl: '0' }}>
                    <Menu autoSelect={false} variant='hoverMenu'>
                      <MenuButton
                        as={Button}
                        variant='base.primary'
                        textStyle='button'
                        width='100%'
                        boxShadow='rgb(0 0 0 / 10%) 0px 4px 6px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px'
                        letterSpacing='0.06rem'
                      >
                        Create
                      </MenuButton>
                      <MenuList>
                        <MenuItem onClick={topicOnOpen}>Topic</MenuItem>
                        <MenuDivider />
                        <MenuItem onClick={materialOnOpen}>Material</MenuItem>
                        <MenuDivider />
                        <MenuItem onClick={assignmentOnOpen}>
                          Assignment
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Box>
                </RenderForUser>

                <HStack spacing={4}>
                  <Flex
                    direction={{ base: 'column', sm: 'row' }}
                    w='100%'
                    justify='space-between'
                  >
                    <Box
                      py='4px'
                      bg='white.1'
                      layerStyle='card.board-updated-shadow'
                      mb={{ base: '16px', md: '0' }}
                      mr={{ base: '0', sm: '16px' }}
                    >
                      <ButtonGroup isAttached size='lg' w='100%'>
                        <Tooltip label='Visual' variant='iconToolTip'>
                          <IconButton
                            aria-label='Select visual learning preference'
                            icon={<VisualIcon />}
                            variant={isPreferenceActive('visual')}
                            borderRight='1px solid'
                            borderColor='grey.border'
                            fontSize='20px'
                            w={{ base: '100%', sm: 'calc(288px/4)' }}
                            h='40px'
                            onClick={() => handlePreferenceChange('visual')}
                          />
                        </Tooltip>
                        <Tooltip label='Aural' variant='iconToolTip'>
                          <IconButton
                            aria-label='Select aural learning preference'
                            icon={<AuralIcon />}
                            variant={isPreferenceActive('aural')}
                            fontSize='20px'
                            borderRight='1px solid'
                            borderColor='grey.border'
                            w={{ base: '100%', sm: 'calc(288px/4)' }}
                            h='40px'
                            onClick={() => handlePreferenceChange('aural')}
                          />
                        </Tooltip>
                        <Tooltip label='Read/Write' variant='iconToolTip'>
                          <IconButton
                            aria-label='Select read learning preference'
                            icon={<Read />}
                            variant={isPreferenceActive('read')}
                            fontSize='20px'
                            borderRight='1px solid'
                            borderColor='grey.border'
                            w={{ base: '100%', sm: 'calc(288px/4)' }}
                            h='40px'
                            onClick={() => handlePreferenceChange('read')}
                          />
                        </Tooltip>
                        <Tooltip label='Kinesthetic' variant='iconToolTip'>
                          <IconButton
                            aria-label='Select kinesthetic learning preference'
                            icon={<Kinesthetic />}
                            variant={isPreferenceActive('kinesthetic')}
                            fontSize='20px'
                            w={{ base: '100%', sm: 'calc(288px/4)' }}
                            h='40px'
                            onClick={() =>
                              handlePreferenceChange('kinesthetic')
                            }
                          />
                        </Tooltip>
                      </ButtonGroup>
                    </Box>

                    <TopicFilter
                      topicFilter={topicFilter}
                      setTopicFilter={setTopicFilter}
                      topics={topics}
                      handleTopicFilterOnChange={handleTopicFilterOnChange}
                      indeterminateTitle='Topics'
                      isTopicCount
                    />
                  </Flex>
                </HStack>
              </Flex>

              <Flex
                w='100%'
                direction='column'
                mt={
                  assignments.length >= 1 || materials.length >= 1
                    ? '2.5rem'
                    : '0 !important'
                }
              >
                <VStack spacing={4} pt={3}>
                  <Assignments
                    assignments={assignments}
                    materialAssignmentRefetch={materialAssignmentRefetch}
                    pendingExams={pendingClassReviews?.exams}
                  />
                  <Materials
                    materials={filterMaterials(materials, preference)}
                    materialAssignmentRefetch={materialAssignmentRefetch}
                  />
                </VStack>
              </Flex>
              <Topics
                topics={topics}
                topicFilter={topicFilter}
                materialAssignmentRefetch={materialAssignmentRefetch}
                preference={preference}
                pendingExams={pendingClassReviews?.exams}
              />
            </VStack>
          </Flex>
        </Container>
      </Layout>
    </ClassWorkContext.Provider>
  )
}

export default ClassWorkPage
