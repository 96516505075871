import { Post } from '../../types'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
  Flex,
  Icon,
  VStack,
  StackDivider,
  Text,
} from '@chakra-ui/react'
import { AnnouncementHeading, PostHeading } from '../Heading'
import { CommentList } from '../Post'
import { FileList } from '../File'
import { useAlamContext } from '../../client'
import { CREATE_POST_COMMENT } from '../../client/queries'
import { CommentForm } from '../Forms'
import { useMutation } from '@apollo/client'
import { v4 as uuid } from 'uuid'
import { useSuccessToast, useErrorToast } from '../../utils'
import { RiPushpin2Fill } from 'react-icons/ri'

export type BulletinModalProps = {
  post: Post
  isOpen: boolean
  onClose: () => void
}

export const BulletinModal = ({
  post,
  isOpen,
  onClose,
}: BulletinModalProps): JSX.Element => {
  const { user: currentUser } = useAlamContext()
  const {
    id,
    type,
    user,
    content,
    inserted_at,
    title,
    comments = [],
    attachments = [],
  } = post
  const [createComment, { loading: commentIsSubmitting }] = useMutation(
    CREATE_POST_COMMENT
  )

  const handleSubmitComment = ({ content }: { content: string }) => {
    createComment({
      variables: {
        id: uuid(),
        content,
        user_id: currentUser.id,
        post_id: id,
      },
    })
      .then(() => {
        useSuccessToast({ message: 'Comment was created' })
      })
      .catch(() => {
        useErrorToast({ message: 'Creating comment failed' })
      })
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size='2xl'
        variant='bulletinModal'
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <VStack
              divider={<StackDivider borderColor='bulletinModalDivider' />}
            >
              <Flex
                align='center'
                w='100%'
                textAlign='left'
                fontFamily="'Nunito Sans', sans-serif"
                fontStyle='normal'
                fontWeight='700'
                letterSpacing='-0.01em'
                fontSize='16px'
                lineHeight='20px'
                padding='16px 16px 8px 24px'
              >
                <Flex mr='16px' align='center' justify='start'>
                  <Icon as={RiPushpin2Fill} />
                </Flex>
                {type === 'post' && (
                  <PostHeading
                    firstName={user.firstName}
                    lastName={user.lastName}
                    inserted_at={inserted_at}
                    type='post'
                    avatar={user.avatar}
                  />
                )}
                {type === 'announcement' && (
                  <AnnouncementHeading
                    type={type}
                    postId={id}
                    inserted_at={inserted_at}
                    title={title}
                    comments={comments.length}
                    {...user}
                    showCommentIcon={false}
                  />
                )}
              </Flex>
              {content && (
                <Flex w='100%' p='8px 16px 8px 24px'>
                  <Text
                    textStyle='subtitle'
                    fontSize='16px'
                    color='black.1'
                    wordBreak='break-word'
                  >
                    {content}
                  </Text>
                </Flex>
              )}
              {attachments.length >= 1 && (
                <Flex w='100%'>
                  <FileList existingFiles={attachments} isReadOnly />
                </Flex>
              )}

              {comments?.length >= 1 && (
                <Flex
                  w='100%'
                  flex='1'
                  height='100%'
                  maxH='280px'
                  overflow='hidden auto'
                >
                  <CommentList comments={comments} isBulletinModal />
                </Flex>
              )}
              <Flex w='100%' p='8px 16px 16px 24px'>
                <CommentForm
                  user={currentUser}
                  isSubmitting={commentIsSubmitting}
                  onSubmit={handleSubmitComment}
                  avatarSize='md'
                />
              </Flex>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
