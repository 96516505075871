import React from 'react'
import { Question } from '../../types'
import {
  Checkbox,
  CheckboxGroup,
  Flex,
  Textarea,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react'

export type ResponseInputProps = {
  question: Question
  answers: string[]
  onChange: (values: string[]) => void
}

export const ResponseInput = ({
  question: { type, response_choices_arrangements },
  answers,
  onChange,
}: ResponseInputProps): JSX.Element => {
  if (type === 'checkbox') {
    return (
      <Flex
        w='100%'
        direction='column'
        border='.5px solid'
        borderColor='primary.2'
        borderRadius='12px'
        py='16px'
        px='16px'
      >
        <CheckboxGroup value={answers} onChange={onChange}>
          <Stack direction='column' spacing='8px'>
            {response_choices_arrangements.map(
              ({ response_choice: { id, text } }) => (
                <Flex
                  key={id}
                  border={answers.includes(text) ? '2px solid' : 'none'}
                  borderColor='accent.1'
                  borderRadius='8px'
                  p={answers.includes(text) ? '14px 18px' : '16px 20px'}
                  w='100%'
                >
                  <Checkbox
                    value={text}
                    sx={{
                      '.chakra-checkbox__control[data-checked]': {
                        bg: 'accent.1',
                      },
                      '.chakra-checkbox__label[data-checked]': {
                        color: 'accent.1',
                        fontWeight: '600',
                      },
                    }}
                  >
                    {text}
                  </Checkbox>
                </Flex>
              )
            )}
          </Stack>
        </CheckboxGroup>
      </Flex>
    )
  }

  if (type === 'multiple_choice') {
    return (
      <Flex
        w='100%'
        direction='column'
        border='.5px solid'
        borderColor='primary.2'
        borderRadius='12px'
        p='16px'
      >
        <RadioGroup
          onChange={(value) => onChange([String(value)])}
          value={answers[0]}
        >
          <Stack direction='column' spacing='8px'>
            {response_choices_arrangements.map(
              ({ response_choice: { id, text } }) => (
                <Flex
                  key={id}
                  border={answers[0] === text ? '2px solid' : 'none'}
                  borderColor='accent.1'
                  borderRadius='8px'
                  p={answers[0] === text ? '14px 18px' : '16px 20px'}
                  w='100%'
                >
                  <Radio value={text} key={id}>
                    <Text
                      lineHeight='24px'
                      fontWeight={answers[0] === text ? '600' : '400'}
                      fontSize='16px'
                    >
                      {text}
                    </Text>
                  </Radio>
                </Flex>
              )
            )}
          </Stack>
        </RadioGroup>
      </Flex>
    )
  }

  return (
    <Textarea
      textStyle='body.1'
      w='100%'
      h='176px'
      value={answers[0]}
      onChange={(e) => onChange([e.target.value])}
      resize='none'
    />
  )
}

export default ResponseInput
