import React, { useState, useEffect, useMemo } from 'react'
import { Flex, Box, Text, IconButton } from '@chakra-ui/react'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'

export const usePagination = (arrayToPaginate: any[]) => {
  const [page, setPage] = useState(1)
  const totalPages = Math.ceil(arrayToPaginate.length / 10)

  const currentPageContent = useMemo(() => {
    const startIndex = (page - 1) * 10

    return arrayToPaginate.slice(startIndex, startIndex + 10)
  }, [arrayToPaginate, page])

  const totalItems = arrayToPaginate.length

  const renderAnalyticsPagination = () => {
    return (
      <Flex align='center' justify='space-between' py={7} mx={6} mb={20}>
        <Text textStyle='body.1' color='rgba(1, 0, 2, 0.56)'>
          Showing {currentPageContent.length} of {totalItems}
        </Text>
        <Flex align='center'>
          <Text textStyle='body.1' color='rgba(1, 0, 2, 0.56)'>
            Page {page} of {totalPages}
          </Text>
          {(page != 1 || page < totalPages) && (
            <Box ml={6}>
              {page !== 1 && (
                <IconButton
                  icon={<MdChevronLeft />}
                  aria-label='previous'
                  variant='icon.button'
                  mr={2}
                  borderWidth='0.5px'
                  borderStyle='solid'
                  borderColor='primary.2'
                  onClick={() => setPage(page - 1)}
                />
              )}
              {page < totalPages && (
                <IconButton
                  icon={<MdChevronRight />}
                  aria-label='next'
                  variant='icon.button'
                  borderWidth='0.5px'
                  borderStyle='solid'
                  borderColor='primary.2'
                  onClick={() => setPage(page + 1)}
                />
              )}
            </Box>
          )}
        </Flex>
      </Flex>
    )
  }

  return {
    page,
    setPage,
    totalPages,
    currentPageContent,
    totalItems,
    renderAnalyticsPagination,
  }
}
