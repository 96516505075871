import { useState } from 'react'
import {
  Flex,
  Text,
  IconButton,
  Input,
  Button,
  Textarea,
  VStack,
  StackDivider,
  Spacer,
  Switch,
  FormErrorMessage,
  FormErrorIcon,
  FormControl,
} from '@chakra-ui/react'
import { MdClose } from 'react-icons/md'
import { useForm } from 'react-hook-form'
import debounce from 'lodash-es/debounce'
import { validateSpaceOnly } from '../../utils'

export interface FeedbackInput {
  content: string
}

export type CreateFeedbackProps = {
  content?: string
  onClose: () => void
  onSubmit: (data: FeedbackInput) => void
  isSubmitting: boolean
  isAnonymous?: boolean
}

export const FeedbackForm = ({
  content = '',
  onClose,
  onSubmit,
  isSubmitting,
  isAnonymous = false,
}: CreateFeedbackProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    errors,
    trigger,
    formState: { isValid },
  } = useForm<FeedbackInput>({
    mode: 'onChange',
    defaultValues: {
      content,
    },
  })

  const handleSubmitForm = (data: FeedbackInput) => {
    onSubmit(data)
  }

  return (
    <Flex
      w='100%'
      layerStyle='card.board'
      bg='white.1'
      direction='column'
      marginBottom={{ base: 'none', sm: '8px' }}
      borderRadius={{ base: 'none', sm: '12px' }}
      boxShadow={{ base: 'none', sm: '0px 4px 8px rgba(23, 5, 61, 0.32)' }}
    >
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <VStack
          spacing={2}
          w='100%'
          divider={<StackDivider layerStyle='divider' />}
        >
          <Flex w='100%' pl={2} pr={6} pt={3} align='center'>
            <IconButton
              variant='icon.button'
              aria-label='Cancel creating post'
              fontSize='1.2rem'
              icon={<MdClose fontSize='1.2rem' />}
              onClick={onClose}
            />
            <Text textStyle='h5' color='primary.1'>
              Give Feedback{isAnonymous && ' (Anonymous)'}
            </Text>
          </Flex>

          <Flex
            direction='column'
            w='100%'
            justify='space-around'
            px={6}
            py={3}
          >
            <VStack spacing={4} align='flex-start'>
              <FormControl isInvalid={!!errors.content?.message}>
                <Textarea
                  placeholder='Type message here'
                  fontSize='16px'
                  lineHeight='24px'
                  border='0.5px solid'
                  borderColor='grey.border'
                  resize='vertical'
                  textStyle='body.1'
                  maxHeight='200px'
                  h='144px'
                  p='1rem'
                  name='content'
                  ref={register({
                    required: 'Message is a required field',
                    maxLength: {
                      value: 1000,
                      message: 'Message exceeded 1000 maximum characters',
                    },
                    validate: {
                      validateSpaceOnly,
                    },
                  })}
                  onChange={debounce(async () => {
                    await trigger('content')
                  }, 500)}
                />
                {errors.content && (
                  <FormErrorMessage>
                    <FormErrorIcon />
                    {errors.content?.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </VStack>
          </Flex>

          <Flex direction='column' w='100%' px={6} pt={3} pb={4}>
            <Flex>
              <Button
                variant='base.primary'
                type='submit'
                textStyle='button'
                minW='72px'
                disabled={!isValid}
                isLoading={isSubmitting}
                loadingText='Submitting'
              >
                Post
              </Button>
            </Flex>
          </Flex>
        </VStack>
      </form>
    </Flex>
  )
}

export default FeedbackForm
