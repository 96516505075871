import React, { useMemo, useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  FormErrorIcon,
  HStack,
  VStack,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { uniqueClassNameValidation, validateSpaceOnly } from '../../utils'

export interface EditClassModalProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (data: EditClassInput) => void
  name: string
  subject?: string
  currentClassNames: string[]
}

export type EditClassInput = {
  className: string
  subject?: string
}

export const EditClass = ({
  isOpen,
  onClose,
  onSubmit,
  name,
  subject = '',
  currentClassNames,
}: EditClassModalProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    errors,
    watch,
    formState: { isValid },
    reset,
  } = useForm<EditClassInput>({
    mode: 'onChange',
    defaultValues: {
      className: name,
      subject: subject,
    },
    shouldUnregister: true,
  })

  useEffect(() => {
    reset({ className: name, subject: subject })
  }, [name, subject])

  const { className, subject: formSubject } = watch()

  const validateClassName = (className: string) => {
    if (className === name) return true
    return uniqueClassNameValidation(className, currentClassNames)
  }

  const isDisabled = (className === name && formSubject === subject) || !isValid

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW='29rem'>
        <ModalHeader>Edit Class</ModalHeader>

        <ModalCloseButton />

        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <VStack spacing={4}>
              <FormControl isInvalid={!!errors.className?.message}>
                <FormLabel variant='required'>Class Name</FormLabel>
                <Input
                  name='className'
                  ref={register({
                    required: 'Class name is a required field',
                    maxLength: {
                      value: 255,
                      message: 'Name exceeded 255 maximum characters',
                    },
                    validate: {
                      validateClassName,
                      validateSpaceOnly,
                    },
                  })}
                />
                {errors.className && (
                  <FormErrorMessage>
                    <FormErrorIcon />
                    {errors.className?.message}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl
                css={{
                  '& .optional::after': {
                    letterSpacing: '0',
                  },
                }}
              >
                <FormLabel variant='optional' className='optional'>
                  Subject
                </FormLabel>
                <Input name='subject' ref={register()} />
              </FormControl>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing={4}>
              <Button textStyle='button' variant='base.white' onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant='base.primary'
                type='submit'
                disabled={isDisabled}
                textStyle='button'
              >
                Save
              </Button>
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

export default EditClass
