import React from 'react'
import { useParams } from 'react-router-dom'
import AssignmentPage from './AssignmentPage'

const Assignment = (): JSX.Element => {
  const { id, assignmentId, group_id } = useParams<{
    id: string
    assignmentId: string
    group_id?: string
  }>()
  return (
    <div>
      <AssignmentPage id={id} assignmentId={assignmentId} groupId={group_id} />
    </div>
  )
}

export default Assignment
