import { Group, ClassUser, BasicGroupUser } from '../types'
import { DropdownOption } from './useDropdownCheckbox'
import { getFullName } from './user'

export const findClassUserGroup = (
  classUser: ClassUser,
  groups: Group[]
): Group | undefined => {
  const result = groups.find((group) => {
    const groupUser = group.groupUsersForOptions!.find(
      ({ user_id }: BasicGroupUser) => {
        const result = user_id == classUser?.user?.id
        return result
      }
    )

    return !!groupUser
  })

  return result
}

export const constructStudentOption = (
  classUser: ClassUser,
  groups: Group[]
): DropdownOption => {
  const group = findClassUserGroup(classUser, groups)

  return {
    display: getFullName(classUser.user),
    value: classUser?.user?.id || '',
    isDisabled: !!group,
    subtext: group ? 'Assigned to Group' : '',
  }
}
