import { Menu, Flex, MenuButton, MenuList, IconButton } from '@chakra-ui/react'
import { isLargerThanTablet } from '../../utils'
import { More } from '../Icons'

export type HoverMenuProps = {
  children: any
  top?: number | 'unset'
  left?: number | 'unset'
  right?: number | 'unset'
  bottom?: number | 'unset'
  menuButtonVariant?: string
  icon?: React.ReactElement
}

export const parentHoverCss = {
  position: 'relative',
  '&:hover > .hover-menu': {
    visibility: 'visible',
  },
} as const

export const HoverMenu = ({
  children,
  left = 'unset',
  right = 'unset',
  top = 'unset',
  bottom = 'unset',
  menuButtonVariant = 'icon.button',
  icon = <More width='22' height='22' viewBox='8 8 24 24' />,
}: HoverMenuProps): JSX.Element => {
  return (
    <Flex
      pos='absolute'
      top={top}
      right={right}
      bottom={bottom}
      left={left}
      visibility={isLargerThanTablet() ? 'hidden' : 'visible'}
      className='hover-menu'
      align='center'
    >
      <Menu placement='bottom-end' autoSelect={false} variant='hoverMenu'>
        <MenuButton
          as={IconButton}
          aria-label='Class actions'
          icon={icon}
          variant={menuButtonVariant}
          bg='transparent'
          fontSize='1.2rem'
        />
        <MenuList>
          <>{children}</>
        </MenuList>
      </Menu>
    </Flex>
  )
}

export default HoverMenu
