import { gql } from '@apollo/client'

export const userDisplayFields = gql`
  fragment userDisplayFields on users {
    id
    firstName: first_name
    lastName: last_name
    avatar: photo_url
    is_preference_public
  }
`
