import React, { useEffect } from 'react'
import {
  Button,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  VStack,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  FormErrorMessage,
  FormErrorIcon,
  ModalFooter,
  HStack,
  Text,
  Tooltip,
  StackDivider,
  Textarea,
  SimpleGrid,
  useDisclosure,
} from '@chakra-ui/react'
import {
  truncate,
  validateSpaceOnly,
  useAttachment,
  useDatePicker,
  useFormState,
  useDropdownCheckbox,
  getFullName,
} from '../../utils'
import { MdInfo } from 'react-icons/md'
import { useForm } from 'react-hook-form'
import { v4 as uuid } from 'uuid'
import debounce from 'lodash-es/debounce'
import { Assignment, AssignmentInput } from '../Forms'
import { useClassWorkContext } from '../../pages/ClassWork/ClassWorkContext'
import { useAlamContext } from '../../client'
import {
  Topic,
  AssignmentUser,
  Attachment,
  Group,
  AssignmentGroup,
  ClassUser,
  Exam,
} from '../../types'
import { handleTopicOnCreateProps } from '../../pages/ClassWork/ClassWorkPage'
import { Select } from '../Input'

export interface TopicInput {
  name: string
}

export interface TopicFormProps extends TopicInput {
  assignment?: Assignment
  onClose: () => void
  onSubmit: (args: handleTopicOnCreateProps) => void
  isOpen: boolean
  isSubmitting: boolean
  title: string
  submitText: string
  submittingText: string
  isCreate?: boolean
}

export const TopicForm = ({
  onClose,
  onSubmit,
  isOpen,
  isSubmitting,
  title,
  submitText,
  submittingText,
  name,
  assignment = {
    name: '',
    description: '',
    isVisible: true,
    rubric: 'numerical',
    grading: '100',
    schedule: '',
    topicId: '',
    dueDateStr: undefined,
    assignmentUsers: undefined,
    attachments: [],
    assignmentGroups: undefined,
    exams: [],
  },
  isCreate = false,
}: TopicFormProps): JSX.Element => {
  const { classUsers } = useClassWorkContext()
  const { classUser } = useAlamContext()

  const {
    register,
    handleSubmit,
    errors,
    trigger,
    formState: { isValid },
  } = useForm<{ name: string; assignment?: Partial<AssignmentInput> }>({
    mode: 'all',
    shouldUnregister: true,
  })

  const {
    formState: { rubric, grading, topicId },
    handleInputChange,
    resetFormState,
  } = useFormState({
    rubric: assignment.rubric,
    grading: assignment.grading,
  })

  const rubricOptions = [
    { value: 'numerical', display: 'Numerical' },
    { value: 'alphabetical', display: 'Alphabetical' },
  ]

  const {
    removedAttachments,
    newAttachments,
    renderFileList,
    renderAttachButton,
    renderAttachmentError,
    resetAttachments,
  } = useAttachment(assignment?.attachments || [])

  const {
    isOpen: showPretopic,
    onOpen: onOpenPretopic,
    onClose: onClosePretopic,
    onToggle: onTogglePretopic,
  } = useDisclosure()

  const resetForm = () => {
    if (isCreate) {
      onClose()
      onClosePretopic()
      resetFormState()
      resetAttachments()
    }
  }

  const onFormSubmit = async (data: any) => {
    if (data.assignment && showPretopic) {
      const assignmentUsers = classUsers.map(({ user }) => user?.id)
      const assignmentData = {
        ...data.assignment,
        assignmentUsers,
        files: newAttachments,
        isVisible: true,
        removedFiles: removedAttachments,
        dueDate: undefined,
        schedule: new Date().toISOString(),
        rubric,
        grading,
        withAssignmentReview: false,
        withLearningReview: false,
        type: 'pretopic',
      }
      await onSubmit({ topic: { name: data.name }, assignment: assignmentData })
    } else {
      await onSubmit({ topic: { name: data.name } })
    }

    resetForm()
  }

  useEffect(() => {
    if (assignment.name) {
      onOpenPretopic()
    }
  }, [])

  useEffect(() => {
    if (rubric === 'alphabetical') {
      handleInputChange('grading', '')
    } else {
      handleInputChange('grading', assignment.grading || '100')
    }
  }, [rubric])

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size='3xl'
        variant='pretopicWithDivider'
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>

          <ModalCloseButton />

          <form onSubmit={handleSubmit(onFormSubmit)}>
            <ModalBody padding='0px 0px 32px 0px'>
              <VStack spacing={4}>
                <VStack
                  w='100%'
                  padding='1.5rem'
                  paddingBottom='0.5rem'
                  spacing={5}
                >
                  <FormControl isInvalid={!!errors.name?.message}>
                    <FormLabel variant='required'>Title</FormLabel>
                    <Input
                      name='name'
                      defaultValue={name}
                      ref={register({
                        required: 'Topic title is a required field',
                        minLength: {
                          value: 2,
                          message: 'Title must be longer than 1 character',
                        },
                        maxLength: {
                          value: 100,
                          message: 'Title exceeded 100 maximum characters',
                        },
                        validate: {
                          validateSpaceOnly,
                        },
                      })}
                      onChange={debounce(async () => {
                        await trigger('name')
                      }, 500)}
                    />
                    {errors?.name && (
                      <FormErrorMessage>
                        <FormErrorIcon />
                        {errors.name?.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>

                  <Flex
                    w='100%'
                    direction='row'
                    justify='flex-start'
                    align='flex-start'
                  >
                    <Checkbox
                      isChecked={showPretopic}
                      onChange={onTogglePretopic}
                    >
                      <HStack spacing='10px' align='flex-end' p='0px' ml='-6px'>
                        <Text align='center'>Create a pre-topic test</Text>
                        <Tooltip
                          placement='right'
                          label="Checking this box will allow you to add a pre-topic test to assess the student's knowledge of the topic."
                          bg='black.1'
                          borderRadius='8px'
                          fontSize='12px'
                          color='white.1'
                          lineHeight='20px'
                          padding='8px 16px'
                          letterSpacing='-0.01em'
                        >
                          <span>
                            <Icon
                              as={MdInfo}
                              boxSize='20px'
                              color='primary.2'
                              _hover={{
                                color: 'primary.1',
                              }}
                            />
                          </span>
                        </Tooltip>
                      </HStack>
                    </Checkbox>
                  </Flex>
                </VStack>

                {showPretopic && (
                  <>
                    <Flex
                      direction='row'
                      padding='16px 16px 16px 24px'
                      align='center'
                      fontWeight='700'
                      h='64px'
                      w='100%'
                      bg='grey.2'
                      fontSize='16px'
                      lineHeight='21px'
                      color='primary.1'
                    >
                      Pre-topic test
                    </Flex>
                    <VStack
                      spacing={4}
                      w='100%'
                      divider={<StackDivider layerStyle='divider' />}
                      align='flex-start'
                      padding='1.5rem'
                      paddingTop='0px'
                      paddingBottom='0.5rem'
                    >
                      <VStack spacing={4} w='100%' align='flex-start'>
                        <Input
                          name='assignment.classId'
                          ref={register()}
                          type='hidden'
                          defaultValue={classUser?.class?.id}
                        />

                        <FormControl
                          isInvalid={!!errors.assignment?.name?.message}
                        >
                          <FormLabel variant='required'>Title</FormLabel>
                          <Input
                            name='assignment.name'
                            defaultValue={assignment?.name}
                            ref={register({
                              required:
                                showPretopic &&
                                'Assignment title is a required field',
                              minLength: {
                                value: 2,
                                message:
                                  'Title must be longer than 1 character',
                              },
                              maxLength: {
                                value: 100,
                                message:
                                  'Title exceeded 100 maximum characters',
                              },
                              validate: {
                                validateSpaceOnly,
                              },
                            })}
                            onChange={debounce(async () => {
                              await trigger('assignment[name]')
                            }, 500)}
                          />
                          {errors?.assignment?.name && (
                            <FormErrorMessage>
                              <FormErrorIcon />
                              {errors.assignment.name?.message}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl
                          isInvalid={!!errors.assignment?.description?.message}
                          css={{
                            '& .optional::after': {
                              letterSpacing: '0',
                            },
                          }}
                        >
                          <FormLabel variant='optional' className='optional'>
                            Description
                          </FormLabel>
                          <Textarea
                            resize='vertical'
                            textStyle='body.1'
                            maxHeight='200px'
                            h='144px'
                            name='assignment.description'
                            defaultValue={assignment?.description}
                            ref={register({
                              maxLength: {
                                value: 1000,
                                message:
                                  'Description exceeded 1000 maximum characters',
                              },
                              validate: {
                                validateSpaceOnly,
                              },
                            })}
                            onChange={debounce(async () => {
                              await trigger('assignment[description]')
                            }, 500)}
                          />
                          {errors?.assignment?.description && (
                            <FormErrorMessage>
                              <FormErrorIcon />
                              {errors.assignment.description?.message}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        {renderAttachButton()}
                        {renderAttachmentError()}
                      </VStack>

                      {renderFileList({ padding: '0px' })}

                      <VStack w='100%' spacing={8}>
                        <SimpleGrid
                          spacing={8}
                          columns={{ base: 1, md: 3 }}
                          w='100%'
                        >
                          <FormControl
                            isInvalid={!!errors.assignment?.rubric?.message}
                          >
                            <FormLabel color='primary.1'>Rubric</FormLabel>
                            <Select
                              value={rubric}
                              onChange={(value) =>
                                handleInputChange('rubric', value)
                              }
                              options={rubricOptions}
                              menuButtonProps={{
                                textStyle: 'body.1',
                                color: 'primary.1',
                                w: '100%',
                              }}
                              menuItemProps={{
                                textStyle: 'body.1',
                                color: 'primary.1',
                                borderWidth: '0px',
                              }}
                              menuListProps={{
                                borderWidth: '0px',
                              }}
                            />
                          </FormControl>

                          <FormControl
                            visibility={
                              rubric === 'numerical' ? 'visible' : 'hidden'
                            }
                            display={{
                              base: rubric === 'numerical' ? 'block' : 'none',
                              md: 'block',
                            }}
                          >
                            <FormLabel color='primary.1'>Grading</FormLabel>
                            <Input
                              name='grading'
                              textStyle='body.1'
                              value={grading}
                              onChange={(e) =>
                                handleInputChange('grading', e.target.value)
                              }
                              color='primary.1'
                            />
                          </FormControl>
                        </SimpleGrid>
                      </VStack>
                    </VStack>
                  </>
                )}
              </VStack>
            </ModalBody>

            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  variant='base.primary'
                  type='submit'
                  disabled={!isValid}
                  textStyle='button'
                  loadingText={submittingText}
                  isLoading={isSubmitting}
                >
                  {submitText}
                </Button>
              </HStack>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
}

export default TopicForm
