import React from 'react'
import { IconAlamProps } from './Type'

export const UserProfile = ({
  fill,
  width,
  height,
  viewBox,
}: IconAlamProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10 11H30C31.05 11 32 11.95 32 13V27C32 28.05 31.05 29 30 29H10C8.95 29 8 28.05 8 27V13C8 11.95 8.95 11 10 11ZM22 14V15H30V14H22ZM22 16V17H29.5H30V16H22ZM22 18V19H29V18H22ZM16 21.91C14 21.91 10 23 10 25V26H22V25C22 23 18 21.91 16 21.91ZM16 14C15.2044 14 14.4413 14.3161 13.8787 14.8787C13.3161 15.4413 13 16.2044 13 17C13 17.7956 13.3161 18.5587 13.8787 19.1213C14.4413 19.6839 15.2044 20 16 20C16.7956 20 17.5587 19.6839 18.1213 19.1213C18.6839 18.5587 19 17.7956 19 17C19 16.2044 18.6839 15.4413 18.1213 14.8787C17.5587 14.3161 16.7956 14 16 14Z'
      fill={fill}
    />
  </svg>
)

UserProfile.defaultProps = {
  fill: '#17053D',
  width: '40',
  height: '40',
  viewBox: '0 0 40 40',
}
