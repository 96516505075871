import { Flex, Text, Divider } from '@chakra-ui/react'

export type SidebarSubHeadingProps = {
  title: string
  containerProps?: Record<string, any>
}

export const SidebarSubHeading = ({
  title,
  containerProps = {},
}: SidebarSubHeadingProps): JSX.Element => (
  <Flex p='0px 0px 8px 16px' align='center' {...containerProps}>
    <Text textStyle='caption' color='grey.1'>
      {title}
    </Text>
    <Divider color='primary.2' opacity='1' borderWidth='.5px' ml='16px' />
  </Flex>
)

export default SidebarSubHeading
