import React from 'react'
import {
  HStack,
  Box,
  Icon,
  Text,
  Button,
  IconButton,
  Flex,
} from '@chakra-ui/react'
import { Warning } from '../Icons/Warning'
import { MdChevronRight } from 'react-icons/md'

export type IndicatorProps = {
  onClick: () => void
  title: string
  subtitle?: string
  type?: 'icon' | 'button'
}

export const Indicator = ({
  onClick,
  title,
  subtitle,
  type,
}: IndicatorProps): JSX.Element => {
  return (
    <HStack
      borderRadius='8px'
      border='0.5px solid rgba(23, 5, 61, 0.32)'
      bg='#FF843F'
      padding='8px'
      width='100%'
      boxShadow='0px 4px 16px rgba(23, 5, 61, 0.08)'
      spacing={4}
      alignItems='center'
      justify='space-between'
    >
      <HStack alignItems='center'>
        <Flex marginLeft='11px'>
          <Icon as={Warning} color='primary.1' fontSize='18px' />
        </Flex>
        <Flex direction='column' align='flex-start' mr='4px'>
          <Text
            color='primary.1'
            fontWeight='700'
            fontSize='14px'
            lineHeight='22px'
          >
            {title}
          </Text>

          {subtitle && <Text textStyle='body.1'>{subtitle}</Text>}
        </Flex>
      </HStack>

      {type === 'button' ? (
        <Button
          width='72px'
          minW='72px'
          height='32px'
          borderRadius='8px'
          bg='primary.1'
          padding='16px'
          onClick={onClick}
          fontSize='10px'
          lineHeight='16px'
          textTransform='uppercase'
          color='#FFFFFF'
          textAlign='center'
          letterSpacing='1.5px'
        >
          Review
        </Button>
      ) : (
        <IconButton
          icon={<MdChevronRight />}
          aria-label='view pending review'
          variant='icon.button'
          onClick={onClick}
          bg='none'
          fontSize='20px'
          border='none'
        />
      )}
    </HStack>
  )
}

export default Indicator
