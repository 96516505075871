import React, { useState, useEffect } from 'react'
import {
  IconButton,
  Flex,
  InputRightElement,
  Input,
  InputGroup,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react'
import { BiChevronUp, BiChevronDown } from 'react-icons/bi'
import { Question, ResponseChoicesArrangement } from '../../types'
import { MdClose } from 'react-icons/md'
import {
  removeResponseChoice,
  updateResponseChoice,
  validateResponseQuestion,
  moveResponseChoiceUp,
  moveResponseChoiceDown,
} from '../../utils'

export type ResponseChoiceInputProps = {
  responseChoiceArrangement: ResponseChoicesArrangement
  question: Question
  updateResponseChoice: updateResponseChoice
  removeResponseChoice: removeResponseChoice
  moveResponseChoiceUp: moveResponseChoiceUp
  moveResponseChoiceDown: moveResponseChoiceDown
}

export const ResponseChoiceInput = ({
  responseChoiceArrangement,
  question,
  updateResponseChoice,
  removeResponseChoice,
  moveResponseChoiceUp,
  moveResponseChoiceDown,
}: ResponseChoiceInputProps): JSX.Element => {
  const [error, setError] = useState<boolean | string>(false)
  const { order, response_choice } = responseChoiceArrangement
  const { response_choices_arrangements } = question
  const questionId = question.id || ''
  const responseChoiceId = response_choice.id || ''

  useEffect(() => {
    if (response_choice.text) {
      validateResponseQuestion({ value: response_choice.text })
    }
  }, [])

  const handleOnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(validateResponseQuestion({ value: e.target.value }))
    updateResponseChoice({
      questionId,
      responseChoiceId,
      text: e.target.value,
    })
  }

  return (
    <Flex my='8px' align='center' pr='16px'>
      <Flex direction='column'>
        <IconButton
          aria-label='move question up'
          variant='icon.button'
          icon={<BiChevronUp />}
          onClick={() => {
            moveResponseChoiceUp({
              questionId,
              responseChoiceId,
            })
          }}
          disabled={order === 0}
          w='32px'
          h='32px'
        />

        <IconButton
          aria-label='move question down'
          variant='icon.button'
          icon={<BiChevronDown />}
          onClick={() =>
            moveResponseChoiceDown({
              questionId,
              responseChoiceId,
            })
          }
          disabled={order === response_choices_arrangements.length - 1}
          w='32px'
          h='32px'
        />
      </Flex>

      <FormControl isInvalid={!!error}>
        <InputGroup>
          <Input
            value={response_choice.text}
            bg='white.1'
            onChange={handleOnChangeInput}
          />

          <InputRightElement>
            <IconButton
              icon={<MdClose />}
              aria-label={`Remove response option ${response_choice.text}`}
              bg='transparent'
              _hover={{ bg: 'transparent' }}
              onClick={() =>
                removeResponseChoice({
                  questionId,
                  responseChoiceId,
                })
              }
            />
          </InputRightElement>
        </InputGroup>

        {!!error && (
          <FormErrorMessage>
            <FormErrorIcon />
            {error}
          </FormErrorMessage>
        )}
      </FormControl>
    </Flex>
  )
}
