import React from 'react'
import { Flex } from '@chakra-ui/react'
import { ComingSoon } from '../ComingSoon/ComingSoon'

export const People = (): JSX.Element => {
  return (
    <Flex
      direction='column'
      overflow='hidden'
      h='containerHeight'
      minW='248px'
      pos='sticky'
      top='64px'
    >
      <ComingSoon />
    </Flex>
  )
}

export default People
