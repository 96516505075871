import React, { useReducer, useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import {
  UPDATE_SUBMISSION,
  UPDATE_SUBMISSION_GRADE,
} from '../../client/queries'
import {
  TeacherSubmission,
  StudentSubmission,
} from '../../components/Submission'
import { RenderForUser } from '../../components/SharedComponents'
import { reducer, initialState } from './submissionReducer'
import { getFirstAndLastInitial, useErrorToast } from '../../utils'
import SubmissionContext from './SubmissionContext'
import { Exam, Submission } from '../../types'
import { v4 as uuid } from 'uuid'
import { useAlamContext } from '../../client'
import { AssignmentType } from './AssignmentPage'
import { Container } from '@chakra-ui/react'
import { BiChevronLeft } from 'react-icons/bi'
import { useHistory } from 'react-router-dom'
import { Layout } from '../../components/Layout'
import { TopNavItemProps } from '../../components/TopNav'
import { isLargerThanTablet } from '../../utils'
import { Loader } from '../../components/SharedComponents'
import { ExamIndicators } from '../../components/Exam'

export type SubmissionPageProps = {
  assignment?: AssignmentType
  assignmentId: string
  classId: string
  assignmentNav: TopNavItemProps[]
  groupId?: string
  loading: boolean[]
  pendingExams: Exam[]
}
export type handleUpdateSubmissionProps = (
  changes: {
    status: string
    gradeId?: string
  },
  callback?: (response: any) => void
) => void

export type handleUpdateSubmissionGradeProps = (
  changes: { status: string; grade?: string },
  callback?: () => void
) => void

const SubmissionPage = ({
  assignment,
  assignmentId,
  classId,
  assignmentNav,
  groupId,
  loading,
  pendingExams,
}: SubmissionPageProps): JSX.Element => {
  const screenIsLargerThanTablet = isLargerThanTablet()
  const { user, classUser, setChatIsOpen, chatIsOpen } = useAlamContext()
  const [submissionStatus, setStatus] = useReducer(reducer, initialState)
  const [selectedSubmission, setSelectedSubmission] = useState<Submission>()

  const [updateSubmission, { loading: submissionIsUpdating }] = useMutation(
    UPDATE_SUBMISSION
  )

  const [
    updateSubmissionGrade,
    { loading: submissionGradeIsUpdating },
  ] = useMutation(UPDATE_SUBMISSION_GRADE)

  // Function for updating selected submission
  const handleUpdateSubmission: handleUpdateSubmissionProps = (
    { status, gradeId },
    callback = () => {}
  ) => {
    updateSubmission({
      variables: {
        submission_id: selectedSubmission?.id,
        status,
        event_log_id: uuid(),
        user_id: user?.id,
        grade_id: gradeId ? gradeId : null,
        group_id: groupId,
      },
    })
      .then((response) => {
        callback(response)
      })
      .catch(() => {
        useErrorToast({})
      })
  }

  // Function for updating grade/status
  const handleUpdateSubmissionGrade: handleUpdateSubmissionGradeProps = (
    { grade, status },
    callback = () => {}
  ) => {
    updateSubmissionGrade({
      variables: {
        submission_id: selectedSubmission?.id,
        status,
        event_log_id: uuid(),
        user_id: user?.id,
        grade,
        grade_id: uuid(),
        group_id: groupId,
      },
    })
      .then(() => {
        callback()
      })
      .catch(() => {
        useErrorToast({})
      })
  }

  const submissionState = {
    assignmentId,
    groupId,
    assignment,
    selectedSubmission,
    setSelectedSubmission,
    submissionStatus,
    handleUpdateSubmission,
    submissionIsUpdating,
    setStatus,
    handleUpdateSubmissionGrade,
    submissionGradeIsUpdating,
  }

  const history = useHistory()

  const renderTopNavLeft = () => {
    // back for teacher mobile clears selected submission
    if (
      ['teacher', 'admin', 'creator'].includes(classUser?.role) &&
      selectedSubmission?.id &&
      !screenIsLargerThanTablet
    ) {
      const { user } = selectedSubmission
      return [
        {
          type: 'textWithIconButton' as const,
          text: getFirstAndLastInitial(user),
          icon: <BiChevronLeft />,
          onClick: () => setSelectedSubmission(undefined),
        },
      ]
    } else if (groupId) {
      return [
        {
          type: 'textWithIconButton' as const,
          text: assignment?.topic?.name,
          icon: <BiChevronLeft />,
          onClick: () =>
            history.push(`/classes/${classId}/groups/${groupId}/groupwork`),
        },
      ]
    } else {
      return [
        {
          type: 'textWithIconButton' as const,
          text: assignment?.topic?.name,
          icon: <BiChevronLeft />,
          onClick: () => history.push(`/classes/${classId}/classwork`),
        },
      ]
    }
  }

  const isPretopic = assignment?.type === 'pretopic'

  const renderAssignmentNav = (assignmentNav: TopNavItemProps[]) => {
    if (screenIsLargerThanTablet) {
      return assignmentNav
    }

    if (!selectedSubmission) {
      return assignmentNav
    }

    return undefined
  }

  const [previousChatIsOpen] = useState(chatIsOpen)

  useEffect(() => {
    if (classUser?.role !== 'student') {
      setChatIsOpen(true)
    }

    return () => {
      setChatIsOpen(previousChatIsOpen)
    }
  }, [classUser])

  return (
    <Layout
      showSideNav={screenIsLargerThanTablet}
      topNavLeft={renderTopNavLeft()}
      assignmentNav={renderAssignmentNav(assignmentNav)}
      hasRightNav={
        !(classUser?.role === 'student' && assignment?.type !== 'pretopic')
      }
      isPretopic={isPretopic}
      redirectNonExistingClass
    >
      <Loader loaders={loading}>
        <Container
          maxWidth={{ base: '100%', md: '456px', lg: '500px', xl: '668px' }}
          py={6}
          h='auto'
          px={{ base: '0px', sm: '16px' }}
        >
          {pendingExams.length > 0 && <ExamIndicators exams={pendingExams} />}

          <SubmissionContext.Provider value={submissionState}>
            <RenderForUser roles={['student']}>
              <StudentSubmission />
            </RenderForUser>

            <RenderForUser roles={['admin', 'teacher', 'creator']}>
              <TeacherSubmission />
            </RenderForUser>
          </SubmissionContext.Provider>
        </Container>
      </Loader>
    </Layout>
  )
}

export default SubmissionPage
