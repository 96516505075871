import {
  StudentWithGradesData,
  GroupWithGradesData,
  StudentWithCompletionData,
  GroupWithCompletionData,
  AnalyticsUserData,
  TextQuestionData,
  ChoiceStudentData,
} from './index'

type studentsAnalyticsData =
  | StudentWithGradesData[]
  | StudentWithCompletionData[]

type groupAnalyticsData = GroupWithGradesData[] | GroupWithCompletionData[]

const sortByNameAZ = (students: studentsAnalyticsData) => {
  return [...students].sort((user1, user2) => {
    const user1FirstName = user1.first_name.toLowerCase()
    const user2FirstName = user2.first_name.toLowerCase()
    const user1LastName = user1.last_name.toLowerCase()
    const user2LastName = user2.last_name.toLowerCase()

    if (user1FirstName < user2FirstName) {
      return -1
    } else if (user1FirstName > user2FirstName) {
      return 1
    } else if (user1LastName < user2LastName) {
      return -1
    } else if (user1LastName > user2LastName) {
      return 1
    } else {
      return 0
    }
  })
}

export const sortStudentDataAZ = (students: AnalyticsUserData[]) => {
  return [...students].sort((user1, user2) => {
    const user1FirstName = user1.firstName.toLowerCase()
    const user2FirstName = user2.firstName.toLowerCase()
    const user1LastName = user1.lastName.toLowerCase()
    const user2LastName = user2.lastName.toLowerCase()

    if (user1FirstName < user2FirstName) {
      return -1
    } else if (user1FirstName > user2FirstName) {
      return 1
    } else if (user1LastName < user2LastName) {
      return -1
    } else if (user1LastName > user2LastName) {
      return 1
    } else {
      return 0
    }
  })
}

export const sortStudentData = (
  students: studentsAnalyticsData,
  sortBy: string
): studentsAnalyticsData => {
  if (!students) {
    return []
  }

  const sortedAZ = sortByNameAZ(students)

  if (sortBy == 'z-a') return sortedAZ.reverse()
  return sortedAZ
}

const sortGroupByNameAZ = (groups: groupAnalyticsData) => {
  return [...groups].sort((group1, group2) => {
    const name1 = group1.name
    const name2 = group2.name

    if (name1 < name2) {
      return -1
    } else if (name1 > name2) {
      return 1
    } else {
      return 0
    }
  })
}

export const sortGroupData = (
  groups: groupAnalyticsData,
  sortBy: string
): groupAnalyticsData => {
  if (!groups) {
    return []
  }

  const sortedAZ = sortGroupByNameAZ(groups)

  if (sortBy == 'z-a') return sortedAZ.reverse()
  return sortedAZ
}

export const sortStudentQuestionData = (
  students: ChoiceStudentData[],
  sortBy: string
): ChoiceStudentData[] => {
  if (!students) {
    return []
  }

  const sortedAZ = [...students].sort((data1, data2) => {
    const user1 = data1.user!
    const user2 = data2.user!

    const user1FirstName = user1.firstName!.toLowerCase()
    const user2FirstName = user2.firstName!.toLowerCase()
    const user1LastName = user1.lastName!.toLowerCase()
    const user2LastName = user2.lastName!.toLowerCase()

    if (user1FirstName < user2FirstName) {
      return -1
    } else if (user1FirstName > user2FirstName) {
      return 1
    } else if (user1LastName < user2LastName) {
      return -1
    } else if (user1LastName > user2LastName) {
      return 1
    } else {
      return 0
    }
  })

  if (sortBy == 'z-a') return sortedAZ.reverse()
  return sortedAZ
}

export const sortStudentTextQuestionData = (
  students: TextQuestionData[],
  sortBy: string
): TextQuestionData[] => {
  if (!students) {
    return []
  }

  const sortedAZ = [...students].sort((data1, data2) => {
    const user1 = data1.user!
    const user2 = data2.user!

    const user1FirstName = user1.firstName!.toLowerCase()
    const user2FirstName = user2.firstName!.toLowerCase()
    const user1LastName = user1.lastName!.toLowerCase()
    const user2LastName = user2.lastName!.toLowerCase()

    if (user1FirstName < user2FirstName) {
      return -1
    } else if (user1FirstName > user2FirstName) {
      return 1
    } else if (user1LastName < user2LastName) {
      return -1
    } else if (user1LastName > user2LastName) {
      return 1
    } else {
      return 0
    }
  })

  if (sortBy == 'z-a') return sortedAZ.reverse()
  return sortedAZ
}
