import {
  Flex,
  VStack,
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  FormErrorMessage,
  FormErrorIcon,
} from '@chakra-ui/react'
import debounce from 'lodash-es/debounce'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useAlamContext } from '../../client'
import { Session } from '../../types'
import { RenderForUser } from '../SharedComponents'
import { validateSpaceOnly } from '../../utils'

export type SessionInput = {
  name: string
  description?: string
}

export type SessionInfoProps = {
  session: Session
  handleUpdateSession: (changes: SessionInput) => void
  isSubmitting: boolean
}

export const SessionInfo = ({
  session,
  isSubmitting,
  handleUpdateSession,
}: SessionInfoProps): JSX.Element => {
  const { name, description, user_id } = session
  const { user } = useAlamContext()
  const readOnly = user?.id !== user_id
  const {
    register,
    handleSubmit,
    errors,
    trigger,
    setValue,
    watch,
    formState: { isValid },
  } = useForm<SessionInput>({
    mode: 'onChange',
    defaultValues: {
      name,
      description,
    },
  })

  useEffect(() => {
    setValue('name', name)
    setValue('description', description)
  }, [name, description])

  const formValues = watch()

  const hasChanged = useMemo((): boolean => {
    const result = Object.keys(formValues).some((key) => {
      const formProp = formValues[key as keyof SessionInput]
      const sessionProp = session[key as keyof SessionInput]
      if (!formProp && !sessionProp) return false
      return formProp !== sessionProp
    })
    return result
  }, [user, formValues])

  const isFormValid = useMemo((): boolean => {
    return isValid && hasChanged
  }, [isValid, hasChanged])

  return (
    <form
      onSubmit={handleSubmit(handleUpdateSession)}
      style={{ width: '100%' }}
    >
      <Box w='100%' layerStyle='card.board-updated-shadow' bg='white.1'>
        <Flex align='center' p={6}>
          <Heading textStyle='h3' color='primary.1' w='100%'>
            Session Info
          </Heading>

          <RenderForUser userIds={[user_id]}>
            <Button
              variant='base.primary'
              type='submit'
              textStyle='button'
              disabled={!isFormValid}
              loadingText='Saving'
              isLoading={isSubmitting}
            >
              Save
            </Button>
          </RenderForUser>
        </Flex>

        <Box px={6} pb={6}>
          <VStack spacing={4}>
            <FormControl isInvalid={!!errors.name?.message}>
              <FormLabel
                variant='required'
                letterSpacing='0.1rem'
                fontWeight='600'
              >
                Session Name
              </FormLabel>
              <Input
                textStyle='body.1'
                border='0.5px solid'
                borderColor='grey.border'
                letterSpacing='0'
                fontSize='16px'
                name='name'
                readOnly={readOnly}
                ref={register({
                  required: 'Session name is a required field',
                  maxLength: {
                    value: 255,
                    message: 'Session name exceeded 255 maximum characters',
                  },
                  validate: {
                    validateSpaceOnly,
                  },
                })}
                onChange={debounce(async () => {
                  await trigger('name')
                }, 500)}
              />

              {errors?.name && (
                <FormErrorMessage>
                  <FormErrorIcon />
                  {errors.name?.message}
                </FormErrorMessage>
              )}
            </FormControl>

            <FormControl
              isInvalid={!!errors.description?.message}
              css={{
                '& .optional::after': {
                  letterSpacing: '0',
                },
              }}
            >
              <FormLabel
                variant='optional'
                className='optional'
                letterSpacing='0.1rem'
                fontWeight='600'
              >
                Description
              </FormLabel>
              <Input
                name='description'
                border='0.5px solid'
                borderColor='grey.border'
                letterSpacing='0'
                fontSize='16px'
                readOnly={readOnly}
                textStyle='body.1'
                ref={register({
                  maxLength: {
                    value: 255,
                    message:
                      'Session description exceeded 255 maximum characters',
                  },
                })}
                onChange={debounce(async () => {
                  await trigger('description')
                }, 500)}
              />

              {errors?.description && (
                <FormErrorMessage>
                  <FormErrorIcon />
                  {errors.description?.message}
                </FormErrorMessage>
              )}
            </FormControl>
          </VStack>
        </Box>
      </Box>
    </form>
  )
}

export default SessionInfo
