import React, { useEffect, useState } from 'react'
import { useDisclosure } from '@chakra-ui/react'
import { Loader } from '../../components/SharedComponents'
import { useParams, useHistory } from 'react-router-dom'
import { useMutation, useQuery, useSubscription } from '@apollo/client'
import {
  USE_TOKEN,
  GET_TOKEN_FROM_CODE,
  GET_USER_CLASSES,
  GetUserClassType,
} from '../../client/queries'
import { Token } from '../../types'
import { redirectToFrontEnd, useErrorToast, useSuccessToast } from '../../utils'
import { useAlamContext } from '../../client'
import { ShowMessageModal, ShowMessageModalProps } from '../../components/Modal'

export const ClassInviteToken = (): JSX.Element => {
  const [token, setToken] = useState<Token | null>(null)
  const [
    errorMessage,
    setErrorMessage,
  ] = useState<ShowMessageModalProps | null>(null)
  const [classIds, setClassIds] = useState<string[]>([])
  const { user: currentUser } = useAlamContext()
  const { tokenCode } = useParams<{ tokenCode: string }>()
  const history = useHistory()

  const redirectToHome = () => {
    history.push('/')
  }

  const { loading: getTokenIsLoading } = useQuery(GET_TOKEN_FROM_CODE, {
    variables: {
      token_code: tokenCode,
    },
    onCompleted: ({ getTokenFromCode }) => {
      console.log(getTokenFromCode)
      setToken(getTokenFromCode)
    },
    onError: ({ message }) => {
      useErrorToast({ message })
      redirectToHome()
    },
  })

  const { loading: getUserClassesIsLoading } = useSubscription(
    GET_USER_CLASSES,
    {
      variables: { user_id: currentUser.id },
      onSubscriptionData: ({
        subscriptionData: {
          data: { classes },
        },
      }) => {
        const ids = classes.map(({ id }: GetUserClassType) => {
          return id
        })
        setClassIds(ids)
      },
    }
  )

  const removedFromClassPattern = /^(Removed from class: )(.)/
  const accessErrorPattern = /^(Invite access error: )(.)/

  const [
    useToken,
    {
      loading: useTokenIsLoading,
      called: useTokenIsCalled,
      error: useTokenError,
    },
  ] = useMutation(USE_TOKEN, {
    variables: {
      token_id: token?.id,
      type: 'class_invite',
    },
    onCompleted: ({ useToken }) => {
      useSuccessToast({ message: 'Successfully joined class' })
      history.push(`/classes/${useToken.classUser.classId}`)
    },
    onError: ({ message }) => {
      if (message.includes('Removed from class: ')) {
        const className = message.replace(removedFromClassPattern, '$2')
        setErrorMessage({
          title: className,
          message: `You have been removed from ${className}. Please contact the instructor for more information.`,
          isOpen: true,
          onClose: redirectToHome,
        })
      } else if (message.includes('Invite access error: ')) {
        const className = message.replace(accessErrorPattern, '$2')
        setErrorMessage({
          title: className,
          message:
            'We can’t seem to find this classroom! Please contact the person who invited you to the classroom for more details.',
          isOpen: true,
          onClose: redirectToHome,
        })
      } else {
        useErrorToast({ message })
        redirectToHome()
      }
    },
  })

  useEffect(() => {
    if (!currentUser) {
      redirectToFrontEnd({})
    }
    if (!getTokenIsLoading && !getUserClassesIsLoading) {
      if (token?.classUser?.userId != currentUser.id) {
        const tokenClassId = token?.classUser?.classId
        if (tokenClassId && classIds.includes(tokenClassId)) {
          useErrorToast({ message: 'You are an existing member of the class.' })
          history.push(`/classes/${token?.classUser?.classId}`)
        } else {
          useErrorToast({
            message:
              'The class invite link was meant for another user. Please contact the admin of the class for an invitation.',
          })
          history.push('/')
        }
      } else {
        useToken()
      }
    }
  }, [token, classIds])

  return (
    <>
      {errorMessage && <ShowMessageModal {...errorMessage} />}
      <Loader loaders={[true]}>
        <></>
      </Loader>
    </>
  )
}
