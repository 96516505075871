import { User } from '../../types'
import {
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  Textarea,
  Icon,
  Box,
} from '@chakra-ui/react'
import { AlamAvatar } from '../Avatar'
import {
  getFullName,
  getFirstAndLastInitial,
  getPreferenceIcon,
  displayPreference,
  capitalize,
} from '../../utils'
import { RenderForUser } from '../SharedComponents'

export type UserProfileProps = {
  user?: User
  isOpen: boolean
  onClose: () => void
}

export const UserProfile = ({
  user,
  isOpen,
  onClose,
}: UserProfileProps): JSX.Element => {
  const name = getFullName(user)

  const learningPreferencePermissions = user?.is_preference_public
    ? ['student', 'admin', 'creator', 'teacher']
    : ['admin', 'creator', 'teacher']

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        minW={{ base: '320px', sm: '416px', lg: '520px' }}
        w={{ base: '320px', sm: '416px', lg: '520px' }}
      >
        <ModalCloseButton layerStyle='modal.leftCloseButton' />
        <ModalBody p='0'>
          <Flex
            direction='column'
            pt='72px'
            px='32px'
            pb='40px'
            p={{ base: '72px 16px 20px', md: '72px 32px 40px' }}
            align='center'
          >
            <AlamAvatar
              src={user?.avatar}
              name={getFirstAndLastInitial(user)}
              size='xl'
            />

            <Text pt='8px' textStyle='h5' color='primary.1'>
              {name}
            </Text>

            <FormControl mt='24px'>
              <FormLabel>Bio</FormLabel>
              <Textarea
                textStyle='body.1'
                color='black.4'
                h='144px'
                value={
                  user?.bio ||
                  `${capitalize(user?.firstName)} has not created a bio yet.`
                }
                readOnly
                border='none'
                disabled
                _disabled={{
                  color: 'black.4',
                  bg: 'grey.2',
                }}
                pt='8px'
                pb='0px'
                resize='none'
              />
            </FormControl>

            <RenderForUser
              roles={learningPreferencePermissions}
              userIds={[user?.id || '']}
            >
              <FormControl mt='32px'>
                <FormLabel>Learning Preferences</FormLabel>
                <Flex
                  bg='grey.2'
                  p='8px 16px'
                  borderRadius='8px'
                  align='center'
                  color='primary.1'
                  minHeight='40px'
                  wrap='wrap'
                  justify='flex-start'
                >
                  {user?.learningStyle?.map((preference) => {
                    return (
                      <Flex
                        align='center'
                        alignContent='space-between'
                        minHeight='24px'
                        flex={1}
                      >
                        <Icon as={getPreferenceIcon(preference)} mr='15.2px' />
                        <Text
                          textStyle='body.1'
                          textTransform='capitalize'
                          mr='16px'
                          align='center'
                          color='black.1'
                        >
                          {displayPreference(preference)}
                        </Text>
                      </Flex>
                    )
                  })}
                  <Box flex={4 - (user?.learningStyle?.length || 0)}></Box>
                </Flex>
              </FormControl>
            </RenderForUser>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
