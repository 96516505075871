import React from 'react'
import GroupsPage from './GroupsPage'
import { useParams } from 'react-router-dom'

const Groups = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  return <GroupsPage id={id} />
}

export default Groups
