import { NotEnoughData } from './NotEnoughData'
import { QuestionGraphProps } from './QuestionCard'
import {
  Container,
  Box,
  Flex,
  VStack,
  StackDivider,
  Text,
} from '@chakra-ui/react'
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  BarChart,
  YAxis,
  XAxis,
  Bar,
  LabelList,
  Label,
  CartesianGrid,
  Tooltip,
} from 'recharts'
import {
  getChoices,
  constructChoiceStudentData,
  populateChoiceStudentData,
  ChoiceGraph,
  ChoiceStudentData,
  renderCustomizedPieLabel,
  renderPercentageCustomLabel,
  computePercentage,
  transformCountToPercentage,
  PercentageXAxisTick,
  TruncatedChoicesTick,
  CheckboxTooltip,
} from '../../utils'
import textStyles from '../../theme/textStyles'

export const MultipleChoiceGraph = ({
  question,
}: QuestionGraphProps): JSX.Element => {
  const colors = ['#EE2092', '#6A61FA', '#09C6CD', '#392ACC', '#ED8D22']
  const initialGraph = getChoices(question)
  const studentData = constructChoiceStudentData(question)
  const populatedGraph = populateChoiceStudentData(initialGraph, studentData)

  const totalCount = populatedGraph.reduce(
    (total: number, choice: ChoiceGraph) => {
      return total + choice.count
    },
    0
  )

  const isChoicesMany = populatedGraph.length > 5

  const finalGraph = !isChoicesMany
    ? populatedGraph
    : transformCountToPercentage(populatedGraph, totalCount)

  return (
    <>
      {totalCount === 0 && <NotEnoughData />}
      {totalCount > 0 && !isChoicesMany && (
        <Flex p='20px 32px'>
          <Box w='560px'>
            <Flex h='100%'>
              <VStack
                w='100%'
                divider={
                  <StackDivider layerStyle='dividerGrey' my='0 !important' />
                }
              >
                {finalGraph.map((choice: ChoiceGraph, index: number) => (
                  <Flex
                    align='center'
                    justify='space-between'
                    w='100%'
                    minH='56px'
                    key={colors[index]}
                  >
                    <Flex align='center'>
                      <Box
                        minW='12px'
                        minH='12px'
                        borderRadius='50%'
                        bg={colors[index]}
                        mr={4}
                      />
                      <Box>
                        <Text
                          textStyle='body.semibold'
                          color='black.1'
                          fontWeight='400'
                          noOfLines={2}
                        >
                          {choice.name}
                        </Text>
                      </Box>
                    </Flex>
                    <Flex>
                      <Text textStyle='h5' color='black.1'>
                        {computePercentage(choice.count, totalCount, 2)}%
                      </Text>
                    </Flex>
                  </Flex>
                ))}
              </VStack>
            </Flex>
          </Box>

          <Flex align='center' justify='center' flex='1' h='260px' mt='10px'>
            <ResponsiveContainer>
              <PieChart width={800} height={400}>
                <Pie
                  data={finalGraph}
                  cx={260}
                  cy={120}
                  labelLine={false}
                  label={renderCustomizedPieLabel}
                  innerRadius={80}
                  outerRadius={125}
                  fill='#8884d8'
                  dataKey='count'
                  nameKey='name'
                  isAnimationActive={false}
                >
                  {finalGraph.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index]} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </Flex>
        </Flex>
      )}
      {totalCount > 0 && isChoicesMany && (
        <Flex p='40px 32px' justify='center' align='center'>
          <ResponsiveContainer width={750} height={264}>
            <BarChart
              width={350}
              height={264}
              data={finalGraph}
              barCategoryGap='30%'
              barGap={8}
              layout='vertical'
              margin={{ left: 50, right: 25 }}
            >
              <CartesianGrid strokeDasharray='0' horizontal={false} />
              <YAxis
                dataKey='name'
                type='category'
                style={{
                  ...textStyles.body['2'],
                  fontWeight: 400,
                  fill: 'rgba(1, 0, 2, 0.56)',
                }}
                tick={<TruncatedChoicesTick />}
                interval={0}
                tickLine={false}
                minTickGap={10}
                padding={{ bottom: -2 }}
              />
              <XAxis
                type='number'
                axisLine={false}
                tickLine={false}
                style={{
                  ...textStyles.body['2'],
                  fontWeight: 400,
                  fontSize: '12px',
                }}
                tick={<PercentageXAxisTick />}
                ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                dataKey='count'
              ></XAxis>
              <Tooltip
                content={<CheckboxTooltip totalCount={totalCount} />}
                cursor={false}
              />
              <Bar
                dataKey='count'
                barSize={40}
                fill='#CE1032'
                isAnimationActive={false}
              >
                <LabelList content={renderPercentageCustomLabel} />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Flex>
      )}
    </>
  )
}
