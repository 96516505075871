import React from 'react'
import GroupWorkPage from './GroupWorkPage'
import { useParams } from 'react-router-dom'

const GroupWork = (): JSX.Element => {
  const { id, group_id } = useParams<{ id: string; group_id: string }>()
  return <GroupWorkPage classId={id} id={group_id} />
}

export default GroupWork
