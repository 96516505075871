import React, { useContext, useState, useEffect, useMemo } from 'react'
import {
  ApolloLink,
  Operation,
  FetchResult,
  Observable,
  ApolloProvider,
  InMemoryCache,
  ApolloClient,
  split,
  HttpLink,
} from '@apollo/client'
// import { WebSocketLink } from '@apollo/client/link/ws' // original code
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import {print, GraphQLError} from 'graphql' // old way ws link
import { createClient, ClientOptions, Client } from "graphql-ws";
import { getMainDefinition } from '@apollo/client/utilities'
import { User, ClassUser, Group, JwtToken, Topic } from '../types'
import { createLink } from 'apollo-absinthe-upload-link'
import { useScheme } from '../utils'
import jwtDecode from 'jwt-decode'

export const GRAPH_HOST = process.env.REACT_APP_GRAPH_HOST || 'localhost:8080'
export const SERVER_HOST = process.env.REACT_APP_SERVER_HOST || 'localhost:4000'
export const GRAPHQL_ENDPOINT = `${useScheme('http')}://${GRAPH_HOST}/v1/graphql`
export const WEBSOCKET_ENDPOINT = `${useScheme(
  'ws'
)}://${GRAPH_HOST}/v1/graphql`

export const ABSINTHE_GRAPHQL_ENDPOINT = `${useScheme('http')}://${SERVER_HOST}/graphql`

// List of absinthe query names to delegate to absinthe
const ABSINTHE_OPERATION_NAMES = ['create_attachment', 'edit_profile_info']

// start - original code
// Link generators
// const wsLink = (uri: string, token: string) => {
//   return new WebSocketLink({
//     uri,
//     options: {
//       reconnect: true,
//       connectionParams: {
//         headers: {
//           authorization: `Bearer ${token}`,
//         },
//       },
//     },
//   })
// }
// end - original code

interface HeaderOptions {
  headers: {
  Authorization: string;
  }
}

interface ClientOptionsManual {
  reconnect: boolean;
  connectionParams: () => HeaderOptions;
}

interface CreateClientOptions {
  url: string;
  connectionParams: () => any;
}

const wsLink = (url: string, token: string) => {
  console.log("Ari print: Using wsLink -> url", url)
  return new GraphQLWsLink(
    createClient({
      url: url,
      connectionParams: () => {
        return {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      },
    } as CreateClientOptions)
  )
}

// Start - old way websocket link
// class WebSocketLink extends ApolloLink {
//   private client: Client;

//   constructor(options: ClientOptions) {
//     super();
//     this.client = createClient(options);
//   }

//   public request(operation: Operation): Observable<FetchResult> | null {
//     return new Observable((sink) => {
//       return this.client.subscribe<FetchResult>(
//         {...operation, query: print(operation.query)},
//         {
//           next: sink.next.bind(sink),
//           complete: sink.complete.bind(sink),
//           error: (err) => {
//             if (err instanceof Error) {
//               return sink.error(err);
//             }

//             if (err instanceof CloseEvent) {
//               return sink.error(
//                 new Error(
//                   `Socket closed with event ${err.code}` +
//                     (err.reason ? `: ${err.reason}` : '')
//                 )
//               );
//             }

//             return sink.error(
//               new Error(
//                 (err as GraphQLError[])
//                   .map(({ message }) => message)
//                   .join(', ')
//               )
//             );
//           }
//         })
//     })
//   }
// }


// const wsLink = (url: string, token: string) => {
//   return new WebSocketLink({
//     url: url,
//     connectionParams: () => {
//         return {
//             Authorization: `Bearer ${token}`
//         };
//       }
//   });
// }
// end - old way websocket link

const httpLink = (uri: string, token: string) => {
  console.log("Ari print: Using httpLink -> uri", uri)
  return new HttpLink({
    uri,
    headers: {
      authorization: `Bearer ${token}`,
    },
  })
}

const absintheLink = (uri: string, token: string) => {
  console.log("Ari print: Using absintheLink -> uri", uri)
  return createLink({
    uri,
    headers: {
      authorization: `Bearer ${token}`,
    },
  })
}

/** Split functions
 ** called for each operation to determine which link to use
 **/
const splitLink = (token: string) => {
  return split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink(WEBSOCKET_ENDPOINT, token),
    httpSplitLink(token)
  )
}

const httpSplitLink = (token: string) => {
  return split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        ABSINTHE_OPERATION_NAMES.includes(definition?.name?.value || '')
      )
    },
    absintheLink(ABSINTHE_GRAPHQL_ENDPOINT, token),
    httpLink(GRAPHQL_ENDPOINT, token)
  )
}

/**
 * This creates a new Apollo Client.
 * Used in ApolloProvider wrapping the app via AlamClient
 */
const createAlamClient = (token: string) => {
  const client = new ApolloClient({
    link: splitLink(token),
    cache: new InMemoryCache(),
  })
  return client
}

export type AlamContextProps = {
  user: User
  setUser: React.Dispatch<any>
  classUser: ClassUser | any
  setClassUser: React.Dispatch<any>
  chatIsOpen: boolean
  setChatIsOpen: React.Dispatch<any>
  managePeopleIsOpen: boolean
  setManagePeopleIsOpen: React.Dispatch<any>
  groups: Group[]
  setGroups: React.Dispatch<Group[]>
  groupUser?: { id: string }
  setGroupUser: React.Dispatch<any>
  students: ClassUser[]
  setStudents: React.Dispatch<ClassUser[]>
  teachers: ClassUser[]
  setTeachers: React.Dispatch<ClassUser[]>
  decodedToken?: JwtToken
  classTopics: Topic[]
  setClassTopics: React.Dispatch<Topic[]>
  isOpenGroupsSidebar: boolean
  setIsOpenGroupsSidebar: React.Dispatch<boolean>
  isOpenSessionsSidebar: boolean
  setIsOpenSessionsSidebar: React.Dispatch<boolean>
}

export const AlamContext = React.createContext<AlamContextProps>({
  user: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    bio: '',
    provider: '',
    learningStyle: [],
  },
  setUser: () => {},
  classUser: {
    id: '',
    role: '',
    class: {
      id: '',
      name: '',
      subject: '',
      code: '',
      can_send_invite: true,
    },
  },
  setClassUser: () => {},
  chatIsOpen: true,
  setChatIsOpen: () => {},
  managePeopleIsOpen: true,
  setManagePeopleIsOpen: () => {},
  groups: [],
  setGroups: () => {},
  groupUser: {
    id: '',
  },
  setGroupUser: () => {},
  teachers: [],
  setTeachers: () => {},
  students: [],
  setStudents: () => {},
  decodedToken: undefined,
  classTopics: [],
  setClassTopics: () => {},
  isOpenGroupsSidebar: true,
  setIsOpenGroupsSidebar: () => {},
  isOpenSessionsSidebar: true,
  setIsOpenSessionsSidebar: () => {},
})

// Config required by Client
export interface AlamConfig {
  user: User
  token: string
}

// ClientProps
interface AlamClientProps {
  children: React.ReactNode
  config: AlamConfig
}

// Context and Apollo provider
const AlamClient = (props: AlamClientProps) => {
  const [classUser, setClassUser] = useState()
  const [user, setUser] = useState(props.config?.user)
  const [chatIsOpen, setChatIsOpen] = useState(true)
  const stringToBoolean = (item: string | boolean | null) => {
    if (item === 'false' || !item) {
      return false
    } else {
      return true
    }
  }

  const initialIsOpenGroupsSidebar = stringToBoolean(
    localStorage.getItem('isOpenGroupsSidebar') || true
  )
  const initialIsOpenSessionsSidebar = stringToBoolean(
    localStorage.getItem('isOpenSessionsSidebar') || true
  )
  const [isOpenGroupsSidebar, setIsOpenGroupsSidebar] = useState(
    initialIsOpenGroupsSidebar
  )
  const [isOpenSessionsSidebar, setIsOpenSessionsSidebar] = useState(
    initialIsOpenSessionsSidebar
  )
  const [managePeopleIsOpen, setManagePeopleIsOpen] = useState(false)
  const [groups, setGroups] = useState<Group[]>([])
  const [groupUser, setGroupUser] = useState()
  const [teachers, setTeachers] = useState<ClassUser[]>([])
  const [students, setStudents] = useState<ClassUser[]>([])
  const [decodedToken, setDecodedToken] = useState<JwtToken>()
  const [classTopics, setClassTopics] = useState<Topic[]>([])
  const {
    config: { token },
  } = props

  useEffect(() => {
    if (token) {
      const decoded = jwtDecode<JwtToken>(token)
      setDecodedToken(decoded)
    }
  }, [token])

  useEffect(() => {
    localStorage.setItem(
      'isOpenGroupsSidebar',
      JSON.stringify(isOpenGroupsSidebar)
    )
  }, [isOpenGroupsSidebar])

  useEffect(() => {
    localStorage.setItem(
      'isOpenSessionsSidebar',
      JSON.stringify(isOpenSessionsSidebar)
    )
  }, [isOpenSessionsSidebar])

  const alamClientState = {
    user,
    setUser,
    classUser,
    setClassUser,
    chatIsOpen,
    setChatIsOpen,
    isOpenGroupsSidebar,
    setIsOpenGroupsSidebar,
    isOpenSessionsSidebar,
    setIsOpenSessionsSidebar,
    managePeopleIsOpen,
    setManagePeopleIsOpen,
    groups,
    setGroups,
    groupUser,
    setGroupUser,
    teachers,
    setTeachers,
    students,
    setStudents,
    decodedToken,
    classTopics,
    setClassTopics,
  }

  const client = useMemo(() => {
    return createAlamClient(token)
  }, [token])

  return (
    <ApolloProvider client={client}>
      <AlamContext.Provider value={alamClientState}>
        {props.children}
      </AlamContext.Provider>
    </ApolloProvider>
  )
}

const useAlamContext = () => {
  return useContext(AlamContext)
}

export { useAlamContext }
export default AlamClient
