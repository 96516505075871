export const PlusCircle = ({
  fill,
  width,
  height,
  viewBox,
}: {
  fill: string
  width: string
  height: string
  viewBox: string
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M25 21H21V25H19V21H15V19H19V15H21V19H25V21ZM20 10C18.6868 10 17.3864 10.2587 16.1732 10.7612C14.9599 11.2638 13.8575 12.0003 12.9289 12.9289C11.0536 14.8043 10 17.3478 10 20C10 22.6522 11.0536 25.1957 12.9289 27.0711C13.8575 27.9997 14.9599 28.7362 16.1732 29.2388C17.3864 29.7413 18.6868 30 20 30C22.6522 30 25.1957 28.9464 27.0711 27.0711C28.9464 25.1957 30 22.6522 30 20C30 18.6868 29.7413 17.3864 29.2388 16.1732C28.7362 14.9599 27.9997 13.8575 27.0711 12.9289C26.1425 12.0003 25.0401 11.2638 23.8268 10.7612C22.6136 10.2587 21.3132 10 20 10V10Z'
      fill={fill}
    />
  </svg>
)

PlusCircle.defaultProps = {
  fill: '#17053D',
  width: '40',
  height: '40',
  viewBox: '0 0 40 40',
}
