import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Flex,
} from '@chakra-ui/react'

export type ConfirmationModalProps = {
  isOpen: boolean
  onClose: () => void
  title: string
  message: string
  size?: string
  confirmButtonText?: string
  onSubmit: () => void
  cancelButtonText?: string
  isSubmitting?: boolean
  titleProps?: Record<string, any>
  messageProps?: Record<string, any>
  showCloseButton?: boolean
}

export const ConfirmationModal = ({
  isOpen,
  onClose,
  title,
  message,
  size = 'xs',
  confirmButtonText = "Yes I'm Sure",
  onSubmit,
  cancelButtonText = 'Cancel',
  isSubmitting = false,
  titleProps = {},
  messageProps = {},
  showCloseButton = true,
}: ConfirmationModalProps): JSX.Element => {
  const [isSubmitClicked, setIsSubmitClicked] = useState(false)

  const handleOnClick = () => {
    setIsSubmitClicked(true)
    onSubmit()
    setIsSubmitClicked(false)
  }

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size={size}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader {...titleProps}>{title}</ModalHeader>

          <ModalBody>
            <Text
              textStyle='body.1'
              color='modal.text'
              textAlign='center'
              whiteSpace='pre-wrap'
              {...messageProps}
            >
              {message}
            </Text>
          </ModalBody>

          <ModalFooter paddingBottom='24px'>
            <Flex w='100%' justify='space-around'>
              {showCloseButton && (
                <Button
                  onClick={onClose}
                  variant='base.white'
                  textStyle='button'
                >
                  {cancelButtonText}
                </Button>
              )}
              <Button
                onClick={handleOnClick}
                isDisabled={isSubmitClicked}
                variant='base.primary'
                textStyle='button'
                isLoading={isSubmitting}
              >
                {confirmButtonText}
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ConfirmationModal
