import { AssignmentTypes } from '../pages/ClassGrades/ClassGrades'

export const dueDateConditions = (assignment: AssignmentTypes) => {
  const today = new Date()
  const assignmentDueDate = assignment.due_date
    ? new Date(assignment.due_date)
    : null

  const withDueDate = assignmentDueDate && assignmentDueDate > today
  const withoutDueDate = assignmentDueDate === null
  const pastDueDate = assignmentDueDate && assignmentDueDate < today

  return {
    withDueDate,
    withoutDueDate,
    pastDueDate,
  }
}

export const checkDueDateFilters = (
  assignment: AssignmentTypes,
  checkedDueDateOptions: string[]
) => {
  const { withDueDate, withoutDueDate, pastDueDate } = dueDateConditions(
    assignment
  )

  if (checkedDueDateOptions.length === 3) {
    return true
  } else if (
    checkedDueDateOptions.length === 2 &&
    checkedDueDateOptions.includes('with_due_date') &&
    checkedDueDateOptions.includes('without_due_date') &&
    (withDueDate || withoutDueDate)
  ) {
    return true
  } else if (
    checkedDueDateOptions.length === 2 &&
    checkedDueDateOptions.includes('with_due_date') &&
    checkedDueDateOptions.includes('past_due') &&
    (withDueDate || pastDueDate)
  ) {
    return true
  } else if (
    checkedDueDateOptions.length === 2 &&
    checkedDueDateOptions.includes('without_due_date') &&
    checkedDueDateOptions.includes('past_due') &&
    (withoutDueDate || pastDueDate)
  ) {
    return true
  } else if (
    checkedDueDateOptions.length === 1 &&
    checkedDueDateOptions.includes('with_due_date') &&
    withDueDate
  ) {
    return true
  } else if (
    checkedDueDateOptions.length === 1 &&
    checkedDueDateOptions.includes('without_due_date') &&
    withoutDueDate
  ) {
    return true
  } else if (
    checkedDueDateOptions.length === 1 &&
    checkedDueDateOptions.includes('past_due') &&
    pastDueDate
  ) {
    return true
  } else {
    return false
  }
}
