export const History = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.2 4.80078V8.88878L10.968 11.1208L11.6 10.0968L8.4 8.20078V4.80078H7.2ZM8.4 0.800781C5.576 0.800781 3.128 2.33678 1.816 4.61678L0 2.80078V8.00078H5.2L3 5.80078C3.968 3.78478 6 2.40078 8.4 2.40078C9.9913 2.40078 11.5174 3.03292 12.6426 4.15814C13.7679 5.28336 14.4 6.80948 14.4 8.40078C14.4 9.99208 13.7679 11.5182 12.6426 12.6434C11.5174 13.7686 9.9913 14.4008 8.4 14.4008C5.784 14.4008 3.576 12.7288 2.752 10.4008H1.072C1.952 13.6248 4.888 16.0008 8.4 16.0008C12.592 16.0008 16 12.6008 16 8.40078C16 7.40274 15.8034 6.41446 15.4215 5.49239C15.0395 4.57031 14.4797 3.73249 13.774 3.02677C13.0683 2.32104 12.2305 1.76123 11.3084 1.3793C10.3863 0.997361 9.39805 0.800781 8.4 0.800781Z'
        fill='currentColor'
      />
    </svg>
  )
}
