import React from 'react'
import { Container, Flex } from '@chakra-ui/react'

export const ComingSoon = (): JSX.Element => {
  return (
    <Container w='100%' h='100%' minH='100%'>
      <Flex
        w='100%'
        minH='100%'
        h='100%'
        align='center'
        justify='center'
        textStyle='h1'
        color='primary.1'
      >
        Coming Soon
      </Flex>
    </Container>
  )
}

export default ComingSoon
