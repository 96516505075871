import { gql } from '@apollo/client'

export const examFields = gql`
  fragment examFields on exams {
    id
    type
    assignment_id
    exam_arrangements(order_by: [{ order: asc }]) {
      id
      order
      question {
        id
        text
        type
        response_choices_arrangements(order_by: [{ order: asc }]) {
          id
          order
          response_choice {
            id
            text
          }
        }
      }
    }
  }
`

export const examWithResponsesForStudents = gql`
  fragment examWithResponsesForStudents on exams {
    id
    type
    assignment {
      name
    }
    assignment_id
    exam_responses(where: { user_id: { _is_null: false } }) {
      id
      started_at
      completed_at
      user_id
      group_user_id
      group_user {
        group_id
      }
      responses {
        id
        answers {
          text
        }
        question_id
      }
    }
    exam_arrangements(order_by: [{ order: asc }]) {
      id
      order
      question {
        id
        text
        type
        response_choices_arrangements(order_by: [{ order: asc }]) {
          id
          order
          response_choice {
            id
            text
          }
        }
      }
    }
  }
`

export const examWithResponsesForGroups = gql`
  fragment examWithResponsesForGroups on exams {
    id
    type
    assignment {
      name
    }
    assignment_id
    exam_responses(where: { group_user: { archived_at: { _is_null: true } } }) {
      id
      started_at
      completed_at
      user_id
      group_user_id
      group_user {
        group_id
      }
      responses {
        id
        answers {
          text
        }
        question_id
      }
    }
    exam_arrangements(order_by: [{ order: asc }]) {
      id
      order
      question {
        id
        text
        type
        response_choices_arrangements(order_by: [{ order: asc }]) {
          id
          order
          response_choice {
            id
            text
          }
        }
      }
    }
  }
`
