import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Td,
  InputGroup,
  InputRightElement,
  IconButton,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react'
import { MdMoreHoriz } from 'react-icons/md'
import { AlphabeticalGradeInput, NumericalGradeInput } from '../Input'
import { SubmissionTypes } from '../../pages/ClassGrades/ClassGrades'
import { displayStatus } from '../../utils'
import { isBefore } from 'date-fns'
import { useAlamContext } from '../../client'

type onUpdateSubmissionProps = {
  status: string
  gradeId: string
}

export interface GradeCellProps {
  submission: SubmissionTypes
  selectedSubmission?: SubmissionTypes
  setSelectedSubmission: (submission: SubmissionTypes) => void
  grade?: string
  setGrade: (value: string) => void
  onUpdateSubmission: (args: onUpdateSubmissionProps) => void
  link: string
}

export type TeacherSubmissionStatusProps = {
  submission: SubmissionTypes
}

const unselectable = ['not assigned', 'Assigned', 'unsubmitted']

export const TeacherSubmissionStatus = ({
  submission,
}: TeacherSubmissionStatusProps): JSX.Element => {
  const isDue =
    !!submission.assignment?.due_date &&
    isBefore(new Date(submission.assignment?.due_date), new Date())

  const submissionStatus = useMemo(() => {
    if (isDue && submission.status === 'unsubmitted') return 'Missing'
    return displayStatus(submission)
  }, [isDue, submission])

  const getTextColor = (status: string) => {
    if (status === 'Missing') return 'error.1'
    return unselectable.includes(submission.status) ? 'primary.2' : 'black.1'
  }

  const submittedInfo = (submission: SubmissionTypes): any => {
    const submittedLogs = submission.eventLogs.filter(
      (eventLog) => eventLog.content === 'submitted'
    )
    const isLate =
      !!submission?.assignment?.due_date &&
      isBefore(
        new Date(submission.assignment?.due_date),
        new Date(submittedLogs[0].inserted_at)
      )
    if (submittedLogs.length > 1) return 'Resubmitted'
    if (isLate) return 'Submitted Late'
    return null
  }

  return (
    <Flex direction='column'>
      <Text
        color={getTextColor(submissionStatus)}
        fontSize='14px'
        lineHeight='20px'
        fontWeight='400'
      >
        {submissionStatus}
      </Text>
      {submission.status === 'submitted' && (
        <Text
          color='black.3'
          fontSize='10px'
          lineHeight='20px'
          fontWeight='400'
        >
          {submittedInfo(submission)}
        </Text>
      )}
    </Flex>
  )
}

export const GradeCell = ({
  submission,
  selectedSubmission,
  setSelectedSubmission,
  grade,
  setGrade,
  onUpdateSubmission,
  link,
}: GradeCellProps): JSX.Element => {
  const history = useHistory()
  const { user } = useAlamContext()
  const handleChangeGrade = (value: string): void => {
    setGrade(value)
  }

  const firstGrade = submission?.grades[0]

  const isDisabled = () => {
    return firstGrade && firstGrade?.grader_id !== user?.id
  }

  const isSelected = () => {
    if (!selectedSubmission) return false
    return selectedSubmission?.id == submission.id
  }

  const handleOnClick = () => {
    if (!unselectable.includes(submission.status) && !isDisabled()) {
      setSelectedSubmission(submission)
    }
  }

  const handleReturn = () => {
    onUpdateSubmission({
      status: 'returned',
      gradeId: submission?.grades[0]?.id,
    })
  }

  const handleView = () => {
    history.push(link)
  }

  return (
    <Td
      borderWidth={isSelected() ? '2px' : '0.2px'}
      borderStyle='solid'
      borderColor={isSelected() ? 'primary.1' : 'primary.2-7'}
      textStyle='body.2'
      p={4}
      minW='112px'
      maxW='auto'
      key={submission.id}
      isNumeric={submission?.grades[0] ? true : false}
      textTransform='capitalize'
      onClick={handleOnClick}
    >
      {isSelected() ? (
        <InputGroup>
          {submission.assignment?.rubric === 'numerical' ? (
            <NumericalGradeInput
              grading={submission?.assignment?.grading}
              value={grade}
              onChange={handleChangeGrade}
              inputProps={{
                autoFocus: true,
                borderRadius: '0px',
                border: 'none',
                textStyle: {
                  fontWeight: '600',
                  fontSize: '14px',
                  lineHeight: '22px',
                  align: 'center',
                },
                p: '0px',
                h: '20px',
                _hover: {
                  borderBottom: '2px solid #4c04c4',
                },
              }}
            />
          ) : (
            <AlphabeticalGradeInput
              value={grade}
              onChange={handleChangeGrade}
              inputProps={{
                autoFocus: true,
                borderRadius: '0px',
                border: 'none',
                textStyle: {
                  fontWeight: '600',
                  fontSize: '14px',
                  lineHeight: '22px',
                  align: 'center',
                },
                p: '0px',
                h: '20px',
                _hover: {
                  borderBottom: '2px solid #4c04c4',
                },
              }}
            />
          )}

          <InputRightElement
            h='100%'
            w='20px'
            alignItems='center'
            css={{
              '.chakra-button > svg': {
                width: '18px',
                height: '18px',
              },
            }}
          >
            <Menu placement='bottom-end'>
              <MenuButton
                as={IconButton}
                aria-label='more'
                variant='icon.button'
                icon={<MdMoreHoriz />}
              />
              <MenuList w='169px'>
                {!['returned', 'unsubmitted', 'not assigned'].includes(
                  submission.status
                ) && (
                  <MenuItem
                    layerStyle='menu.item.normal'
                    textStyle='body.1'
                    onClick={handleReturn}
                  >
                    Return
                  </MenuItem>
                )}
                <MenuItem
                  layerStyle='menu.item.normal'
                  textStyle='body.1'
                  onClick={handleView}
                >
                  View Submission
                </MenuItem>
              </MenuList>
            </Menu>
          </InputRightElement>
        </InputGroup>
      ) : !submission?.grades[0]?.grade ? (
        <TeacherSubmissionStatus submission={submission} />
      ) : (
        <Text
          fontWeight='400'
          fontSize='14px'
          lineHeight='20px'
          color='black.1'
          align='center'
        >
          {submission.grades[0].grade}
        </Text>
      )}
    </Td>
  )
}

export default GradeCell
