export type RoutesProp = {
  [key: string]: {
    path: string
    classChat: boolean
    hasRightNav: boolean
    groupChat: boolean
  }
}

export const routes: RoutesProp = {
  classes: {
    path: '/',
    classChat: false,
    hasRightNav: false,
    groupChat: false,
  },
  class: {
    path: '/classes/:id',
    classChat: true,
    hasRightNav: true,
    groupChat: false,
  },
  classwork: {
    path: '/classes/:id/classwork',
    classChat: true,
    hasRightNav: true,
    groupChat: false,
  },
  classAssignment: {
    path: '/classes/:id/assignments/:assignmentId',
    classChat: false,
    hasRightNav: false,
    groupChat: false,
  },
  classFeedback: {
    path: '/classes/:id/assignments/:assignmentId/feedback',
    classChat: false,
    hasRightNav: false,
    groupChat: false,
  },
  classSubmission: {
    path: '/classes/:id/assignments/:assignmentId/submissions',
    classChat: false,
    hasRightNav: true,
    groupChat: false,
  },
  pretopicInfo: {
    path: '/classes/:id/pretopic/:pretopicId',
    classChat: true,
    hasRightNav: true,
    groupChat: false,
  },
  pretopicSubmission: {
    path: '/classes/:id/pretopic/:pretopicId/submissions',
    classChat: true,
    hasRightNav: true,
    groupChat: false,
  },
  classMaterial: {
    path: '/classes/:id/materials/:materialId',
    classChat: true,
    hasRightNav: true,
    groupChat: false,
  },
  groups: {
    path: '/classes/:id/groups',
    classChat: false,
    hasRightNav: false,
    groupChat: false,
  },
  group: {
    path: '/classes/:id/groups/:group_id',
    classChat: false,
    hasRightNav: true,
    groupChat: true,
  },
  groupwork: {
    path: '/classes/:id/groups/:group_id/groupwork',
    classChat: false,
    hasRightNav: true,
    groupChat: true,
  },
  groupMaterial: {
    path: '/classes/:id/groups/:group_id/materials/:materialId',
    classChat: false,
    hasRightNav: true,
    groupChat: true,
  },
  groupAssignment: {
    path: '/classes/:id/groups/:group_id/assignments/:assignmentId',
    classChat: false,
    hasRightNav: false,
    groupChat: false,
  },
  groupFeedback: {
    path: '/classes/:id/groups/:group_id/assignments/:assignmentId/feedback',
    classChat: false,
    hasRightNav: false,
    groupChat: false,
  },
  groupSubmission: {
    path: '/classes/:id/groups/:group_id/assignments/:assignmentId/submissions',
    classChat: false,
    hasRightNav: false,
    groupChat: false,
  },
  grades: {
    path: '/classes/:id/grades',
    classChat: false,
    hasRightNav: false,
    groupChat: false,
  },
  settings: {
    path: '/classes/:id/account-settings/:page',
    classChat: true,
    hasRightNav: true,
    groupChat: false,
  },
  classSettings: {
    path: '/classes/:id/settings',
    classChat: true,
    hasRightNav: true,
    groupChat: false,
  },
  homeSettings: {
    path: '/home/settings/:page',
    classChat: false,
    hasRightNav: false,
    groupChat: false,
  },
}
