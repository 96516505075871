import React, { useEffect } from 'react'
import {
  MenuItem,
  Checkbox,
  MenuDivider,
  Flex,
  Text,
  Icon,
} from '@chakra-ui/react'
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md'
import { DropdownOption } from '../../utils'

export type MultiDropdownItemProps = {
  options: DropdownOption[]
  isEverythingChecked: boolean
  toggleEverything: () => void
  allTitle: string
  isChecked: (item: string) => boolean
  handleChange: (checked: boolean, item: string) => void
  collapseTitle: string
  collapseIsOpen: boolean
  isCollapsable: boolean
  handleCollapseToggle: () => void
}

export const MultiDropdownItem = ({
  options,
  isEverythingChecked,
  toggleEverything,
  allTitle,
  isChecked,
  handleChange,
  collapseTitle,
  collapseIsOpen,
  isCollapsable,
  handleCollapseToggle,
}: MultiDropdownItemProps): JSX.Element => {
  return (
    <>
      {isCollapsable && (
        <>
          <MenuItem
            onClick={handleCollapseToggle}
            layerStyle='menu.item.checkbox'
          >
            <Flex w='100%' justify='space-between'>
              <Text color='black.1' textStyle='overline'>
                {collapseTitle}
              </Text>

              {collapseIsOpen ? (
                <Icon color='primary.1' as={MdKeyboardArrowUp} />
              ) : (
                <Icon color='primary.1' as={MdKeyboardArrowDown} />
              )}
            </Flex>
          </MenuItem>
          <MenuDivider />
        </>
      )}

      <MenuItem layerStyle='menu.item.checkbox'>
        <Checkbox isChecked={isEverythingChecked} onChange={toggleEverything}>
          {allTitle}
        </Checkbox>
      </MenuItem>

      {collapseIsOpen &&
        options.map(({ value, display, subtext, isDisabled }) => {
          return (
            <React.Fragment key={value}>
              <MenuDivider />
              <MenuItem
                value={value}
                layerStyle='menu.item.checkbox.indented'
                isDisabled={isDisabled}
              >
                <Checkbox
                  isChecked={isChecked(value)}
                  onChange={(e) => handleChange(e.target.checked, value)}
                >
                  <Flex direction='column' justify='center'>
                    <Text
                      color='black.1'
                      fontWeight='400'
                      fontSize='14px'
                      lineHeight='21px'
                      letterSpacing='-0.01em'
                    >
                      {display}
                    </Text>
                    <Text
                      color='primary.1'
                      fontWeight='400'
                      fontSize='14px'
                      lineHeight='21px'
                      letterSpacing='-0.01em'
                    >
                      {subtext}
                    </Text>
                  </Flex>
                </Checkbox>
              </MenuItem>
            </React.Fragment>
          )
        })}
    </>
  )
}

export default MultiDropdownItem
