import React, { useState, useEffect } from 'react'
import {
  Container,
  Box,
  Text,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  FormErrorIcon,
  useMediaQuery,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useAlamContext } from '../../client'
import { TopNavItemProps } from '../../components/TopNav'
import { useMutation } from '@apollo/client'
import { EDIT_USER } from '../../client/queries'
import { useErrorToast, useSuccessToast } from '../../utils'

type AccountPageProps = {
  topNavLeft: TopNavItemProps[]
  topNavCenter: TopNavItemProps[]
}

type AccountSettingsInput = {
  email: string
}

const AccountPage = ({
  topNavLeft,
  topNavCenter,
}: AccountPageProps): JSX.Element => {
  const [mobileDevice] = useMediaQuery([`(max-width: 650px)`])

  const { user, setUser } = useAlamContext()

  const validateEmail = (email: string) => {
    return email !== user.email
  }

  const {
    register,
    errors,
    handleSubmit,
    formState: { isValid },
  } = useForm<AccountSettingsInput>({
    mode: 'onChange',
    defaultValues: {
      email: user.email,
    },
  })

  const [editUser, { loading: editUserIsSubmitting }] = useMutation(EDIT_USER)

  const onSubmit = (data: AccountSettingsInput) => {
    editUser({
      variables: {
        data: data,
        id: user.id,
      },
    })
      .then(({ data: { update_users_by_pk: user } }) => {
        localStorage.setItem('user', JSON.stringify(user))
        setUser(user)
        useSuccessToast({ message: 'Email successfully changed' })
      })
      .catch(() => {
        useErrorToast({})
      })
  }

  return (
    <Container
      maxW={{ base: '100%', md: '456px', lg: '500px', xl: '668px' }}
      py={12}
      pt={{ base: 4, md: 8, xl: 14 }}
      px='0px'
      h='auto'
    >
      <Box
        w='100%'
        layerStyle={mobileDevice ? '' : 'card.board-updated-shadow'}
        boxShadow='rgb(0 0 0 / 10%) 0px 4px 6px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px'
        bg='white.1'
        px={mobileDevice ? 4 : 8}
        py={10}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex align='center' justify='space-between' mb={10}>
            <Text textStyle='h2' color='primary.1'>
              Account
            </Text>
            <Button
              type='submit'
              variant='base.primary'
              textStyle='button'
              disabled={!isValid}
              isLoading={editUserIsSubmitting}
            >
              Change Email
            </Button>
          </Flex>
          <Flex direction='column'>
            <FormControl isInvalid={!!errors.email?.message}>
              <FormLabel fontWeight='600' letterSpacing='0.1rem'>
                Email Address
              </FormLabel>
              <Input
                name='email'
                placeholder='hello1@gmail.com'
                ref={register({
                  required: 'Email is required',
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: 'Enter a valid email address',
                  },
                  validate: {
                    validateEmail,
                  },
                })}
                border='0.5px solid'
                borderColor='grey.border'
              />
              {errors?.email && (
                <FormErrorMessage>
                  <FormErrorIcon />
                  {errors.email?.message}
                </FormErrorMessage>
              )}
            </FormControl>
          </Flex>
        </form>
      </Box>
    </Container>
  )
}

export default AccountPage
