import { useContext } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Flex,
  Icon,
  Text,
} from '@chakra-ui/react'
import { useSuccessToast, useErrorToast, truncate } from '../../utils'
import { MaterialForm, MaterialInput } from '../../components/Forms/'
import { Material as MaterialType, Attachment, ClassUser } from '../../types'
import {
  CREATE_MATERIAL,
  UPDATE_MATERIAL,
  CREATE_ATTACHMENT,
  DELETE_ATTACHMENT,
  generateAssignmentUsers as generateMaterialUsers,
  generateInsertMaterialUsers,
  generateMaterialGroups,
} from '../../client/queries'
import { useMutation } from '@apollo/client'
import { v4 as uuid } from 'uuid'
import ClassWorkContext from '../../pages/ClassWork/ClassWorkContext'
import { useAlamContext } from '../../client'
import { MdRemoveRedEye } from 'react-icons/md'
import { IoEarOutline } from 'react-icons/io5'
import { Kinesthetic, Read } from '../../components/Icons'


export type MaterialModalProps = {
  isOpen: boolean
  onClose: () => void
  students?: ClassUser[]
  size?: string
  material?: MaterialType
  materialAssignmentRefetch: () => void
  topics: Array<{ name: string; id: string }>
}

export const MaterialModal = ({
  isOpen,
  onClose,
  materialAssignmentRefetch,
  material = {
    id: '',
    title: '',
    description: '',
    schedule: '',
    learningStyle: ['visual', 'aural', 'read', 'kinesthetic'],
    classId: '',
    topicId: '',
    isShown: true,
    addNewUsers: true,
    addNewGroups: true,
    inserted_at: '',
    comments: [],
    materialUsers: [],
    attachments: [],
    materialGroups: [],
  },
  topics,
  students,
  size = 'xl',
}: MaterialModalProps): JSX.Element => {
  const { getClassTopics, groups } = useContext(ClassWorkContext)
  const { user } = useAlamContext()
  const [createMaterial, { data: createMaterialData, loading: createMaterialIsSubmitting, error: createMaterialError }] = useMutation(
    CREATE_MATERIAL,
    {
      onError: (err) => {
        onClose()
        useErrorToast({ message: 'Material creation failed' })
      },
    }
  )

  const [updateMaterial, { loading: updateMaterialIsSubmitting }] = useMutation(
    UPDATE_MATERIAL,
    {
      onError: (err) => {
        console.warn(err)
        onClose()
        useErrorToast({ message: `Material wasn't updated` })
      },
    }
  )
  const [createAttachment, { loading: attachmentIsSubmitting }] = useMutation(
    CREATE_ATTACHMENT
  )
  const [deleteAttachment, { loading: attachmentIsDeleting }] = useMutation(
    DELETE_ATTACHMENT
  )

  const studentsCount = students?.length || 0
  const groupsCount = groups?.length || 0

  const onSubmit = async ({
    id,
    title,
    description,
    materialUsers,
    schedule,
    isShown: is_shown,
    addNewUsers: add_new_users,
    addNewGroups: add_new_groups,
    classId: class_id,
    learningStyle: learning_style,
    topicId,
    files,
    removedFiles,
    materialGroups,
  }: MaterialInput) => {
    try {
      const topic_id = topicId ? topicId : null

      if (id) {
        const idFoundPromises = await Promise.all([
          submitAttachments(files, id),
          handleDeleteAttachments(removedFiles),
          updateMaterial({
            variables: {
              id: id,
              changes: {
                class_id,
                description,
                is_shown,
                learning_style,
                schedule,
                title,
                topic_id,
                add_new_users,
                add_new_groups,
              },
              material_users: generateInsertMaterialUsers(materialUsers, id),
              material_groups: generateMaterialGroups(materialGroups, id),
            },
          }),
        ])
      } else {
        const materialId = uuid()
        const ariTestInputs = {
            variables: {
              id: materialId,
              class_id,
              title,
              description,
              schedule,
              learning_style,
              is_shown,
              user_id: user.id,
              material_users: generateMaterialUsers(materialUsers),
              topic_id,
              material_groups: generateMaterialGroups(
                materialGroups,
                materialId
              ),
              add_new_groups,
              add_new_users,
            },
          }

        const newIdPromises = await Promise.all([
          createMaterial({
            variables: {
              id: materialId,
              class_id,
              title,
              description,
              schedule,
              learning_style,
              is_shown,
              user_id: user.id,
              material_users: generateMaterialUsers(materialUsers),
              topic_id,
              material_groups: generateMaterialGroups(
                materialGroups,
                materialId
              ),
              add_new_groups,
              add_new_users,
            },
          }),
          submitAttachments(files, materialId),
        ])
      }

      getClassTopics()
      materialAssignmentRefetch()
      onClose()
      console.log(`createMaterialData: ${JSON.stringify(createMaterialData)}`)
      console.log(`createMaterialError: ${JSON.stringify(createMaterialError)}`)
      if (createMaterialData && !createMaterialError) {
        useSuccessToast({
          message: `Material was successfully ${id ? 'updated' : 'created'}`,
        })
      }
    } catch (err) {
      getClassTopics()
      materialAssignmentRefetch()
      onClose()
      useErrorToast({
        message: `Error ${
          id ? 'updating' : 'creating'
        } material`,
      })
    }
  }

  const handleDeleteAttachments = (removedFiles: Attachment[]) => {
    try {
      return Promise.all(
        removedFiles?.map(({ id }) =>
          deleteAttachment({
            variables: { id },
          })
        )
      )
    } catch (err) {
      return err
    }
  }

  const submitAttachments = (files: File[], materialId: string) => {
    try {
      return Promise.all(
        files?.map((file) =>
          createAttachment({
            variables: {
              materialId,
              file,
            },
          })
        )
      )
    } catch (err) {
      return err
    }
  }

  const learningStyles = [
    {
      value: 'visual',
      display: (
        <>
          <Flex align='center'>
            <Icon as={MdRemoveRedEye} boxSize='14px' />
            <Text marginLeft='12px'>Visual</Text>
          </Flex>
        </>
      ),
    },
    {
      value: 'aural',
      display: (
        <>
          <Flex align='center'>
            <Icon as={IoEarOutline} boxSize='14px' />
            <Text marginLeft='12px'>Aural</Text>
          </Flex>
        </>
      ),
    },
    {
      value: 'read',
      display: (
        <>
          <Flex align='center'>
            <Read boxSize='14px' />
            <Text marginLeft='12px'>Read/Write</Text>
          </Flex>
        </>
      ),
    },
    {
      value: 'kinesthetic',
      display: (
        <>
          <Flex align='center'>
            <Kinesthetic boxSize='14px' />
            <Text marginLeft='12px'>Kinesthetic</Text>
          </Flex>
        </>
      ),
    },
  ]

  const topicOption = topics.map(({ name, id }) => {
    return { value: id, display: truncate(name, 24) }
  })

  return (
    <>
      <Modal
        variant='withDivider'
        isOpen={isOpen}
        onClose={onClose}
        size={size}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {material.id ? 'Edit ' : 'Create '} Material
          </ModalHeader>
          <ModalCloseButton />
          <MaterialForm
            onSubmit={onSubmit}
            material={material}
            learningStyles={learningStyles}
            students={students}
            topics={topicOption}
            createMaterialIsSubmitting={
              createMaterialIsSubmitting || attachmentIsSubmitting
            }
            updateMaterialIsSubmitting={
              updateMaterialIsSubmitting ||
              attachmentIsSubmitting ||
              attachmentIsDeleting
            }
            groups={groups}
          />
        </ModalContent>
      </Modal>
    </>
  )
}
