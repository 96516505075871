import React from 'react'
import { IconAlamProps } from './Type'

export const AddPeople = ({
  fill,
  width,
  height,
  viewBox,
}: IconAlamProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M23 22C20.33 22 15 23.33 15 26V28H31V26C31 23.33 25.67 22 23 22ZM14 18V15H12V18H9V20H12V23H14V20H17V18H14ZM23 20C24.0609 20 25.0783 19.5786 25.8284 18.8284C26.5786 18.0783 27 17.0609 27 16C27 14.9391 26.5786 13.9217 25.8284 13.1716C25.0783 12.4214 24.0609 12 23 12C21.9391 12 20.9217 12.4214 20.1716 13.1716C19.4214 13.9217 19 14.9391 19 16C19 17.0609 19.4214 18.0783 20.1716 18.8284C20.9217 19.5786 21.9391 20 23 20Z'
      fill={fill}
    />
  </svg>
)

AddPeople.defaultProps = {
  fill: '#17053D',
  width: '40',
  height: '40',
  viewBox: '0 0 40 40',
}
