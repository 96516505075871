import { Message as MessageType } from '../../types'
import { Flex, Box } from '@chakra-ui/react'
import { useEffect } from 'react'
import { RenderGroupMessages } from '../RenderGroupMessages'

export type MessagesProps = {
  messages: MessageType[]
  scrollHook: JSX.Element
  scrollToBottom: () => void
}

export const Messages = ({
  messages,
  scrollHook,
  scrollToBottom,
}: MessagesProps): JSX.Element => {
  useEffect(() => {
    scrollToBottom()
  }, [messages])

  const groupLogs = messages.reduce((logs: any, message) => {
    const date = message.inserted_at.slice(0, -9)
    logs[date] = logs[date] ? [...logs[date], message] : [message]

    return logs
  }, {})

  const renderGroupLogs = () => {
    return Object.entries(groupLogs).map(([groupDate, messages]) => {
      return (
        <RenderGroupMessages
          key={groupDate}
          groupDate={groupDate}
          messages={messages}
        />
      )
    })
  }

  return messages.length === 0 ? (
    <Box flex={3} borderBottomWidth='0.5px' borderStyle='solid'>
      <Flex
        align='center'
        justify='center'
        width='100%'
        height='100%'
        color='primary.2'
        textStyle='body.big'
      >
        No messages
      </Flex>
    </Box>
  ) : (
    <Flex
      width='100%'
      height='100%'
      direction='column'
      borderBottomWidth='0.5px'
      borderStyle='solid'
      overflowY='scroll'
      pt='16px'
    >
      {/* {messages.map((message) => (
        <Message key={message.id} message={message} />
      ))} */}

      {renderGroupLogs()}

      {scrollHook}
    </Flex>
  )
}

export default Messages
