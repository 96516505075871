import { Fragment } from 'react'
import {
  Container,
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  Text,
  VStack,
  StackDivider,
} from '@chakra-ui/react'
import { Select } from '../../components/Input'
import { Layout } from '../../components/Layout'
import { TopNavItemProps } from '../../components/TopNav'
import { MdFileDownload } from 'react-icons/md'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { GET_NO_TOPIC_ASSIGNMENTS } from '../../client/queries'
import { AssignmentTypes } from './TopicsPage'
import { useHistory } from 'react-router-dom'
import { DownloadButton } from '../../components/Analytics'

type NoTopicPageProps = {
  topNavLeft: TopNavItemProps[]
  topNavCenter: TopNavItemProps[]
  topicOptions: Array<{ value: string; display: string }>
  handleTopicChange: (val: string) => void
}

const NoTopicPage = ({
  topNavLeft,
  topNavCenter,
  topicOptions,
  handleTopicChange,
}: NoTopicPageProps): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const { data } = useQuery(GET_NO_TOPIC_ASSIGNMENTS, {
    variables: { class_id: id },
  })

  const history = useHistory()

  const assignments: AssignmentTypes[] = data?.classes_by_pk?.assignments || []
  const assignmentUrl = (assignmentId: string) => {
    return `/classes/${id}/analytics/class/assignment/${assignmentId}`
  }

  return (
    <Layout topNavLeft={topNavLeft} topNavCenter={topNavCenter} showSideNav>
      <Fragment>
        <Box px={6} pt={6} mb={8}>
          <Flex align='center' justify='space-between'>
            <Select
              value='no-topic'
              options={topicOptions}
              onChange={handleTopicChange}
              menuButtonProps={{
                textStyle: 'button',
                variant: 'tab.selector',
                textAlign: 'left',
                py: '8px',
                pr: '8px',
                w: '196px',
                layerStyle: 'card.module',
                color: 'primary.1',
              }}
              menuItemProps={{
                textStyle: 'body.1',
                color: 'primary.1',
                w: '196px',
                _hover: { bg: 'hover.2', color: 'white.1' },
                overflow: 'hidden',
              }}
              hasTextDisplay={true}
              textDisplayProps={{
                maxW: '18ch',
                isTruncated: true,
                textAlign: 'left',
                textStyle: 'button',
              }}
            />

            <DownloadButton classId={id} topicId='no-topic' />
          </Flex>
        </Box>
        <Container
          maxW={{ base: '100%', md: '456px', lg: '500px', xl: '1080px' }}
          px={0}
        >
          <Text textStyle='h2' mb={6} color='black.1'>
            No-topic
          </Text>
          {assignments && assignments.length > 0 && (
            <Box
              bg='white.1'
              borderRadius='12px'
              p={10}
              mb={10}
              borderWidth='0.5px'
              borderStyle='solid'
              borderColor='primary.2'
            >
              <VStack
                w='100%'
                alignItems='flex-start'
                divider={<StackDivider layerStyle='dividerGrey' />}
                spacing={4}
              >
                <Box>
                  <Text textStyle='h5' color='primary.1'>
                    Assignments
                  </Text>
                </Box>

                {assignments
                  .filter(
                    (assign: AssignmentTypes) => assign.type === 'assignment'
                  )
                  .map((assignment: AssignmentTypes) => (
                    <Flex
                      align='center'
                      justify='space-between'
                      w='100%'
                      key={assignment.id}
                    >
                      <Box maxW='calc(100% - 100px)'>
                        <Text
                          textStyle='body.semibold'
                          color='primary.1'
                          cursor='pointer'
                          onClick={() =>
                            history.push(assignmentUrl(assignment.id))
                          }
                          isTruncated
                        >
                          {assignment.name}
                        </Text>
                        <Text
                          textStyle='subtitle'
                          color='rgba(1, 0, 2, 0.56)'
                          isTruncated
                        >
                          {assignment.description}
                        </Text>
                      </Box>
                      <Box w='90px'>
                        <Text
                          textStyle='smallstate'
                          letterSpacing='0.02em'
                          cursor='pointer'
                          color='primary.1'
                          onClick={() =>
                            history.push(assignmentUrl(assignment.id))
                          }
                        >
                          View Analytics
                        </Text>
                      </Box>
                    </Flex>
                  ))}
              </VStack>
            </Box>
          )}
        </Container>
      </Fragment>
    </Layout>
  )
}

export default NoTopicPage
