const colors = {
  primary: {
    1: '#17053D',
    2: 'rgba(23, 5, 61, 0.32)',
    '2-7': 'rgba(23,5,61,0.12)',
    '2-5': 'rgba(23, 5, 61, 0.08)',
    3: 'rgba(23, 5, 61, 0.05)',
  },
  secondary: {
    1: '#CE1032',
    2: 'rgba(224, 19, 0, 0.32)',
    3: '#FF843F',
  },
  accent: {
    1: '#4204C4',
    2: 'rgba(66, 4, 196, 0.32)',
  },
  disabled: {
    1: '#E6E6E7',
    2: '#C1BACD',
  },
  hover: {
    1: '#DBD1F1',
    2: '#5214D3',
  },
  error: {
    1: '#FF0000',
    2: 'rgba(255, 0, 0, 0.32)',
  },
  black: {
    0: 'rgba(0, 0, 0, 1)',
    1: '#010002',
    2: 'rgba(1, 0, 2, 0.72)',
    3: 'rgba(1, 0, 2, 0.32)',
    4: 'rgba(1, 0, 2, 0.56)',
    5: 'rgba(1, 0, 2, 0.88)',
    6: 'rgba(0, 0, 0, 0.88)',
  },
  white: {
    1: '#FFFFFF',
    2: 'rgba(255, 255, 255, 0.72)',
    3: 'rgba(255, 255, 255, 0.24)',
    4: 'rgba(255, 255, 255, 0.16)',
  },
  grey: {
    1: '#9B9B9C',
    2: '#EDECF0',
    3: '#979797',
    border: '#E4E4E4',
    session: '#717071',
  },
  active: {
    1: '#FFFFFF',
    2: '#17053D',
  },
  overlay: '#05010D',
  modal: {
    text: 'rgba(0, 0, 0, 0.56)',
  },
  transparent: 'rgba(0, 0, 0, 0)',
  gdocsblue: 'rgb(33, 150, 243)',
  excelgreen: '#2A8947',
  pptorange: '#BC3211',
  pdfred: '#B30C00',
  wordblue: '#00a2ed',
  greenbase: '#2FA84F',
  assignmentsidenav: '#D1CDD8',
  bulletinModalDivider: 'rgb(226, 232, 240)',
  placeholder: '#4F4F4F',
}

export default colors
