import { gql } from '@apollo/client'
import { userDisplayFields, examFields } from './index'

export const assignmentFields = gql`
  ${userDisplayFields}
  ${examFields}
  fragment assignmentFields on assignments {
    id
    name
    description
    schedule
    dueDate: due_date
    isVisible: is_visible
    addNewUsers: add_new_users
    addNewGroups: add_new_groups
    rubric
    grading
    classId: class_id
    inserted_at
    type
    comments(where: { archived_at: { _is_null: true } }) {
      id
      content
      user {
        ...userDisplayFields
        email
      }
      inserted_at
    }
    user {
      id
      firstName: first_name
      lastName: last_name
    }
    assignmentUsers: assignment_users {
      user_id
      user {
        id
        firstName: first_name
        lastName: last_name
      }
    }
    assignmentGroups: assignment_groups {
      group_id
    }
    topicId: topic_id
    attachments {
      id
      url
      file
    }
    exams {
      ...examFields
    }
    submissions {
      status
    }
  }
`

export const studentAnalyticsAssignmentFields = gql`
  fragment studentAnalyticsAssignmentFields on assignments {
    id
    name
    inserted_at
    rubric
    grading
    class_id
    exams {
      id
      type
    }
    assignment_users_aggregate {
      aggregate {
        count
      }
    }
    assignment_groups_aggregate {
      aggregate {
        count
      }
    }
  }
`
