import theme from '@chakra-ui/theme'

const styles = {
  ...theme.styles,
  global: {
    'html, body': {
      minHeight: '100vh',
      color: 'black.1',
    },
    '#root': {
      minHeight: '100vh',
    },
    '.chakra-toast': {
      color: 'white.1',
    },
  },
}

export default styles
