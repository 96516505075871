import { subMilliseconds, millisecondsToMinutes } from 'date-fns'
import { computePercentage } from './questionAnalytics'

export interface Review {
  id: string
  started_at?: string
  completed_at?: string
}

export interface GetReviewChartDataProps {
  completeReviews: Review[]
  totalStudentCount: number
  questionCount: number
}

export interface ChartData {
  name: string
  value: number
}

export interface ReviewChartResult {
  completionRate: string
  chartData: ChartData[]
  questionCount: number
  avgCompletionTime?: string
}

export const getReviewChartData = ({
  completeReviews,
  totalStudentCount,
  questionCount,
}: GetReviewChartDataProps) => {
  if (!questionCount) {
    return
  }

  const respondentCount = completeReviews?.length
  const incompleteReviewsCount = totalStudentCount - respondentCount
  const completionRate = computePercentage(
    respondentCount,
    totalStudentCount,
    2
  )
  totalStudentCount === 0 ? 0 : (respondentCount / totalStudentCount) * 100
  const chartData = [
    {
      name: 'Incomplete Assignment Reviews',
      value: incompleteReviewsCount,
    },
    {
      name: 'Completed Assignment Reviews',
      value: respondentCount,
    },
  ]

  const totalCompletionTime =
    respondentCount === 0
      ? undefined
      : completeReviews?.reduce(
          (totalCompletionTime: number, assignmentReview: Review) => {
            const { started_at, completed_at } = assignmentReview
            const start = Date.parse(started_at!)
            const completed = Date.parse(completed_at!)

            const completionMs = Date.parse(
              subMilliseconds(completed, start).toUTCString()
            )
            return totalCompletionTime + completionMs
          },
          0
        )

  const avgCompletionTime = !totalCompletionTime
    ? undefined
    : formatCompletionTimeForReview(totalCompletionTime / respondentCount)

  return {
    completionRate,
    chartData,
    questionCount,
    avgCompletionTime,
    respondentCount,
  }
}

const formatCompletionTimeForReview = (timeMs: number) => {
  const rawHours = timeMs / 1000 / 60 / 60
  const hours = Math.floor(rawHours)
  const rawMinutes = rawHours * 60
  const minutes = Math.floor(rawMinutes)
  const rawSeconds = rawMinutes * 60
  const seconds = Math.floor(rawSeconds)

  if (hours > 0) {
    return `${hours} hrs`
  }

  if (minutes > 0) {
    return `${minutes} mins`
  }

  return `${seconds} seconds`
}

export const reviewStrokeProp = (rawCompletionRate: string | undefined) => {
  if (rawCompletionRate === undefined) {
    return {}
  }
  const completionRate = parseInt(rawCompletionRate)
  if (completionRate === 0 || completionRate === 100) {
    return {
      stroke: '',
    }
  }

  return {}
}
