import { Attachment, SessionLink as LinkType } from '../../types'
import UploadedFile from './UploadedFile'
import SessionLink from './SessionLink'

export type UploadedFileProps = {
  uploaded: Attachment | LinkType
  refetch: () => void
}

export const AttachmentLinkSorter = ({
  uploaded,
  refetch,
}: UploadedFileProps): JSX.Element => {
  if (uploaded.__typename === 'attachments') {
    return (
      <UploadedFile attachment={uploaded as Attachment} refetch={refetch} />
    )
  }

  if (uploaded.__typename === 'links') {
    return <SessionLink link={uploaded as LinkType} refetch={refetch} />
  }

  return <></>
}

export default AttachmentLinkSorter
