import { useState } from 'react'
import { Post } from '../../types'
import {
  Flex,
  VStack,
  StackDivider,
  Box,
  Text,
  MenuItem,
  useDisclosure,
} from '@chakra-ui/react'
import { AnnouncementHeading } from '../Heading'
import { BulletinModal } from './BulletinModal'
import { HoverMenu, parentHoverCss } from '../Menu'
import { RiPencilFill } from 'react-icons/ri'

export type BulletinItemProps = {
  post: Post
  editMode?: boolean
}

export const BulletinItem = ({
  post,
  editMode,
}: BulletinItemProps): JSX.Element => {
  const { id, type, user, content, inserted_at, title, comments = [] } = post

  const {
    isOpen: modalIsOpen,
    onOpen: modalOnOpen,
    onClose: modalOnClose,
  } = useDisclosure()

  const handleItemClick = () => {
    if (!editMode) {
      modalOnOpen()
    }
  }

  return (
    <>
      <BulletinModal post={post} isOpen={modalIsOpen} onClose={modalOnClose} />
      <Box
        w='100%'
        px={6}
        py={2}
        key={id}
        _first={{ paddingTop: 3 }}
        _last={{ paddingBottom: 3 }}
        onClick={handleItemClick}
      >
        {type === 'post' && (
          <AnnouncementHeading
            title={content}
            postId={id}
            type={type}
            editMode={editMode}
            {...user}
            inserted_at={inserted_at}
            comments={comments.length}
            showCommentsCount
            titleProps={{
              noOfLines: 1,
            }}
          />
        )}
        {type === 'announcement' && (
          <>
            <AnnouncementHeading
              type={type}
              postId={id}
              editMode={editMode}
              inserted_at={inserted_at}
              title={title}
              comments={comments.length}
              {...user}
              showCommentsCount
              titleProps={{
                noOfLines: 1,
              }}
              isBulletinBoard
            />
          </>
        )}
      </Box>
    </>
  )
}
