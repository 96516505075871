import {
  Question,
  User,
  UserForQuestionData,
  Answer,
  ResponseChoicesArrangement,
  ResponseAnalytics,
  GroupForQuestionData,
} from '../types'
import { format } from 'date-fns'

export interface TextQuestionData {
  id: string
  user?: User | UserForQuestionData
  answers: Answer[]
  inserted_at?: string
  group?: GroupForQuestionData
}

export interface ChoiceGraph {
  name: string
  count: number
}

export interface ChoiceStudentData {
  id: string
  user?: User | UserForQuestionData
  answers: string[]
  inserted_at?: string
  group?: GroupForQuestionData
}

export const constructTextQuestionData = (
  question: Question
): TextQuestionData[] => {
  const responses = question?.responses || []
  return responses.map((response: ResponseAnalytics) => {
    const user =
      response?.exam_response?.user || response?.exam_response?.group_user?.user
    const group =
      response?.exam_response?.group_user?.group ||
      response?.exam_response?.user?.group_users[0]?.group

    return {
      id: response?.id,
      user,
      answers: response?.answers || [],
      group: group,
    }
  })
}

export const constructTextQuestionDataLimited = (
  question: Question,
  responseCount: number
) => {
  const shortened = {
    ...question,
    responses: question?.responses?.slice(0, responseCount),
  }

  return constructTextQuestionData(shortened) || []
}

export const getChoices = (question: Question): ChoiceGraph[] => {
  const arrangements = question?.response_choices_arrangements
  if (!arrangements || arrangements.length < 1) {
    return []
  }

  return arrangements.map((arrangement) => {
    return {
      name: arrangement.response_choice.text,
      count: 0,
    }
  })
}

export const constructChoiceStudentData = (
  question: Question
): ChoiceStudentData[] => {
  const responses = question?.responses
  if (!responses || responses.length < 1) {
    return []
  }

  return (
    responses?.map((response) => {
      const user =
        response?.exam_response?.user ||
        response?.exam_response?.group_user?.user
      const group =
        response?.exam_response?.group_user?.group ||
        response?.exam_response?.user?.group_users[0]?.group

      return {
        id: response?.id,
        user,
        answers: response?.answers?.map(({ text }: Answer) => {
          return text
        }),
        inserted_at: response?.answers[0]?.inserted_at,
        group,
      }
    }) || []
  )
}

export const populateChoiceStudentData = (
  choices: ChoiceGraph[],
  studentData: ChoiceStudentData[]
): ChoiceGraph[] => {
  return studentData.reduce(
    (graphData: ChoiceGraph[], student: ChoiceStudentData): ChoiceGraph[] => {
      const answers = student.answers
      if (answers.length < 1) {
        return graphData
      }

      return answers.reduce(
        (graphData: ChoiceGraph[], answer): ChoiceGraph[] => {
          const index = graphData.findIndex(
            ({ name }: ChoiceGraph) => name === answer
          )

          if (index === -1) return graphData

          const currentChoice = graphData[index]

          graphData[index] = {
            ...currentChoice,
            count: currentChoice.count + 1,
          }

          return graphData
        },
        graphData
      )
    },
    choices
  )
}

export const transformCountToPercentage = (
  graphData: ChoiceGraph[],
  totalCount: number
): ChoiceGraph[] => {
  return graphData.map(
    (graphData: ChoiceGraph): ChoiceGraph => {
      return {
        ...graphData,
        count: computePercentageRaw(graphData.count, totalCount),
      }
    }
  )
}

export const computePercentage = (
  count: number,
  totalCount: number,
  decimalPlaces: number
) => {
  const percentage = (count / totalCount) * 100

  if (isNaN(percentage)) {
    const zero = 0
    return zero.toString()
  } else if (percentage % 1 > 0) {
    return percentage.toFixed(decimalPlaces)
  } else {
    return percentage.toString()
  }
}

export const computePercentageRaw = (
  count: number,
  totalCount: number
): number => {
  const percentage = (count / totalCount) * 100

  if (isNaN(percentage)) {
    return 0
  }

  return percentage
}

export const renderQuestionDate = (date: string | undefined) => {
  if (!date) return 'N/A'

  return format(new Date(date), 'MM/dd/yyyy')
}

export const answersToString = (answers: string[]): string => {
  if (answers.length === 0) {
    return 'N/A'
  }

  return answers.join(', ')
}
