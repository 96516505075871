import { useState, useMemo, useEffect } from 'react'
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  BarChart,
  YAxis,
  XAxis,
  Bar,
  LabelList,
  Label,
  CartesianGrid,
  Tooltip,
} from 'recharts'
import {
  Container,
  Box,
  Flex,
  VStack,
  HStack,
  StackDivider,
  Text,
} from '@chakra-ui/react'
import { QuestionPageContentProps } from './QuestionPageContent'
import { NotEnoughData } from './NotEnoughData'
import {
  getChoices,
  constructChoiceStudentData,
  populateChoiceStudentData,
  ChoiceGraph,
  ChoiceStudentData,
  renderPercentageCustomLabel,
  transformCountToPercentage,
  usePagination,
  sortStudentQuestionData,
  gradesSortDisplay,
  getFullName,
  renderQuestionDate,
  answersToString,
  computePercentage,
  renderCustomizedPieLabel,
  pluralize,
  PercentageXAxisTick,
  CheckboxTooltip,
  TruncatedChoicesTick,
} from '../../utils'
import textStyles from '../../theme/textStyles'
import { Select } from '../../components/Input'
import { AlamAvatar } from '../../components/Avatar'

export const MultipleChoicePageContent = ({
  question,
}: QuestionPageContentProps): JSX.Element => {
  const [sortBy, setSortBy] = useState('a-z')
  const colors = ['#EE2092', '#6A61FA', '#09C6CD', '#392ACC', '#ED8D22']
  const initialGraph = getChoices(question)
  const studentData = constructChoiceStudentData(question)
  const populatedGraph = populateChoiceStudentData(initialGraph, studentData)

  const totalCount = studentData.length

  const isChoicesMany = populatedGraph.length > 5

  const finalGraph = !isChoicesMany
    ? populatedGraph
    : transformCountToPercentage(populatedGraph, totalCount)

  const sortOptions = [
    { value: 'a-z', display: 'Alphabetical A-Z' },
    { value: 'z-a', display: 'Alphabetical Z-A' },
  ]

  const sortedStudentData = useMemo(() => {
    return sortStudentQuestionData(studentData, sortBy)
  }, [studentData, sortBy])

  const {
    page,
    setPage,
    totalPages,
    currentPageContent,
    totalItems,
    renderAnalyticsPagination,
  } = usePagination(sortedStudentData)

  useEffect(() => {
    setPage(1)
  }, [sortBy])

  return (
    <>
      <Container
        maxW={{ base: '100%', md: '456px', lg: '500px', xl: '1080px' }}
        px={0}
        mb={6}
      >
        {totalCount === 0 && <NotEnoughData />}
        {totalCount > 0 && (
          <Flex
            p='20px 32px'
            h='496px'
            w='100%'
            justify='center'
            align='center'
            bg='#FFFFFF'
            borderRadius='12px'
            borderWidth='0.5px'
            borderStyle='solid'
            borderColor='primary.2'
          >
            {isChoicesMany && (
              <ResponsiveContainer>
                <BarChart
                  width={350}
                  height={264}
                  data={finalGraph}
                  barCategoryGap='30%'
                  barGap={8}
                  layout='vertical'
                  margin={{ left: 50, bottom: 30 }}
                >
                  <CartesianGrid strokeDasharray='0' horizontal={false} />
                  <YAxis
                    dataKey='name'
                    type='category'
                    style={{
                      ...textStyles.body['2'],
                      fontWeight: 400,
                      fill: 'rgba(1, 0, 2, 0.56)',
                    }}
                    interval={0}
                    tick={<TruncatedChoicesTick />}
                    tickLine={false}
                    minTickGap={10}
                    padding={{ bottom: -2 }}
                  />
                  <XAxis
                    type='number'
                    axisLine={false}
                    tickLine={false}
                    style={{
                      ...textStyles.body['2'],
                      fontWeight: 400,
                      fill: 'rgba(1, 0, 2, 0.56)',
                    }}
                    tick={<PercentageXAxisTick />}
                    ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                    dataKey='count'
                  >
                    <Label
                      value='Respondents'
                      position='insideBottomRight'
                      style={{
                        ...textStyles.body['2'],
                        fontWeight: 400,
                        fill: 'rgba(1, 0, 2, 0.56)',
                      }}
                      offset={0}
                      dy={25}
                    />
                  </XAxis>
                  <Tooltip
                    content={<CheckboxTooltip totalCount={totalCount} />}
                    cursor={false}
                  />
                  <Bar
                    dataKey='count'
                    barSize={40}
                    fill='#CE1032'
                    isAnimationActive={false}
                  >
                    <LabelList content={renderPercentageCustomLabel} />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            )}
            {!isChoicesMany && (
              <>
                <Box w='560px' h='100%'>
                  <Flex h='100%'>
                    <VStack
                      w='100%'
                      divider={
                        <StackDivider
                          layerStyle='dividerGrey'
                          my='0 !important'
                        />
                      }
                    >
                      {finalGraph.map((choice: ChoiceGraph, index: number) => (
                        <Flex
                          align='center'
                          justify='space-between'
                          w='100%'
                          minH='56px'
                          key={colors[index]}
                          grow={1}
                        >
                          <Flex align='center'>
                            <Box
                              minW='12px'
                              minH='12px'
                              borderRadius='50%'
                              bg={colors[index]}
                              mr={4}
                            />
                            <Box>
                              <Text
                                textStyle='body.semibold'
                                color='black.1'
                                fontWeight='400'
                                noOfLines={2}
                              >
                                {choice.name}
                              </Text>
                              <Text
                                fontSize='12px'
                                fontWeight='400'
                                lineHeight='16px'
                                color='grey.1'
                              >
                                {`${choice.count} ${pluralize(
                                  'student',
                                  choice.count
                                )}`}
                              </Text>
                            </Box>
                          </Flex>
                          <Flex>
                            <Text textStyle='h5' color='black.1'>
                              {computePercentage(choice.count, totalCount, 2)}%
                            </Text>
                          </Flex>
                        </Flex>
                      ))}
                    </VStack>
                  </Flex>
                </Box>

                <Flex
                  align='center'
                  justify='center'
                  flex='1'
                  h='100%'
                  mt='10px'
                >
                  <ResponsiveContainer>
                    <PieChart width={454} height={454}>
                      <Pie
                        data={finalGraph}
                        cx={227}
                        cy={227}
                        labelLine={false}
                        label={renderCustomizedPieLabel}
                        innerRadius={100}
                        outerRadius={150}
                        fill='#8884d8'
                        dataKey='count'
                        nameKey='name'
                        isAnimationActive={false}
                      >
                        {finalGraph.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={colors[index]} />
                        ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </Flex>
              </>
            )}
          </Flex>
        )}
      </Container>
      <Box px={6} py={4}>
        <Select
          value={sortBy}
          onChange={(value) => setSortBy(value)}
          options={sortOptions}
          menuButtonProps={{
            textStyle: 'button',
            variant: 'tab.selector',
            textAlign: 'left',
            py: '8px',
            pr: '8px',
            w: '196px',
            layerStyle: 'card.board',
            color: 'primary.1',
          }}
          menuItemProps={{
            textStyle: 'body.1',
            color: 'primary.1',
            w: '196px',
            _hover: { bg: 'hover.2', color: 'white.1' },
          }}
          displayTransform={gradesSortDisplay}
        />
      </Box>
      <Box
        mx={6}
        my={4}
        css={{
          '& > :last-child': {
            borderRadius: '0px 0px 8px 8px',
          },
          '& > :first-child': {
            borderRadius: '8px 8px 0px 0px',
          },
        }}
      >
        <Flex
          w='100%'
          bg='white.1'
          borderWidth='0.5px'
          borderColor='primary.2'
          css={{
            '& > :nth-child(2)': {
              borderLeftWidth: '0.5px',
              borderRightWidth: '0.5px',
              borderColor: 'rgba(23, 5, 61, 0.32)',
            },
          }}
        >
          <Box py={3} px={6} w='40%'>
            <Text
              textStyle='smallstate'
              textTransform='uppercase'
              color='primary.2'
              letterSpacing='0.02em'
            >
              students
            </Text>
          </Box>
          <Box py={3} px={6} w='30%'>
            <Text
              textStyle='smallstate'
              textTransform='uppercase'
              color='primary.2'
              letterSpacing='0.02em'
            >
              submission date
            </Text>
          </Box>
          <Box py={3} px={6} w='30%'>
            <Text
              textStyle='smallstate'
              textTransform='uppercase'
              color='primary.2'
              letterSpacing='0.02em'
            >
              choices
            </Text>
          </Box>
        </Flex>
        {currentPageContent?.map((data: ChoiceStudentData) => (
          <Flex
            w='100%'
            bg='white.1'
            key={data?.id}
            borderColor='primary.2'
            borderWidth='0.5px'
            borderTopWidth='0px'
            css={{
              '& > :nth-child(2)': {
                borderLeftWidth: '0.5px',
                borderRightWidth: '0.5px',
                borderColor: 'rgba(23, 5, 61, 0.32)',
              },
            }}
          >
            <Box py={3} px={6} w='40%'>
              <Flex align='center'>
                <AlamAvatar size='lg' mr={2} src={data?.user?.avatar} />
                <Box>
                  <Text
                    textStyle='smallstate'
                    textTransform='uppercase'
                    letterSpacing='0.02em'
                    mb={1}
                    fontSize='14px'
                    color='primary.1'
                  >
                    {getFullName(data?.user)}
                  </Text>
                  <Box>
                    <HStack
                      spacing={2}
                      divider={<Box layerStyle='bulletpoint' />}
                    >
                      <Text textStyle='subtitle' color='grey.1' fontSize='12px'>
                        Student
                      </Text>
                      {data?.group && (
                        <Text
                          textStyle='subtitle'
                          color='grey.1'
                          fontSize='12px'
                        >
                          {data?.group?.name}
                        </Text>
                      )}
                    </HStack>
                  </Box>
                </Box>
              </Flex>
            </Box>
            <Flex py={3} px={6} w='30%' align='center'>
              <Text
                textStyle='smallstate'
                textTransform='uppercase'
                letterSpacing='0.02em'
              >
                {renderQuestionDate(data?.inserted_at)}
              </Text>
            </Flex>
            <Flex py={3} px={6} w='30%' flex='1' align='center'>
              <Text
                textStyle='smallstate'
                textTransform='uppercase'
                letterSpacing='0.02em'
              >
                {answersToString(data?.answers)}
              </Text>
            </Flex>
          </Flex>
        ))}
      </Box>
      {renderAnalyticsPagination()}
    </>
  )
}
