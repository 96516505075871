import { extendTheme } from '@chakra-ui/react'
import colors from './colors'
import fonts from './fonts'
import textStyles from './textStyles'
import layerStyles from './layerStyles'
import styles from './styles'
import sizes from './sizes'
import breakpoints from './breakpoints'
import components from './components'

const overrides = {
  colors,
  fonts,
  textStyles,
  layerStyles,
  sizes,
  styles,
  breakpoints,
  components,
}

export default extendTheme(overrides)
