import React, { useState } from 'react'
import { useSubscription, useMutation } from '@apollo/client'
import {
  GET_ASSIGNMENT,
  UPDATE_INDIVIDUAL_VIEWED_AT,
} from '../../client/queries'
import { useAlamContext } from '../../client'
import { BiChevronLeft } from 'react-icons/bi'
import SubmissionPage from '../Assignment/Submission'
import Assignment from '../Assignment/Assignment'
import { Route, useRouteMatch } from 'react-router'
import { useHistory } from 'react-router-dom'
import { AssignmentType } from '../Assignment/AssignmentPage'

export type PretopicPageProps = {
  id: string
  pretopicId: string
}

const PretopicPage = ({ id, pretopicId }: PretopicPageProps): JSX.Element => {
  const { user } = useAlamContext()
  const [assignment, setAssignment] = useState<AssignmentType>({
    id: '',
    name: '',
    description: '',
    user: {
      id: '',
      email: '',
      firstName: '',
      lastName: '',
      avatar: '',
    },
    schedule: '',
    isVisible: true,
    inserted_at: '',
    comments: [],
    class: {
      subject: '',
      name: '',
      id: '',
    },
    assessments: [],
    grading: '',
    rubric: '',
    topic: {
      name: '',
    },
    attachments: [],
    examsForStudents: [],
    examsForGroups: [],
    examsForTeachers: [],
  })

  const { data, loading, error } = useSubscription(GET_ASSIGNMENT, {
    variables: { id: pretopicId },
    onSubscriptionData: ({
      subscriptionData: {
        data: { assignments_by_pk },
      },
    }) => {
      setAssignment(assignments_by_pk)
    },
  })

  const backUrl = `/classes/${id}/classwork`

  const history = useHistory()
  const topNavLeft = [
    {
      type: 'textWithIconButton' as const,
      text: assignment?.topic?.name || assignment?.name,
      icon: <BiChevronLeft />,
      onClick: () => history.push(backUrl),
    },
  ]
  const { path, url } = useRouteMatch()
  const assignmentNav = [
    {
      type: 'link' as const,
      url,
      text: 'Test Info',
    },
    {
      type: 'link' as const,
      url: `${url}/submissions`,
      text: 'Submissions',
    },
  ]

  const [updateIndividualViewedAt] = useMutation(UPDATE_INDIVIDUAL_VIEWED_AT)

  updateIndividualViewedAt({
    variables: {
      assignment_id: pretopicId,
      user_id: user?.id,
    },
  })

  return (
    <>
      <Route path={path} exact>
        <Assignment
          assignment={assignment}
          topNavLeft={topNavLeft}
          assignmentNav={assignmentNav}
          loading={[loading]}
          pendingExams={[]}
        />
      </Route>

      <Route path={`${path}/submissions`}>
        <SubmissionPage
          assignment={assignment}
          assignmentId={pretopicId}
          classId={id}
          assignmentNav={assignmentNav}
          loading={[loading]}
          pendingExams={[]}
        />
      </Route>
    </>
  )
}

export default PretopicPage
