import { Flex, Icon, Text } from '@chakra-ui/react'
import { History } from '../Icons'
import { EventLog } from '../../types'
import { format } from 'date-fns'

export interface SubmissionHistoryItemProps extends EventLog {
  grading?: string
  currentUserRole: string
}

export const SubmissionHistoryItem = ({
  content,
  inserted_at,
  grading,
  grade,
  currentUserRole,
}: SubmissionHistoryItemProps): JSX.Element => {
  return (
    <Flex
      w='100%'
      h='40px'
      align='center'
      pl='22px'
      pr='20px'
      _notLast={{
        borderBottomStyle: 'solid',
        borderBottomWidth: '.5px',
        borderBottomColor: 'primary.2',
      }}
    >
      <Flex justify='space-between' align='center' w='100%'>
        <Flex w='100%' align='center' position='relative'>
          <Icon as={History} color='primary.1' h='100%' />
          <Text
            position='absolute'
            top='45%'
            transform='translateY(-40%)'
            left='16px'
            textStyle='body.2'
            fontSize='14px'
            ml='12px'
            textTransform='capitalize'
          >
            {`${content} ${format(
              new Date(inserted_at),
              'MMM d, yyyy, kk:mm '
            )} UTC`}
          </Text>
        </Flex>

        {/** For teacher only show grade for graded logs **/}
        {['teacher', 'admin', 'creator'].includes(currentUserRole) &&
          content === 'graded' &&
          grade?.grade && (
            <Flex
              textStyle='body.2'
              fontSize='14px'
            >{`${grade?.grade}/${grading}`}</Flex>
          )}

        {currentUserRole === 'student' && grade?.grade && (
          <Flex
            textStyle='body.2'
            fontSize='14px'
          >{`${grade?.grade}/${grading}`}</Flex>
        )}
      </Flex>
    </Flex>
  )
}
