import React, { useState, useEffect, useMemo } from 'react'
import { Layout } from '../../components/Layout'
import {
  Container,
  Box,
  Text,
  Flex,
  IconButton,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormErrorIcon,
  Input,
  InputGroup,
  InputRightElement,
  Switch,
  useDisclosure,
  useMediaQuery,
  useClipboard,
} from '@chakra-ui/react'
import { ConfirmationModal } from '../../components/Modal'
import { MdContentCopy } from 'react-icons/md'
import { useRouteMatch } from 'react-router'
import { useHistory } from 'react-router-dom'
import { useAlamContext } from '../../client'
import {
  ARCHIVE_CLASS,
  LEAVE_CLASS,
  UPDATE_CLASS,
  GET_ALL_CLASSES,
} from '../../client/queries'
import { useMutation, useSubscription } from '@apollo/client'
import { useForm, FormProvider } from 'react-hook-form'
import { FormInput, FormButton } from '../../components/Input'
import {
  useErrorToast,
  useSuccessToast,
  validateSpaceOnly,
  uniqueClassNameValidation,
  buildURL,
} from '../../utils'
import { IoMdDocument } from 'react-icons/io'
import { RenderForUser } from '../../components/SharedComponents'
import { Class as ClassType } from '../../types'
import { ContentCopy } from '../../components/Icons'

export type ClassFormInput = {
  name: string
  subject?: string
}

const ClassSettingsPage = (): JSX.Element => {
  const topNavLeft = [{ type: 'text' as const, text: 'Class Settings' }]
  const alamContext = useAlamContext()
  const { classUser } = alamContext
  const userClass = classUser?.class || {
    name: '',
    subject: '',
    code: '',
    can_send_invite: true,
  }
  const classCode = userClass.code
  const inviteLink = buildURL({
    host: window.location.host,
    path: `/join/${classCode}`,
  })
  const { hasCopied, onCopy } = useClipboard(inviteLink)
  const {
    hasCopied: hasCopiedClassCode,
    onCopy: onCopyClassCode,
  } = useClipboard(classCode)
  const [canSendInvite, setCanSendInvite] = useState(userClass?.can_send_invite)

  const [updateClass, { loading: updateClassIsLoading }] = useMutation(
    UPDATE_CLASS
  )

  const {
    handleSubmit,
    register,
    errors,
    reset,
    watch,
    formState: { isValid },
  } = useForm<ClassFormInput>({
    mode: 'onChange',
    defaultValues: { name: userClass?.name, subject: userClass?.subject },
  })

  useEffect(() => {
    reset({ name: userClass?.name, subject: userClass?.subject })
  }, [userClass?.name, userClass?.subject])

  const { data: allClasses } = useSubscription(GET_ALL_CLASSES)

  const currentClassNames = useMemo(() => {
    if (allClasses) {
      return allClasses.classes.map(({ name }: ClassType) => name)
    }
    return []
  }, [allClasses])

  const validateClassName = (className: string) => {
    if (className === userClass?.name) return true
    return uniqueClassNameValidation(className, currentClassNames)
  }

  const { name, subject } = watch()

  const isDisabled =
    (name === userClass?.name && subject === userClass?.subject) || !isValid

  const [mobileDevice] = useMediaQuery([`(max-width: 650px)`])

  const [archiveClass, { loading: archiveClassIsLoading }] = useMutation(
    ARCHIVE_CLASS
  )

  const [leaveClass, { loading: leaveClassIsLoading }] = useMutation(
    LEAVE_CLASS
  )

  const history = useHistory()

  const handleCopyInviteLink = () => {
    console.log('Invite Link Copied')
    onCopy()
  }

  const handleCopyInviteCode = () => {
    console.log('Invite Code Copied')
    onCopyClassCode()
  }

  useEffect(() => {
    setCanSendInvite(userClass.can_send_invite)
  }, [userClass.can_send_invite])

  const handleInviteAccess = () => {
    try {
      updateClass({
        variables: {
          id: userClass?.id,
          name: userClass?.name,
          subject: userClass?.subject,
          can_send_invite: !canSendInvite,
        },
      }).then(() => {
        useSuccessToast({
          title: 'Class updated',
          icon: IoMdDocument,
        })
        onCloseInviteAccess()
      })
    } catch (error) {
      console.warn(error)
      onCloseInviteAccess()
      useErrorToast({ message: 'Update Class failed' })
    }
  }

  const handleDeleteClass = () => {
    archiveClass({
      variables: {
        id: classUser?.class?.id,
      },
    })
      .then(() => {
        useSuccessToast({ message: 'Successfully deleted class' })
        history.push('/')
      })
      .catch((err) => {
        console.log(err)
        useErrorToast({})
      })
  }

  const handleLeaveClass = () => {
    leaveClass({
      variables: {
        id: classUser?.id,
      },
    })
      .then(() => {
        useSuccessToast({ message: 'Successfully left the class' })
        history.push('/')
      })
      .catch((err) => {
        console.log(err)
        useErrorToast({})
      })
  }

  const handleUpdateClass = ({ name, subject }: ClassFormInput) => {
    updateClass({
      variables: {
        id: userClass?.id,
        name: name,
        subject: subject,
        can_send_invite: userClass?.can_send_invite,
      },
    })
      .then(() => {
        useSuccessToast({
          title: 'Class updated',
          icon: IoMdDocument,
        })
      })
      .catch((error) => {
        console.warn(error)
        useErrorToast({ message: 'Update Class failed' })
      })
  }

  const {
    isOpen: showInviteAccess,
    onOpen: onOpenInviteAccess,
    onClose: onCloseInviteAccess,
  } = useDisclosure()

  const {
    isOpen: showDeleteClass,
    onOpen: onOpenDeleteClass,
    onClose: onCloseDeleteClass,
  } = useDisclosure()

  const {
    isOpen: showLeaveClass,
    onOpen: onOpenLeaveClass,
    onClose: onCloseLeaveClass,
  } = useDisclosure()

  const { path } = useRouteMatch()
  const isSettingsPage = path.indexOf('settings')
  const showSettingsIconButton = isSettingsPage > -1 ? true : false

  return (
    <React.Fragment>
      <RenderForUser roles={['admin', 'creator']}>
        <ConfirmationModal
          isOpen={showDeleteClass}
          onClose={onCloseDeleteClass}
          title='Are you sure?'
          message={`${classUser?.class?.name} will be deleted. This process is irreversible, and all the data and content will be removed and no longer accessible.`}
          onSubmit={handleDeleteClass}
        />
      </RenderForUser>
      <ConfirmationModal
        isOpen={showLeaveClass}
        onClose={onCloseLeaveClass}
        title='Are you sure?'
        message={`You will no longer be able to access the class. This process is irreversible, and all the data and content will be removed and no longer accessible.`}
        onSubmit={handleLeaveClass}
      />
      <ConfirmationModal
        isOpen={showInviteAccess}
        onClose={onCloseInviteAccess}
        title='Are you sure?'
        message={`All previous invite links and codes will no longer allow new students to enter.`}
        onSubmit={handleInviteAccess}
        isSubmitting={updateClassIsLoading}
      />
      <Layout showSideNav hasRightNav topNavLeft={topNavLeft}>
        <Container
          maxW={{ base: '100%', md: '456px', lg: '500px', xl: '668px' }}
          py={12}
          pt={{ base: 4, md: 8, xl: 14 }}
          px={mobileDevice ? 0 : 6}
          h='auto'
        >
          <RenderForUser roles={['admin', 'teacher', 'creator']}>
            <form onSubmit={handleSubmit(handleUpdateClass)}>
              <Box
                w='100%'
                layerStyle={mobileDevice ? '' : 'card.board'}
                boxShadow='rgb(0 0 0 / 10%) 0px 4px 6px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px'
                bg='white.1'
                px={mobileDevice ? 4 : 8}
                py={10}
                mb={10}
              >
                <Flex align='center' justify='space-between' mb={8}>
                  <Text textStyle='h2' color='primary.1'>
                    Class Info
                  </Text>
                  <Button
                    type='submit'
                    variant='base.primary'
                    textStyle='button'
                    disabled={isDisabled}
                    isLoading={updateClassIsLoading}
                  >
                    Save
                  </Button>
                </Flex>
                <Flex direction='column'>
                  <FormControl mb={4} isInvalid={!!errors.name?.message}>
                    <FormLabel
                      variant='required'
                      letterSpacing='0.1rem'
                      fontWeight='600'
                    >
                      Title
                    </FormLabel>
                    <Input
                      name='name'
                      ref={register({
                        required: 'Name is a required field',
                        maxLength: {
                          value: 255,
                          message: 'Name exceeded 255 maximum characters',
                        },
                        validate: {
                          validateSpaceOnly,
                          validateClassName,
                        },
                      })}
                      placeholder='Class 1A'
                      border='0.5px solid'
                      borderColor='grey.border'
                    />
                    {errors.name && (
                      <FormErrorMessage>
                        <FormErrorIcon />
                        {errors.name?.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl
                    css={{
                      '& .optional::after': {
                        letterSpacing: '0',
                      },
                    }}
                  >
                    <FormLabel
                      variant='optional'
                      letterSpacing='0.1rem'
                      fontWeight='600'
                      className='optional'
                    >
                      Subject
                    </FormLabel>
                    <Input
                      name='subject'
                      ref={register()}
                      placeholder='GSEP PSychology'
                      border='0.5px solid'
                      borderColor='grey.border'
                    />
                  </FormControl>
                </Flex>
              </Box>
            </form>
          </RenderForUser>
          <RenderForUser roles={['student']}>
            <Box
              w='100%'
              layerStyle={mobileDevice ? '' : 'card.board'}
              boxShadow='rgb(0 0 0 / 10%) 0px 4px 6px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px'
              bg='white.1'
              px={mobileDevice ? 4 : 8}
              py={10}
              mb={10}
            >
              <Flex align='center' justify='space-between' mb={8}>
                <Text textStyle='h2' color='primary.1'>
                  Class Info
                </Text>
                <Button
                  type='submit'
                  disabled
                  variant='base.primary'
                  textStyle='button'
                >
                  Save
                </Button>
              </Flex>
              <Flex direction='column'>
                <FormControl mb={4}>
                  <FormLabel
                    variant='required'
                    letterSpacing='0.1rem'
                    fontWeight='600'
                  >
                    Title
                  </FormLabel>
                  <Input
                    name='name'
                    defaultValue={userClass.name}
                    disabled
                    placeholder='Class 1A'
                    border='0.5px solid'
                    borderColor='grey.border'
                  />
                </FormControl>
                <FormControl
                  css={{
                    '& .optional::after': {
                      letterSpacing: '0',
                    },
                  }}
                >
                  <FormLabel
                    variant='optional'
                    letterSpacing='0.1rem'
                    fontWeight='600'
                    className='optional'
                  >
                    Subject
                  </FormLabel>
                  <Input
                    name='subject'
                    defaultValue={userClass.subject}
                    disabled
                    placeholder='GSEP PSychology'
                    border='0.5px solid'
                    borderColor='grey.border'
                  />
                </FormControl>
              </Flex>
            </Box>
          </RenderForUser>
          <Box
            w='100%'
            layerStyle={mobileDevice ? '' : 'card.board'}
            boxShadow='rgb(0 0 0 / 10%) 0px 4px 6px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px'
            bg='white.1'
            px={mobileDevice ? 4 : 8}
            py={10}
            mb={10}
          >
            <Text textStyle='h2' color='primary.1' mb={8}>
              General
            </Text>
            <Flex direction='column' mb={8}>
              <FormControl mb={4}>
                <FormLabel letterSpacing='0.1rem' fontWeight='600'>
                  Invite Link
                </FormLabel>
                <InputGroup size='md'>
                  <Input
                    name='invite-link'
                    value={inviteLink}
                    readonly={true}
                    border='0.5px solid'
                    borderColor='grey.border'
                  />
                  <RenderForUser roles={['admin', 'teacher', 'creator']}>
                    <InputRightElement width='4.5rem'>
                      <IconButton
                        icon={
                          <ContentCopy
                            width='16'
                            height='16'
                            viewBox='8 8 24 24'
                          />
                        }
                        aria-label='show-password'
                        disabled={!canSendInvite}
                        bg='transparent'
                        onClick={handleCopyInviteLink}
                      />
                    </InputRightElement>
                  </RenderForUser>
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel letterSpacing='0.1rem' fontWeight='600'>
                  Invite Code
                </FormLabel>
                <InputGroup size='md'>
                  <Input
                    name='invite-code'
                    value={classCode}
                    readonly={true}
                    border='0.5px solid'
                    borderColor='grey.border'
                  />
                  <RenderForUser roles={['admin', 'teacher', 'creator']}>
                    <InputRightElement width='4.5rem'>
                      <IconButton
                        icon={
                          <ContentCopy
                            width='16'
                            height='16'
                            viewBox='8 8 24 24'
                          />
                        }
                        disabled={!canSendInvite}
                        aria-label='show-password'
                        bg='transparent'
                        onClick={handleCopyInviteCode}
                      />
                    </InputRightElement>
                  </RenderForUser>
                </InputGroup>
              </FormControl>
            </Flex>
            <RenderForUser roles={['admin', 'teacher', 'creator']}>
              <Flex>
                <FormControl
                  display='flex'
                  alignItems='center'
                  justifyContent='space-between'
                  w='100%'
                >
                  <FormLabel
                    htmlFor='invite-access'
                    mb='0'
                    textStyle='body.1'
                    textTransform='capitalize'
                    fontSize='16px'
                    lineHeight='24px'
                    letterSpacing='0'
                  >
                    Allow Invite Access
                  </FormLabel>
                  <Switch
                    id='invite-access'
                    isChecked={canSendInvite}
                    onChange={onOpenInviteAccess}
                  />
                </FormControl>
              </Flex>
            </RenderForUser>
          </Box>
          <Flex w='100%' justify='flex-end' pr={mobileDevice ? 4 : 0}>
            <RenderForUser roles={['admin', 'creator']}>
              <Button
                textStyle='button'
                variant='base.error'
                onClick={onOpenDeleteClass}
                isLoading={archiveClassIsLoading}
              >
                Delete Class
              </Button>
            </RenderForUser>
            <RenderForUser roles={['student', 'teacher']}>
              <Button
                textStyle='button'
                variant='base.error'
                onClick={onOpenLeaveClass}
                isLoading={leaveClassIsLoading}
              >
                Leave Class
              </Button>
            </RenderForUser>
          </Flex>
        </Container>
      </Layout>
    </React.Fragment>
  )
}

export default ClassSettingsPage
