import React from 'react'
import { IconAlamProps } from './Type'

export const Assignment = ({
  fill,
  width,
  height,
  viewBox,
}: IconAlamProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M25 18H15V16H25V18ZM25 22H15V20H25V22ZM22 26H15V24H22V26ZM20 12C20.2652 12 20.5196 12.1054 20.7071 12.2929C20.8946 12.4804 21 12.7348 21 13C21 13.2652 20.8946 13.5196 20.7071 13.7071C20.5196 13.8946 20.2652 14 20 14C19.7348 14 19.4804 13.8946 19.2929 13.7071C19.1054 13.5196 19 13.2652 19 13C19 12.7348 19.1054 12.4804 19.2929 12.2929C19.4804 12.1054 19.7348 12 20 12V12ZM27 12H22.82C22.4 10.84 21.3 10 20 10C18.7 10 17.6 10.84 17.18 12H13C12.4696 12 11.9609 12.2107 11.5858 12.5858C11.2107 12.9609 11 13.4696 11 14V28C11 28.5304 11.2107 29.0391 11.5858 29.4142C11.9609 29.7893 12.4696 30 13 30H27C27.5304 30 28.0391 29.7893 28.4142 29.4142C28.7893 29.0391 29 28.5304 29 28V14C29 13.4696 28.7893 12.9609 28.4142 12.5858C28.0391 12.2107 27.5304 12 27 12Z'
      fill={fill}
    />
  </svg>
)

Assignment.defaultProps = {
  fill: '#17053D',
  width: '40',
  height: '40',
  viewBox: '0 0 40 40',
}
