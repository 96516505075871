import { useScheme } from './useScheme'

type redirectToFrontEndProps = {
  path?: string
}

export const redirectToFrontEnd = (props: redirectToFrontEndProps) => {
  const url = process.env.REACT_APP_SERVER_HOST || 'localhost:4000'
  const path = props.path || '/'
  window.location.href = `${useScheme('http')}://${url}${path}`
}
