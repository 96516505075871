import { PrivateComments } from './PrivateComments'
import { CommentForm } from '../Forms'
import { isLargerThanTablet } from '../../utils'
import {
  StackDivider,
  Flex,
  Text,
  VStack,
  Box,
  IconButton,
} from '@chakra-ui/react'
import { Comment, User } from '../../types'
import { MdChevronLeft } from 'react-icons/md'

export type CommentsSectionProps = {
  title: string
  comments: Comment[]
  user: User
  disabled?: boolean
  handleSubmitComment: (data: { content: string }) => void
  isSubmitting: boolean
  subtitle?: string
  handleGoBack?: () => void
  emptyCommentsPlaceholder?: string
}

export const CommentsSection = ({
  title,
  comments,
  user,
  disabled = false,
  handleSubmitComment,
  isSubmitting,
  subtitle,
  handleGoBack,
  emptyCommentsPlaceholder,
}: CommentsSectionProps): JSX.Element => {
  const boxLayerStyle = isLargerThanTablet() ? 'card.module' : 'card.board-flat'
  return (
    <Box
      w='100%'
      layerStyle={boxLayerStyle}
      bg='white.1'
      minH={comments.length === 0 ? '264px' : 'auto'}
    >
      <VStack w='100%' divider={<StackDivider layerStyle='divider.nospace' />}>
        <Flex
          w='100%'
          py='12px'
          pr='16px'
          pl='24px'
          alignItems='center'
          h='72px'
        >
          {handleGoBack && (
            <IconButton
              variant='icon.button'
              icon={<MdChevronLeft />}
              aria-label='Go back to submission group users list'
              color='primary.1'
              fontSize='20px'
              ml='-20px'
              onClick={handleGoBack}
            />
          )}
          <Text textStyle='h4'>{title}</Text>
          <Text textStyle='h4' fontWeight='400' ml='4px'>
            {subtitle}
          </Text>
        </Flex>

        <PrivateComments
          comments={comments}
          emptyCommentsPlaceholder={emptyCommentsPlaceholder}
        />

        <Flex
          justify='space-between'
          pl='16px'
          pr='16px'
          py='16px'
          minH='72px'
          w='100%'
          align='center'
        >
          <CommentForm
            user={user}
            disabled={disabled}
            onSubmit={handleSubmitComment}
            isSubmitting={isSubmitting}
          />
        </Flex>
      </VStack>
    </Box>
  )
}

export default CommentsSection
