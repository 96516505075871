import { useContext, useEffect } from 'react'
import { Box, VStack, Slide, SlideFade } from '@chakra-ui/react'
import { TeacherSubmissionBox, SubmissionSideNav } from './index'
import { Submission } from '../../types'
import {
  GET_ASSIGNMENT_SUBMISSIONS,
  CREATE_SUBMISSION_COMMENT,
  GET_GROUP_USERS_SUBMISSION_COMMENTS,
} from '../../client/queries'
import { useSubscription, useMutation } from '@apollo/client'
import SubmissionContext from '../../pages/Assignment/SubmissionContext'
import { useAlamContext } from '../../client'
import { v4 as uuid } from 'uuid'
import {
  useErrorToast,
  useSuccessToast,
  isLargerThanTablet,
  useSearchParams,
} from '../../utils'
import { RenderForDevice, Loader } from '../SharedComponents'
import { CommentsSection, TeacherGroupComments } from './index'

export const TeacherSubmission = (): JSX.Element => {
  const query = useSearchParams()
  const { user, classUser } = useAlamContext()
  const {
    groupId,
    assignmentId,
    assignment,
    selectedSubmission,
    submissionStatus,
    handleUpdateSubmission,
    submissionIsUpdating,
    setSelectedSubmission,
    setStatus,
    handleUpdateSubmissionGrade,
    submissionGradeIsUpdating,
  } = useContext(SubmissionContext)
  const comments = selectedSubmission?.comments || []

  const turnedInSubmissions = useSubscription(GET_ASSIGNMENT_SUBMISSIONS, {
    variables: {
      assignment_id: assignmentId,
      status: ['submitted', 'graded', 'returned'],
      no_groups: groupId ? false : true,
      no_users: groupId ? true : false,
    },
    onSubscriptionData: ({
      subscriptionData: {
        data: { submissions },
      },
    }) => {
      updateSelectedSubmission(submissions)
    },
  })

  const missingSubmissions = useSubscription(GET_ASSIGNMENT_SUBMISSIONS, {
    variables: {
      assignment_id: assignmentId,
      status: ['unsubmitted'],
      no_groups: groupId ? false : true,
      no_users: groupId ? true : false,
    },
    onSubscriptionData: ({
      subscriptionData: {
        data: { submissions },
      },
    }) => {
      updateSelectedSubmission(submissions)
    },
  })

  const {
    data: { groupUsersComments } = { groupUsersComments: [] },
  } = useSubscription(GET_GROUP_USERS_SUBMISSION_COMMENTS, {
    variables: {
      submission_id: selectedSubmission?.id,
      group_id: selectedSubmission?.group?.id,
      class_id: classUser?.class?.id,
    },
  })
  const updateSelectedSubmission = (submissions: Submission[]) => {
    const selectedId = selectedSubmission?.id || query.get('submissionId')
    const newSelected = submissions.find(({ id }) => id === selectedId)
    if (newSelected) {
      // deletes query submissionId query param when selectedSubmission is set
      query.delete('submissionId')
      setSelectedSubmission(newSelected)
      setStatus(newSelected.status)
    }
  }

  const [createComment, { loading: commentIsSubmitting }] = useMutation(
    CREATE_SUBMISSION_COMMENT,
    {
      onCompleted: () => {
        useSuccessToast({ message: 'Comment was created' })
      },
      onError: () => {
        useErrorToast({})
      },
    }
  )

  const handleSubmitComment = ({ content }: { content: string }) => {
    createComment({
      variables: {
        id: uuid(),
        content,
        user_id: user?.id,
        submission_id: selectedSubmission?.id,
      },
    })
  }

  const boxLayerStyle = isLargerThanTablet() ? 'card.module' : 'card.board-flat'

  const isPretopic = assignment?.type === 'pretopic'

  return (
    <Loader loaders={[turnedInSubmissions.loading, missingSubmissions.loading]}>
      <RenderForDevice sizes={['xs', 'sm']}>
        <Slide
          direction='right'
          in={isLargerThanTablet() ? true : !selectedSubmission?.id}
        >
          <SubmissionSideNav
            submissionsFor={groupId ? 'groups' : 'students'}
            turnedInSubmissions={turnedInSubmissions?.data?.submissions}
            missingSubmissions={missingSubmissions?.data?.submissions}
            containerDisplay={selectedSubmission?.id ? 'none' : 'flex'}
          />
        </Slide>

        <SlideFade
          in={isLargerThanTablet() ? true : !!selectedSubmission?.id}
          reverse
          offsetY='144px'
        >
          <VStack
            spacing={6}
            display={selectedSubmission?.id ? 'block' : 'none'}
          >
            <Box w='100%' layerStyle={boxLayerStyle} bg='white.1' minH='264px'>
              <TeacherSubmissionBox
                assignment={assignment}
                submission={selectedSubmission}
                submissionStatus={submissionStatus}
                handleUpdateSubmission={handleUpdateSubmission}
                submissionIsUpdating={submissionIsUpdating}
                handleUpdateSubmissionGrade={handleUpdateSubmissionGrade}
                submissionGradeIsUpdating={submissionGradeIsUpdating}
              />
            </Box>

            {!isPretopic && (
              <CommentsSection
                title={groupId ? 'Group Comments' : 'Private Comments'}
                comments={comments}
                user={user}
                disabled={!selectedSubmission}
                handleSubmitComment={handleSubmitComment}
                isSubmitting={commentIsSubmitting}
                emptyCommentsPlaceholder={
                  groupId && 'No comments have been made yet.'
                }
              />
            )}

            {!isPretopic && groupId && (
              <TeacherGroupComments
                groupUsersComments={groupUsersComments}
                title='Private Comments'
                selectedSubmission={selectedSubmission}
                disabled={!selectedSubmission}
              />
            )}
          </VStack>
        </SlideFade>
      </RenderForDevice>

      <RenderForDevice sizes={['md', 'lg', 'xl']}>
        <SubmissionSideNav
          turnedInSubmissions={turnedInSubmissions?.data?.submissions}
          missingSubmissions={missingSubmissions?.data?.submissions}
          submissionsFor={groupId ? 'groups' : 'students'}
        />

        <VStack spacing={6}>
          <Box w='100%' layerStyle={boxLayerStyle} bg='white.1' minH='264px'>
            <TeacherSubmissionBox
              assignment={assignment}
              submission={selectedSubmission}
              submissionStatus={submissionStatus}
              handleUpdateSubmission={handleUpdateSubmission}
              submissionIsUpdating={submissionIsUpdating}
              handleUpdateSubmissionGrade={handleUpdateSubmissionGrade}
              submissionGradeIsUpdating={submissionGradeIsUpdating}
            />
          </Box>

          {!isPretopic && (
            <CommentsSection
              title={groupId ? 'Group Comments' : 'Private Comments'}
              comments={comments}
              user={user}
              disabled={!selectedSubmission}
              handleSubmitComment={handleSubmitComment}
              isSubmitting={commentIsSubmitting}
              emptyCommentsPlaceholder={
                groupId && 'No comments have been made yet.'
              }
            />
          )}

          {!isPretopic && groupId && (
            <TeacherGroupComments
              groupUsersComments={groupUsersComments}
              title='Private Comments'
              selectedSubmission={selectedSubmission}
              disabled={!selectedSubmission}
            />
          )}
        </VStack>
      </RenderForDevice>
    </Loader>
  )
}

export default TeacherSubmission
