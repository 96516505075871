import React from 'react'
import {
  Text,
  Flex,
  MenuItem,
  MenuDivider,
  useDisclosure,
} from '@chakra-ui/react'
import { PostHeading } from '../Heading'
import { Comment as CommentType } from '../../types'
import Linkify from 'react-linkify'
import { DecoratedLink } from '../Styled'
import { HoverMenu, parentHoverCss } from '../Menu'
import { RiPencilFill } from 'react-icons/ri'
import { FaTrash } from 'react-icons/fa'
import { BsThreeDots } from 'react-icons/bs'
import { EditCommentModal } from '../Modal/EditCommentModal'
import { useMutation } from '@apollo/client'
import { UPDATE_COMMENT, ARCHIVE_COMMENT } from '../../client/queries'
import { ConfirmationModal } from '../Modal'
import { useErrorToast, useSuccessToast } from '../../utils'
import { RenderForUser } from '../SharedComponents'

export interface CommentProps extends CommentType {
  indented?: boolean
  refetch?: () => void
  commentProps?: Record<string, any>
  textProps?: Record<string, any>
  isBulletinModal?: boolean
}

type EditCommentInput = {
  id: string
  content: string
}

export const Comment = ({
  id,
  user,
  inserted_at,
  content,
  indented = false,
  isBulletinModal = false,
  refetch,
  commentProps = {},
  textProps = {},
}: CommentProps): JSX.Element => {
  const {
    isOpen: showEditComment,
    onOpen: onOpenEditComment,
    onClose: onCloseEditComment,
  } = useDisclosure()

  const {
    isOpen: showDeleteComment,
    onOpen: onOpenDeleteComment,
    onClose: onCloseDeleteComment,
  } = useDisclosure()

  const [updateComment] = useMutation(UPDATE_COMMENT)
  const [archiveComment] = useMutation(ARCHIVE_COMMENT)

  const onSubmitUpdateComment = async (
    { content }: EditCommentInput,
    e: React.SyntheticEvent
  ) => {
    e.preventDefault()

    try {
      await updateComment({
        variables: {
          id: id,
          content: content,
        },
      })
      useSuccessToast({
        title: 'Comment updated',
        message: 'Comment was successfully updated',
      })
      onCloseEditComment()
    } catch (error) {
      console.warn(error)
      useErrorToast({
        message: 'Updating comment failed',
      })
    }
  }

  const handleDeleteComment = async () => {
    try {
      await archiveComment({
        variables: {
          id,
        },
      })
      refetch && refetch()
      onCloseDeleteComment()
      useSuccessToast({
        title: 'Comment deleted',
        message: 'Comment was successfully deleted',
      })
    } catch (error) {
      console.warn(error)
      useErrorToast({
        message: 'Failed to delete comment',
      })
    }
  }

  return (
    <>
      <ConfirmationModal
        isOpen={showDeleteComment}
        onClose={onCloseDeleteComment}
        title='Delete Comment'
        message='Are you sure you want to delete your comment?'
        onSubmit={handleDeleteComment}
        confirmButtonText='Delete'
      />
      <EditCommentModal
        content={content}
        isOpen={showEditComment}
        onClose={onCloseEditComment}
        onSubmit={onSubmitUpdateComment}
      />
      <Flex
        w='100%'
        direction='column'
        pl={indented ? '32px' : isBulletinModal ? '8px' : '0px'}
        {...commentProps}
      >
        <Flex position='relative' align='center' css={parentHoverCss}>
          <PostHeading
            type='comment'
            firstName={user.firstName}
            lastName={user.lastName}
            avatar={user.avatar}
            inserted_at={inserted_at}
            textNameProps={{
              fontSize: '14px',
              fontWeight: '600',
            }}
            textDateProps={{
              fontSize: '14px',
              fontWeight: '600',
            }}
          />

          <RenderForUser
            roles={['admin', 'teacher', 'creator']}
            userIds={[user.id]}
          >
            <HoverMenu top={-2} right={-2} icon={<BsThreeDots />}>
              <>
                <RenderForUser userIds={[user.id]}>
                  <MenuItem icon={<RiPencilFill />} onClick={onOpenEditComment}>
                    Edit
                  </MenuItem>
                  <MenuDivider />
                </RenderForUser>
                <RenderForUser
                  roles={['admin', 'teacher', 'creator']}
                  userIds={[user.id]}
                >
                  <MenuItem icon={<FaTrash />} onClick={onOpenDeleteComment}>
                    Delete
                  </MenuItem>
                </RenderForUser>
              </>
            </HoverMenu>
          </RenderForUser>
        </Flex>
        <Flex w='100%' pl={8} pt={1}>
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <DecoratedLink target='_blank' href={decoratedHref} key={key}>
                {decoratedText}
              </DecoratedLink>
            )}
          >
            <Text
              wordBreak='break-word'
              letterSpacing='0'
              textStyle='subtitle'
              whiteSpace='pre-line'
              {...textProps}
            >
              {content}
            </Text>
          </Linkify>
        </Flex>
      </Flex>
    </>
  )
}

export default Comment
