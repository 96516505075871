import { gql } from '@apollo/client'

export const GET_USER = gql`
  query getUser($email: String!) {
    users(limit: 1, where: { email: { _eq: $email } }) {
      id
      email
      firstName: first_name
      lastName: last_name
      avatar: photo_url
      bio
      learningStyle: learning_style
      provider
      is_preference_public
    }
  }
`

export const GET_USERS = gql`
  query getUsers($emails: [String!]!) {
    users(
      where: {
        email: { _in: $emails }
      }
    ) {
      id
      email
    }
  }
`

export const INVITE_USER = gql`
  mutation invite_user(
    $class_id: ID!
    $role: String!
    $user_id: ID!
    $send_invite: Boolean!
  ) {
    createClassUser(
      classId: $class_id
      role: $role
      userId: $user_id
      sendInvite: $send_invite
    ) {
      id
    }
  }
`

export const JOIN_CLASS = gql`
  mutation invite_user(
    $class_id: ID!
    $role: String!
    $user_id: ID!
    $send_invite: Boolean!
  ) {
    createClassUser(
      classId: $class_id
      role: $role
      userId: $user_id
      send_invite: $send_invite
    ) {
      id
      role
      user {
        email
        firstName: first_name
        lastName: last_name
        id
      }
    }
  }
`

export const CREATE_USERS = gql`
  mutation insert_users($objects: [users_insert_input!]!) {
    insert_users(
      objects: $objects
      on_conflict: { constraint: unique_users_email, update_columns: [email] }
    ) {
      returning {
        id
        email
      }
    }
  }
`
export const EDIT_USER = gql`
  mutation edit_user($data: users_set_input!, $id: uuid!) {
    update_users_by_pk(_set: $data, pk_columns: { id: $id }) {
      id
      firstName: first_name
      lastName: last_name
      bio
      email
      avatar: photo_url
      learningStyle: learning_style
      provider
      is_preference_public
    }
  }
`

export const CHANGE_PASSWORD = gql`
  mutation change_password(
    $old_password: String
    $new_password: String!
    $user_id: ID!
  ) {
    changePassword(
      oldPassword: $old_password
      newPassword: $new_password
      userId: $user_id
    ) {
      id
    }
  }
`

export const EDIT_PROFILE_INFO = gql`
  mutation edit_profile_info(
    $first_name: String
    $last_name: String
    $bio: String
    $photo: Upload
    $user_id: ID!
    $delete_photo: Boolean
    $is_preference_public: Boolean
  ) {
    editProfileInfo(
      firstName: $first_name
      lastName: $last_name
      bio: $bio
      photo: $photo
      userId: $user_id
      deletePhoto: $delete_photo
      is_preference_public: $is_preference_public
    ) {
      id
      firstName: first_name
      lastName: last_name
      bio
      email
      avatar: photo_url
      learningStyle: learning_style
      provider
      is_preference_public
    }
  }
`
