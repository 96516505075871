import { useRef, useMemo, useEffect } from 'react'
import { useSubscription, useMutation } from '@apollo/client'
import { useAlamContext } from '../../client'
import {
  GET_GROUP_CHAT,
  SEND_MESSAGE,
  READ_MESSAGES,
  GET_GROUP_PEOPLE,
} from '../../client/queries'
import { ClassUser } from '../../types'
import {
  useOnlineStatus,
  useSelectedItem,
  isLargerThanTablet,
} from '../../utils'
import { SidebarHeading } from '../Heading'
import ChatHeading from './ChatHeading'
import { ChatSideBar, ClassUserItems, Chatroom, MessageInput } from './index'
import { v4 as uuid } from 'uuid'
import { Flex, useDisclosure, useTheme, useMediaQuery } from '@chakra-ui/react'
import { UserProfile } from '../Modal'
import { Chat } from './Chat'

export const GroupChat = (): JSX.Element => {
  const { classUser, user, groups } = useAlamContext()
  const [group] = groups

  const {
    selectedItem: selectedClassUser,
    handleSelectItem: handleSelectClassUser,
    handleUnselectItem: handleUnselectClassUser,
    updateSelectedItem: updateSelectedClassUser,
  } = useSelectedItem<ClassUser>()

  const { breakpoints } = useTheme()
  const [isMobileDevice] = useMediaQuery(`(max-width: ${breakpoints.mobile})`)

  const { data: getGroupPeopleData } = useSubscription(GET_GROUP_PEOPLE, {
    variables: {
      class_id: classUser?.class.id,
      group_id: group?.id,
    },
  })

  const {
    managePeopleStudents,
    managePeopleTeachers,
    managePeopleAdmins,
  } = getGroupPeopleData?.class || {
    managePeopleStudents: [],
    managePeopleTeachers: [],
    managePeopleAdmins: [],
  }

  const { data: getGroupChatData } = useSubscription(GET_GROUP_CHAT, {
    variables: {
      class_id: classUser?.class?.id,
      current_user_id: user?.id,
      group_id: group?.id,
    },
  })

  const {
    rawOnlineTeachers,
    rawOnlineStudents,
    currentUser,
    offlineStudents,
    offlineTeachers,
  } = getGroupChatData?.class || {
    rawOnlineTeachers: [],
    rawOnlineStudents: [],
    currentUser: [],
    offlineClassUsers: [],
    offlineStudents: [],
    offlineTeachers: [],
  }

  const onlineStudents = useMemo(() => {
    if (currentUser[0]?.role === 'student') {
      return [...currentUser, ...rawOnlineStudents]
    }
    return rawOnlineStudents
  }, [currentUser, rawOnlineStudents])

  const onlineTeachers = useMemo(() => {
    if (currentUser[0]?.role !== 'student') {
      return [...currentUser, ...rawOnlineTeachers]
    }
    return rawOnlineTeachers
  }, [currentUser, rawOnlineTeachers])

  useEffect(() => {
    updateSelectedClassUser(offlineStudents) ||
      updateSelectedClassUser(onlineStudents) ||
      updateSelectedClassUser(onlineTeachers) ||
      updateSelectedClassUser(offlineTeachers)

    scrollToBottom(selectedClassUser)
  }, [offlineStudents, offlineTeachers, onlineStudents, onlineTeachers])

  const [handleReadMessages] = useMutation(READ_MESSAGES)
  const messagesEndRef = useRef<HTMLDivElement>(null)
  const scrollHook = <Flex className='scroll-hook' ref={messagesEndRef} />

  const scrollToBottom = (selectedUser?: ClassUser) => {
    if (selectedUser) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })

      handleReadMessages({
        variables: {
          sender_id: selectedUser?.user?.id,
          current_user_id: user?.id,
          class_id: classUser?.class?.id,
        },
      })
    }
  }

  return (
    <>
      {!isMobileDevice && (
        <Chat
          onlineTeachers={onlineTeachers}
          onlineStudents={onlineStudents}
          offlineUsers={[
            ...(offlineTeachers || []),
            ...(offlineStudents || []),
          ]}
          managePeopleStudents={managePeopleStudents}
          managePeopleTeachers={managePeopleTeachers}
          managePeopleAdmins={managePeopleAdmins}
          selectedClassUser={selectedClassUser}
          updateSelectedClassUser={updateSelectedClassUser}
          scrollToBottom={scrollToBottom}
          handleUnselectClassUser={handleUnselectClassUser}
          handleSelectClassUser={handleSelectClassUser}
          scrollHook={scrollHook}
          title={group?.name}
          type='group'
        />
      )}
    </>
  )
}

export default GroupChat
