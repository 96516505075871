import { useRadio, Box } from '@chakra-ui/react'

export const RadioCard = (props: any) => {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box
      as='label'
      w='100%'
      marginTop='0 !important'
      _notLast={{
        borderBottomWidth: '0.5px',
        borderColor: 'primary.2',
      }}
    >
      <input {...input} />
      <Box
        {...checkbox}
        _checked={{
          bg: 'primary.1',
          color: 'white.1',
        }}
        px={4}
        py={3}
        textStyle='body.1'
      >
        {props.children}
      </Box>
    </Box>
  )
}
