import { gql } from '@apollo/client'
import { v4 as uuid } from 'uuid'
import {
  examWithResponsesForGroups,
  examWithResponsesForStudents,
  userDisplayFields,
  examFields,
} from './fragments'

export const CREATE_ASSIGNMENT = gql`
  mutation create_assignment(
    $id: uuid!
    $name: String!
    $description: String
    $schedule: timestamp!
    $due_date: timestamp
    $rubric: String!
    $grading: String
    $type: String!
    $topic_id: uuid
    $is_visible: Boolean!
    $assignment_users: [assignment_users_insert_input!]!
    $user_id: uuid!
    $class_id: uuid!
    $submissions: [submissions_insert_input!]!
    $assignment_groups: [assignment_groups_insert_input!]!
    $add_new_users: Boolean!
    $add_new_groups: Boolean!
  ) {
    insert_assignments_one(
      object: {
        id: $id
        name: $name
        description: $description
        schedule: $schedule
        due_date: $due_date
        rubric: $rubric
        grading: $grading
        topic_id: $topic_id
        class_id: $class_id
        is_visible: $is_visible
        inserted_at: "NOW()"
        updated_at: "NOW()"
        assignment_users: { data: $assignment_users }
        user_id: $user_id
        type: $type
        add_new_users: $add_new_users
        add_new_groups: $add_new_groups
      }
    ) {
      id
      name
      description
      schedule
      due_date
      rubric
      grading
      topic_id
      is_visible
      class_id
      type
      assignment_users {
        user {
          id
        }
      }
      add_new_users
      add_new_groups
    }

    insert_assignment_groups(objects: $assignment_groups) {
      returning {
        id
      }
    }
    insert_submissions(objects: $submissions) {
      returning {
        id
      }
    }
  }
`

export const GET_ASSIGNMENT = gql`
  ${userDisplayFields}
  ${examWithResponsesForStudents}
  ${examWithResponsesForGroups}
  subscription get_assignment($id: uuid!, $user_id: uuid) {
    assignments_by_pk(id: $id) {
      id
      name
      description
      schedule
      isVisible: is_visible
      inserted_at
      type
      user {
        ...userDisplayFields
      }
      submissions(where: { user_id: { _eq: $user_id } }) {
        id
        event_logs {
          id
          content
        }
      }
      comments(
        order_by: [{ inserted_at: asc_nulls_last }]
        where: { archived_at: { _is_null: true } }
      ) {
        id
        content
        inserted_at
        user {
          ...userDisplayFields
        }
      }
      class {
        subject
        name
        id
      }
      assessments(order_by: [{ inserted_at: desc_nulls_last }]) {
        id
        content
        is_anonymous
        inserted_at
        user {
          ...userDisplayFields
        }
        comments(
          order_by: [{ inserted_at: asc_nulls_last }]
          where: { archived_at: { _is_null: true } }
        ) {
          id
          content
          inserted_at
          user {
            ...userDisplayFields
          }
        }
      }
      grading
      rubric
      topic {
        name
      }
      attachments {
        id
        url
        file
      }
      examsForStudents: exams {
        ...examWithResponsesForStudents
      }
      examsForGroups: exams {
        ...examWithResponsesForGroups
      }
    }
  }
`

export const UPDATE_ASSIGNMENT = gql`
  mutation update_assignment(
    $id: uuid!
    $changes: assignments_set_input!
    $assignment_users: [assignment_users_insert_input!]!
    $removed_users: [uuid!]!
    $user_submissions: [submissions_insert_input!]!
    $group_submissions: [submissions_insert_input!]!
    $assignment_groups: [assignment_groups_insert_input!]!
    $removed_groups: [uuid!]!
  ) {
    update_assignments(where: { id: { _eq: $id } }, _set: $changes) {
      returning {
        id
      }
    }

    delete_assignment_users(
      where: { assignment_id: { _eq: $id }, user_id: { _in: $removed_users } }
    ) {
      affected_rows
    }

    insert_assignment_users(objects: $assignment_users) {
      returning {
        id
      }
    }

    delete_assignment_groups(
      where: { assignment_id: { _eq: $id }, group_id: { _in: $removed_groups } }
    ) {
      affected_rows
    }

    insert_assignment_groups(objects: $assignment_groups) {
      returning {
        id
      }
    }

    update_submissions(
      where: {
        assignment_id: { _eq: $id }
        _or: [
          { user_id: { _in: $removed_users } }
          { group_id: { _in: $removed_groups } }
        ]
      }
      _set: { archived_at: "NOW()", updated_at: "NOW()" }
    ) {
      affected_rows
    }

    userSubmissions: insert_submissions(
      objects: $user_submissions
      on_conflict: {
        constraint: submissions_assignment_user_uniq
        update_columns: [archived_at]
      }
    ) {
      returning {
        id
      }
    }

    groupSubmissions: insert_submissions(
      objects: $group_submissions
      on_conflict: {
        constraint: submissions_assignment_group_uniq
        update_columns: [archived_at]
      }
    ) {
      returning {
        id
      }
    }
  }
`

// This only updates assignment object
export const EDIT_ASSIGNMENT = gql`
  mutation edit_assignment($id: uuid!, $changes: assignments_set_input!) {
    update_assignments(where: { id: { _eq: $id } }, _set: $changes) {
      returning {
        id
      }
    }
  }
`

export const GET_USER_SUBMISSION = gql`
  ${userDisplayFields}
  subscription get_user_submission($assignment_id: uuid!, $user_id: uuid) {
    submissions(
      where: {
        _and: [
          { assignment_id: { _eq: $assignment_id } }
          { user_id: { _eq: $user_id } }
        ]
      }
    ) {
      id
      status
      grades(order_by: [{ inserted_at: desc_nulls_last }]) {
        id
        grade
      }
      comments(
        where: {
          archived_at: { _is_null: true }
          group_user_id: { _is_null: true }
        }
        order_by: [{ inserted_at: asc_nulls_last }]
      ) {
        id
        content
        inserted_at
        user {
          ...userDisplayFields
        }
      }
      event_logs(
        where: { content: { _in: ["submitted", "returned", "unsubmitted"] } }
        order_by: [{ inserted_at: desc_nulls_last }]
      ) {
        id
        content
        grade {
          grade
        }
        inserted_at
      }
      attachments {
        id
        url
        file
      }
    }
  }
`

export const GET_GROUP_SUBMISSION = gql`
  ${userDisplayFields}
  subscription get_group_submission($assignment_id: uuid!, $group_id: uuid) {
    submissions(
      where: {
        _and: [
          { assignment_id: { _eq: $assignment_id } }
          { group_id: { _eq: $group_id } }
        ]
      }
    ) {
      id
      status
      grades(order_by: [{ inserted_at: desc_nulls_last }]) {
        id
        grade
      }
      comments(
        where: {
          archived_at: { _is_null: true }
          group_user_id: { _is_null: true }
        }
        order_by: [{ inserted_at: asc_nulls_last }]
      ) {
        id
        content
        inserted_at
        user {
          ...userDisplayFields
        }
      }
      event_logs(
        where: { content: { _in: ["submitted", "returned", "unsubmitted"] } }
        order_by: [{ inserted_at: desc_nulls_last }]
      ) {
        id
        content
        grade {
          grade
        }
        inserted_at
      }
      attachments {
        id
        url
        file
      }
    }
  }
`

export const GET_GROUP_STUDENT_SUBMISSION_COMMENTS = gql`
  ${userDisplayFields}
  subscription get_group_student_submission_comments(
    $submission_id: uuid!
    $group_user_id: uuid
  ) {
    comments(
      where: {
        archived_at: { _is_null: true }
        group_user_id: { _eq: $group_user_id }
        group_user: { archived_at: { _is_null: true } }
        submission_id: { _eq: $submission_id }
      }
      order_by: [{ inserted_at: asc_nulls_last }]
    ) {
      id
      content
      inserted_at
      user {
        ...userDisplayFields
      }
    }
  }
`

export const UPDATE_SUBMISSION = gql`
  mutation update_submission(
    $submission_id: uuid!
    $status: String!
    $event_log_id: uuid!
    $user_id: uuid
    $grade_id: uuid
    $group_id: uuid
  ) {
    update_submissions_by_pk(
      pk_columns: { id: $submission_id }
      _set: { status: $status }
    ) {
      id
      status
      grades(order_by: [{ inserted_at: desc_nulls_last }]) {
        id
        grade
      }
    }
    insert_event_logs_one(
      object: {
        id: $event_log_id
        content: $status
        updated_at: "NOW()"
        inserted_at: "NOW()"
        user_id: $user_id
        submission_id: $submission_id
        grade_id: $grade_id
        group_id: $group_id
      }
    ) {
      id
    }
  }
`

// update submission and insert grade
export const UPDATE_SUBMISSION_GRADE = gql`
  mutation update_submission_grade(
    $submission_id: uuid!
    $status: String!
    $event_log_id: uuid!
    $user_id: uuid
    $grade_id: uuid!
    $grade: String!
    $group_id: uuid
  ) {
    update_submissions_by_pk(
      pk_columns: { id: $submission_id }
      _set: { status: $status }
    ) {
      id
      status
    }
    insert_grades_one(
      object: {
        id: $grade_id
        grade: $grade
        submission_id: $submission_id
        grader_id: $user_id
        inserted_at: "NOW()"
        updated_at: "NOW()"
      }
    ) {
      id
      grade
      submission_id
    }
    insert_event_logs_one(
      object: {
        id: $event_log_id
        content: $status
        updated_at: "NOW()"
        inserted_at: "NOW()"
        user_id: $user_id
        submission_id: $submission_id
        grade_id: $grade_id
        group_id: $group_id
      }
    ) {
      id
    }
  }
`

export const GET_ASSIGNMENT_SUBMISSIONS = gql`
  ${userDisplayFields}
  subscription get_assignment_submissions(
    $assignment_id: uuid!
    $status: [String!]!
    $no_groups: Boolean!
    $no_users: Boolean!
  ) {
    submissions(
      where: {
        assignment_id: { _eq: $assignment_id }
        status: { _in: $status }
        group_id: { _is_null: $no_groups }
        user_id: { _is_null: $no_users }
      }
    ) {
      id
      status
      grades(order_by: [{ inserted_at: desc_nulls_last }]) {
        id
        grade
        grader_id
      }
      user {
        ...userDisplayFields
      }
      comments(
        where: {
          archived_at: { _is_null: true }
          group_user_id: { _is_null: true }
        }
        order_by: [{ inserted_at: asc_nulls_last }]
      ) {
        id
        content
        inserted_at
        user {
          ...userDisplayFields
        }
      }
      event_logs(order_by: [{ inserted_at: desc_nulls_last }]) {
        id
        content
        grade {
          grade
        }
        inserted_at
      }
      attachments {
        id
        url
        file
      }
      group {
        id
        name
        groupUsers: group_users(where: { archived_at: { _is_null: true } }) {
          id
        }
      }
    }
  }
`

export const GET_GROUP_USERS_SUBMISSION_COMMENTS = gql`
  ${userDisplayFields}
  subscription get_group_submission_comments(
    $submission_id: uuid!
    $group_id: uuid!
    $class_id: uuid!
  ) {
    groupUsersComments: group_users(
      where: {
        group_id: { _eq: $group_id }
        user: {
          class_users: {
            role: { _eq: "student" }
            class_id: { _eq: $class_id }
          }
        }
        archived_at: { _is_null: true }
      }
    ) {
      id
      user {
        email
        ...userDisplayFields
      }
      comments(
        where: {
          submission_id: { _eq: $submission_id }
          archived_at: { _is_null: true }
        }
        order_by: [{ inserted_at: asc_nulls_last }]
      ) {
        content
        user {
          ...userDisplayFields
        }
        inserted_at
        read_at
      }
    }
  }
`

export const READ_GROUP_COMMENTS = gql`
  mutation read_group_comments($submission_id: uuid!, $group_user_id: uuid!) {
    update_comments(
      where: {
        submission_id: { _eq: $submission_id }
        group_user_id: { _eq: $group_user_id }
        read_at: { _is_null: true }
      }
      _set: { read_at: "NOW()" }
    ) {
      affected_rows
    }
  }
`

export const GET_ASSIGNMENT_ASSESSMENT = gql`
  ${userDisplayFields}
  query get_assignment_assessment($assignment_id: uuid!) {
    assessments(
      where: { assignment_id: { _eq: $assignment_id } }
      order_by: [{ updated_at: asc_nulls_last }]
    ) {
      id
      content
      inserted_at
      isAnonymous: is_anonymous
      user {
        ...userDisplayFields
      }
      assignment {
        topic {
          name
        }
      }
      comments(order_by: [{ updated_at: asc_nulls_last }]) {
        id
        content
        inserted_at
        user {
          ...userDisplayFields
        }
      }
    }
  }
`

export const CREATE_ASSESSMENT = gql`
  mutation create_assessment(
    $id: uuid!
    $assignment_id: uuid!
    $content: String!
    $user_id: uuid!
    $is_anonymous: Boolean!
  ) {
    insert_assessments_one(
      object: {
        id: $id
        content: $content
        user_id: $user_id
        is_anonymous: $is_anonymous
        assignment_id: $assignment_id
        inserted_at: "NOW()"
        updated_at: "NOW()"
      }
    ) {
      id
    }
  }
`

export const CREATE_ASSESSMENT_COMMENT = gql`
  mutation create_assessment_comment(
    $id: uuid!
    $content: String!
    $user_id: uuid!
    $assessment_id: uuid!
  ) {
    insert_comments_one(
      object: {
        id: $id
        content: $content
        inserted_at: "NOW()"
        updated_at: "NOW()"
        user_id: $user_id
        assessment_id: $assessment_id
      }
    ) {
      id
      content
    }
  }
`

export const UPSERT_EXAM = gql`
  mutation upsert_exam(
    $exam: exams_insert_input!
    $exam_arrangement_ids: [uuid!]!
    $response_choice_arrangement_ids: [uuid!]!
  ) {
    insert_exams_one(
      object: $exam
      on_conflict: { constraint: exams_pkey, update_columns: [updated_at] }
    ) {
      id
    }

    delete_response_choices_arrangements(
      where: { id: { _in: $response_choice_arrangement_ids } }
    ) {
      affected_rows
    }

    delete_exam_arrangements(where: { id: { _in: $exam_arrangement_ids } }) {
      affected_rows
    }
  }
`

export const ARCHIVE_EXAM = gql`
  mutation archive_exam($exam_id: uuid!) {
    update_exams_by_pk(
      pk_columns: { id: $exam_id }
      _set: { archived_at: "NOW()" }
    ) {
      id
    }
  }
`

export const CREATE_EXAM_RESPONSE = gql`
  mutation create_exam_response($params: [exam_responses_insert_input!]!) {
    insert_exam_responses(objects: $params) {
      returning {
        id
      }
    }
  }
`

export const UPSERT_RESPONSE = gql`
  mutation upsert_response(
    $response: responses_insert_input!
    $exam_response_id: uuid!
    $user_id: uuid!
    $question_id: uuid!
    $response_id: uuid!
    $answers: [answers_insert_input!]!
  ) {
    delete_answers(where: { response_id: { _eq: $response_id } }) {
      affected_rows
    }

    update_responses(
      where: {
        exam_response_id: { _eq: $exam_response_id }
        user_id: { _eq: $user_id }
        question_id: { _eq: $question_id }
        archived_at: { _is_null: true }
      }
      _set: { archived_at: "NOW()" }
    ) {
      affected_rows
    }

    insert_responses_one(
      object: $response
      on_conflict: {
        constraint: responses_pkey
        update_columns: [archived_at, updated_at]
      }
    ) {
      id
    }

    insert_answers(objects: $answers) {
      returning {
        id
      }
    }
  }
`

export const COMPLETE_EXAM_RESPONSE = gql`
  mutation complete_exam_response($exam_response_id: uuid!) {
    update_exam_responses_by_pk(
      _set: { completed_at: "NOW()" }
      pk_columns: { id: $exam_response_id }
    ) {
      id
    }
  }
`

// for class page indicator
export const GET_PENDING_CLASS_REVIEWS = gql`
  subscription get_pending_class_reviews($class_id: uuid!, $user_id: uuid!) {
    class: classes_by_pk(id: $class_id) {
      assignments(where: { assignment_users: { user_id: { _eq: $user_id } } }) {
        incompleteExamsCount: exams_aggregate(
          where: {
            exam_responses: {
              completed_at: { _is_null: true }
              user_id: { _eq: $user_id }
            }
          }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  }
`

// for group page indicator
export const GET_PENDING_GROUP_REVIEWS = gql`
  subscription get_pending_group_reviews(
    $class_id: uuid!
    $group_user_id: uuid!
  ) {
    class: classes_by_pk(id: $class_id) {
      assignments(
        where: {
          assignment_groups: {
            group: {
              group_users: {
                id: { _eq: $group_user_id }
                archived_at: { _is_null: true }
              }
            }
          }
        }
      ) {
        incompleteExamsCount: exams_aggregate(
          where: {
            exam_responses: {
              completed_at: { _is_null: true }
              group_user_id: { _eq: $group_user_id }
              group_user: { archived_at: { _is_null: true } }
            }
          }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  }
`

// for classwork page indicator
export const GET_PENDING_CLASSWORK_REVIEWS = gql`
  subscription get_pending_classwork_review($class_id: uuid!, $user_id: uuid!) {
    exams(
      where: {
        exam_responses: {
          completed_at: { _is_null: true }
          user_id: { _eq: $user_id }
        }
        assignment: { class_id: { _eq: $class_id } }
      }
    ) {
      id
      type
      assignment {
        name
      }
      assignment_id
      exam_responses(where: { user_id: { _eq: $user_id } }) {
        id
        started_at
        completed_at
        user_id
        group_user_id
        user {
          email
        }
        responses {
          id
          answers {
            text
          }
          question_id
        }
      }
      exam_arrangements(order_by: [{ order: asc }]) {
        id
        order
        question {
          id
          text
          type
          response_choices_arrangements(order_by: [{ order: asc }]) {
            id
            order
            response_choice {
              id
              text
            }
          }
        }
      }
    }
  }
`

// for groupwork page indicator
export const GET_GROUP_PENDING_CLASSWORK_REVIEWS = gql`
  subscription get_pending_group_classwork_review(
    $class_id: uuid!
    $group_user_id: uuid!
  ) {
    exams(
      where: {
        exam_responses: {
          completed_at: { _is_null: true }
          group_user: {
            id: { _eq: $group_user_id }
            archived_at: { _is_null: true }
          }
        }
        assignment: { class_id: { _eq: $class_id } }
      }
    ) {
      id
      type
      assignment {
        name
      }
      assignment_id
      exam_responses(
        where: {
          group_user_id: { _eq: $group_user_id }
          group_user: { archived_at: { _is_null: true } }
        }
      ) {
        id
        started_at
        completed_at
        user_id
        group_user_id
        responses {
          id
          answers {
            text
          }
          question_id
        }
        user {
          email
        }
      }
      exam_arrangements(order_by: [{ order: asc }]) {
        id
        order
        question {
          id
          text
          type
          response_choices_arrangements(order_by: [{ order: asc }]) {
            id
            order
            response_choice {
              id
              text
            }
          }
        }
      }
    }
  }
`

export const GET_EXAMS_FOR_TEACHERS = gql`
  ${examFields}
  subscription get_pending_assignment_review($assignment_id: uuid!) {
    exams(
      where: { assignment_id: { _eq: $assignment_id } }
      order_by: [{ type: asc }]
    ) {
      ...examFields
      inserted_at
      updated_at
    }
  }
`

// for assignment pages indicator
export const GET_PENDING_ASSIGNMENT_REVIEWS = gql`
  subscription get_pending_assignment_review(
    $assignment_id: uuid!
    $user_id: uuid!
  ) {
    exams(
      where: {
        exam_responses: {
          completed_at: { _is_null: true }
          user_id: { _eq: $user_id }
        }
        assignment_id: { _eq: $assignment_id }
      }
    ) {
      id
      type
      assignment {
        name
      }
      assignment_id
      exam_responses(where: { user_id: { _eq: $user_id } }) {
        id
        started_at
        completed_at
        user_id
        group_user_id
        user {
          email
        }
        responses {
          id
          answers {
            text
          }
          question_id
        }
      }
      exam_arrangements(order_by: [{ order: asc }]) {
        id
        order
        question {
          id
          text
          type
          response_choices_arrangements(order_by: [{ order: asc }]) {
            id
            order
            response_choice {
              id
              text
            }
          }
        }
      }
    }
  }
`

// for group assignment pages indicator
export const GET_GROUP_PENDING_ASSIGNMENT_REVIEWS = gql`
  subscription get_pending_group_assignment_review(
    $assignment_id: uuid!
    $group_user_id: uuid!
  ) {
    exams(
      where: {
        exam_responses: {
          completed_at: { _is_null: true }
          group_user_id: { _eq: $group_user_id }
          group_user: { archived_at: { _is_null: true } }
        }
        assignment_id: { _eq: $assignment_id }
      }
    ) {
      id
      type
      assignment {
        name
      }
      assignment_id
      exam_responses(
        where: {
          group_user_id: { _eq: $group_user_id }
          group_user: { archived_at: { _is_null: true } }
        }
      ) {
        id
        started_at
        completed_at
        user_id
        group_user_id
        responses {
          id
          answers {
            text
          }
          question_id
        }
        user {
          email
        }
      }
      exam_arrangements(order_by: [{ order: asc }]) {
        id
        order
        question {
          id
          text
          type
          response_choices_arrangements(order_by: [{ order: asc }]) {
            id
            order
            response_choice {
              id
              text
            }
          }
        }
      }
    }
  }
`

export const UPDATE_INDIVIDUAL_VIEWED_AT = gql`
  mutation update_individual_viewed_at($user_id: uuid, $assignment_id: uuid) {
    update_assignment_users(
      where: {
        assignment_id: { _eq: $assignment_id }
        user_id: { _eq: $user_id }
        viewed_at: { _is_null: true }
      }
      _set: { viewed_at: "NOW()" }
    ) {
      returning {
        id
        viewed_at
      }
    }
  }
`

export const UPDATE_GROUP_VIEWED_AT = gql`
  mutation update_group_viewed_at($group_id: uuid, $assignment_id: uuid) {
    update_assignment_groups(
      where: {
        assignment_id: { _eq: $assignment_id }
        group_id: { _eq: $group_id }
        viewed_at: { _is_null: true }
      }
      _set: { viewed_at: "NOW()" }
    ) {
      returning {
        id
        viewed_at
      }
    }
  }
`

export const generateAssignmentUsers = (studentIds: string[]) => {
  return studentIds.map((studentId) => {
    const assignmentId = uuid()
    return {
      id: assignmentId,
      inserted_at: 'NOW()',
      updated_at: 'NOW()',
      user: {
        data: {
          id: studentId,
          inserted_at: 'NOW()',
          updated_at: 'NOW()',
        },
        on_conflict: {
          constraint: 'users_pkey',
          update_columns: ['updated_at'],
        },
      },
    }
  })
}

export const generateInsertAssignmentUsers = (
  studentIds: string[],
  assignmentId: string
) => {
  return studentIds.map((studentId) => {
    return {
      id: assignmentId,
      inserted_at: 'NOW()',
      updated_at: 'NOW()',
      assignmentId: assignmentId,
      user: {
        data: {
          id: studentId,
          inserted_at: 'NOW()',
          updated_at: 'NOW()',
        },
        on_conflict: {
          constraint: 'users_pkey',
          update_columns: ['updated_at'],
        },
      },
    }
  })
}

export const generateStudentSubmissions = (
  studentIds: string[],
  assignmentId: string
) => {
  return studentIds.map((studentId) => {
    const submissionId = uuid()
    return {
      id: submissionId,
      inserted_at: 'NOW()',
      updated_at: 'NOW()',
      assignment_id: assignmentId,
      user_id: studentId,
      archived_at: null,
    }
  })
}

export const generateGroupSubmissions = (
  groupIds: string[],
  assignmentId: string
) => {
  return groupIds.map((groupId) => {
    const submissionId = uuid()
    return ({
      id: submissionId,
      inserted_at: 'NOW()',
      updated_at: 'NOW()',
      assignment_id: assignmentId,
      group_id: groupId,
      archived_at: null,
    })
  }) 
}

export const generateNewAssignmentGroups = (
  groupIds: string[],
  assignmentId: string
) => {
  return groupIds.map((groupId) => {
    const newId = uuid()
    return {
      id: newId,
      inserted_at: 'NOW()',
      updated_at: 'NOW()',
      assignment_id: assignmentId,
      group_id: groupId,
    }
  })
}