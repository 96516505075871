export interface AnalyticsParams {
  id: string
  topic_id?: string
  assignment_id: string
  question_id?: string
}

export interface Names {
  topicName?: string
  className?: string
  assignmentName?: string
  questionOrder?: string
}

export const buildBasePath = ({ id, topic_id }: AnalyticsParams) => {
  if (!topic_id) {
    return `/classes/${id}/analytics/class`
  }

  return `/classes/${id}/analytics/class/topic/${topic_id}`
}

export const buildAnalyticsCrumbs = (
  params: AnalyticsParams,
  names: Names,
  type: string
) => {
  const { topic_id, assignment_id, question_id } = params
  const basePath = buildBasePath(params)
  const assignmentPath = `${basePath}/assignment/${assignment_id}`
  const assignmentCrumb = {
    text: names?.assignmentName || 'Assignment',
    url: assignmentPath,
  }
  const firstCrumb = topic_id
    ? {
        text: names?.topicName || 'Topic',
        url: basePath,
      }
    : {
        text: names?.className || 'Class',
        url: basePath,
      }

  const thirdCrumb =
    type === 'assignment'
      ? []
      : [
          {
            text: getReviewNameFromType(type),
            url: `${assignmentPath}/${type}`,
          },
        ]

  const fourthCrumb = !question_id
    ? []
    : [
        {
          text: names?.questionOrder || 'Q',
          url: `${assignmentPath}/${type}/question/${question_id}`,
        },
      ]

  return [firstCrumb, assignmentCrumb, ...thirdCrumb, ...fourthCrumb]
}

export const buildAssignmentLinks = (params: AnalyticsParams, type: string) => {
  const basePath = buildBasePath(params)
  const assignmentPath = `${basePath}/assignment/${params.assignment_id}`
  return `${assignmentPath}/${type}`
}

export const buildQuestionLinks = (params: AnalyticsParams, type: string) => {
  const assignmentPath = buildAssignmentLinks(params, type)
  return `${assignmentPath}/question/${params?.question_id}`
}

export const getReviewNameFromType = (type: string) => {
  switch (type) {
    case 'assignment-review':
      return 'Assignment Review'
    case 'learning-review':
      return 'Learning Review'
    case 'score':
      return 'Scores'
    case 'completion-time':
      return 'Completion Time'
    default:
      return ''
  }
}
