import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Home from './pages/Home'
import Class from './pages/Class'
import Group from './pages/Group'
import GroupUserAuthentication from './pages/Group/GroupUserAuthentication'
import Groups from './pages/Groups'
import ClassWork from './pages/ClassWork'
import GroupWork from './pages/GroupWork'
import NotFound from './pages/NotFound'
import ClassGrades from './pages/ClassGrades'
import Analytics from './pages/Analytics'
import AccountSettings from './pages/AccountSettings'
import AccountSettingsGoogle from './pages/AccountSettingsGoogle'
import ClassSettings from './pages/ClassSettings'
import Assignment from './pages/Assignment'
import Pretopic from './pages/Pretopic'
import Material from './pages/Material'
import InviteCode from './pages/ClassInvite'
import { ManagePeopleMobile } from './components/ManagePeople'
import { ClassInviteToken } from './pages/Tokens'
import Auth from './pages/Auth'
import AlamClient, { AlamConfig } from './client'
import { ScrollToTop } from './components/SharedComponents'
import { ClassChatRoute, GroupChatRoute } from './components/Chat'
import SessionPage from './pages/Session'
import { GoogleAPIProvider } from './components/GoogleAPI'
import { isLargerThanTablet, isMobileDevice } from './utils'
import { ErrorProvider } from './context'
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";

// if (__DEV__) {  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
// }

const config: AlamConfig = {
  token: localStorage.getItem('token') || '',
  user: localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user') || '')
    : undefined,
}

function App() {
  return (
    <Router>
      {config.user ? (
        <AlamClient config={config}>
          <GoogleAPIProvider>
            <ErrorProvider>
              <ScrollToTop />
              <ClassChatRoute />
              <GroupChatRoute />
              <Switch>
                <Route path='/' exact>
                  <Home />
                </Route>

                <Route path='/auth'>
                  <Auth />
                </Route>

                <Route path='/classes/:id/settings'>
                  <ClassSettings />
                </Route>

                <Route path='/classes/:id/groups/:group_id/groupwork'>
                  <GroupUserAuthentication>
                    <GroupWork />
                  </GroupUserAuthentication>
                </Route>
                <Route path='/classes/:id/groups/:group_id/materials/:materialId'>
                  <GroupUserAuthentication>
                    <Material />
                  </GroupUserAuthentication>
                </Route>
                <Route path='/classes/:id/groups/:group_id/assignments/:assignmentId'>
                  <GroupUserAuthentication>
                    <Assignment />
                  </GroupUserAuthentication>
                </Route>
                <Route path='/classes/:id/groups/:group_id'>
                  <GroupUserAuthentication>
                    <Group />
                  </GroupUserAuthentication>
                </Route>
                <Route path='/classes/:id/groups'>
                  <Groups />
                </Route>

                <Route path='/classes/:id/analytics'>
                  <Analytics />
                </Route>

                <Route path='/classes/:id/assignments/:assignmentId'>
                  <Assignment />
                </Route>
                <Route path='/classes/:id/pretopic/:pretopicId'>
                  <Pretopic />
                </Route>
                <Route path='/classes/:id/materials/:materialId'>
                  <Material />
                </Route>
                <Route path='/classes/:id/classwork'>
                  <ClassWork />
                </Route>

                {isLargerThanTablet() && (
                  <Route path='/classes/:id/grades'>
                    <ClassGrades />
                  </Route>
                )}

                <Route path='/classes/:id/sessions/:sessionId'>
                  <SessionPage />
                </Route>

                <Route path='/classes/:id/account-settings'>
                  <AccountSettings />
                </Route>

                {isMobileDevice() && (
                  <Route path='/classes/:id/people'>
                    <ManagePeopleMobile />
                  </Route>
                )}

                <Route path='/classes/:id'>
                  <Class />
                </Route>

                <Route path='/home/account-settings'>
                  <AccountSettings fromHome />
                </Route>

                <Route path='/token/:tokenCode/class-invite'>
                  <ClassInviteToken />
                </Route>

                <Route path='/join/:inviteCode'>
                  <InviteCode />
                </Route>

                <Route path='*'>
                  <NotFound />
                </Route>
              </Switch>
            </ErrorProvider>
          </GoogleAPIProvider>
        </AlamClient>
      ) : (
        <Auth />
      )}
    </Router>
  )
}

export default App
