import { gql } from '@apollo/client'
import { Class } from '../../types'
import {
  userDisplayFields,
  gradesSubmissionFields,
  examWithResponsesForStudents,
  examWithResponsesForGroups,
  analyticsSubmissionFields,
} from './fragments'

export interface GetUserClassType extends Class {
  memberCount: {
    aggregate: {
      count: number
    }
  }
}

export type GetClassGroupType = {
  id: string
  name: string
  description?: string
  memberCount: {
    aggregate: {
      count: number
    }
  }
}

export const GET_CLASS_USERS = gql`
  ${userDisplayFields}
  subscription get_class_users($class_id: uuid!, $group_id: uuid!) {
    class_users(
      where: {
        class_id: { _eq: $class_id }
        archived_at: { _is_null: true }
        _not: {
          user: {
            group_users: {
              group_id: { _eq: $group_id }
              archived_at: { _is_null: true }
            }
          }
        }
      }
    ) {
      id
      role
      user {
        ...userDisplayFields
      }
    }
  }
`

export const IS_STUDENT_REMOVED_FROM_GROUP = gql`
  subscription is_student_removed_from_group(
    $current_user_id: uuid!
    $group_id: uuid!
  ) {
    group_users(
      where: {
        group_id: { _eq: $group_id }
        user_id: { _eq: $current_user_id }
        archived_at: { _is_null: true }
      }
    ) {
      id
    }
  }
`

export const USER_GROUPS = gql`
  subscription user_groups($class_id: uuid!, $user_id: uuid!) {
    groups(
      where: {
        class_id: { _eq: $class_id }
        archived_at: { _is_null: true }
        _or: [
          {
            group_users: {
              user_id: { _eq: $user_id }
              archived_at: { _is_null: true }
            }
          }
          {
            class: {
              class_users: {
                user_id: { _eq: $user_id }
                role: { _in: ["teacher", "admin", "creator"] }
              }
            }
          }
        ]
      }
    ) {
      id
      name
    }
  }
`

export const GET_USER_CLASSES = gql`
  subscription get_user_classes($user_id: uuid!) {
    classes(
      where: {
        archived_at: { _is_null: true }
        class_users: {
          accepted_at: { _is_null: false }
          archived_at: { _is_null: true }
          user: {
            _and: { id: { _eq: $user_id }, activated_at: { _is_null: false } }
          }
        }
      }
    ) {
      id
      name
      subject
      class_users(where: { user_id: { _eq: $user_id } }) {
        id
        role
      }
      memberCount: class_users_aggregate(
        where: { accepted_at: { _is_null: false } }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`

// export const GET_ALAM_CONTEXT = gql`
//   ${userDisplayFields}
//   subscription get_alam_context(
//     $class_id: uuid!
//     $user_id: uuid
//     $group_id: uuid
//   ) {
//     classes: classes_by_pk(id: $class_id) {
//       class_users(
//         where: {
//           user: { id: { _eq: $user_id } }
//           class: { id: { _eq: $class_id }, archived_at: { _is_null: true } }
//           archived_at: { _is_null: true }
//         }
//       ) {
//         id
//         role
//         class {
//           id
//           name
//           subject
//           code
//           can_send_invite
//           topics {
//             id
//             name
//           }
//         }
//       }

//       groups(where: { id: { _eq: $group_id } }) {
//         id
//         name
//         description
//         group_users(
//           where: {
//             archived_at: { _is_null: true }
//             user: {
//               class_users: {
//                 class_id: { _eq: $class_id }
//                 role: { _eq: "student" }
//               }
//             }
//           }
//         ) {
//           id
//           user {
//             ...userDisplayFields
//           }
//         }
//         current_group_user: group_users(
//           where: {
//             user_id: { _eq: $user_id }
//             group_id: { _eq: $group_id }
//             archived_at: { _is_null: true }
//           }
//         ) {
//           id
//         }
//       }

//       classTopics: topics(where: { class_id: { _eq: $class_id } }) {
//         id
//         name
//       }
//     }
//   }
// `

export const GET_ALAM_CONTEXT = gql`
  ${userDisplayFields}
  subscription get_alam_context(
    $class_id: uuid!
    $user_id: uuid
    $group_id: uuid
  ) {
    classes: classes_by_pk(id: $class_id) {
      class_users(
        where: {
          user: { id: { _eq: $user_id } }
          class: { id: { _eq: $class_id }, archived_at: { _is_null: true } }
          archived_at: { _is_null: true }
        }
      ) {
        id
        role
        class {
          id
          name
          subject
          code
          can_send_invite
          topics {
            id
            name
          }
        }
      }

      groups(where: { class_id: { _eq: $class_id } }) {
        id
        name
        description
        group_users(
          where: {
            archived_at: { _is_null: true }
            user: {
              class_users: {
                class_id: { _eq: $class_id }
                role: { _eq: "student" }
              }
            }
          }
        ) {
          id
          user {
            ...userDisplayFields
          }
        }
        current_group_user: group_users(
          where: {
            user_id: { _eq: $user_id }
            archived_at: { _is_null: true }
          }
        ) {
          id
        }
      }

      classTopics: topics(where: { class_id: { _eq: $class_id } }) {
        id
        name
      }
    }
  }
`

export const ALAM_SUBSCRIPTION = gql`
  subscription refetch_alam_subscription($class_id: uuid!, $user_id: uuid!) {
    classes: classes_by_pk(id: $class_id) {
      students: class_users(
        where: {
          accepted_at: { _is_null: false }
          role: { _eq: "student" }
          user_id: { _neq: $user_id }
        }
        order_by: [{ user: { first_name: asc } }]
      ) {
        role
        user {
          id
          email
          firstName: first_name
          lastName: last_name
          avatar: photo_url
          provider
        }
      }

      teachers: class_users(
        where: {
          accepted_at: { _is_null: false }
          role: { _in: ["teacher", "admin", "creator"] }
          user_id: { _neq: $user_id }
        }
        order_by: [{ user: { first_name: asc } }]
      ) {
        role
        user {
          id
          email
          firstName: first_name
          lastName: last_name
          avatar: photo_url
          provider
        }
      }
    }
  }
`

export const GET_CLASS_CODE = gql`
  query get_class_code($id: uuid!) {
    classes(where: { id: { _eq: $id } }) {
      code
    }
  }
`

export const CREATE_CLASS = gql`
  mutation create_class(
    $class_id: uuid!
    $name: String!
    $subject: String
    $class_user_id: uuid!
    $code: String!
    $user_id: uuid!
  ) {
    insert_classes_one(
      object: {
        id: $class_id
        name: $name
        code: $code
        subject: $subject
        inserted_at: "NOW()"
        updated_at: "NOW()"
        class_users: {
          data: [
            {
              id: $class_user_id
              user_id: $user_id
              inserted_at: "NOW()"
              updated_at: "NOW()"
              role: "creator"
              accepted_at: "NOW()"
              notification_settings: {
                is_subscribed_posts: true
                is_subscribed_announcements: true
                is_subscribed_comments: true
                is_subscribed_group_posts: true
                is_subscribed_group_announcements: true
                is_subscribed_group_comments: true
              }
            }
          ]
        }
      }
    ) {
      id
      name
      subject
      class_users {
        role
        user {
          email
          firstName: first_name
          lastName: last_name
          role
          id
        }
      }
    }
  }
`


export const UPDATE_CLASS = gql`
  mutation update_class(
    $id: uuid!
    $name: String!
    $subject: String
    $can_send_invite: Boolean
  ) {
    update_classes(
      where: { id: { _eq: $id } }
      _set: {
        name: $name
        subject: $subject
        can_send_invite: $can_send_invite
      }
    ) {
      affected_rows
      returning {
        id
        name
        subject
        can_send_invite
      }
    }
  }
`

export const ARCHIVE_CLASS = gql`
  mutation archive_class($id: uuid!) {
    update_classes(
      where: { id: { _eq: $id } }
      _set: { archived_at: "NOW()" }
    ) {
      affected_rows
      returning {
        id
        archived_at
      }
    }
  }
`

export const LEAVE_CLASS = gql`
  mutation leave_class($id: uuid!) {
    update_class_users(
      where: { id: { _eq: $id } }
      _set: { archived_at: "NOW()" }
    ) {
      affected_rows
      returning {
        id
        archived_at
      }
    }
  }
`

export const SWITCH_ROLE = gql`
  mutation switch_role($role: String!, $class_user_id: uuid!) {
    update_class_users(
      where: { id: { _eq: $class_user_id } }
      _set: { role: $role }
    ) {
      affected_rows
      returning {
        id
        role
      }
    }
  }
`

export const DELETE_PERSON = gql`
  mutation delete_person($user_id: uuid!) {
    update_class_users(
      where: { id: { _eq: $user_id } }
      _set: { archived_at: "NOW()" }
    ) {
      affected_rows
      returning {
        id
        archived_at
      }
    }
  }
`

export const DELETE_PEOPLE = gql`
  mutation delete_people($user_ids: [uuid!]!) {
    update_class_users(
      where: { id: { _in: $user_ids } }
      _set: { archived_at: "NOW()" }
    ) {
      affected_rows
      returning {
        id
        archived_at
      }
    }
  }
`

export const UPDATE_GROUP = gql`
  mutation update_group($id: uuid!, $changes: groups_set_input) {
    update_groups(where: { id: { _eq: $id } }, _set: $changes) {
      affected_rows
      returning {
        id
        name
        description
      }
    }
  }
`

export const ARCHIVE_GROUP = gql`
  mutation update_groups($id: uuid!) {
    update_groups(where: { id: { _eq: $id } }, _set: { archived_at: "NOW()" }) {
      returning {
        id
        name
        description
        inserted_at
        archived_at
      }
    }
  }
`

export const GET_ALL_CLASSES = gql`
  subscription get_all_classes {
    classes(where: { archived_at: { _is_null: true } }) {
      id
      name
      subject
      code
      can_send_invite
    }
  }
`

export const GET_CLASS_FROM_CODE = gql`
  query get_class_from_code($invite_code: String!) {
    classes(
      where: { archived_at: { _is_null: true }, code: { _eq: $invite_code } }
    ) {
      id
      name
      subject
      code
      can_send_invite
    }
  }
`

export const GET_TOPIC_REPORT = gql`
  ${analyticsSubmissionFields}
  query get_topic_report($topic_id: uuid!) {
    topics_by_pk(id: $topic_id) {
      id
      name
      assignments {
        id
        name
        type
        description
      }
      pretopic: assignments(
        where: { topic_id: { _eq: $topic_id }, type: { _eq: "pretopic" } }
      ) {
        id
        name
        topic {
          name
        }
        rubric
        assignment_users {
          id
          viewed_at
          user {
            id
            first_name
            last_name
            avatar: photo_url
            group_users(
              where: {
                group: { class: { topics: { id: { _eq: $topic_id } } } }
                archived_at: { _is_null: true }
              }
            ) {
              group {
                name
              }
            }
            submissions(
              where: {
                assignment: {
                  topic_id: { _eq: $topic_id }
                  type: { _eq: "pretopic" }
                }
              }
            ) {
              ...analyticsSubmissionFields
            }
          }
        }
      }
    }
  }
`

export const GET_ASSIGNMENT_REPORT = gql`
  ${analyticsSubmissionFields}
  query get_assignment_report($assignment_id: uuid!) {
    assignments_by_pk(id: $assignment_id) {
      id
      name
      rubric
      topic {
        name
      }

      assignment_users {
        id
        viewed_at
        user {
          id
          first_name
          last_name
          avatar: photo_url
          group_users(
            where: {
              group: { class: { assignments: { id: { _eq: $assignment_id } } } }
              archived_at: { _is_null: true }
            }
          ) {
            group {
              name
            }
          }
          submissions(where: { assignment_id: { _eq: $assignment_id } }) {
            ...analyticsSubmissionFields
          }
        }
      }

      assignment_groups {
        id
        viewed_at
        group {
          id
          name
          submissions(where: { assignment_id: { _eq: $assignment_id } }) {
            ...analyticsSubmissionFields
          }
        }
      }
    }
  }
`

export const GET_PRETOPIC_COMPLETION_REPORT = gql`
  ${analyticsSubmissionFields}
  query get_pretopic_completion_report($topic_id: uuid!) {
    assignments(
      where: { topic_id: { _eq: $topic_id }, type: { _eq: "pretopic" } }
    ) {
      id
      name
      topic {
        name
      }
      assignment_users {
        id
        viewed_at
        user {
          id
          first_name
          last_name
          avatar: photo_url
          group_users(
            where: {
              group: { class: { topics: { id: { _eq: $topic_id } } } }
              archived_at: { _is_null: true }
            }
          ) {
            group {
              name
            }
          }
          submissions(
            where: {
              assignment: {
                topic_id: { _eq: $topic_id }
                type: { _eq: "pretopic" }
              }
            }
          ) {
            ...analyticsSubmissionFields
          }
        }
      }
    }
  }
`

export const GET_PRETOPIC_REPORT = gql`
  ${analyticsSubmissionFields}
  query get_pretopic_report($topic_id: uuid!) {
    assignments(
      where: { topic_id: { _eq: $topic_id }, type: { _eq: "pretopic" } }
    ) {
      id
      name
      rubric
      topic {
        name
      }
      assignment_users {
        id
        user {
          id
          first_name
          last_name
          avatar: photo_url
          group_users(
            where: {
              group: { class: { topics: { id: { _eq: $topic_id } } } }
              archived_at: { _is_null: true }
            }
          ) {
            group {
              name
            }
          }
          submissions(
            where: {
              assignment: {
                topic_id: { _eq: $topic_id }
                type: { _eq: "pretopic" }
              }
            }
          ) {
            ...analyticsSubmissionFields
          }
        }
      }
    }
  }
`

export const GET_CLASS_REPORT = gql`
  subscription get_class_report($class_id: uuid!) {
    classes_by_pk(id: $class_id) {
      id
      code
      class_users(where: { role: { _eq: "student" } }) {
        user {
          id
          learning_preferences: learning_style
        }
      }
      topics {
        id
        name
        materials {
          id
          title
        }
        assignments(where: { type: { _eq: "assignment" } }) {
          id
          name
        }
      }
      studentCount: class_users_aggregate(where: { role: { _eq: "student" } }) {
        aggregate {
          count
        }
      }
      topicsCount: topics_aggregate {
        aggregate {
          count
        }
      }
      materialsCount: materials_aggregate {
        aggregate {
          count
        }
      }
      assignmentsCount: assignments_aggregate(
        where: { type: { _eq: "assignment" } }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`

export const GET_CLASS_GROUPS = gql`
  query get_class_groups($class_id: uuid!) {
    groups(
      where: {
        archived_at: { _is_null: true }
        class: { id: { _eq: $class_id }, archived_at: { _is_null: true } }
      }
    ) {
      id
      name
      description
      memberCount: group_users_aggregate(
        where: { archived_at: { _is_null: true } }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`

export const GET_USER_GROUPS = gql`
  query get_user_groups($user_id: uuid!, $class_id: uuid!) {
    groups(
      where: {
        archived_at: { _is_null: true }
        class: { id: { _eq: $class_id }, archived_at: { _is_null: true } }
        group_users: {
          user_id: { _eq: $user_id }
          archived_at: { _is_null: true }
        }
      }
    ) {
      id
      name
      description
      memberCount: group_users_aggregate(
        where: { archived_at: { _is_null: true } }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`

export const CREATE_GROUP_MEMBERS = gql`
  mutation create_group_members($members: [group_users_insert_input!]!) {
    insert_group_users(
      objects: $members
      on_conflict: {
        constraint: group_users_group_id_user_id_uniq
        update_columns: [archived_at, updated_at]
      }
    ) {
      returning {
        id
        user_id
        group_id
      }
    }
  }
`

export const REMOVE_GROUP_MEMBERS = gql`
  mutation remove_group_members($user_ids: [uuid!]!, $group_id: uuid!) {
    update_group_users(
      where: { user_id: { _in: $user_ids }, group_id: { _eq: $group_id } }
      _set: { archived_at: "NOW()" }
    ) {
      affected_rows
      returning {
        id
        archived_at
      }
    }
  }
`


export const CREATE_GROUP = gql`
  mutation insert_group(
    $id: uuid!
    $name: String!
    $description: String
    $class_id: uuid!
  ) {
    insert_groups_one(
      object: {
        archived_at: null
        assignment_groups: { data: [] }
        class_id: $class_id
        description: $description
        event_logs: { data: [] }
        group_users: { data: [] }
        id: $id
        inserted_at: "NOW()"
        material_groups: { data: [] }
        name: $name
        posts: { data: [] }
        submissions: { data: [] }
        updated_at: "NOW()"
      }
    ) {
      id
      inserted_at
    }
  }
`

export const GET_ASSIGNMENTS_IN_CLASS = gql`
  ${examWithResponsesForStudents}
  subscription get_assignments_in_class($class_id: uuid!) {
    assignments(
      where: {
        class_id: { _eq: $class_id }
        archived_at: { _is_null: true }
        assignment_users: {}
      }
      order_by: { inserted_at: asc }
    ) {
      id
      name
      grading
      rubric
      updated_at
      due_date
      topic {
        id
        name
      }
      examsForStudents: exams {
        ...examWithResponsesForStudents
      }
    }
  }
`

export const GET_USERS_SUBMISSION_STATUS_IN_CLASS = gql`
  ${userDisplayFields}
  ${gradesSubmissionFields}
  subscription GET_USERS_SUBMISSIONS_STATUS_IN_CLASS($class_id: uuid!) {
    users(
      where: { submissions: { assignment: { class_id: { _eq: $class_id } } } }
    ) {
      email
      ...userDisplayFields
      submissions(
        order_by: { assignment: { inserted_at: asc } }
        where: { assignment: { class_id: { _eq: $class_id } } }
      ) {
        ...gradesSubmissionFields
      }
    }
  }
`

export const GET_GROUP_ASSIGNMENTS_IN_CLASS = gql`
  ${examWithResponsesForGroups}
  subscription GET_GROUP_ASSIGNMENTS_IN_CLASS($class_id: uuid!) {
    assignments(
      where: { class: { id: { _eq: $class_id } }, assignment_groups: {} }
      order_by: { inserted_at: asc }
    ) {
      id
      name
      grading
      rubric
      updated_at
      due_date
      topic {
        id
        name
      }
      examsForGroups: exams {
        ...examWithResponsesForGroups
      }
    }
  }
`

export const GET_GROUPS_SUBMISSION_STATUS_IN_CLASS = gql`
  ${gradesSubmissionFields}
  subscription GET_GROUPS_SUBMISSION_STATUS_IN_CLASS($class_id: uuid!) {
    groups(where: { class_id: { _eq: $class_id } }) {
      id
      name
      submissions(
        order_by: { assignment: { inserted_at: asc } }
        where: { assignment: { class_id: { _eq: $class_id } } }
      ) {
        ...gradesSubmissionFields
      }
    }
  }
`

export const GET_INDIVIDUAL_USER_SUBMISSIONS = gql`
  subscription get_individual_user_submissions(
    $user_id: uuid!
    $class_id: uuid!
  ) {
    submissions(
      where: {
        _and: [
          { assignment: { class_id: { _eq: $class_id } } }
          {
            _or: [
              { user_id: { _eq: $user_id } }
              { group: { group_users: { user: { id: { _eq: $user_id } } } } }
            ]
          }
        ]
      }
    ) {
      id
      status
      grades(order_by: [{ inserted_at: desc_nulls_last }]) {
        id
        grade
      }
      assignment {
        id
        name
        dueDate: due_date
        topic {
          id
          name
          insertedAt: inserted_at
        }
        rubric
        grading
        classId: class_id
      }
      comments(order_by: [{ inserted_at: desc }]) {
        id
      }
      eventLogs: event_logs(order_by: [{ inserted_at: desc }]) {
        id
        content
        insertedAt: inserted_at
      }
      user {
        id
      }
      group {
        id
      }
      insertedAt: inserted_at
    }
  }
`

export const GET_CLASS_USER_SUBSCRIPTIONS = gql`
  subscription get_class_user_subscriptions($class_user_id: uuid!) {
    class_users_by_pk(id: $class_user_id) {
      notification_settings
    }
  }
`
export const EDIT_CLASS_USER = gql`
  mutation edit_class_user($data: class_users_set_input!, $id: uuid!) {
    update_class_users_by_pk(_set: $data, pk_columns: { id: $id }) {
      notification_settings
    }
  }
`
