import { useState } from 'react'
import { ChatHeading } from '../Chat'
import { ManagePeopleItems, ManagePeopleProps } from './index'
import { SidebarHeading } from '../Heading'
import { FaUserPlus, FaTrash } from 'react-icons/fa'
import { ClassUser } from '../../types'
import { Flex, Text, IconButton, useDisclosure } from '@chakra-ui/react'
import { useMutation, useQuery } from '@apollo/client'
import {
  REMOVE_GROUP_MEMBERS,
  GET_CLASS_CODE,
  CREATE_GROUP_MEMBERS,
} from '../../client/queries'
import { useErrorToast, useSuccessToast } from '../../utils'
import { ClassInvite, ClassInviteInput } from '../Forms'
import { v4 as uuid } from 'uuid'
import { useParams } from 'react-router-dom'
import {
  ConfirmationModal,
  GroupMembersModal,
  GroupMembersInput,
} from '../Modal'

export const ManagePeopleGroup = ({
  managePeopleStudents,
  managePeopleTeachers,
  managePeopleAdmins,
}: ManagePeopleProps) => {
  const [selected, setSelected] = useState<string[]>([])
  const [isInviting, setIsInviting] = useState(false)
  const {
    isOpen: showGroupMembersModal,
    onOpen: onOpenGroupMembersModal,
    onClose: onCloseGroupMembersModal,
  } = useDisclosure()

  const {
    isOpen: deleteIsOpen,
    onOpen: deleteOnOpen,
    onClose: deleteOnClose,
  } = useDisclosure()

  const { id: classId, group_id: groupId } = useParams<{
    id: string
    group_id: string
  }>()

  const handleChange = (newSelected: string[]) => {
    setSelected(newSelected)
  }

  const isSelected = (id: string) => selected.includes(id)

  const countSelected = selected.length

  const [removeGroupMembers] = useMutation(REMOVE_GROUP_MEMBERS, {
    onCompleted: () => {
      setSelected([])
      deleteOnClose()
    },
  })

  const [
    createGroupMembers,
    { loading: groupMembersIsSubmitting },
  ] = useMutation(CREATE_GROUP_MEMBERS)

  const handleDeletePeople = () => {
    removeGroupMembers({
      variables: {
        user_ids: selected,
        group_id: groupId,
      },
    })
      .then(() => {
        useSuccessToast({ message: 'Successfully removed users from group' })
      })
      .catch((error) => {
        console.warn(error)
      })
  }

  const handleAddMembers = ({ user_ids }: GroupMembersInput) => {
    const members = []
    if (Array.isArray(user_ids)) {
      user_ids.map((userId) => {
        members.push({
          id: uuid(),
          user_id: userId,
          group_id: groupId,
          inserted_at: new Date().toUTCString(),
          updated_at: new Date().toUTCString(),
          archived_at: null,
        })
      })
    } else {
      members.push({
        id: uuid(),
        user_id: user_ids,
        group_id: groupId,
        inserted_at: new Date().toUTCString(),
        updated_at: new Date().toUTCString(),
        archived_at: null,
      })
    }
    try {
      createGroupMembers({
        variables: {
          members: members,
        },
      }).then(() => {
        useSuccessToast({
          title: 'Group members invited',
          message: `Members was successfully invited`,
        })
        onCloseGroupMembersModal()
      })
    } catch (error) {
      console.warn(error)
      onCloseGroupMembersModal()
      useErrorToast({ message: `Group members wasn't invited` })
    }
  }

  return (
    <>
      <ConfirmationModal
        isOpen={deleteIsOpen}
        onClose={deleteOnClose}
        title={`Remove Users (${countSelected})`}
        message={`Removed users will no longer have access to the group and all materials or assignments permissions will be removed.`}
        onSubmit={handleDeletePeople}
        confirmButtonText='REMOVE'
      />

      <GroupMembersModal
        isOpen={showGroupMembersModal}
        classId={classId}
        groupId={groupId}
        onSubmit={handleAddMembers}
        onClose={onCloseGroupMembersModal}
      />
      <ChatHeading title='Group' />
      <SidebarHeading
        title='Manage People'
        bg='primary.1'
        color='white.1'
        padding='16px'
      />

      <SidebarHeading
        titleWithIcon
        title='Add to Group'
        icon={FaUserPlus}
        ariaLabel='add to group'
        onClick={onOpenGroupMembersModal}
        containerProps={{
          mb: '16px',
        }}
      />

      <ManagePeopleItems
        classUsers={managePeopleAdmins}
        title='Admins'
        selected={selected}
        handleChange={handleChange}
        isSelected={isSelected}
      />
      <ManagePeopleItems
        classUsers={managePeopleTeachers}
        title='Instructors'
        selected={selected}
        handleChange={handleChange}
        isSelected={isSelected}
      />
      <ManagePeopleItems
        classUsers={managePeopleStudents}
        title='Students'
        selected={selected}
        handleChange={handleChange}
        isSelected={isSelected}
      />

      {countSelected > 0 && (
        <Flex
          w='248px'
          h='48px'
          backgroundColor='primary.1'
          color='white.1'
          pos='fixed'
          bottom='0'
          right='0'
          align='center'
          justify='space-between'
          pl='16px'
        >
          <Text textStyle='body.semibold'>{countSelected} selected</Text>
          <IconButton
            aria-label='remove from group'
            icon={<FaTrash />}
            backgroundColor='transparent'
            fontSize='16px'
            _hover={{
              backgroundColor: 'transparent',
            }}
            color='white'
            onClick={deleteOnOpen}
          />
        </Flex>
      )}
    </>
  )
}

export default ManagePeopleGroup
