import { useState, useEffect } from 'react'
import { v4 } from 'uuid'
import { dbTimestamp } from '../constants'
import {
  Exam,
  ExamArrangement,
  ExamType,
  Question,
  ResponseChoicesArrangement,
} from '../types'
import { deepOmit } from './deepOmit'

export type Options = {
  type: ExamType
  assignmentId: string
}

const sortResponseChoiceByOrder = (
  a: ResponseChoicesArrangement,
  b: ResponseChoicesArrangement
) => {
  return a.order - b.order
}

const sortQuestionByOrder = (a: ExamArrangement, b: ExamArrangement) => {
  return a.order - b.order
}

export type createQuestion = () => void
export type removeQuestion = (params: {
  order: number
  examArrangementId: string
}) => void
export type addResponseChoice = (params: { questionId: string }) => void
export type updateResponseChoice = (params: {
  questionId: string
  responseChoiceId: string
  text: string
}) => void
export type moveResponseChoiceUp = (params: {
  questionId: string
  responseChoiceId: string
}) => void
export type moveResponseChoiceDown = (params: {
  questionId: string
  responseChoiceId: string
}) => void
export type updateQuestionType = (questionId: string, type: string) => void
export type updateQuestionText = (questionId: string, text: string) => void
export type moveQuestionDown = (params: { order: number }) => void
export type moveQuestionUp = (params: { order: number }) => void
export type removeResponseChoice = (params: {
  questionId: string
  responseChoiceId: string
}) => void

export const useExamForm = (
  { type, assignmentId }: Options,
  examData?: Exam
) => {
  const [exam, setExam] = useState<Exam>({
    id: v4(),
    type,
    assignment_id: assignmentId,
    exam_arrangements: [],
  })
  const [removedExamArrangements, setRemovedExamArrangements] = useState<
    string[]
  >([])
  const [
    removedResponseChoiceArrangement,
    setRemovedResponseChoiceArrangement,
  ] = useState<string[]>([])
  const [selectedExamArrangement, setSelectedExamArrangement] = useState<
    ExamArrangement | undefined
  >()
  const [removeOnCancel, setRemoveOnCancel] = useState<boolean>(false)

  const exam_arrangements = exam.exam_arrangements || []

  useEffect(() => {
    const newExam = examData ? examData : createNewExam()
    setExam(newExam)
  }, [examData])

  // State Helpers
  const handleUpdateExamArragements = (
    exam_arrangements: ExamArrangement[]
  ) => {
    setExam({ ...exam, exam_arrangements })
  }

  const handleGetQuestion = (
    examArrangements: ExamArrangement[],
    questionId: string
  ) => {
    const examArrangement = examArrangements.find(
      (examArrangement) => examArrangement.question.id === questionId
    )

    return examArrangement?.question
  }

  const handleUpdateQuestion = (questionId: string, question: Question) => {
    const newArrangements = exam_arrangements.map((examArrangement) => {
      if (examArrangement.question.id === questionId) {
        return {
          ...examArrangement,
          question: {
            ...examArrangement.question,
            ...question,
          },
        }
      }

      return examArrangement
    })

    handleUpdateExamArragements(newArrangements)
  }

  const handleUpdateSelectedQuestion = (
    questionId: string,
    question: Question
  ) => {
    const selectedArrangement = exam_arrangements.find((examArrangements) => {
      return examArrangements.question.id === questionId
    })

    if (selectedArrangement) {
      const newSelectedArrangment = {
        ...selectedArrangement,
        question: {
          ...selectedArrangement.question,
          ...question,
        },
      }

      setSelectedExamArrangement(newSelectedArrangment)
    }
  }

  // functionality helpers
  const createNewExam = () => {
    return {
      id: v4(),
      type,
      assignment_id: assignmentId,
      exam_arrangements: [],
    }
  }

  const createQuestion: createQuestion = () => {
    const order =
      exam_arrangements.length >= 1
        ? exam_arrangements[exam_arrangements.length - 1].order + 1
        : 0

    const examArrangement = {
      id: v4(),
      order,
      question: {
        id: v4(),
        text: '',
        type: 'text' as const,
        response_choices_arrangements: [],
      },
    }

    handleUpdateExamArragements([
      ...(exam?.exam_arrangements || []),
      examArrangement,
    ])

    setRemoveOnCancel(true)
    setSelectedExamArrangement(examArrangement)
  }

  const removeQuestion: removeQuestion = ({ examArrangementId, order }) => {
    const newArrangements = exam_arrangements.reduce(
      (arrangements, arrangement) => {
        if (arrangement.order < order) {
          arrangements.push(arrangement)
        }

        if (arrangement.order > order) {
          arrangements.push({
            ...arrangement,
            order: arrangement.order - 1,
          })
        }

        const removed = examData?.exam_arrangements.find(
          (examArrangement) => examArrangement.id === examArrangementId
        )

        // A question which was present before was deleted
        if (arrangement.order === order && removed && removed?.id) {
          setRemovedExamArrangements([...removedExamArrangements, removed.id])
        }

        return arrangements
      },
      [] as ExamArrangement[]
    )

    handleUpdateExamArragements(newArrangements)
  }

  const createResponseChoice = ({ questionId }: { questionId: string }) => {
    const question = handleGetQuestion(exam_arrangements, questionId)

    if (!question) {
      return
    }

    const { response_choices_arrangements } = question

    const order =
      response_choices_arrangements.length >= 1
        ? response_choices_arrangements[
            response_choices_arrangements.length - 1
          ].order + 1
        : 0

    const responseChoiceArrangement = {
      id: v4(),
      order,
      response_choice: {
        id: v4(),
        text: '',
      },
    }

    const responseChoiceArrangements = [
      ...response_choices_arrangements,
      responseChoiceArrangement,
    ]

    const newQuestion = {
      ...question,
      response_choices_arrangements: responseChoiceArrangements,
    }

    return {
      responseChoiceArrangement,
      responseChoiceArrangements,
      newQuestion,
    }
  }

  const addResponseChoice: addResponseChoice = ({ questionId }) => {
    const responseChoice = createResponseChoice({ questionId })
    const newQuestion = responseChoice?.newQuestion

    if (!newQuestion) {
      return
    }

    handleUpdateQuestion(questionId, newQuestion)
    handleUpdateSelectedQuestion(questionId, newQuestion)
  }

  const updateResponseChoice: updateResponseChoice = ({
    questionId,
    responseChoiceId,
    text,
  }) => {
    const question = handleGetQuestion(exam_arrangements, questionId)
    if (!question) {
      return
    }
    const { response_choices_arrangements } = question

    const newResponseChoiceArrangements = response_choices_arrangements.map(
      (responseChoiceArrangement) => {
        if (responseChoiceArrangement.response_choice.id === responseChoiceId) {
          return {
            ...responseChoiceArrangement,
            response_choice: {
              ...responseChoiceArrangement.response_choice,
              text,
            },
          }
        }

        return responseChoiceArrangement
      }
    )

    const newQuestion = {
      ...question,
      response_choices_arrangements: newResponseChoiceArrangements,
    }

    handleUpdateQuestion(questionId, newQuestion)
    handleUpdateSelectedQuestion(questionId, newQuestion)
  }

  const removeResponseChoice: removeResponseChoice = ({
    questionId,
    responseChoiceId,
  }) => {
    const question = handleGetQuestion(exam_arrangements, questionId)

    if (!question) {
      return
    }

    const { response_choices_arrangements } = question

    const newResponseChoiceArrangements = response_choices_arrangements.filter(
      (responseChoiceArrangement) =>
        responseChoiceArrangement.response_choice.id !== responseChoiceId
    )

    const newQuestion = {
      ...question,
      response_choices_arrangements: newResponseChoiceArrangements,
    }

    handleUpdateQuestion(questionId, newQuestion)
    handleUpdateSelectedQuestion(questionId, newQuestion)

    // Remove old questions that were removed
    const initialQuestion = handleGetQuestion(
      examData?.exam_arrangements || [],
      questionId
    )
    const removed = initialQuestion?.response_choices_arrangements.find(
      ({ response_choice }) => response_choice.id === responseChoiceId
    )

    if (removed && removed.id) {
      setRemovedResponseChoiceArrangement([
        ...removedResponseChoiceArrangement,
        removed.id,
      ])
    }
  }

  const moveResponseChoiceUp: moveResponseChoiceUp = ({
    questionId,
    responseChoiceId,
  }) => {
    const question = handleGetQuestion(exam_arrangements, questionId)

    if (!question) {
      return
    }

    const { response_choices_arrangements } = question

    const responseChoice = response_choices_arrangements.find(
      ({ response_choice: { id } }) => id === responseChoiceId
    )

    if (!responseChoice) {
      return
    }

    const { order } = responseChoice

    if (order === 0) {
      return
    }

    const newResponseChoices = response_choices_arrangements
      .map((responseChoice) => {
        if (responseChoice.order === order - 1) {
          return { ...responseChoice, order }
        }

        if (responseChoice.order === order) {
          return { ...responseChoice, order: order - 1 }
        }

        return responseChoice
      })
      .sort(sortResponseChoiceByOrder)

    const newQuestion = {
      ...question,
      response_choices_arrangements: newResponseChoices,
    }

    handleUpdateQuestion(questionId, newQuestion)
    handleUpdateSelectedQuestion(questionId, newQuestion)
  }

  const moveResponseChoiceDown = ({
    questionId,
    responseChoiceId,
  }: {
    questionId: string
    responseChoiceId: string
  }) => {
    const question = handleGetQuestion(exam_arrangements, questionId)

    if (!question) {
      return
    }

    const { response_choices_arrangements } = question
    const responseChoice = response_choices_arrangements.find(
      ({ response_choice: { id } }) => id === responseChoiceId
    )

    if (!responseChoice) {
      return
    }

    const { order } = responseChoice

    if (order === response_choices_arrangements.length - 1) {
      return
    }

    const newResponseChoices = response_choices_arrangements
      .map((responseChoice) => {
        if (responseChoice.order === order + 1) {
          return { ...responseChoice, order }
        }

        if (responseChoice.order === order) {
          return { ...responseChoice, order: order + 1 }
        }

        return responseChoice
      })
      .sort(sortResponseChoiceByOrder)

    const newQuestion = {
      ...question,
      response_choices_arrangements: newResponseChoices,
    }

    handleUpdateQuestion(questionId, newQuestion)
    handleUpdateSelectedQuestion(questionId, newQuestion)
  }

  const getResponseChoicesArrangements = (
    type: string,
    question: Question,
    questionId: string
  ) => {
    if (type === 'text') {
      return []
    }

    if (
      question.type === 'text' &&
      ['multiple_choice', 'checkbox'].includes(type)
    ) {
      const responseChoice = createResponseChoice({ questionId })
      return responseChoice?.responseChoiceArrangements || []
    }

    return question.response_choices_arrangements
  }

  const updateQuestionType = (questionId: string, type: string) => {
    const question = handleGetQuestion(exam_arrangements, questionId)

    if (!question) {
      return
    }

    const response_choices_arrangements = getResponseChoicesArrangements(
      type,
      question,
      questionId
    )

    const newQuestion = {
      ...question,
      type,
      response_choices_arrangements,
    }

    handleUpdateQuestion(questionId, newQuestion)
    handleUpdateSelectedQuestion(questionId, newQuestion)
  }

  const updateQuestionText = (questionId: string, text: string) => {
    const question = handleGetQuestion(exam_arrangements, questionId)
    if (!question) {
      return
    }

    const newQuestion = { ...question, text }

    handleUpdateQuestion(questionId, newQuestion)
    handleUpdateSelectedQuestion(questionId, newQuestion)
  }

  const moveQuestionUp = ({ order }: { order: number }) => {
    if (order === 0) {
      return
    }

    const newExamArrangements = exam_arrangements
      .map((examArrangement) => {
        if (examArrangement.order === order - 1) {
          return { ...examArrangement, order }
        }

        if (examArrangement.order === order) {
          return { ...examArrangement, order: order - 1 }
        }

        return examArrangement
      })
      .sort(sortQuestionByOrder)

    handleUpdateExamArragements(newExamArrangements)
  }

  const moveQuestionDown = ({ order }: { order: number }) => {
    if (order === exam.exam_arrangements.length - 1) {
      return
    }

    const newExamArrangements = exam_arrangements
      .map((examArrangement) => {
        if (examArrangement.order === order + 1) {
          return { ...examArrangement, order }
        }

        if (examArrangement.order === order) {
          return { ...examArrangement, order: order + 1 }
        }

        return examArrangement
      })
      .sort(sortQuestionByOrder)

    handleUpdateExamArragements(newExamArrangements)
  }

  // https://hasura.io/docs/latest/graphql/core/databases/postgres/mutations/upsert.html
  const buildExam = () => {
    const data = {
      ...exam,
      ...dbTimestamp,
      exam_arrangements: {
        data: exam.exam_arrangements.map((examArrangement) => ({
          ...examArrangement,
          ...dbTimestamp,
          question: {
            data: {
              ...examArrangement.question,
              ...dbTimestamp,
              response_choices_arrangements: {
                data: examArrangement.question.response_choices_arrangements.map(
                  (responseChoiceArrangements) => ({
                    ...responseChoiceArrangements,
                    ...dbTimestamp,
                    response_choice: {
                      data: {
                        ...responseChoiceArrangements.response_choice,
                        ...dbTimestamp,
                      },
                      on_conflict: {
                        constraint: 'response_choices_pkey',
                        update_columns: ['updated_at', 'text'],
                      },
                    },
                  })
                ),
                on_conflict: {
                  constraint: 'response_choices_arrangements_pkey',
                  update_columns: ['order', 'updated_at'],
                },
              },
            },
            on_conflict: {
              constraint: 'questions_pkey',
              update_columns: ['updated_at', 'text', 'type'],
            },
          },
        })),
        on_conflict: {
          constraint: 'exam_arrangements_pkey',
          update_columns: ['updated_at', 'order'],
        },
      },
    }

    return deepOmit(data, ['__typename'])
  }

  return {
    createQuestion,
    removeQuestion,
    addResponseChoice,
    updateResponseChoice,
    removeResponseChoice,
    moveResponseChoiceUp,
    moveResponseChoiceDown,
    updateQuestionType,
    updateQuestionText,
    moveQuestionDown,
    moveQuestionUp,
    exam,
    exam_arrangements,
    selectedExamArrangement,
    setSelectedExamArrangement,
    buildExam,
    removedResponseChoiceArrangement,
    removedExamArrangements,
    removeOnCancel,
    setRemoveOnCancel,
  }
}
