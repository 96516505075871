import { useContext, useMemo } from 'react'
import {
  Box,
  Icon,
  HStack,
  VStack,
  StackDivider,
  Flex,
  Text,
  MenuItem,
  MenuDivider,
  Button,
  useDisclosure,
  Tooltip,
  SimpleGrid,
} from '@chakra-ui/react'
import { CommentList } from './../Post'
import { Assignment as AssignmentType, Exam } from '../../types'
import {
  getFullName,
  dateInPast,
  isLargerThanTablet,
  utcToLocale,
} from '../../utils'
import { format } from 'date-fns'
import { HoverMenu, parentHoverCss } from '../Menu'
import { RiPencilFill } from 'react-icons/ri'
import { FaTrash } from 'react-icons/fa'
import ClassWorkContext from '../../pages/ClassWork/ClassWorkContext'
import { useMutation } from '@apollo/client'
import { EDIT_ASSIGNMENT } from '../../client/queries'
import { useErrorToast, useSuccessToast } from '../../utils'
import { useAlamContext } from '../../client'
import { ConfirmationModal } from '../Modal'
import { Link } from 'react-router-dom'
import { RenderForUser } from '../SharedComponents'
import { FileList } from '../File'
import { AssignmentModal } from './index'
import { Warning } from '../Icons/Warning'
import { Assignment as AssignmentIcon, Member, Group } from '../Icons'

export type AssignmentProps = {
  assignment: AssignmentType
  context?: string
  materialAssignmentRefetch: () => void
  groupId?: string
  pendingExams: Exam[]
}

export const Assignment = ({
  assignment,
  context = 'classwork',
  materialAssignmentRefetch,
  groupId,
  pendingExams,
}: AssignmentProps): JSX.Element => {
  const { getClassTopics } = useContext(ClassWorkContext)
  const { classUser } = useAlamContext()
  const assignmentUsersCount = assignment.assignmentUsers.length
  const assignmentGroupsCount = assignment.assignmentGroups.length

  const submissionStatus = assignment?.submissions || []
  const filteredSubmissionStatus = submissionStatus.filter(
    (submission) => submission.status !== 'unsubmitted'
  )
  const filteredSubmissionCount = filteredSubmissionStatus.length
  const totalAssignmentsCount = assignmentUsersCount + assignmentGroupsCount

  // To not display the due date info on Assignment with Schedule only
  const assignmentWithDueDate = () => {
    if (!!assignment.schedule && !assignment.dueDateStr) {
      return false
    }

    return true
  }

  const dateSchedule = new Date(assignment.schedule)
  const dateDueDate = new Date(assignment.dueDateStr || '')
  const today = new Date()
  const pastSchedule = dateInPast(dateSchedule, today)
  const pastDueDate = dateInPast(dateDueDate, today)

  const {
    isOpen: editAssignmentIsOpen,
    onClose: editAssignmentOnClose,
    onOpen: editAssignmentOnOpen,
  } = useDisclosure()
  const {
    isOpen: deleteAssignmentIsOpen,
    onClose: deleteAssignmentOnClose,
    onOpen: deleteAssignmentOnOpen,
  } = useDisclosure()

  const {
    isOpen: expandedIsOpen,
    onClose: expandedOnClose,
    onOpen: expandedOnOpen,
  } = useDisclosure()

  const [archiveAssignment] = useMutation(EDIT_ASSIGNMENT, {
    variables: {
      id: assignment.id,
      changes: {
        archived_at: 'NOW()',
      },
    },
  })

  const refetch = () => {
    materialAssignmentRefetch()
    getClassTopics()
  }

  const handleDeleteAssignment = async () => {
    try {
      await archiveAssignment()
      getClassTopics()
      materialAssignmentRefetch()
      deleteAssignmentOnClose()
      useSuccessToast({
        title: 'Assignment deleted',
        message: 'Assignment was successfully deleted',
      })
    } catch (error) {
      console.warn(error)
      useErrorToast({ message: 'Failed to delete assignment' })
    }
  }

  const viewUrl = groupId
    ? `/classes/${classUser?.class?.id}/groups/${groupId}/assignments/${assignment.id}`
    : `/classes/${classUser?.class?.id}/assignments/${assignment.id}`

  const assignmentPendingExams = useMemo(() => {
    const assignmentPendingExams = pendingExams.filter(
      ({ assignment_id }) => assignment_id === assignment.id
    )
    const learningReview = assignmentPendingExams.find(
      ({ type }) => type === 'learning_review'
    )
    const assignmentReview = assignmentPendingExams.find(
      ({ type }) => type === 'assignment_review'
    )

    const hasPendingExams = assignmentPendingExams.length >= 1

    if (learningReview && assignmentReview) {
      return {
        hasPendingExams,
        message: 'Complete Assignment and Learning Review',
      }
    }

    if (learningReview) {
      return { hasPendingExams, message: 'Complete Learning Review' }
    }

    if (assignmentReview) {
      return { hasPendingExams, message: 'Complete Assignment Review' }
    }
  }, [pendingExams, assignment.id])

  return (
    <>
      <ConfirmationModal
        isOpen={deleteAssignmentIsOpen}
        onClose={deleteAssignmentOnClose}
        title={`Are you sure you want to delete ${assignment.name}?`}
        message={`All comments and data will also be deleted`}
        onSubmit={handleDeleteAssignment}
      />
      <AssignmentModal
        isOpen={editAssignmentIsOpen}
        onClose={editAssignmentOnClose}
        type='edit'
        assignment={assignment}
      />
      {expandedIsOpen ? (
        <VStack
          bg='white.1'
          layerStyle='card.module'
          w='100%'
          divider={<StackDivider layerStyle='divider' />}
          borderColor={
            assignmentPendingExams?.hasPendingExams ? 'secondary.3' : 'none'
          }
          borderWidth={
            assignmentPendingExams?.hasPendingExams ? '1.5px' : '.5px'
          }
          lineHeight='24px'
          letterSpacing='0.02em'
        >
          <Box
            layerStyle='card.expanded'
            bg='white.1'
            w='100%'
            px={4}
            pt={4}
            pb={2}
            css={parentHoverCss}
          >
            <RenderForUser
              roles={['admin', 'teacher', 'creator']}
              context={context}
            >
              <HoverMenu top={4} right={2}>
                <>
                  <MenuItem
                    icon={<RiPencilFill />}
                    onClick={editAssignmentOnOpen}
                  >
                    Edit
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem icon={<FaTrash />} onClick={deleteAssignmentOnOpen}>
                    Delete
                  </MenuItem>
                </>
              </HoverMenu>
            </RenderForUser>
            <HStack spacing={4} h='100%' w='100%'>
              {assignmentPendingExams?.hasPendingExams &&
                isLargerThanTablet() && (
                  <Icon as={Warning} fontSize='xl' color='secondary.3' />
                )}

              {!assignmentPendingExams?.hasPendingExams &&
                isLargerThanTablet() && (
                  <AssignmentIcon width='20' height='20' viewBox='8 8 24 24' />
                )}

              <Flex
                direction='column'
                onClick={expandedIsOpen ? expandedOnClose : expandedOnOpen}
                cursor='pointer'
              >
                <Tooltip
                  label={assignment.name}
                  aria-label={assignment.name}
                  placement='top-start'
                >
                  <Text
                    isTruncated
                    w={{ base: '24ch', lg: '48ch' }}
                    textStyle='h5'
                    lineHeight='24px'
                  >
                    {assignment.name}
                  </Text>
                </Tooltip>
                <HStack
                  spacing={3}
                  divider={<Box layerStyle='bulletpoint' />}
                  flexDirection={{ base: 'column', md: 'row' }}
                  alignItems={{ base: 'flex-start', md: 'center' }}
                >
                  <HStack
                    spacing={3}
                    divider={<Box layerStyle='bulletpoint' />}
                  >
                    {assignmentUsersCount === 0 &&
                      assignmentGroupsCount === 0 &&
                      classUser?.role !== 'student' && (
                        <Text textStyle='body.1' color='grey.1'>
                          Not Assigned
                        </Text>
                      )}

                    {(assignmentUsersCount >= 1 ||
                      assignmentGroupsCount >= 1) &&
                      classUser?.role !== 'student' && (
                        <SimpleGrid columns={2} gap={2} color='black.1'>
                          <Flex align='center'>
                            <Text fontSize='14px' lineHeight='16px' mr={1}>
                              {assignmentUsersCount}
                            </Text>
                            <Member
                              width='16'
                              height='16'
                              viewBox='8 8 24 24'
                              fill='#010002'
                            />
                          </Flex>
                          <Flex align='center'>
                            <Text fontSize='14px' lineHeight='16px' mr={1}>
                              {assignmentGroupsCount}
                            </Text>
                            <Group
                              width='16'
                              height='16'
                              viewBox='8 8 24 24'
                              fill='#010002'
                            />
                          </Flex>
                        </SimpleGrid>
                      )}

                    {classUser?.role === 'student' && (
                      <Text
                        textStyle='body.1'
                        color='grey.1'
                        noOfLines={1}
                        isTruncated
                      >
                        {getFullName(assignment?.user)}
                      </Text>
                    )}
                  </HStack>

                  <HStack
                    spacing={3}
                    divider={<Box layerStyle='bulletpoint' />}
                  >
                    {assignment.comments?.length === 0 ? (
                      <Text textStyle='body.1' color='grey.1'>
                        No Comments
                      </Text>
                    ) : (
                      <Text textStyle='body.1' color='black.1'>
                        {assignment.comments?.length > 1
                          ? `${assignment.comments.length} comments`
                          : `${assignment.comments.length} comment`}
                      </Text>
                    )}

                    {/* <Text textStyle='body.1' color='grey.1'>
                    Created{' '}
                    {format(new Date(assignment?.inserted_at), dateFormat)}
                  </Text> */}

                    {!pastSchedule &&
                      !assignment.dueDateStr &&
                      classUser?.role !== 'student' && (
                        <Text textStyle='body.1' color='black.1'>
                          Scheduled{' '}
                          {format(
                            utcToLocale(assignment?.schedule),
                            'MMM d, y'
                          )}
                        </Text>
                      )}

                    {assignment.dueDateStr &&
                      filteredSubmissionCount === 0 &&
                      classUser?.role !== 'student' && (
                        <Text textStyle='body.1' color='secondary.1'>
                          Due{' '}
                          {format(utcToLocale(assignment?.dueDateStr), 'MMM d, y')}
                        </Text>
                      )}

                    {filteredSubmissionCount >= 1 &&
                      assignmentWithDueDate() &&
                      classUser?.role !== 'student' && (
                        <Text textStyle='body.1' color='black.1'>
                          {filteredSubmissionCount} / {totalAssignmentsCount}{' '}
                          Submitted
                        </Text>
                      )}

                    {!pastDueDate &&
                      assignment?.dueDateStr &&
                      classUser?.role === 'student' && (
                        <Text textStyle='body.1' color='primary.1'>
                          Due{' '}
                          {format(utcToLocale(assignment?.dueDateStr), 'MMM d, y')}
                          , {format(utcToLocale(assignment?.dueDateStr), 'k:mm')}{' '}
                          UTC
                        </Text>
                      )}
                  </HStack>
                </HStack>
              </Flex>
            </HStack>
          </Box>

          {assignment.description && (
            <Box w='100%' px={6} py={2} textStyle='body.big'>
              {assignment.description}
            </Box>
          )}

          {assignment.attachments.length >= 1 && (
            <FileList
              existingFiles={assignment.attachments}
              isReadOnly
              removeBorder
              iconProps={{
                width: '1.2rem',
                height: '1.2rem',
              }}
            />
          )}

          <Box w='100%'>
            {assignment.comments?.length >= 1 ? (
              <CommentList comments={assignment.comments} refetch={refetch} />
            ) : (
              <Text textStyle='body.1' color='grey.1' px={4} py={2}>
                0 comment
              </Text>
            )}
          </Box>
          <Box w='100%' px={4} pb={4} pt={2}>
            <Flex w='100%' direction='row' justify='flex-end'>
              <Link to={viewUrl}>
                <Button variant='base.primary' textStyle='button' minW='72px'>
                  View Assignment
                </Button>
              </Link>
            </Flex>
          </Box>
        </VStack>
      ) : (
        <Box
          layerStyle='card.board-updated-shadow'
          borderColor={
            assignmentPendingExams?.hasPendingExams ? 'secondary.3' : 'none'
          }
          borderWidth={
            assignmentPendingExams?.hasPendingExams ? '1.5px' : '.5px'
          }
          bg='white.1'
          w='100%'
          h={{ base: 'auto', md: '72px' }}
          p={4}
          css={parentHoverCss}
          lineHeight='24px'
          letterSpacing='0.02em'
        >
          <RenderForUser
            roles={['admin', 'teacher', 'creator']}
            context={context}
          >
            <HoverMenu top={4} right={2}>
              <>
                <MenuItem
                  icon={<RiPencilFill />}
                  onClick={editAssignmentOnOpen}
                >
                  Edit
                </MenuItem>
                <MenuDivider />
                <MenuItem icon={<FaTrash />} onClick={deleteAssignmentOnOpen}>
                  Delete
                </MenuItem>
              </>
            </HoverMenu>
          </RenderForUser>

          <HStack spacing={4} h='100%' w='100%'>
            {assignmentPendingExams?.hasPendingExams &&
              isLargerThanTablet() && (
                <Icon as={Warning} fontSize='xl' color='secondary.3' />
              )}

            {!assignmentPendingExams?.hasPendingExams &&
              isLargerThanTablet() && (
                <AssignmentIcon width='20' height='20' viewBox='8 8 24 24' />
              )}

            <Flex
              height='auto'
              direction='column'
              onClick={expandedIsOpen ? expandedOnClose : expandedOnOpen}
              cursor='pointer'
            >
              <Tooltip
                label={assignment.name}
                aria-label={assignment.name}
                placement='top-start'
              >
                <Text
                  isTruncated
                  w={{ base: '24ch', lg: '48ch' }}
                  textStyle='h5'
                  lineHeight='24px'
                >
                  {assignment.name}
                </Text>
              </Tooltip>

              {assignmentPendingExams?.hasPendingExams ? (
                <HStack spacing={3} divider={<Box layerStyle='bulletpoint' />}>
                  <Text textStyle='body.1' color='secondary.3'>
                    {assignmentPendingExams.message}
                  </Text>
                </HStack>
              ) : (
                <HStack
                  spacing={3}
                  divider={<Box layerStyle='bulletpoint' />}
                  flexDirection={{ base: 'column', md: 'row' }}
                  alignItems={{ base: 'flex-start', md: 'center' }}
                >
                  <HStack
                    spacing={3}
                    divider={<Box layerStyle='bulletpoint' />}
                  >
                    {assignmentUsersCount === 0 &&
                      assignmentGroupsCount === 0 &&
                      classUser?.role !== 'student' && (
                        <Text textStyle='body.1' color='grey.1'>
                          Not Assigned
                        </Text>
                      )}

                    {(assignmentUsersCount >= 1 ||
                      assignmentGroupsCount >= 1) &&
                      classUser?.role !== 'student' && (
                        <SimpleGrid columns={2} gap={2} color='black.1'>
                          <Flex align='center'>
                            <Text fontSize='14px' lineHeight='16px' mr={1}>
                              {assignmentUsersCount}
                            </Text>
                            <Member
                              width='16'
                              height='16'
                              viewBox='8 8 24 24'
                              fill='#010002'
                            />
                          </Flex>
                          <Flex align='center'>
                            <Text fontSize='14px' lineHeight='16px' mr={1}>
                              {assignmentGroupsCount}
                            </Text>
                            <Group
                              width='16'
                              height='16'
                              viewBox='8 8 24 24'
                              fill='#010002'
                            />
                          </Flex>
                        </SimpleGrid>
                      )}

                    {classUser?.role === 'student' && (
                      <Text
                        textStyle='body.1'
                        color='grey.1'
                        noOfLines={1}
                        isTruncated
                      >
                        {getFullName(assignment?.user)}
                      </Text>
                    )}
                  </HStack>

                  <HStack
                    spacing={3}
                    divider={<Box layerStyle='bulletpoint' />}
                  >
                    {assignment.comments?.length === 0 ? (
                      <Text textStyle='body.1' color='grey.1'>
                        No Comments
                      </Text>
                    ) : (
                      <Text textStyle='body.1' color='black.1'>
                        {assignment.comments?.length > 1
                          ? `${assignment.comments.length} comments`
                          : `${assignment.comments.length} comment`}
                      </Text>
                    )}

                    {/* <Text textStyle='body.1' color='grey.1'>
                    Created{' '}
                    {format(new Date(assignment?.inserted_at), dateFormat)}
                  </Text> */}

                    {!pastSchedule &&
                      !assignment.dueDateStr &&
                      classUser?.role !== 'student' && (
                        <Text textStyle='body.1' color='black.1'>
                          Scheduled{' '}
                          {format(
                            utcToLocale(assignment?.schedule),
                            'MMM d, y'
                          )}
                        </Text>
                      )}

                    {assignment.dueDateStr &&
                      filteredSubmissionCount === 0 &&
                      classUser?.role !== 'student' && (
                        <Text textStyle='body.1' color='secondary.1'>
                          Due{' '}
                          {format(utcToLocale(assignment?.dueDateStr), 'MMM d, y')}
                        </Text>
                      )}

                    {filteredSubmissionCount >= 1 &&
                      assignmentWithDueDate() &&
                      classUser?.role !== 'student' && (
                        <Text textStyle='body.1' color='black.1'>
                          {filteredSubmissionCount} / {totalAssignmentsCount}{' '}
                          Submitted
                        </Text>
                      )}

                    {!pastDueDate &&
                      assignment?.dueDateStr &&
                      classUser?.role === 'student' && (
                        <Text textStyle='body.1' color='primary.1'>
                          Due{' '}
                          {format(utcToLocale(assignment?.dueDateStr), 'MMM d, y')}
                          , {format(utcToLocale(assignment?.dueDateStr), 'k:mm')}{' '}
                          UTC
                        </Text>
                      )}
                  </HStack>
                </HStack>
              )}
            </Flex>
          </HStack>
        </Box>
      )}
    </>
  )
}

export default Assignment
