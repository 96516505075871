import React from 'react'
import { IconButton, Text, Flex, useDisclosure } from '@chakra-ui/react'
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md'
import { EventLog } from '../../types'
import { SubmissionHistoryItem } from './index'

export type SubmissionHistoryProps = {
  eventLogs?: EventLog[]
  grading?: string
  currentUserRole: string
}

export const SubmissionHistory = ({
  eventLogs = [],
  grading,
  currentUserRole,
}: SubmissionHistoryProps) => {
  const { onClose, isOpen, onOpen } = useDisclosure()

  return (
    <Flex direction='column' bg='grey.2' w='100%'>
      <Flex
        h='48px'
        w='100%'
        align='center'
        borderBottomStyle='solid'
        borderBottomWidth={isOpen && eventLogs.length >= 1 ? '.5px' : '0px'}
        borderBottomColor='primary.2'
      >
        {isOpen ? (
          <IconButton
            ml='10px'
            icon={<MdKeyboardArrowUp />}
            color='primary.1'
            aria-label='hide submission history'
            onClick={onClose}
            variant='blank'
          />
        ) : (
          <IconButton
            ml='10px'
            icon={<MdKeyboardArrowDown />}
            color='primary.1'
            aria-label='show submission history'
            onClick={onOpen}
            variant='blank'
          />
        )}
        <Text textStyle='semibold'>Show History</Text>
      </Flex>

      {isOpen &&
        eventLogs.map((eventLog) => (
          <React.Fragment key={eventLog.id}>
            <SubmissionHistoryItem
              grading={grading}
              currentUserRole={currentUserRole}
              {...eventLog}
            />
          </React.Fragment>
        ))}
    </Flex>
  )
}
