import { Route } from 'react-router-dom'
import { routes } from '../../constants'
import pickBy from 'lodash-es/pickBy'
import { GroupChat } from './index'

export const GroupChatRoute = (): JSX.Element => {
  const groupRoutes = pickBy(routes, (route) => route.groupChat)
  const paths = Object.keys(groupRoutes).map(
    (routeName: string) => routes[routeName].path
  )
  return (
    <Route path={paths} exact>
      <GroupChat />
    </Route>
  )
}
