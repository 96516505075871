import React from 'react'
import { IconAlamProps } from './Type'

export const AccountRemove = ({
  fill,
  width,
  height,
  viewBox,
}: IconAlamProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M22.54 22C25.21 22 30.54 23.33 30.54 26V28H14.54V26C14.54 23.33 19.87 22 22.54 22ZM22.54 20C21.4791 20 20.4617 19.5786 19.7116 18.8284C18.9614 18.0783 18.54 17.0609 18.54 16C18.54 14.9391 18.9614 13.9217 19.7116 13.1716C20.4617 12.4214 21.4791 12 22.54 12C23.6009 12 24.6183 12.4214 25.3684 13.1716C26.1186 13.9217 26.54 14.9391 26.54 16C26.54 17.0609 26.1186 18.0783 25.3684 18.8284C24.6183 19.5786 23.6009 20 22.54 20ZM12.54 17.59L14.66 15.46L16.08 16.88L13.95 19L16.08 21.12L14.66 22.54L12.54 20.41L10.42 22.54L9 21.12L11.13 19L9 16.88L10.42 15.46L12.54 17.59Z'
      fill={fill}
    />
  </svg>
)

AccountRemove.defaultProps = {
  fill: '#17053D',
  width: '40',
  height: '40',
  viewBox: '0 0 40 40',
}
