import { Button, Icon } from '@chakra-ui/react'
import { MdFileDownload } from 'react-icons/md'
import { useQuery } from '@apollo/client'
import {
  GET_DOWNLOAD_HEADER_DATA,
  GET_ANALYTICS_STUDENT_DATA,
  GET_ANALYTICS_GROUP_DATA,
} from '../../client/queries'
import {
  AnalyticsGroupData,
  AnalyticsUserData,
  constructCSVTableHeadings,
  sortStudentDataAZ,
  formatCSVHeading,
  renderCSVStudentCellContent,
  renderCSVGroupCellContent,
} from '../../utils'
import JSZip from 'jszip'
import { saveAs } from 'file-saver-es'

type DownloadButtonProps = {
  topicId?: string
  classId: string
  buttonProps?: Record<string, any>
}

export const DownloadButton = ({
  topicId,
  classId,
  buttonProps = {},
}: DownloadButtonProps): JSX.Element => {
  const headerQueryVariables =
    !topicId || topicId === 'no-topic'
      ? {
          class_id: classId,
          with_topic: false,
          is_topicless: topicId === 'no-topic',
        }
      : {
          class_id: classId,
          with_topic: true,
          topic_id: topicId,
          is_topicless: false,
        }
  const { data: headerData } = useQuery(GET_DOWNLOAD_HEADER_DATA, {
    variables: headerQueryVariables,
  })

  const { data: studentData } = useQuery(GET_ANALYTICS_STUDENT_DATA, {
    variables: { class_id: classId },
  })

  const sortedStudentData = sortStudentDataAZ(studentData?.users || [])

  const { data: groupData } = useQuery(GET_ANALYTICS_GROUP_DATA, {
    variables: { class_id: classId },
  })

  const tableHeadingsFromData = constructCSVTableHeadings(headerData)

  const formattedHeaderData = formatCSVHeading(tableHeadingsFromData)

  const studentHeadings =
    '"Full Name","Learning Preference",' + formattedHeaderData
  const studentRows = sortedStudentData
    .map((student: AnalyticsUserData) => {
      const name = `"${student.firstName + ' ' + student.lastName}"`
      const preference = `"${(student.learning_style || []).join(',')}"`
      const studentInfoColumns = `${name},${preference}`
      const classWorkInfoColumns = tableHeadingsFromData
        .map((heading) => {
          return renderCSVStudentCellContent(student, heading)
        })
        .join(',')
      return `${studentInfoColumns},${classWorkInfoColumns}`
    })
    .join('\n')

  const studentCSVContent = `${studentHeadings}\n${studentRows}`

  const groups = groupData?.groups || []
  const groupHeadings = '"Group Name",' + formattedHeaderData
  const groupRows = groups
    .map((group: AnalyticsGroupData) => {
      const name = `"${group.name}"`
      const classWorkInfoColumns = tableHeadingsFromData
        .map((heading) => {
          return renderCSVGroupCellContent(group, heading)
        })
        .join(',')
      return `${name},${classWorkInfoColumns}`
    })
    .join('\n')

  const groupCSVContent = `${groupHeadings}\n${groupRows}`

  const handleOnClick = () => {
    const zip = new JSZip()
    zip.file('studentData.csv', studentCSVContent)
    zip.file('groupData.csv', groupCSVContent)
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, 'download.zip')
    })
  }

  return (
    <Button
      leftIcon={<Icon as={MdFileDownload} width='16px' height='16px' />}
      variant='icon.button'
      fontSize='14px'
      fontWeight='600'
      textTransform='uppercase'
      boxShadow='rgb(0 0 0 / 10%) 0px 4px 6px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px'
      letterSpacing='0.73233px'
      borderRadius='12px'
      h='48px'
      w='auto'
      onClick={handleOnClick}
      {...buttonProps}
    >
      Download
    </Button>
  )
}
