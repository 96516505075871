import React from 'react'

export const Delete = () => {
  return (
    <svg
      width='16'
      height='18'
      viewBox='0 0 16 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.20078 1.19961H7.10078L6.50078 0.599609H3.50078L2.90078 1.19961H0.800781V2.39961H9.20078V1.19961ZM1.40078 10.1996C1.40078 10.5179 1.52721 10.8231 1.75225 11.0481C1.9773 11.2732 2.28252 11.3996 2.60078 11.3996H7.40078C7.71904 11.3996 8.02427 11.2732 8.24931 11.0481C8.47435 10.8231 8.60078 10.5179 8.60078 10.1996V2.99961H1.40078V10.1996Z'
        fill='#17053D'
      />
    </svg>
  )
}

export default Delete
