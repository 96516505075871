export const useFilename = (
  path: string
): { filename: string; extension: string } => {
  // extract file name from full path ...
  const basename = path?.split(/[\\/]/).pop() || ''
  const position = basename?.lastIndexOf('.')

  if (basename === '') {
    return { filename: '', extension: '' }
  }

  // . doesn't exist or is in front eg: .config
  if (position < 1) {
    return { filename: basename, extension: '' }
  }

  const filename = basename.slice(0, position)
  const ext = basename.slice(position + 1)
  const extension = removeQuestionMark(ext)

  return { filename, extension }
}

const removeQuestionMark = (extension: string) => {
  const position = extension.lastIndexOf('?')

  if (position < 1) {
    return extension
  }

  return extension.slice(0, position)
}
