import { Fragment, PureComponent } from 'react'
import { useRouteMatch } from 'react-router'
import {
  Container,
  Box,
  Button,
  Flex,
  Text,
  HStack,
  VStack,
  StackDivider,
  Icon,
} from '@chakra-ui/react'
import { MdFileDownload } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import { useSubscription } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts'
import { Topic } from '../../types'
import { Select } from '../../components/Input'
import { GET_CLASS_REPORT } from '../../client/queries'
import { Layout } from '../../components/Layout'
import { TopNavItemProps } from '../../components/TopNav'
import { renderCustomizedPieLabel } from '../../utils'
import { DownloadButton } from '../../components/Analytics'

type ClassStatTypes = {
  id: string
  name: string
  count: string
}

type PieChartLabelTypes = {
  cx: number
  cy: number
  midAngle: number
  innerRadius: number
  outerRadius: number
  percent: number
  index?: number
}

type LearningPreferenceTypes = {
  id: string
  name: string
  studentCount: string | number
  percentage: string
  legendColor: string
}

type TopicTypes = {
  id: string
  name: string
  materialsAssignments: string[]
}

type ClassAnalyticsPageProps = {
  topNavLeft: TopNavItemProps[]
  topNavCenter: TopNavItemProps[]
  topicOptions: Array<{ value: string; display: string }>
  handleTopicChange: (val: string) => void
}

const ClassAnalyticsPage = ({
  topNavLeft,
  topNavCenter,
  topicOptions,
  handleTopicChange,
}: ClassAnalyticsPageProps): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const { data } = useSubscription(GET_CLASS_REPORT, {
    variables: { class_id: id },
  })

  const classTopics = data?.classes_by_pk?.topics || []
  const studentCount = data?.classes_by_pk?.studentCount.aggregate.count || 0
  const topicsCount = data?.classes_by_pk?.topicsCount.aggregate.count || 0
  const materialsCount =
    data?.classes_by_pk?.materialsCount.aggregate.count || 0
  const assignmentsCount =
    data?.classes_by_pk?.assignmentsCount.aggregate.count || 0
  const classUsers = data?.classes_by_pk?.class_users || []

  const classStats = [
    {
      id: '1',
      name: 'Students',
      count: studentCount,
    },
    {
      id: '2',
      name: 'Topics',
      count: topicsCount,
    },
    {
      id: '3',
      name: 'Materials',
      count: materialsCount,
    },
    {
      id: '4',
      name: 'Assignments',
      count: assignmentsCount,
    },
  ]

  const preferenceCount = (preference: string) => {
    if (preference === 'multi') {
      return classUsers.filter(
        (cu: any) => cu.user.learning_preferences?.length > 1
      ).length
    }

    return classUsers.filter(
      (cu: any) =>
        cu.user.learning_preferences?.includes(preference) &&
        cu.user.learning_preferences.length < 2
    ).length
  }

  const computeLearningPreferencesPercentage = (preference: string) => {
    let count = 0
    if (preference === 'multi') {
      count = classUsers.filter(
        (cu: any) => cu.user.learning_preferences?.length > 1
      ).length
    } else {
      count = preferenceCount(preference)
    }
    const percentage = (count / studentCount) * 100
    const result = isNaN(percentage) ? 0 : percentage.toFixed(2)
    if (isNaN(percentage)) {
      return 0
    } else if (percentage % 1 > 0) {
      return percentage.toFixed(2)
    } else {
      return percentage
    }
  }

  const graphData = [
    {
      name: 'Visual',
      value: preferenceCount('visual'),
    },
    {
      name: 'Aural',
      value: preferenceCount('aural'),
    },
    {
      name: 'Reading/Writing',
      value: preferenceCount('read'),
    },
    {
      name: 'Kinesthetic',
      value: preferenceCount('kinesthetic'),
    },
    {
      name: 'Multi-Modal',
      value: preferenceCount('multi'),
    },
  ]

  const graphColors = ['#EE2092', '#6A61FA', '#09C6CD', '#392ACC', '#ED8D22']

  const learningPreferences = [
    {
      id: '1',
      name: 'Visual',
      studentCount: preferenceCount('visual'),
      percentage: `${computeLearningPreferencesPercentage('visual')}%`,
      legendColor: '#EE2092',
    },
    {
      id: '2',
      name: 'Aural',
      studentCount: preferenceCount('aural'),
      percentage: `${computeLearningPreferencesPercentage('aural')}%`,
      legendColor: '#6A61FA',
    },
    {
      id: '3',
      name: 'Reading/Writing',
      studentCount: preferenceCount('read'),
      percentage: `${computeLearningPreferencesPercentage('read')}%`,
      legendColor: '#09C6CD',
    },
    {
      id: '4',
      name: 'Kinesthetic',
      studentCount: preferenceCount('kinesthetic'),
      percentage: `${computeLearningPreferencesPercentage('kinesthetic')}%`,
      legendColor: '#392ACC',
    },
    {
      id: '5',
      name: 'Multi-Modal',
      studentCount: preferenceCount('multi'),
      percentage: `${computeLearningPreferencesPercentage('multi')}%`,
      legendColor: '#ED8D22',
    },
  ]

  const topics: TopicTypes[] = classTopics.map((topic: any) => ({
    id: topic.id,
    name: topic.name,
    materialsAssignments: topic.materials
      .map((material: any) => material.title)
      .concat(topic.assignments.map((assignment: any) => assignment.name)),
  }))

  const { url } = useRouteMatch()

  const history = useHistory()

  return (
    <Layout topNavLeft={topNavLeft} topNavCenter={topNavCenter} showSideNav>
      <Box px={6} pt={6} mb={8}>
        <Flex align='center' justify='space-between'>
          <Select
            value='default'
            options={topicOptions}
            onChange={handleTopicChange}
            menuButtonProps={{
              textStyle: 'button',
              letterSpacing: '0.06rem',
              variant: 'tab.selector',
              textAlign: 'left',
              p: '8px 12px',
              w: 'auto',
              layerStyle: 'card.module',
              color: 'primary.1',
            }}
            menuItemProps={{
              textStyle: 'body.1',
              color: 'primary.1',
              w: '196px',
              _hover: { bg: 'hover.2', color: 'white.1' },
              overflow: 'hidden',
            }}
            textDisplayProps={{
              maxW: '18ch',
              isTruncated: true,
              textAlign: 'left',
              textStyle: 'button',
            }}
            adjustRightIconPadding='1rem'
          />

          <DownloadButton classId={id} />
        </Flex>
      </Box>
      <Container
        maxW={{ base: '100%', md: '456px', lg: '500px', xl: '1080px' }}
        px={0}
      >
        <Text textStyle='h2' mb={6} color='rgba(0, 0, 0, 1)'>
          Overview
        </Text>
        <HStack spacing={6} mb={6}>
          {classStats.map((classStat: ClassStatTypes) => (
            <Box
              w='252px'
              h='100%'
              bg='white.1'
              px={8}
              py='26px'
              borderRadius='12px'
              borderWidth='0.5px'
              borderStyle='solid'
              borderColor='bulletinModalDivider'
              key={classStat.id}
            >
              <Text
                textStyle='h5'
                fontSize='20px'
                lineHeight='27px'
                mb='0.5rem'
                color='primary.1'
              >
                {classStat.name}
              </Text>
              <Text textStyle='h3' fontSize='24px' lineHeight='32px'>
                {classStat.count}
              </Text>
            </Box>
          ))}
        </HStack>

        <Box
          bg='white.1'
          borderRadius='12px'
          p={10}
          mb={6}
          borderWidth='0.5px'
          borderStyle='solid'
          borderColor='bulletinModalDivider'
        >
          <Text
            textStyle='h5'
            fontSize='18px'
            lineHeight='27px'
            mb={8}
            color='primary.1'
          >
            Learning Preferences
          </Text>
          <Flex>
            <Box w='560px'>
              <VStack
                w='100%'
                alignItems='flex-start'
                divider={
                  <StackDivider
                    layerStyle='dividerGrey'
                    borderColor='bulletinModalDivider'
                  />
                }
              >
                {learningPreferences.map(
                  (learningPreference: LearningPreferenceTypes) => (
                    <Flex
                      align='center'
                      justify='space-between'
                      w='100%'
                      key={learningPreference.id}
                    >
                      <Flex align='center'>
                        <Box
                          w='12px'
                          h='12px'
                          borderRadius='50%'
                          bg={learningPreference.legendColor}
                          mr={4}
                        />
                        <Box>
                          <Text
                            textStyle='body.semibold'
                            color='primary.1'
                            fontSize='16px'
                            lineHeight='24px'
                          >
                            {learningPreference.name}
                          </Text>
                          <Text
                            textStyle='body.2'
                            color='rgba(1, 0, 2, 0.56)'
                            fontSize='14px'
                            lineHeight='22px'
                          >
                            {learningPreference.studentCount} student
                            {learningPreference.studentCount > 1 ? 's' : ''}
                          </Text>
                        </Box>
                      </Flex>
                      <Flex>
                        <Text textStyle='h5' color='primary.1'>
                          {learningPreference.percentage}
                        </Text>
                      </Flex>
                    </Flex>
                  )
                )}
              </VStack>
            </Box>

            <Flex align='center' justify='center' flex='1'>
              <ResponsiveContainer width='100%' height='100%'>
                <PieChart width={800} height={400}>
                  <Pie
                    data={graphData}
                    cx={260}
                    cy={120}
                    labelLine={false}
                    label={renderCustomizedPieLabel}
                    innerRadius={80}
                    outerRadius={125}
                    fill='#8884d8'
                    dataKey='value'
                    isAnimationActive={false}
                  >
                    {graphData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={graphColors[index % graphColors.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </Flex>
          </Flex>
        </Box>

        {topics.length >= 1 && (
          <Box
            bg='white.1'
            borderRadius='12px'
            p={10}
            mb={10}
            borderWidth='0.5px'
            borderStyle='solid'
            borderColor='bulletinModalDivider'
          >
            <VStack
              w='100%'
              alignItems='flex-start'
              divider={
                <StackDivider
                  layerStyle='dividerGrey'
                  borderColor='bulletinModalDivider'
                />
              }
              spacing={4}
            >
              <Box>
                <Text textStyle='h5' color='primary.1'>
                  Topics
                </Text>
              </Box>
              {topics.map((topic: TopicTypes) => {
                const listOfAssignmentAndMaterials = topic.materialsAssignments.join(
                  ', '
                )
                return (
                  <Flex
                    align='center'
                    justify='space-between'
                    w='100%'
                    key={topic.id}
                  >
                    <Box mr={2}>
                      <Text
                        textStyle='body.semibold'
                        color='primary.1'
                        cursor='pointer'
                        onClick={() =>
                          history.push(
                            `/classes/${id}/analytics/class/topic/${topic.id}`
                          )
                        }
                      >
                        {topic.name}
                      </Text>
                      <Text textStyle='subtitle' color='rgba(1, 0, 2, 0.56)'>
                        {listOfAssignmentAndMaterials}
                      </Text>
                    </Box>
                    <Box>
                      <Text
                        textStyle='smallstate'
                        letterSpacing='0.02em'
                        cursor='pointer'
                        onClick={() =>
                          history.push(
                            `/classes/${id}/analytics/class/topic/${topic.id}`
                          )
                        }
                        color='primary.1'
                        whiteSpace='nowrap'
                      >
                        View Analytics
                      </Text>
                    </Box>
                  </Flex>
                )
              })}
            </VStack>
          </Box>
        )}
      </Container>
    </Layout>
  )
}

export default ClassAnalyticsPage
