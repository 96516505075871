import React from 'react'
import { IconAlamProps } from './Type'

export const ManagePeople = ({
  fill,
  width,
  height,
  viewBox,
}: IconAlamProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M19.6 16.0004C20.704 16.0004 21.592 15.1044 21.592 14.0004C21.592 12.8964 20.704 12.0004 19.6 12.0004C18.496 12.0004 17.6 12.8964 17.6 14.0004C17.6 15.1044 18.496 16.0004 19.6 16.0004ZM13.6 15.2004C14.928 15.2004 15.992 14.1284 15.992 12.8004C15.992 11.4724 14.928 10.4004 13.6 10.4004C12.272 10.4004 11.2 11.4724 11.2 12.8004C11.2 14.1284 12.272 15.2004 13.6 15.2004ZM19.6 17.6004C18.136 17.6004 15.2 18.3364 15.2 19.8004V21.6004H24V19.8004C24 18.3364 21.064 17.6004 19.6 17.6004ZM13.6 16.8004C11.736 16.8004 8 17.7364 8 19.6004V21.6004H13.6V19.8004C13.6 19.1204 13.864 17.9284 15.496 17.0244C14.8 16.8804 14.128 16.8004 13.6 16.8004Z'
      fill={fill}
    />
  </svg>
)

ManagePeople.defaultProps = {
  fill: '#17053D',
  width: '40',
  height: '40',
  viewBox: '0 0 40 40',
}
