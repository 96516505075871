import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  IconButton,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { BiChevronLeft } from 'react-icons/bi'
import { v4 } from 'uuid'
import { useClassWorkContext } from '../../pages/ClassWork/ClassWorkContext'
import { Exam } from '../../types'
import { OneItemSideNav, AssignmentInfoForm } from '../Assignment'
import { AssignmentReviewForm, LearningReviewForm } from '../Exam'
import { AssignmentType } from '../../pages/Assignment/AssignmentPage'
import { isIPhone } from '../../utils'

export type ExamModalProps = {
  isOpen: boolean
  onClose: () => void
  assignment: AssignmentType
  exam: Exam
}

export type FormType = {
  value: string
  display: string
  showInSideNav: boolean
}

export type FormsType = Record<string, FormType>
export type ModalState = {
  title: string
  showQuestionHeader: boolean
  onClickBack: () => void
}

export const ExamModal = ({
  isOpen,
  onClose,
  assignment,
  exam,
}: ExamModalProps): JSX.Element => {
  const defaultFormsState = {
    info: {
      value: 'assignmentInfo',
      display: 'Assignment Info',
      showInSideNav: false,
    },
    assignmentReview: {
      value: 'assignmentReview',
      display: 'Assignment Review',
      showInSideNav: exam.type === 'assignment_review',
    },
    learningReview: {
      value: 'learningReview',
      display: 'Learning Review',
      showInSideNav: exam.type === 'learning_review',
    },
  }

  const defaultForm =
    exam.type === 'assignment_review' ? 'assignmentReview' : 'learningReview'

  const [forms, setForms] = useState<FormsType>(defaultFormsState)
  const [selectedForm, setSelectedForm] = useState(defaultForm)
  // for new assignments
  const [modalState, setModalState] = useState<ModalState>({
    title: '',
    showQuestionHeader: false,
    onClickBack: () => {},
  })

  const assignmentId = assignment.id

  const title =
    exam.type === 'assignment_review'
      ? forms.assignmentReview.display
      : forms.learningReview.display

  const resetForm = () => {
    setForms(defaultFormsState)
    setSelectedForm(defaultForm)
  }

  const handleCloseModal = () => {
    onClose()
    resetForm()
  }

  const assignmentReviewOnSubmit = () => {
    if (forms.learningReview.showInSideNav) {
      setSelectedForm(forms.learningReview.value)
    } else {
      onClose()
      resetForm()
    }
  }

  const learningReviewOnSubmit = () => {
    onClose()
    resetForm()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCloseModal}
      size='3xl'
      variant='withDivider'
    >
      <ModalOverlay />
      <ModalContent
        my={{ base: '0px !important', md: '3.75rem !important' }}
        borderRadius={{ base: '0px', md: '12px' }}
      >
        {modalState.showQuestionHeader ? (
          <>
            <ModalHeader>{modalState.title}</ModalHeader>
            <IconButton
              fontSize='20px'
              aria-label='Go back'
              variant='icon.button'
              icon={<BiChevronLeft />}
              onClick={modalState.onClickBack}
              pos='absolute'
              top={4}
              insetInlineEnd='unset'
              left={2}
              paddingBottom='4px'
            />
          </>
        ) : (
          <>
            <ModalHeader>{title}</ModalHeader>
            <ModalCloseButton />
          </>
        )}

        <ModalBody p='0' minH='705px'>
          <Flex w='100%' minH='705px' direction={{ base: 'column', md: 'row' }}>
            {selectedForm !== forms.info.value && (
              <OneItemSideNav selectedForm={selectedForm} forms={forms} />
            )}
            {exam.type === 'assignment_review' ? (
              <AssignmentReviewForm
                assignmentId={assignment.id}
                setModalState={setModalState}
                display={forms.assignmentReview.display}
                onSubmit={assignmentReviewOnSubmit}
                assignmentReview={exam}
                submitText='Save'
              />
            ) : (
              <LearningReviewForm
                assignmentId={assignment.id}
                setModalState={setModalState}
                display={forms.learningReview.display}
                onSubmit={learningReviewOnSubmit}
                learningReview={exam}
                submitText='Save'
              />
            )}
          </Flex>
          {
            // make sure button doesn't get blocked by IOS browser bottom bar
            isIPhone() && <Flex h='100px'></Flex>
          }
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
