import { gql } from '@apollo/client'
import {
  userDisplayFields,
  studentAnalyticsAssignmentFields,
  studentAnalyticsMaterialFields,
  analyticsSubmissionFields,
  analyticsQuestionFields,
} from './fragments'

export const GET_REVIEW_REPORT = gql`
  query get_review_reports($assignment_id: uuid!) {
    totalStudentCount: assignment_users_aggregate(
      where: { assignment_id: { _eq: $assignment_id } }
    ) {
      aggregate {
        count
      }
    }

    totalGroupCount: assignment_groups(
      where: { assignment_id: { _eq: $assignment_id } }
    ) {
      group {
        group_users_aggregate(where: { archived_at: { _is_null: true } }) {
          aggregate {
            count
          }
        }
      }
    }

    assignmentReviewQuestions: questions_aggregate(
      where: {
        exam_arrangements: {
          exam: {
            assignment_id: { _eq: $assignment_id }
            type: { _eq: "assignment_review" }
          }
        }
      }
    ) {
      aggregate {
        count
      }
    }

    learningReviewQuestions: questions_aggregate(
      where: {
        exam_arrangements: {
          exam: {
            assignment_id: { _eq: $assignment_id }
            type: { _eq: "learning_review" }
          }
        }
      }
    ) {
      aggregate {
        count
      }
    }

    completeLearningReviews: exam_responses(
      where: {
        completed_at: { _is_null: false }
        exam: {
          assignment_id: { _eq: $assignment_id }
          type: { _eq: "learning_review" }
        }
      }
    ) {
      id
      started_at
      completed_at
    }

    incompleteLearningReviews: exam_responses(
      where: {
        completed_at: { _is_null: true }
        exam: {
          assignment_id: { _eq: $assignment_id }
          type: { _eq: "learning_review" }
        }
      }
    ) {
      id
      started_at
      completed_at
    }

    incompleteAssignmentReview: exam_responses(
      where: {
        completed_at: { _is_null: true }
        exam: {
          assignment_id: { _eq: $assignment_id }
          type: { _eq: "assignment_review" }
        }
      }
    ) {
      id
      started_at
      completed_at
    }

    completeAssignmentReview: exam_responses(
      where: {
        completed_at: { _is_null: false }
        exam: {
          assignment_id: { _eq: $assignment_id }
          type: { _eq: "assignment_review" }
        }
      }
    ) {
      id
      started_at
      completed_at
    }
  }
`

export const GET_REVIEW_QUESTIONS_REPORT = gql`
  ${analyticsQuestionFields}
  query get_review_questions_report(
    $assignment_id: uuid!
    $type: String!
    $class_id: uuid!
  ) {
    assignment: assignments_by_pk(id: $assignment_id) {
      id
      name
      rubric
      topic {
        name
      }
    }

    totalStudentCount: assignment_users_aggregate(
      where: { assignment_id: { _eq: $assignment_id } }
    ) {
      aggregate {
        count
      }
    }

    respondentCount: exam_responses_aggregate(
      where: {
        completed_at: { _is_null: false }
        exam: { assignment_id: { _eq: $assignment_id }, type: { _eq: $type } }
      }
    ) {
      aggregate {
        count
      }
    }

    reviewReport: exams(
      where: { assignment_id: { _eq: $assignment_id }, type: { _eq: $type } }
    ) {
      id
      exam_arrangements(order_by: { order: asc }) {
        id
        order
        question {
          ...analyticsQuestionFields
        }
      }
    }
  }
`

export const GET_STUDENT_ANALYTICS_HEADER_DATA = gql`
  ${studentAnalyticsAssignmentFields}
  ${studentAnalyticsMaterialFields}
  query get_student_analytics_header_data($class_id: uuid!) {
    materials(
      where: {
        topic_id: { _is_null: true }
        class_id: { _eq: $class_id }
        archived_at: { _is_null: true }
      }
      order_by: { inserted_at: asc }
    ) {
      ...studentAnalyticsMaterialFields
    }

    assignments(
      where: {
        topic_id: { _is_null: true }
        class_id: { _eq: $class_id }
        archived_at: { _is_null: true }
      }
      order_by: { inserted_at: asc }
    ) {
      ...studentAnalyticsAssignmentFields
    }

    topics(
      where: { class_id: { _eq: $class_id }, archived_at: { _is_null: true } }
      order_by: { inserted_at: asc }
    ) {
      id
      name
      pretopics: assignments(
        where: {
          archived_at: { _is_null: true }
          assignment_users: {}
          type: { _eq: "pretopic" }
        }
        order_by: { inserted_at: asc }
      ) {
        ...studentAnalyticsAssignmentFields
      }

      materials(
        where: { archived_at: { _is_null: true }, material_users: {} }
        order_by: { inserted_at: asc }
      ) {
        ...studentAnalyticsMaterialFields
      }

      assignments(
        where: { archived_at: { _is_null: true }, type: { _eq: "assignment" } }
        order_by: { inserted_at: asc }
      ) {
        ...studentAnalyticsAssignmentFields
      }
    }
  }
`

export const GET_ANALYTICS_STUDENT_DATA = gql`
  ${userDisplayFields}
  ${analyticsSubmissionFields}
  query get_analytics_student_data($class_id: uuid!) {
    users(
      where: {
        class_users: {
          class_id: { _eq: $class_id }
          archived_at: { _is_null: true }
          role: { _eq: "student" }
        }
      }
    ) {
      ...userDisplayFields
      learning_style

      group_users(
        where: {
          group: { class_id: { _eq: $class_id } }
          archived_at: { _is_null: true }
        }
      ) {
        group {
          name
        }
      }

      material_users(where: { material: { class_id: { _eq: $class_id } } }) {
        id
        material_id
        inserted_at
        view_time
      }

      submissions(where: { assignment: { class_id: { _eq: $class_id } } }) {
        ...analyticsSubmissionFields
      }

      assignment_users(
        where: { assignment: { class_id: { _eq: $class_id } } }
      ) {
        id
        user_id
        assignment_id
        viewed_at
      }

      exam_responses(
        where: { exam: { assignment: { class_id: { _eq: $class_id } } } }
      ) {
        id
        exam {
          id
          assignment_id
          type
        }
      }
    }
  }
`

export const GET_ANALYTICS_GROUP_DATA = gql`
  ${analyticsSubmissionFields}
  query get_analytics_group_data($class_id: uuid!) {
    groups(
      where: { class_id: { _eq: $class_id }, archived_at: { _is_null: true } }
    ) {
      id
      name

      material_groups(where: { material: { class_id: { _eq: $class_id } } }) {
        id
        group_id
        material_id
        inserted_at
        view_time
      }

      submissions(where: { assignment: { class_id: { _eq: $class_id } } }) {
        ...analyticsSubmissionFields
      }

      assignment_groups(
        where: { assignment: { class_id: { _eq: $class_id } } }
      ) {
        id
        group_id
        assignment_id
        viewed_at
      }
    }
  }
`

export const GET_NO_TOPIC_ASSIGNMENTS = gql`
  query get_no_topic_assignments($class_id: uuid!) {
    classes_by_pk(id: $class_id) {
      id
      name
      assignments(where: { topic_id: { _is_null: true } }) {
        id
        name
        type
        description
      }
    }
  }
`

export const GET_QUESTION_REPORT = gql`
  ${analyticsQuestionFields}
  query get_question_report($question_id: uuid!, $class_id: uuid!) {
    questions_by_pk(id: $question_id) {
      ...analyticsQuestionFields
      exam_arrangements {
        id
        order
        exam {
          exam_responses_aggregate(
            where: { completed_at: { _is_null: false } }
          ) {
            aggregate {
              count
            }
          }
          assignment {
            id
            name
            class {
              id
              name
            }
            topic {
              id
              name
            }
            assignment_users_aggregate {
              aggregate {
                count
              }
            }
          }
        }
      }
    }
  }
`

export const GET_DOWNLOAD_HEADER_DATA = gql`
  ${studentAnalyticsAssignmentFields}
  ${studentAnalyticsMaterialFields}
  query get_student_analytics_header_data(
    $class_id: uuid!
    $topic_id: uuid
    $with_topic: Boolean!
    $is_topicless: Boolean!
  ) {
    materials(
      where: {
        topic_id: { _is_null: true }
        class_id: { _eq: $class_id }
        archived_at: { _is_null: true }
      }
      order_by: { inserted_at: asc }
    ) @skip(if: $with_topic) {
      ...studentAnalyticsMaterialFields
    }

    assignments(
      where: {
        topic_id: { _is_null: true }
        class_id: { _eq: $class_id }
        archived_at: { _is_null: true }
      }
      order_by: { inserted_at: asc }
    ) @skip(if: $with_topic) {
      ...studentAnalyticsAssignmentFields
    }

    topics(
      where: {
        class_id: { _eq: $class_id }
        archived_at: { _is_null: true }
        id: { _eq: $topic_id }
      }
      order_by: { inserted_at: asc }
    ) @skip(if: $is_topicless) {
      id
      name
      pretopics: assignments(
        where: {
          archived_at: { _is_null: true }
          assignment_users: {}
          type: { _eq: "pretopic" }
        }
        order_by: { inserted_at: asc }
      ) {
        ...studentAnalyticsAssignmentFields
      }

      materials(
        where: { archived_at: { _is_null: true }, material_users: {} }
        order_by: { inserted_at: asc }
      ) {
        ...studentAnalyticsMaterialFields
      }

      assignments(
        where: { archived_at: { _is_null: true }, type: { _eq: "assignment" } }
        order_by: { inserted_at: asc }
      ) {
        ...studentAnalyticsAssignmentFields
      }
    }
  }
`
