import React from 'react'
import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react'
import {
  UPDATE_POST,
  CREATE_ATTACHMENT,
  DELETE_ATTACHMENT,
} from '../../client/queries'
import { useMutation } from '@apollo/client'
import { CreateAnnouncement, CreatePost } from '../../components/Forms/'
import { useSuccessToast, useErrorToast } from '../../utils'
import { Attachment } from '../../types'
import { CreatePostInput, CreateAnnouncementInput } from '../Forms'

export type EditPostModalProps = {
  id: string
  isOpen: boolean
  isPinned: boolean
  content: string
  onClose: () => void
  title?: string
  type: string
  schedule?: any
  size?: string
  cancelButtonText?: string
  attachments: Attachment[]
  isGroup?: boolean
}

export const EditPostModal = ({
  id,
  isOpen,
  onClose,
  content,
  schedule = '',
  isPinned,
  title = '',
  type,
  size = '2xl',
  attachments,
  isGroup = false,
}: EditPostModalProps): JSX.Element => {
  const [updatePost, { loading: postIsSubmitting }] = useMutation(UPDATE_POST)
  const [
    createPostAttachments,
    { loading: attachmentIsSubmitting },
  ] = useMutation(CREATE_ATTACHMENT)
  const [deleteAttachment, { loading: attachmentIsDeleting }] = useMutation(
    DELETE_ATTACHMENT
  )

  const onSubmitAnnouncement = async ({
    content,
    title,
    isPinned: is_pinned,
    postSchedule: schedule,
    files,
    removedFiles,
  }: CreateAnnouncementInput) => {
    try {
      await Promise.all([
        submitPostAttachments(files),
        handleUpdateAnnouncement({ content, is_pinned, title, schedule }),
        handleDeleteAttachments(removedFiles),
      ])

      onClose()
      useSuccessToast({ message: 'Post updated' })
    } catch (error) {
      useErrorToast({ message: 'Post failed to update' })
    }
  }

  const handleUpdateAnnouncement = (changes: {
    content?: string
    is_pinned: boolean
    title: string
    schedule?: string
  }) => {
    try {
      return updatePost({
        variables: {
          id,
          changes,
        },
      })
    } catch (err) {
      return err
    }
  }

  const onSubmitPost = async ({
    content,
    isPinned: is_pinned,
    files,
    removedFiles,
  }: CreatePostInput) => {
    console.log("Ari print: onSubmitPost -> files", files)
    try {
      await Promise.all([
        submitPostAttachments(files),
        handleUpdatePost({ content, is_pinned }),
        handleDeleteAttachments(removedFiles),
      ])
      onClose()
      useSuccessToast({ message: 'Post created' })
    } catch (error) {
      useErrorToast({ message: 'Post failed to update' })
    }
  }

  const handleUpdatePost = (changes: {
    content: string
    is_pinned: boolean
  }) => {
    try {
      return updatePost({
        variables: {
          id,
          changes,
        },
      })
    } catch (err) {
      return err
    }
  }

  const handleDeleteAttachments = (removedFiles: Attachment[]) => {
    try {
      return Promise.all(
        removedFiles?.map(({ id }) =>
          deleteAttachment({
            variables: { id },
          })
        )
      )
    } catch (err) {
      return err
    }
  }

  const submitPostAttachments = (files: File[]) => {
    console.log("Ari print: submitPostAttachments -> files", files)
    try {
      return Promise.all(
        files?.map((file) => {
          return createPostAttachments({
            variables: {
              postId: id,
              file,
            },
          })
        })
      )
    } catch (err) {
      return err
    }
  }

  const onDelete = async (postId: string) => {
    try {
      await updatePost({
        variables: {
          id: postId,
          changes: {
            archived_at: 'NOW()',
          },
        },
      })
      onClose()
      useSuccessToast({ message: 'Post deleted' })
    } catch (error) {
      console.warn(error)
      useErrorToast({ message: 'Post deletion failed' })
    }
  }

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size={size}>
        <ModalOverlay />
        <ModalContent>
          {type === 'announcement' ? (
            <CreateAnnouncement
              id={id}
              onClose={onClose}
              title={title}
              isPinned={isPinned}
              schedule={schedule}
              content={content}
              onSubmit={onSubmitAnnouncement}
              onDelete={onDelete}
              isSubmitting={
                postIsSubmitting ||
                attachmentIsSubmitting ||
                attachmentIsDeleting
              }
              files={attachments}
            />
          ) : (
            <CreatePost
              id={id}
              content={content}
              isPinned={isPinned}
              onClose={onClose}
              onSubmit={onSubmitPost}
              onDelete={onDelete}
              isSubmitting={
                postIsSubmitting ||
                attachmentIsSubmitting ||
                attachmentIsDeleting
              }
              files={attachments}
              isGroup={isGroup}
            />
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
