import { useMutation } from '@apollo/client'
import {
  UPDATE_MATERIAL_USER_VIEW_TIME,
  UPDATE_MATERIAL_GROUP_VIEW_TIME,
} from '../client/queries'
import useInterval from '@use-it/interval'

export type UpdateMaterialUserViewTimeProps = {
  user_id: string
  material_id: string
  delay: number
}

export type UpdateMaterialGroupViewTimeProps = {
  group_id: string
  material_id: string
  delay: number
}

export const updateMaterialUserViewTime = ({
  user_id,
  material_id,
  delay,
}: UpdateMaterialUserViewTimeProps): void => {
  const [updateViewTime] = useMutation(UPDATE_MATERIAL_USER_VIEW_TIME, {
    variables: {
      user_id,
      material_id,
      duration: delay,
    },
  })

  useInterval(() => {
    updateViewTime()
  }, delay)
}

export const updateMaterialGroupViewTime = ({
  group_id,
  material_id,
  delay,
}: UpdateMaterialGroupViewTimeProps): void => {
  const [updateViewTime] = useMutation(UPDATE_MATERIAL_GROUP_VIEW_TIME, {
    variables: {
      group_id,
      material_id,
      duration: delay,
    },
  })

  useInterval(() => {
    updateViewTime()
  }, delay)
}
