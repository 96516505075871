import React from 'react'
import {
  Box,
  Flex,
  Text,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Link,
} from '@chakra-ui/react'
import {
  isLargerThanTablet,
  useErrorToast,
  useSuccessToast,
  useFilename,
} from '../../utils'
import { MdFileDownload } from 'react-icons/md'
import { FaTrash } from 'react-icons/fa'
import { Attachment } from '../../types'
import { useMutation } from '@apollo/client'
import { DELETE_ATTACHMENT } from '../../client/queries'
import { BasicFileIcon } from '../File'
import { More } from '../Icons'
import { hasuraErrorSignature } from '../../utils/toast'

export type UploadedFileProps = {
  attachment: Attachment
  refetch: () => void
}

export const UploadedFile = ({
  attachment,
  refetch,
}: UploadedFileProps): JSX.Element => {
  const [deleteAttachment] = useMutation(DELETE_ATTACHMENT)
  const { filename, extension } = useFilename(attachment.file)

  const handleDownload = () => {
    window.open(attachment.url, '_blank')
  }

  const handleDelete = async () => {
    try {
      await deleteAttachment({ variables: { id: attachment.id } })
      refetch()

      useSuccessToast({ message: 'Document successfully deleted' })
    } catch (error) {
      const hasuraError = error as hasuraErrorSignature

      useErrorToast({ error: hasuraError })
    }
  }

  return (
    <Box
      key={attachment.id}
      css={{
        '&:hover > .attachment-wrapper > .attachment-box > .attachment-menu': {
          visibility: 'visible',
        },
      }}
    >
      <Flex
        justify='space-between'
        align='center'
        className='attachment-wrapper'
      >
        <Link href={attachment.url} isExternal>
          <Flex align='center' cursor='pointer'>
            <Flex
              borderRadius='4px'
              marginRight='12px'
              align='center'
              justify='center'
              p='8px'
            >
              <BasicFileIcon
                extension={extension}
                iconProps={{
                  boxSize: '20px',
                }}
              />
            </Flex>

            <Box>
              <Text
                textStyle='body.semibold'
                color='rgba(0, 0, 0, 0.88)'
                wordBreak='break-word'
              >
                {`${filename}.${extension}`}
              </Text>
            </Box>
          </Flex>
        </Link>

        <Box className='attachment-box'>
          <Box
            className='attachment-menu'
            visibility={isLargerThanTablet() ? 'hidden' : 'visible'}
          >
            <Menu closeOnSelect>
              <MenuButton
                as={IconButton}
                aria-label='Document actions'
                icon={<More width='22' height='22' viewBox='8 8 24 24' />}
                variant='icon.button'
              />
              <MenuList>
                <MenuItem icon={<MdFileDownload />} onClick={handleDownload}>
                  Download
                </MenuItem>
                <MenuDivider />
                <MenuItem icon={<FaTrash />} onClick={handleDelete}>
                  Remove
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </Box>
      </Flex>
    </Box>
  )
}

export default UploadedFile
