import React from 'react'
import { ExamArrangement } from '../../types'
import { BiChevronUp, BiChevronDown } from 'react-icons/bi'
import {
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  HStack,
  Spacer,
  Text,
} from '@chakra-ui/react'
import { useExamFormContext } from '../../context'
import { MultipleChoice, TextIcon } from '../Icons'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FaTrash } from 'react-icons/fa'
import { RiPencilFill } from 'react-icons/ri'
import { IoMdCheckboxOutline } from 'react-icons/io'

export type QuestionProps = {
  examArrangement: ExamArrangement
  changeModalHeaderToSelected: () => void
}

export const Question = ({
  examArrangement,
  changeModalHeaderToSelected,
}: QuestionProps): JSX.Element => {
  const { order, question, id = '' } = examArrangement
  const {
    exam_arrangements = [],
    setSelectedExamArrangement,
    moveQuestionUp,
    moveQuestionDown,
    removeQuestion,
    setRemoveOnCancel,
  } = useExamFormContext()

  const getQuestionIcon = (type: string) => {
    switch (type) {
      case 'text':
        return (
          <Flex mr='12px' ml='4px'>
            <Icon as={TextIcon} fontSize='xl' color='primary.1' />
          </Flex>
        )
      case 'multiple_choice':
        return (
          <Flex mr='8px'>
            <Icon as={MultipleChoice} fontSize='xl' color='primary.1' />
          </Flex>
        )
      case 'checkbox':
        return (
          <Flex mr='8px'>
            <Icon as={IoMdCheckboxOutline} fontSize='xl' color='primary.1' />
          </Flex>
        )
      default:
        return (
          <Flex mr='12px' ml='4px'>
            <Icon as={TextIcon} fontSize='xl' color='primary.1' />
          </Flex>
        )
    }
  }

  const getQuestionText = (type: string) => {
    switch (type) {
      case 'text':
        return 'Text Question'
      case 'multiple_choice':
        return 'Multiple Choice Question'
      case 'checkbox':
        return 'Checkbox'
      default:
        return 'Unknown type'
    }
  }

  const handleEditQuestion = () => {
    setRemoveOnCancel(false)
    setSelectedExamArrangement(examArrangement)
    changeModalHeaderToSelected()
  }

  return (
    <Flex p='8px 16px 8px 8px'>
      <Flex direction='column'>
        <IconButton
          aria-label='move question up'
          variant='icon.button'
          icon={<BiChevronUp />}
          disabled={order === 0}
          w='32px'
          h='32px'
          onClick={() => moveQuestionUp({ order })}
        />

        <IconButton
          aria-label='move question down'
          variant='icon.button'
          icon={<BiChevronDown />}
          disabled={order === exam_arrangements.length - 1}
          w='32px'
          h='32px'
          onClick={() => moveQuestionDown({ order })}
        />
      </Flex>

      <Flex
        borderRadius='8px'
        p='8px 16px 8px 8px'
        border='.5px solid'
        borderColor='primary.2'
        w='100%'
        align='center'
        position='relative'
      >
        <Flex p='12px'>{getQuestionIcon(question.type)}</Flex>

        <Flex
          direction='column'
          justify='space-between'
          onClick={handleEditQuestion}
          cursor='pointer'
        >
          <Text textStyle='overline' color='primary.2'>
            {getQuestionText(question.type)}
          </Text>

          <Text textStyle='body.1' minH='21px'>
            {question.text}
          </Text>
        </Flex>
        <Spacer />
        <Flex align='end'>
          <HStack spacing='8px'>
            <IconButton
              aria-label='Edit Question'
              icon={<Icon as={RiPencilFill} boxSize='14px' />}
              onClick={handleEditQuestion}
              variant='icon.button.question'
            />
            <IconButton
              aria-label='Delete Question'
              icon={<Icon as={FaTrash} boxSize='14px' />}
              onClick={() => removeQuestion({ order, examArrangementId: id })}
              variant='icon.button.question'
            />
          </HStack>
        </Flex>
      </Flex>
    </Flex>
  )
}
