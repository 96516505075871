import { User, ClassUser } from '../types'

export const getFullName = (user?: {
  firstName?: string
  lastName?: string
}): string => {
  if (!user) return ''
  const firstName = user.firstName
  const lastName = user.lastName

  if (firstName && !lastName) {
    return firstName
  } else if (lastName && !firstName) {
    return lastName
  } else if (firstName && lastName) {
    return `${firstName} ${lastName}`
  } else {
    return ''
  }
}

export const getFirstAndLastInitial = (user?: {
  firstName?: string
  lastName?: string
}) => {
  if (!user) return ''
  const firstName = user.firstName
  const lastName = user.lastName

  if (firstName && !lastName) {
    return firstName[0]
  } else if (lastName && !firstName) {
    return lastName[0]
  } else if (firstName && lastName) {
    return `${firstName[0]} ${lastName[0]}`
  } else {
    return ''
  }
}

export const getUserRole = (user: User): string => {
  const classUsers = user?.classUsers || []
  if (classUsers.length >= 1) {
    return getClassUserRole(classUsers[0])
  }

  return ''
}

export const getClassUserRole = (classUser: ClassUser): string => {
  if (classUser?.role === 'creator') return 'admin'
  if (classUser?.role === 'teacher') return 'instructor'

  return classUser?.role
}

export const displayRole = (role: string): string => {
  if (role === 'creator') return 'admin'
  if (role === 'teacher') return 'instructor'

  return role
}

export const capitalize = (name: string | undefined): string => {
  if (!name) return ''
  return name[0].toUpperCase() + name.substr(1)
}
