import React from 'react'
import { Container, Flex } from '@chakra-ui/react'

const NotFound = (): JSX.Element => {
  return (
    <Container w='100%' minH='100vh'>
      <Flex
        w='100%'
        minH='100vh'
        align='center'
        justify='center'
        textStyle='h1'
        color='primary.1'
      >
        Page Not Found
      </Flex>
    </Container>
  )
}

export default NotFound
