import React from 'react'
import { Message as MessageType } from '../../types'
import { Flex, Text } from '@chakra-ui/react'
import { Message } from './index'

export type MessagesProps = {
  messages?: MessageType[]
}

export const Messages = ({ messages = [] }: MessagesProps): JSX.Element => {
  if (messages.length === 0) {
    return (
      <Flex
        h='emptyChatroomHeight'
        direction='column'
        justify='center'
        align='center'
      >
        <Text textStyle='body.big' color='primary.2'>
          No messages
        </Text>
      </Flex>
    )
  }

  return (
    <Flex h='100%' direction='column'>
      {messages?.map((message) => (
        <React.Fragment key={message.id}>
          <Message message={message} />
        </React.Fragment>
      ))}
    </Flex>
  )
}

export default Messages
