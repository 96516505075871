import {
  Box,
  Button,
  IconButton,
  Icon,
  Flex,
  Menu,
  MenuButton,
  Text,
  HStack,
  Container,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from '@chakra-ui/react'
import { AlamAvatar } from '../../components/Avatar'
import { MdRemoveRedEye } from 'react-icons/md'
import { IoEarOutline } from 'react-icons/io5'
import { Read, Kinesthetic } from '../../components/Icons'
import { NotEnoughData } from '../../components/Analytics'
import {
  constructTableHeadings,
  StudentAnalyticsTableHeading,
  getPreferenceIcon,
  displayPreference,
  renderStudentCellContent,
  AnalyticsUserData,
  usePagination,
  sortStudentDataAZ,
} from '../../utils'

export interface IndividualAnalyticsTable {
  students: AnalyticsUserData[]
  headings: StudentAnalyticsTableHeading[]
}

export const IndividualAnalyticsTable = ({
  students,
  headings,
}: IndividualAnalyticsTable): JSX.Element => {
  const {
    page,
    setPage,
    totalPages,
    currentPageContent,
    totalItems,
    renderAnalyticsPagination,
  } = usePagination(sortStudentDataAZ(students))

  const tableStyle = {
    borderWidth: '0.5px',
    borderStyle: 'solid',
    borderColor: 'primary.2',
  }

  return (
    <>
      <Table display='inline-block' bg='white.1' overflow='auto'>
        <Thead>
          <Th {...tableStyle}>
            <Text
              fontWeight='700'
              fontSize='18px'
              lineHeight='23.23px'
              letterSpacing='-0.01em'
              color='black.1'
              textTransform='none'
            >
              Students
            </Text>
          </Th>
          <Th {...tableStyle}>
            <Text
              fontWeight='700'
              fontSize='12px'
              lineHeight='16px'
              letterSpacing='-0.01em'
              color='black.6'
              textTransform='none'
            >
              Learning Preference
            </Text>
          </Th>
          {headings.map((tableHeading) => {
            const { topic, text, subtext } = tableHeading
            return (
              <Th {...tableStyle}>
                <Flex direction='column' maxW='400px' minW='128px'>
                  {topic && (
                    <Text
                      maxW='400px'
                      minW='128px'
                      isTruncated
                      fontWeight='600'
                      fontSize='8px'
                      lineHeight='15px'
                      letterSpacing='0.01em'
                      color='rgba(206, 16, 50, 1)'
                      textTransform='none'
                    >
                      {topic.name}
                    </Text>
                  )}
                  <Text
                    maxW='400px'
                    minW='128px'
                    isTruncated
                    fontWeight='600'
                    fontSize='12px'
                    lineHeight='16px'
                    letterSpacing='-0.01em'
                    color='black.6'
                    textTransform='none'
                  >
                    {text}
                  </Text>
                  {subtext && (
                    <Text
                      maxW='400px'
                      minW='128px'
                      isTruncated
                      fontWeight='400'
                      fontSize='8px'
                      lineHeight='15px'
                      letterSpacing='0.01em'
                      color='black.3'
                      textTransform='none'
                    >
                      {subtext}
                    </Text>
                  )}
                </Flex>
              </Th>
            )
          })}
        </Thead>
        <Tbody>
          {currentPageContent.map((student: AnalyticsUserData) => {
            return (
              <Tr>
                <Td {...tableStyle}>
                  <Flex align='center' w='258px'>
                    <AlamAvatar size='lg' mr={2} src={student.avatar} />
                    <Box>
                      <Text
                        textStyle='smallstate'
                        textTransform='uppercase'
                        letterSpacing='0.02em'
                        mb={1}
                        fontSize='14px'
                        color='primary.1'
                      >
                        {student.firstName} {student.lastName}
                      </Text>
                      <Box>
                        <HStack
                          spacing={2}
                          divider={<Box layerStyle='bulletpoint' />}
                        >
                          <Text
                            textStyle='subtitle'
                            color='grey.1'
                            fontSize='12px'
                          >
                            Student
                          </Text>
                          {student.group_users[0] && (
                            <Text
                              textStyle='subtitle'
                              color='grey.1'
                              fontSize='12px'
                            >
                              {student?.group_users[0]?.group?.name}
                            </Text>
                          )}
                        </HStack>
                      </Box>
                    </Box>
                  </Flex>
                </Td>
                <Td {...tableStyle}>
                  <Flex direction='column' w='112px'>
                    {(student?.learning_style || []).map(
                      (preference: string) => {
                        return (
                          <Flex align='center' alignContent='space-between'>
                            <Icon
                              as={getPreferenceIcon(preference)}
                              mr='15.2px'
                            />
                            <Text
                              textStyle='analyticsTableCell'
                              textTransform='capitalize'
                              mr='16px'
                              align='center'
                              color='black.1'
                            >
                              {displayPreference(preference)}
                            </Text>
                          </Flex>
                        )
                      }
                    )}
                  </Flex>
                </Td>
                {headings.map((tableHeading) => {
                  return (
                    <Td {...tableStyle}>
                      {renderStudentCellContent(student, tableHeading)}
                    </Td>
                  )
                })}
              </Tr>
            )
          })}
        </Tbody>
        {totalItems === 0 && (
          <NotEnoughData
            containerProps={{
              h: '124px',
              w: '100%',
              bg: 'white.1',
            }}
            display='No data'
          />
        )}
      </Table>
      {renderAnalyticsPagination()}
    </>
  )
}
