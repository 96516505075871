import { useState, useEffect } from 'react'
import {
  Flex,
  Container,
  Menu,
  MenuButton,
  Button,
  HStack,
  Text,
  Link,
  Icon,
  Box,
} from '@chakra-ui/react'
import { NavLink as RouterLink } from 'react-router-dom'
import { format } from 'date-fns'
import { IoChevronDown } from 'react-icons/io5'
import { FaClipboardList } from 'react-icons/fa'
import { useAlamContext } from '../../client'
import { useSubscription } from '@apollo/client'
import { GET_INDIVIDUAL_USER_SUBMISSIONS } from '../../client/queries'
import { Grade, Topic, EventLog } from '../../types'
import { gradesSortDisplay } from '../../utils'
import { Options } from './TeacherClassGrades'
import { DropdownCheckbox, Select } from '../../components/Input'
import TopicSubmissionList from './TopicSubmissionList'

type TopicType = {
  id: string
  name: string
  insertedAt: string
}

interface EventLogType extends Pick<EventLog, 'id' | 'content'> {
  insertedAt: string
}

type CommentType = {
  id: string
}

export type SubmissionType = {
  id: string
  grades: Grade[]
  status: string
  assignment: {
    id: string
    name: string
    dueDateStr: string
    topic?: TopicType
    rubric: string
    grading: string
    classId: string
  }
  eventLogs: EventLogType[]
  comments: CommentType[]
  user?: {
    id: string
  }
  group?: {
    id: string
  }
  insertedAt: string
}

type StudentClassGradesProps = {
  topics: Topic[]
}

export const StudentClassGrades = ({
  topics,
}: StudentClassGradesProps): JSX.Element => {
  const [checkedTopics, setCheckedTopics] = useState<string[]>([])
  const [topicFilter, setTopicFilter] = useState<Options[]>([])
  const [sortBy, setSortBy] = useState('a-z')
  const [assignmentTypeFilter, setAssignmentTypeFilter] = useState<Options[]>(
    []
  )
  const [checkedAssignmentType, setCheckedAssignmentType] = useState<string[]>([
    'group',
    'user',
  ])

  const { user, classUser } = useAlamContext()

  const { data: submissionsData } = useSubscription(
    GET_INDIVIDUAL_USER_SUBMISSIONS,
    {
      variables: {
        class_id: classUser.class.id,
        user_id: user.id,
      },
    }
  )

  const { submissions } = submissionsData || { submissions: [] }

  const filterAssignmentType = (
    submissions: SubmissionType[],
    checkedAssignmentType: string[]
  ): SubmissionType[] => {
    return submissions.filter((submission) => {
      if (submission.group) {
        return checkedAssignmentType.includes('group')
      }
      if (submission.user) {
        return checkedAssignmentType.includes('user')
      }
    })
  }

  const getTopicSubmissions = (
    submissions: SubmissionType[],
    topic: Topic | null
  ): SubmissionType[] => {
    if (topic) {
      return submissions.filter((submission: SubmissionType) => {
        return submission.assignment.topic?.id === topic.id
      })
    }
    return submissions.filter((submission: SubmissionType) => {
      return submission.assignment.topic === null
    })
  }

  const topicsWithNull = [null, ...topics]

  const sortFunction = (
    submissions: SubmissionType[],
    sortBy: string
  ): SubmissionType[] => {
    const alphabeticalSubmissions = [...submissions].sort(
      (submission1, submission2) => {
        if (
          submission1.assignment.name.toLowerCase() <
          submission2.assignment.name.toLowerCase()
        )
          return -1
        if (
          submission1.assignment.name.toLowerCase() >
          submission2.assignment.name.toLowerCase()
        )
          return 1
        return 0
      }
    )

    if (sortBy == 'a-z') return alphabeticalSubmissions
    if (sortBy == 'z-a') return alphabeticalSubmissions.reverse()
    return alphabeticalSubmissions
  }

  const sortOptions = [
    { value: 'a-z', display: 'Alphabetical A-Z' },
    { value: 'z-a', display: 'Alphabetical Z-A' },
  ]

  useEffect(() => {
    const topicsWithNull = [null, ...topics]
    const topicOptions = topicsWithNull.map((topic) => {
      if (topic) {
        return {
          display: topic.name,
          value: topic.id,
        }
      }
      return {
        display: 'No Topic',
        value: 'null',
      }
    })

    const topicIdArray = topics.map(({ id }) => id)
    const initialTopics = ['null', ...topicIdArray]

    setTopicFilter(topicOptions)
    setCheckedTopics(initialTopics)
  }, [topics])

  const applyTopicFilter = (topics: (Topic | null)[], checkedIds: string[]) => {
    return topics.filter((topic) => {
      if (topic === null) {
        return checkedIds.includes('null')
      }
      return checkedIds.includes(topic.id)
    })
  }

  const assignmentTypeOptions = [
    { value: 'user', display: 'Individual' },
    { value: 'group', display: 'Group' },
  ]

  const assignmentsHeading = [
    {
      id: '1',
      name: 'Score',
    },
    {
      id: '2',
      name: 'Status',
    },
    {
      id: '3',
      name: 'Date Submitted',
    },
    {
      id: '4',
      name: 'Group',
    },
    {
      id: '5',
      name: 'Instructor Feedback',
    },
  ]

  return (
    <>
      <Container maxW='100%' py={4}>
        <Flex align='center' justify='space-between'>
          <Flex>
            <HStack spacing={4}>
              <DropdownCheckbox
                isTopicDropdown
                onChange={setCheckedTopics}
                allTitle='All Topics'
                emptyTitle='None'
                indeterminateTitle='Topics'
                options={topicFilter}
                values={checkedTopics}
                {...{
                  menuButtonProps: {
                    w: 'auto',
                    height: '48px',
                    textAlign: 'left',
                    textStyle: 'button',
                    color: 'primary.1',
                    variant: 'tab.selector',
                    layerStyle: 'card.module',
                  },
                }}
              />

              <Select
                value={sortBy}
                onChange={(value) => setSortBy(value)}
                options={sortOptions}
                menuButtonProps={{
                  w: '198px',
                  textAlign: 'left',
                  textStyle: 'button',
                  color: 'primary.1',
                  variant: 'tab.selector',
                  height: '48px',
                  layerStyle: 'card.module',
                }}
                menuItemProps={{
                  textStyle: 'body.1',
                  color: 'primary.1',
                  w: '196px',
                  _hover: { bg: 'hover.2', color: 'white.1' },
                }}
                displayTransform={gradesSortDisplay}
              />

              <DropdownCheckbox
                onChange={setCheckedAssignmentType}
                allTitle='All'
                emptyTitle='None'
                indeterminateTitle='All'
                options={assignmentTypeOptions}
                values={checkedAssignmentType}
                {...{
                  menuButtonProps: {
                    w: 'auto',
                    textAlign: 'left',
                    textStyle: 'button',
                    color: 'primary.1',
                    variant: 'tab.selector',
                    height: '48px',
                    layerStyle: 'card.module',
                  },
                }}
              />
            </HStack>
          </Flex>
        </Flex>
      </Container>
      <Container maxW='100%' p='0'>
        <Flex
          bg='white.1'
          w='100%'
          css={{
            '& > .table-heading:last-child': {
              flexGrow: 1,
            },
          }}
        >
          <Flex
            w='258px'
            height='64px'
            borderWidth='0.5px'
            borderStyle='solid'
            borderColor='primary.2'
            align='center'
            className='table-heading'
          >
            <Text textStyle='h4' color='black.1' px={4}>
              Assignments
            </Text>
          </Flex>
          {assignmentsHeading.map(
            (assignmentHeading: { id: string; name: string }) => (
              <Flex
                w='112px'
                height='64px'
                borderTopWidth='0.5px'
                borderRightWidth='0.5px'
                borderBottomWidth='0.5px'
                borderStyle='solid'
                borderColor='primary.2'
                align='center'
                key={assignmentHeading.id}
                className='table-heading'
              >
                <Text
                  textStyle='smallstate'
                  fontWeight='700'
                  color='black.1'
                  px={4}
                >
                  {assignmentHeading.name}
                </Text>
              </Flex>
            )
          )}
        </Flex>
        {applyTopicFilter(topicsWithNull, checkedTopics).map((topic, index) => {
          const topicSubmissions = getTopicSubmissions(submissions, topic)
          const assignmentTypeSubmissions = filterAssignmentType(
            topicSubmissions,
            checkedAssignmentType
          )
          const sortedSubmissions = sortFunction(
            assignmentTypeSubmissions,
            sortBy
          )

          return (
            <TopicSubmissionList
              key={topic ? topic.id : index}
              topic={topic}
              submissions={sortedSubmissions}
            />
          )
        })}
      </Container>
    </>
  )
}

export default StudentClassGrades
