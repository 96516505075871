import React from 'react'
import { useErrorToast, useExamForm, useSuccessToast } from '../../utils'
import { ExamForm } from './index'
import { ExamFormContext } from '../../context'
import { ModalState } from '../Assignment'
import { useMutation } from '@apollo/client'
import { UPSERT_EXAM } from '../../client/queries'
import { Exam } from '../../types'

export type LearningReviewFormProps = {
  assignmentId: string
  setModalState: React.Dispatch<ModalState>
  display: string
  onSubmit?: () => void
  learningReview?: Exam
  submitText?: string
}

export const LearningReviewForm = ({
  assignmentId,
  setModalState,
  display,
  onSubmit = () => {},
  learningReview,
  submitText = 'Finish',
}: LearningReviewFormProps): JSX.Element => {
  const {
    buildExam,
    removedExamArrangements,
    removedResponseChoiceArrangement,
    ...contextState
  } = useExamForm(
    {
      type: 'learning_review',
      assignmentId: assignmentId,
    },
    learningReview
  )
  const type = learningReview?.id ? 'edit' : 'create'

  const [upsertExam, { loading }] = useMutation(UPSERT_EXAM)

  const handleSubmit = async () => {
    const exam = buildExam()
    try {
      await upsertExam({
        variables: {
          exam,
          exam_arrangement_ids: removedExamArrangements,
          response_choice_arrangement_ids: removedResponseChoiceArrangement,
        },
      })

      useSuccessToast({
        message: `Learning Review was sucessfully ${
          type === 'create' ? 'created' : 'updated'
        }`,
      })
      onSubmit()
    } catch (error) {
      useErrorToast({ error })
    }
  }

  return (
    <ExamFormContext.Provider value={{ ...contextState }}>
      <ExamForm
        placeholderText="Gain valuable feedback on students' experience with the assignment. The feedback questions you post will show up after the assignment has been graded and returned"
        setModalState={setModalState}
        display={display}
        handleSubmit={handleSubmit}
        isSubmitting={loading}
        submitText={submitText}
      />
    </ExamFormContext.Provider>
  )
}

export default LearningReviewForm
