import { Fragment, useState, useMemo, useEffect } from 'react'
import {
  Container,
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  Text,
  HStack,
  IconButton,
} from '@chakra-ui/react'
import {
  GroupWithGradesData,
  usePagination,
  renderSubmissionDate,
  sortGroupData,
} from '../../utils'
import { AlamAvatar } from '../../components/Avatar'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import { Group } from '../../components/Icons'
import { NotEnoughData } from './NotEnoughData'

export interface GroupScoreAnalyticsTableProps {
  groups: GroupWithGradesData[]
  sortBy: string
}

export const GroupScoreAnalyticsTable = ({
  groups,
  sortBy,
}: GroupScoreAnalyticsTableProps) => {
  const sortedGroups = useMemo(() => {
    return sortGroupData(groups, sortBy)
  }, [groups, sortBy])

  const {
    page,
    setPage,
    totalPages,
    currentPageContent,
    totalItems,
    renderAnalyticsPagination,
  } = usePagination(sortedGroups)

  useEffect(() => {
    setPage(1)
  }, [sortBy])

  const isEmpty = sortedGroups.length === 0

  return (
    <>
      <Box
        mx={6}
        my={4}
        css={{
          '& > :last-child': {
            borderRadius: '0px 0px 8px 8px',
          },
          '& > :first-child': {
            borderRadius: '8px 8px 0px 0px',
          },
        }}
      >
        <Flex
          w='100%'
          bg='white.1'
          borderWidth='0.5px'
          borderColor='primary.2'
          css={{
            '& > :nth-child(2)': {
              borderLeftWidth: '0.5px',
              borderRightWidth: '0.5px',
              borderColor: 'rgba(23, 5, 61, 0.32)',
            },
          }}
        >
          <Box py={3} px={6} w='40%'>
            <Text
              textStyle='smallstate'
              textTransform='uppercase'
              color='primary.2'
              letterSpacing='0.02em'
            >
              groups
            </Text>
          </Box>
          <Box py={3} px={6} w='30%'>
            <Text
              textStyle='smallstate'
              textTransform='uppercase'
              color='primary.2'
              letterSpacing='0.02em'
            >
              submission date
            </Text>
          </Box>
          <Box py={3} px={6} w='30%'>
            <Text
              textStyle='smallstate'
              textTransform='uppercase'
              color='primary.2'
              letterSpacing='0.02em'
            >
              score
            </Text>
          </Box>
        </Flex>

        {isEmpty && (
          <NotEnoughData
            containerProps={{
              w: '100%',
              bg: 'white.1',
              borderColor: 'primary.2',
              borderWidth: '0.5px',
              borderTopWidth: '0px',
              justify: 'center',
              align: 'center',
              h: '416px',
              css: {
                '& > :nth-child(2)': {
                  borderLeftWidth: '0.5px',
                  borderRightWidth: '0.5px',
                  borderColor: 'rgba(23, 5, 61, 0.32)',
                },
              },
            }}
          />
        )}
        {!isEmpty &&
          currentPageContent?.map((group: GroupWithGradesData) => (
            <Flex
              w='100%'
              bg='white.1'
              key={group.id}
              borderColor='primary.2'
              borderWidth='0.5px'
              borderTopWidth='0px'
              css={{
                '& > :nth-child(2)': {
                  borderLeftWidth: '0.5px',
                  borderRightWidth: '0.5px',
                  borderColor: 'rgba(23, 5, 61, 0.32)',
                },
              }}
            >
              <Box py={3} px={6} w='40%'>
                <Flex align='center'>
                  <Group />
                  <Box>
                    <Text
                      textStyle='smallstate'
                      textTransform='uppercase'
                      letterSpacing='0.02em'
                      mb={1}
                      fontSize='14px'
                      color='primary.1'
                    >
                      {group.name}
                    </Text>
                  </Box>
                </Flex>
              </Box>
              <Flex py={3} px={6} w='30%' align='center'>
                <Text
                  textStyle='smallstate'
                  textTransform='uppercase'
                  letterSpacing='0.02em'
                >
                  {renderSubmissionDate(group.submissionDate)}
                </Text>
              </Flex>
              <Flex py={3} px={6} w='30%' flex='1' align='center'>
                <Text
                  textStyle='smallstate'
                  textTransform='uppercase'
                  letterSpacing='0.02em'
                >
                  {group.grade || 'N/A'}
                </Text>
              </Flex>
            </Flex>
          ))}
      </Box>
      {renderAnalyticsPagination()}
    </>
  )
}
