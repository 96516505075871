import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Button } from '@chakra-ui/react'

export type FormButtonProps = {
  text: string
  buttonProps?: Record<string, any>
  submittingText: string
  isSubmitting: boolean
}

export const FormButton = ({
  text,
  buttonProps = {},
  submittingText,
  isSubmitting,
}: FormButtonProps) => {
  const {
    formState: { isValid },
  } = useFormContext()

  return (
    <Button
      type='submit'
      disabled={!isValid}
      loadingText={submittingText}
      isLoading={isSubmitting}
      {...buttonProps}
    >
      {text}
    </Button>
  )
}

export default FormButton
