import { useState, useEffect, useMemo } from 'react'
import { useDisclosure } from '@chakra-ui/react'

export interface DropdownOption {
  display: string
  value: string
  isDisabled: boolean
  subtext?: string
}

type useDropdownCheckbox = {
  options: DropdownOption[]
  initialValues: Array<string>
  allTitle: string
  emptyTitle: string
  indeterminateTitle: string
  isCollapsable?: boolean
  collapseTitle?: string
}

export const useDropdownCheckbox = ({
  initialValues,
  options,
  allTitle,
  emptyTitle,
  indeterminateTitle,
  isCollapsable = false,
  collapseTitle = '',
}: useDropdownCheckbox) => {
  const [values, setValues] = useState(initialValues)

  const availableOptions = useMemo(() => {
    return options.filter((option) => !option.isDisabled)
  }, [options])

  const disabledValues = useMemo(() => {
    return options
      .filter(({ isDisabled }) => isDisabled)
      .map(({ value }) => value)
  }, [options])

  useEffect(() => {
    setValues(initialValues)
  }, [initialValues])

  const filteredValues = useMemo(() => {
    return values.filter((value) => {
      return !disabledValues.includes(value)
    })
  }, [values, disabledValues])

  const {
    isOpen: collapseIsOpen,
    onToggle: handleCollapseToggle,
  } = useDisclosure({ defaultIsOpen: true })

  const isEverythingChecked = availableOptions.every((option) =>
    values.includes(option.value)
  )

  const toggleEverything = () => {
    const newValues = isEverythingChecked
      ? []
      : availableOptions.map(({ value }) => value)

    setValues(newValues)
  }

  const handleChange = (checked: boolean, item: string) => {
    if (checked) {
      setValues([...values, item])
    } else {
      const newValues = values.filter((val) => val !== item)
      setValues(newValues)
    }
  }

  const isChecked = (item: string) =>
    filteredValues.includes(item) || disabledValues.includes(item)

  const getSelectedDisplay = () => {
    const selectedCount = filteredValues.length

    if (selectedCount === 0) {
      return { title: emptyTitle, isEmpty: true, isIndeterminate: false }
    }

    if (isEverythingChecked) {
      return { title: allTitle, isEmpty: false, isIndeterminate: false }
    }

    if (selectedCount === 1) {
      const selected = options.find(({ value }) => filteredValues[0] === value)
      return { title: selected?.display, isEmpty: false, isIndeterminate: true }
    }

    return {
      title: `${selectedCount} ${indeterminateTitle}`,
      isEmpty: false,
      isIndeterminate: true,
    }
  }

  const wrapperDisplay = getSelectedDisplay()

  const resetValues = () => {
    setValues(initialValues)
  }

  return {
    dropdownWrapperProps: {
      isEverythingChecked,
      values: filteredValues,
      options,
      ...wrapperDisplay,
    },
    dropdownItemProps: {
      options,
      isEverythingChecked,
      toggleEverything,
      allTitle,
      isChecked,
      handleChange,
      collapseTitle,
      collapseIsOpen,
      isCollapsable,
      handleCollapseToggle,
    },
    values: filteredValues,
    resetValues,
  }
}
