import { useState, useEffect, useMemo } from 'react'
import {
  Button,
  VStack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  FormErrorIcon,
  StackDivider,
  Textarea,
  SimpleGrid,
  Flex,
  Spacer,
  Switch,
  Text,
  MenuDivider,
  Checkbox,
} from '@chakra-ui/react'
import { MultiDropdown, MultiDropdownItem, Select } from '../Input'
import { useForm } from 'react-hook-form'
import {
  Topic,
  AssignmentUser,
  Attachment,
  Group,
  AssignmentGroup,
  ClassUser,
  Exam,
} from '../../types'
import debounce from 'lodash-es/debounce'
import {
  truncate,
  validateSpaceOnly,
  useAttachment,
  useDatePicker,
  useFormState,
  useDropdownCheckbox,
  getFullName,
  utcToLocale,
  constructStudentOption,
} from '../../utils'
import { EditableDatePicker } from './index'

export interface AssignmentInput {
  name: string
  description?: string
  schedule: string
  dueDateStr?: string
  isVisible?: boolean
  type?: string
  rubric: string
  grading: string
  topicId: string
  assignmentUsers: string[]
  classId: string
  files: File[]
  removedFiles: Attachment[]
  assignmentGroups: string[]
  withAssignmentReview: boolean
  withLearningReview: boolean
  addNewUsers?: boolean
  addNewGroups?: boolean
}

export interface Assignment
  extends Omit<
    AssignmentInput,
    | 'assignmentUsers'
    | 'classId'
    | 'files'
    | 'removedFiles'
    | 'assignmentGroups'
    | 'withAssignmentReview'
    | 'withLearningReview'
  > {
  assignmentUsers?: AssignmentUser[]
  assignmentGroups?: AssignmentGroup[]
  attachments?: Attachment[]
  exams: Exam[]
}

export interface AssignmentFormProps {
  assignment?: Assignment
  submitText: string
  submittingText: string
  isSubmitting: boolean
  onSubmit: (data: AssignmentInput) => Promise<void>
  topicsOption: Topic[]
  students: ClassUser[]
  classId: string
  groups: Group[]
  isCreate?: boolean
}

export const AssignmentForm = ({
  submitText,
  submittingText,
  isSubmitting,
  onSubmit,
  topicsOption,
  students,
  classId,
  groups,
  assignment = {
    name: '',
    description: '',
    isVisible: true,
    rubric: 'numerical',
    grading: '100',
    schedule: '',
    topicId: '',
    dueDateStr: undefined,
    assignmentUsers: undefined,
    attachments: [],
    assignmentGroups: undefined,
    addNewUsers: true,
    addNewGroups: true,
    type: 'assignment',
    exams: [],
  },
  isCreate = false,
}: AssignmentFormProps): JSX.Element => {
  const assignmentReview = assignment.exams.find(
    ({ type }) => type === 'assignment_review'
  )
  const learningReview = assignment.exams.find(
    ({ type }) => type === 'learning_review'
  )
  const [isVisible, setIsVisible] = useState(assignment.isVisible)
  const [addNewUsers, setAddNewUsers] = useState(assignment.addNewUsers!)
  const [addNewGroups, setAddNewGroups] = useState(assignment.addNewGroups!)
  const [reviewState, setReviewState] = useState({
    assignmentReview: !!assignmentReview,
    learningReview: !!learningReview,
  })
  const {
    register,
    handleSubmit,
    errors,
    trigger,
    watch,
    formState: { isValid },
  } = useForm<AssignmentInput>({
    mode: 'all',
    defaultValues: {
      name: assignment.name,
      description: assignment.description,
    },
  })

  const name = watch('name')

  const {
    removedAttachments,
    newAttachments,
    renderFileList,
    renderAttachButton,
    resetAttachments,
    renderAttachmentError,
  } = useAttachment(assignment?.attachments || [])

  const {
    date: schedule,
    handleChangeDate: handleChangeSchedule,
    resetDate: resetSchedule,
  } = useDatePicker(assignment.schedule)

  const [dueDate, setDueDate] = useState<Date | undefined>(
    assignment.dueDateStr ? utcToLocale(assignment.dueDateStr) : undefined
  )
  const {
    formState: { rubric, grading, topicId },
    handleInputChange,
    resetFormState,
  } = useFormState({
    rubric: assignment.rubric,
    grading: assignment.grading,
    topicId: assignment.topicId,
  })

  useEffect(() => {
    if (rubric === 'alphabetical') {
      handleInputChange('grading', '')
    } else {
      handleInputChange('grading', assignment.grading || '100')
    }
  }, [rubric])

  const rubricOptions = [
    { value: 'numerical', display: 'Numerical' },
    { value: 'alphabetical', display: 'Alphabetical' },
  ]
  const topics = topicsOption.map(({ name, id }) => {
    return { value: id, display: truncate(name, 24) }
  })

  const {
    values: assignmentGroups,
    dropdownItemProps: groupsDropdownItem,
    dropdownWrapperProps: groupsDropdownWrapper,
    resetValues: resetAssignmentGroups,
  } = useDropdownCheckbox({
    allTitle: 'All Groups',
    emptyTitle: 'No Groups',
    indeterminateTitle: 'groups',
    isCollapsable: true,
    collapseTitle: 'GROUPS',
    options: groups.map(({ id, name }) => ({
      display: name,
      value: id,
      isDisabled: false,
      subtext: '',
    })),
    initialValues: useMemo(() => {
      // edit
      if (Array.isArray(assignment?.assignmentGroups)) {
        return assignment?.assignmentGroups.map(({ group_id }) => group_id)
      }

      return groups.map(({ id }) => id)
    }, [groups]),
  })

  const studentOptions = useMemo(() => {
    const assignedGroups =
      groups?.filter((group) => {
        const selectedGroups: string[] = assignmentGroups
        return selectedGroups.includes(group.id)
      }) || []

    const options = students.map((student) => {
      return constructStudentOption(student, assignedGroups)
    })

    return options
  }, [assignmentGroups, groups, students])

  const {
    values: assignmentUsers,
    dropdownItemProps: studentsDropdownItem,
    dropdownWrapperProps: studentsDropdownWrapper,
    resetValues: resetAssignmentUsers,
  } = useDropdownCheckbox({
    allTitle: 'All Students',
    emptyTitle: 'No Students',
    indeterminateTitle: 'students',
    isCollapsable: true,
    collapseTitle: 'STUDENTS',
    options: studentOptions,
    initialValues: useMemo(() => {
      // edit
      if (Array.isArray(assignment?.assignmentUsers)) {
        return assignment?.assignmentUsers.map(({ user_id }) => user_id)
      }

      // create
      return students.map(({ user }) => user?.id || '')
    }, [students]),
  })

  const handleSubmitAssignmentForm = async (data: AssignmentInput) => {
    const newData = {
      ...data,
      assignmentUsers,
      assignmentGroups,
      files: newAttachments,
      isVisible,
      addNewUsers,
      addNewGroups,
      removedFiles: removedAttachments,
      dueDate: dueDate ? dueDate.toISOString() : dueDate,
      schedule: schedule.toISOString(),
      type: 'assignment',
      grading,
      rubric,
      topicId,
      withAssignmentReview: reviewState.assignmentReview,
      withLearningReview: reviewState.learningReview,
    }

    await onSubmit(newData)

    if (isCreate) {
      resetFormState()
      resetAttachments()
      setDueDate(undefined)
      resetSchedule()
      resetAssignmentUsers()
      resetAssignmentGroups()
    }
  }

  return (
    <form onSubmit={handleSubmit(handleSubmitAssignmentForm)}>
      <VStack
        spacing={4}
        w='100%'
        divider={<StackDivider layerStyle='divider' />}
        align='flex-start'
      >
        <VStack spacing={4} w='100%' align='flex-start'>
          <Input
            name='classId'
            ref={register()}
            type='hidden'
            defaultValue={classId}
          />

          <FormControl isInvalid={!!errors.name?.message}>
            <FormLabel variant='required'>Title</FormLabel>
            <Input
              name='name'
              ref={register({
                required: 'Assignment title is a required field',
                minLength: {
                  value: 2,
                  message: 'Title must be longer than 1 character',
                },
                maxLength: {
                  value: 100,
                  message: 'Title exceeded 100 maximum characters',
                },
                validate: {
                  validateSpaceOnly,
                },
              })}
              onChange={debounce(async () => {
                await trigger('name')
              }, 500)}
            />
            {errors?.name && (
              <FormErrorMessage>
                <FormErrorIcon />
                {errors.name?.message}
              </FormErrorMessage>
            )}
          </FormControl>

          <FormControl
            isInvalid={!!errors.description?.message}
            css={{
              '& .optional::after': {
                letterSpacing: '0',
              },
            }}
          >
            <FormLabel variant='optional' className='optional'>
              Description
            </FormLabel>
            <Textarea
              resize='vertical'
              textStyle='body.1'
              maxHeight='200px'
              h='144px'
              name='description'
              ref={register({
                maxLength: {
                  value: 1000,
                  message: 'Description exceeded 1000 maximum characters',
                },
                validate: {
                  validateSpaceOnly,
                },
              })}
              onChange={debounce(async () => {
                await trigger('description')
              }, 500)}
            />
            {errors?.description && (
              <FormErrorMessage>
                <FormErrorIcon />
                {errors.description?.message}
              </FormErrorMessage>
            )}
          </FormControl>

          {renderAttachButton()}
          {renderAttachmentError()}
        </VStack>

        {renderFileList({ padding: '0px' })}

        <VStack w='100%' spacing={8}>
          <SimpleGrid spacing={8} columns={{ base: 1, md: 3 }} w='100%'>
            <FormControl>
              <FormLabel color='primary.1'>Due Date</FormLabel>
              <EditableDatePicker
                value={dueDate}
                onChange={(date) => setDueDate(date)}
                placeholderText='No Due Date'
              />
            </FormControl>

            <FormControl isInvalid={!!errors.rubric?.message}>
              <FormLabel color='primary.1'>Rubric</FormLabel>
              <Select
                value={rubric}
                onChange={(value) => handleInputChange('rubric', value)}
                options={rubricOptions}
                menuButtonProps={{
                  textStyle: 'body.1',
                  color: 'primary.1',
                  w: '100%',
                }}
                menuItemProps={{
                  textStyle: 'body.1',
                  color: 'primary.1',
                }}
              />
            </FormControl>

            <FormControl
              visibility={rubric === 'numerical' ? 'visible' : 'hidden'}
              display={{
                base: rubric === 'numerical' ? 'block' : 'none',
                md: 'block',
              }}
            >
              <FormLabel color='primary.1'>Grading</FormLabel>
              <Input
                name='grading'
                textStyle='body.1'
                value={grading}
                onChange={(e) => handleInputChange('grading', e.target.value)}
                color='primary.1'
              />
            </FormControl>

            <FormControl isInvalid={!!errors.topicId?.message}>
              <FormLabel color='primary.1'>Topic</FormLabel>
              <Select
                value={topicId}
                onChange={(value) => handleInputChange('topicId', value)}
                options={topics}
                placeholder='No Topic'
                menuButtonProps={{
                  textStyle: 'body.1',
                  color: 'primary.1',
                  w: '100%',
                  whiteSpace: 'no-wrap',
                }}
                menuItemProps={{
                  textStyle: 'body.1',
                  color: 'primary.1',
                }}
              />
            </FormControl>

            <FormControl>
              <FormLabel color='primary.1'>Assign To</FormLabel>

              <MultiDropdown
                {...{
                  menuButtonProps: {
                    w: '100%',
                    textStyle: 'body.1',
                  },
                  itemProps: [studentsDropdownWrapper, groupsDropdownWrapper],
                  allTitle: 'All',
                  indeterminateTitle: 'Students and Groups',
                  emptyTitle: 'Not assigned',
                }}
              >
                <MultiDropdownItem {...studentsDropdownItem} />
                <MenuDivider />
                <MultiDropdownItem {...groupsDropdownItem} />
              </MultiDropdown>
            </FormControl>

            <FormControl isInvalid={!!errors.schedule?.message}>
              <FormLabel color='primary.1'>Schedule</FormLabel>
              <EditableDatePicker
                value={schedule}
                onChange={handleChangeSchedule}
              />
            </FormControl>
          </SimpleGrid>

          <VStack spacing='24px' w='100%'>
            <Flex w='100%' align='center'>
              <Text textStyle='body.1'>Auto-assign new students</Text>
              <Spacer />
              <Switch
                isChecked={addNewUsers}
                name='addNewUsers'
                onChange={(e) => setAddNewUsers(e.target.checked)}
              />
            </Flex>

            <Flex w='100%' align='center'>
              <Text textStyle='body.1'>Auto-assign new groups</Text>
              <Spacer />
              <Switch
                isChecked={addNewGroups}
                name='addNewGroups'
                onChange={(e) => setAddNewGroups(e.target.checked)}
              />
            </Flex>

            <Flex w='100%' align='center'>
              <Text textStyle='body.1'>Show in Class</Text>
              <Spacer />
              <Switch
                isChecked={isVisible}
                name='isVisible'
                onChange={(e) => setIsVisible(e.target.checked)}
              />
            </Flex>
          </VStack>
        </VStack>

        <Flex direction='column'>
          <Flex p='24px 0px 16px' direction='column'>
            <Text textStyle='h5'>Reviews</Text>
            <Text textStyle='body.1' mt='4px' color='black.4'>
              Gain valuable feedback on students' experience with the assignment
            </Text>
          </Flex>

          <FormControl
            isInvalid={assignmentReview && !reviewState.assignmentReview}
          >
            <Flex py='6px'>
              <Checkbox
                isChecked={reviewState.assignmentReview}
                onChange={(e) =>
                  setReviewState({
                    ...reviewState,
                    assignmentReview: e.target.checked,
                  })
                }
              >
                <Flex align='center'>
                  <Text textStyle='h6'>Assignment Review:</Text>
                  <Text textStyle='body.1' ml='4px'>
                    Collect feedback after the assignment has been submitted
                  </Text>
                </Flex>
              </Checkbox>
            </Flex>

            {assignmentReview && !reviewState.assignmentReview && (
              <FormErrorMessage mt='0' textStyle='body.1' pl='32px'>
                {`All assignment review data for ${name} such as questions, responses and analytics will be removed.`}
              </FormErrorMessage>
            )}
          </FormControl>

          <FormControl
            pb='20px'
            isInvalid={learningReview && !reviewState.learningReview}
          >
            <Flex py='6px'>
              <Checkbox
                isChecked={reviewState.learningReview}
                onChange={(e) =>
                  setReviewState({
                    ...reviewState,
                    learningReview: e.target.checked,
                  })
                }
              >
                <Flex align='center'>
                  <Text textStyle='h6'>Learning Review:</Text>
                  <Text textStyle='body.1' ml='4px'>
                    Collect feedback after the assignment has been graded
                  </Text>
                </Flex>
              </Checkbox>
            </Flex>

            {learningReview && !reviewState.learningReview && (
              <FormErrorMessage mt='0' textStyle='body.1' pl='32px'>
                {`All learning review data for ${name} such as questions, responses and analytics will be removed.`}
              </FormErrorMessage>
            )}
          </FormControl>
        </Flex>
      </VStack>

      <Flex
        py={6}
        justify='flex-end'
        borderTop='.5px solid'
        borderTopColor='primary.2'
      >
        <Button
          variant='base.primary'
          type='submit'
          disabled={!isValid}
          textStyle='button'
          loadingText={submittingText}
          isLoading={isSubmitting}
        >
          {submitText}
        </Button>
      </Flex>
    </form>
  )
}

export default AssignmentForm
