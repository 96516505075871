import React, { Fragment } from 'react'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import {
  Box,
  Text,
  Flex,
  Container,
  StackDivider,
  VStack,
  SimpleGrid,
} from '@chakra-ui/react'
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  LabelList,
  Label,
  XAxis,
  YAxis,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from 'recharts'
import textStyles from '../../theme/textStyles'
import millisecondsToMinutes from 'date-fns/millisecondsToMinutes'
import subMilliseconds from 'date-fns/subMilliseconds'
import { GET_ASSIGNMENT_REPORT, GET_REVIEW_REPORT } from '../../client/queries'
import { PieLabel } from '../../components/Chart'
import { ExamResponse } from '../../types'
import {
  buildStudentCompletionData,
  buildInitialGraphData,
  populateCompletionGraph,
  StudentWithCompletionData,
  StudentWithGradesData,
  buildStudentGradesData,
  getGradesData,
  getReviewChartData,
  renderCustomizedPieLabel,
  renderReviewCustomizedPieLabel,
  buildAnalyticsCrumbs,
  buildAssignmentLinks,
  buildGroupCompletionData,
  buildGroupGradesData,
  AnalyticsAssignmentGroupData,
  GroupWithCompletionData,
  GroupWithGradesData,
  reviewStrokeProp,
} from '../../utils'
import { Layout } from '../../components/Layout'
import { TopNavItemProps } from '../../components/TopNav'
import { RespondentCount } from '../../components/Analytics'
import { useAlamContext } from '../../client'

export interface StudentsData {
  studentsCompletionData: StudentWithCompletionData[]
  studentsGradesData: StudentWithGradesData[]
}

export interface GroupsData {
  groupsCompletionData: GroupWithCompletionData[]
  groupsGradesData: GroupWithGradesData[]
}

const AssignmentPage = () => {
  const PIE_COLORS = ['rgba(238, 131, 125, 1)', 'rgba(206, 16, 50, 1)']
  const params = useParams<{
    id: string
    topic_id?: string
    assignment_id: string
  }>()
  const { id, topic_id, assignment_id } = params

  const history = useHistory()
  const { data, refetch } = useQuery(GET_ASSIGNMENT_REPORT, {
    variables: { assignment_id: assignment_id },
  })
  const { classUser } = useAlamContext()
  const className = classUser?.class?.name

  const assignment = data?.assignments_by_pk
  const assignmentStudents = assignment?.assignment_users || []
  const assignmentGroups = assignment?.assignment_groups || []

  const { data: reviewReportsData } = useQuery(GET_REVIEW_REPORT, {
    variables: { assignment_id: assignment_id },
  })

  const {
    studentsCompletionData,
    studentsGradesData,
  } = assignmentStudents.reduce(
    (
      { studentsCompletionData, studentsGradesData }: StudentsData,
      student: any
    ) => {
      const studentCompletionData = buildStudentCompletionData(student)
      const studentGradesData = buildStudentGradesData(student?.user)
      return {
        studentsCompletionData: [
          ...studentsCompletionData,
          studentCompletionData,
        ],
        studentsGradesData: [...studentsGradesData, studentGradesData],
      }
    },
    {
      studentsCompletionData: [],
      studentsGradesData: [],
    }
  )

  const { groupsCompletionData, groupsGradesData } = assignmentGroups.reduce(
    (
      { groupsCompletionData, groupsGradesData }: GroupsData,
      assignmentGroup: AnalyticsAssignmentGroupData
    ) => {
      const groupCompletionData = buildGroupCompletionData(assignmentGroup)
      const groupGradesData = buildGroupGradesData(assignmentGroup?.group)
      return {
        groupsCompletionData: [...groupsCompletionData, groupCompletionData],
        groupsGradesData: [...groupsGradesData, groupGradesData],
      }
    },
    {
      groupsCompletionData: [],
      groupsGradesData: [],
    }
  )

  const completedStudents = studentsCompletionData.filter(
    (student: StudentWithCompletionData) => {
      const completionTime = student.completionTime
      return completionTime === 0 || !!completionTime
    }
  )

  const completedGroups = groupsCompletionData.filter(
    (group: GroupWithCompletionData) => {
      const completionTime = group.completionTime
      return completionTime === 0 || !!completionTime
    }
  )

  const totalCount = studentsCompletionData.length + groupsCompletionData.length
  const respondentCount = completedStudents.length + completedGroups.length

  const completionRawData = [...completedStudents, ...completedGroups]

  const initialCompletionGraph = buildInitialGraphData(completionRawData)

  const finalCompletionGraph = populateCompletionGraph(
    completionRawData,
    initialCompletionGraph
  )

  const gradesRawData = [...studentsGradesData, ...groupsGradesData]

  const gradesGraphData = getGradesData(gradesRawData, assignment?.rubric)

  const assignmentName = data?.assignments_by_pk?.name || ''

  const totalStudentCount =
    reviewReportsData?.totalStudentCount?.aggregate?.count || 0
  const totalGroupUserCount =
    reviewReportsData?.totalGroupCount?.reduce(
      (total: number, assignment_groups: any) => {
        return (
          total +
          (assignment_groups?.group?.group_users_aggregate?.aggregate?.count ||
            0)
        )
      },
      0
    ) || 0

  const totalAssignedCount = totalStudentCount + totalGroupUserCount

  const assignmentReviewChartData = getReviewChartData({
    completeReviews: reviewReportsData?.completeAssignmentReview || [],
    totalStudentCount: totalAssignedCount,
    questionCount:
      reviewReportsData?.assignmentReviewQuestions?.aggregate?.count || 0,
  })

  const learningReviewChartData = getReviewChartData({
    completeReviews: reviewReportsData?.completeLearningReviews || [],
    totalStudentCount: totalAssignedCount,
    questionCount:
      reviewReportsData?.learningReviewQuestions?.aggregate?.count || 0,
  })

  const showAssignmentReviewChart = assignmentReviewChartData
  const showLearningReviewChart = learningReviewChartData

  const topNavLeft: TopNavItemProps[] = [
    {
      type: 'breadcrumbs',
      crumbs: buildAnalyticsCrumbs(
        params,
        {
          topicName: assignment?.topic?.name,
          className: className,
          assignmentName: assignmentName,
        },
        'assignment'
      ),
    },
  ]

  return (
    <Layout showSideNav topNavLeft={topNavLeft}>
      <Box px={6} pt={6} mb={8}>
        <Text textStyle='h2' color='black.1'>
          {assignmentName}
        </Text>
        <RespondentCount
          respondentCount={respondentCount}
          totalCount={totalCount}
        />
      </Box>

      <Container
        maxW={{ base: '100%', md: '456px', lg: '500px', xl: '1080px' }}
        px={0}
      >
        <Flex mb={6}>
          <Box
            w='344px'
            bg='white.1'
            borderRadius='12px'
            borderWidth='0.5px'
            borderStyle='solid'
            borderColor='primary.2'
            py={7}
            px={8}
            mr={6}
          >
            <Flex align='center' justify='space-between'>
              <Text textStyle='h5' mb={7}>
                Scores
              </Text>
              <Text
                textStyle='body.big'
                mb={7}
                cursor='pointer'
                color='primary.1'
                onClick={() =>
                  history.push(buildAssignmentLinks(params, 'score'))
                }
              >
                View Report
              </Text>
            </Flex>
            <Flex w='100%' h='264px' mb={3} align='center' justify='center'>
              <ResponsiveContainer>
                <BarChart
                  width={350}
                  height={264}
                  data={gradesGraphData}
                  layout='vertical'
                  margin={{ left: -25 }}
                >
                  <YAxis
                    dataKey='score'
                    type='category'
                    style={{
                      ...textStyles.body['2'],
                      fontWeight: 400,
                      color: '#010002',
                    }}
                    padding={{ bottom: -2 }}
                    interval={0}
                  />
                  <XAxis
                    type='number'
                    tickLine={false}
                    tickFormatter={() => ''}
                    dataKey='students'
                  >
                    <Label
                      value='No. of students'
                      position='insideBottomRight'
                      style={{
                        ...textStyles.body['2'],
                        fontWeight: 400,
                        color: '#010002',
                      }}
                    />
                  </XAxis>
                  <Bar dataKey='students' barSize={30} fill='#CE1032'>
                    <LabelList
                      position='insideRight'
                      fill='#FFFFFF'
                      offset={7}
                      style={{
                        fontWeight: 600,
                        fontSize: '12px',
                        lineHeight: '20px',
                        fontFamily: "'Nunito Sans', sans-serif",
                      }}
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Flex>
          </Box>

          <Box
            bg='white.1'
            borderRadius='12px'
            borderWidth='0.5px'
            borderStyle='solid'
            borderColor='primary.2'
            py={7}
            pr={5}
            pl={8}
            flex='1'
          >
            <Flex align='center' justify='space-between'>
              <Text textStyle='h5' mb={7}>
                Completion Time
              </Text>
              <Text
                textStyle='body.big'
                mb={7}
                cursor='pointer'
                color='primary.1'
                onClick={() =>
                  history.push(buildAssignmentLinks(params, 'completion-time'))
                }
              >
                View Report
              </Text>
            </Flex>
            <Flex w='100%' h='264px' mb={3} align='center' justify='center'>
              {finalCompletionGraph ? (
                <ResponsiveContainer width={600}>
                  <BarChart
                    width={350}
                    height={264}
                    data={finalCompletionGraph.graphData}
                    layout='horizontal'
                    margin={{ left: -10, bottom: 10 }}
                  >
                    <YAxis
                      dataKey='amt'
                      type='number'
                      style={{
                        ...textStyles.body['2'],
                        fontWeight: 400,
                        color: '#010002',
                      }}
                    >
                      <Label
                        value='No. of students'
                        position='insideTop'
                        angle={-90}
                        style={{
                          ...textStyles.body['2'],
                          fontWeight: 400,
                          fill: '#0100028f',
                        }}
                        dx={-20}
                        dy={35}
                      />
                    </YAxis>

                    <XAxis
                      type='category'
                      dataKey='roundedCompletionTime'
                      style={{
                        ...textStyles.body['2'],
                        fontWeight: 400,
                        color: '#010002',
                      }}
                    >
                      <Label
                        value={`Completion Time (${finalCompletionGraph.unit})`}
                        position='insideBottomRight'
                        style={{
                          ...textStyles.body['2'],
                          fontWeight: 400,
                          fill: '#0100028f',
                        }}
                        offset={0}
                      />
                    </XAxis>
                    <Bar dataKey='amt' barSize={50} fill='#CE1032' />
                  </BarChart>
                </ResponsiveContainer>
              ) : (
                <Flex align='center' justify='center' w='100%' h='100%'>
                  <Text
                    textStyle='body.2'
                    color='black.3'
                    fontSize='16px'
                    fontWeight='600'
                  >
                    Not enough data
                  </Text>
                </Flex>
              )}
            </Flex>
          </Box>
        </Flex>

        <Flex mb={6}>
          <Box
            w='528px'
            bg='white.1'
            borderRadius='12px'
            borderWidth='0.5px'
            borderStyle='solid'
            borderColor='primary.2'
            mr={6}
          >
            <Flex
              align='center'
              justify='space-between'
              borderBottomWidth='1px'
              borderStyle='solid'
              borderColor='primary.2'
              py={7}
              px={8}
            >
              <Text textStyle='h5'>Assignment Review</Text>
              {showAssignmentReviewChart && (
                <Text
                  textStyle='body.big'
                  cursor='pointer'
                  color='primary.1'
                  onClick={() =>
                    history.push(
                      buildAssignmentLinks(params, 'assignment-review')
                    )
                  }
                >
                  View Report
                </Text>
              )}
            </Flex>

            <Flex
              w='100%'
              h='264px'
              mb={3}
              align='center'
              justify='space-between'
            >
              {showAssignmentReviewChart ? (
                <>
                  <Flex w='50%' h='100%'>
                    <ResponsiveContainer width='100%' height='100%'>
                      <PieChart width={400} height={400}>
                        <Pie
                          data={assignmentReviewChartData?.chartData}
                          cx='50%'
                          cy='50%'
                          labelLine={false}
                          label={renderReviewCustomizedPieLabel}
                          outerRadius={80}
                          fill='#8884d8'
                          dataKey='value'
                          isAnimationActive={false}
                        >
                          {assignmentReviewChartData?.chartData?.map(
                            (_, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={PIE_COLORS[index % PIE_COLORS.length]}
                                {...reviewStrokeProp(
                                  assignmentReviewChartData?.completionRate
                                )}
                              />
                            )
                          )}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </Flex>

                  <SimpleGrid columns={2} w='50%' h='100%' pt='46px'>
                    <Flex direction='column'>
                      <Text textStyle='smallstate'>Respondents</Text>
                      <Text textStyle='h5' color='secondary.1' mt='8px'>
                        {assignmentReviewChartData?.respondentCount}
                      </Text>
                    </Flex>

                    <Flex direction='column'>
                      <Text textStyle='smallstate'>Completion Rate</Text>
                      <Text textStyle='h5' color='secondary.1' mt='8px'>
                        {`${assignmentReviewChartData?.completionRate}%`}
                      </Text>
                    </Flex>

                    <Flex direction='column'>
                      <Text textStyle='smallstate'>No. of Questions</Text>
                      <Text textStyle='h5' color='secondary.1' mt='8px'>
                        {assignmentReviewChartData?.questionCount}
                      </Text>
                    </Flex>

                    <Flex direction='column'>
                      <Text textStyle='smallstate'>Average Time</Text>
                      <Text textStyle='h5' color='secondary.1' mt='8px'>
                        {`${
                          assignmentReviewChartData?.avgCompletionTime || 'N/A'
                        }`}
                      </Text>
                    </Flex>
                  </SimpleGrid>
                </>
              ) : (
                <Flex align='center' justify='center' w='100%' h='100%'>
                  <Text
                    textStyle='body.2'
                    color='black.3'
                    fontSize='16px'
                    fontWeight='600'
                  >
                    Not enough data
                  </Text>
                </Flex>
              )}
            </Flex>
          </Box>

          <Box
            bg='white.1'
            borderRadius='12px'
            borderWidth='0.5px'
            borderStyle='solid'
            borderColor='primary.2'
            w='528px'
          >
            <Flex
              align='center'
              justify='space-between'
              borderBottomWidth='1px'
              borderStyle='solid'
              borderColor='primary.2'
              py={7}
              px={8}
            >
              <Text textStyle='h5'>Learning Review</Text>
              {showLearningReviewChart && (
                <Text
                  textStyle='body.big'
                  cursor='pointer'
                  color='primary.1'
                  onClick={() =>
                    history.push(
                      buildAssignmentLinks(params, 'learning-review')
                    )
                  }
                >
                  View Report
                </Text>
              )}
            </Flex>
            <Flex
              w='100%'
              h='264px'
              mb={3}
              align='center'
              justify='space-between'
            >
              {showLearningReviewChart ? (
                <>
                  <Flex w='50%' h='100%'>
                    <ResponsiveContainer width='100%' height='100%'>
                      <PieChart width={400} height={400}>
                        <Pie
                          data={learningReviewChartData?.chartData}
                          cx='50%'
                          cy='50%'
                          labelLine={false}
                          label={renderReviewCustomizedPieLabel}
                          outerRadius={80}
                          fill='#8884d8'
                          dataKey='value'
                          isAnimationActive={false}
                        >
                          {learningReviewChartData?.chartData?.map(
                            (_, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={PIE_COLORS[index % PIE_COLORS.length]}
                                {...reviewStrokeProp(
                                  learningReviewChartData?.completionRate
                                )}
                              />
                            )
                          )}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </Flex>

                  <SimpleGrid columns={2} w='50%' h='100%' pt='46px'>
                    <Flex direction='column'>
                      <Text textStyle='smallstate'>Respondents</Text>
                      <Text textStyle='h5' color='secondary.1' mt='8px'>
                        {learningReviewChartData?.respondentCount}
                      </Text>
                    </Flex>

                    <Flex direction='column'>
                      <Text textStyle='smallstate'>Completion Rate</Text>
                      <Text textStyle='h5' color='secondary.1' mt='8px'>
                        {`${learningReviewChartData?.completionRate}%`}
                      </Text>
                    </Flex>

                    <Flex direction='column'>
                      <Text textStyle='smallstate'>No. of Questions</Text>
                      <Text textStyle='h5' color='secondary.1' mt='8px'>
                        {learningReviewChartData?.questionCount}
                      </Text>
                    </Flex>

                    <Flex direction='column'>
                      <Text textStyle='smallstate'>Average Time</Text>
                      <Text textStyle='h5' color='secondary.1' mt='8px'>
                        {`${
                          learningReviewChartData?.avgCompletionTime || 'N/A'
                        }`}
                      </Text>
                    </Flex>
                  </SimpleGrid>
                </>
              ) : (
                <Flex align='center' justify='center' w='100%' h='100%'>
                  <Text
                    textStyle='body.2'
                    color='black.3'
                    fontSize='16px'
                    fontWeight='600'
                  >
                    Not enough data
                  </Text>
                </Flex>
              )}
            </Flex>
          </Box>
        </Flex>
      </Container>
    </Layout>
  )
}

export default AssignmentPage
