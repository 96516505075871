import React from 'react'
import { Assignment as AssignmentType, Exam } from '../../types'
import { Assignment } from './index'

export interface AssignmentsProps {
  assignments: AssignmentType[]
  context?: string
  materialAssignmentRefetch: () => void
  groupId?: string
  pendingExams?: Exam[]
}

export const Assignments = ({
  assignments,
  context = 'classwork',
  materialAssignmentRefetch,
  groupId,
  pendingExams = [],
}: AssignmentsProps): JSX.Element => {
  return (
    <>
      {assignments?.map((assignment) => {
        return (
          <React.Fragment key={assignment.id}>
            <Assignment
              assignment={assignment}
              context={context}
              materialAssignmentRefetch={materialAssignmentRefetch}
              groupId={groupId}
              pendingExams={pendingExams}
            />
          </React.Fragment>
        )
      })}
    </>
  )
}

export default Assignments
