import React, { useState } from 'react'
import { NewCard } from '../../components/Card'
import { useDisclosure, Flex, Box, Text } from '@chakra-ui/react'
import {
  CreateClass as CreateClassForm,
  CreateClassInput,
  ClassInvite,
  ClassInviteInput,
} from '../../components/Forms'
import { v4 as uuid } from 'uuid'
import { useAlamContext } from '../../client'
import { useMutation, useSubscription, useLazyQuery } from '@apollo/client'
import { Class as ClassType } from '../../types'
import {
  CREATE_CLASS,
  GET_ALL_CLASSES,
  INVITE_USER,
  CREATE_USERS,
  GET_USERS,
} from '../../client/queries'
import { useHistory } from 'react-router-dom'
import { useSuccessToast, useErrorToast, buildURL } from '../../utils'

export type CreateClassProps = {
  handleRefetchClasses: () => void
  showCreateJoinClass: boolean
  onOpenJoinClass: () => void
  currentClassNames: string[]
}

export const CreateClass = ({
  handleRefetchClasses,
  showCreateJoinClass,
  onOpenJoinClass,
  currentClassNames,
}: CreateClassProps): JSX.Element => {
  const [classId, setClassId] = useState('')
  const [classCode, setClassCode] = useState('')
  const [isInviting, setIsInviting] = useState(false)
  const {
    isOpen: showCreateClass,
    onOpen: onOpenCreateClass,
    onClose: onCloseCreateClass,
  } = useDisclosure()

  const {
    isOpen: showClassInvite,
    onOpen: onOpenClassInvite,
    onClose: onCloseClassInvite,
  } = useDisclosure()

  const context = useAlamContext()
  console.log('context: ', context)
  const { user } = context
  const [createClass, { loading: createClassLoading }] = useMutation(
    CREATE_CLASS,
    {
      onError: (err) => {
        console.warn('onError: ', err)
        onCloseCreateClass()
        useErrorToast({ message: 'Class creation failed.' })
      },
    }
  )
  const { data } = useSubscription(GET_ALL_CLASSES)
  const [inviteUser] = useMutation(
    INVITE_USER, 
    {
      onError: (err) => {
        console.warn('onError: ', err)
        onCloseCreateClass()
        useErrorToast({ message: 'Invite user failed.' })
      },
    }
  )
  const [createUsers] = useMutation(
    CREATE_USERS, 
    {
      onError: (err) => {
        console.warn('onError: ', err)
        onCloseCreateClass()
        useErrorToast({ message: 'Creating users failed.' })
      },
    }
  )
  const [getUsers] = useLazyQuery(GET_USERS)

  const history = useHistory()

  const createClassCode = (len = 6): string => {
    return doCreateClassCode(len)
  }

  const doCreateClassCode = (len: number): string => {
    const code = generateCode(len)
    if (data.classes.find((userClass: ClassType) => userClass.code === code)) {
      doCreateClassCode(len)
    }

    return code
  }

  const generateCode = (length: number): string => {
    const result = []
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
      result.push(
        characters.charAt(Math.floor(Math.random() * charactersLength))
      )
    }
    return result.join('')
  }

  const handleCreateClass = async ({
    className: name,
    subject,
  }: CreateClassInput) => {
    try {
      const newClassId = uuid()
      const code = createClassCode()

      const newClassPromise = await createClass({
        variables: {
          class_id: newClassId,
          name,
          subject,
          code: code,
          class_user_id: uuid(),
          user_id: user.id,
        },
      })
      console.log('newClassPromise: ', newClassPromise)

      setClassId(newClassId)
      setClassCode(code)
      handleRefetchClasses()
      onCloseCreateClass()
      useSuccessToast({ message: `Class ${name} Created!` })
      onOpenClassInvite()
    } catch (error) {
      console.warn(error)
      useErrorToast({ message: `Class creation failed` })
    }
  }

  const handleClassInviteSubmit = async ({
    teachers,
    students,
  }: ClassInviteInput) => {
    setIsInviting(true)

    await Promise.all([
      handleInviteTeachers(teachers),
      handleInviteStudents(students),
    ])
      .then(() => {
        useSuccessToast({ message: 'Invited successfully' })
      })
      .catch((error) => {
        if (error.message === 'User has not accepted class invite') {
          useErrorToast({
            title: 'User has not accepted class invite',
            message:
              'The user has been added to the class but has not accepted the class invite',
          })
        } else {
          useErrorToast({ message: error.message })
        }
      })

    setIsInviting(false)
    handleCloseClassInvite()
  }

  const handleInviteTeachers = async (teachers: string) => {
    if (teachers) {
      await handleCreateUsers(teachers)
      const emails = teachers.split(',')
      const getTeachersPromise = await getUsers({
        variables: { emails },
      })
      const inviteTeachersPromise = await handleInviteUsers(
        getTeachersPromise?.data?.users,
        'teacher'
      )
      return inviteTeachersPromise
    }
  }
  
  const handleInviteStudents = async (students: string) => {
    if (students) {
      await handleCreateUsers(students)
      const emails = students.split(',')
      const getStudentsPromise = await getUsers({
        variables: { emails },
      })
      const inviteStudentsPromise = await handleInviteUsers(
        getStudentsPromise?.data?.users,
        'student'
      )
      return inviteStudentsPromise
    }
  }

  const handleCreateUsers = async (emails: string) => {
    try {
      const userParams = emails.split(',').map((email) => ({
        id: uuid(),
        email,
        updated_at: 'NOW()',
        inserted_at: 'NOW()',
      }))

      const createUsersPromise = await createUsers({
        variables: {
          objects: userParams,
        },
      })
      return createUsersPromise
    } catch (error) {
      console.warn(error)
      useErrorToast({ message: `User wasn't created` })
    }
  }

  const handleInviteUsers = async (users: { id: string }[], role: string) => {
    try {
      const userInvitePromises = await Promise.all(
        users?.map(async ({ id }) =>
          inviteUser({
            variables: {
              class_id: classId,
              user_id: id,
              role,
              send_invite: true,
            },
          })
        )
      )
      return userInvitePromises
    } catch (error) {
      console.warn(error)
      useErrorToast({ message: `User invite failed` })
    }
  }

  const handleCloseClassInvite = () => {
    onCloseClassInvite()
    history.push(`/classes/${classId}`)
  }

  return (
    <>
      <NewCard title='Create Class' onClick={onOpenCreateClass} />

      {/* Display for mobile because 'Create Class' and 'Join Class' Card will not be shown */}
      {showCreateJoinClass && (
        <Flex
          justify='flex-end'
          position='absolute'
          right='24px'
          top='148px'
          zIndex={16}
        >
          <Box
            bg='white.1'
            borderRadius='12px'
            borderStyle='solid'
            borderWidth='.5px'
            borderColor='primary.2'
            color='primary.1'
            w='144px'
          >
            <Text
              p={3}
              fontSize='sm'
              borderStyle='solid'
              borderBottomWidth='.5px'
              borderColor='primary.2'
              onClick={onOpenCreateClass}
            >
              Create Class
            </Text>
            <Text p={3} fontSize='sm' onClick={onOpenJoinClass}>
              Join Class
            </Text>
          </Box>
        </Flex>
      )}

      <CreateClassForm
        isOpen={showCreateClass}
        onClose={onCloseCreateClass}
        onSubmit={handleCreateClass}
        isSubmitting={createClassLoading}
        currentClassNames={currentClassNames}
      />
      <ClassInvite
        isOpen={showClassInvite}
        onClose={handleCloseClassInvite}
        onSubmit={handleClassInviteSubmit}
        inviteLink={buildURL({
          host: window.location.host,
          path: `/join/${classCode}`,
        })}
        isSubmitting={isInviting}
      />
    </>
  )
}

export default CreateClass
