import { useMutation } from '@apollo/client'
import { UPDATE_LAST_SEEN } from '../client/queries'
import useInterval from '@use-it/interval'

export type useOnlineStatus = {
  user_id?: string
  delay?: number
}

export const useOnlineStatus = ({ user_id, delay }: useOnlineStatus): void => {
  const interval = delay
    ? delay
    : Number(process.env.REACT_APP_ONLINE_INTERVAL) || 2000

  const [updateOnlineStatus] = useMutation(UPDATE_LAST_SEEN, {
    variables: { user_id },
  })

  useInterval(() => {
    updateOnlineStatus()
  }, interval)
}
