import React, { useEffect, useState } from 'react'
import { useLocation, Redirect, useHistory } from 'react-router-dom'
import { GRAPHQL_ENDPOINT } from '../../client'
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import { GET_USER } from '../../client/queries'
import { Loader } from '../../components/SharedComponents'
import { useScheme } from '../../utils'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const Auth = (): JSX.Element => {
  const [message, setMessage] = useState('Authenticating..')
  const [authenticated, setAuthenticated] = useState(false)
  const query = useQuery()
  const token = query.get('token') || ''
  const email = query.get('email') || ''
  const history = useHistory()
  const { pathname, hash } = useLocation()

  const redirectPath = pathname + hash

  const redirectToLogin = (path: string) => {
    const encodedPath = encodeURIComponent(path)

    const url = process.env.REACT_APP_SERVER_HOST || 'localhost:4000'
    // Use this when landing page should be active
    window.location.href = `${useScheme(
      'http'
    )}://${url}/?redirect=${encodedPath}`
    // window.location.href = `${useScheme('http')}://${url}?redirect=${encodedPath}`
  }

  const getUser = async (token: string, email: string) => {
    try {
      const client = new ApolloClient({
        link: new HttpLink({
          uri: GRAPHQL_ENDPOINT,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
        cache: new InMemoryCache(),
      })

      const result = await client.query({
        query: GET_USER,
        variables: { email },
      })

      if (result?.data?.users?.length > 0) {
        const userFromResult = JSON.stringify(result.data.users[0])
        localStorage.setItem('token', token)
        localStorage.setItem('user', userFromResult)
        setAuthenticated(true)
        window.location.href = pathname + hash
        // reload the window with updated url
        window.location.reload()
      } else {
        //setMessage('Authentication failed. User not found')
        redirectToLogin(redirectPath)
      }
    } catch (error) {
      //setMessage(`Authentication failed. ${error.message}`)
      redirectToLogin(redirectPath)
    }
  }

  useEffect(() => {
    if (token && email) {
      getUser(token, email)
    } else {
      setMessage('Authentication failed')
      redirectToLogin(redirectPath)
    }
  }, [])

  // Redirect is only for cleaning the url of token
  return authenticated ? (
    <>
      <Redirect
        to={
          redirectPath
            ? {
                pathname,
                hash,
              }
            : '/'
        }
      />
    </>
  ) : (
    <Loader loaders={[true]}>
      <></>
    </Loader>
  )
}

export default Auth
