import { Layout } from '../../components/Layout'
import { TopNavItemProps } from '../../components/TopNav'
import { Grade as GradeType, EventLog } from '../../types'
import { Topic, Exam, Group } from '../../types'
import { RenderForUser } from '../../components/SharedComponents'
import StudentClassGrades from './StudentClassGrades'
import TeacherClassGrades from './TeacherClassGrades'

export type AssignmentTypes = {
  id: string
  name: string
  grading: string
  updated_at: string
  topic: Topic
  due_date?: string
  rubric: string
  examsForStudents?: Exam[]
  examsForGroups?: Exam[]
}

export type UserTypes = {
  id: string
  email: string
  firstName: string
  lastName: string
  avatar: string
  submissions: SubmissionTypes[]
}

export type SubmissionTypes = {
  id: string
  status: string
  grades: GradeType[]
  assignment?: AssignmentTypes
  eventLogs: Omit<EventLog, 'user'>[]
  user_id?: string
  group_id?: string
  group?: Group
}

export type ClassGradesProps = {
  topNavLeft: TopNavItemProps[]
  topNavCenter: TopNavItemProps[]
  assignments: AssignmentTypes[]
  users: UserTypes[]
  usersRefetch: () => void
  topics: Topic[]
}

const ClassGrades = ({
  topNavLeft,
  topNavCenter,
  assignments,
  topics,
  users,
  usersRefetch,
}: ClassGradesProps): JSX.Element => {
  return (
    <>
      <RenderForUser roles={['admin', 'teacher', 'creator']}>
        <TeacherClassGrades
          assignments={assignments}
          topics={topics}
          users={users}
          usersRefetch={usersRefetch}
        />
      </RenderForUser>
      <RenderForUser roles={['student']}>
        <StudentClassGrades topics={topics} />
      </RenderForUser>
    </>
  )
}

export default ClassGrades
