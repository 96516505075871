import { Fragment, useState, useMemo, useEffect } from 'react'
import {
  Container,
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  Text,
  HStack,
  IconButton,
} from '@chakra-ui/react'
import { IoChevronDown } from 'react-icons/io5'
import { AlamAvatar } from '../../components/Avatar'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import { Layout } from '../../components/Layout'
import { TopNavItemProps } from '../../components/TopNav'
import { useParams, useRouteMatch } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_PRETOPIC_COMPLETION_REPORT } from '../../client/queries'
import {
  buildStudentCompletionData,
  buildInitialGraphData,
  populateCompletionGraph,
  renderCompletionTime,
  renderSubmissionDate,
  StudentWithCompletionData,
  gradesSortDisplay,
  usePagination,
  sortStudentData,
} from '../../utils'
import format from 'date-fns/format'
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  LabelList,
  Label,
  XAxis,
  YAxis,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from 'recharts'
import textStyles from '../../theme/textStyles'
import { Select } from '../../components/Input'
import { RespondentCount } from '../../components/Analytics'

type StudentTypes = {
  id: string
  first_name: string
  last_name: string
  submissionDate: string
  completionTime: string
  avatar: string
}

const CompletionTimePage = (): JSX.Element => {
  const [sortBy, setSortBy] = useState('a-z')
  const { id: class_id, topic_id } = useParams<{
    id: string
    topic_id: string
  }>()
  const { data } = useQuery(GET_PRETOPIC_COMPLETION_REPORT, {
    variables: { topic_id: topic_id },
  })

  const pretopic = data?.assignments[0]
  const topNavLeft: TopNavItemProps[] = [
    {
      type: 'breadcrumbs',
      crumbs: [
        {
          text: pretopic?.topic?.name || 'Topic',
          url: `/classes/${class_id}/analytics/class/topic/${topic_id}`,
        },
        {
          text: 'Completion Time',
          url: `/classes/${class_id}/analytics/class/topic/${topic_id}/completion-time`,
        },
      ],
    },
  ]

  const assignmentStudents = pretopic?.assignment_users || []

  const students = assignmentStudents.map((student: any) => {
    return buildStudentCompletionData(student)
  })

  const completedStudents = students.filter(
    (student: StudentWithCompletionData) => {
      return !!student.completionTime
    }
  )

  const totalCount = students.length
  const respondentCount = completedStudents.length

  const initialGraph = buildInitialGraphData(completedStudents)

  const finalGraph = populateCompletionGraph(completedStudents, initialGraph)

  const sortOptions = [
    { value: 'a-z', display: 'Alphabetical A-Z' },
    { value: 'z-a', display: 'Alphabetical Z-A' },
  ]

  const sortedStudentData = useMemo(() => {
    return sortStudentData(students, sortBy)
  }, [students, sortBy])

  const {
    page,
    setPage,
    totalPages,
    currentPageContent,
    totalItems,
  } = usePagination(sortedStudentData)

  useEffect(() => {
    setPage(1)
  }, [sortBy])
  return (
    <Layout showSideNav topNavLeft={topNavLeft}>
      <Box px={6} pt={6} mb={8}>
        <Text textStyle='h2' color='black.1'>
          Completion Time
        </Text>
        <RespondentCount
          respondentCount={respondentCount}
          totalCount={totalCount}
        />
      </Box>
      <Container
        maxW={{ base: '100%', md: '456px', lg: '500px', xl: '1080px' }}
        px={0}
        mb={6}
      >
        <Box
          h='496px'
          bg='white.1'
          borderRadius='12px'
          borderWidth='0.5px'
          borderStyle='solid'
          borderColor='primary.2'
          py={7}
          pr={5}
          pl={8}
        >
          <Flex align='center' justify='center' w='100%' h='100%'>
            {pretopic && finalGraph ? (
              <ResponsiveContainer>
                <BarChart
                  data={finalGraph?.graphData}
                  layout='horizontal'
                  margin={{ left: -15, bottom: 10 }}
                >
                  <YAxis
                    dataKey='amt'
                    type='number'
                    allowDecimals={false}
                    style={{
                      ...textStyles.body['2'],
                      fontWeight: 400,
                      color: '#010002',
                    }}
                  >
                    <Label
                      value='No. of students'
                      position='insideTop'
                      angle={-90}
                      style={{
                        ...textStyles.body['2'],
                        fontWeight: 400,
                        fill: '#0100028f',
                      }}
                      dy={50}
                    />
                  </YAxis>

                  <XAxis
                    type='category'
                    dataKey='roundedCompletionTime'
                    style={{
                      ...textStyles.body['2'],
                      fontWeight: 400,
                      color: '#010002',
                    }}
                  >
                    <Label
                      value={`Completion Time (${finalGraph?.unit})`}
                      position='insideBottomRight'
                      style={{
                        ...textStyles.body['2'],
                        fontWeight: 400,
                        fill: '#0100028f',
                      }}
                      offset={0}
                    />
                  </XAxis>
                  <Bar dataKey='amt' barSize={60} fill='#CE1032' />
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <Text
                textStyle='body.2'
                color='black.3'
                fontSize='16px'
                fontWeight='600'
              >
                Not enough data
              </Text>
            )}
          </Flex>
        </Box>
      </Container>
      <Box px={6} py={4}>
        <Select
          value={sortBy}
          onChange={(value) => setSortBy(value)}
          options={sortOptions}
          menuButtonProps={{
            textStyle: 'button',
            variant: 'tab.selector',
            textAlign: 'left',
            py: '8px',
            pr: '8px',
            w: '196px',
            layerStyle: 'card.board',
            color: 'primary.1',
          }}
          menuItemProps={{
            textStyle: 'body.1',
            color: 'primary.1',
            w: '196px',
            _hover: { bg: 'hover.2', color: 'white.1' },
          }}
          displayTransform={gradesSortDisplay}
        />
      </Box>
      <Box
        mx={6}
        my={4}
        css={{
          '& > :last-child': {
            borderRadius: '0px 0px 8px 8px',
          },
          '& > :first-child': {
            borderRadius: '8px 8px 0px 0px',
          },
        }}
      >
        <Flex
          w='100%'
          bg='white.1'
          borderWidth='0.5px'
          borderColor='primary.2'
          css={{
            '& > :nth-child(2)': {
              borderLeftWidth: '0.5px',
              borderRightWidth: '0.5px',
              borderColor: 'rgba(23, 5, 61, 0.32)',
            },
          }}
        >
          <Box py={3} px={6} w='40%'>
            <Text
              textStyle='smallstate'
              textTransform='uppercase'
              color='primary.2'
              letterSpacing='0.02em'
            >
              students
            </Text>
          </Box>
          <Box py={3} px={6} w='30%'>
            <Text
              textStyle='smallstate'
              textTransform='uppercase'
              color='primary.2'
              letterSpacing='0.02em'
            >
              submission date
            </Text>
          </Box>
          <Box py={3} px={6} w='30%'>
            <Text
              textStyle='smallstate'
              textTransform='uppercase'
              color='primary.2'
              letterSpacing='0.02em'
            >
              completion time
            </Text>
          </Box>
        </Flex>
        {currentPageContent?.map((student: StudentWithCompletionData) => (
          <Flex
            w='100%'
            bg='white.1'
            key={student.id}
            borderColor='primary.2'
            borderWidth='0.5px'
            borderTopWidth='0px'
            css={{
              '& > :nth-child(2)': {
                borderLeftWidth: '0.5px',
                borderRightWidth: '0.5px',
                borderColor: 'rgba(23, 5, 61, 0.32)',
              },
            }}
          >
            <Box py={3} px={6} w='40%'>
              <Flex align='center'>
                <AlamAvatar size='lg' mr={2} src={student.avatar} />
                <Box>
                  <Text
                    textStyle='smallstate'
                    textTransform='uppercase'
                    letterSpacing='0.02em'
                    mb={1}
                    fontSize='14px'
                    color='primary.1'
                  >
                    {student.first_name} {student.last_name}
                  </Text>
                  <Box>
                    <HStack
                      spacing={2}
                      divider={<Box layerStyle='bulletpoint' />}
                    >
                      <Text textStyle='subtitle' color='grey.1' fontSize='12px'>
                        Student
                      </Text>
                      {student.group_name && (
                        <Text
                          textStyle='subtitle'
                          color='grey.1'
                          fontSize='12px'
                        >
                          {student.group_name}
                        </Text>
                      )}
                    </HStack>
                  </Box>
                </Box>
              </Flex>
            </Box>
            <Flex py={3} px={6} w='30%' align='center'>
              <Text
                textStyle='smallstate'
                textTransform='uppercase'
                letterSpacing='0.02em'
              >
                {renderSubmissionDate(student.submissionDate)}
              </Text>
            </Flex>
            <Flex py={3} px={6} w='30%' flex='1' align='center'>
              <Text
                textStyle='smallstate'
                textTransform='uppercase'
                letterSpacing='0.02em'
              >
                {renderCompletionTime(student.completionTime)}
              </Text>
            </Flex>
          </Flex>
        ))}
      </Box>
      <Flex align='center' justify='space-between' py={7} mx={6} mb={20}>
        <Text textStyle='body.1' color='rgba(1, 0, 2, 0.56)'>
          Showing {currentPageContent.length} of {totalItems}
        </Text>
        <Flex align='center'>
          <Text textStyle='body.1' color='rgba(1, 0, 2, 0.56)'>
            Page {page} of {totalPages}
          </Text>
          {(page != 1 || page < totalPages) && (
            <Box ml={6}>
              {page !== 1 && (
                <IconButton
                  icon={<MdChevronLeft />}
                  aria-label='previous'
                  variant='icon.button'
                  mr={2}
                  borderWidth='0.5px'
                  borderStyle='solid'
                  borderColor='primary.2'
                  onClick={() => setPage(page - 1)}
                />
              )}
              {page < totalPages && (
                <IconButton
                  icon={<MdChevronRight />}
                  aria-label='next'
                  variant='icon.button'
                  borderWidth='0.5px'
                  borderStyle='solid'
                  borderColor='primary.2'
                  onClick={() => setPage(page + 1)}
                />
              )}
            </Box>
          )}
        </Flex>
      </Flex>
    </Layout>
  )
}

export default CompletionTimePage
