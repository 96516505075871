import { gql } from '@apollo/client'
import { userDisplayFields } from './index'

export const materialFields = gql`
  ${userDisplayFields}
  fragment materialFields on materials {
    id
    title
    user {
      firstName: first_name
      lastName: last_name
    }
    description
    inserted_at
    comments(where: { archived_at: { _is_null: true } }) {
      id
      content
      inserted_at
      user {
        ...userDisplayFields
        email
      }
    }
    materialUsers: material_users {
      id
      user_id
      user {
        id
      }
    }
    learningStyle: learning_style
    classId: class_id
    topicId: topic_id
    isShown: is_shown
    addNewUsers: add_new_users
    addNewGroups: add_new_groups
    schedule
    attachments {
      id
      url
      file
    }
    materialGroups: material_groups {
      group_id
    }
  }
`

export const studentAnalyticsMaterialFields = gql`
  fragment studentAnalyticsMaterialFields on materials {
    id
    title
    inserted_at
    class_id
    material_users_aggregate {
      aggregate {
        count
      }
    }
    material_groups_aggregate {
      aggregate {
        count
      }
    }
  }
`
