import { Flex, Text } from '@chakra-ui/react'
import { CommentList } from '../Post'
import { Comment } from '../../types'

export type PrivateCommentsProps = {
  comments: Comment[]
  emptyCommentsPlaceholder?: string
}

export const PrivateComments = ({
  comments,
  emptyCommentsPlaceholder = 'No feedback has been given back yet.',
}: PrivateCommentsProps) => {
  return (
    <Flex
      w='100%'
      pt='4px'
      minH={comments.length === 0 ? '136px' : 'auto'}
      align='center'
      justify='center'
    >
      {comments.length === 0 ? (
        <Text textStyle='body.big' color='primary.2'>
          {emptyCommentsPlaceholder}
        </Text>
      ) : (
        <CommentList comments={comments} />
      )}
    </Flex>
  )
}
