export interface createFileProps {
  name: string
  mimeType?: string
  parents?: string[]
}

export const createFile = async ({
  name,
  mimeType,
  parents = [],
}: createFileProps): Promise<string> => {
  const fileMetaData = {
    name,
    mimeType,
  }

  try {
    // @ts-ignore
    const response = await window.gapi.client.drive.files.create({
      name,
      parents,
      resource: fileMetaData,
      mimeType,
      fields: 'id',
      supportsAllDrives: true,
    })

    return response?.result?.id
  } catch (error) {
    return error
  }
}

//https://developers.google.com/drive/api/v3/multi-parenting
//https://developers.google.com/drive/api/v3/shortcuts
export type createShortcutProps = {
  targetId: string
  parents?: string[]
  name: string
}

export const createShortcut = async ({
  targetId,
  parents,
  name,
}: createShortcutProps) => {
  try {
    const mimeType = 'application/vnd.google-apps.shortcut'
    const shortcutDetails = {
      targetId,
    }
    const shortcutMetadata = {
      name,
      mimeType,
      shortcutDetails,
    }

    // @ts-ignore
    const response = await window.gapi.client.drive.files.create({
      name,
      mimeType,
      parents,
      resource: shortcutMetadata,
      fields: 'id',
      supportsAllDrives: true,
      shortcutDetails,
    })

    return response?.result?.id
  } catch (error) {
    return error
  }
}

export interface deleteFileProps {
  fileId: string
}

export const deleteFile = async ({
  fileId,
}: deleteFileProps): Promise<string> => {
  try {
    // @ts-ignore
    await window.gapi.client.drive.files.delete({
      fileId,
      supportsAllDrives: true,
    })

    return 'Deleted'
  } catch (error) {
    return error
  }
}

export interface getFileDetails extends deleteFileProps {
  fields: string
}

export const getFileDetails = async ({
  fileId,
  fields,
}: getFileDetails): Promise<any> => {
  try {
    // @ts-ignore
    return await window.gapi.client.drive.files.get({
      fileId,
      fields,
      supportsAllDrives: true,
    })
  } catch (error) {
    return error
  }
}

export interface searchProps {
  query: string
}

// https://developers.google.com/drive/api/v3/search-files#node.js
export const search = async ({ query }: searchProps) => {
  try {
    // @ts-ignore
    return await window.gapi.client.drive.files.list({
      q: query,
      supportsAllDrives: true,
    })
  } catch (error) {
    return error
  }
}

export interface listFilesProps {
  query?: string
  fields?: string
}

// https://developers.google.com/drive/api/v3/search-files#node.js
export const listFiles = async ({
  fields = 'files(parents, id, mimeType)',
  query = `mimeType = 'application/vnd.google-apps.folder' and trashed = false`,
}: listFilesProps) => {
  try {
    // @ts-ignore
    return await window.gapi.client.drive.files.list({
      q: query,
      supportsAllDrives: true,
      fields,
    })
  } catch (error) {
    return error
  }
}
