import { Flex, Text, Link, Box } from '@chakra-ui/react'
import { NavLink as RouterLink } from 'react-router-dom'
import { format } from 'date-fns'
import { Topic } from '../../types'
import { SubmissionType } from './StudentClassGrades'
import { displayStatus } from '../../utils'
import { Assignment } from '../../components/Icons'

type TopicSubmissionListProps = {
  topic: Topic | null
  submissions: SubmissionType[]
}

export const TopicSubmissionList = ({
  topic,
  submissions,
}: TopicSubmissionListProps): JSX.Element => {
  const submittedStatusArray = ['submitted', 'graded', 'returned']
  const formatDate = (dateString: string) => {
    return format(new Date(dateString), 'MM/dd/yyyy')
  }
  const displaySubmissionDate = (submission: SubmissionType) => {
    const submittedEventLog = submission.eventLogs.find((eventLog) => {
      return eventLog.content === 'submitted'
    })
    if (submittedStatusArray.includes(submission.status) && submittedEventLog) {
      return formatDate(submittedEventLog.insertedAt)
    }
    return null
  }

  const displayGrade = (submission: SubmissionType) => {
    if (submission.assignment.rubric === 'numerical' && submission.grades[0]) {
      return `${submission.grades[0]?.grade}/${submission.assignment.grading}`
    }
    return submission.grades[0]?.grade
  }

  return (
    <>
      {topic && submissions.length > 0 && (
        <Flex bg='grey.2' w='100%' height='40px' align='center' key={topic.id}>
          <Text textStyle='body.semibold' pl={6} color='black.1'>
            {topic.name}
          </Text>
        </Flex>
      )}
      {submissions.length > 0 && (
        <Box
          css={{
            '& > :not(:first-child) > *': {
              borderTopWidth: '0',
            },
          }}
        >
          {submissions.map((submission: any) => (
            <Flex
              bg='white.1'
              w='100%'
              key={submission.id}
              css={{
                '& > :last-child': {
                  flexGrow: 1,
                },
              }}
            >
              <Flex
                pl={6}
                w='258px'
                height='64px'
                borderWidth='0.5px'
                borderStyle='solid'
                borderColor='primary.2'
                direction='column'
                justify='center'
              >
                <Text
                  color='black.3'
                  fontSize='10px'
                  fontWeight='400'
                  textTransform='uppercase'
                  letterSpacing='1.5px'
                >
                  {submission.assignment.dueDateStr &&
                    `DUE DATE ${formatDate(
                      submission.assignment.dueDateStr + 'Z'
                    )}`}
                </Text>
                <Flex align='center'>
                  <Box mr={2}>
                    <Assignment width='16' height='16' viewBox='8 8 24 24' />
                  </Box>
                  <Link
                    to={
                      submission.user
                        ? `/classes/${submission.assignment.classId}/assignments/${submission.assignment.id}`
                        : `/classes/${submission.assignment.classId}/groups/${submission.group?.id}/assignments/${submission.assignment.id}`
                    }
                    as={RouterLink}
                    textStyle='body.semibold'
                    color='black.1'
                  >
                    {submission.assignment.name}
                  </Link>
                </Flex>
              </Flex>
              <Flex
                w='112px'
                height='64px'
                borderTopWidth='0.5px'
                borderRightWidth='0.5px'
                borderBottomWidth='0.5px'
                borderStyle='solid'
                borderColor='primary.2'
                align='center'
              >
                <Text textStyle='caption' color='black.1' px={4}>
                  {displayGrade(submission)}
                </Text>
              </Flex>
              <Flex
                w='112px'
                height='64px'
                borderTopWidth='0.5px'
                borderRightWidth='0.5px'
                borderBottomWidth='0.5px'
                borderStyle='solid'
                borderColor='primary.2'
                align='center'
              >
                <Text
                  textStyle='caption'
                  color='black.1'
                  px={4}
                  textTransform='capitalize'
                >
                  {displayStatus(submission)}
                </Text>
              </Flex>
              <Flex
                w='112px'
                height='64px'
                borderTopWidth='0.5px'
                borderRightWidth='0.5px'
                borderBottomWidth='0.5px'
                borderStyle='solid'
                borderColor='primary.2'
                align='center'
              >
                <Text textStyle='caption' color='black.1' px={4}>
                  {displaySubmissionDate(submission)}
                </Text>
              </Flex>
              <Flex
                w='112px'
                height='64px'
                borderTopWidth='0.5px'
                borderRightWidth='0.5px'
                borderBottomWidth='0.5px'
                borderStyle='solid'
                borderColor='primary.2'
                align='center'
              >
                <Text textStyle='caption' color='black.1' px={4}>
                  {submission.group ? 'Group' : 'Individual'}
                </Text>
              </Flex>
              <Flex
                w='112px'
                height='64px'
                borderTopWidth='0.5px'
                borderRightWidth='0.5px'
                borderBottomWidth='0.5px'
                borderStyle='solid'
                borderColor='primary.2'
                align='center'
              >
                {submission.comments.length > 0 ? (
                  <Link
                    to={
                      submission.user
                        ? `/classes/${submission.assignment.classId}/assignments/${submission.assignment.id}/submissions`
                        : `/classes/${submission.assignment.classId}/groups/${submission.group?.id}/assignments/${submission.assignment.id}/submissions`
                    }
                    as={RouterLink}
                    textStyle='caption'
                    px={4}
                    color='black.1'
                  >
                    View Feedback
                  </Link>
                ) : (
                  <Text textStyle='caption' px={4} color='black.3'>
                    No Feedback
                  </Text>
                )}
              </Flex>
            </Flex>
          ))}
        </Box>
      )}
    </>
  )
}

export default TopicSubmissionList
