import { createContext, useContext } from 'react'
import { Assignment, Submission } from '../../types'
import { AssignmentType } from './AssignmentPage'
import {
  handleUpdateSubmissionProps,
  handleUpdateSubmissionGradeProps,
} from './Submission'
import { submissionStatusType, initialState } from './submissionReducer'

export type SubmissionContextState = {
  assignmentId: string
  groupId?: string
  assignment?: AssignmentType
  selectedSubmission?: Submission
  setSelectedSubmission: React.Dispatch<Submission>
  submissionStatus: submissionStatusType
  handleUpdateSubmission: handleUpdateSubmissionProps
  submissionIsUpdating: boolean
  setStatus: React.Dispatch<string>
  handleUpdateSubmissionGrade: handleUpdateSubmissionGradeProps
  submissionGradeIsUpdating: boolean
}

const SubmissionContext = createContext<SubmissionContextState>({
  assignmentId: '',
  groupId: undefined,
  assignment: undefined,
  selectedSubmission: undefined,
  setSelectedSubmission: () => {},
  submissionStatus: initialState,
  handleUpdateSubmission: () => {},
  submissionIsUpdating: false,
  setStatus: () => {},
  handleUpdateSubmissionGrade: () => {},
  submissionGradeIsUpdating: false,
})

export const useSubmissionContext = () => useContext(SubmissionContext)

export default SubmissionContext
