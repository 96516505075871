import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormErrorIcon,
  HStack,
  Flex,
  Text,
  VStack,
  Textarea,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  IconButton,
  Spacer,
  useClipboard,
  Tooltip,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { MdContentCopy } from 'react-icons/md'
import { useSuccessToast, isMobileDevice } from '../../utils'
import { RenderForDevice } from '../SharedComponents'

const spaceRegex = /\s/
// This Regex will allow emails with spaces after the commas.
const commaSeparatedEmailsRegex = /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/

// Validation
const noSpace = (value: string) => !spaceRegex.test(value) || 'No space allowed'
const commaSeparatedEmails = (value: string) => {
  // Regex will catch '' so filter it since field is optional
  if (value === '') {
    return true
  }

  return (
    commaSeparatedEmailsRegex.test(value) ||
    'Valid email addresses separated by comma is required'
  )
}

export type ClassInviteProps = {
  isOpen: boolean
  onClose: () => void
  onSubmit: (data: ClassInviteInput) => void
  inviteLink?: string
  isSubmitting: boolean
  isManagePeople?: boolean
}

export type ClassInviteInput = {
  teachers: string
  students: string
}

export const ClassInvite = ({
  isOpen,
  onClose,
  onSubmit,
  inviteLink = 'https://alam.edu/class-1a-aoeui',
  isSubmitting,
  isManagePeople,
}: ClassInviteProps): JSX.Element => {
  const { register, handleSubmit, errors, watch } = useForm<ClassInviteInput>()
  const watcher = watch()
  const { hasCopied, onCopy } = useClipboard(inviteLink)

  const isButtonDisabled = () => {
    if (!watcher.students && !watcher.teachers) {
      return true
    }

    return Boolean(Object.keys(errors).length)
  }

  const handleCopyLink = () => {
    useSuccessToast({ message: 'Invite Link Copied!' })
    onCopy()
  }

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxWidth={isMobileDevice() ? '20rem' : '36rem'}>
        <ModalHeader>Class Invite</ModalHeader>

        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <VStack spacing={4}>
              <FormControl isInvalid={!!errors?.teachers?.message}>
                <FormLabel fontWeight='600' letterSpacing='0.1rem'>
                  Invite Instructors
                </FormLabel>
                <Textarea
                  placeholder='Enter email addresses separated with a comma ,'
                  resize='vertical'
                  textStyle='body.1'
                  h='80px'
                  maxHeight='120px'
                  border='.5px solid'
                  borderColor='grey.border'
                  padding='1rem'
                  fontSize='16px'
                  lineHeight='24px'
                  letterSpacing='0'
                  name='teachers'
                  ref={register({
                    validate: {
                      noSpace,
                      commaSeparatedEmails,
                    },
                  })}
                  css={{
                    '&::placeholder': {
                      color: '#4F4F4F',
                    },
                  }}
                />
                {errors.teachers && (
                  <FormErrorMessage>
                    <FormErrorIcon w='14px' h='14px' />
                    {errors.teachers?.message}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={!!errors?.students?.message}>
                <FormLabel fontWeight='600' letterSpacing='0.1rem'>
                  Invite Students
                </FormLabel>

                <Flex
                  direction='row'
                  mb={4}
                  bg='grey.2'
                  borderRadius='8px'
                  borderColor='primary.2'
                  textStyle='body.1'
                  alignItems='center'
                  fontSize='16px'
                  lineHeight='24px'
                  pl='16px'
                  py='8px'
                  pr='8px'
                >
                  <RenderForDevice sizes={['md', 'lg', 'xl']}>
                    <Text
                      color='placeholder'
                      mr='1rem'
                      whiteSpace='nowrap'
                      width='auto'
                    >
                      Invite Link
                    </Text>
                  </RenderForDevice>
                  <Text
                    readOnly
                    border='none'
                    wordWrap='break-word'
                    display='flex'
                    alignItems='center'
                  >
                    {inviteLink}
                  </Text>
                  <Spacer />

                  <Tooltip placement='top' label='Copy invite link'>
                    <IconButton
                      aria-label='Copy invite link'
                      icon={<MdContentCopy />}
                      variant='input.icon.button'
                      onClick={handleCopyLink}
                      fontSize='1.1rem'
                      minH='32px'
                      minW='32px'
                      h='32px'
                      w='32px'
                    />
                  </Tooltip>
                </Flex>

                <Textarea
                  placeholder='Enter email addresses separated with a comma ,'
                  resize='vertical'
                  textStyle='body.1'
                  name='students'
                  h='80px'
                  maxHeight='120px'
                  border='.5px solid'
                  borderColor='grey.border'
                  padding='1rem'
                  fontSize='16px'
                  lineHeight='24px'
                  letterSpacing='0'
                  ref={register({
                    validate: {
                      noSpace,
                      commaSeparatedEmails,
                    },
                  })}
                  css={{
                    '&::placeholder': {
                      color: '#4F4F4F',
                    },
                  }}
                />
                {errors.students && (
                  <FormErrorMessage letterSpacing='0.02rem' fontWeight={500}>
                    <FormErrorIcon w='14px' h='14px' />
                    {errors.students?.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing={4}>
              <Button variant='base.white' onClick={onClose} textStyle='button'>
                {isManagePeople ? 'Cancel' : `I'll do it later`}
              </Button>
              <Button
                variant='base.primary'
                type='submit'
                textStyle='button'
                disabled={isButtonDisabled()}
                isLoading={isSubmitting}
                loadingText='Inviting'
              >
                Invite
              </Button>
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

export default ClassInvite
