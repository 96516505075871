import React, { useState, useEffect } from 'react'
import { ExamArrangement } from '../../types'
import {
  Button,
  Flex,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  Spacer,
  Input,
  Text,
} from '@chakra-ui/react'
import { Select } from '../Input'
import {
  addResponseChoice,
  removeQuestion,
  updateQuestionText,
  updateQuestionType,
  validateResponseQuestion,
  isMobileDevice,
} from '../../utils'
import { CustomFooter } from '../Modal'
import { ResponseChoiceInput } from '.'
import { useExamFormContext } from '../../context'
import { FaPlusCircle } from 'react-icons/fa'
import { RenderForDevice } from '../SharedComponents'

export interface QuestionFormProps {
  examArrangement?: ExamArrangement
  updateQuestionType: updateQuestionType
  updateQuestionText: updateQuestionText
  onClickBack: () => void
  addResponseChoice: addResponseChoice
  removeQuestion: removeQuestion
}

export const QuestionForm = ({
  examArrangement,
  updateQuestionType,
  updateQuestionText,
  onClickBack,
  addResponseChoice,
  removeQuestion,
}: QuestionFormProps) => {
  if (!examArrangement) {
    return null
  }

  const {
    updateResponseChoice,
    removeResponseChoice,
    removeOnCancel,
    moveResponseChoiceUp,
    moveResponseChoiceDown,
  } = useExamFormContext()
  const { order, question, id = '' } = examArrangement
  const { response_choices_arrangements } = question
  const questionId = question?.id || ''
  const [questionTextError, setQuestionTextError] = useState<string | boolean>(
    false
  )

  const questionTypeOptions = [
    { display: 'Text', value: 'text' },
    { display: 'Multiple Choice', value: 'multiple_choice' },
    { display: 'Checkbox', value: 'checkbox' },
  ]

  const withErrors =
    response_choices_arrangements.some(({ response_choice: { text } }) => {
      return !!validateResponseQuestion({ value: text })
    }) ||
    !!questionTextError ||
    !question.text

  useEffect(() => {
    if (question?.text) {
      setQuestionTextError(validateResponseQuestion({ value: question.text }))
    }
  }, [])

  const handleOnChangeQuestionText = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setQuestionTextError(validateResponseQuestion({ value: e.target.value }))
    updateQuestionText(questionId, e.target.value)
  }

  return (
    <Flex direction='column' w='100%' pos='relative'>
      <Flex
        direction={{ base: 'column', md: 'row' }}
        px='16px'
        align={{ base: 'start', md: 'center' }}
        w='100%'
        h={{ base: '199px', md: '64px' }}
        justify='space-between'
        borderBottom='.5px solid'
        borderBottomColor='primary.2'
        py={{ base: '24px', md: '0px' }}
      >
        <Text textStyle='h5' color='primary.1'>{`Question ${order + 1}`}</Text>

        {['multiple_choice', 'checkbox'].includes(question.type) && (
          <RenderForDevice sizes={['xs', 'sm']}>
            <FormControl isInvalid={!!questionTextError}>
              <Input
                name='text'
                value={question.text}
                onChange={handleOnChangeQuestionText}
                placeholder='Write a question...'
              />
              {!!questionTextError && (
                <FormErrorMessage>
                  <FormErrorIcon />
                  {questionTextError}
                </FormErrorMessage>
              )}
            </FormControl>
          </RenderForDevice>
        )}

        <Select
          value={question.type}
          options={questionTypeOptions}
          onChange={(type) => updateQuestionType(questionId, type)}
          menuButtonProps={{
            textStyle: 'button',
            variant: 'tab.selector',
            textAlign: 'left',
            py: '8px',
            pr: '8px',
            w: { base: '100%', md: '210px' },
            layerStyle: 'card.board',
            boxShadow: {
              base: 'none',
              md: '0px 4px 8px rgba(23, 5, 61, 0.32)',
            },
          }}
          menuItemProps={{
            textStyle: 'body.1',
            color: 'primary.1',
            w: '210px',
          }}
        />

        {['text'].includes(question.type) && (
          <RenderForDevice sizes={['xs', 'sm']}>
            <FormControl isInvalid={!!questionTextError}>
              <Input
                name='text'
                value={question.text}
                onChange={handleOnChangeQuestionText}
                placeholder='Write a question...'
              />
              {!!questionTextError && (
                <FormErrorMessage>
                  <FormErrorIcon />
                  {questionTextError}
                </FormErrorMessage>
              )}
            </FormControl>
          </RenderForDevice>
        )}
      </Flex>

      <RenderForDevice sizes={['md', 'lg', 'xl']}>
        <Flex p='16px'>
          <FormControl isInvalid={!!questionTextError}>
            <Input
              name='text'
              value={question.text}
              onChange={handleOnChangeQuestionText}
            />
            {!!questionTextError && (
              <FormErrorMessage>
                <FormErrorIcon />
                {questionTextError}
              </FormErrorMessage>
            )}
          </FormControl>
        </Flex>
      </RenderForDevice>

      {response_choices_arrangements.map((responseChoiceArrangement) => (
        <ResponseChoiceInput
          key={responseChoiceArrangement.id}
          responseChoiceArrangement={responseChoiceArrangement}
          question={question}
          updateResponseChoice={updateResponseChoice}
          removeResponseChoice={removeResponseChoice}
          moveResponseChoiceUp={moveResponseChoiceUp}
          moveResponseChoiceDown={moveResponseChoiceDown}
        />
      ))}

      {['multiple_choice', 'checkbox'].includes(question.type) && (
        <Flex
          p='16px 24px'
          borderBottom={{
            base: 'none',
            md: '.5px solid rgba(23, 5, 61, 0.32)',
          }}
        >
          <Button
            variant='base.white'
            leftIcon={<FaPlusCircle />}
            onClick={() => addResponseChoice({ questionId })}
            textStyle='button'
          >
            Add Choice
          </Button>
        </Flex>
      )}

      <Spacer />

      <CustomFooter
        submitText='Add'
        handleSubmit={onClickBack}
        showCancel
        cancelText='Cancel'
        handleCancel={() => {
          if (removeOnCancel) {
            removeQuestion({ order, examArrangementId: id })
          }
          onClickBack()
        }}
        submitDisabled={withErrors}
      />
    </Flex>
  )
}
