import { getSubmittedEventLog } from './completionAnalytics'

export interface StudentWithGradesData {
  id: string
  first_name: string
  last_name: string
  avatar?: string
  submissionDate?: string
  grade?: string
  group_name?: string
}

export interface GroupWithGradesData {
  id: string
  name: string
  submissionDate?: string
  grade?: string
}

export type GradesData = StudentWithGradesData | GroupWithGradesData

export const buildStudentGradesData = (student: any): StudentWithGradesData => {
  const submission = student?.submissions[0]
  const grade = submission?.grades[0]?.grade
  const submittedEventLog = getSubmittedEventLog(submission?.event_logs)
  const submissionDate = submittedEventLog?.inserted_at
  const group_name = student?.group_users[0]?.group?.name

  return {
    ...student,
    grade,
    submissionDate,
    group_name,
  }
}

export const buildGroupGradesData = (group: any): GroupWithGradesData => {
  const submission = group?.submissions[0]
  const grade = submission?.grades[0]?.grade
  const submittedEventLog = getSubmittedEventLog(submission?.event_logs)
  const submissionDate = submittedEventLog?.inserted_at

  return {
    id: group.id!,
    name: group.name!,
    grade,
    submissionDate,
  }
}

export const getNumericalGradesData = (students: GradesData[]) => {
  const initialState: Record<string, number> = {
    'N/A': 0,
    '0': 0,
    '10': 0,
    '20': 0,
    '30': 0,
    '40': 0,
    '50': 0,
    '60': 0,
    '70': 0,
    '80': 0,
    '90': 0,
    '100': 0,
  }

  const populatedGraph = students.reduce(
    (barData: Record<string, number>, { grade }): Record<string, number> => {
      if (!grade) {
        barData['N/A'] = barData['N/A'] + 1
        return barData
      }

      const parsedGrade = parseInt(grade)

      if (parsedGrade >= 100) {
        barData['100'] = barData['100'] + 1
        return barData
      } else if (parsedGrade >= 90) {
        barData['90'] = barData['90'] + 1
        return barData
      } else if (parsedGrade >= 80) {
        barData['80'] = barData['80'] + 1
        return barData
      } else if (parsedGrade >= 70) {
        barData['70'] = barData['70'] + 1
        return barData
      } else if (parsedGrade >= 60) {
        barData['60'] = barData['60'] + 1
        return barData
      } else if (parsedGrade >= 50) {
        barData['50'] = barData['50'] + 1
        return barData
      } else if (parsedGrade >= 40) {
        barData['40'] = barData['40'] + 1
        return barData
      } else if (parsedGrade >= 30) {
        barData['30'] = barData['30'] + 1
        return barData
      } else if (parsedGrade >= 20) {
        barData['20'] = barData['20'] + 1
        return barData
      } else if (parsedGrade >= 10) {
        barData['10'] = barData['10'] + 1
        return barData
      } else if (parsedGrade < 10) {
        barData['0'] = barData['0'] + 1
        return barData
      }

      return barData
    },
    initialState
  )

  return populatedGraph
}

export const getAlphabeticalGradesData = (students: GradesData[]) => {
  const initialState = {
    'N/A': 0,
    F: 0,
    E: 0,
    D: 0,
    C: 0,
    B: 0,
    A: 0,
  }

  const populatedGraph = students.reduce((barData, { grade }) => {
    if (!grade) {
      barData['N/A'] = barData['N/A'] + 1
      return barData
    }
    const lowerCasedGrade = grade.toLowerCase()

    if (lowerCasedGrade === 'a') {
      barData.A = barData.A + 1
      return barData
    } else if (lowerCasedGrade === 'b') {
      barData.B = barData.B + 1
      return barData
    } else if (lowerCasedGrade === 'c') {
      barData.C = barData.C + 1
      return barData
    } else if (lowerCasedGrade === 'd') {
      barData.D = barData.D + 1
      return barData
    } else if (lowerCasedGrade === 'f') {
      barData.F = barData.F + 1
      return barData
    }

    return barData
  }, initialState)

  return populatedGraph
}

export const getGradesData = (
  students: GradesData[],
  rubric: string | undefined
) => {
  if (!rubric) {
    return
  }
  const rawGradesData =
    rubric === 'numerical'
      ? getNumericalGradesData(students)
      : getAlphabeticalGradesData(students)

  return formatGradesDataForGraph(rawGradesData, rubric)
}

export const formatGradesDataForGraph = (
  rawGradesData: Record<string, number>,
  rubric: string | undefined
) => {
  const barData = []

  for (const [score, amt] of Object.entries(rawGradesData)) {
    barData.push({ score, students: amt })
  }

  if (rubric === 'numerical') {
    return barData.sort((first: any, second: any) => {
      const firstScore = parseInt(first.score)
      const secondScore = parseInt(second.score)
      if (isNaN(firstScore)) return 1
      if (isNaN(secondScore)) return -1
      return firstScore > secondScore ? -1 : 1
    })
  } else {
    return barData.sort((first: any, second: any) => {
      return first.score < second.score ? -1 : 1
    })
  }
}
