// @ts-nocheck
import { Box, Flex, Text } from '@chakra-ui/react'

export type PieChartLabelTypes = {
  cx: number
  cy: number
  midAngle: number
  innerRadius: number
  outerRadius: number
  percent: number
  index?: number
}

export type AxisTickProps = {
  x: number
  y: number
  stroke: any
  payload: any
}

const RADIAN = Math.PI / 180

export const CheckboxTooltip = (props) => {
  const { active, payload, label, totalCount } = props

  const actualPayload = payload[0]

  const percentage = actualPayload ? actualPayload.value : 0

  const ratio = percentage / 100
  const choiceCount = Math.round(ratio * totalCount)

  if (active && payload && payload.length) {
    return (
      <Flex
        boxShadow='0px 4px 16px rgba(23, 5, 61, 0.04)'
        bg='black.1'
        borderRadius='12px'
        direction='column'
        padding='0px 8px 0px 0px'
      >
        <Flex padding='8px 16px' direction='column' margin='8px 0px'>
          <Text
            textTransform='uppercase'
            color='white.2'
            fontWeight='700'
            fontSize='10px'
            lineHeight='16px'
            letterSpacing='1.5px'
          >
            {label}
          </Text>
          <Flex direction='row'>
            <Text
              color='white.1'
              fontStyle='normal'
              fontWeight='400'
              fontSize='12px'
              lineHeight='20px'
              letterSpacing='-0.01em'
            >
              {`${percentage.toFixed(2)}% of respondents`}
            </Text>
            <Text
              color='white.1'
              fontStyle='normal'
              fontWeight='400'
              fontSize='12px'
              lineHeight='20px'
              letterSpacing='-0.01em'
            >
              {` (${choiceCount}/${totalCount})`}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    )
  }

  return null
}

export const PercentageXAxisTick = (props: any) => {
  const { x, y, style, payload } = props

  return (
    <text
      x={x}
      y={y}
      dy={16}
      textAnchor='middle'
      style={style}
      fill='rgba(1, 0, 2, 0.56)'
    >
      {payload.value}%
    </text>
  )
}

export const TruncatedChoicesTick = (props: any) => {
  const { x, y, style, payload } = props
  const truncated = truncateString(payload.value)

  return (
    <text x={x} y={y} textAnchor='end' style={style} fill='rgba(1, 0, 2, 0.56)'>
      {truncated}
    </text>
  )
}

const truncateString = (value: string) => {
  if (value.length > 15) {
    return value.slice(0, 12) + '...'
  }
  return value
}

export const renderCustomizedPieLabel = (props: PieChartLabelTypes) => {
  const { percent } = props
  if (percent > 0) {
    return renderPieLabel(props)
  } else {
    return ''
  }
}

export const renderPieLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}: PieChartLabelTypes) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      x={x}
      y={y}
      fill='white'
      textAnchor='middle'
      dominantBaseline='central'
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

export const renderCenterPieLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}: PieChartLabelTypes) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5

  return (
    <text
      x={cx}
      y={cy}
      fill='white'
      textAnchor='middle'
      dominantBaseline='central'
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

export const renderReviewCustomizedPieLabel = (props: PieChartLabelTypes) => {
  const { index, percent } = props
  if (index === 0 && percent === 0) return ''
  if (index === 0 && percent === 1) return ''
  if (index === 1 && (percent === 0 || percent === 1)) {
    return renderCenterPieLabel({
      ...props,
    })
  }

  return renderPieLabel(props)
}

export const formatPercentage = (
  percentage: number,
  decimalPlaces: number
): string => {
  if (percentage % 1 > 0) {
    return percentage.toFixed(decimalPlaces)
  } else {
    return percentage.toString()
  }
}

export const renderPercentageCustomLabel = ({
  x,
  y,
  width,
  height,
  value,
}: any) => {
  if (value === 0) {
    return <></>
  }

  return (
    <g>
      <text
        x={x + width - 15}
        y={y + height / 2 + 1}
        fill='#fff'
        textAnchor='middle'
        dominantBaseline='middle'
        fontSize='12px'
        fontWeight={600}
        fontFamily="'Nunito Sans', sans-serif"
      >
        {formatPercentage(value, 0)}%
      </text>
    </g>
  )
}
