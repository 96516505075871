import { Flex } from '@chakra-ui/react'
import { useSubscription, useMutation } from '@apollo/client'
import { GET_PEOPLE } from '../../client/queries'
import { ManagePeople } from '../ManagePeople'
import { useAlamContext } from '../../client'
import { Layout } from '../Layout'

export const ManagePeopleMobile = (): JSX.Element => {
  const { classUser, user } = useAlamContext()

  const {
    data: {
      class: { managePeopleStudents, managePeopleTeachers, managePeopleAdmins },
    } = {
      class: {
        managePeopleStudents: [],
        managePeopleTeachers: [],
        managePeopleAdmins: [],
      },
    },
  } = useSubscription(GET_PEOPLE, {
    variables: {
      class_id: classUser?.class.id,
    },
  })

  const id = classUser?.class?.id

  const topNavLeft = [{ type: 'text' as const, text: classUser?.class?.name }]
  const topNavCenter = [
    { type: 'link' as const, url: `/classes/${id}`, text: 'Home' },
    {
      type: 'link' as const,
      url: `/classes/${id}/classwork`,
      text: 'Classwork',
    },
    {
      type: 'link' as const,
      url: `/classes/${id}/people`,
      text: 'People',
    },
  ]

  return (
    <Layout
      showSideNav
      topNavLeft={topNavLeft}
      topNavCenter={topNavCenter}
      redirectNonExistingClass
    >
      <Flex h='auto' w='100%' direction='column' bg='white.1'>
        <ManagePeople
          managePeopleStudents={managePeopleStudents}
          managePeopleTeachers={managePeopleTeachers}
          managePeopleAdmins={managePeopleAdmins}
        />
      </Flex>
    </Layout>
  )
}
