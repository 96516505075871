import { gql } from '@apollo/client'
import { classUserChatFields, userDisplayFields } from './fragments'

export const GET_GROUP_CHAT = gql`
  ${classUserChatFields}
  subscription get_group_chat(
    $class_id: uuid!
    $group_id: uuid!
    $current_user_id: uuid!
  ) {
    class: classes_by_pk(id: $class_id) {
      rawOnlineTeachers: class_users(
        where: {
          accepted_at: { _is_null: false }
          role: { _in: ["teacher", "admin", "creator"] }
          user: {
            user_online: {}
            group_users: {
              group_id: { _eq: $group_id }
              archived_at: { _is_null: true }
            }
            _not: { id: { _eq: $current_user_id } }
          }
        }
        order_by: [{ user: { last_name: asc } }, { user: { first_name: asc } }]
      ) {
        ...classUserChatFields
      }

      currentUser: class_users(
        where: {
          accepted_at: { _is_null: false }
          user: {
            user_online: {}
            group_users: {
              group_id: { _eq: $group_id }
              archived_at: { _is_null: true }
            }
            id: { _eq: $current_user_id }
          }
        }
        order_by: [{ user: { last_name: asc } }, { user: { first_name: asc } }]
      ) {
        ...classUserChatFields
      }

      offlineTeachers: class_users(
        where: {
          accepted_at: { _is_null: false }
          role: { _in: ["teacher", "admin", "creator"] }
          user: {
            _not: { user_online: {} }
            group_users: {
              group_id: { _eq: $group_id }
              archived_at: { _is_null: true }
            }
          }
        }
        order_by: [{ user: { last_name: asc } }, { user: { first_name: asc } }]
      ) {
        ...classUserChatFields
      }

      rawOnlineStudents: class_users(
        where: {
          accepted_at: { _is_null: false }
          role: { _eq: "student" }
          user: {
            user_online: {}
            group_users: {
              group_id: { _eq: $group_id }
              archived_at: { _is_null: true }
            }
            _not: { id: { _eq: $current_user_id } }
          }
        }
        order_by: [{ user: { last_name: asc } }, { user: { first_name: asc } }]
      ) {
        ...classUserChatFields
      }

      offlineStudents: class_users(
        where: {
          accepted_at: { _is_null: false }
          role: { _eq: "student" }
          user: {
            _not: { user_online: {} }
            group_users: {
              group_id: { _eq: $group_id }
              archived_at: { _is_null: true }
            }
          }
        }
        order_by: [{ user: { last_name: asc } }, { user: { first_name: asc } }]
      ) {
        ...classUserChatFields
      }
    }
  }
`

export const GET_CLASS_CHAT = gql`
  ${classUserChatFields}
  subscription get_class_chat($class_id: uuid!, $current_user_id: uuid!) {
    class: classes_by_pk(id: $class_id) {
      rawOnlineStudents: class_users(
        where: {
          accepted_at: { _is_null: false }
          role: { _eq: "student" }
          user: { _not: { id: { _eq: $current_user_id } }, user_online: {} }
        }
        order_by: [{ user: { last_name: asc } }, { user: { first_name: asc } }]
      ) {
        ...classUserChatFields
      }

      rawOnlineTeachers: class_users(
        where: {
          accepted_at: { _is_null: false }
          role: { _in: ["teacher", "admin", "creator"] }
          user: { _not: { id: { _eq: $current_user_id } }, user_online: {} }
        }
        order_by: [{ user: { last_name: asc } }, { user: { first_name: asc } }]
      ) {
        ...classUserChatFields
      }

      currentUser: class_users(
        where: {
          accepted_at: { _is_null: false }
          user: { id: { _eq: $current_user_id }, user_online: {} }
        }
      ) {
        ...classUserChatFields
      }

      offlineClassUsers: class_users(
        where: {
          accepted_at: { _is_null: false }
          class_id: { _eq: $class_id }
          user: { _not: { user_online: {} } }
        }
        order_by: [{ user: { last_name: asc } }, { user: { first_name: asc } }]
      ) {
        ...classUserChatFields
      }
    }
  }
`

export const GET_CLASS_CHAT_UNREAD_MESSAGES = gql`
  subscription get_class_chat($class_id: uuid!, $current_user_id: uuid!) {
    class: classes_by_pk(id: $class_id) {
      class_users(
        where: {
          accepted_at: { _is_null: false }
          user_id: { _neq: $current_user_id }
        }
      ) {
        user {
          unreadMessages: messagesSent_aggregate(
            where: {
              class_id: { _eq: $class_id }
              receiver_id: { _eq: $current_user_id }
              read_at: { _is_null: true }
            }
          ) {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
`

export const GET_PEOPLE = gql`
  ${userDisplayFields}
  subscription get_people($class_id: uuid!) {
    class: classes_by_pk(id: $class_id) {
      managePeopleStudents: class_users(
        where: { accepted_at: { _is_null: false }, role: { _eq: "student" } }
      ) {
        id
        role
        user {
          ...userDisplayFields
          learningStyle: learning_style
          bio
        }
      }

      managePeopleTeachers: class_users(
        where: { accepted_at: { _is_null: false }, role: { _eq: "teacher" } }
      ) {
        id
        role
        user {
          ...userDisplayFields
          learningStyle: learning_style
          bio
        }
      }

      managePeopleAdmins: class_users(
        where: {
          accepted_at: { _is_null: false }
          role: { _in: ["admin", "creator"] }
        }
      ) {
        id
        role
        user {
          ...userDisplayFields
          learningStyle: learning_style
          bio
        }
      }
    }
  }
`

export const GET_GROUP_PEOPLE = gql`
  ${userDisplayFields}
  subscription get_group_people($class_id: uuid!, $group_id: uuid!) {
    class: classes_by_pk(id: $class_id) {
      managePeopleStudents: class_users(
        where: {
          accepted_at: { _is_null: false }
          role: { _eq: "student" }
          user: {
            group_users: {
              group_id: { _eq: $group_id }
              archived_at: { _is_null: true }
            }
          }
        }
      ) {
        id
        role
        user {
          ...userDisplayFields
        }
      }

      managePeopleTeachers: class_users(
        where: {
          accepted_at: { _is_null: false }
          role: { _eq: "teacher" }
          user: {
            group_users: {
              group_id: { _eq: $group_id }
              archived_at: { _is_null: true }
            }
          }
        }
      ) {
        id
        role
        user {
          ...userDisplayFields
        }
      }

      managePeopleAdmins: class_users(
        where: {
          accepted_at: { _is_null: false }
          role: { _in: ["admin", "creator"] }
          user: {
            group_users: {
              group_id: { _eq: $group_id }
              archived_at: { _is_null: true }
            }
          }
        }
      ) {
        id
        role
        user {
          ...userDisplayFields
        }
      }
    }
  }
`

export const UPDATE_LAST_SEEN = gql`
  mutation update_last_seen($user_id: uuid!) {
    update_users_by_pk(
      pk_columns: { id: $user_id }
      _set: { last_seen: "NOW()" }
    ) {
      id
    }
  }
`

export const SEND_MESSAGE = gql`
  mutation send_message(
    $id: uuid!
    $class_id: uuid!
    $sender_id: uuid!
    $receiver_id: uuid!
    $text: String!
    $inserted_at: timestamp
  ) {
    insert_messages_one(
      object: {
        id: $id
        class_id: $class_id
        sender_id: $sender_id
        receiver_id: $receiver_id
        text: $text
        inserted_at: $inserted_at
        updated_at: $inserted_at
      }
    ) {
      id
    }
  }
`

export const READ_MESSAGES = gql`
  mutation read_messages(
    $sender_id: uuid!
    $current_user_id: uuid!
    $class_id: uuid!
  ) {
    update_messages(
      where: {
        class_id: { _eq: $class_id }
        receiver_id: { _eq: $current_user_id }
        sender_id: { _eq: $sender_id }
        read_at: { _is_null: true }
      }
      _set: { read_at: "NOW()" }
    ) {
      affected_rows
    }
  }
`
