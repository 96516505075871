import React from 'react'
import { ClassUser } from '../../types'
import { SidebarSubHeading } from '../Heading'
import { ClassUserItem } from './index'

export type ClassUserItemsProps = {
  classUsers: ClassUser[]
  title?: string
  isOnline?: boolean
  handleSelectClassUser: (classUser?: ClassUser) => void
}

export const ClassUserItems = ({
  classUsers,
  title,
  isOnline = false,
  handleSelectClassUser,
}: ClassUserItemsProps) => {
  return classUsers?.length >= 1 ? (
    <>
      {title && <SidebarSubHeading title={title} />}

      {classUsers.map((classUser: ClassUser, index) => (
        <React.Fragment key={classUser.id}>
          <ClassUserItem
            classUser={classUser}
            handleSelectClassUser={handleSelectClassUser}
            isOnline={isOnline}
            isLast={index === classUsers.length - 1}
          />
        </React.Fragment>
      ))}
    </>
  ) : null
}

export default ClassUserItems
