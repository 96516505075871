import React, { useEffect, useState } from 'react'
import { Layout } from '../../components/Layout'
import styled from '@emotion/styled'
import {
  Container,
  Box,
  Icon,
  IconButton,
  Flex,
  HStack,
  VStack,
  StackDivider,
  Text,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'
import { IoMdBookmarks } from 'react-icons/io'
import { useMutation, useQuery, useSubscription } from '@apollo/client'
import { CREATE_MATERIAL_COMMENT, GET_MATERIAL } from '../../client/queries'
import { v4 as uuid } from 'uuid'
import { format } from 'date-fns'
import { dateFormat, routes } from '../../constants'
import { CommentList } from './../../components/Post'
import { useAlamContext } from '../../client'
import {
  getFullName,
  useErrorToast,
  useSuccessToast,
  updateMaterialUserViewTime,
  updateMaterialGroupViewTime,
} from '../../utils'
import { MdSend } from 'react-icons/md'
import { BiChevronLeft } from 'react-icons/bi'
import { useHistory } from 'react-router-dom'
import { AlamAvatar } from '../../components/Avatar'
import { Material } from '../../types'
import { CommentForm } from '../../components/Forms'
import { FileList } from '../../components/File'

export type MateriapPageProps = {
  id: string
  materialId: string
  groupId?: string
}

const MaterialPage = ({
  id,
  materialId,
  groupId,
}: MateriapPageProps): JSX.Element => {
  const [material, setMaterial] = useState({
    id: '',
    title: '',
    description: '',
    learningStyle: [],
    class: {
      subject: '',
      name: '',
      id: '',
    },
    topic: {
      name: '',
    },
    topicId: '',
    schedule: '',
    comments: [],
    inserted_at: '',
    user: {
      firstName: '',
      lastName: '',
    },
    attachments: [],
  })

  useSubscription(GET_MATERIAL, {
    variables: { id: materialId },
    onSubscriptionData: ({
      subscriptionData: {
        data: { materials_by_pk },
      },
    }) => {
      setMaterial(materials_by_pk)
    },
  })

  // const topic = material?.topic || {}
  const history = useHistory()

  const backUrl = groupId
    ? `/classes/${id}/groups/${groupId}/groupwork`
    : `/classes/${id}/classwork`;
  const topNavLeft = [
    {
      type: 'textWithIconButton' as const,
      text: (material?.topic?.name || material.title),
      icon: <BiChevronLeft />,
      onClick: () => history.push(backUrl),
    },
  ];

  const currentUser = useAlamContext().user;
  const { firstName, lastName, avatar } = currentUser;
  const name = getFullName({
    firstName: material?.user?.firstName,
    lastName: material?.user?.lastName,
  })

  if (groupId) {
    updateMaterialGroupViewTime({
      group_id: groupId,
      material_id: materialId,
      delay: 5000,
    })
  } else {
    updateMaterialUserViewTime({
      user_id: currentUser.id,
      material_id: materialId,
      delay: 5000,
    })
  }

  const [createComment, { loading: commentIsSubmitting }] = useMutation(
    CREATE_MATERIAL_COMMENT
  )

  const handleSubmitComment = ({ content }: { content: string }) => {
    createComment({
      variables: {
        id: uuid(),
        content,
        user_id: currentUser.id,
        material_id: materialId,
      },
    })
      .then(() => {
        useSuccessToast({ message: 'Comment was created' })
      })
      .catch(() => {
        useErrorToast({ message: 'Creating comment failed' })
      })
  }

  const countAttachments = 0

  return (
    <>
      <Layout
        showSideNav
        topNavLeft={topNavLeft}
        hasRightNav={routes.classMaterial.hasRightNav}
        redirectNonExistingClass
      >
        <Container
          maxWidth={{ base: '100%', md: '456px', lg: '500px', xl: '668px' }}
          py={6}
          px={{ base: 0, lg: 4 }}
          h='auto'
        >
          <VStack
            bg='white.1'
            layerStyle='card.module'
            borderRadius={{ base: '0', lg: '12px' }}
            w='100%'
            divider={<StackDivider layerStyle='divider' />}
          >
            <Box
              bg='white.1'
              layerStyle='card.expanded'
              w='100%'
              px={4}
              pt={4}
              pb={2}
            >
              <HStack spacing={4} h='100%' w='100%'>
                <Icon as={IoMdBookmarks} color='primary.1' fontSize='2xl' />

                <Flex direction='column'>
                  <Text textStyle='h5'>{material.title}</Text>
                  <HStack
                    spacing={3}
                    divider={<Box layerStyle='bulletpoint' />}
                  >
                    <Text textStyle='body.1' color='grey.1' isTruncated>
                      {name}
                    </Text>

                    <Text textStyle='body.1' color='grey.1'>
                      {material.comments?.length > 1
                        ? `${material.comments?.length} comments`
                        : `${material.comments?.length} comment`}
                    </Text>

                    <Text textStyle='body.1' color='grey.1'>
                      {material.inserted_at &&
                        format(new Date(material.inserted_at), dateFormat)}
                    </Text>
                  </HStack>
                </Flex>
              </HStack>
            </Box>
            {material.description && (
              <Box w='100%' px={6} py={2} textStyle='body.1'>
                {material.description}
              </Box>
            )}

            {material.attachments.length >= 1 && (
              <FileList existingFiles={material.attachments} isReadOnly />
            )}

            <Box w='100%'>
              {material.comments?.length >= 1 ? (
                <CommentList comments={material.comments} />
              ) : (
                <Text textStyle='body.1' color='grey.1' px={4} py={2}>
                  0 comment
                </Text>
              )}
            </Box>

            <Flex w='100%' p='8px 16px 16px'>
              <CommentForm
                user={currentUser}
                isSubmitting={commentIsSubmitting}
                onSubmit={handleSubmitComment}
                avatarSize='sm'
              />
            </Flex>
          </VStack>
        </Container>
      </Layout>
    </>
  )
}

export const FormWrapper = styled(Box)`
  width: 100%;
  .form {
    width: 100%;
  }
`

export default MaterialPage
