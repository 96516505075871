import { useState } from 'react'

export const useDatePicker = (initialState: string) => {
  const initialDate = initialState ? new Date(initialState) : new Date()
  const [date, setDate] = useState<Date>(initialDate)

  const handleChangeDate = (date: Date) => {
    setDate(date)
  }

  const resetDate = () => {
    setDate(initialDate)
  }

  return { date, handleChangeDate, resetDate }
}
