import { Fragment, useState, useMemo, useEffect } from 'react'
import {
  Container,
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  Text,
  HStack,
  IconButton,
} from '@chakra-ui/react'
import { IoChevronDown } from 'react-icons/io5'
import { AlamAvatar } from '../../components/Avatar'
import textStyles from '../../theme/textStyles'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import sub from 'date-fns/sub'
import subMilliseconds from 'date-fns/subMilliseconds'
import format from 'date-fns/format'
import millisecondsToMinutes from 'date-fns/millisecondsToMinutes'
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  LabelList,
  Label,
  XAxis,
  YAxis,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from 'recharts'
import { GET_ASSIGNMENT_REPORT } from '../../client/queries'
import {
  buildStudentCompletionData,
  buildGroupCompletionData,
  buildInitialGraphData,
  AnalyticsAssignmentGroupData,
  populateCompletionGraph,
  renderCompletionTime,
  renderSubmissionDate,
  StudentWithCompletionData,
  GroupWithCompletionData,
  gradesSortDisplay,
  usePagination,
  sortStudentData,
  buildAnalyticsCrumbs,
} from '../../utils'
import { Select } from '../../components/Input'
import { Layout } from '../../components/Layout'
import { TopNavItemProps } from '../../components/TopNav'
import {
  RespondentCount,
  IndividualCompletionAnalyticsTable,
  GroupCompletionAnalyticsTable,
} from '../../components/Analytics'
import { useAlamContext } from '../../client'

const AssignmentCompletionTimePage = () => {
  const [sortBy, setSortBy] = useState('a-z')
  const [dataDisplay, setDataDisplay] = useState<string>('students')
  const params = useParams<{
    id: string
    topic_id?: string
    assignment_id: string
  }>()
  const { id, topic_id, assignment_id } = params
  const { classUser } = useAlamContext()
  const { data, refetch } = useQuery(GET_ASSIGNMENT_REPORT, {
    variables: { assignment_id: assignment_id },
  })

  const dataOption = [
    {
      value: 'students',
      display: 'Class',
    },
    {
      value: 'groups',
      display: 'Groups',
    },
  ]

  const topNavLeft: TopNavItemProps[] = [
    {
      type: 'breadcrumbs',
      crumbs: buildAnalyticsCrumbs(
        params,
        {
          topicName: data?.assignments_by_pk?.topic?.name,
          className: classUser?.class?.name,
          assignmentName: data?.assignments_by_pk?.name,
        },
        'completion-time'
      ),
    },
  ]

  const groups =
    data?.assignments_by_pk?.assignment_groups?.map(
      (assignmentGroup: AnalyticsAssignmentGroupData) =>
        buildGroupCompletionData(assignmentGroup)
    ) || []

  const students =
    data?.assignments_by_pk?.assignment_users?.map((assignmentUser: any) =>
      buildStudentCompletionData(assignmentUser)
    ) || []

  const completedStudents = students.filter(
    (student: StudentWithCompletionData) => {
      const completionTime = student.completionTime
      return completionTime === 0 || !!completionTime
    }
  )

  const completedGroups = groups.filter((group: GroupWithCompletionData) => {
    const completionTime = group.completionTime
    return completionTime === 0 || !!completionTime
  })

  const totalCount = students.length + groups.length
  const respondentCount = completedStudents.length + completedGroups.length
  const allRawData = [...completedStudents, ...completedGroups]

  const initialGraph = buildInitialGraphData(allRawData)

  const finalGraph = populateCompletionGraph(allRawData, initialGraph)

  const sortOptions = [
    { value: 'a-z', display: 'Alphabetical A-Z' },
    { value: 'z-a', display: 'Alphabetical Z-A' },
  ]

  return (
    <Layout showSideNav topNavLeft={topNavLeft}>
      <Box px={6} pt={6} mb={8}>
        <Text textStyle='h2' color='black.1'>
          Completion Time
        </Text>
        <RespondentCount
          respondentCount={respondentCount}
          totalCount={totalCount}
        />
      </Box>
      <Container
        maxW={{ base: '100%', md: '456px', lg: '500px', xl: '1080px' }}
        px={0}
        mb={6}
      >
        <Box
          h='496px'
          bg='white.1'
          borderRadius='12px'
          borderWidth='0.5px'
          borderStyle='solid'
          borderColor='primary.2'
          py={7}
          pr={5}
          pl={8}
        >
          <Flex align='center' justify='center' w='100%' h='100%'>
            {finalGraph ? (
              <ResponsiveContainer>
                <BarChart
                  data={finalGraph?.graphData}
                  layout='horizontal'
                  margin={{ left: -15, bottom: 10 }}
                >
                  <YAxis
                    dataKey='amt'
                    type='number'
                    allowDecimals={false}
                    style={{
                      ...textStyles.body['2'],
                      fontWeight: 400,
                      color: '#010002',
                    }}
                  >
                    <Label
                      value='No. of students'
                      position='insideTop'
                      angle={-90}
                      style={{
                        ...textStyles.body['2'],
                        fontWeight: 400,
                        fill: '#0100028f',
                      }}
                      dy={50}
                    />
                  </YAxis>

                  <XAxis
                    type='category'
                    dataKey='roundedCompletionTime'
                    style={{
                      ...textStyles.body['2'],
                      fontWeight: 400,
                      color: '#010002',
                    }}
                  >
                    <Label
                      value='Completion Time (mins)'
                      position='insideBottomRight'
                      style={{
                        ...textStyles.body['2'],
                        fontWeight: 400,
                        fill: '#0100028f',
                      }}
                      offset={0}
                    />
                  </XAxis>
                  <Bar dataKey='amt' barSize={60} fill='#CE1032' />
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <Text
                textStyle='body.2'
                color='black.3'
                fontSize='16px'
                fontWeight='600'
              >
                Not enough data
              </Text>
            )}
          </Flex>
        </Box>
      </Container>
      <Box px={6} py={4}>
        <Select
          value={dataDisplay}
          options={dataOption}
          onChange={setDataDisplay}
          menuButtonProps={{
            as: Button,
            rightIcon: <IoChevronDown />,
            textStyle: 'button',
            variant: 'tab.selector',
            p: '8px 12px',
            textAlign: 'left',
            letterSpacing: '0.06rem',
            w: 'auto',
            textTransform: 'uppercase',
            layerStyle: 'card.module',
            mr: 4,
            color: 'primary.1',
          }}
          menuItemProps={{
            textStyle: 'body.1',
            color: 'primary.1',
            textTransform: 'uppercase',
            w: '196px',
            _hover: { bg: 'hover.2', color: 'white.1' },
          }}
          adjustRightIconPadding='1rem'
        />
        <Select
          value={sortBy}
          onChange={(value) => setSortBy(value)}
          options={sortOptions}
          menuButtonProps={{
            textStyle: 'button',
            variant: 'tab.selector',
            textAlign: 'left',
            py: '8px',
            pr: '8px',
            w: '196px',
            layerStyle: 'card.module',
            color: 'primary.1',
          }}
          menuItemProps={{
            textStyle: 'body.1',
            color: 'primary.1',
            w: '196px',
            _hover: { bg: 'hover.2', color: 'white.1' },
          }}
          displayTransform={gradesSortDisplay}
        />
      </Box>
      {dataDisplay === 'students' && (
        <IndividualCompletionAnalyticsTable
          students={students}
          sortBy={sortBy}
        />
      )}
      {dataDisplay === 'groups' && (
        <GroupCompletionAnalyticsTable groups={groups} sortBy={sortBy} />
      )}
    </Layout>
  )
}

export default AssignmentCompletionTimePage
