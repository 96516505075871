import { Route, useRouteMatch, matchPath, useLocation } from 'react-router'
import { Layout } from '../../components/Layout'
import ClassAnalyticsPage from './ClassAnalyticsPage'
import StudentsAnalyticsPage from './StudentsAnalyticsPage'
import TopicsPage from './TopicsPage'
import PreCourseTestPage from './PreCourseTestPage'
import CompletionTimePage from './CompletionTimePage'
import AssignmentPage from './AssignmentPage'
import AssignmentCompletionTimePage from './AssignmentCompletionTimePage'
import AssignmentScorePage from './AssignmentScorePage'
import AssignmentAssignmentReviewPage from './AssignmentAssignmentReviewPage'
import AssignmentLearningReviewPage from './AssignmentLearningReviewPage'
import AssignmentReviewQuestionPage from './AssignmentReviewQuestionPage'
import LearningReviewQuestionPage from './LearningReviewQuestionPage'
import NoTopicPage from './NoTopicPage'
import { useAlamContext } from '../../client'
import { useHistory, useParams } from 'react-router-dom'
import { Topic } from '../../types'

export type AnalyticsPageProps = {
  classId: string
}

const AnalyticsPage = ({ classId }: AnalyticsPageProps): JSX.Element => {
  const topNavLeft = [{ type: 'text' as const, text: 'Analytics' }]
  const { url, path } = useRouteMatch()
  const { id } = useParams<{ id: string }>()

  const { classUser } = useAlamContext()
  const history = useHistory()
  const classTopics = classUser?.class?.topics || []
  const topicsFromClass = classTopics.map((topic: Topic) => ({
    value: topic.id,
    display: topic.name,
  }))
  const defaultTopic = { value: 'default', display: 'OVERVIEW' }
  const noTopic = { value: 'no-topic', display: 'NO-TOPIC' }
  const topicOptions = [defaultTopic, noTopic, ...topicsFromClass]
  const topicRedirectPath = `${url}/class/topic`
  const handleTopicChange = (topicId: string) => {
    if (topicId === 'default') {
      history.push(`/classes/${id}/analytics/class`)
    } else if (topicId === 'no-topic') {
      history.push(`/classes/${id}/analytics/class/no-topic`)
    } else {
      history.push(`${topicRedirectPath}/${topicId}`)
    }
  }

  const topNavCenter = [
    {
      type: 'link' as const,
      url: `${url}/class`,
      text: 'Class',
      isExact: false,
    },
    { type: 'link' as const, url: `${url}/students`, text: 'Students' },
  ]

  return (
    <>
      <Route path={`${path}/class/topic/:topic_id`} exact>
        <TopicsPage
          topNavLeft={topNavLeft}
          topNavCenter={topNavCenter}
          topicOptions={topicOptions}
          handleTopicChange={handleTopicChange}
        />
      </Route>
      <Route path={`${path}/class/no-topic`} exact>
        <NoTopicPage
          topNavLeft={topNavLeft}
          topNavCenter={topNavCenter}
          topicOptions={topicOptions}
          handleTopicChange={handleTopicChange}
        />
      </Route>
      <Route
        path={`${path}/class/assignment/:assignment_id/completion-time`}
        exact
      >
        <AssignmentCompletionTimePage />
      </Route>
      <Route path={`${path}/class/assignment/:assignment_id/score`} exact>
        <AssignmentScorePage />
      </Route>
      <Route
        path={`${path}/class/assignment/:assignment_id/assignment-review/question/:question_id`}
        exact
      >
        <AssignmentReviewQuestionPage />
      </Route>
      <Route
        path={`${path}/class/assignment/:assignment_id/learning-review/question/:question_id`}
        exact
      >
        <LearningReviewQuestionPage />
      </Route>
      <Route
        path={`${path}/class/assignment/:assignment_id/learning-review`}
        exact
      >
        <AssignmentLearningReviewPage />
      </Route>
      <Route
        path={`${path}/class/assignment/:assignment_id/assignment-review`}
        exact
      >
        <AssignmentAssignmentReviewPage />
      </Route>
      <Route path={`${path}/class/assignment/:assignment_id`} exact>
        <AssignmentPage />
      </Route>
      <Route
        path={`${path}/class/topic/:topic_id/assignment/:assignment_id`}
        exact
      >
        <AssignmentPage />
      </Route>
      <Route
        path={`${path}/class/topic/:topic_id/assignment/:assignment_id/assignment-review/question/:question_id`}
        exact
      >
        <AssignmentReviewQuestionPage />
      </Route>
      <Route
        path={`${path}/class/topic/:topic_id/assignment/:assignment_id/learning-review/question/:question_id`}
        exact
      >
        <LearningReviewQuestionPage />
      </Route>
      <Route
        path={`${path}/class/topic/:topic_id/assignment/:assignment_id/learning-review`}
        exact
      >
        <AssignmentLearningReviewPage />
      </Route>
      <Route
        path={`${path}/class/topic/:topic_id/assignment/:assignment_id/assignment-review`}
        exact
      >
        <AssignmentAssignmentReviewPage />
      </Route>
      <Route
        path={`${path}/class/topic/:topic_id/assignment/:assignment_id/score`}
        exact
      >
        <AssignmentScorePage />
      </Route>
      <Route
        path={`${path}/class/topic/:topic_id/assignment/:assignment_id/completion-time`}
        exact
      >
        <AssignmentCompletionTimePage />
      </Route>
      <Route path={`${path}/class/topic/:topic_id/completion-time`} exact>
        <CompletionTimePage />
      </Route>
      <Route path={`${path}/class/topic/:topic_id/pre-course-test`} exact>
        <PreCourseTestPage />
      </Route>
      <Route path={`${path}/class`} exact>
        <ClassAnalyticsPage
          topNavLeft={topNavLeft}
          topNavCenter={topNavCenter}
          topicOptions={topicOptions}
          handleTopicChange={handleTopicChange}
        />
      </Route>
      <Route path={`${path}/students`} exact>
        <StudentsAnalyticsPage
          topNavLeft={topNavLeft}
          topNavCenter={topNavCenter}
        />
      </Route>
    </>
  )
}

export default AnalyticsPage
