import { useMutation } from '@apollo/client'
import { Box, Flex, Heading, IconButton } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaUserPlus } from 'react-icons/fa'
import { useAlamContext } from '../../client'
import { DELETE_SESSION_USER } from '../../client/queries'
import { documentPermission } from '../../pages/Session/SessionPage'
import { Session, SessionUser } from '../../types'
import { useErrorToast, useSuccessToast } from '../../utils'
import { deletePermission, permissionType, useGoogleAPI } from '../GoogleAPI'
import { AddPeople } from '../Icons'
import { RenderForUser } from '../SharedComponents'
import { CustomListBox } from '../Styled'
import { SessionMember } from './index'

type SessionMembersProps = {
  onOpenAddSessionMembers: () => void
  session?: Session
  session_users?: SessionUser[]
  refetch: () => void
  documentsPermissions: documentPermission[]
  setDocumentsPermissions: (documentsPermission: documentPermission[]) => void
}

export const SessionMembers = ({
  onOpenAddSessionMembers,
  session,
  session_users = [],
  refetch,
  documentsPermissions,
  setDocumentsPermissions,
}: SessionMembersProps): JSX.Element => {
  const [sessionUsers, setSessionUsers] = useState<SessionUser[]>(session_users)
  const [permissionIsDeleting, setPermissionIsDeleting] = useState(false)
  const { isAuthorized, tokenIsExpired } = useGoogleAPI()
  const { user } = useAlamContext()

  useEffect(() => {
    setSessionUsers(session_users)
  }, [session_users, setSessionUsers])

  const [deleteSessionUser, { loading: sessionUserIsDeleting }] = useMutation(
    DELETE_SESSION_USER
  )
  const handleDeleteMember = async (id: string, closeModal: () => void) => {
    setPermissionIsDeleting(true)

    try {
      // optimistic rendering
      const newSessionUsers = sessionUsers.filter((sessionUser) => {
        return sessionUser.id !== id
      })
      setSessionUsers(newSessionUsers)

      const response = await deleteSessionUser({
        variables: { session_user_id: id },
      })

      // this also contains the provider which you can check if === 'google'
      const { email } = response.data.delete_session_users_by_pk.user

      await deleteMemberPermissions({
        documentsPermissions,
        deletedEmail: email,
      })

      setPermissionIsDeleting(false)
      closeModal()
      refetch()

      useSuccessToast({ message: 'Session member was successfully removed' })
    } catch (error) {
      useErrorToast({ error })
    }
  }

  // loop through documentsPermissions
  // check if deleted user is owner
  // if owner, delete all non owner permissions
  // if not owner, find deleted user's permissions and delete that
  // WARNING: user can't delete permissions of files he doesn't own (needs service account)
  // You can only delete your permissions (permission.emailAddress === email)
  // or permissions of other people in a file which you are the 'owner'
  // https://stackoverflow.com/questions/49098484/how-to-delete-a-file-owned-by-another-user
  const deleteMemberPermissions = async ({
    documentsPermissions,
    deletedEmail,
  }: {
    documentsPermissions: documentPermission[]
    deletedEmail: string
  }) => {
    try {
      const newDocumentPermissions = await Promise.all(
        documentsPermissions.map(async (document) => {
          const { permissions, user: documentOwner, file_id: fileId } = document
          let permissionsToDelete: permissionType[] = []
          let remainingPermissions: permissionType[] = []

          // deleted user is owner: delete your permission to file
          if (documentOwner.email === deletedEmail) {
            permissionsToDelete = permissions.filter(
              (permission) =>
                permission.emailAddress === user?.email && !permission.deleted
            )

            remainingPermissions = permissions.filter(
              (permission) =>
                permission.emailAddress !== user?.email && !permission.deleted
            )

            // you are owner: delete deleted user's permission to file
          } else if (documentOwner.id === user?.id) {
            permissionsToDelete = permissions.filter(
              (permission) => permission.emailAddress === deletedEmail
            )

            remainingPermissions = permissions.filter(
              (permission) => permission.emailAddress !== deletedEmail
            )
          } else {
            // somebody else is owner: do nothing. lol
            remainingPermissions = permissions
          }

          await Promise.all(
            permissionsToDelete.map(async (permission: permissionType) => {
              return await deletePermission({
                fileId,
                permissionId: permission.id,
              })
            })
          )

          return {
            ...document,
            permissions: remainingPermissions,
          }
        })
      )

      setDocumentsPermissions(newDocumentPermissions)
    } catch (error) {
      return error
    }
  }

  return (
    <Box w='100%' layerStyle='card.board-updated-shadow' bg='white.1'>
      <Flex p={6} align='center'>
        <Heading textStyle='h3' w='100%' color='primary.1'>
          Session Members ({sessionUsers.length})
        </Heading>

        <RenderForUser userIds={[session?.user_id || '']}>
          <Box>
            <IconButton
              icon={<AddPeople />}
              aria-label='Add Session Members'
              variant='icon.button.md'
              onClick={onOpenAddSessionMembers}
              fontSize='1.25rem'
            />
          </Box>
        </RenderForUser>
      </Flex>

      <Box px={6} pb={6}>
        <Box
          width='100%'
          borderRadius='12px'
          border='0.5px solid'
          borderColor='grey.border'
          p='16px'
          bg='#EDECF0'
        >
          <CustomListBox>
            {sessionUsers.map((sessionUser) => (
              <SessionMember
                key={sessionUser.id}
                sessionUser={sessionUser}
                creatorId={session?.user_id}
                sessionName={session?.name}
                handleDeleteMember={handleDeleteMember}
                isDeleting={sessionUserIsDeleting || permissionIsDeleting}
              />
            ))}
          </CustomListBox>
        </Box>
      </Box>
    </Box>
  )
}

export default SessionMembers
