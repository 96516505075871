import { createFile, createFileProps, search } from '.'

export const createDirectory = async ({
  name,
  mimeType = 'application/vnd.google-apps.folder',
  parents = [],
}: createFileProps): Promise<string> => {
  try {
    const fileId = await createFile({ name, mimeType, parents })
    return fileId
  } catch (error) {
    return error
  }
}

type createFolderStructureProps = {
  folderNames: string[]
}
export const createFolderStructure = async ({
  folderNames,
}: createFolderStructureProps) => {
  try {
    return await createFolder({ foldersToCreate: folderNames, fileIds: [] })
  } catch (error) {
    return error
  }
}

type createFolderProps = {
  foldersToCreate: string[]
  fileIds: string[]
}

// recursively check/create folder inside foldersToCreate array
// return array of fileIds found/created
export const createFolder = async ({
  foldersToCreate,
  fileIds,
}: createFolderProps): Promise<string[]> => {
  try {
    if (foldersToCreate.length >= 1) {
      const [folderName, ...rest] = foldersToCreate
      const directParent = fileIds[fileIds.length - 1]
      const query = `name = '${folderName}'${
        directParent ? ` and parents in '${directParent}'` : ''
      } and mimeType = 'application/vnd.google-apps.folder' and trashed = false`
      const res = await search({ query })

      // folder already exists
      if (res?.result?.files[0]) {
        const fileId = res?.result?.files[0].id

        return createFolder({
          foldersToCreate: rest,
          fileIds: [...fileIds, fileId],
        })
      } else {
        // folder doesn't exist. create it
        const parents = fileIds.length >= 1 ? [fileIds[fileIds.length - 1]] : []
        const fileId = await createDirectory({ name: folderName, parents })
        return createFolder({
          foldersToCreate: rest,
          fileIds: [...fileIds, fileId],
        })
      }
    } else {
      return fileIds
    }
  } catch (error) {
    return error
  }
}
