import React from 'react'
import {
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  MenuDivider,
  Checkbox,
  Flex,
  Text,
  Tooltip,
  Box,
} from '@chakra-ui/react'
import { IoChevronDown } from 'react-icons/io5'

type DropdownCheckbox = {
  menuButtonProps?: Record<string, any>
  menuItemProps?: Record<string, any>
  selectedDisplayProps?: Record<string, any>
  values: Array<string>
  onChange: (val: string[]) => void
  options: Array<{ value: string; display: string | JSX.Element }>
  allTitle: string
  emptyTitle: string
  indeterminateTitle: string
  isTruncated?: boolean
  isTopicDropdown?: boolean
}

export const DropdownCheckbox = ({
  values,
  menuButtonProps = {},
  menuItemProps = {},
  selectedDisplayProps = {},
  onChange,
  allTitle,
  options,
  emptyTitle,
  indeterminateTitle,
  isTruncated,
  isTopicDropdown,
}: DropdownCheckbox) => {
  const getSelectedDisplay = () => {
    if (isEverythingChecked) {
      return allTitle
    }

    if (values.length === 0) {
      return emptyTitle
    }

    if (values.length === 1) {
      const selected = options.find(({ value }) => values[0] === value)
      return selected?.display
    }

    return indeterminateTitle
  }

  const isEverythingChecked = options.every((option) =>
    values.includes(option.value)
  )

  const toggleEverything = () => {
    const newValues = isEverythingChecked
      ? []
      : options.map(({ value }) => value)

    onChange(newValues)
  }

  const handleChange = (checked: boolean, item: string) => {
    if (checked) {
      onChange([...values, item])
    } else {
      const newValues = values.filter((val) => val !== item)
      onChange(newValues)
    }
  }

  const isChecked = (item: string) => values.includes(item)
  const showCount =
    values.length > 1 && isTopicDropdown && values.length !== options.length

  return (
    <Menu
      autoSelect={false}
      closeOnSelect={false}
      placement='bottom-start'
      flip={false}
    >
      <Tooltip
        label={getSelectedDisplay()}
        aria-label='topic-long-name'
        placement='top-start'
      >
        <MenuButton
          as={Button}
          rightIcon={<IoChevronDown />}
          variant='input'
          {...menuButtonProps}
          css={{
            '.chakra-button__icon': {
              marginInlineStart: '2rem',
            },
          }}
          position='relative'
        >
          <Text
            w={isTruncated ? '18ch' : '100%'}
            isTruncated={isTruncated}
            {...selectedDisplayProps}
          >
            {getSelectedDisplay()}
          </Text>

          {showCount && (
            <Box
              fontSize='14px'
              fontWeight='600'
              w='24px'
              h='24px'
              borderRadius='50%'
              position='absolute'
              top='50%'
              bottom='50%'
              transform='translate(-50%, -50%)'
              right='20px'
              display='flex'
              alignItems='center'
              justifyContent='center'
              color='#fff'
              bg='primary.1'
            >
              {values.length}
            </Box>
          )}
        </MenuButton>
      </Tooltip>

      <MenuList position='relative'>
        <MenuItem layerStyle='menu.item.checkbox'>
          <Checkbox isChecked={isEverythingChecked} onChange={toggleEverything}>
            <Flex {...menuItemProps}>{allTitle}</Flex>
          </Checkbox>
        </MenuItem>

        {options.map(({ value, display }, index) => {
          return (
            <React.Fragment key={index}>
              <MenuDivider borderColor='grey.border' />
              <MenuItem value={value} layerStyle='menu.item.checkbox.indented'>
                <Checkbox
                  isChecked={isChecked(value)}
                  onChange={(e) => handleChange(e.target.checked, value)}
                >
                  <Tooltip
                    label={display}
                    aria-label='topic-long-name'
                    placement='top-start'
                  >
                    <Flex {...menuItemProps}>
                      <Text
                        w={isTruncated ? '12ch' : '100%'}
                        isTruncated={isTruncated}
                        fontSize='14px'
                      >
                        {display}
                      </Text>
                    </Flex>
                  </Tooltip>
                </Checkbox>
              </MenuItem>
            </React.Fragment>
          )
        })}
      </MenuList>
    </Menu>
  )
}
