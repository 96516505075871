import { Flex, Box, Text, IconButton, useDisclosure } from '@chakra-ui/react'
import { FaAddressCard, FaUserTimes } from 'react-icons/fa'
import { getFullName, getUserRole, getFirstAndLastInitial } from '../../utils'
import { AlamAvatar } from '../Avatar'
import { SessionUser } from '../../types'
import { RenderForUser } from '../SharedComponents'
import { UserProfile, ConfirmationModal } from '../Modal'
import { AccountRemove, UserProfile as UserProfileIcon } from '../Icons'

export type SessionMemberProps = {
  sessionUser: SessionUser
  creatorId?: string
  sessionName?: string
  handleDeleteMember: (id: string, callback: () => void) => void
  isDeleting: boolean
}

export const SessionMember = ({
  sessionUser: { user, id = '' },
  creatorId = '',
  sessionName = '',
  handleDeleteMember,
  isDeleting,
}: SessionMemberProps): JSX.Element => {
  const name = getFullName(user)
  const {
    isOpen: userProfileIsOpen,
    onOpen: userProfileOnOpen,
    onClose: userProfileOnClose,
  } = useDisclosure()
  const {
    isOpen: removeUserIsOpen,
    onOpen: removeUserOnOpen,
    onClose: removeUserOnClose,
  } = useDisclosure()

  const onRemoveSessionMember = () => {
    handleDeleteMember(id, () => removeUserOnClose())
  }

  return (
    <>
      <UserProfile
        user={user}
        isOpen={userProfileIsOpen}
        onClose={userProfileOnClose}
      />
      <ConfirmationModal
        isOpen={removeUserIsOpen}
        onClose={removeUserOnClose}
        title='Are you sure?'
        message={`${name} will no longer be able to access ${sessionName}. This process is irreversible, and all the data and content will be removed and no longer accessible.`}
        onSubmit={onRemoveSessionMember}
        isSubmitting={isDeleting}
      />

      <Flex justify='space-between' key={user.id}>
        <Flex align='center'>
          <AlamAvatar
            mr='16px'
            size='md'
            name={getFirstAndLastInitial(user)}
            src={user?.avatar}
          />
          <Box>
            <Text textStyle='body.semibold' fontSize='16px' color='primary.1'>
              {name}
            </Text>
            <Text
              textStyle='body.2'
              fontSize='14px'
              color='grey.1'
              textTransform='capitalize'
            >
              {getUserRole(user)}
            </Text>
          </Box>
        </Flex>

        <Flex>
          <Box>
            <IconButton
              icon={
                <UserProfileIcon width='20' height='20' viewBox='8 8 24 24' />
              }
              aria-label={`View ${name}'s Profile`}
              variant='icon.button.md'
              onClick={userProfileOnOpen}
              fontSize='1.25rem'
            />
          </Box>

          {creatorId !== user.id && (
            <RenderForUser userIds={[creatorId]}>
              <Box>
                <IconButton
                  icon={<AccountRemove />}
                  aria-label='Delete Members'
                  variant='icon.button.md'
                  onClick={removeUserOnOpen}
                  fontSize='1.25rem'
                />
              </Box>
            </RenderForUser>
          )}
        </Flex>
      </Flex>
    </>
  )
}

export default SessionMember
