import { useEffect } from 'react'
import { MdSend } from 'react-icons/md'
import { validateSpaceOnly } from '../../utils'
import { useForm } from 'react-hook-form'
import debounce from 'lodash-es/debounce'
import {
  Flex,
  InputGroup,
  Input,
  IconButton,
  InputRightElement,
} from '@chakra-ui/react'

export type ChatInput = {
  text: string
}

export type ChatInputProps = {
  defaultValues?: { text: string }
  onSubmit: (data: ChatInput) => void
  isSubmitting: boolean
  iconButtonProps?: Record<string, any>
  inputGroupProps?: Record<string, any>
  inputProps?: Record<string, any>
}

export const ChatInput = ({
  defaultValues = { text: '' },
  onSubmit,
  isSubmitting,
  iconButtonProps = {},
  inputGroupProps = {},
  inputProps = {},
}: ChatInputProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    reset,
    trigger,
    formState: { isSubmitSuccessful, isValid },
  } = useForm({
    defaultValues,
    mode: 'onChange',
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ ...defaultValues })
    }
  }, [reset, isSubmitSuccessful, defaultValues])

  return (
    <Flex w='100%' position='sticky' bottom='0px' right='0'>
      <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
        <InputGroup {...inputGroupProps}>
          <Input
            name='text'
            ref={register({
              required: 'Message is a required field',
              validate: {
                validateSpaceOnly,
              },
            })}
            placeholder='Type your message here...'
            border='0.5px solid'
            borderColor='primary.2-5'
            size='lg'
            pl='22px'
            textStyle='body.1'
            w='100%'
            fontSize='16px'
            lineHeight='24px'
            borderRadius='0'
            h='56px'
            _focus={{
              borderColor: 'primary.2',
              bg: 'grey.2',
            }}
            onChange={debounce(async () => {
              await trigger('text')
            }, 500)}
            {...inputProps}
          />

          <InputRightElement
            h='100%'
            alignItems='center'
            className='right-element'
          >
            <IconButton
              className='icon-button'
              type='submit'
              visibility={isValid ? 'visible' : 'hidden'}
              aria-label='submit post'
              variant='icon.button'
              isDisabled={!isValid}
              bg='grey.2'
              icon={<MdSend />}
              isLoading={isSubmitting}
              {...iconButtonProps}
            />
          </InputRightElement>
        </InputGroup>
      </form>
    </Flex>
  )
}

export default ChatInput
