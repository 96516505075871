import React from 'react'
import { IconAlamProps } from './Type'

export const ChevronDown = ({
  fill,
  width,
  height,
  viewBox,
}: IconAlamProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M15.41 16L20 20.59L24.59 16L26 17.42L20 23.42L14 17.42L15.41 16Z'
      fill={fill}
    />
  </svg>
)

ChevronDown.defaultProps = {
  fill: '#17053D',
  width: '40',
  height: '40',
  viewBox: '0 0 40 40',
}
