import { isLargerThanTablet } from '../../utils'
import { IconButton } from '@chakra-ui/react'
import { MdClose } from 'react-icons/md'

export const parentHoverCss = {
  position: 'relative',
  '&:hover .hover-close': {
    visibility: 'visible',
  },
} as const

export type HoverCloseProps = {
  buttonVariant?: string
  buttonProps?: Record<string, string>
  onClick: () => void
}

export const HoverClose = ({
  buttonVariant = 'icon.button',
  buttonProps = {},
  onClick,
}: HoverCloseProps): JSX.Element => {
  return (
    <IconButton
      icon={<MdClose />}
      aria-label='close or delete button'
      variant={buttonVariant}
      className='hover-close'
      visibility={isLargerThanTablet() ? 'hidden' : 'visible'}
      onClick={onClick}
      {...buttonProps}
    />
  )
}

export default HoverClose
