import { useMemo } from 'react'
import { Route, useRouteMatch } from 'react-router'
import { Link } from 'react-router-dom'
import ProfileInfoPage from './ProfileInfoPage'
import AccountPage from './AccountPage'
import PasswordPage from './PasswordPage'
import NotificationsPage from './NotificationsPage'
import { Layout, LayoutProps } from '../../components/Layout'
import { useAlamContext } from '../../client'
import { TopNavItemProps as TopNavItemPropsBasic } from '../../components/TopNav'
import { GET_USER } from '../../client/queries'
import { useQuery } from '@apollo/client'
import { Logo } from '../../components/Logo'

interface TopNavItemProps extends TopNavItemPropsBasic {
  text: string
}

export interface AccountSettingsPageProps {
  fromHome: boolean
}

interface Condition {
  condition: boolean
  exclusion: string[]
}

const AccountSettingsPage = ({
  fromHome = false,
}: AccountSettingsPageProps): JSX.Element => {
  const { path, url } = useRouteMatch()
  const { user, setUser } = useAlamContext()

  useQuery(GET_USER, {
    variables: {
      email: user.email,
    },
    onCompleted: ({ users }) => {
      setUser(users[0])
    },
  })

  const topNavLeft = fromHome
    ? [
        {
          type: 'reactElement' as const,
          children: (
            <Link to='/'>
              <Logo />
            </Link>
          ),
        },
      ]
    : [{ type: 'text' as const, text: 'Account Settings' }]
  const topNavCenterAll = [
    { type: 'link' as const, url: `${url}/profile-info`, text: 'Profile Info' },
    {
      type: 'link' as const,
      url: `${url}/account`,
      text: 'Account',
    },
    {
      type: 'link' as const,
      url: `${url}/password`,
      text: 'Password',
    },
    {
      type: 'link' as const,
      url: `${url}/notifications`,
      text: 'Notifications',
    },
  ]

  const constructNavExclusion = () => {
    const googleExclusion = ['Account', 'Password']
    const isHomeSettingsExclusion = ['Notifications']
    const conditions = [
      {
        condition: fromHome,
        exclusion: isHomeSettingsExclusion,
      },
      {
        condition: user.provider === 'google',
        exclusion: googleExclusion,
      },
    ]

    const navExclusions = conditions.reduce(
      (exclusions: string[], condition: Condition): string[] => {
        if (condition.condition) {
          return [...exclusions, ...condition.exclusion]
        }
        return exclusions
      },
      []
    )

    return navExclusions
  }

  const constructNav = (
    navItems: TopNavItemProps[],
    navExclusions: string[]
  ): TopNavItemProps[] => {
    return navItems.filter(({ text }: TopNavItemProps): boolean => {
      return !navExclusions.includes(text)
    })
  }

  const topNavCenter = useMemo(() => {
    if (!user) return []
    return constructNav(topNavCenterAll, constructNavExclusion())
  }, [user])

  const layoutProps: Omit<LayoutProps, 'children'> = fromHome
    ? {
        topNavLeft,
        homeSettingsNav: topNavCenter,
        hideRightIcons: true,
        topNavColor: 'primary',
      }
    : {
        showSideNav: true,
        topNavLeft,
        topNavCenter: topNavCenter,
        hasRightNav: true,
      }

  return (
    <Layout {...layoutProps}>
      {user && user.provider !== 'google' && (
        <>
          <Route path={`${path}/account`} exact>
            <AccountPage topNavLeft={topNavLeft} topNavCenter={topNavCenter} />
          </Route>
          <Route path={`${path}/password`} exact>
            <PasswordPage topNavLeft={topNavLeft} topNavCenter={topNavCenter} />
          </Route>
        </>
      )}
      <Route path={`${path}/profile-info`} exact>
        <ProfileInfoPage topNavLeft={topNavLeft} topNavCenter={topNavCenter} />
      </Route>
      <Route path={`${path}/notifications`} exact>
        <NotificationsPage
          topNavLeft={topNavLeft}
          topNavCenter={topNavCenter}
        />
      </Route>
    </Layout>
  )
}

export default AccountSettingsPage
