const submissionStatus = ['unsubmitted', 'submitted', 'graded', 'returned']

export type submissionStatusType = {
  status?: string
  can: {
    unsubmitted: boolean
    submitted: boolean
    graded: boolean
    returned: boolean
    showReturn: boolean
    inputGrade: boolean
    privateComments: boolean
    showSubmission: boolean
  }
}

// can.inputGrade doesn't really disable the grade input, but only styles it as disabled
export const initialState: submissionStatusType = {
  status: undefined,
  can: {
    unsubmitted: false,
    submitted: false,
    graded: false,
    returned: false,
    showReturn: false,
    inputGrade: false,
    privateComments: false,
    showSubmission: false,
  },
}

const submissionStates = {
  unsubmitted: {
    unsubmitted: false,
    submitted: true,
    graded: false,
    returned: false,
    showReturn: false,
    inputGrade: true,
    privateComments: false,
    showSubmission: false,
  },
  submitted: {
    unsubmitted: true,
    submitted: false,
    graded: false,
    returned: true,
    showReturn: true,
    inputGrade: true,
    privateComments: false,
    showSubmission: true,
  },
  graded: {
    unsubmitted: false,
    submitted: false,
    graded: false,
    returned: true,
    showReturn: true,
    inputGrade: false,
    privateComments: false,
    showSubmission: true,
  },
  returned: {
    unsubmitted: false,
    submitted: true,
    graded: false,
    returned: false,
    showReturn: true,
    inputGrade: false,
    privateComments: true,
    showSubmission: true,
  },
}

export const reducer = (
  state: submissionStatusType,
  action: string
): submissionStatusType => {
  switch (action) {
    case 'unsubmitted':
      return {
        ...state,
        status: 'unsubmitted',
        can: submissionStates.unsubmitted,
      }
    case 'submitted':
      return {
        ...state,
        status: 'submitted',
        can: submissionStates.submitted,
      }
    case 'graded':
      return {
        ...state,
        status: 'graded',
        can: submissionStates.graded,
      }
    case 'returned':
      return {
        ...state,
        status: 'returned',
        can: submissionStates.returned,
      }
    default:
      return initialState
  }
}
