import { gql } from '@apollo/client'

export const GET_ORGANIZATION_FROM_USER = gql`
  subscription GET_ORGANIZATION_FROM_USER($user_id: uuid) {
    organizations(
      limit: 1
      where: {
        users: { id: { _eq: $user_id } }
        status: { _in: ["ACTIVATED", "PENDING"] }
      }
    ) {
      id
      status
    }
  }
`
