import styled from '@emotion/styled'

export const DatePickerBox = styled.div(() => ({
  border: '0.5px solid',
  borderColor: 'rgba(23, 5, 61, 0.32)',
  borderRadius: '8px',
  height: '48px',
  background: '#EDECF0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '> .react-datepicker-wrapper': {
    width: '100%',
  },
}))
