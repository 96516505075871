import { useMemo } from 'react'
import {
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  Text,
} from '@chakra-ui/react'
import { Option } from '../../types'
import { IoChevronDown } from 'react-icons/io5'

export interface SelectProps {
  value?: string
  options: Array<{ value: string; display: string }>
  placeholder?: string
  onChange: (val: string) => void
  menuButtonProps?: Record<string, any>
  menuItemProps?: Record<string, any>
  menuListProps?: Record<string, any>
  displayTransform?: (option: Option) => string
  adjustRightIconPadding?: string
  hasTextDisplay?: boolean
  textDisplayProps?: Record<string, any>
}

export const Select = ({
  value = '',
  options,
  placeholder,
  onChange,
  menuButtonProps = {},
  menuItemProps = {},
  menuListProps = {},
  displayTransform,
  adjustRightIconPadding,
  hasTextDisplay = false,
  textDisplayProps = {},
}: SelectProps) => {
  const val = () => {
    if (!value) return placeholder

    const currentOption = options.find((option) => option.value === value)

    if (displayTransform && currentOption) {
      return displayTransform(currentOption)
    } else if (value) {
      return currentOption?.display
    } else {
      return placeholder
    }
  }

  return (
    <Menu autoSelect={false} placement='bottom-start' flip={false}>
      <MenuButton
        as={Button}
        rightIcon={<IoChevronDown />}
        variant='input'
        {...menuButtonProps}
        css={{
          '.chakra-button__icon': {
            marginInlineStart: adjustRightIconPadding
              ? adjustRightIconPadding
              : '0.5rem',
          },
        }}
      >
        {hasTextDisplay ? <Text {...textDisplayProps}>{val()}</Text> : val()}
      </MenuButton>

      <MenuList {...menuListProps}>
        <MenuOptionGroup type='radio' value={value}>
          {placeholder && (
            <MenuItemOption
              key='placeholder'
              value={''}
              onClick={() => onChange('')}
              icon={<></>}
              _checked={{ bg: 'primary.1', color: 'white.1' }}
              _hover={{ bg: 'hover.2', color: 'white.1' }}
              {...menuItemProps}
            >
              {placeholder}
            </MenuItemOption>
          )}
          {options.map((option, index) => {
            return (
              <MenuItemOption
                key={index}
                value={option.value}
                onClick={() => onChange(option.value)}
                icon={<></>}
                _checked={{ bg: 'primary.1', color: 'white.1' }}
                _hover={{ bg: 'hover.2', color: 'white.1' }}
                {...menuItemProps}
              >
                {option.display}
              </MenuItemOption>
            )
          })}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
}

export default Select
