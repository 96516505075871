import { Box } from '@chakra-ui/react'
import { Message } from '../Session/Message'
import { Message as MessageType } from '../../types'
import { format } from 'date-fns'

export type RenderGroupMessagesProp = {
  groupDate: string
  messages: any
}

export const RenderGroupMessages = ({
  groupDate,
  messages,
}: RenderGroupMessagesProp): JSX.Element => {
  const today = new Date()
  const formattedToday = format(today, 'y-MM-d')
  const yesterday = new Date(Date.now() - 86400000).toISOString().split('T')[0]
  const currentYear = today.getFullYear()

  const formattedGroupDate = (groupDate: string) => {
    if (groupDate === formattedToday) return 'Today'
    if (groupDate === yesterday) return 'Yesterday'

    const date = format(new Date(groupDate), 'd MMM')
    const year = parseInt(groupDate.slice(0, 4))
    if (currentYear > year) {
      return `${date} ${year}`
    }

    return date
  }

  return (
    <Box>
      <Box fontSize='12px' textAlign='center' color='black.4'>
        {formattedGroupDate(groupDate)}
      </Box>
      {messages.map((message: MessageType) => (
        <Message key={message.id} message={message} />
      ))}
    </Box>
  )
}

export default RenderGroupMessages
