import { GroupUser, Comment } from '../../types'
import { getFirstAndLastInitial, getFullName } from '../../utils'
import { Flex, Text, IconButton, Tooltip } from '@chakra-ui/react'
import { AlamAvatar } from '../Avatar'
import { MdChevronRight } from 'react-icons/md'

export const getLastComment = (comments: Comment[]): Comment | null => {
  if (comments.length >= 1) {
    return comments[comments.length - 1]
  }

  return null
}

interface GroupUserItem {
  handleSelectGroupUser: (groupUserId: string) => void
  groupUser: GroupUser
}

export const TeacherGroupComment = ({
  handleSelectGroupUser,
  groupUser,
}: GroupUserItem): JSX.Element => {
  const { user, comments } = groupUser
  const name = getFullName(user)
  const latestComment = getLastComment(comments)

  return (
    <Flex
      w='100%'
      h='64px'
      pl='24px'
      pr='8px'
      align='center'
      justify='space-between'
    >
      <Flex w='90%' h='100%' align='center'>
        <AlamAvatar
          size='md'
          src={user.avatar}
          name={getFirstAndLastInitial(user)}
        />
        <Tooltip label={name} aria-label={`group user ${name}`}>
          <Text
            ml='8px'
            minW='50px'
            maxW='50px'
            fontWeight='700'
            fontSize='12px'
            lineHeight='16px'
            letterSpacing='-0.01em'
            isTruncated
          >
            {name}
          </Text>
        </Tooltip>

        <Tooltip
          label={latestComment?.content}
          aria-label={`latest comment: ${latestComment?.content}`}
        >
          <Text
            ml='16px'
            textStyle='body.1'
            color={latestComment?.read_at ? 'black.3' : 'black.1'}
            isTruncated
          >
            {latestComment?.content}
          </Text>
        </Tooltip>
      </Flex>

      <IconButton
        icon={<MdChevronRight />}
        aria-label={`View ${name}'s private submission comments`}
        variant='icon.button'
        color='primary.1'
        justify='flex-end'
        fontSize='20px'
        onClick={() => handleSelectGroupUser(groupUser.id)}
      />
    </Flex>
  )
}
