import React from 'react'
import GroupPage from './GroupPage'
import { useParams } from 'react-router-dom'

const Group = (): JSX.Element => {
  const { id, group_id } = useParams<{ id: string; group_id: string }>()
  return <GroupPage classId={id} id={group_id} />
}

export default Group
