import React, { Fragment, useEffect, useMemo } from 'react'
import {
  Box,
  Flex,
  useDisclosure,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerBody,
  DrawerContent,
  useMediaQuery,
  useTheme,
  Text,
} from '@chakra-ui/react'
import SideNavLayout from './SideNavLayout'
import { TopNavItemProps, TopNav } from '../TopNav'
import { MdSettings, MdNotificationsNone, MdMenu } from 'react-icons/md'
import { People } from '../People'
import { useAlamContext } from '../../client'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import {
  ALAM_SUBSCRIPTION,
  GET_ALAM_CONTEXT,
  GET_CLASS_CHAT_UNREAD_MESSAGES,
} from '../../client/queries'
import { useLazyQuery, useSubscription } from '@apollo/client'
import { NonExistentClass } from '../Modal'
import {
  isLargerThanTablet,
  useOnlineStatus,
  checkOrganizationStatus,
} from '../../utils'
import { BsArrowBarLeft } from 'react-icons/bs'
import { useErrorToast, useSuccessToast } from '../../utils'
// @ts-ignore
import noInternet from 'no-internet'

export interface LayoutProps {
  children: React.ReactNode
  topNavCenter?: TopNavItemProps[]
  topNavLeft?: TopNavItemProps[]
  assignmentNav?: TopNavItemProps[]
  homeSettingsNav?: TopNavItemProps[]
  showPeople?: boolean
  showSideNav?: boolean
  topNavColor?: 'primary' | 'white'
  hideRightIcons?: boolean
  redirectNonExistingClass?: boolean
  hasRightNav?: boolean
  settingsIconButtonDisplay?: string
  isPretopic?: boolean
}

type ClassUserUnreadMessagesTypes = {
  user: {
    unreadMessages: {
      aggregate: {
        count: number
      }
    }
  }
}

export const Layout = ({
  children,
  topNavCenter,
  topNavLeft = [],
  assignmentNav,
  showPeople = false,
  showSideNav = false,
  topNavColor = 'white',
  hideRightIcons = false,
  redirectNonExistingClass = false,
  hasRightNav = false,
  isPretopic = false,
  settingsIconButtonDisplay,
  homeSettingsNav,
}: LayoutProps): JSX.Element => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const { breakpoints } = useTheme()
  const [isMobileDevice] = useMediaQuery([`(max-width: ${breakpoints.mobile})`])
  const {
    user,
    setClassUser,
    classUser,
    setGroups,
    setGroupUser,
    chatIsOpen,
    setChatIsOpen,
    setTeachers,
    setStudents,
    setClassTopics,
  } = useAlamContext()
  const { id, group_id } = useParams<{
    id: string
    group_id?: string
  }>()


   const test_context = useSubscription(GET_ALAM_CONTEXT, {
     variables: {
       class_id: id,
       user_id: user?.id,
       group_id,
     },
     shouldResubscribe: true,
   })


  const { data: alamContextData } = useSubscription(GET_ALAM_CONTEXT, {
    variables: {
      class_id: id,
      user_id: user?.id,
      group_id,
    },
    shouldResubscribe: true,
  })

  useEffect(() => {
    noInternet().then((offline: any) => {
      if (offline) {
        useErrorToast({ message: 'You are currently offline' })
      }
    })
  }, [])

  useEffect(() => {
    if (alamContextData) {
      const { class_users, groups } = alamContextData?.classes || {
        class_users: [],
        groups: [],
      }
      const group = groups[0]
      const { current_group_user = [] } = group || {}
      setClassUser(class_users[0])
      setGroups(groups)
      setGroupUser(current_group_user[0])
    }
  }, [id, user?.id, alamContextData])

  useOnlineStatus({ user_id: user?.id, delay: 1000 })

  checkOrganizationStatus({ user_id: user?.id })

  const { data: unreadClassChatData } = useSubscription(
    GET_CLASS_CHAT_UNREAD_MESSAGES,
    {
      variables: {
        class_id: id,
        current_user_id: user.id,
      },
    }
  )

  const {
    class_users: classUsersUnreadMessages,
  } = unreadClassChatData?.class || { class_users: [] }


  const totalUnreadMessages = useMemo(() => {
    if (classUsersUnreadMessages && classUsersUnreadMessages[0]?.user?.unreadMessages?.aggregate?.count) {
      return classUsersUnreadMessages.reduce(
        (
          acc: number,
          {
            user: {
              unreadMessages: {
                aggregate: { count },
              },
            },
          }: { user: { unreadMessages: { aggregate: { count: number } } } }
        ) => {
          return acc + count
        },
        0
      )
    }
    return 0
  }, [classUsersUnreadMessages])

  const { data: alamSubscriptionData } = useSubscription(ALAM_SUBSCRIPTION, {
    variables: {
      class_id: id,
      user_id: user.id,
    },
  })

  useEffect(() => {
    if (alamSubscriptionData) {
      setTeachers(alamSubscriptionData?.classes?.teachers || [])
      setStudents(alamSubscriptionData?.classes?.students || [])
    }
  }, [alamSubscriptionData])

  const history = useHistory()
  const { url } = useRouteMatch()

  const handleCollapseChatSidebar = () => {
    setChatIsOpen(!chatIsOpen)
  }

  const topNavRight: TopNavItemProps[] = []

  const topNavLeftElements =
    showSideNav && !isLargerThanTablet()
      ? [
          {
            type: 'reactElement' as const,
            children: (
              <IconButton
                fontSize='20px'
                aria-label='Toggle side nav button'
                variant='icon.button'
                icon={<MdMenu />}
                onClick={onToggle}
                display='inline-flex'
              />
            ),
          },
          ...topNavLeft,
        ]
      : topNavLeft

  return (
    <>
      {redirectNonExistingClass && <NonExistentClass id={id} />}

      <Flex minHeight='100vh' w='100%'>
        {/* For big screens */}
        {showSideNav && isLargerThanTablet() && (
          <SideNavLayout classUser={classUser} user={user} />
        )}

        {/* Drawer version of side nav for mobile and tablet */}
        {showSideNav && !isLargerThanTablet() && (
          <Drawer
            isOpen={isOpen}
            onClose={onClose}
            placement='left'
            size='sidenav'
          >
            <DrawerOverlay>
              <DrawerContent
                bg='primary.1'
                top={{ base: '-108px !important', md: '0px !important' }}
              >
                <DrawerBody px='0'>
                  <SideNavLayout classUser={classUser} user={user} isMobile />
                </DrawerBody>
              </DrawerContent>
            </DrawerOverlay>
          </Drawer>
        )}

        <Flex direction='column' w='100%' maxW='auto'>
          <Flex w='100%' pos='sticky' top='0' zIndex='sticky'>
            <TopNav
              left={topNavLeftElements}
              center={topNavCenter}
              right={hideRightIcons ? [] : topNavRight}
              bg={topNavColor === 'primary' ? 'primary.1' : 'white.1'}
              homeSettingsNav={homeSettingsNav}
              assignmentNav={assignmentNav}
              isPretopic={isPretopic}
            />
            {hasRightNav &&
              (totalUnreadMessages === 0 ? (
                <IconButton
                  position='absolute'
                  top={isPretopic ? 'calc(5rem + 64px)' : 20}
                  right={4}
                  boxShadow='0px 4px 8px rgba(23, 5, 61, 0.12)'
                  display={chatIsOpen ? 'none' : 'inline-flex'}
                  onClick={handleCollapseChatSidebar}
                  icon={<BsArrowBarLeft />}
                  aria-label='Toggle side chatbar/people management bar'
                  variant='icon.button'
                  maxW='32px'
                  minW='32px'
                  h='32px'
                />
              ) : (
                <Flex
                  position='absolute'
                  top={isPretopic ? 'calc(5rem + 64px)' : 20}
                  right={4}
                  boxShadow='0px 4px 8px rgba(23, 5, 61, 0.12)'
                  bg='primary.1'
                  borderRadius='6.4px'
                  w='52px'
                  align='center'
                  display={chatIsOpen ? 'none' : 'inline-flex'}
                  onClick={handleCollapseChatSidebar}
                  cursor='pointer'
                >
                  <IconButton
                    icon={<BsArrowBarLeft />}
                    aria-label='Toggle side chatbar/people management bar'
                    color='white.1'
                    bg='primary.1'
                    maxW='32px'
                    minW='32px'
                    h='32px'
                    textAlign='center'
                    _hover={{
                      bg: 'primary.1',
                      color: 'white.1',
                    }}
                    _active={{
                      bg: 'primary.1',
                      color: 'white.1',
                    }}
                  />
                  <Text color='white.1' textStyle='smallstate'>
                    {totalUnreadMessages}
                  </Text>
                </Flex>
              ))}
          </Flex>

          <Flex w='100%'>
            <Box
              minH={{ base: 'mobileContainerHeight', sm: 'containerHeight' }}
              h={{ base: 'mobileContainerHeight', sm: 'containerHeight' }}
              w={
                hasRightNav && !isMobileDevice && chatIsOpen
                  ? 'calc(100% - 248px)'
                  : '100%'
              }
              bg='grey.2'
              overflowY='auto'
            >
              {children}
            </Box>

            {/* deprecate this in favor of hasRightNav and rendering right nav component inside page */}
            {showPeople && !isMobileDevice && <People />}
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

export default Layout
