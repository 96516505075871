import theme from '@chakra-ui/theme'

const components = {
  ...theme.components,
  Link: {
    ...theme.components.Link,
    baseStyle: {
      ...theme.components.Link.baseStyle,
      _focus: {
        boxShadow: 'none',
        outline: 'none',
      },
      _hover: {
        textDecoration: 'none',
      },
    },
    variants: {
      'base.primary': {
        minW: '104px',
        height: '48px',
        borderRadius: '8px',
        p: 3,
        color: 'white.1',
        bg: 'primary.1',
        border: '0.5px solid',
        borderColor: 'primary.2',
        _hover: {
          bg: 'hover.2',
          borderColor: 'white.1',
          border: '0.5px solid',
          boxShadow: '0px 4px 12px rgba(1,0,2,0.12)',
        },
        _disabled: {
          bg: 'disabled.2',
          border: 'none',
        },
        _focus: {
          boxShadow: '0px 2px 4px rgba(0,0,0,0.16)',
          border: '2px solid',
          borderColor: 'white.1',
        },
      },
    },
  },
  Button: {
    ...theme.components.Button,
    baseStyle: {
      ...theme.components.Button.baseStyle,
      _focus: {
        boxShadow: 'none',
        outline: 'none',
      },
    },
    variants: {
      ...theme.components.Button.variants,
      blank: {
        color: 'primary.1',
        bg: 'none',
        _hover: {
          bg: 'none',
        },
        _active: {
          bg: 'none',
        },
      },
      'icon.button': {
        color: 'primary.1',
        bg: 'white.1',
      },
      'icon.button.feedback': {
        color: 'black.1',
        minW: '16px',
        fontSize: '24px',
        ml: '4px !important',
      },
      'icon.button.md': {
        color: 'primary.1',
        fontSize: '22px',
      },
      'icon.button.inactive': {
        color: 'primary.2',
        bg: 'white.1',
        _hover: {
          bg: 'white.1',
        },
      },
      'input.icon.button': {
        bg: 'grey.2',
        color: 'primary.1',
      },
      'icon.button.question': {
        color: 'primary.1',
        bg: 'white.1',
        minW: '32px',
        w: '32px',
        maxW: '32px',
        minH: '32px',
        h: '32px',
        fontSize: '14px',
      },
      'base.white': {
        minW: '104px',
        height: '48px',
        borderTopWidth: '0.5px',
        borderRightWidth: '0.5px',
        borderBottomWidth: '0.5px',
        borderLeftWidth: '0.5px',
        borderStyle: 'solid',
        borderColor: 'primary.2',
        borderRadius: '8px',
        p: 4,
        color: 'primary.1',
        bg: 'white.1',
        _hover: {
          bg: 'hover.1',
          borderColor: 'primary.2',
          boxShadow: '0px 4px 12px rgba(1,0,2,0.12)',
        },
        _disabled: {
          bg: 'disabled.1',
          border: 'none',
        },
        _focus: {
          border: '1px solid',
          borderColor: 'primary.3',
          boxShadow: '0px 2px 4px rgba(0,0,0,0.12)',
        },
      },
      'base.white.disabled': {
        minW: '104px',
        height: '48px',
        borderTopWidth: '0.5px',
        borderRightWidth: '0.5px',
        borderBottomWidth: '0.5px',
        borderLeftWidth: '0.5px',
        borderStyle: 'solid',
        borderColor: 'primary.2',
        borderRadius: '8px',
        p: 4,
        color: 'primary.1',
        bg: 'white.1',
        _hover: {
          bg: 'hover.1',
          borderColor: 'primary.2',
          boxShadow: '0px 4px 12px rgba(1,0,2,0.12)',
        },
        _disabled: {
          bg: 'rgba(0, 0, 0, 0.13) none repeat scroll 0% 0%',
          border: 'none',
        },
        _focus: {
          border: '1px solid',
          borderColor: 'primary.3',
          boxShadow: '0px 2px 4px rgba(0,0,0,0.12)',
        },
      },
      'base.primary': {
        minW: '104px',
        height: '48px',
        borderRadius: '8px',
        p: 4,
        color: 'white.1',
        bg: 'primary.1',
        border: '0.5px solid',
        borderColor: 'primary.2',
        _hover: {
          bg: 'hover.2',
          borderColor: 'white.1',
          border: '0.5px solid',
          boxShadow: '0px 4px 12px rgba(1,0,2,0.12)',
          _disabled: {
            background: 'disabled.2',
          },
        },
        _disabled: {
          bg: 'disabled.2',
          border: 'none',
          color: 'primary.2',
          opacity: 1,
        },
        _focus: {
          boxShadow: '0px 2px 4px rgba(0,0,0,0.16)',
          border: '2px solid',
          borderColor: 'white.1',
        },
      },
      'base.error': {
        minW: '104px',
        height: '48px',
        borderRadius: '8px',
        p: 4,
        color: 'white.1',
        bg: 'error.1',
        border: '0.5px solid',
        borderColor: 'error.2',
        _disabled: {
          bg: 'disabled.2',
          border: 'none',
        },
        _focus: {
          boxShadow: '0px 2px 4px rgba(0,0,0,0.16)',
        },
      },
      'tab.selector': {
        bg: 'white.1',
        borderWidth: '.5px',
        borderColor: 'primary.2',
        borderRadius: '8px',
        width: '164px',
        height: '48px',
      },
      input: {
        h: '48px',
        borderWidth: '0.5px',
        borderStyle: 'solid',
        borderColor: 'primary.2',
        borderRadius: '8px',
        p: 4,
        color: 'primary.1',
        bg: 'grey.2',
        textAlign: 'left',
        _hover: {
          borderColor: 'primary.1',
        },
        _readOnly: {
          boxShadow: 'none !important',
          userSelect: 'all',
          bg: 'grey.2',
          _focus: {
            bg: 'grey.2',
          },
        },
        _disabled: {
          opacity: 0.4,
          cursor: 'not-allowed',
          border: 'none',
        },
        _invalid: {
          border: '.5px solid',
          bg: 'grey.2',
          borderColor: 'error.1',
          _focus: {
            border: '.5px solid',
            bg: 'white.1',
            borderColor: 'error.1',
          },
        },
        _focus: {
          bg: 'white.1',
          border: '1px solid',
          borderColor: 'primary.1',
        },
      },
    },
  },
  Input: {
    parts: ['field', 'addon'],
    variants: {
      alamInput: {
        field: {
          h: '48px',
          border: '.5px solid',
          borderColor: 'primary.2',
          bg: 'grey.2',
          _hover: {
            borderColor: 'primary.1',
          },
          _readOnly: {
            boxShadow: 'none !important',
            userSelect: 'all',
            bg: 'grey.2',
            _focus: {
              bg: 'grey.2',
            },
          },
          _disabled: {
            opacity: 0.4,
            cursor: 'not-allowed',
            border: 'none',
          },
          _invalid: {
            border: 'none',
            bg: 'grey.2',
            _focus: {
              border: '.5px solid',
              bg: 'white.1',
              borderColor: 'primary.1',
            },
          },
          _focus: {
            bg: 'white.1',
            border: '1px solid',
            borderColor: 'primary.1',
          },
        },
      },
      grade: {
        field: {
          w: '96px',
          h: '48px',
          color: 'primary.1',
          borderRadius: '12px',
          borderColor: 'primary.2',
          borderWidth: '.5px',
          borderStyle: 'solid',
          bg: 'white.1',
          py: '16px',
          pr: '16px',
          _disabled: {
            bg: 'grey.2',
            color: 'black.3',
          },
          _focus: {
            bg: 'white.1',
            color: 'primary.1',
          },
          _readOnly: {
            bg: 'grey.2',
            borderColor: 'grey.2',
          },
        },
      },
    },
    defaultProps: {
      size: 'md',
      variant: 'alamInput',
    },
  },
  Modal: {
    ...theme.components.Modal,
    baseStyle: {
      ...theme.components.Modal.baseStyle,
      overlay: {
        bg: 'overlay',
        opacity: '0.72 !important',
      },
      header: {
        pt: 10,
        color: 'primary.1',
        fontFamily: "'Nunito Sans', sans-serif",
        fontStyle: 'normal',
        fontWeight: '800',
        letterSpacing: '-0.01em',
        fontSize: '20px',
        lineHeight: '27px',
        textAlign: 'center',
      },
      dialog: {
        bg: 'white.1',
        color: 'inherit',
        my: '3.75rem',
        zIndex: 'modal',
        boxShadow: '0px 4px 16px primary.3',
        borderRadius: '12px',
      },
      closeButton: {
        color: 'primary.1',
        _focus: {
          outline: 'none',
        },
      },
    },
    variants: {
      withDivider: {
        header: {
          borderBottom: '.5px solid',
          borderColor: 'primary.2',
          pt: 6,
          textAlign: 'left',
          pl: 12,
          fontFamily: "'Nunito Sans', sans-serif",
          fontStyle: 'normal',
          fontWeight: '700',
          letterSpacing: '-0.01em',
          fontSize: '16px',
          lineHeight: '20px',
        },
        closeButton: {
          position: 'absolute',
          top: 4,
          insetInlineEnd: 'unset',
          left: 2,
        },
        body: {
          pt: 6,
        },
      },
      createClass: {
        dialog: {
          maxWidth: '29rem',
        },
      },
      assignmentWithDivider: {
        header: {
          borderBottom: '.5px solid',
          borderColor: 'primary.2',
          pt: '12px',
          pb: '12px',
          display: 'flex',
          justifyContent: 'space-between',
          w: '100%',
          alignItems: 'center',
          pl: 12,
          fontFamily: "'Nunito Sans', sans-serif",
          fontStyle: 'normal',
          fontWeight: '700',
          letterSpacing: '-0.01em',
          fontSize: '16px',
          lineHeight: '20px',
        },
        closeButton: {
          position: 'absolute',
          top: 5,
          insetInlineEnd: 'unset',
          left: 2,
        },
        body: {
          pt: 6,
        },
      },
      pretopicWithDivider: {
        header: {
          borderBottom: '.5px solid',
          borderColor: 'primary.2',
          pt: 6,
          textAlign: 'left',
          pl: 12,
          fontFamily: "'Nunito Sans', sans-serif",
          fontStyle: 'normal',
          fontWeight: '700',
          letterSpacing: '-0.01em',
          fontSize: '16px',
          lineHeight: '20px',
        },
        closeButton: {
          position: 'absolute',
          top: 4,
          insetInlineEnd: 'unset',
          left: 2,
        },
        body: {
          pt: 6,
          borderBottom: '.5px solid',
          borderColor: 'primary.2',
        },
      },
      bulletinModal: {
        header: {
          borderBottom: '.5px solid',
          borderColor: 'primary.2',
          textAlign: 'left',
          fontFamily: "'Nunito Sans', sans-serif",
          fontStyle: 'normal',
          fontWeight: '700',
          letterSpacing: '-0.01em',
          fontSize: '16px',
          lineHeight: '20px',
          padding: '16px 16px 16px 24px',
        },
        closeButton: {
          position: 'absolute',
          top: 4,
          insetInlineEnd: 'unset',
          left: 2,
        },
        body: {
          padding: '0px',
          borderBottom: '.5px solid',
          borderColor: 'primary.2',
        },
      },
      textQuestionModal: {
        closeButton: {
          position: 'absolute',
          right: 4,
          top: 4,
        },
        body: {
          padding: '48px 32px 32px',
        },
      },
    },
  },
  FormLabel: {
    ...theme.components.FormLabel,
    baseStyle: {
      ...theme.components.FormLabel.baseStyle,
      fontFamily: "'Nunito Sans', sans-serif",
      fontStyle: 'normal',
      fontWeight: '700',
      letterSpacing: '1.5px',
      fontSize: '10px',
      lineHeight: '16px',
      color: 'black.1',
      textTransform: 'uppercase',
      _invalid: {
        color: 'error.1',
      },
    },
    variants: {
      optional: {
        _after: {
          ml: 1,
          content: '"Optional"',
          fontFamily: "'Nunito Sans', sans-serif",
          fontStyle: 'italic',
          fontWeight: 400,
          fontSize: '10px',
          lineHeight: '16px',
          color: 'primary.2',
          textTransform: 'none',
        },
      },
      required: {
        _after: {
          ml: 1,
          content: '"*"',
          fontFamily: "'Nunito Sans', sans-serif",
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '16px',
          color: 'error.1',
          textTransform: 'none',
        },
      },
    },
  },
  FormError: {
    ...theme.components.FormError,
    parts: ['text', 'icon'],
    baseStyle: {
      text: {
        fontFamily: "'Nunito Sans', sans-serif",
        fontStyle: 'normal',
        fontWeight: '600',
        letterSpacing: '0.02em',
        fontSize: '12px',
        lineHeight: '16px',
        color: 'error.1',
        _invalid: {
          color: 'error.1',
        },
      },
      icon: {
        color: 'error.1',
        _invalid: {
          color: 'error.1',
        },
      },
    },
  },
  Menu: {
    parts: ['item', 'command', 'list', 'button', 'groupTitle', 'divider'],
    variants: {
      alam: {
        list: {
          minW: 'auto',
          py: 0,
          borderRadius: '12px',
        },
        item: {
          color: 'primary.1',
          fontFamily: "'Nunito Sans', sans-serif",
          fontStyle: 'normal',
          fontWeight: '400',
          letterSpacing: '-0.01em',
          fontSize: '14px',
          lineHeight: '21px',
          transition: 'background 50ms ease-in 0s',
          _focus: {
            bg: 'primary.1',
            color: 'white.1',
          },
          _active: {
            bg: 'primary.1',
            color: 'white.1',
          },
          _expandend: {
            bg: 'white.1',
          },
          _disabled: {
            bg: 'white.1',
            cursor: 'not-allowed',
            opacity: 0.4,
          },
          w: '200px',
          h: '48px',
          py: '12px',
          pr: '8px',
          pl: '16px',
          _first: {
            borderTopRadius: '12px',
          },
          _last: {
            borderBottomRadius: '12px',
          },
        },
        divider: {
          border: 0,
          borderBottom: '1px solid',
          borderColor: 'primary.2',
          my: 0,
          opacity: 0.6,
        },
      },
      hoverMenu: {
        list: {
          minW: 'auto',
          py: 0,
          borderRadius: '12px',
        },
        item: {
          color: 'primary.1',
          fontFamily: "'Nunito Sans', sans-serif",
          fontStyle: 'normal',
          fontWeight: '400',
          letterSpacing: '-0.01em',
          fontSize: '14px',
          lineHeight: '21px',
          transition: 'background 50ms ease-in 0s',
          _focus: {
            bg: 'primary.1',
            color: 'white.1',
          },
          _active: {
            bg: 'primary.1',
            color: 'white.1',
          },
          _expandend: {
            bg: 'white.1',
          },
          _disabled: {
            bg: 'white.1',
            cursor: 'not-allowed',
            opacity: 0.4,
          },
          _hover: {
            bg: 'hover.2',
            color: 'white.1',
          },
          w: '200px',
          h: '48px',
          py: '12px',
          pr: '8px',
          pl: '16px',
          _first: {
            borderTopRadius: '12px',
          },
          _last: {
            borderBottomRadius: '12px',
          },
        },
        divider: {
          border: 0,
          borderBottom: '1px solid',
          borderColor: 'primary.2',
          my: 0,
          opacity: 0.6,
        },
      },
      checkbox: {
        item: {
          _focus: {
            color: 'primary.1',
            bg: 'green.50',
          },
          _active: {
            color: 'primary.1',
            bg: 'green.50',
          },
        },
      },
    },
    defaultProps: {
      variant: 'alam',
    },
  },
  Drawer: {
    ...theme.components.Drawer,
    baseStyle: {
      ...theme.components.Drawer.baseStyle,
    },
    sizes: {
      ...theme.components.Drawer.sizes,
      sidenav: {
        dialog: { maxW: '240px' },
      },
    },
  },
  Textarea: {
    ...theme.components.Textarea,
    variants: {
      ...theme.components.Textarea.variants,
      alamInput: {
        border: '.5px solid',
        borderColor: 'primary.2',
        bg: 'grey.2',
        _hover: {
          borderColor: 'primary.1',
        },
        _readOnly: {
          boxShadow: 'none !important',
          userSelect: 'all',
        },
        _disabled: {
          opacity: 0.4,
          border: 'none',
        },
        _invalid: {
          border: 'none',
          bg: 'grey.2',
          _focus: {
            border: '.5px solid',
            bg: 'white.1',
            borderColor: 'primary.1',
          },
        },
        _focus: {
          bg: 'white.1',
          border: '1px solid',
          borderColor: 'primary.1',
        },
      },
    },
    defaultProps: {
      size: 'md',
      variant: 'alamInput',
    },
  },
  Switch: {
    ...theme.components.Switch,
    baseStyle: {
      ...theme.components.Switch.baseStyle,
      track: {
        borderRadius: '16px',
        p: '2px',
        transition: 'all 120ms',
        bg: 'primary.2',
        _focus: {
          boxShadow: 'none',
        },
        _disabled: {
          opacity: 0.4,
          cursor: 'not-allowed',
        },
        _checked: {
          bg: 'primary.1',
        },
      },
      thumb: {
        bg: 'white.1',
        transition: 'transform 250ms',
        borderRadius: 'full',
        transform: 'translateX(0)',
      },
    },
  },
  Checkbox: {
    ...theme.components.Checkbox,
    baseStyle: {
      control: {
        w: '100%',
        transition: 'box-shadow 250ms',
        border: '2px solid',
        borderRadius: 'sm',
        borderColor: 'primary.1',
        color: 'white.1',

        _focus: {
          boxShadow: 'none',
        },

        _checked: {
          bg: 'primary.1',
          borderColor: 'primary.2',
          color: 'white.1',

          _disabled: {
            bg: 'disabled.2',
            border: 'none',
          },
        },

        _disabled: {
          bg: 'disabled.2',
          border: 'none',
        },
      },
      label: {
        userSelect: 'none',
        _disabled: { opacity: 0.4 },
        color: 'primary.1',
        fontFamily: "'Nunito Sans', sans-serif",
        fontStyle: 'normal',
        fontWeight: '400',
        letterSpacing: '-0.01em',
        fontSize: '14px',
        lineHeight: '21px',
        ml: '16px',
      },
    },
    variants: {
      managePeople: {
        control: {
          _checked: {
            bg: 'white.1',
            borderColor: 'white.1',
            color: 'accent.1',

            _hover: {
              bg: 'white.1',
              borderColor: 'white.1',
            },
          },
        },
      },
    },
  },
  Select: {
    parts: ['field', 'icon'],
    variants: {
      alam: {
        field: {
          h: '48px',
          border: '.5px solid',
          borderColor: 'primary.2',
          bg: 'grey.2',
          _hover: {
            borderColor: 'primary.1',
          },
          _readOnly: {
            boxShadow: 'none !important',
            userSelect: 'all',
            bg: 'grey.2',
            _focus: {
              bg: 'grey.2',
            },
          },
          _disabled: {
            opacity: 0.4,
            cursor: 'not-allowed',
            border: 'none',
          },
          _invalid: {
            border: '.5px solid',
            bg: 'grey.2',
            borderColor: 'error.1',
            _focus: {
              border: '.5px solid',
              bg: 'white.1',
              borderColor: 'error.1',
            },
          },
          _focus: {
            bg: 'white.1',
            border: '1px solid',
            borderColor: 'primary.1',
          },
        },
      },
    },
    defaultProps: {
      size: 'md',
      variant: 'alam',
    },
  },
  Radio: {
    ...theme.components.Radio,
    parts: ['container', 'control', 'label'],
    baseStyle: {
      control: {
        _focus: {
          boxShadow: 'none',
        },
      },
    },
    variants: {
      alam: {
        control: {
          border: '2px solid',
          borderColor: 'primary.1',

          _checked: {
            bg: 'white.1',
            borderColor: 'accent.1',
            color: 'accent.1',

            _hover: {
              bg: 'white.1',
              borderColor: 'accent.1',
              color: 'accent.1',
            },
          },
        },
        label: {
          fontFamily: "'Nunito Sans', sans-serif",
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '16px',
          lineHeight: '24px',
          letterSpacing: '-0.01em',
          ml: '20px',

          _checked: {
            color: 'accent.1',
          },
        },
      },
    },
    defaultProps: {
      size: 'md',
      variant: 'alam',
    },
  },
  Tooltip: {
    ...theme.components.Tooltip,
    variants: {
      iconToolTip: {
        placement: 'bottom-end',
        bg: 'black.1',
        borderRadius: '8px',
        fontSize: '12px',
        color: 'white.1',
        lineHeight: '20px',
        padding: '8px 16px',
        letterSpacing: '-0.01em',
      },
    },
  },
}

export default components
