import { useMemo } from 'react'
import { Route, useRouteMatch } from 'react-router'
import ClassGrades from './ClassGrades'
import GroupsGrades from './GroupsGrades'
import { Layout } from '../../components/Layout'
import { useQuery, useSubscription } from '@apollo/client'
import { useAlamContext } from '../../client'
import {
  GET_CLASS_TOPICS,
  GET_ASSIGNMENTS_IN_CLASS,
  GET_GROUP_ASSIGNMENTS_IN_CLASS,
  GET_USERS_SUBMISSION_STATUS_IN_CLASS,
  GET_GROUPS_SUBMISSION_STATUS_IN_CLASS,
} from '../../client/queries'
import { RenderForUser } from '../../components/SharedComponents'

export type ClassGradesPage = {
  id: string
}

const ClassGradesPage = ({ id }: ClassGradesPage): JSX.Element => {
  const { user, classUser } = useAlamContext()
  const topNavLeft = [{ type: 'text' as const, text: 'Grades' }]
  const { path, url } = useRouteMatch()
  const topNavCenter = ['admin', 'teacher', 'creator'].includes(classUser?.role)
    ? [
        {
          type: 'link' as const,
          url,
          text: 'Class',
        },
        {
          type: 'link' as const,
          url: `${url}/group`,
          text: 'Group',
        },
      ]
    : []

  const { data: topicsData } = useQuery(GET_CLASS_TOPICS, {
    variables: {
      class_id: id,
      user_id: user.id,
    },
  })
  const { topics } = topicsData || { topics: [] }

  const { data: assignmentsData } = useSubscription(GET_ASSIGNMENTS_IN_CLASS, {
    variables: {
      class_id: id,
    },
  })
  const { assignments } = assignmentsData || { assignments: [] }

  const { data: groupAssignmentsData } = useSubscription(
    GET_GROUP_ASSIGNMENTS_IN_CLASS,
    {
      variables: {
        class_id: id,
      },
    }
  )

  const { assignments: groupAssignments } = groupAssignmentsData || {
    assignments: [],
  }

  const { data: usersData } = useSubscription(
    GET_USERS_SUBMISSION_STATUS_IN_CLASS,
    {
      variables: {
        class_id: id,
      },
    }
  )
  const { users } = usersData || { users: [] }

  const { data: groupsData } = useSubscription(
    GET_GROUPS_SUBMISSION_STATUS_IN_CLASS,
    {
      variables: {
        class_id: id,
      },
    }
  )

  const usersQueryRefetch = () => {}

  const groupsQueryRefetch = () => {}

  const { groups } = groupsData || { groups: [] }

  const constructSubmissions = (submissions: any, assignments: any) => {
    return assignments.map((assignment: any) => {
      const subm = submissions.find(
        (submission: any) => submission.assignment.id === assignment.id
      )
      if (subm) {
        return {
          id: subm.id,
          status: subm.status,
          assignment: assignment,
          grades: subm.grades,
          eventLogs: subm.eventLogs,
          user_id: subm.user_id,
          group_id: subm.group_id,
          group: subm.group,
        }
      } else {
        return {
          id: null,
          status: 'not assigned',
          assignment: assignment,
          grades: [],
          eventLogs: [],
          user_id: null,
          group_id: null,
          group: null,
        }
      }
    })
  }

  const constructUserData = (assignments: any, users: any) => {
    return users.map((user: any) => {
      return {
        ...user,
        submissions: constructSubmissions(user.submissions, assignments),
      }
    })
  }

  const constructGroupData = (assignments: any, groups: any) => {
    return groups.map((group: any) => {
      return {
        ...group,
        submissions: constructSubmissions(group.submissions, assignments),
      }
    })
  }

  const constructedUsers = useMemo(() => {
    return constructUserData(assignments, users)
  }, [users, assignments])

  const constructedGroups = useMemo(() => {
    return constructGroupData(groupAssignments, groups)
  }, [groups, groupAssignments])

  return (
    <>
      <Layout showSideNav topNavLeft={topNavLeft} topNavCenter={topNavCenter}>
        <Route path={path} exact>
          <ClassGrades
            topNavLeft={topNavLeft}
            topNavCenter={topNavCenter}
            assignments={assignments}
            users={constructedUsers}
            usersRefetch={usersQueryRefetch}
            topics={topics}
          />
        </Route>
        <RenderForUser roles={['admin', 'teacher', 'creator']}>
          <Route path={`${path}/group`}>
            <GroupsGrades
              topNavLeft={topNavLeft}
              topNavCenter={topNavCenter}
              assignments={groupAssignments}
              groups={constructedGroups}
              groupsRefetch={groupsQueryRefetch}
              topics={topics}
            />
          </Route>
        </RenderForUser>
      </Layout>
    </>
  )
}

export default ClassGradesPage
