import { useEffect, useState } from 'react'
import {
  Box,
  Modal,
  ModalHeader,
  ModalContent,
  ModalBody,
  ModalOverlay,
  ModalFooter,
  Flex,
  Checkbox,
  Stack,
  HStack,
  Icon,
  Button,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { useAlamContext } from '../../client'
import { EDIT_USER } from '../../client/queries'
import {
  formatLearningStyle,
  useErrorToast,
  useSuccessToast,
} from '../../utils'
import { MdCheck } from 'react-icons/md'

type varkFormInput = {
  chosenLearningStyles: string[]
}

export type VarkModalProps = {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  cancelText?: string
}

export const VarkModal = ({
  isOpen,
  onClose,
  onOpen,
  cancelText = 'Cancel',
}: VarkModalProps): JSX.Element => {
  const { user, classUser, setUser } = useAlamContext()
  const [chosenLearningStyles, setChosenLearningStyles] = useState(
    user?.learningStyle || []
  )

  const [editUser, { loading: editUserIsSubmitting }] = useMutation(EDIT_USER)

  const learningStyles = [
    { display: 'Visual', value: 'visual' },
    { display: 'Aural', value: 'aural' },
    { display: 'Read/Write', value: 'read' },
    { display: 'Kinesthetic', value: 'kinesthetic' },
  ]

  const handleSubmit = (chosenLearningStyles: string[]) => {
    const data = { learning_style: formatLearningStyle(chosenLearningStyles) }

    editUser({
      variables: {
        data,
        id: user.id,
      },
    })
      .then(({ data: { update_users_by_pk: user } }) => {
        localStorage.setItem('user', JSON.stringify(user))
        setUser(user)
        setChosenLearningStyles(user.learningStyle)
        useSuccessToast({ message: 'Learning preferences successfully set' })
        onClose()
      })
      .catch(() => {
        useErrorToast({})
      })
  }

  const handleOnClose = () => {
    setChosenLearningStyles(user?.learningStyle || [])
    onClose()
  }

  const isLearningStyleEmpty = () => {
    return !chosenLearningStyles || chosenLearningStyles.length < 1
  }

  const isLearningStyleChecked = (style: string): boolean => {
    return chosenLearningStyles.includes(style)
  }

  const onChangeStyles = (style: string) => {
    if (chosenLearningStyles.includes(style)) {
      const updatedLearningStyle = chosenLearningStyles.filter(
        (chosenStyle) => chosenStyle !== style
      )
      setChosenLearningStyles(updatedLearningStyle)
    } else {
      setChosenLearningStyles([...chosenLearningStyles, style])
    }
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleOnClose} isCentered>
        <ModalOverlay />
        <ModalContent width='576px' maxWidth='576px' padding='40px 40px 32px'>
          <ModalHeader
            fontSize='20px'
            lineHeight='27px'
            margin='0px'
            padding='0px'
            textAlign='left'
            color='primary.1'
          >
            Learning Preferences
          </ModalHeader>
          <ModalBody padding='0px'>
            <Stack>
              <Text margin='16px 0px' fontSize='16px' color='black.1'>
                Select your preferred learning preferences below. This will help
                us show materials that are relevant for your learning
                preferences.
              </Text>
              <Stack
                border='0.5px solid'
                borderColor='primary.2'
                borderRadius='12px'
                marginBottom='32px'
                justify='start'
                padding='16px'
                overflow='auto'
                css={{
                  '&::-webkit-scrollbar': {
                    width: '0px',
                  },
                }}
              >
                {learningStyles.map((learningStyle) => {
                  const isChecked = isLearningStyleChecked(learningStyle.value)
                  return (
                    <Box
                      {...(isChecked
                        ? {
                            borderColor: 'accent.1',
                          }
                        : {
                            borderColor: 'rgba(255,255,255, 0)',
                          })}
                      borderRadius='8px'
                      borderWidth='2px'
                    >
                      <Checkbox
                        h={46}
                        name='chosenLearningStyles'
                        isChecked={isChecked}
                        padding='16px'
                        key={learningStyle.value}
                        onChange={() => {
                          onChangeStyles(learningStyle.value)
                        }}
                        icon={
                          <Icon
                            as={MdCheck}
                            p='0px'
                            border='0px'
                            margin='0px'
                            boxSize='1rem'
                            backgroundColor='accent.1'
                          />
                        }
                      >
                        <Text
                          color={isChecked ? 'accent.1' : 'black.1'}
                          fontSize='16px'
                          lineHeight='24px'
                        >
                          {learningStyle.display}
                        </Text>
                      </Checkbox>
                    </Box>
                  )
                })}
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter padding='0px' margin='0px'>
            <HStack spacing={4}>
              <Button
                variant='base.white'
                onClick={handleOnClose}
                textStyle='button'
              >
                {cancelText}
              </Button>
              <Button
                variant='base.primary'
                type='submit'
                textStyle='button'
                onClick={() => {
                  handleSubmit(chosenLearningStyles)
                }}
                isLoading={editUserIsSubmitting}
                isDisabled={isLearningStyleEmpty()}
              >
                Save
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
