import React from 'react'
import Indicator from '../SharedComponents/Indicator'
import { ResponseForm } from '.'
import { useDisclosure } from '@chakra-ui/react'
import { Exam } from '../../types'

export type ExamIndicatorProps = {
  exam: Exam
}

const getText = (exam: Exam) => {
  if (exam.type === 'assignment_review') {
    return 'Review'
  }

  if (exam.type === 'learning_review') {
    return 'Learning Review'
  }

  return ''
}

export const ExamIndicator = ({ exam }: ExamIndicatorProps): JSX.Element => {
  const {
    isOpen: reviewIsOpen,
    onClose: reviewOnClose,
    onOpen: reviewOnOpen,
  } = useDisclosure()
  return (
    <>
      <ResponseForm isOpen={reviewIsOpen} onClose={reviewOnClose} exam={exam} />
      <Indicator
        key={exam.id}
        onClick={reviewOnOpen}
        title={`(${exam?.assignment?.name}) ${getText(exam)}`}
        type='button'
      />
    </>
  )
}

export default ExamIndicator
