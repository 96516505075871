import { Link, Text } from '@chakra-ui/react'
import {
  AggregateField,
  AnalyticsUser,
  Submission,
  AnalyticsGroupUser,
} from '../types'
import {
  getSubmittedEventLog,
  getEventLog,
  renderCompletionTime,
} from './completionAnalytics'
import { pluralize } from './date'
import { differenceInMilliseconds } from 'date-fns'

export interface AnalyticsMaterialData {
  id: string
  title: string
  inserted_at: string
  material_users_aggregate: AggregateField
  material_groups_aggregate: AggregateField
  class_id: string
}

export interface AnalyticsExamData {
  id: string
  type: string
}

export interface AnalyticsAssignmentData {
  id: string
  name: string
  rubric: string
  grading: string
  inserted_at: string
  exams: AnalyticsExamData[]
  assignment_users_aggregate: AggregateField
  assignment_groups_aggregate: AggregateField
  class_id: string
}

export interface AnalyticsTopicData {
  id: string
  name: string
  pretopics: AnalyticsAssignmentData[]
  materials: AnalyticsMaterialData[]
  assignments: AnalyticsAssignmentData[]
}

export interface AnalyticsMaterialUser {
  id: string
  material_id: string
  inserted_at: string
  view_time: number
}

export interface AnalyticsAssignmentUser {
  id: string
  user_id: string
  assignment_id: string
  viewed_at: string
}

export interface AnalyticsUserData {
  id: string
  firstName: string
  lastName: string
  avatar: string
  is_preference_public: string
  learning_style?: string[]
  group_users: AnalyticsGroupUser[]
  submissions: Submission[]
  material_users?: AnalyticsMaterialUser[]
  assignment_users?: AnalyticsAssignmentUser[]
}

export interface AnalyticsExam {
  id: string
  assignment_id: string
  type: string
}

export interface AnalyticsExamResponse {
  id: string
  exam: AnalyticsExam
}

export interface StudentAnalyticsTableHeading {
  id: string
  type: string
  text: string
  topic?: TopicField
  subtext?: string
  hasGroup?: boolean
  hasIndividual?: boolean
  pretopic?: AnalyticsAssignmentData
  assignment?: AnalyticsAssignmentData
  material?: AnalyticsMaterialData
}

export interface TopicField {
  id: string
  name: string
}

export interface HeaderData {
  materials: AnalyticsMaterialData[]
  assignments: AnalyticsAssignmentData[]
  topics: AnalyticsTopicData[]
}

export interface AnalyticsGroupData {
  id: string
  name: string
  material_groups: AnalyticsMaterialGroup[]
  submissions: Submission[]
  assignment_groups: AnalyticsAssignmentGroup[]
}

export interface AnalyticsMaterialGroup {
  id: string
  group_id: string
  material_id: string
  inserted_at: string
  view_time: number
}

export interface AnalyticsAssignmentGroup {
  id: string
  group_id: string
  assignment_id: string
  viewed_at: string
}

export const constructTableHeadings = (
  headerData: HeaderData
): StudentAnalyticsTableHeading[] => {
  if (!headerData) {
    return []
  }

  const { materials, assignments, topics } = headerData

  const noTopicMaterialHeadings = materials.map(
    (material): StudentAnalyticsTableHeading =>
      constructMaterialHeading(material, undefined)
  )

  const noTopicAssignmentHeadings = assignments.flatMap((assignment) =>
    constructAssignmentHeadings(assignment, undefined)
  )

  const topicHeadings = topics.flatMap((topic) => {
    const { id, name, pretopics, materials, assignments } = topic
    const pretopic = pretopics[0]
    const pretopicHeading = pretopic
      ? [
          {
            id: pretopic?.id,
            type: 'PRETOPIC',
            text: pretopic?.name,
            topic: {
              id,
              name,
            },
            subtext: 'TEST',
            hasGroup: false,
            hasIndividual: true,
            pretopic: pretopic,
          },
        ]
      : []

    const topicMaterialHeadings = materials.map((material) =>
      constructMaterialHeading(material, topic)
    )

    const topicAssignmentHeadings = assignments.flatMap((assignment) =>
      constructAssignmentHeadings(assignment, topic)
    )

    return [
      ...pretopicHeading,
      ...topicMaterialHeadings,
      ...topicAssignmentHeadings,
    ]
  })

  return [
    ...noTopicMaterialHeadings,
    ...noTopicAssignmentHeadings,
    ...topicHeadings,
  ]
}

export const formatTopicData = (topic?: AnalyticsTopicData) => {
  if (!topic) return undefined

  return {
    id: topic?.id,
    name: topic?.name,
  }
}

export const constructAssignmentHeadings = (
  assignment: AnalyticsAssignmentData,
  topic?: AnalyticsTopicData
): StudentAnalyticsTableHeading[] => {
  const {
    id,
    name,
    rubric,
    grading,
    exams,
    assignment_users_aggregate,
    assignment_groups_aggregate,
  } = assignment
  const hasGroup = assignment_groups_aggregate.aggregate.count > 0
  const hasIndividual = assignment_users_aggregate.aggregate.count > 0
  const assignmentReview = exams.find(
    (exam) => exam.type === 'assignment_review'
  )
  const learningReview = exams.find((exam) => exam.type === 'learning_review')

  const assignmentCompletionHeading = {
    id,
    type: 'ASSIGNMENT_COMPLETION_TIME',
    text: `${name} Completion Time`,
    topic: formatTopicData(topic),
    subtext: 'HOURS',
    hasGroup,
    hasIndividual,
    assignment,
  }

  const assignmentScoreHeading = {
    id,
    type: 'ASSIGNMENT_SCORE',
    text: `${name} Score`,
    topic: formatTopicData(topic),
    subtext: rubric === 'numerical' ? `OUT OF ${grading}` : undefined,
    hasGroup,
    hasIndividual,
    assignment,
  }

  const assignmentReviewHeading = assignmentReview
    ? [
        {
          id,
          type: 'ASSIGNMENT_REVIEW',
          text: `${name} Review`,
          topic: formatTopicData(topic),
          subtext: 'FEEDBACK',
          assignment,
        },
      ]
    : []

  const learningReviewHeading = learningReview
    ? [
        {
          id,
          type: 'LEARNING_REVIEW',
          text: `${name} Review`,
          topic: formatTopicData(topic),
          subtext: 'FEEDBACK',
          assignment,
        },
      ]
    : []

  return [
    assignmentCompletionHeading,
    assignmentScoreHeading,
    ...assignmentReviewHeading,
    ...learningReviewHeading,
  ]
}

export const constructMaterialHeading = (
  {
    id,
    title,
    material_users_aggregate,
    material_groups_aggregate,
  }: AnalyticsMaterialData,
  topic?: AnalyticsTopicData
): StudentAnalyticsTableHeading => {
  const hasGroup = material_groups_aggregate.aggregate.count > 0
  const hasIndividual = material_users_aggregate.aggregate.count > 0
  return {
    id,
    type: 'MATERIAL_COMPLETION_TIME',
    text: `${title} View Time`,
    topic: formatTopicData(topic),
    subtext: 'HOURS',
    hasGroup,
    hasIndividual,
  }
}

export const renderStudentCellContent = (
  student: AnalyticsUserData,
  heading: StudentAnalyticsTableHeading
): JSX.Element => {
  switch (heading.type) {
    case 'PRETOPIC':
      return buildPretopicCellContent(student, heading)
    case 'ASSIGNMENT_COMPLETION_TIME':
      return buildCompletionTimeCellContent(student, heading)
    case 'ASSIGNMENT_SCORE':
      return buildScoreCellContent(student, heading)
    case 'ASSIGNMENT_REVIEW':
      return buildReviewContent(student, heading)
    case 'LEARNING_REVIEW':
      return buildReviewContent(student, heading)
    case 'MATERIAL_COMPLETION_TIME':
      return buildMaterialCompletionTimeCellContent(student, heading)
    default:
      return <>'not assigned'</>
  }
}

export const renderGroupCellContent = (
  group: AnalyticsGroupData,
  heading: StudentAnalyticsTableHeading
): JSX.Element => {
  switch (heading.type) {
    case 'PRETOPIC':
      return buildPretopicGroupCellContent(group, heading)
    case 'ASSIGNMENT_COMPLETION_TIME':
      return buildCompletionTimeGroupCellContent(group, heading)
    case 'ASSIGNMENT_SCORE':
      return buildScoreGroupCellContent(group, heading)
    case 'ASSIGNMENT_REVIEW':
      return buildReviewGroupContent(group, heading)
    case 'LEARNING_REVIEW':
      return buildReviewGroupContent(group, heading)
    case 'MATERIAL_COMPLETION_TIME':
      return buildMaterialCompletionTimeGroupCellContent(group, heading)
    default:
      return <>'not assigned'</>
  }
}

export const buildPretopicGroupCellContent = (
  group: AnalyticsGroupData,
  heading: StudentAnalyticsTableHeading
): JSX.Element => {
  return <Text textStyle='analyticsTableCell'>Not Assigned</Text>
}

export const buildPretopicCellContent = (
  student: AnalyticsUserData,
  heading: StudentAnalyticsTableHeading
): JSX.Element => {
  const assignmentUser = getAssignmentUser(student, heading)
  const { class_id, id } = heading.pretopic!

  if (!assignmentUser) {
    return <Text textStyle='analyticsTableCell'>Not Assigned</Text>
  }

  return (
    <Link href={`/classes/${class_id}/pretopic/${id}`}>
      <Text textStyle='analyticsTableCell' color='primary.1'>
        View Test
      </Text>
    </Link>
  )
}

export const buildMaterialCompletionTimeCellContent = (
  student: AnalyticsUserData,
  heading: StudentAnalyticsTableHeading
): JSX.Element => {
  const materialUser = student?.material_users?.find(({ material_id }) => {
    return heading.id === material_id
  })

  if (!materialUser) {
    return <Text textStyle='analyticsTableCell'>Not Assigned</Text>
  }

  const viewTime = materialUser?.view_time

  return (
    <Text textStyle='analyticsTableCell' color='black.1'>
      {formatDuration(viewTime)}
    </Text>
  )
}

export const buildMaterialCompletionTimeGroupCellContent = (
  group: AnalyticsGroupData,
  heading: StudentAnalyticsTableHeading
): JSX.Element => {
  const materialGroup = group?.material_groups?.find(({ material_id }) => {
    return heading.id === material_id
  })

  if (!materialGroup) {
    return <Text textStyle='analyticsTableCell'>Not Assigned</Text>
  }

  const viewTime = materialGroup?.view_time

  return (
    <Text textStyle='analyticsTableCell' color='black.1'>
      {formatDuration(viewTime)}
    </Text>
  )
}

export const buildCompletionTimeCellContent = (
  student: AnalyticsUserData,
  heading: StudentAnalyticsTableHeading
): JSX.Element => {
  const assignmentUser = getAssignmentUser(student, heading)

  if (!assignmentUser) {
    return <Text textStyle='analyticsTableCell'>Not Assigned</Text>
  }

  const userSubmission = getSubmission(student, heading)
  if (!userSubmission) {
    return <Text textStyle='analyticsTableCell'>N/A</Text>
  }
  const submittedEventLog = getSubmittedEventLog(userSubmission?.event_logs)

  if (!submittedEventLog) {
    return <Text textStyle='analyticsTableCell'>N/A</Text>
  }

  const rawStartDate =
    assignmentUser?.viewed_at ||
    getEventLog(userSubmission.event_logs, 'started')?.inserted_at ||
    userSubmission.inserted_at!

  const dateEnd = Date.parse(submittedEventLog.inserted_at)
  const dateStart = Date.parse(rawStartDate)
  const completionTime = differenceInMilliseconds(dateEnd, dateStart)

  return (
    <Text textStyle='analyticsTableCell' color='black.1'>
      {formatDuration(completionTime)}
    </Text>
  )
}

export const buildCompletionTimeGroupCellContent = (
  group: AnalyticsGroupData,
  heading: StudentAnalyticsTableHeading
): JSX.Element => {
  const assignmentGroup = getAssignmentGroup(group, heading)

  if (!assignmentGroup) {
    return <Text textStyle='analyticsTableCell'>Not Assigned</Text>
  }

  const groupSubmission = getGroupSubmission(group, heading)
  if (!groupSubmission) {
    return <Text textStyle='analyticsTableCell'>N/A</Text>
  }
  const submittedEventLog = getSubmittedEventLog(groupSubmission?.event_logs)

  if (!submittedEventLog) {
    return <Text textStyle='analyticsTableCell'>N/A</Text>
  }

  const rawStartDate =
    assignmentGroup?.viewed_at ||
    getEventLog(groupSubmission.event_logs, 'started')?.inserted_at ||
    groupSubmission.inserted_at!

  const dateEnd = Date.parse(submittedEventLog.inserted_at)
  const dateStart = Date.parse(rawStartDate)
  const completionTime = differenceInMilliseconds(dateEnd, dateStart)

  return (
    <Text textStyle='analyticsTableCell' color='black.1'>
      {formatDuration(completionTime)}
    </Text>
  )
}

export const buildScoreCellContent = (
  student: AnalyticsUserData,
  heading: StudentAnalyticsTableHeading
): JSX.Element => {
  const assignmentUser = getAssignmentUser(student, heading)

  if (!assignmentUser) {
    return <Text textStyle='analyticsTableCell'>Not Assigned</Text>
  }

  const userSubmission = getSubmission(student, heading)
  const grade = userSubmission?.grades[0]?.grade

  if (!grade) {
    return <Text textStyle='analyticsTableCell'>N/A</Text>
  }

  return (
    <Text textStyle='analyticsTableCell' color='primary.1'>
      {grade}
    </Text>
  )
}

export const buildScoreGroupCellContent = (
  group: AnalyticsGroupData,
  heading: StudentAnalyticsTableHeading
): JSX.Element => {
  const assignmentGroup = getAssignmentGroup(group, heading)

  if (!assignmentGroup) {
    return <Text textStyle='analyticsTableCell'>Not Assigned</Text>
  }

  const groupSubmission = getGroupSubmission(group, heading)
  const grade = groupSubmission?.grades[0]?.grade

  if (!grade) {
    return <Text textStyle='analyticsTableCell'>N/A</Text>
  }

  return (
    <Text textStyle='analyticsTableCell' color='primary.1'>
      {grade}
    </Text>
  )
}

export const getAssignmentUser = (
  student: AnalyticsUserData,
  { id: heading_id }: StudentAnalyticsTableHeading
) => {
  return student?.assignment_users?.find(({ assignment_id }) => {
    return heading_id === assignment_id
  })
}

export const getAssignmentGroup = (
  group: AnalyticsGroupData,
  { id: heading_id }: StudentAnalyticsTableHeading
) => {
  return group?.assignment_groups?.find(({ assignment_id }) => {
    return heading_id === assignment_id
  })
}

export const getSubmission = (
  student: AnalyticsUserData,
  { id: heading_id }: StudentAnalyticsTableHeading
) => {
  return student?.submissions?.find(({ assignment_id }) => {
    return heading_id === assignment_id
  })
}

export const getGroupSubmission = (
  group: AnalyticsGroupData,
  { id: heading_id }: StudentAnalyticsTableHeading
) => {
  return group?.submissions?.find(({ assignment_id }) => {
    return heading_id === assignment_id
  })
}

export const buildReviewContent = (
  student: AnalyticsUserData,
  heading: StudentAnalyticsTableHeading
) => {
  const assignmentUser = getAssignmentUser(student, heading)

  if (!assignmentUser) {
    return <Text textStyle='analyticsTableCell'>Not Assigned</Text>
  }

  const { topic, id, assignment } = heading

  const examType = heading.type.toLowerCase().replace('_', '-')

  const reviewURL = topic
    ? `/classes/${assignment?.class_id}/analytics/class/topic/${topic?.id}/assignment/${assignment?.id}/${examType}`
    : `/classes/${assignment?.class_id}/analytics/class/assignment/${assignment?.id}/${examType}`

  return (
    <Link href={reviewURL}>
      <Text textStyle='analyticsTableCell' color='primary.1'>
        View Feedback
      </Text>
    </Link>
  )
}

export const buildReviewGroupContent = (
  group: AnalyticsGroupData,
  heading: StudentAnalyticsTableHeading
) => {
  const assignmentGroup = getAssignmentGroup(group, heading)

  if (!assignmentGroup) {
    return <Text textStyle='analyticsTableCell'>Not Assigned</Text>
  }

  const { topic, id, assignment } = heading

  const examType = heading.type.toLowerCase().replace('_', '-')

  const reviewURL = topic
    ? `/classes/${assignment?.class_id}/analytics/class/topic/${topic?.id}/assignment/${assignment?.id}/${examType}`
    : `/classes/${assignment?.class_id}/analytics/class/assignment/${assignment?.id}/${examType}`

  return (
    <Link href={reviewURL}>
      <Text textStyle='analyticsTableCell' color='primary.1'>
        View Feedback
      </Text>
    </Link>
  )
}

export const formatDuration = (duration: number) => {
  if (duration === 0) {
    return '0 min'
  }
  const rawDays = duration / 1000 / 60 / 60 / 24
  const days = Math.floor(rawDays)
  const rawHours = (rawDays - days) * 24
  const hours = Math.floor(rawHours)
  const rawMinutes = (rawHours - hours) * 60
  const minutes = Math.ceil(rawMinutes)
  const daysString = days > 0 ? `${days}d,` : ''
  const hoursString = hours > 0 ? `${hours}h,` : ''
  const minutesString =
    minutes > 0 ? `${minutes} ${pluralize('min', minutes)}` : '0 min'

  return `${daysString}${hoursString}${minutesString}`
}
