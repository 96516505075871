import React, { useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  FormErrorIcon,
  HStack,
  VStack,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'

export interface EditGroupModalProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (data: EditGroupInput) => void
  name: string
  description?: string
}

export type EditGroupInput = {
  groupName: string
  description?: string
}

export const EditGroup = ({
  isOpen,
  onClose,
  onSubmit,
  name,
  description = '',
}: EditGroupModalProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    errors,
    formState: { isValid },
    watch,
    reset,
  } = useForm<EditGroupInput>({
    mode: 'onChange',
    defaultValues: { groupName: name, description: description },
  })
  const groupName = watch('groupName')
  const descriptionInput = watch('description')

  useEffect(() => {
    reset({ groupName: name, description: description })
  }, [name, description])

  const isDisabled =
    (groupName === name && descriptionInput === description) || !isValid

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size='sm'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Group</ModalHeader>

        <ModalCloseButton />

        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <VStack spacing={4}>
              <FormControl isInvalid={!!errors.groupName?.message}>
                <FormLabel variant='required'>Group Name</FormLabel>
                <Input
                  name='groupName'
                  placeholder=''
                  defaultValue={name}
                  ref={register({
                    required: 'Group name is a required field',
                    maxLength: {
                      value: 255,
                      message: 'Name exceeded 255 maximum characters',
                    },
                  })}
                />
                {errors.groupName && (
                  <FormErrorMessage>
                    <FormErrorIcon />
                    {errors.groupName?.message}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl
                css={{
                  '& .optional::after': {
                    letterSpacing: '0',
                  },
                }}
                isInvalid={!!errors.description?.message}
              >
                <FormLabel variant='optional' className='optional'>
                  Description
                </FormLabel>
                <Input
                  name='description'
                  placeholder=''
                  ref={register({
                    maxLength: {
                      value: 1000,
                      message: 'Description exceeded 1000 maximum characters',
                    },
                  })}
                  defaultValue={description}
                />
                {errors.description && (
                  <FormErrorMessage>
                    <FormErrorIcon />
                    {errors.description?.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing={4}>
              <Button textStyle='button' variant='base.white' onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant='base.primary'
                type='submit'
                disabled={isDisabled}
                textStyle='button'
              >
                Save
              </Button>
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

export default EditGroup
