import React, { useContext, useState } from 'react'
import { ShowMessageModal } from '../components/Modal'

export type ErrorMessage = {
  title: string
  message: string
  callback?: () => void
}

interface ErrorProviderProps {
  children: React.ReactNode
}

interface ErrorContextType {
  errorMessage?: ErrorMessage
  setErrorMessage: React.Dispatch<ErrorMessage | undefined>
}

const ErrorContext = React.createContext<ErrorContextType>({
  errorMessage: undefined,
  setErrorMessage: () => {},
})

export const ErrorProvider = (props: ErrorProviderProps) => {
  const [errorMessage, setErrorMessage] = useState<ErrorMessage | undefined>(
    undefined
  )

  const errorState = {
    errorMessage,
    setErrorMessage,
  }

  return (
    <ErrorContext.Provider value={errorState}>
      <ShowMessageModal
        {...(errorMessage || { title: '', message: '' })}
        isOpen={!!errorMessage}
        hideCloseButton
        onClose={() => {
          if (errorMessage?.callback) {
            errorMessage.callback()
          }
          setErrorMessage(undefined)
        }}
        textProps={{
          fontSize: '16px',
          lineHeight: '24px',
        }}
      />
      {props.children}
    </ErrorContext.Provider>
  )
}

export const useErrorContext = () => {
  return useContext(ErrorContext)
}
