import { Layout } from '../Layout'
import {
  Container,
  Flex,
  VStack,
  Button,
  useDisclosure,
} from '@chakra-ui/react'
import { ConfirmationModal } from '../Modal'
import { AddSessionMembers } from '../Forms'
import { SessionInfo, SessionInput, SessionMembers } from './index'
import { useAlamContext } from '../../client'
import { TopNavItemProps } from '../TopNav'
import { Session, SessionDocument, SessionUser } from '../../types'
import { useMutation } from '@apollo/client'
import {
  ARCHIVE_SESSION,
  buildSessionUsers,
  LEAVE_SESSION,
  INSERT_SESSION_USERS,
  UPDATE_SESSION,
} from '../../client/queries'
import { useSuccessToast, useErrorToast } from '../../utils'
import { useHistory } from 'react-router-dom'
import {
  useGoogleAPI,
  buildSessionMemberPermissions,
  createPermissions,
  permissionType,
  deletePermission,
} from '../GoogleAPI'
import { useState, useMemo } from 'react'
import { documentPermission } from '../../pages/Session/SessionPage'

export type SessionInfoPageProps = {
  id: string
  sessionId: string
  topNavLeft: TopNavItemProps[]
  topNavCenter: TopNavItemProps[]
  session: Session
  refetch: () => void
  setSession: (session: Session) => void
  documentsPermissions: documentPermission[]
  setDocumentsPermissions: (documentsPermissions: documentPermission[]) => void
}

export const SessionInfoPage = ({
  id,
  sessionId,
  topNavLeft,
  topNavCenter,
  session,
  refetch,
  setSession,
  documentsPermissions,
  setDocumentsPermissions,
}: SessionInfoPageProps): JSX.Element => {
  const { teachers, students, user, classUser } = useAlamContext()
  const { isAuthorized, tokenIsExpired } = useGoogleAPI()
  const [permissionIsArchiving, setPermissionsIsArchiving] = useState(false)
  const { session_users } = session

  const sessionUserIds = useMemo(() => {
    return session_users.map(({ user }) => user.id)
  }, [session_users])

  const filteredUsers = useMemo(() => {
    return [...teachers, ...students].filter((classUser) => {
      return !sessionUserIds.includes(classUser?.user?.id || '')
    })
  }, [sessionUserIds, teachers, students])

  const [updateSession, { loading: sessionIsUpdating }] = useMutation(
    UPDATE_SESSION
  )
  const [sessionMemberIsAdding, setSessionMemberIsAdding] = useState(false)

  const handleUpdateSession = async (changes: SessionInput) => {
    try {
      await updateSession({
        variables: { session_id: sessionId, changes: changes },
      })
      setSession({ ...session, ...changes })
      useSuccessToast({ message: 'Session successfully updated' })
    } catch (error) {
      useErrorToast({ error })
    }

    refetch()
  }

  const {
    isOpen: showAddSessionMembers,
    onOpen: onOpenAddSessionMembers,
    onClose: onCloseAddSessionMembers,
  } = useDisclosure()

  const [
    insertSessionUsers,
    { loading: sessionUserIsSubmitting },
  ] = useMutation(INSERT_SESSION_USERS)

  const handleAddMember = async (userIds: string[]) => {
    setSessionMemberIsAdding(true)
    try {
      const response = await insertSessionUsers({
        variables: {
          session_users: buildSessionUsers(userIds, sessionId),
        },
      })

      const sessionMembers =
        response?.data.insert_session_users?.returning || []
      await handleShareFiles({ sessionMembers, documentsPermissions })

      refetch()
      useSuccessToast({ message: 'Users successfully added' })
    } catch (error) {
      useErrorToast({ error })
    }

    onCloseAddSessionMembers()
    setSessionMemberIsAdding(false)
  }

  // share all documents to new session member
  const handleShareFiles = async ({
    sessionMembers,
    documentsPermissions,
  }: {
    sessionMembers: SessionUser[]
    documentsPermissions: documentPermission[]
  }) => {
    try {
      if (!isAuthorized || tokenIsExpired()) {
        return
      }

      const permissions = buildSessionMemberPermissions(sessionMembers)

      const newDocumentsPermissions = await Promise.all(
        documentsPermissions.map(async (document) => {
          const [newPermission] = await createPermissions({
            fileId: document.file_id,
            permissions,
          })

          return {
            ...document,
            permissions: [...document.permissions, { ...newPermission.result }],
          }
        })
      )

      setDocumentsPermissions(newDocumentsPermissions)
    } catch (error) {
      return error
    }
  }

  const {
    isOpen: showLeaveSessionModal,
    onOpen: onOpenLeaveSessionModal,
    onClose: onCloseLeaveSessionModal,
  } = useDisclosure()

  const [leaveSession, { loading: sessionUserIsLeaving }] = useMutation(
    LEAVE_SESSION,
    {
      variables: { session_id: sessionId, user_id: user?.id },
      onCompleted: () => {
        onCloseLeaveSessionModal()
        useSuccessToast({ message: 'You have successfully left the session' })
        history.push(`/classes/${id}`)
      },
      onError: (error) => useErrorToast({ error }),
    }
  )

  const {
    isOpen: showCloseSession,
    onOpen: onOpenCloseSession,
    onClose: onCloseCloseSession,
  } = useDisclosure()
  const history = useHistory()

  const [archiveSession, { loading: sessionIsArchiving }] = useMutation(
    ARCHIVE_SESSION,
    {
      variables: { session_id: sessionId },
    }
  )

  const handleArchiveSession = async () => {
    try {
      const archivePromises =
        !isAuthorized || tokenIsExpired()
          ? [archiveSession()]
          : [archiveSession(), clearDocumentPermissions(documentsPermissions)]

      setPermissionsIsArchiving(true)
      await Promise.all(archivePromises)

      onCloseCloseSession()
      useSuccessToast({ message: 'Session successfully closed' })
      history.push(`/classes/${id}`)
    } catch (error) {
      useErrorToast({ error })
    }
    setPermissionsIsArchiving(false)
  }

  // delete all permissions except owner permissions for all docs
  // not possible. see comments in deleteMemberPermissions in SessionMembers.tsx
  const clearDocumentPermissions = async (
    documentsPermissions: documentPermission[]
  ) => {
    try {
      const res = await Promise.all(
        documentsPermissions.map(async (document) => {
          const { permissions, user: documentOwner, file_id: fileId } = document

          // if I own the file: remove all permissions other than owner
          // if somebody else owns file: just remove my permissions
          const permissionsToDelete =
            documentOwner.id === user?.id
              ? permissions.filter(
                  (permission) =>
                    permission.role !== 'owner' && !permission.deleted
                )
              : permissions.filter(
                  (permission) =>
                    permission.emailAddress === user?.email &&
                    !permission.deleted
                )

          return await Promise.all(
            permissionsToDelete.map(async (permission: permissionType) => {
              return await deletePermission({
                fileId,
                permissionId: permission.id,
              })
            })
          )
        })
      )
      return res
    } catch (error) {
      return error
    }
  }
  return (
    <>
      <ConfirmationModal
        isOpen={showLeaveSessionModal}
        onClose={onCloseLeaveSessionModal}
        title='Are you sure?'
        message={`You will no longer be able to access ${session.name}. This process is irreversible, and all the data and content will be removed.`}
        onSubmit={leaveSession}
        isSubmitting={sessionUserIsLeaving}
      />

      <ConfirmationModal
        isOpen={showCloseSession}
        onClose={onCloseCloseSession}
        title='Are you sure?'
        message={`${session.name} will be deleted. This process is irreversible, and all the data and content will be removed and no longer accessible`}
        onSubmit={handleArchiveSession}
        isSubmitting={sessionIsArchiving || permissionIsArchiving}
      />

      <AddSessionMembers
        isOpen={showAddSessionMembers}
        onClose={onCloseAddSessionMembers}
        handleAddMember={handleAddMember}
        isSubmitting={sessionUserIsSubmitting || sessionMemberIsAdding}
        classUsers={filteredUsers}
      />

      <Layout showSideNav topNavLeft={topNavLeft} topNavCenter={topNavCenter}>
        <Container
          maxWidth={{ base: '100%', md: '456px', lg: '500px', xl: '668px' }}
          py={6}
          h='auto'
        >
          <Flex direction='column' w='100%' h='100%'>
            <VStack spacing={8} h='100%'>
              <SessionInfo
                session={session}
                handleUpdateSession={handleUpdateSession}
                isSubmitting={sessionIsUpdating}
              />

              <SessionMembers
                onOpenAddSessionMembers={onOpenAddSessionMembers}
                session={session}
                session_users={session_users}
                refetch={refetch}
                documentsPermissions={documentsPermissions}
                setDocumentsPermissions={setDocumentsPermissions}
              />

              <Flex w='100%' justify='flex-end'>
                {session.user_id === user?.id ||
                ['admin', 'teacher', 'creator'].includes(classUser?.role) ? (
                  <Button
                    textStyle='button'
                    variant='base.error'
                    bg='#F42A2A none repeat scroll 0% 0%'
                    onClick={onOpenCloseSession}
                  >
                    Close Session
                  </Button>
                ) : (
                  <Button
                    textStyle='button'
                    variant='base.error'
                    bg='#F42A2A none repeat scroll 0% 0%'
                    onClick={onOpenLeaveSessionModal}
                  >
                    Leave Session
                  </Button>
                )}
              </Flex>
            </VStack>
          </Flex>
        </Container>
      </Layout>
    </>
  )
}

export default SessionInfoPage
