import { useContext, useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import { GroupMembersModal } from './'
import { useSuccessToast, useErrorToast } from '../../utils'
import { GroupsForm } from '../../components/Forms/'
import { CREATE_GROUP, CREATE_GROUP_MEMBERS } from '../../client/queries'
import { useMutation } from '@apollo/client'
import { v4 as uuid } from 'uuid'
import { useAlamContext } from '../../client'

export type GroupModalType = {
  isOpen: boolean
  onClose: () => void
  size: string
  refetch?: () => void
}

export type GroupMembersInput = {
  user_ids: string[]
}

export const GroupModal = ({
  isOpen,
  onClose,
  size,
  refetch = () => {},
}: GroupModalType) => {
  const alamContext = useAlamContext()
  const classId = alamContext.classUser?.class?.id
  const currentUser = alamContext.user
  const [createGroup, { data: createGroupData, loading: groupIsSubmitting, error: createGroupError }] = useMutation(
    CREATE_GROUP,
    {
      onError: (err) => {
        console.warn("onError: ", err)
        onClose()
        useErrorToast({ message: 'Group creation failed' })
      },
    }

  )
  const [
    createGroupMembers,
    { loading: groupMembersIsSubmitting },
  ] = useMutation(CREATE_GROUP_MEMBERS)
  const {
    isOpen: isOpenMember,
    onOpen: onOpenMember,
    onClose: onCloseMember,
  } = useDisclosure()
  const [groupId, setGroupId] = useState('')

  const handleSubmitCreateGroup = async ({ groupName, description }) => {
    console.log(
      `GroupModal, handleSubmitCreateGroup called with: groupName: ${groupName}, description: ${description}, classId: ${classId}, userId: ${currentUser.id}`
    )
    const newGroupId = uuid()
    console.log(`newGroupId: ${newGroupId}`)
    try {
      const groupPromises = await Promise.all([
        createGroup({
          variables: {
            id: newGroupId,
            name: groupName,
            description: description,
            class_id: classId,
            // user_id: currentUser.id,
            // inserted_at: new Date().toUTCString(),
            // updated_at: new Date().toUTCString(),
          },
        })
      ])
      console.log(`groupPromises: ${JSON.stringify(groupPromises)}`)
      useSuccessToast({
        title: 'Group created',
        message: 'Group was successfully created',
      })
      setGroupId(groupPromises[0].data?.createGroup?.id)
      onClose()
      onOpenMember()
      refetch()
      }
    catch (error) {
      console.warn(error)
      console.warn("group createGroupData = ", createGroupData)
      console.warn("createGroupError = ", createGroupError)
      useErrorToast({ message: `Group wasn't created` })
      onClose()
    }
  }

  const handleAddMembers = ({ user_ids }: GroupMembersInput) => {
    const members = []
    if (Array.isArray(user_ids)) {
      user_ids.map((userId) => {
        members.push({
          id: uuid(),
          user_id: userId,
          group_id: groupId,
          inserted_at: new Date().toUTCString(),
          updated_at: new Date().toUTCString(),
          archived_at: null,
        })
      })
    } else {
      members.push({
        id: uuid(),
        user_id: user_ids,
        group_id: groupId,
        inserted_at: new Date().toUTCString(),
        updated_at: new Date().toUTCString(),
        archived_at: null,
      })
    }
    try {
      createGroupMembers({
        variables: {
          members: members,
        },
      }).then(() => {
        useSuccessToast({
          title: 'Group members invited',
          message: `Members was successfully invited`,
        })
        onCloseMember()
        refetch()
      })
    } catch (error) {
      console.warn(error)
      useErrorToast({ message: `Group members wasn't invited` })
    }
  }

  return (
    <>
      <GroupMembersModal
        isOpen={isOpenMember}
        classId={classId}
        groupId={groupId}
        onSubmit={handleAddMembers}
        onClose={onCloseMember}
      />
      <GroupsForm
        isOpen={isOpen}
        onClose={onClose}
        size={size}
        onSubmit={handleSubmitCreateGroup}
        isSubmitting={groupIsSubmitting}
      />
    </>
  )
}
