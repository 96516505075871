import React, { useState, useEffect, useContext } from 'react'
import { useAlamContext } from '../../client'
import {
  GOOGLE_SCOPES,
  GOOGLE_CLIENT_ID,
  GOOGLE_DISCOVERY_DOCS,
} from '../../constants'
import { toDate, isPast } from 'date-fns'

export type GoogleAPIContextProps = {
  isAuthorized: boolean
  expiresAt?: Date
  tokenIsExpired: () => boolean
}

export const GoogleAPIContext = React.createContext<GoogleAPIContextProps>({
  isAuthorized: false,
  expiresAt: undefined,
  tokenIsExpired: () => false,
})

export const GoogleAPIProvider = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false)
  const [expiresAt, setExpiresAt] = useState<Date>()

  const {
    decodedToken: { google_claims } = {
      google_claims: { uid: '', token: '' },
    },
  } = useAlamContext()

  const uid = google_claims?.uid
  const token = google_claims?.token

  const tokenIsExpired = () => {
    return expiresAt ? isPast(expiresAt) : false
  }

  const handleClientLoad = () => {
    const check = () => {
      if (window?.gapi && uid && token) {
        window.gapi.load('client:auth2', () => initClient())
      } else {
        setTimeout(check, 20)
      }
    }

    check()
  }

  useEffect(handleClientLoad, [uid, token])

  const initClient = () => {
    // @ts-ignore
    window.gapi.auth2.authorize(
      {
        response_type: 'permission',
        scope: GOOGLE_SCOPES,
        client_id: GOOGLE_CLIENT_ID,
        prompt: 'none',
        login_hint: uid,
      },
      (response: any) => {
        const expiration = response?.expires_at
        if (expiration) {
          setExpiresAt(toDate(expiration))
        }

        window.gapi.client
          .init({
            discoveryDocs: GOOGLE_DISCOVERY_DOCS,
          })
          .then(() => {
            setIsAuthorized(true)
          })
      }
    )
  }

  const contextState = { isAuthorized, expiresAt, tokenIsExpired }

  return (
    <GoogleAPIContext.Provider value={contextState}>
      {children}
    </GoogleAPIContext.Provider>
  )
}

export const useGoogleAPI = () => useContext(GoogleAPIContext)
