import { ClassUser } from '../../types'
import { SidebarSubHeading } from '../Heading'
import { ManagePeopleItem } from './index'
import { CheckboxGroup, Checkbox, Flex } from '@chakra-ui/react'
import { isMobileDevice } from '../../utils'
import { useParams } from 'react-router'
import { useAlamContext } from '../../client'

export type ManagePeopleItemsProps = {
  classUsers: ClassUser[]
  title?: string
  selected: string[]
  handleChange: (newSelected: string[]) => void
  isSelected: (id: string) => boolean
}

export const ManagePeopleItems = ({
  classUsers,
  title,
  selected,
  handleChange,
  isSelected,
}: ManagePeopleItemsProps) => {
  const { classUser: currentClassUser } = useAlamContext()

  const { group_id: groupId } = useParams<{ group_id?: string }>()

  return classUsers?.length >= 1 ? (
    <>
      {title && (
        <SidebarSubHeading
          title={title}
          containerProps={{ padding: '8px 0px 8px 16px' }}
        />
      )}

      {isMobileDevice() ? (
        classUsers.map((classUser: ClassUser) => (
          <Flex w='100%' padding='8px 8px 8px 16px'>
            <ManagePeopleItem classUser={classUser} isSelected={isSelected} />
          </Flex>
        ))
      ) : (
        <CheckboxGroup value={selected} onChange={handleChange}>
          {classUsers.map((classUser: ClassUser) => (
            <Checkbox
              key={classUser.id}
              css={{
                '& > .chakra-checkbox__control': {
                  marginLeft: '12px',
                  marginRight: '8px',
                },
                '& > .chakra-checkbox__control[data-disabled]': {
                  display: 'none',
                },
                '& > .chakra-checkbox__label': {
                  marginLeft: '0',
                },
                '& > .chakra-checkbox__label[data-disabled]': {
                  marginLeft: '12px',
                  opacity: '1',
                },
              }}
              variant='managePeople'
              value={groupId ? classUser.user!.id : classUser.id}
              backgroundColor={
                isSelected(groupId ? classUser.user!.id : classUser.id)
                  ? 'accent.1'
                  : 'white.1'
              }
              w='248px'
              overflow='hidden'
              isDisabled={
                (['teacher', 'student'].includes(currentClassUser?.role) &&
                  ['admin', 'creator'].includes(classUser.role)) ||
                classUser.role === 'creator'
              }
            >
              <ManagePeopleItem classUser={classUser} isSelected={isSelected} />
            </Checkbox>
          ))}
        </CheckboxGroup>
      )}
    </>
  ) : null
}

export default ManagePeopleItems
