import React from 'react'
import { Post } from './index'
import { Post as PostType } from '../../types'
import { Flex, Text } from '@chakra-ui/react'

export type PostsProps = {
  posts: PostType[]
  handleDeletePost: (postId: string, onClodeDeletePost: () => void) => void
  postsFor?: string
  postFormIsOpen?: boolean
  announcementFormIsOpen?: boolean
}

export const Posts = ({
  posts,
  handleDeletePost,
  postsFor = 'Classroom',
  postFormIsOpen,
  announcementFormIsOpen,
}: PostsProps): JSX.Element => {
  return posts.length === 0 ? (
    <Flex
      display={postFormIsOpen || announcementFormIsOpen ? 'none' : 'flex'}
      align='center'
      justify='center'
      w='100%'
      layerStyle='post.empty'
      grow={1}
    >
      <Text textStyle='body.big' color='primary.2' py={6}>
        {postsFor} has no posts
      </Text>
    </Flex>
  ) : (
    <>
      {posts.map((post, index) => {
        return (
          <Post
            key={index}
            post={post}
            handleDeletePost={handleDeletePost}
            isGroup={postsFor === 'Group'}
          />
        )
      })}
    </>
  )
}

export default Posts
