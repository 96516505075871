const layerStyles = {
  nav: {
    text: {
      selected: {
        color: 'primary.1',
      },
      inactive: {
        color: 'primary.2',
      },
    },
  },
  sidenav: {
    selected: {
      bg: 'white.1',
      color: 'primary.1',
    },
    inactive: {
      color: 'white.1',
      bg: 'primary.1',
    },
  },
  card: {
    base: {
      bg: 'white.1',
      borderWidth: '.5px',
      borderColor: 'grey.border',
      boxShadow: '0px 4px 16px primary.3',
      borderRadius: '12px',
    },
    expanded: {
      borderTopRadius: '12px',
    },
    board: {
      borderWidth: '.5px',
      boxShadow: '0px 4px 8px rgba(23, 5, 61, 0.32)',
      borderColor: 'grey.border',
      borderRadius: '12px',
    },
    module: {
      borderWidth: '.5px',
      boxShadow:
        'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
      borderColor: 'grey.border',
      borderRadius: '12px',
    },
    'board-updated-shadow': {
      borderWidth: '.5px',
      boxShadow:
        'rgb(0 0 0 / 10%) 0px 4px 6px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px',
      borderColor: 'grey.border',
      borderRadius: '12px',
    },
    'board-flat': {
      boxShadow: '0px 4px 16px rgba(23, 5, 61, 0.08)',
    },
    input: {
      bg: 'grey.2',
      p: '8px 16px',
      borderRadius: '8px',
      h: '48px',
    },
  },
  avatar: {
    sm: {
      w: '24px',
      h: '24px',
    },
    md: {
      w: '32px',
      h: '32px',
    },
    lg: {
      w: '40px',
      h: '40px',
    },
    xl: {
      w: '72px',
      h: '72px',
    },
    '2xl': {
      w: '120px',
      h: '120px',
    },
  },
  divider: {
    nospace: {
      borderColor: 'grey.border',
      my: '0 !important',
    },
    borderColor: 'grey.border',
  },
  dividerGrey: {
    nospace: {
      borderColor: 'rgb(23,5,61,0.32)',
      my: '0 !important',
    },
    borderColor: 'rgb(23,5,61,0.32)',
  },
  post: {
    empty: {
      bg: 'white.3',
      borderRadius: '16px',
    },
  },
  bulletpoint: {
    w: '2px',
    h: '2px',
    bg: 'black.1',
    borderRadius: '50%',
  },
  bulletpoint3: {
    w: '3px',
    h: '3px',
    bg: 'black.1',
    borderRadius: '50%',
  },
  menu: {
    item: {
      checkbox: {
        pl: '20px',
        _focus: {
          color: 'primary.1',
          bg: 'white.1',
        },
        _active: {
          color: 'primary.1',
          bg: 'white.1',
        },

        indented: {
          _focus: {
            color: 'primary.1',
            bg: 'white.1',
          },
          _active: {
            color: 'primary.1',
            bg: 'white.1',
          },
          w: '200px',
          h: 'auto',
          py: '12px',
          pr: '8px',
          pl: '38px',
        },
      },
      normal: {
        w: '100%',
        h: 'auto',
        px: '16px',
        py: '13px',
      },
    },
  },
  modal: {
    leftCloseButton: {
      pos: 'absolute',
      top: '13px',
      insetInlineEnd: 'unset',
      left: '13px',
    },
  },
}

export default layerStyles
