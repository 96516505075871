import React from 'react'
import ClassGradesPage from './ClassGradesPage'
import { useParams } from 'react-router-dom'

const ClassGrades = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  return <ClassGradesPage id={id} />
}

export default ClassGrades
