import { gql } from '@apollo/client'
import { materialFields, assignmentFields } from './fragments'

export const CREATE_TOPIC = gql`
  mutation create_topic($topic_id: uuid!, $name: String!, $class_id: uuid!) {
    insert_topics_one(
      object: {
        id: $topic_id
        name: $name
        inserted_at: "NOW()"
        updated_at: "NOW()"
        class: {
          data: { id: $class_id, inserted_at: "NOW()", updated_at: "NOW()" }
          on_conflict: { constraint: classes_pkey, update_columns: [updated_at] }
        }
      }
    ) {
      id
      name
      materials {
        id
        title
      }
    }
  }
`

export const GET_GROUP_TOPICS = gql`
  ${materialFields}
  ${assignmentFields}
  query get_group_topic($group_id: uuid!) {
    topics(
      where: {
        _or: [
          {
            assignments: { assignment_groups: { group_id: { _eq: $group_id } } }
          }
          { materials: { material_groups: { group_id: { _eq: $group_id } } } }
        ]
      }
      order_by: [{ name: asc }]
    ) {
      id
      name
      materials(
        where: {
          _and: [
            { archived_at: { _is_null: true } }
            { is_shown: { _eq: true } }
            { material_groups: { group_id: { _eq: $group_id } } }
          ]
        }
        order_by: [{ inserted_at: desc_nulls_last }]
      ) {
        ...materialFields
      }
      assignments(
        where: {
          _and: [
            { archived_at: { _is_null: true } }
            { schedule: { _lte: "NOW()" } }
            { is_visible: { _eq: true } }
            { assignment_groups: { group_id: { _eq: $group_id } } }
          ]
        }
        order_by: [{ inserted_at: desc_nulls_last }]
      ) {
        ...assignmentFields
      }
    }
  }
`

export const GET_GROUP_MATERIALS_ASSIGNMENTS = gql`
  ${materialFields}
  ${assignmentFields}
  subscription get_group_materials_assignments($group_id: uuid!) {
    materials(
      where: {
        class: { archived_at: { _is_null: true } }
        material_groups: { group_id: { _eq: $group_id } }
        topic_id: { _is_null: true }
      }
    ) {
      ...materialFields
    }
    assignments(
      where: {
        class: { archived_at: { _is_null: true } }
        assignment_groups: { group_id: { _eq: $group_id } }
        topic_id: { _is_null: true }
      }
    ) {
      ...assignmentFields
    }
  }
`

export const GET_GROUP_MATERIALS = gql`
  ${materialFields}
  subscription get_group_materials($group_id: uuid!) {
    materials(
      where: {
        class: { archived_at: { _is_null: true } }
        material_groups: { group_id: { _eq: $group_id } }
        topic_id: { _is_null: true }
      }
      order_by: [{ inserted_at: desc_nulls_last }]
    ) {
      ...materialFields
    }
  }
`

export const GET_GROUP_ASSIGNMENTS = gql`
  ${assignmentFields}
  subscription get_group_assignments($group_id: uuid!) {
    assignments(
      where: {
        class: { archived_at: { _is_null: true } }
        assignment_groups: { group_id: { _eq: $group_id } }
        topic_id: { _is_null: true }
      }
      order_by: [{ inserted_at: desc_nulls_last }]
    ) {
      ...assignmentFields
    }
  }
`

export const UPDATE_TOPIC = gql`
  mutation update_topic($id: uuid!, $changes: topics_set_input!) {
    update_topics(where: { id: { _eq: $id } }, _set: $changes) {
      returning {
        id
      }
    }
  }
`
