import React from 'react'
import { IconAlamProps } from './Type'

export const PencilBox = ({
  fill,
  width,
  height,
  viewBox,
}: IconAlamProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M27 11C27.5304 11 28.0391 11.2107 28.4142 11.5858C28.7893 11.9609 29 12.4696 29 13V27C29 28.11 28.1 29 27 29H13C12.4696 29 11.9609 28.7893 11.5858 28.4142C11.2107 28.0391 11 27.5304 11 27V13C11 12.4696 11.2107 11.9609 11.5858 11.5858C11.9609 11.2107 12.4696 11 13 11H27ZM24.7 17.35C24.92 17.14 24.92 16.79 24.7 16.58L23.42 15.3C23.21 15.08 22.86 15.08 22.65 15.3L21.65 16.3L23.7 18.35L24.7 17.35ZM15 22.94V25H17.06L23.12 18.94L21.06 16.88L15 22.94Z'
      fill={fill}
    />
  </svg>
)

PencilBox.defaultProps = {
  fill: '#17053D',
  width: '40',
  height: '40',
  viewBox: '0 0 40 40',
}
