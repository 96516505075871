import React from 'react'
import { useParams } from 'react-router-dom'
import MaterialPage from './MaterialPage'

const Material = (): JSX.Element => {
  const { id, materialId, group_id } = useParams<{
    id: string
    group_id?: string
    materialId: string
  }>()
  return (
    <div>
      <MaterialPage id={id} materialId={materialId} groupId={group_id} />
    </div>
  )
}

export default Material
