import { useLocation } from 'react-router-dom'
import { useScheme } from './index'

export const useSearchParams = () => {
  return new URLSearchParams(useLocation().search)
}

export interface buildURLProps {
  host: string
  path?: string
}

export const buildURL = ({ host, path = '/' }: buildURLProps) => {
  return `${useScheme('http')}://${host}${path}`
}
