import React from 'react'
import { IconAlamProps } from './Type'

export const Announcement = ({
  fill,
  width,
  height,
  viewBox,
}: IconAlamProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M24 20V24C24 24.2652 23.8946 24.5196 23.7071 24.7071C23.5196 24.8946 23.2652 25 23 25C22.83 25 22.67 25 22.06 24.5C21.44 24 20.39 23 19.31 22.5C18.31 22.04 17.28 22 16.26 22L17.47 25.32L17.5 25.5C17.5 25.6326 17.4473 25.7598 17.3536 25.8536C17.2598 25.9473 17.1326 26 17 26H15C14.78 26 14.59 25.86 14.53 25.66L13.19 22H13C12.7348 22 12.4804 21.8946 12.2929 21.7071C12.1054 21.5196 12 21.2652 12 21C11.4696 21 10.9609 20.7893 10.5858 20.4142C10.2107 20.0391 10 19.5304 10 19C10 18.4696 10.2107 17.9609 10.5858 17.5858C10.9609 17.2107 11.4696 17 12 17C12 16.7348 12.1054 16.4804 12.2929 16.2929C12.4804 16.1054 12.7348 16 13 16H16C17.11 16 18.22 16 19.31 15.5C20.39 15 21.44 14 22.06 13.5C22.67 13 22.83 13 23 13C23.2652 13 23.5196 13.1054 23.7071 13.2929C23.8946 13.4804 24 13.7348 24 14V18C24.2652 18 24.5196 18.1054 24.7071 18.2929C24.8946 18.4804 25 18.7348 25 19C25 19.2652 24.8946 19.5196 24.7071 19.7071C24.5196 19.8946 24.2652 20 24 20ZM29 19C29 20.38 28.44 21.63 27.54 22.54L26.12 21.12C26.66 20.58 27 19.83 27 19C27 18.17 26.66 17.42 26.12 16.88L27.54 15.46C28.44 16.37 29 17.62 29 19Z'
      fill={fill}
    />
  </svg>
)

Announcement.defaultProps = {
  fill: '#17053D',
  width: '40',
  height: '40',
  viewBox: '0 0 40 40',
}
