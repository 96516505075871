import { useContext } from 'react'
import { Flex, Box } from '@chakra-ui/react'
import { Submission } from '../../types'
import { SideNavItem } from './index'
import SubmissionContext from '../../pages/Assignment/SubmissionContext'
import { SidebarHeading } from '../Heading'

export type SideNavListProps = {
  title: string
  submissions: Submission[]
}

export const SideNavList = ({ title, submissions }: SideNavListProps) => {
  const {
    selectedSubmission,
    setSelectedSubmission,
    assignment,
    setStatus,
    groupId,
  } = useContext(SubmissionContext)

  return (
    <Flex direction='column'>
      <SidebarHeading title={title} />

      <Box>
        {submissions?.map((submission) => {
          return (
            <SideNavItem
              key={submission?.id}
              submission={submission}
              setSelectedSubmission={setSelectedSubmission}
              selectedSubmission={selectedSubmission}
              grading={assignment?.grading}
              rubric={assignment?.rubric}
              setStatus={setStatus}
              groupId={groupId}
            />
          )
        })}
      </Box>
    </Flex>
  )
}
