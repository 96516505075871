import React from 'react'
import { useSubscription } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { useAlamContext } from '../../client'
import { IS_STUDENT_REMOVED_FROM_GROUP } from '../../client/queries'
import { useDisclosure } from '@chakra-ui/react'
import { ConfirmationModal } from '../../components/Modal'
import { useHistory } from 'react-router-dom'
import { RenderForUser } from '../../components/SharedComponents'

interface GroupUserAuthenticationProps {
  children: React.ReactNode
}

const GroupUserAuthentication = (props: GroupUserAuthenticationProps) => {
  const { group_id } = useParams<{ group_id: string }>()
  const { user } = useAlamContext()
  const history = useHistory()

  const {
    isOpen: studentRemovedIsOpen,
    onClose: studentRemovedOnClose,
    onOpen: studentRemovedOnOpen,
  } = useDisclosure()

  useSubscription(IS_STUDENT_REMOVED_FROM_GROUP, {
    variables: {
      current_user_id: user?.id,
      group_id,
    },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (data?.group_users.length === 0) {
        studentRemovedOnOpen()
      }
    },
  })

  return (
    <>
      <RenderForUser roles={['student']}>
        <ConfirmationModal
          isOpen={studentRemovedIsOpen}
          onClose={studentRemovedOnClose}
          title='Removed from group'
          message='Sorry you have been removed in the Group'
          onSubmit={() => history.push('/')}
          showCloseButton={false}
          confirmButtonText='Proceed'
        />
      </RenderForUser>

      <React.Fragment>{props.children}</React.Fragment>
    </>
  )
}

export default GroupUserAuthentication
