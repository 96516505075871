import React from 'react'
import { Icon } from '@chakra-ui/react'

export const DeleteUser = (props: Record<string, any>): JSX.Element => {
  return (
    <Icon
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      xmlSpace='preserve'
      {...props}
    >
      <path
        d='M13.54 10C16.21 10 21.54 11.33 21.54 14V16H5.54V14C5.54 11.33 10.87 10 13.54 10ZM13.54 8C12.4791 8 11.4617 7.57857 10.7116 6.82843C9.96143 6.07828 9.54 5.06087 9.54 4C9.54 2.93913 9.96143 1.92172 10.7116 1.17157C11.4617 0.421427 12.4791 0 13.54 0C14.6009 0 15.6183 0.421427 16.3684 1.17157C17.1186 1.92172 17.54 2.93913 17.54 4C17.54 5.06087 17.1186 6.07828 16.3684 6.82843C15.6183 7.57857 14.6009 8 13.54 8ZM3.54 5.59L5.66 3.46L7.08 4.88L4.95 7L7.08 9.12L5.66 10.54L3.54 8.41L1.42 10.54L3.8147e-08 9.12L2.13 7L3.8147e-08 4.88L1.42 3.46L3.54 5.59Z'
        fill='currentColor'
      />
    </Icon>
  )
}

export default DeleteUser
