import React from 'react'
import { Icon } from '@chakra-ui/react'

export const GoogleDocs = (props: Record<string, any>) => {
  return (
    <Icon
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      height='24'
      width='24'
      viewBox='0 0 512 512'
      xmlSpace='preserve'
      boxSize='20px'
      {...props}
    >
      <path
        fill='#518EF8'
        d='M441.412,140.235v338.781c0,18.219-14.778,32.983-32.983,32.983H103.572
      c-18.219,0-32.983-14.764-32.983-32.983V32.983C70.588,14.764,85.352,0,103.572,0h197.605L441.412,140.235z'
      />
      <g>
        <rect
          x='161.18'
          y='257.054'
          fill='#FFFFFF'
          width='189.626'
          height='21.071'
        />
        <rect
          x='161.18'
          y='304.112'
          fill='#FFFFFF'
          width='189.626'
          height='21.071'
        />
        <rect
          x='161.18'
          y='351.171'
          fill='#FFFFFF'
          width='189.626'
          height='21.071'
        />
        <rect
          x='161.18'
          y='398.23'
          fill='#FFFFFF'
          width='134.855'
          height='21.071'
        />
      </g>
      <polygon
        fill='#3A5BBC'
        points='320.31,137.188 441.412,187.079 441.412,140.235 372.751,119.962 '
      />
      <path
        fill='#ACD1FC'
        d='M441.412,140.235H334.16c-18.22,0-32.983-14.764-32.983-32.983V0L441.412,140.235z'
      />
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </Icon>
  )
}

export default GoogleDocs
