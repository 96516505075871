import { useMediaQuery, useTheme } from '@chakra-ui/react'

// useMediaQuery helper
export const useBreakpoint = () => {
  const { breakpoints: b } = useTheme()
  const [xs, sm, md, lg, xl] = useMediaQuery([
    `(min-width: ${b.base}) and (max-width: ${b.xs})`,
    `(min-width: ${b.xs}) and (max-width: ${b.sm})`,
    `(min-width: ${b.sm}) and (max-width: ${b.md})`,
    `(min-width: ${b.md}) and (max-width: ${b.lg})`,
    `(min-width: ${b.lg})`,
  ])

  return { xs, sm, md, lg, xl }
}
