import { createStandaloneToast, Box, Text, Flex, Icon } from '@chakra-ui/react'
import { IoMdCloseCircle, IoMdCheckmarkCircle } from 'react-icons/io'
import { IconType } from 'react-icons/lib'
// @ts-ignore
import noInternet from 'no-internet'

export type toast<T> = (props: T) => void

export interface toastProps {
  position?: string
  title?: string
  message?: string
  status?: string
  isClosable?: boolean
  icon?: IconType
}

export interface hasuraErrorSignature {
  extensions?: {
    code: string
    path: string
  }
  message: string
}

export interface errorToastProps extends toastProps {
  error?: hasuraErrorSignature
}

const getErrorMessage = (message?: string, error?: hasuraErrorSignature) => {
  if (error?.extensions?.code === 'permission-error') {
    return 'Insufficient permission to perform operation'
  }
  // Handle other hasura error codes here

  return message
}

export const useErrorToast: toast<errorToastProps> = ({
  title = 'An Error Occured',
  message,
  error,
}) => {
  noInternet().then((offline: boolean) => {
    if (offline) {
      renderErrorToast({
        message: 'You are currently offline',
      })
    } else {
      renderErrorToast({
        title,
        message,
        error,
      })
    }
  })
}

export const renderErrorToast: toast<errorToastProps> = ({
  title = 'An Error Occured',
  message,
  error,
}) => {
  const toast = createStandaloneToast()

  const errorMessage = getErrorMessage(message, error)

  toast({
    position: 'top-right',
    status: 'error',
    duration: 5000,
    render: () => (
      <Box bg='#FF0000' padding={4} borderRadius='8px'>
        <Flex>
          <Icon as={IoMdCloseCircle} fontSize='xl' marginRight='8px' />
          <Box>
            <Text
              color='white'
              fontSize='14px'
              fontWeight='600'
              isTruncated
              maxW='400px'
            >
              {title}
            </Text>
            <Text
              color='rgba(255, 255, 255, 0.72)'
              fontSize='12px'
              fontWeight='400'
            >
              {errorMessage}
            </Text>
          </Box>
        </Flex>
      </Box>
    ),
  })
}

export const useSuccessToast: toast<toastProps> = ({
  title = 'Success',
  message,
  icon = IoMdCheckmarkCircle,
}) => {
  const toast = createStandaloneToast()

  toast({
    position: 'top-right',
    status: 'success',
    duration: 5000,
    render: () => (
      <Box bg='#27AE60' padding={4} borderRadius='8px'>
        <Flex>
          <Icon as={icon} fontSize='xl' marginRight='8px' />
          <Box>
            <Text
              color='white'
              fontSize='14px'
              fontWeight='600'
              isTruncated
              maxW='400px'
            >
              {title}
            </Text>
            <Text
              color='rgba(255, 255, 255, 0.72)'
              fontSize='12px'
              fontWeight='400'
            >
              {message}
            </Text>
          </Box>
        </Flex>
      </Box>
    ),
  })
}
