import React from 'react'
import { IconAlamProps } from './Type'

export const Image = ({
  fill,
  width,
  height,
  viewBox,
}: IconAlamProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M21 17H26.5L21 11.5V17ZM14 10H22L28 16V28C28 28.5304 27.7893 29.0391 27.4142 29.4142C27.0391 29.7893 26.5304 30 26 30H14C12.89 30 12 29.1 12 28V12C12 10.89 12.89 10 14 10ZM14 28H23H26V20L22 24L20 22L14 28ZM16 17C15.4696 17 14.9609 17.2107 14.5858 17.5858C14.2107 17.9609 14 18.4696 14 19C14 19.5304 14.2107 20.0391 14.5858 20.4142C14.9609 20.7893 15.4696 21 16 21C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19C18 18.4696 17.7893 17.9609 17.4142 17.5858C17.0391 17.2107 16.5304 17 16 17Z'
      fill={fill}
    />
  </svg>
)

Image.defaultProps = {
  fill: '#17053D',
  width: '40',
  height: '40',
  viewBox: '0 0 40 40',
}
