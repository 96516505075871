import React, { useEffect } from 'react'
import {
  Box,
  Flex,
  Text,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Link,
  useClipboard,
  useDisclosure,
} from '@chakra-ui/react'
import { isLargerThanTablet, useErrorToast, useSuccessToast } from '../../utils'
import { MdContentCopy } from 'react-icons/md'
import { RiPencilFill } from 'react-icons/ri'
import { FaTrash } from 'react-icons/fa'
import { SessionLink as LinkType } from '../../types'
import { CreateLinkForm, CreateLinkInput } from '../Forms'
import { useMutation } from '@apollo/client'
import { UPDATE_LINK } from '../../client/queries'
import { ConfirmationModal } from '../Modal'
import { More, Link as LinkIcon } from '../Icons'

export type SessionLinkProps = {
  link: LinkType
  refetch: () => void
}

export const SessionLink = ({
  link,
  refetch,
}: SessionLinkProps): JSX.Element => {
  const [updateLink, { loading: updateLinkLoading }] = useMutation(UPDATE_LINK)
  const { onCopy } = useClipboard(link.url)
  const {
    isOpen: showAddLink,
    onOpen: onOpenAddLink,
    onClose: onCloseAddLink,
  } = useDisclosure()
  const {
    isOpen: showDeleteLink,
    onOpen: onOpenDeleteLink,
    onClose: onCloseDeleteLink,
  } = useDisclosure()

  const handleCopyLink = () => {
    if (onCopy) {
      onCopy()
      useSuccessToast({ message: 'Copied to clipboard' })
    }
  }

  const handleUpdateLink = async ({ name, url }: CreateLinkInput) => {
    try {
      await updateLink({
        variables: {
          link_id: link.id,
          changes: {
            name,
            url,
          },
        },
      })

      refetch()
      useSuccessToast({
        title: 'Link Updated',
        message: `${name} was successfully updated`,
      })
      onCloseAddLink()
    } catch (error) {
      console.warn(error)
      useErrorToast({ message: `Link wasn't updated` })
    }
  }

  const handleDeleteLink = async () => {
    try {
      await updateLink({
        variables: {
          link_id: link.id,
          changes: {
            archived_at: 'NOW()',
          },
        },
      })

      refetch()
      useSuccessToast({
        title: 'Link deleted',
        message: 'Link was successfully deleted',
      })
      onCloseDeleteLink()
    } catch (error) {
      console.warn(error)
      useErrorToast({ message: 'Failed to delete link' })
    }
  }

  const handleOpenAddLink = async () => {
    try {
      refetch()
      onOpenAddLink()
    } catch (error) {
      console.warn(error)
    }
  }

  useEffect(() => {
    refetch()
  }, [link])

  return (
    <>
      <Box
        css={{
          '&:hover > .link-wrapper > .link-box > .link-menu': {
            visibility: 'visible',
          },
        }}
      >
        <Flex justify='space-between' align='center' className='link-wrapper'>
          <Link href={link.url} isExternal>
            <Flex align='center' cursor='pointer'>
              <Flex
                borderRadius='4px'
                marginRight='12px'
                align='center'
                justify='center'
                p='8px'
              >
                <LinkIcon width='22' height='22' viewBox='8 8 24 24' />
              </Flex>

              <Box>
                <Text
                  textStyle='body.semibold'
                  color='rgba(0, 0, 0, 0.88)'
                  wordBreak='break-word'
                >
                  {link.name}
                </Text>
              </Box>
            </Flex>
          </Link>

          <Box className='link-box'>
            <Box
              className='link-menu'
              visibility={isLargerThanTablet() ? 'hidden' : 'visible'}
            >
              <Menu closeOnSelect>
                <MenuButton
                  as={IconButton}
                  aria-label='Link actions'
                  icon={<More width='22' height='22' viewBox='8 8 24 24' />}
                  variant='icon.button'
                />
                <MenuList>
                  <MenuItem icon={<MdContentCopy />} onClick={handleCopyLink}>
                    Copy Link
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem icon={<RiPencilFill />} onClick={handleOpenAddLink}>
                    Edit Link
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem icon={<FaTrash />} onClick={onOpenDeleteLink}>
                    Remove
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </Box>
        </Flex>
      </Box>

      <CreateLinkForm
        isOpen={showAddLink}
        onClose={onCloseAddLink}
        onSubmit={handleUpdateLink}
        isSubmitting={updateLinkLoading}
        name={link.name}
        url={link.url}
        isEditing
      />

      <ConfirmationModal
        isOpen={showDeleteLink}
        onClose={onCloseDeleteLink}
        title={`Are you sure you want to delete ${link.name}?`}
        message={`${link.name} will be deleted. This process is irreversible, and all data and content will be removed and no longer accessible.`}
        onSubmit={handleDeleteLink}
      />
    </>
  )
}

export default SessionLink
