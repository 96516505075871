import { Button, Flex, Spacer, Text } from '@chakra-ui/react'
import React from 'react'
import { FaPlusCircle } from 'react-icons/fa'
import { QuestionForm, Questions } from '../Exam'
import { useExamFormContext } from '../../context'
import { ModalState } from '../Assignment'
import { CustomFooter } from '../Modal'
import { validateResponseQuestion } from '../../utils'
import { RenderForDevice } from '../SharedComponents'

export type ExamFormProps = {
  placeholderText: string
  setModalState: React.Dispatch<ModalState>
  display: string
  handleSubmit: () => void
  isSubmitting?: boolean
  submitText: string
}

export const ExamForm = ({
  placeholderText,
  setModalState,
  display,
  handleSubmit,
  isSubmitting,
  submitText,
}: ExamFormProps): JSX.Element => {
  const {
    selectedExamArrangement,
    createQuestion,
    setSelectedExamArrangement,
    updateQuestionType,
    updateQuestionText,
    exam_arrangements,
    addResponseChoice,
    removeQuestion,
    setRemoveOnCancel,
  } = useExamFormContext()

  const onClickBack = () => {
    setRemoveOnCancel(false)
    setSelectedExamArrangement(undefined)
    setModalState({
      title: '',
      showQuestionHeader: false,
      onClickBack: () => {},
    })
  }

  const changeModalHeaderToSelected = () => {
    setModalState(modalState)
  }

  const modalState = {
    title: display,
    showQuestionHeader: true,
    onClickBack,
  }

  const handleCreateQuestion = () => {
    createQuestion()
    changeModalHeaderToSelected()
  }

  const submitIsDisabled = () => {
    if (exam_arrangements && exam_arrangements?.length <= 0) {
      return true
    }

    // traverses questions and their response for any errors
    return exam_arrangements?.some(
      ({ question: { text, response_choices_arrangements } }) => {
        const questionTextError = !!validateResponseQuestion({ value: text })

        const responseChoicesError = response_choices_arrangements.some(
          ({ response_choice: { text: responseChoiceText } }) => {
            return !!validateResponseQuestion({ value: responseChoiceText })
          }
        )

        return responseChoicesError || questionTextError
      }
    )
  }

  return selectedExamArrangement ? (
    <QuestionForm
      examArrangement={selectedExamArrangement}
      updateQuestionType={updateQuestionType}
      updateQuestionText={updateQuestionText}
      onClickBack={onClickBack}
      addResponseChoice={addResponseChoice}
      removeQuestion={removeQuestion}
    />
  ) : (
    <Flex direction='column' w='100%' minH='705px' pos='relative'>
      <Flex p='24px' borderBottom='.5px solid' borderBottomColor='primary.2'>
        <Text textStyle='text' color='primary.1'>
          Questions
        </Text>
      </Flex>

      <Questions
        examArrangements={exam_arrangements}
        placeholderText={placeholderText}
        changeModalHeaderToSelected={changeModalHeaderToSelected}
      />

      <Flex p='16px 24px 16px 24px'>
        <Button
          variant='base.white'
          leftIcon={<FaPlusCircle />}
          onClick={handleCreateQuestion}
          textStyle='button'
        >
          Add Question
        </Button>
      </Flex>

      <RenderForDevice sizes={['md', 'lg', 'xl']}>
        <Spacer />
      </RenderForDevice>

      <CustomFooter
        isSubmitting={isSubmitting}
        submitText={submitText}
        handleSubmit={handleSubmit}
        submitDisabled={submitIsDisabled()}
      />
    </Flex>
  )
}

export default ExamForm
