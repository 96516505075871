import {
  Flex,
  Spacer,
  Text,
  Tooltip,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
} from '@chakra-ui/react'
import { ClassUser } from '../../types'
import {
  getFullName,
  useSuccessToast,
  isMobileDevice,
  getFirstAndLastInitial,
} from '../../utils'
import { DELETE_PERSON, REMOVE_GROUP_MEMBERS } from '../../client/queries'
import { useAlamContext } from '../../client'
import { useMutation } from '@apollo/client'
import { AlamAvatar } from '../Avatar'
import { AccountSwitch, Delete, DeleteUser } from '../Icons'
import { SwitchRole, UserProfile } from '../Modal'
import { RenderForUser } from '../SharedComponents'
import { ConfirmationModal } from '../Modal'
import { useParams } from 'react-router-dom'
import { RenderForDevice } from '../SharedComponents'
import { MdMoreHoriz } from 'react-icons/md'

export type ManagePeopleItemProps = {
  classUser: ClassUser
  isSelected: (id: string) => boolean
}

export const ManagePeopleItem = ({
  classUser,
  isSelected,
}: ManagePeopleItemProps): JSX.Element => {
  const { classUser: currentClassUser } = useAlamContext()
  const { user } = classUser
  const name = getFullName(user)
  const {
    isOpen: deleteIsOpen,
    onOpen: deleteOnOpen,
    onClose: deleteOnClose,
  } = useDisclosure()

  const { group_id: groupId } = useParams<{ group_id?: string }>()

  const [deletePerson] = useMutation(DELETE_PERSON, {
    onCompleted: () => deleteOnClose(),
  })

  const [removeGroupMembers] = useMutation(REMOVE_GROUP_MEMBERS, {
    onCompleted: () => {
      deleteOnClose()
    },
  })

  const handleDeletePerson = () => {
    if (groupId) {
      removeGroupMembers({
        variables: {
          user_ids: [user?.id],
          group_id: groupId,
        },
      })
        .then(() => {
          useSuccessToast({ message: 'Successfully removed user from group' })
        })
        .catch((error) => {
          console.warn(error)
        })
    } else {
      deletePerson({
        variables: {
          user_id: classUser.id,
        },
      })
        .then(() => {
          useSuccessToast({ message: 'Successfully deleted person' })
        })
        .catch((error) => {
          console.warn(error)
        })
    }
  }

  const {
    isOpen: showSwitchRole,
    onOpen: onOpenSwitchRole,
    onClose: onCloseSwitchRole,
  } = useDisclosure()

  const handleOpenSwitchRoleModal = () => {
    onOpenSwitchRole()
  }

  const permittedRoles = () => {
    if (classUser.role === 'creator') {
      return []
    }
    if (classUser.role === 'admin') {
      return ['admin', 'creator']
    }
    return ['admin', 'creator', 'teacher']
  }

  const id = groupId ? classUser.user!.id : classUser.id

  const {
    isOpen: showUserProfile,
    onOpen: onOpenUserProfile,
    onClose: onCloseUserProfile,
  } = useDisclosure()

  const onClickInfoProp = isMobileDevice()
    ? {
        onClick: onOpenUserProfile,
      }
    : {}

  return (
    <>
      <ConfirmationModal
        isOpen={deleteIsOpen}
        onClose={deleteOnClose}
        title={`Remove User`}
        message={`Removed users will no longer have access to the ${
          groupId ? 'group' : 'class'
        } and all materials or assignments permissions will be removed.`}
        onSubmit={handleDeletePerson}
        confirmButtonText='REMOVE'
      />
      <SwitchRole
        isOpen={showSwitchRole}
        onClose={onCloseSwitchRole}
        classUser={classUser}
      />
      <UserProfile
        isOpen={showUserProfile}
        onClose={onCloseUserProfile}
        user={user}
      />
      <Flex
        h='56px'
        minH='56px'
        w={{ base: '100%', md: '208px' }}
        align='center'
        cursor='pointer'
      >
        <Flex align='center' w='144px' {...onClickInfoProp}>
          <AlamAvatar
            size='md'
            name={getFirstAndLastInitial(user)}
            src={user?.avatar}
          />

          <Flex direction='column' ml='8px'>
            <Tooltip label={name} aria-label={`chat with ${name}`}>
              <Text
                maxW='106px'
                color={isSelected(id) ? 'white.1' : 'primary.1'}
                textStyle='body.1'
                isTruncated
                overflow='hidden'
                opacity={
                  permittedRoles().includes(currentClassUser?.role)
                    ? '1'
                    : '0.32'
                }
              >
                {name}
              </Text>
            </Tooltip>
          </Flex>
        </Flex>

        <RenderForUser roles={permittedRoles()}>
          <RenderForDevice sizes={['md', 'lg', 'xl']}>
            {!isSelected(id) && (
              <Flex w='100%' justify='flex-end'>
                <Tooltip label="Change user's role" variant='iconToolTip'>
                  <IconButton
                    aria-label="Change user's role"
                    icon={<AccountSwitch width='18px' height='18px' />}
                    variant='icon.button'
                    minW='32px'
                    maxW='32px'
                    h='24px'
                    justifyContent='flex-end'
                    onClick={handleOpenSwitchRoleModal}
                    isDisabled={
                      (['teacher', 'student'].includes(
                        currentClassUser?.role
                      ) &&
                        ['admin', 'creator'].includes(classUser.role)) ||
                      classUser.role === 'creator'
                    }
                  />
                </Tooltip>
                <Tooltip label='Remove user' variant='iconToolTip'>
                  <IconButton
                    aria-label='delete account'
                    icon={<Delete />}
                    variant='icon.button'
                    minW='32px'
                    maxW='32px'
                    h='24px'
                    justifyContent='flex-end'
                    onClick={deleteOnOpen}
                    isDisabled={
                      (['teacher', 'student'].includes(
                        currentClassUser?.role
                      ) &&
                        ['admin', 'creator'].includes(classUser.role)) ||
                      classUser.role === 'creator'
                    }
                  />
                </Tooltip>
              </Flex>
            )}
          </RenderForDevice>
          <RenderForDevice sizes={['xs', 'sm']}>
            <Flex w='100%' justify='flex-end'>
              <Menu placement='bottom-end' autoSelect={false}>
                <MenuButton
                  as={IconButton}
                  aria-label='more'
                  variant='icon.button'
                  icon={<MdMoreHoriz />}
                />
                <MenuList w='184px' borderColor='primary.2'>
                  <MenuItem
                    as={Button}
                    icon={
                      <AccountSwitch
                        width='18px'
                        height='18px'
                        mt='3px'
                        mb='-3px'
                        ml='3px'
                        mr='-4px'
                      />
                    }
                    variant='icon.button.md'
                    layerStyle='menu.item.normal'
                    textStyle='body.1'
                    onClick={handleOpenSwitchRoleModal}
                    isDisabled={
                      (['teacher', 'student'].includes(
                        currentClassUser?.role
                      ) &&
                        ['admin', 'creator'].includes(classUser.role)) ||
                      classUser.role === 'creator'
                    }
                  >
                    Change User Role
                  </MenuItem>
                  <MenuDivider opacity='1.0' />
                  <MenuItem
                    as={Button}
                    icon={<DeleteUser width='17.23px' height='12.8px' />}
                    variant='icon.button.md'
                    layerStyle='menu.item.normal'
                    textStyle='body.1'
                    onClick={deleteOnOpen}
                    isDisabled={
                      (['teacher', 'student'].includes(
                        currentClassUser?.role
                      ) &&
                        ['admin', 'creator'].includes(classUser.role)) ||
                      classUser.role === 'creator'
                    }
                  >
                    Remove
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </RenderForDevice>
        </RenderForUser>
      </Flex>
    </>
  )
}
