import { useContext } from 'react'
import {
  Box,
  Icon,
  HStack,
  VStack,
  StackDivider,
  Flex,
  Text,
  MenuItem,
  MenuDivider,
  Button,
  useDisclosure,
  Tooltip,
} from '@chakra-ui/react'
import { FaClipboardList } from 'react-icons/fa'
import { PretopicIcon } from '../Icons'
import { CommentList } from './../Post'
import { Assignment as AssignmentType } from '../../types'
import { getFullName } from '../../utils'
import { format } from 'date-fns'
import { dateFormat } from '../../constants'
import { HoverMenu, parentHoverCss } from '../Menu'
import { RiPencilFill } from 'react-icons/ri'
import { FaTrash } from 'react-icons/fa'
import ClassWorkContext from '../../pages/ClassWork/ClassWorkContext'
import { useMutation } from '@apollo/client'
import { EDIT_ASSIGNMENT } from '../../client/queries'
import { useErrorToast, useSuccessToast } from '../../utils'
import { useAlamContext } from '../../client'
import { ConfirmationModal } from '../Modal'
import { Link } from 'react-router-dom'
import { RenderForUser } from '../SharedComponents'
import { FileList } from '../File'
import { AssignmentModal } from '../Assignment'
import { Test } from '../Icons'

export type PretopicProps = {
  pretopic: AssignmentType
  context?: string
  materialAssignmentRefetch: () => void
}

export const Pretopic = ({
  pretopic,
  context = 'classwork',
  materialAssignmentRefetch,
}: PretopicProps): JSX.Element => {
  const { getClassTopics } = useContext(ClassWorkContext)
  const { classUser } = useAlamContext()
  const {
    isOpen: editPretopicIsOpen,
    onClose: editPretopicOnClose,
    onOpen: editPretopicOnOpen,
  } = useDisclosure()
  const {
    isOpen: deletePretopicIsOpen,
    onClose: deletePretopicOnClose,
    onOpen: deletePretopicOnOpen,
  } = useDisclosure()

  const {
    isOpen: expandedIsOpen,
    onClose: expandedOnClose,
    onOpen: expandedOnOpen,
  } = useDisclosure()

  const [archiveAssignment] = useMutation(EDIT_ASSIGNMENT, {
    variables: {
      id: pretopic.id,
      changes: {
        archived_at: 'NOW()',
      },
    },
  })

  const refetch = () => {
    materialAssignmentRefetch()
    getClassTopics()
  }

  const handleDeletePretopic = async () => {
    try {
      await archiveAssignment()
      getClassTopics()
      materialAssignmentRefetch()
      deletePretopicOnClose()
      useSuccessToast({
        title: 'Pretopic deleted',
        message: 'Pretopic was successfully deleted',
      })
    } catch (error) {
      console.warn(error)
      useErrorToast({ message: 'Failed to delete assignment' })
    }
  }

  const viewUrl = `/classes/${classUser?.class?.id}/pretopic/${pretopic.id}`

  return (
    <>
      <ConfirmationModal
        isOpen={deletePretopicIsOpen}
        onClose={deletePretopicOnClose}
        title={`Are you sure you want to delete ${pretopic.name}?`}
        message={`All comments and data will also be deleted`}
        onSubmit={handleDeletePretopic}
      />
      <AssignmentModal
        isOpen={editPretopicIsOpen}
        onClose={editPretopicOnClose}
        type='edit'
        assignment={pretopic}
      />
      {expandedIsOpen ? (
        <VStack
          bg='white.1'
          layerStyle='card.board'
          w='100%'
          divider={<StackDivider layerStyle='divider' />}
        >
          <Box
            layerStyle='card.expanded'
            bg='white.1'
            w='100%'
            px={4}
            pt={4}
            pb={2}
            css={parentHoverCss}
          >
            <RenderForUser
              roles={['admin', 'teacher', 'creator']}
              context={context}
            >
              <HoverMenu top={4} right={2}>
                <>
                  <MenuItem icon={<FaTrash />} onClick={deletePretopicOnOpen}>
                    Delete
                  </MenuItem>
                </>
              </HoverMenu>
            </RenderForUser>
            <HStack spacing={4} h='100%' w='100%'>
              <Test width='20' height='20' viewBox='8 8 24 24' />

              <Flex
                direction='column'
                onClick={expandedIsOpen ? expandedOnClose : expandedOnOpen}
                cursor='pointer'
              >
                <Tooltip
                  label={pretopic.name}
                  aria-label={pretopic.name}
                  placement='top-start'
                >
                  <Text
                    isTruncated
                    w={{ base: '24ch', lg: '48ch' }}
                    textStyle='h5'
                    lineHeight='24px'
                  >
                    {pretopic.name}
                  </Text>
                </Tooltip>
                <HStack spacing={3} divider={<Box layerStyle='bulletpoint' />}>
                  <Text
                    textStyle='body.1'
                    color='grey.1'
                    maxWidth='50%'
                    noOfLines={1}
                    isTruncated
                  >
                    {getFullName(pretopic?.user)}
                  </Text>

                  <Text textStyle='body.1' color='grey.1'>
                    {format(new Date(pretopic?.inserted_at), dateFormat)}
                  </Text>
                </HStack>
              </Flex>
            </HStack>
          </Box>

          {pretopic.description && (
            <Box w='100%' px={6} py={2} textStyle='body.1'>
              {pretopic.description}
            </Box>
          )}

          {pretopic.attachments.length >= 1 && (
            <FileList existingFiles={pretopic.attachments} isReadOnly />
          )}

          <Box w='100%' px={4} pb={4} pt={2}>
            <Flex w='100%' direction='row' justify='flex-end'>
              <Link to={viewUrl}>
                <Button variant='base.primary' textStyle='button' minW='72px'>
                  view test
                </Button>
              </Link>
            </Flex>
          </Box>
        </VStack>
      ) : (
        <Box
          layerStyle='card.board-updated-shadow'
          bg='white.1'
          w='100%'
          h='72px'
          p={4}
          css={parentHoverCss}
        >
          <RenderForUser
            roles={['admin', 'teacher', 'creator']}
            context={context}
          >
            <HoverMenu top={4} right={2}>
              <>
                <MenuItem icon={<FaTrash />} onClick={deletePretopicOnOpen}>
                  Delete
                </MenuItem>
              </>
            </HoverMenu>
          </RenderForUser>

          <HStack spacing={4} h='100%' w='100%'>
            <Test width='20' height='20' viewBox='8 8 24 24' />

            <Flex
              direction='column'
              onClick={expandedIsOpen ? expandedOnClose : expandedOnOpen}
              cursor='pointer'
            >
              <Tooltip
                label={pretopic.name}
                aria-label={pretopic.name}
                placement='top-start'
              >
                <Text
                  isTruncated
                  w={{ base: '24ch', lg: '48ch' }}
                  textStyle='h5'
                  lineHeight='24px'
                >
                  {pretopic.name}
                </Text>
              </Tooltip>

              <HStack spacing={3} divider={<Box layerStyle='bulletpoint' />}>
                <Text
                  textStyle='body.1'
                  color='grey.1'
                  maxWidth='50%'
                  noOfLines={1}
                >
                  {getFullName(pretopic?.user)}
                </Text>

                <Text textStyle='body.1' color='grey.1'>
                  {format(new Date(pretopic?.inserted_at), dateFormat)}
                </Text>
              </HStack>
            </Flex>
          </HStack>
        </Box>
      )}
    </>
  )
}

export default Pretopic
