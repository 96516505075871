import theme from '@chakra-ui/theme'

const sizes = {
  ...theme.space,
  containerHeight: 'calc(100vh - 64px)',
  mobileContainerHeight: 'calc(100vh - 64px)',
  assignmentContainerHeight: 'calc(100vh - 128px)',
  sideNavHeight: 'calc(100% - 64px)',
  chatroomHeight: 'calc(100% - 120px)',
  emptyChatroomHeight: 'calc(100vh - 128px)',
}

export default sizes
