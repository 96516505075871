import { gql } from '@apollo/client'
import { v4 as uuid } from 'uuid'
import {
  materialFields,
  assignmentFields,
  userDisplayFields,
} from './fragments'

export const GET_CLASS_TOPICS = gql`
  ${materialFields}
  ${assignmentFields}
  query get_class_topics($class_id: uuid!, $user_id: uuid!) {
    topics(
      where: {
        class: { id: { _eq: $class_id } }
        archived_at: { _is_null: true }
      }
      order_by: [{ name: asc }]
    ) {
      id
      name
      materials(
        where: {
          archived_at: { _is_null: true }
          _or: [
            {
              _and: [
                { schedule: { _lte: "NOW()" } }
                { is_shown: { _eq: true } }
                { material_users: { user_id: { _eq: $user_id } } }
              ]
            }
            {
              class: {
                class_users: {
                  user_id: { _eq: $user_id }
                  role: { _in: ["admin", "teacher", "creator"] }
                }
              }
            }
          ]
        }
        order_by: [{ inserted_at: desc_nulls_last }]
      ) {
        ...materialFields
      }
      assignments(
        where: {
          archived_at: { _is_null: true }
          _or: [
            {
              _and: [
                { schedule: { _lte: "NOW()" } }
                { is_visible: { _eq: true } }
                { assignment_users: { user_id: { _eq: $user_id } } }
              ]
            }
            {
              class: {
                class_users: {
                  user_id: { _eq: $user_id }
                  role: { _in: ["teacher", "admin", "creator"] }
                }
              }
            }
          ]
        }
        order_by: [{ inserted_at: desc_nulls_last }]
      ) {
        ...assignmentFields
      }
    }
    class_users(
      where: {
        class: { id: { _eq: $class_id } }
        accepted_at: { _is_null: false }
        archived_at: { _is_null: true }
        role: { _eq: "student" }
      }
    ) {
      role
      user {
        id
        email
        firstName: first_name
        lastName: last_name
      }
    }
    groups(where: { class_id: { _eq: $class_id } }) {
      id
      name
      groupUsersForOptions: group_users(
        where: { archived_at: { _is_null: true } }
      ) {
        id
        group_id
        user_id
      }
    }
  }
`

export const GET_CLASS_MATERIALS_ASSIGNMENTS = gql`
  ${materialFields}
  ${assignmentFields}
  query get_materials_assignments($class_id: uuid!, $user_id: uuid!) {
    materials(
      where: {
        class: { archived_at: { _is_null: true }, id: { _eq: $class_id } }
        archived_at: { _is_null: true }
        topic_id: { _is_null: true }
        _or: [
          {
            _and: [
              { schedule: { _lte: "NOW()" } }
              { is_shown: { _eq: true } }
              { material_users: { user_id: { _eq: $user_id } } }
            ]
          }
          {
            class: {
              class_users: {
                user_id: { _eq: $user_id }
                role: { _in: ["teacher", "admin", "creator"] }
              }
            }
          }
        ]
      }
    ) {
      ...materialFields
    }
    assignments(
      where: {
        class: { archived_at: { _is_null: true }, id: { _eq: $class_id } }
        archived_at: { _is_null: true }
        topic_id: { _is_null: true }
        _or: [
          {
            _and: [
              { schedule: { _lte: "NOW()" } }
              { is_visible: { _eq: true } }
              { assignment_users: { user_id: { _eq: $user_id } } }
            ]
          }
          {
            class: {
              class_users: {
                user_id: { _eq: $user_id }
                role: { _in: ["teacher", "admin", "creator"] }
              }
            }
          }
        ]
      }
    ) {
      ...assignmentFields
    }
  }
`
export const GET_CLASS_MATERIALS = gql`
  ${materialFields}
  subscription get_materials($class_id: uuid!, $user_id: uuid!) {
    materials(
      where: {
        class: { archived_at: { _is_null: true }, id: { _eq: $class_id } }
        archived_at: { _is_null: true }
        topic_id: { _is_null: true }
        _or: [
          {
            _and: [
              { schedule: { _lte: "NOW()" } }
              { is_shown: { _eq: true } }
              { material_users: { user_id: { _eq: $user_id } } }
            ]
          }
          {
            class: {
              class_users: {
                user_id: { _eq: $user_id }
                role: { _in: ["teacher", "admin", "creator"] }
              }
            }
          }
        ]
      }
      order_by: [{ inserted_at: desc_nulls_last }]
    ) {
      ...materialFields
    }
  }
`

export const GET_CLASS_ASSIGNMENTS_DUE_DATE = gql`
  subscription get_assignments_due_date(
    $user_id: uuid!
    $class_id: uuid!
    $current_date: timestamp!
    $after_seven_days: timestamp!
  ) {
    classes: classes_by_pk(id: $class_id) {
      assignments(
        where: {
          due_date: { _gte: $current_date, _lte: $after_seven_days }
          assignment_users: { user_id: { _eq: $user_id } }
        }
        order_by: { due_date: asc }
      ) {
        id
        name
        dueDate: due_date
      }
    }
  }
`

export const GET_GROUPS_ASSIGNMENTS_DUE_DATE = gql`
  subscription get_assignments_due_date(
    $group_id: uuid!
    $current_date: timestamp!
    $after_seven_days: timestamp!
  ) {
    groups: groups_by_pk(id: $group_id) {
      assignment_groups(
        where: {
          assignment: {
            due_date: { _gte: $current_date, _lte: $after_seven_days }
          }
        }
        order_by: { assignment: { due_date: asc } }
      ) {
        assignment {
          id
          name
          dueDate: due_date
        }
      }
    }
  }
`

export const GET_CLASS_ASSIGNMENTS = gql`
  ${assignmentFields}
  subscription get_assignments($class_id: uuid!, $user_id: uuid!) {
    assignments(
      where: {
        class: { archived_at: { _is_null: true }, id: { _eq: $class_id } }
        archived_at: { _is_null: true }
        topic_id: { _is_null: true }
        _or: [
          {
            _and: [
              { schedule: { _lte: "NOW()" } }
              { is_visible: { _eq: true } }
              { assignment_users: { user_id: { _eq: $user_id } } }
            ]
          }
          {
            class: {
              class_users: {
                user_id: { _eq: $user_id }
                role: { _in: ["teacher", "admin", "creator"] }
              }
            }
          }
        ]
      }
      order_by: [{ inserted_at: desc_nulls_last }]
    ) {
      ...assignmentFields
    }
  }
`

export const GET_MATERIAL = gql`
  ${userDisplayFields}
  subscription get_material($id: uuid!) {
    materials_by_pk(id: $id) {
      id
      title
      description
      schedule
      is_shown
      learning_style
      inserted_at
      comments(where: { archived_at: { _is_null: true } }) {
        id
        content
        inserted_at
        user {
          ...userDisplayFields
        }
      }
      class {
        subject
        name
        id
      }
      topic {
        name
      }
      attachments {
        id
        url
        file
      }
      user {
        ...userDisplayFields
      }
    }
  }
`

export const CREATE_MATERIAL = gql`
  mutation create_material(
    $id: uuid!
    $class_id: uuid!
    $title: String!
    $description: String
    $schedule: timestamp!
    $learning_style: [String!]
    $is_shown: Boolean!
    $user_id: uuid!
    $material_users: [material_users_insert_input!]!
    $topic_id: uuid
    $material_groups: [material_groups_insert_input!]!
    $add_new_groups: Boolean!
    $add_new_users: Boolean!
  ) {
    insert_materials_one(
      object: {
        id: $id
        title: $title
        description: $description
        schedule: $schedule
        learning_style: $learning_style
        is_shown: $is_shown
        class_id: $class_id
        user_id: $user_id
        topic_id: $topic_id
        add_new_groups: $add_new_groups
        add_new_users: $add_new_users
        material_users: { data: $material_users }
        inserted_at: "NOW()"
        updated_at: "NOW()"
      }
    ) {
      id
      title
      description
      is_shown
    }

    insert_material_groups(objects: $material_groups) {
      returning {
        id
      }
    }
  }
`

export const UPDATE_MATERIAL = gql`
  mutation update_material(
    $id: uuid!
    $changes: materials_set_input
    $material_users: [material_users_insert_input!]!
    $material_groups: [material_groups_insert_input!]!
  ) {
    update_materials(where: { id: { _eq: $id } }, _set: $changes) {
      returning {
        id
        title
        description
        schedule
        inserted_at
        is_shown
      }
    }

    delete_material_users(where: { material_id: { _eq: $id } }) {
      affected_rows
    }

    insert_material_users(objects: $material_users) {
      returning {
        id
      }
    }

    delete_material_groups(where: { material_id: { _eq: $id } }) {
      affected_rows
    }

    insert_material_groups(objects: $material_groups) {
      returning {
        id
      }
    }
  }
`

export const UPDATE_MATERIAL_USER_VIEW_TIME = gql`
  mutation update_material_user_view_time(
    $material_id: uuid!
    $user_id: uuid!
    $duration: Int!
  ) {
    update_material_users(
      where: { material_id: { _eq: $material_id }, user_id: { _eq: $user_id } }
      _inc: { view_time: $duration }
    ) {
      returning {
        id
        view_time
      }
    }
  }
`

export const UPDATE_MATERIAL_GROUP_VIEW_TIME = gql`
  mutation update_material_group_view_time(
    $material_id: uuid!
    $group_id: uuid!
    $duration: Int!
  ) {
    update_material_groups(
      where: {
        material_id: { _eq: $material_id }
        group_id: { _eq: $group_id }
      }
      _inc: { view_time: $duration }
    ) {
      returning {
        id
        view_time
      }
    }
  }
`

export const EDIT_MATERIAL = gql`
  mutation edit_material($id: uuid!, $changes: materials_set_input) {
    update_materials(where: { id: { _eq: $id } }, _set: $changes) {
      returning {
        id
        title
        description
        schedule
        inserted_at
        is_shown
        archived_at
      }
    }
  }
`

export const generateInsertMaterialUsers = (
  studentIds: string[],
  materialId: string
) => {
  return studentIds.map((id) => {
    return {
      id: uuid(),
      inserted_at: 'NOW()',
      updated_at: 'NOW()',
      material_id: materialId,
      user: {
        data: {
          id,
          inserted_at: 'NOW()',
          updated_at: 'NOW()',
        },
        on_conflict: {
          constraint: 'users_pkey',
          update_columns: ['updated_at'],
        },
      },
    }
  })
}

export const generateMaterialGroups = (
  groupIds: string[],
  material_id: string
) =>
  groupIds.map((group_id) => ({
    id: uuid(),
    inserted_at: 'NOW()',
    updated_at: 'NOW()',
    group_id,
    material_id,
  }))
