import { SideNav, GroupHeading, SideNavCell } from '../SideNav'
import {
  MdAssignmentTurnedIn,
  MdChromeReaderMode,
  MdContacts,
  MdSettings,
  MdInput,
} from 'react-icons/md'
import { HiUserGroup } from 'react-icons/hi'
import { AiOutlineLineChart } from 'react-icons/ai'
import {
  Flex,
  Text,
  VStack,
  StackDivider,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react'
import { ClassUser, User } from '../../types'
import { RenderForUser } from '../SharedComponents'
import { useAlamContext } from '../../client'
import { CreateSession, AddSessionMembers, CreateSessionInput } from '../Forms'
import {
  useErrorToast,
  useScheme,
  useSuccessToast,
  isLargerThanTablet,
  getClassUserRole,
} from '../../utils'
import {
  buildSessionUsers,
  CREATE_SESSION,
  GET_USER_SESSIONS,
  INSERT_SESSION_USERS,
  USER_GROUPS,
} from '../../client/queries'
import { useLazyQuery, useMutation, useSubscription } from '@apollo/client'
import { v4 as uuid } from 'uuid'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useGoogleAPI } from '../GoogleAPI'
import { Class } from '../Icons'

export type SideNavLayoutProps = {
  classUser: ClassUser
  user: User
  isMobile?: boolean
}

export const logout = () => {
  localStorage.clear()
  const url = process.env.REACT_APP_SERVER_HOST || 'http://localhost:4000'
  window.location.href = `${useScheme('http')}://${url}`
}

const SideNavLayout = ({
  classUser,
  user,
  isMobile = false,
}: SideNavLayoutProps): JSX.Element => {
  const [sessionId, setSessionId] = useState<string>()
  const { isAuthorized } = useGoogleAPI()
  const class_id = classUser?.class?.id
  const className = classUser?.class?.name
  const {
    teachers,
    students,
    isOpenGroupsSidebar: isOpenGroups,
    setIsOpenGroupsSidebar,
    isOpenSessionsSidebar: isOpenSessions,
    setIsOpenSessionsSidebar,
  } = useAlamContext()
  const history = useHistory()
  const [sessionMemberIsAdding, setSessionMemberIsAdding] = useState(false)
  const { sessionId: session_id } = useParams<{ sessionId?: string }>()
  const onOpenGroups = () => {
    setIsOpenGroupsSidebar(true)
  }

  const onCloseGroups = () => {
    setIsOpenGroupsSidebar(false)
  }

  const onOpenSessions = () => {
    setIsOpenSessionsSidebar(true)
  }

  const onCloseSessions = () => {
    setIsOpenSessionsSidebar(false)
  }

  const { data } = useSubscription(USER_GROUPS, {
    variables: {
      class_id: class_id,
      user_id: user.id,
    },
    onSubscriptionData: ({
      subscriptionData: {
        data: { groups },
      },
    }) => {
      if (groups.length > 0 && isOpenGroups) {
        onOpenGroups()
      } else {
        onCloseGroups()
      }
    },
  })


  const [getSession, { data: { sessions } = { sessions: [] } }] = useLazyQuery(
    GET_USER_SESSIONS,
    {
      variables: { class_id, user_id: user.id },
      fetchPolicy: 'cache-and-network',
      onCompleted: ({ sessions = [] }) => {
        if (sessions.length > 0 && isOpenSessions) {
          onOpenSessions()
        } else {
          onCloseSessions()
        }
      },
    }
  )

  useEffect(() => {
    getSession()
  }, [class_id, user.id, session_id])

  const groups = data?.groups || []

  const {
    isOpen: showCreateSession,
    onOpen: onOpenCreateSession,
    onClose: onCloseCreateSession,
  } = useDisclosure()

  const {
    isOpen: showAddSessionMembers,
    onOpen: onOpenAddSessionMembers,
    onClose: onCloseAddSessionMembers,
  } = useDisclosure()

  const handleCloseAddMembers = () => {
    onCloseAddSessionMembers()
    history.push(`/classes/${class_id}/sessions/${sessionId}`)
  }

  const [createSession, { loading: sessionIsCreating }] = useMutation(
    CREATE_SESSION
  )

  const handleCreateSession = async ({
    name,
    description,
  }: CreateSessionInput) => {
    try {
      const id = uuid()

      await createSession({
        variables: {
          id,
          name,
          description,
          class_id,
          user_id: user.id,
          session_user_id: uuid(),
        },
      })

      useSuccessToast({ message: 'Session was successfully created' })
      getSession()
      setSessionId(id)
      onCloseCreateSession()
      onOpenAddSessionMembers()
    } catch (error) {
      useErrorToast({ error })
      onCloseCreateSession()
    }
  }

  const [
    insertSessionUsers,
    { loading: sessionUserIsSubmitting },
  ] = useMutation(INSERT_SESSION_USERS)

  const handleAddMember = async (userIds: string[]) => {
    setSessionMemberIsAdding(true)
    try {
      await insertSessionUsers({
        variables: {
          session_users: buildSessionUsers(userIds, sessionId),
        },
      })

      useSuccessToast({ message: 'Users successfully added' })
    } catch (error) {
      useErrorToast({ error })
    }

    handleCloseAddMembers()
    setSessionMemberIsAdding(false)
  }

  const [isDesktop] = useMediaQuery('(min-width: 1200px)')

  return (
    <>
      <SideNav isMobile={isMobile}>
        <VStack
          divider={<StackDivider borderColor='white.3' />}
          spacing={4}
          w='100%'
          maxH='100vh'
          overflow='auto'
          align='start'
          css={{
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            'scrollbar-width': 'none',
            '-ms-overflow-style': 'none',
          }}
        >
          <SideNavCell url='/' icon={Class} text='All classes' exact />

          <Flex pl={7} direction='column' justify='space-between'>
            <Text
              color='white.1'
              fontSize='12px'
              fontWeight='700'
              lineHeight='18px'
              letterSpacing='0.1em'
              textTransform='uppercase'
              width='25ch'
              isTruncated
              mb='4px'
            >
              {classUser?.class?.name}
            </Text>
            <Text
              color='grey.1'
              fontSize='12px'
              fontWeight='600'
              lineHeight='16px'
              letterSpacing='1.5%'
              textTransform='capitalize'
            >
              {getClassUserRole(classUser)}
            </Text>
          </Flex>

          <GroupHeading>
            <SideNavCell
              url={`/classes/${classUser?.class?.id}`}
              text='Classroom'
              isZeroLetterSpacing
              icon={MdChromeReaderMode}
              paths={[
                '/classes/:id',
                '/classes/:id/classwork',
                '/classes/:id/assignments/:id',
                '/classes/:id/assignments/:id/submissions',
                '/classes/:id/assignments/:id/feedback',
                '/classes/:id/materials/:id',
                '/classes/:id/pretopic/:id',
                '/classes/:id/pretopic/:id/submissions',
              ]}
            />

            {isLargerThanTablet() ? (
              <SideNavCell
                url={`/classes/${classUser?.class?.id}/grades`}
                text='Grades'
                icon={MdAssignmentTurnedIn}
                paths={['/classes/:id/grades']}
                display={
                  !isDesktop && classUser?.role === 'student' ? 'none' : 'block'
                }
              />
            ) : (
              <></>
            )}

            <RenderForUser roles={['admin', 'teacher', 'creator']}>
              <SideNavCell
                url={`/classes/${classUser?.class?.id}/analytics/class`}
                text='Analytics'
                icon={AiOutlineLineChart}
                paths={[
                  '/analytics/class',
                  '/analytics/class/topic/:id',
                  '/analytics/class/topic/:id/pre-course-test',
                  '/analytics/class/topic/:id/completion-time',
                  '/analytics/class/topic/:id/assignment/:id',
                  '/analytics/class/topic/:id/assignment/:id/completion-time',
                  '/analytics/class/topic/:id/assignment/:id/score',
                  '/analytics/class/topic/:id/assignment/:id/assignment-review',
                  '/analytics/class/topic/:id/assignment/:id/assignment-review/question/:id',
                  '/analytics/students',
                  '/classes/:id/analytics/class',
                  '/classes/:id/analytics/students',
                  '/classes/:id/analytics/class/topic/:id',
                ]}
              />
            </RenderForUser>
          </GroupHeading>

          <GroupHeading
            collapsable
            text={`my groups (${groups.length})`}
            textFontWeight='600'
            textLetterSpacing='0.1em'
            adjustPaddingBottom='0'
            adjustPaddingRight={1}
            isOpen={isOpenGroups}
            onOpen={onOpenGroups}
            onClose={onCloseGroups}
          >
            <SideNavCell
              url={`/classes/${classUser?.class?.id}/groups`}
              text='All groups'
              icon={HiUserGroup}
              paths={['/classes/:id/groups']}
            >
              {groups.map((group: { id: string; name: string }) => (
                <SideNavCell
                  url={`/classes/${classUser?.class?.id}/groups/${group.id}`}
                  isSubItem
                  text={group.name}
                  icon={HiUserGroup}
                  key={group.id}
                  groupId={group.id}
                  paths={[
                    '/classes/:id/groups/:id',
                    '/classes/:id/groups/:id/groupwork',
                    '/classes/:id/groups/:id/assignments/:id',
                    '/classes/:id/groups/:id/assignments/:id/submissions',
                    '/classes/:id/groups/:id/assignments/:id/feedback',
                    '/classes/:id/groups/:id/materials/:id',
                  ]}
                />
              ))}
            </SideNavCell>
          </GroupHeading>

          <GroupHeading
            collapsable
            text={`my sessions (${sessions.length})`}
            textFontWeight='600'
            textLetterSpacing='0.1em'
            adjustPaddingBottom='0'
            adjustPaddingRight={1}
            isOpen={isOpenSessions}
            onOpen={onOpenSessions}
            onClose={onCloseSessions}
          >
            <SideNavCell
              url='/'
              text='Create new session'
              icon={MdContacts}
              isLink={false}
              onClick={onOpenCreateSession}
              isZeroLetterSpacing
            >
              {sessions.map(({ id, name }: { id: string; name: string }) => (
                <SideNavCell
                  key={id}
                  url={`/classes/${classUser?.class?.id}/sessions/${id}`}
                  isSubItem
                  text={name}
                  icon={MdContacts}
                  paths={[
                    '/classes/:id/sessions/:sessionId',
                    '/classes/:id/sessions/:sessionId/info',
                    '/classes/:id/sessions/:sessionId/document/:documentId',
                  ]}
                  sessionId={id}
                  isCreateNewSession
                />
              ))}
            </SideNavCell>
          </GroupHeading>

          <SideNavCell
            url={`/classes/${classUser?.class?.id}/settings`}
            icon={MdSettings}
            text='Class settings'
            paths={[`/classes/${classUser?.class?.id}/settings`]}
          />

          <SideNavCell
            onClick={logout}
            isLink={false}
            icon={MdInput}
            text='Log out'
            url='/logout'
          />
        </VStack>
      </SideNav>

      <CreateSession
        isOpen={showCreateSession}
        onClose={onCloseCreateSession}
        onSubmit={handleCreateSession}
        isSubmitting={sessionIsCreating}
      />

      <AddSessionMembers
        isOpen={showAddSessionMembers}
        onClose={handleCloseAddMembers}
        handleAddMember={handleAddMember}
        isSubmitting={sessionUserIsSubmitting || sessionMemberIsAdding}
        classUsers={[...teachers, ...students]}
      />
    </>
  )
}

export default SideNavLayout
