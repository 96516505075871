import React from 'react'
import { useParams } from 'react-router-dom'
import SessionPage from './SessionPage'

const Session = (): JSX.Element => {
  const { id, sessionId } = useParams<{ id: string; sessionId: string }>()
  return <SessionPage id={id} sessionId={sessionId} />
}

export default Session
