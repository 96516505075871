import React, { Fragment } from 'react'
import { useRouteMatch } from 'react-router'
import {
  Container,
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  Text,
  VStack,
  StackDivider,
} from '@chakra-ui/react'
import { IoChevronDown } from 'react-icons/io5'
import { MdFileDownload } from 'react-icons/md'
import { useQuery } from '@apollo/client'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import { GET_TOPIC_REPORT } from '../../client/queries'
import { Assignment, Submission, Topic } from '../../types'
import {
  BarChart,
  YAxis,
  XAxis,
  Bar,
  ResponsiveContainer,
  LabelList,
  Label,
} from 'recharts'
import textStyles from '../../theme/textStyles'
import { millisecondsToMinutes, subMilliseconds } from 'date-fns'
import { roundToNearestHundred, getEventLog } from '../../utils'
import { useAlamContext } from '../../client'
import { Select } from '../../components/Input'
import { Layout } from '../../components/Layout'
import { TopNavItemProps } from '../../components/TopNav'
import {
  buildStudentCompletionData,
  buildInitialGraphData,
  populateCompletionGraph,
  StudentWithCompletionData,
  StudentWithGradesData,
  buildStudentGradesData,
  getGradesData,
} from '../../utils'
import { StudentsData } from './AssignmentPage'
import { DownloadButton } from '../../components/Analytics'

export type AssignmentTypes = {
  id: string
  type: string
  name: string
  description: string
}

type completionGraphData = {
  amt: number
  roundedCompletionTime: string
}

type TopicsPageProps = {
  topNavLeft: TopNavItemProps[]
  topNavCenter: TopNavItemProps[]
  topicOptions: Array<{ value: string; display: string }>
  handleTopicChange: (val: string) => void
}

type LabelListType = {
  x: number
  y: number
  width: number
  height: number
  value: number
}

const TopicsPage = ({
  topNavLeft,
  topNavCenter,
  topicOptions,
  handleTopicChange,
}: TopicsPageProps): JSX.Element => {
  const { id, topic_id } = useParams<{ id: string; topic_id: string }>()
  const history = useHistory()
  const { data } = useQuery(GET_TOPIC_REPORT, {
    variables: { topic_id: topic_id },
  })

  const topicName = data?.topics_by_pk?.name
  const assignments: AssignmentTypes[] = data?.topics_by_pk?.assignments
  const pre_course_test_report = undefined
  const pretopic = data?.topics_by_pk?.pretopic[0]

  const assignmentStudents = pretopic?.assignment_users || []

  const {
    studentsCompletionData,
    studentsGradesData,
  }: StudentsData = assignmentStudents.reduce(
    (
      { studentsCompletionData, studentsGradesData }: StudentsData,
      student: any
    ) => {
      const studentCompletionData = buildStudentCompletionData(student)
      const studentGradesData = buildStudentGradesData(student?.user)
      return {
        studentsCompletionData: [
          ...studentsCompletionData,
          studentCompletionData,
        ],
        studentsGradesData: [...studentsGradesData, studentGradesData],
      }
    },
    {
      studentsCompletionData: [],
      studentsGradesData: [],
    }
  )

  const completedStudents =
    studentsCompletionData.filter((student: StudentWithCompletionData) => {
      return !!student.completionTime
    }) || []

  const initialCompletionGraph = buildInitialGraphData(completedStudents)
  const finalCompletionGraph = populateCompletionGraph(
    completedStudents,
    initialCompletionGraph
  )

  const gradesGraphData = getGradesData(studentsGradesData, pretopic?.rubric)

  const { url } = useRouteMatch()

  const renderGradeCustomLabel = ({ x, y, width, height, value }: any) => {
    if (value === 0) {
      return <></>
    }

    return (
      <g>
        <text
          x={x + width - 10}
          y={y + height / 2 + 1}
          fill='#fff'
          textAnchor='middle'
          dominantBaseline='middle'
          fontSize='12px'
          fontWeight={600}
          fontFamily="'Nunito Sans', sans-serif"
        >
          {value}
        </text>
      </g>
    )
  }

  const classPath = `/classes/${id}`

  return (
    <Layout topNavLeft={topNavLeft} topNavCenter={topNavCenter} showSideNav>
      <Fragment>
        <Box px={6} pt={6} mb={8}>
          <Flex align='center' justify='space-between'>
            <Select
              value={topic_id}
              options={topicOptions}
              onChange={handleTopicChange}
              menuButtonProps={{
                textStyle: 'button',
                variant: 'tab.selector',
                textAlign: 'left',
                py: '8px',
                pr: '8px',
                w: '196px',
                layerStyle: 'card.module',
                color: 'primary.1',
              }}
              menuItemProps={{
                textStyle: 'body.1',
                color: 'primary.1',
                w: '196px',
                _hover: { bg: 'hover.2', color: 'white.1' },
                overflow: 'hidden',
              }}
              hasTextDisplay={true}
              textDisplayProps={{
                maxW: '18ch',
                isTruncated: true,
                textAlign: 'left',
                textStyle: 'button',
              }}
            />

            <DownloadButton classId={id} topicId={topic_id} />
          </Flex>
        </Box>
        <Container
          maxW={{ base: '100%', md: '456px', lg: '500px', xl: '1080px' }}
          px={0}
        >
          <Text textStyle='h2' mb={6} color='black.0'>
            {topicName}
          </Text>
          <Flex mb={6}>
            <Box
              w='344px'
              bg='white.1'
              borderRadius='12px'
              borderWidth='0.5px'
              borderStyle='solid'
              borderColor='primary.2'
              mr={6}
            >
              <Flex p='24px 32px' justify='space-between' align='center'>
                <Text
                  textStyle='h5'
                  mb={7}
                  cursor='pointer'
                  color='primary.1'
                  onClick={() =>
                    history.push(
                      `${classPath}/analytics/class/topic/${topic_id}/pre-course-test`
                    )
                  }
                >
                  Pre-topic Test
                </Text>
                {pretopic && (
                  <Text
                    textStyle='body.big'
                    color='primary.1'
                    mb={7}
                    cursor='pointer'
                    onClick={() =>
                      history.push(
                        `${classPath}/analytics/class/topic/${topic_id}/pre-course-test`
                      )
                    }
                  >
                    View Report
                  </Text>
                )}
              </Flex>

              <Flex
                w='100%'
                h='264px'
                mb={3}
                align='center'
                justify='center'
                p='0 13px'
              >
                {gradesGraphData && gradesGraphData?.length >= 1 ? (
                  <ResponsiveContainer width={290}>
                    <BarChart
                      width={350}
                      height={264}
                      data={gradesGraphData}
                      barCategoryGap='30%'
                      barGap={8}
                      layout='vertical'
                      margin={{ left: -25 }}
                    >
                      <YAxis
                        dataKey='score'
                        type='category'
                        style={{
                          ...textStyles.body['2'],
                          fontWeight: 400,
                          color: '#010002',
                        }}
                        interval={0}
                        tickLine={false}
                        minTickGap={10}
                        padding={{ bottom: -2 }}
                      />
                      <XAxis
                        type='number'
                        tickLine={false}
                        tickFormatter={() => ''}
                        dataKey='students'
                      >
                        <Label
                          value='No. of students'
                          position='insideBottomRight'
                          style={{
                            ...textStyles.body['2'],
                            fontWeight: 400,
                            fill: '#0100028f',
                          }}
                        />
                      </XAxis>
                      <Bar
                        dataKey='students'
                        barSize={40}
                        fill='#CE1032'
                        isAnimationActive={false}
                      >
                        <LabelList content={renderGradeCustomLabel} />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                ) : (
                  <Flex align='center' justify='center' w='100%' h='100%'>
                    <Text
                      textStyle='body.2'
                      color='black.3'
                      fontSize='16px'
                      fontWeight='600'
                    >
                      Not enough data
                    </Text>
                  </Flex>
                )}
              </Flex>
            </Box>

            <Box
              bg='white.1'
              borderRadius='12px'
              borderWidth='0.5px'
              borderStyle='solid'
              borderColor='primary.2'
              pt={7}
              pr={5}
              pl={8}
              pb={0}
              flex='1'
            >
              <Flex justify='space-between' align='center' mb={7}>
                <Text
                  textStyle='h5'
                  cursor='pointer'
                  onClick={() =>
                    history.push(
                      `${classPath}/analytics/class/topic/${topic_id}/completion-time`
                    )
                  }
                >
                  Completion Time
                </Text>
                {pretopic && (
                  <Text
                    textStyle='body.big'
                    color='primary.1'
                    cursor='pointer'
                    onClick={() =>
                      history.push(
                        `${classPath}/analytics/class/topic/${topic_id}/completion-time`
                      )
                    }
                  >
                    View Report
                  </Text>
                )}
              </Flex>
              <Flex w='100%' h='300px' mb={3} align='center' justify='center'>
                {pretopic && finalCompletionGraph ? (
                  <ResponsiveContainer width={630} height={300}>
                    <BarChart
                      height={264}
                      width={350}
                      data={finalCompletionGraph.graphData}
                      layout='horizontal'
                      margin={{ left: -15, bottom: 30 }}
                    >
                      <YAxis
                        dataKey='amt'
                        type='number'
                        style={{
                          ...textStyles.body['2'],
                          fontWeight: 400,
                          fill: '#0100028f',
                        }}
                        tickLine={false}
                      >
                        <Label
                          value='No. of students'
                          position='insideLeft'
                          angle={-90}
                          style={{
                            ...textStyles.body['2'],
                            fontWeight: 400,
                            fill: '#0100028f',
                          }}
                          offset={20}
                        />
                      </YAxis>

                      <XAxis
                        type='category'
                        dataKey='roundedCompletionTime'
                        style={{
                          ...textStyles.body['2'],
                          fontWeight: 400,
                          color: '#0100028f',
                        }}
                        tickLine={false}
                      >
                        <Label
                          value={`Completion Time (${finalCompletionGraph.unit})`}
                          position='insideBottomRight'
                          style={{
                            ...textStyles.body['2'],
                            fontWeight: 400,
                            fill: '#0100028f',
                          }}
                          offset={0}
                          dy={8}
                        />
                      </XAxis>
                      <Bar dataKey='amt' barSize={40} fill='#CE1032' />
                    </BarChart>
                  </ResponsiveContainer>
                ) : (
                  <Flex align='center' justify='center' w='100%' h='100%'>
                    <Text
                      textStyle='body.2'
                      color='black.3'
                      fontSize='16px'
                      fontWeight='600'
                    >
                      Not enough data
                    </Text>
                  </Flex>
                )}
              </Flex>
            </Box>
          </Flex>

          {assignments && assignments.length > 0 && (
            <Box
              bg='white.1'
              borderRadius='12px'
              p={10}
              mb={10}
              borderWidth='0.5px'
              borderStyle='solid'
              borderColor='primary.2'
            >
              <VStack
                w='100%'
                alignItems='flex-start'
                divider={<StackDivider layerStyle='dividerGrey' />}
                spacing={4}
              >
                <Box>
                  <Text textStyle='h5' color='primary.1'>
                    Assignments
                  </Text>
                </Box>

                {assignments
                  .filter(
                    (assign: AssignmentTypes) => assign.type === 'assignment'
                  )
                  .map((assignment: AssignmentTypes) => (
                    <Flex
                      align='center'
                      justify='space-between'
                      w='100%'
                      key={assignment.id}
                    >
                      <Box maxW='calc(100% - 100px)'>
                        <Text
                          textStyle='body.semibold'
                          color='primary.1'
                          cursor='pointer'
                          onClick={() =>
                            history.push(
                              `/classes/${id}/analytics/class/topic/${topic_id}/assignment/${assignment.id}`
                            )
                          }
                          isTruncated
                        >
                          {assignment.name}
                        </Text>
                        <Text
                          textStyle='subtitle'
                          color='rgba(1, 0, 2, 0.56)'
                          isTruncated
                        >
                          {assignment.description}
                        </Text>
                      </Box>
                      <Box w='90px'>
                        <Text
                          textStyle='smallstate'
                          letterSpacing='0.02em'
                          cursor='pointer'
                          color='primary.1'
                          onClick={() =>
                            history.push(
                              `/classes/${id}/analytics/class/topic/${topic_id}/assignment/${assignment.id}`
                            )
                          }
                        >
                          View Analytics
                        </Text>
                      </Box>
                    </Flex>
                  ))}
              </VStack>
            </Box>
          )}
        </Container>
      </Fragment>
    </Layout>
  )
}

export default TopicsPage
