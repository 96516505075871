import React, { useState } from 'react'
import { GroupUser, Submission } from '../../types'
import {
  getFullName,
  isLargerThanTablet,
  useSuccessToast,
  useErrorToast,
} from '../../utils'
import { StackDivider, Flex, Text, VStack, Box } from '@chakra-ui/react'
import { compareDesc } from 'date-fns'
import CommentsSection from './CommentsSection'
import { useMutation } from '@apollo/client'
import {
  CREATE_SUBMISSION_COMMENT,
  READ_GROUP_COMMENTS,
} from '../../client/queries'
import { v4 as uuid } from 'uuid'
import { useAlamContext } from '../../client'
import { TeacherGroupComment, getLastComment } from './TeacherGroupComment'

const sortByLatestComment = (groupUserA: GroupUser, groupUserB: GroupUser) => {
  const lastCommentA = getLastComment(groupUserA.comments)?.inserted_at
  const lastCommentB = getLastComment(groupUserB.comments)?.inserted_at

  if (!lastCommentA && !lastCommentB) {
    return 0
  }

  if (!lastCommentA) {
    return 1
  }

  if (!lastCommentB) {
    return -1
  }

  return compareDesc(new Date(lastCommentA), new Date(lastCommentB))
}

export type TeacherGroupCommentsProps = {
  groupUsersComments: GroupUser[]
  title: string
  selectedSubmission?: Submission
  disabled: boolean
}

export const TeacherGroupComments = ({
  groupUsersComments,
  title,
  selectedSubmission,
  disabled,
}: TeacherGroupCommentsProps): JSX.Element => {
  const { user } = useAlamContext()
  const [selectedGroupUserId, setSelectedGroupUserId] = useState<string>()
  const sortedGroupUsers = groupUsersComments.sort(sortByLatestComment)
  const selectedGroupUser = sortedGroupUsers.find(
    ({ id }) => id === selectedGroupUserId
  )

  const [readGroupComments] = useMutation(READ_GROUP_COMMENTS)

  const [createComment, { loading: isSubmitting }] = useMutation(
    CREATE_SUBMISSION_COMMENT,
    {
      onCompleted: () => useSuccessToast({ message: 'Comment was created' }),
      onError: () => useErrorToast({}),
    }
  )

  const handleSelectGroupUser = (group_user_id: string) => {
    setSelectedGroupUserId(group_user_id)
    readGroupComments({
      variables: {
        submission_id: selectedSubmission?.id,
        group_user_id,
      },
    })
  }

  const handleSubmitComment = ({ content }: { content: string }) =>
    createComment({
      variables: {
        id: uuid(),
        content,
        user_id: user?.id,
        submission_id: selectedSubmission?.id,
        group_user_id: selectedGroupUserId,
      },
    })

  const boxLayerStyle = isLargerThanTablet() ? 'card.board' : 'card.board-flat'

  if (selectedGroupUser) {
    return (
      <CommentsSection
        title='Private Comments'
        subtitle={`- ${getFullName(selectedGroupUser.user)}`}
        comments={selectedGroupUser.comments}
        user={user}
        isSubmitting={isSubmitting}
        handleSubmitComment={handleSubmitComment}
        handleGoBack={() => setSelectedGroupUserId(undefined)}
        disabled={disabled}
      />
    )
  }

  return (
    <Box
      w='100%'
      layerStyle={boxLayerStyle}
      bg='white.1'
      minH={groupUsersComments.length === 0 ? '264px' : 'auto'}
    >
      <VStack w='100%' divider={<StackDivider layerStyle='divider.nospace' />}>
        <Flex
          justify='space-between'
          w='100%'
          py='12px'
          pr='16px'
          pl='24px'
          alignItems='center'
          h='72px'
        >
          <Text textStyle='h4'>{title}</Text>
        </Flex>

        {groupUsersComments.length === 0 && (
          <Flex w='100%' pt='4px' minH='136px' align='center' justify='center'>
            <Text textStyle='body.big' color='primary.2'>
              No group members
            </Text>
          </Flex>
        )}

        {sortedGroupUsers.map((sortedGroupUser) => (
          <React.Fragment key={sortedGroupUser.id}>
            <TeacherGroupComment
              handleSelectGroupUser={handleSelectGroupUser}
              groupUser={sortedGroupUser}
            />
          </React.Fragment>
        ))}
      </VStack>
    </Box>
  )
}

export default TeacherGroupComments
