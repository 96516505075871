import React, { useState } from 'react'
import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  Button,
  Text,
  useRadioGroup,
  VStack,
  RadioGroup,
  Radio,
} from '@chakra-ui/react'
import { AlamAvatar } from '../Avatar'
import { RadioCard } from '../Forms'
import { useMutation } from '@apollo/client'
import { SWITCH_ROLE } from '../../client/queries'
import {
  useErrorToast,
  useSuccessToast,
  getFirstAndLastInitial,
  getFullName,
} from '../../utils'
import { useAlamContext } from '../../client'
import { ClassUser } from '../../types'

export type SwitchRoleProps = {
  isOpen: boolean
  onClose: () => void
  classUser: ClassUser
}

type RolesOptionsTypes = {
  value: string
  display: string
}

export const SwitchRole = ({
  isOpen,
  onClose,
  classUser,
}: SwitchRoleProps): JSX.Element => {
  const { classUser: currentClassUser } = useAlamContext()
  const [selectedRole, setSelectedRole] = useState(classUser.role)
  const rolesOptions = () => {
    if (['creator', 'admin'].includes(currentClassUser?.role)) {
      return [
        {
          value: 'admin',
          display: 'Admin',
        },
        {
          value: 'teacher',
          display: 'Instructor',
        },
        {
          value: 'student',
          display: 'Student',
        },
      ]
    }
    if (currentClassUser?.role === 'teacher') {
      return [
        {
          value: 'teacher',
          display: 'Instructor',
        },
        {
          value: 'student',
          display: 'Student',
        },
      ]
    }
    return []
  }

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'Roles',
    defaultValue: classUser.role,
    onChange: (nextValue: string) => setSelectedRole(nextValue),
  })
  const group = getRootProps()

  const [switchRole] = useMutation(SWITCH_ROLE)
  const handleSubmit = (e: React.SyntheticEvent) => {
    try {
      e.preventDefault()

      switchRole({
        variables: {
          role: selectedRole,
          class_user_id: classUser.id,
        },
      }).then(() => {
        onClose()
        useSuccessToast({ message: 'Role updated' })
      })
    } catch (error) {
      console.warn(error)
      useErrorToast({ message: 'Update role failed' })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent minH='408px' h='408px' minW='320px' w='320px' py='0'>
        <form onSubmit={handleSubmit}>
          <ModalBody p='0'>
            <Flex
              align='center'
              justify='center'
              w='100%'
              px={6}
              pt='44px'
              mb={7}
            >
              <Text textStyle='h3' color='primary.1'>
                Change User Role
              </Text>
            </Flex>

            <Flex align='center' justify='center' w='100%' px={6} mb={7}>
              <AlamAvatar
                size='md'
                mr={3}
                src={classUser.user?.avatar}
                name={getFirstAndLastInitial(classUser.user)}
              />
              <Text textStyle='h5' color='primary.1'>
                {getFullName(classUser.user)}
              </Text>
            </Flex>

            <Flex w='100%' px={10} mb={7}>
              <Flex
                direction='column'
                align='center'
                justify='center'
                w='100%'
                borderWidth='1px'
                borderStyle='solid'
                borderColor='primary.2'
                borderRadius='8px'
              >
                <VStack
                  {...group}
                  w='100%'
                  css={{
                    '& > :first-of-type > *': {
                      borderTopLeftRadius: '8px',
                      borderTopRightRadius: '8px',
                    },
                    '& > :last-of-type > *': {
                      borderBottomLeftRadius: '8px',
                      borderBottomRightRadius: '8px',
                    },
                  }}
                >
                  {rolesOptions().map(
                    ({ value, display }: RolesOptionsTypes) => {
                      // @ts-ignore-next-line
                      const radio = getRadioProps({ value })
                      return (
                        <RadioCard key={value} {...radio}>
                          {display}
                        </RadioCard>
                      )
                    }
                  )}
                </VStack>
              </Flex>
            </Flex>

            <ModalFooter>
              <Flex w='100%' justify='space-between'>
                <Button
                  onClick={onClose}
                  variant='base.white'
                  textStyle='button'
                  w='128px'
                >
                  Cancel
                </Button>
                <Button
                  type='submit'
                  variant='base.primary'
                  textStyle='button'
                  w='128px'
                  disabled={selectedRole === classUser.role}
                >
                  Save
                </Button>
              </Flex>
            </ModalFooter>
          </ModalBody>
        </form>
      </ModalContent>
    </Modal>
  )
}
