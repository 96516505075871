import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  VStack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  FormErrorMessage,
  FormErrorIcon,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import debounce from 'lodash-es/debounce'
import { validateSpaceOnly } from '../../utils'

type CreateSessionProps = {
  isOpen: boolean
  onClose: () => void
  onSubmit: (data: CreateSessionInput) => void
  isSubmitting: boolean
}

export type CreateSessionInput = {
  name: string
  description?: string
}

export const CreateSession = ({
  isOpen,
  onClose,
  onSubmit,
  isSubmitting,
}: CreateSessionProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    errors,
    trigger,
    formState: { isValid },
  } = useForm<CreateSessionInput>({ mode: 'onChange' })

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size='sm'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Session</ModalHeader>

        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <VStack spacing={4}>
              <FormControl isInvalid={!!errors.name?.message}>
                <FormLabel
                  variant='required'
                  fontWeight='600'
                  letterSpacing='0.1rem'
                >
                  Session Name
                </FormLabel>
                <Input
                  name='name'
                  ref={register({
                    required: 'Session Name is a required field',
                    maxLength: {
                      value: 255,
                      message: 'Session name exceeded 255 maximum characters',
                    },
                    validate: {
                      validateSpaceOnly,
                    },
                  })}
                  onChange={debounce(async () => {
                    await trigger('name')
                  }, 500)}
                />
                {errors?.name && (
                  <FormErrorMessage>
                    <FormErrorIcon />
                    {errors.name.message}
                  </FormErrorMessage>
                )}
              </FormControl>

              <FormControl
                isInvalid={!!errors.description?.message}
                css={{
                  '& .optional::after': {
                    letterSpacing: '0',
                  },
                }}
              >
                <FormLabel
                  variant='optional'
                  className='optional'
                  fontWeight='600'
                  letterSpacing='0.1rem'
                >
                  Description
                </FormLabel>
                <Input
                  name='description'
                  ref={register({
                    maxLength: {
                      value: 255,
                      message:
                        'Session description exceeded 255 maximum characters',
                    },
                  })}
                  onChange={debounce(async () => {
                    await trigger('description')
                  }, 500)}
                />

                {errors?.description && (
                  <FormErrorMessage>
                    <FormErrorIcon />
                    {errors.description?.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </VStack>
          </ModalBody>

          <ModalFooter pb='1.5rem'>
            <HStack spacing={4}>
              <Button variant='base.white' onClick={onClose} textStyle='button'>
                Cancel
              </Button>
              <Button
                variant='base.primary'
                type='submit'
                textStyle='button'
                disabled={!isValid}
                loadingText='Creating'
                isLoading={isSubmitting}
              >
                Create
              </Button>
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

export default CreateSession
