import { useMutation } from '@apollo/client'
import {
  Box,
  Flex,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Tooltip,
  Text,
  useClipboard,
} from '@chakra-ui/react'
import React from 'react'
import { BsThreeDots } from 'react-icons/bs'
import { FaTrash } from 'react-icons/fa'
import { MdContentCopy } from 'react-icons/md'
import { Link as RouterLink, useRouteMatch, useHistory } from 'react-router-dom'
import { DELETE_DOCUMENT } from '../../client/queries'
import { SessionDocument as DocumentType } from '../../types'
import { isLargerThanTablet, useErrorToast, useSuccessToast } from '../../utils'
import { deleteFile } from '../GoogleAPI'
import { GoogleDocs } from '../Icons'
import { RenderForUser } from '../SharedComponents'
import { useAlamContext } from '../../client'

export type DocumentProps = {
  document: DocumentType
  refetch: () => void
}

export const Document = ({ document, refetch }: DocumentProps): JSX.Element => {
  const [deleteDocument] = useMutation(DELETE_DOCUMENT)
  const { onCopy } = useClipboard(document.url)
  const { url } = useRouteMatch()
  const { user } = useAlamContext()
  const history = useHistory()

  const handleCopyLink = () => {
    useSuccessToast({ message: `${document.name} Link Copied!` })
    onCopy()
  }

  const handleDelete = async () => {
    try {
      await deleteFile({ fileId: document.file_id })
      await deleteDocument({ variables: { document_id: document.id } })
      refetch()

      useSuccessToast({ message: 'Document successfully deleted' })
    } catch (error) {
      useErrorToast({ error })
    }
  }

  const documentUrl = `${url}/document/${document.id}`

  const handleOnDocumentClick = () => {
    if (user?.provider && user?.provider === 'google') {
      history.push(documentUrl)
    }
  }

  return (
    <Tooltip
      label='To view this Google Doc, please sign in to Alam-edu using the "Sign in with Google" option.'
      aria-label='To view this Google Doc, please sign in to Alam-edu using the "Sign in with Google" option.'
      isDisabled={!!user?.provider && user?.provider === 'google'}
      variant='iconToolTip'
    >
      <Box
        key={document.id}
        css={{
          '&:hover > .document-wrapper > .document-box > .document-menu': {
            visibility: 'visible',
          },
        }}
      >
        <Flex
          justify='space-between'
          align='center'
          className='document-wrapper'
        >
          <Flex onClick={handleOnDocumentClick}>
            <Flex align='center' cursor='pointer'>
              <Flex
                border='2px solid'
                borderColor='#2196F3'
                borderRadius='4px'
                marginRight='8px'
                align='center'
                justify='center'
                p='8px'
              >
                <GoogleDocs />
              </Flex>

              <Box>
                <Text textStyle='body.semibold' color='rgba(0, 0, 0, 0.88)'>
                  {document.name}
                </Text>
                <Text fontSize='xs' color='#9B9B9C'>
                  Google Docs
                </Text>
              </Box>
            </Flex>
          </Flex>

          <Box className='document-box'>
            <Box
              className='document-menu'
              visibility={isLargerThanTablet() ? 'hidden' : 'visible'}
            >
              <Menu closeOnSelect>
                <MenuButton
                  as={IconButton}
                  aria-label='Document actions'
                  icon={<BsThreeDots />}
                  variant='icon.button'
                />
                <MenuList>
                  <MenuItem icon={<MdContentCopy />} onClick={handleCopyLink}>
                    Copy Link
                  </MenuItem>
                  <RenderForUser userIds={[document.user.id]}>
                    <MenuDivider />
                    <MenuItem icon={<FaTrash />} onClick={handleDelete}>
                      Remove
                    </MenuItem>
                  </RenderForUser>
                </MenuList>
              </Menu>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Tooltip>
  )
}

export default Document
