import React, { useState, useEffect, useMemo } from 'react'
import {
  Flex,
  Container,
  Menu,
  MenuButton,
  Button,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Icon,
} from '@chakra-ui/react'
import { Layout } from '../../components/Layout'
import { IoChevronDown } from 'react-icons/io5'
import { TopNavItemProps } from '../../components/TopNav'
import { HiUserGroup } from 'react-icons/hi'
import { AssignmentTypes, SubmissionTypes } from './ClassGrades'
import { Options } from './TeacherClassGrades'
import { format } from 'date-fns'
import { dateFormat } from '../../constants'
import { Topic, GroupUserForSubmission } from '../../types'
import { GradeCell } from '../../components/Grades'
import {
  UPDATE_SUBMISSION,
  UPDATE_SUBMISSION_GRADE,
  CREATE_EXAM_RESPONSE,
} from '../../client/queries'
import { useMutation } from '@apollo/client'
import {
  handleUpdateSubmissionGradeProps,
  handleUpdateSubmissionProps,
} from '../Assignment/Submission'
import {
  useErrorToast,
  useSuccessToast,
  gradesSortDisplay,
  checkDueDateFilters,
} from '../../utils'
import { v4 as uuid } from 'uuid'
import { useAlamContext } from '../../client'
import { Grade as GradeType } from '../../types'
import { DropdownCheckbox, Select } from '../../components/Input'

type GroupType = {
  id: string
  name: string
  submissions: SubmissionTypes[]
}

export type ClassGradesProps = {
  topNavLeft: TopNavItemProps[]
  topNavCenter: TopNavItemProps[]
  assignments: AssignmentTypes[]
  groups: GroupType[]
  groupsRefetch: () => void
  topics: Topic[]
}

type TableHeadingTypes = {
  id: string
  date: string
  title: string
  rating: string
}

type TableDataTypes = {
  id: string
  title: string
  status: string
}

const GroupsGrades = ({
  topNavLeft,
  topNavCenter,
  assignments,
  topics,
  groups,
  groupsRefetch,
}: ClassGradesProps): JSX.Element => {
  const { classUser, user } = useAlamContext()
  const dueDateOptions = [
    {
      display: 'With Due Date',
      value: 'with_due_date',
    },
    {
      display: 'No Due Date',
      value: 'without_due_date',
    },
    {
      display: 'Past Due Date',
      value: 'past_due',
    },
  ]
  const [selectedSubmission, setSelectedSubmission] = useState<any>()
  const [grade, setGrade] = useState('')
  const [sortBy, setSortBy] = useState('a-z')
  const [topicFilter, setTopicFilter] = useState<Options[]>([])
  const [checkedTopics, setCheckedTopics] = useState<string[]>([])
  const [dueDateFilter, setDueDateFilter] = useState<Options[]>([])
  const [checkedDueDateOptions, setCheckedDueDateOptions] = useState<string[]>(
    []
  )

  const [createExamResponse] = useMutation(CREATE_EXAM_RESPONSE)

  useEffect(() => {
    const topicOptions = topics.map((topic) => ({
      display: topic.name,
      value: topic.id,
    }))

    const initialDueDateOptions = dueDateOptions.map(({ value }) => value)
    const initialTopics = topics.map(({ id }) => id)

    setDueDateFilter(dueDateOptions)
    setTopicFilter(topicOptions)
    setCheckedTopics(initialTopics)
    setCheckedDueDateOptions(initialDueDateOptions)
  }, [topics])

  const learningReview = useMemo(() => {
    if (selectedSubmission) {
      return selectedSubmission?.assignment?.examsForGroups?.find(
        (exam: any) => exam.type === 'learning_review'
      )
    }
    return undefined
  }, [selectedSubmission])

  const examResponseForSubmission = useMemo(() => {
    if (selectedSubmission && learningReview) {
      return learningReview?.exam_responses?.find((exam_response: any) => {
        return (
          exam_response?.group_user?.group_id === selectedSubmission?.group_id
        )
      })
    }
  }, [learningReview])

  const createLearningReview = async (gradeId: string) => {
    if (!gradeId) {
      return
    }

    const params = selectedSubmission?.group?.groupUsers?.map(
      ({ id }: GroupUserForSubmission) => ({
        id: uuid(),
        exam_id: learningReview.id,
        inserted_at: 'NOW()',
        updated_at: 'NOW()',
        started_at: 'NOW()',
        group_user_id: id,
      })
    )

    await createExamResponse({
      variables: {
        params,
      },
    })
  }

  const applyFilters = (assignments: any) => {
    return assignments.filter(
      (assignment: any) =>
        ((assignment.topic && checkedTopics.includes(assignment.topic.id)) ||
          assignment.topic === null) &&
        checkDueDateFilters(assignment, checkedDueDateOptions)
    )
  }

  const applySubmissionFilters = (submissions: SubmissionTypes[]) => {
    return submissions.filter(
      (submission: any) =>
        applyFilters(assignments).includes(submission.assignment) ||
        applyFilters(assignments).length === assignments.length
    )
  }

  useEffect(() => {
    setGrade(selectedSubmission?.grades[0]?.grade || '')
  }, [selectedSubmission?.grades[0]?.grade])

  useEffect(() => {
    updateSelectedSubmission(groups)
  }, [groups])

  const [updateSubmission, { loading: submissionIsUpdating }] = useMutation(
    UPDATE_SUBMISSION
  )

  const [
    updateSubmissionGrade,
    { loading: submissionGradeIsUpdating },
  ] = useMutation(UPDATE_SUBMISSION_GRADE)

  const handleUpdateSubmission: handleUpdateSubmissionProps = ({
    status,
    gradeId,
  }) => {
    updateSubmission({
      variables: {
        submission_id: selectedSubmission?.id,
        status,
        event_log_id: uuid(),
        user_id: user?.id,
        grade_id: gradeId ? gradeId : null,
      },
    })
      .then(() => {
        if (gradeId && learningReview && !examResponseForSubmission) {
          createLearningReview(gradeId)
        }
        groupsRefetch()
        useSuccessToast({ message: 'Assignment was successfully returned' })
      })
      .catch(() => {
        useErrorToast({})
      })
  }

  const handleUpdateSubmissionGrade: handleUpdateSubmissionGradeProps = ({
    grade,
    status,
  }) => {
    updateSubmissionGrade({
      variables: {
        submission_id: selectedSubmission?.id,
        status,
        event_log_id: uuid(),
        user_id: user?.id,
        grade,
        grade_id: uuid(),
      },
    })
      .then(() => {
        groupsRefetch()
        useSuccessToast({ message: 'Assignment was successfully graded' })
      })
      .catch((error) => {
        useErrorToast({})
      })
  }

  const updateSelectedSubmission = (groups: GroupType[]): void => {
    if (selectedSubmission) {
      const newSubmissions = groups.map((group) => group.submissions).flat()
      const updatedSubmission = newSubmissions.find((submission) => {
        return submission.id == selectedSubmission?.id
      })
      setSelectedSubmission(updatedSubmission)
    }
  }

  const currentGrade = selectedSubmission?.grades[0]

  const isGradeButtonDisabled = () => {
    if (!currentGrade?.grade && !grade) {
      return true
    }

    if (grade === currentGrade?.grade) {
      return true
    }

    if (currentGrade && currentGrade?.grader_id !== user?.id) {
      return true
    }

    if (grade === '') return true

    return false
  }

  const isReturnButtonDisabled = () => {
    if (
      !selectedSubmission ||
      ['returned', 'unsubmitted', 'not assigned'].includes(
        selectedSubmission.status
      ) ||
      (currentGrade && currentGrade?.grader_id !== user?.id)
    ) {
      return true
    }
    return false
  }

  const sortFunction = (groups: GroupType[], sortBy: string): GroupType[] => {
    const alphabeticalGroups = [...groups].sort((group1, group2) => {
      if (group1.name < group2.name) return -1
      if (group1.name > group2.name) return 1
      return 0
    })

    if (sortBy == 'a-z') return alphabeticalGroups
    if (sortBy == 'z-a') return alphabeticalGroups.reverse()
    return alphabeticalGroups
  }

  const sortOptions = [
    { value: 'a-z', display: 'Alphabetical A-Z' },
    { value: 'z-a', display: 'Alphabetical Z-A' },
  ]
  return (
    <>
      <Container maxW='100%' py={4}>
        <Flex align='center' justify='space-between'>
          <Flex>
            <HStack spacing={4}>
              <DropdownCheckbox
                isTruncated
                onChange={setCheckedTopics}
                allTitle='All'
                emptyTitle='None'
                indeterminateTitle='All'
                options={topicFilter}
                values={checkedTopics}
                {...{
                  menuButtonProps: {
                    w: 'auto',
                    height: '48px',
                    padding: '8px 12px',
                    textStyle: 'button',
                    color: 'primary.1',
                    variant: 'tab.selector',
                    layerStyle: 'card.module',
                  },
                }}
              />

              <Select
                value={sortBy}
                onChange={(value) => setSortBy(value)}
                options={sortOptions}
                menuButtonProps={{
                  textStyle: 'button',
                  variant: 'tab.selector',
                  textAlign: 'left',
                  py: '8px',
                  pr: '8px',
                  w: '196px',
                  layerStyle: 'card.module',
                }}
                menuItemProps={{
                  textStyle: 'body.1',
                  color: 'primary.1',
                  w: '196px',
                  _hover: { bg: 'hover.2', color: 'white.1' },
                }}
                displayTransform={gradesSortDisplay}
              />

              <DropdownCheckbox
                onChange={setCheckedDueDateOptions}
                allTitle='All'
                emptyTitle='None'
                indeterminateTitle='Due Dates'
                options={dueDateOptions}
                values={checkedDueDateOptions}
                {...{
                  menuButtonProps: {
                    w: 'auto',
                    height: '48px',
                    padding: '8px 12px',
                    textStyle: 'button',
                    textAlign: 'left',
                    color: 'primary.1',
                    variant: 'tab.selector',
                    layerStyle: 'card.module',
                  },
                }}
              />
            </HStack>
          </Flex>
          <Flex>
            <HStack spacing={4}>
              <Button
                variant='base.primary'
                textStyle='button'
                disabled={isReturnButtonDisabled()}
                isLoading={submissionIsUpdating}
                onClick={() =>
                  handleUpdateSubmission({
                    status: 'returned',
                    gradeId: selectedSubmission?.grades[0]?.id,
                  })
                }
              >
                Return
              </Button>
              <Button
                variant='base.white.disabled'
                textStyle='button'
                disabled={isGradeButtonDisabled()}
                isLoading={submissionGradeIsUpdating}
                onClick={() =>
                  handleUpdateSubmissionGrade({ status: 'graded', grade })
                }
              >
                Save
              </Button>
            </HStack>
          </Flex>
        </Flex>
      </Container>
      <Container w='100%' minW='100%' p='0'>
        <Table bg='white.1' display='inline-block' overflow='auto'>
          <Thead>
            <Tr>
              <Th
                w='258px'
                borderWidth='0.2px'
                borderStyle='solid'
                borderColor='primary.2-7'
                px='24px'
                py='16px'
                textStyle='h4'
                textTransform='initial'
                color='black.1'
                minHeight='64px'
              >
                Groups
              </Th>

              {applyFilters(assignments).map((assignment: AssignmentTypes) => (
                <Th
                  borderWidth='0.2px'
                  borderStyle='solid'
                  borderColor='primary.2-7'
                  px={4}
                  py={2}
                  key={assignment.id}
                  maxW='112px'
                >
                  <Flex direction='column' maxW='112px'>
                    <Text fontSize='8px' fontWeight='400' color='black.3'>
                      {format(new Date(assignment.updated_at), dateFormat)}
                    </Text>
                    <Text
                      textStyle='smallstate'
                      color='black.2'
                      textTransform='initial'
                    >
                      {assignment.name}
                    </Text>
                    <Text
                      fontSize='8px'
                      fontWeight='400'
                      color='black.3'
                      visibility={
                        assignment.rubric === 'numerical' ? 'visible' : 'hidden'
                      }
                    >
                      Out of {assignment.grading}
                    </Text>
                  </Flex>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {sortFunction(groups, sortBy).map((group: GroupType) => (
              <Tr>
                <Td
                  w='258px'
                  borderWidth='0.2px'
                  borderStyle='solid'
                  borderColor='primary.2-7'
                  p={0}
                >
                  <Flex align='center' minHeight='64px' p='12px 16px 12px 24px'>
                    <Icon as={HiUserGroup} boxSize='22px' mr='9px' />
                    <Text textStyle='body.semibold' color='black.1'>
                      {group.name}
                    </Text>
                  </Flex>
                </Td>

                {applySubmissionFilters(group.submissions).map(
                  (submission: SubmissionTypes) => (
                    <GradeCell
                      key={submission.id}
                      submission={submission}
                      selectedSubmission={selectedSubmission}
                      setSelectedSubmission={setSelectedSubmission}
                      grade={grade}
                      setGrade={setGrade}
                      onUpdateSubmission={handleUpdateSubmission}
                      link={`/classes/${classUser?.class?.id}/groups/${group.id}/assignments/${submission?.assignment?.id}/submissions`}
                    />
                  )
                )}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Container>
    </>
  )
}

export default GroupsGrades
