import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormErrorMessage,
  FormErrorIcon,
  Button,
  HStack,
  VStack,
} from '@chakra-ui/react'
import { useForm, FormProvider } from 'react-hook-form'
import { FormInput, FormButton } from '../Input'
import { Group as GroupType } from '../../types'
import { validateSpaceOnly } from '../../utils'

export type CreateGroupInput = {
  groupName: string
  description?: string
}

export interface ModalProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (data: CreateGroupInput) => void
  group?: GroupType
  size: string
  isSubmitting: boolean
}

export const GroupsForm = ({
  isOpen,
  onClose,
  onSubmit,
  isSubmitting,
  size,
  group = {
    id: '',
    name: '',
    description: '',
  },
}: ModalProps): JSX.Element => {
  const methods = useForm<CreateGroupInput>({
    mode: 'onChange',
    defaultValues: { groupName: '', description: '' },
  })

  const {
    errors,
    formState: { isValid },
  } = methods

  return (
    <FormProvider {...methods}>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size='sm'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Group</ModalHeader>

          <ModalCloseButton />
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalBody>
              <VStack spacing={4}>
                <FormInput
                  name='groupName'
                  label='Group Name'
                  placeholder=''
                  formLabelProps={{ variant: 'required' }}
                  validationProps={{
                    required: 'Group name is a required field',
                    maxLength: {
                      value: 255,
                      message: 'Name exceeded 255 maximum characters',
                    },
                    validate: {
                      validateSpaceOnly,
                    },
                  }}
                />
                {errors.groupName && (
                  <FormErrorMessage>
                    <FormErrorIcon />
                    {errors.groupName?.message}
                  </FormErrorMessage>
                )}
                <FormInput
                  name='description'
                  label='Description'
                  formLabelProps={{ variant: 'optional' }}
                  validationProps={{
                    maxLength: {
                      value: 1000,
                      message: 'Description exceeded 1000 maximum characters',
                    },
                  }}
                />
                {errors.description && (
                  <FormErrorMessage>
                    <FormErrorIcon />
                    {errors.description?.message}
                  </FormErrorMessage>
                )}
              </VStack>
            </ModalBody>

            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  variant='base.white'
                  onClick={onClose}
                  textStyle='button'
                >
                  Cancel
                </Button>
                <FormButton
                  text='Create'
                  isSubmitting={isSubmitting}
                  buttonProps={{
                    variant: 'base.primary',
                    textStyle: 'button',
                    disabled: !isValid,
                  }}
                  submittingText='Creating'
                />
              </HStack>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </FormProvider>
  )
}
