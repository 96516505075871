import React from 'react'
import { Icon } from '@chakra-ui/react'

export const BreadcrumbArrow = (props: Record<string, any>): JSX.Element => {
  return (
    <Icon
      width='8'
      height='13'
      viewBox='0 0 8 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M0.290039 10.88L4.88004 6.29004L0.290039 1.70004L1.71004 0.290039L7.71004 6.29004L1.71004 12.29L0.290039 10.88Z'
        fill='currentColor'
      />
    </Icon>
  )
}
