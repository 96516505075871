import { Menu, MenuButton, MenuList, Box, Button } from '@chakra-ui/react'
import { IoChevronDown } from 'react-icons/io5'
import styled from '@emotion/styled'
import { DropdownOption } from '../../utils'

type ItemProps = Array<{
  isEverythingChecked: boolean
  values: string[]
  options: DropdownOption[]
  title?: string
  isEmpty: boolean
  isIndeterminate: boolean
}>

export type MultiDropdownProps = {
  menuButtonProps?: Record<string, string>
  children: any
  itemProps: ItemProps
  allTitle: string
  indeterminateTitle: string
  emptyTitle: string
}
export const MultiDropdown = ({
  menuButtonProps,
  children,
  itemProps,
  allTitle,
  indeterminateTitle,
  emptyTitle,
}: MultiDropdownProps): JSX.Element => {
  // To-do: write tests for this
  const getTitle = (itemProps: ItemProps) => {
    const isEverythingChecked = itemProps.filter(
      (itemProp) => itemProp.isEverythingChecked
    )
    const isIndeterminate = itemProps.filter(
      (itemProp) => itemProp.isIndeterminate
    )

    const isEmpty = itemProps.every((itemProp) => itemProp.isEmpty)

    if (isEmpty) {
      return emptyTitle
    }

    // All items under all categories are checked
    if (
      isEverythingChecked.length === itemProps.length &&
      itemProps.length > 0
    ) {
      return allTitle
    }

    // 1 category is all checked
    if (isEverythingChecked.length === 1 && isIndeterminate.length === 0) {
      return isEverythingChecked[0]?.title
    }

    const indeterminateDisplay = isIndeterminate
      .map(({ title }) => title)
      .join('; ')

    // 2 or more indeterminate categories
    if (isIndeterminate.length > 1) {
      // return indeterminateTitle
      return indeterminateDisplay
    }

    // 1 indeterminate category; return that title
    if (isEverythingChecked.length === 0 && isIndeterminate.length === 1) {
      return isIndeterminate[0]?.title
    }

    if (isEverythingChecked.length === 0) {
      return emptyTitle
    }

    return itemProps.map(({ title }) => title).join('; ')
  }

  return (
    <MenuWrapper>
      <Menu
        autoSelect={false}
        closeOnSelect={false}
        placement='bottom-start'
        flip={false}
      >
        <MenuButton
          as={Button}
          rightIcon={<IoChevronDown />}
          variant='input'
          {...menuButtonProps}
        >
          {getTitle(itemProps)}
        </MenuButton>

        <MenuList>{children}</MenuList>
      </Menu>
    </MenuWrapper>
  )
}

const MenuWrapper = styled(Box)`
  [role='menu'] {
    max-height: 200px;
    overflow-y: scroll;
  }
`

export default MultiDropdown
