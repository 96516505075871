import { Fragment, useState, useEffect, useMemo } from 'react'
import {
  Box,
  Button,
  IconButton,
  Icon,
  Flex,
  Menu,
  MenuButton,
  Text,
  HStack,
  Container,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from '@chakra-ui/react'
import { IoChevronDown } from 'react-icons/io5'
import { MdFileDownload } from 'react-icons/md'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import {
  renderStudentsHeading,
  renderLearningPreferenceHeading,
  renderTopicHeading,
} from '../../components/Analytics'
import { AlamAvatar } from '../../components/Avatar'
import { MdRemoveRedEye } from 'react-icons/md'
import { IoEarOutline } from 'react-icons/io5'
import { Read, Kinesthetic } from '../../components/Icons'
import { Layout } from '../../components/Layout'
import { TopNavItemProps } from '../../components/TopNav'
import { useQuery } from '@apollo/client'
import {
  GET_STUDENT_ANALYTICS_HEADER_DATA,
  GET_ANALYTICS_STUDENT_DATA,
  GET_ANALYTICS_GROUP_DATA,
} from '../../client/queries'
import { useParams } from 'react-router-dom'
import {
  constructTableHeadings,
  StudentAnalyticsTableHeading,
  getPreferenceIcon,
  displayPreference,
  renderStudentCellContent,
  AnalyticsUserData,
  usePagination,
  sortStudentDataAZ,
  AnalyticsTopicData,
} from '../../utils'
import { DropdownCheckbox } from '../../components/Input'
import { Options } from '../ClassGrades/TeacherClassGrades'
import {
  IndividualAnalyticsTable,
  GroupAnalyticsTable,
} from '../../components/Analytics'
import { Select } from '../../components/Input'
import { DownloadButton } from '../../components/Analytics'

type StudentType = {
  id: string
  name: string
  learningPreference: string
  materialCompletionTime: string
  assignmentCompletionTime: string
  assignmentScore: string
}

type StudentAnalyticsPageProps = {
  topNavLeft: TopNavItemProps[]
  topNavCenter: TopNavItemProps[]
}

const StudentsAnalyticsPage = ({
  topNavLeft,
  topNavCenter,
}: StudentAnalyticsPageProps): JSX.Element => {
  const [topicFilter, setTopicFilter] = useState<Options[]>([])
  const [classFilter, setClassFilter] = useState<string>('students')
  const [checkedTopics, setCheckedTopics] = useState<string[]>([])
  const { id } = useParams<{ id: string }>()
  const { data: headerData } = useQuery(GET_STUDENT_ANALYTICS_HEADER_DATA, {
    variables: { class_id: id },
  })
  const { data: studentData } = useQuery(GET_ANALYTICS_STUDENT_DATA, {
    variables: { class_id: id },
  })
  const { data: groupData } = useQuery(GET_ANALYTICS_GROUP_DATA, {
    variables: { class_id: id },
  })

  const students = studentData?.users || []
  const groups = groupData?.groups || []

  const tableHeadingsFromData = constructTableHeadings(headerData)

  useEffect(() => {
    const topicOptions = (headerData?.topics || [])?.map(
      (topic: AnalyticsTopicData) => {
        return {
          value: topic?.id,
          display: topic?.name,
        }
      }
    )

    const initialTopics = topicOptions.map((options: Options) => {
      return options.value
    })

    setTopicFilter(topicOptions)
    setCheckedTopics(initialTopics)
  }, [headerData])

  const filteredHeadings = useMemo(() => {
    return tableHeadingsFromData.filter(({ topic }) => {
      if (topicFilter.length === checkedTopics.length) {
        return true
      }
      if (checkedTopics.length == 0) {
        return !topic
      }

      if (!topic) return false
      return checkedTopics.includes(topic.id!)
    })
  }, [tableHeadingsFromData, checkedTopics])

  const tableStyle = {
    borderWidth: '0.5px',
    borderStyle: 'solid',
    borderColor: 'primary.2',
  }

  const classSelectOption = [
    {
      value: 'students',
      display: 'Class',
    },
    {
      value: 'groups',
      display: 'Groups',
    },
  ]

  return (
    <Layout topNavLeft={topNavLeft} topNavCenter={topNavCenter} showSideNav>
      <Box maxW='calc(100vw - 240px)' overflow='auto' p='0px'>
        <Box px={6} pt={6} mb={8} maxW='100%'>
          <Flex align='center' justify='space-between'>
            <Flex>
              <Select
                value={classFilter}
                options={classSelectOption}
                onChange={setClassFilter}
                menuButtonProps={{
                  as: Button,
                  rightIcon: <IoChevronDown />,
                  textStyle: 'button',
                  variant: 'tab.selector',
                  p: '8px 12px',
                  textAlign: 'left',
                  letterSpacing: '0.06rem',
                  w: 'auto',
                  textTransform: 'uppercase',
                  layerStyle: 'card.module',
                  mr: 4,
                  color: 'primary.1',
                }}
                menuItemProps={{
                  textStyle: 'body.1',
                  color: 'primary.1',
                  textTransform: 'uppercase',
                  w: '196px',
                  _hover: { bg: 'hover.2', color: 'white.1' },
                }}
                adjustRightIconPadding='1rem'
              />

              <DropdownCheckbox
                isTopicDropdown
                isTruncated
                onChange={setCheckedTopics}
                allTitle='All Data'
                emptyTitle='None'
                indeterminateTitle='Topics'
                options={topicFilter}
                values={checkedTopics}
                {...{
                  menuButtonProps: {
                    as: Button,
                    rightIcon: <IoChevronDown />,
                    textStyle: 'button',
                    variant: 'tab.selector',
                    p: '8px 12px',
                    textAlign: 'left',
                    letterSpacing: '0.06rem',
                    w: 'auto',
                    layerStyle: 'card.module',
                  },
                  selectedDisplayProps: {
                    textAlign: 'left',
                    letterSpacing: '0.06rem',
                    textStyle: 'button',
                    color: 'primary.1',
                  },
                }}
              />
            </Flex>

            <DownloadButton classId={id} />
          </Flex>
        </Box>
        {classFilter === 'students' && (
          <IndividualAnalyticsTable
            students={students}
            headings={filteredHeadings}
          />
        )}
        {classFilter === 'groups' && (
          <GroupAnalyticsTable groups={groups} headings={filteredHeadings} />
        )}
      </Box>
    </Layout>
  )
}

export default StudentsAnalyticsPage
