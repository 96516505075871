import { useMediaQuery, useTheme } from '@chakra-ui/react'

export const isLargerThanTablet = () => {
  const [mediaQueryTablet] = useMediaQuery('(min-width: 769px)')

  const userAgentTablet = /ipad|android|android 3.0|xoom|sch-i800|playbook|tablet|kindle/i.test(
    navigator.userAgent.toLowerCase()
  )

  const userAgentIpadPro =
    navigator.userAgent.match(/Mac/) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2

  return mediaQueryTablet && !userAgentTablet && !userAgentIpadPro
}

export const isMobileDevice = () => {
  const { breakpoints } = useTheme()
  const [isMobileDevice] = useMediaQuery([`(max-width: ${breakpoints.mobile})`])

  return isMobileDevice
}

export const isIPhone = () => {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) && isMobileDevice()
  )
}
