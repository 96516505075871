import React from 'react'
import { MenuItem, Checkbox, Tooltip, useDisclosure } from '@chakra-ui/react'
import { truncate } from '../../utils'

interface TopicFilterItemProps {
  name: string
  isTruncated: boolean
  isChecked: boolean
  handleChange: (e: any) => void
}

const TopicFilterItem = ({
  name,
  isTruncated,
  isChecked,
  handleChange,
}: TopicFilterItemProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <MenuItem layerStyle='menu.item.checkbox.indented'>
      <Tooltip
        shouldWrapChildren={true}
        label={name}
        aria-label={name}
        isOpen={isTruncated && isOpen}
        onOpen={() => onOpen()}
        onClose={() => onClose()}
      >
        <Checkbox
          isChecked={isChecked}
          onChange={handleChange}
          css={{
            '&> .chakra-checkbox__label': {
              height: '100%',
              display: 'block',
            },
          }}
        >
          {truncate(name, 24)}
        </Checkbox>
      </Tooltip>
    </MenuItem>
  )
}

export default TopicFilterItem
