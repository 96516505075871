import { EventLog, Submission, User, AnalyticsAssignmentUser } from '../types'
import { roundToNearestHundred } from './index'
import {
  millisecondsToMinutes,
  subMilliseconds,
  differenceInMilliseconds,
  millisecondsToHours,
} from 'date-fns'
import format from 'date-fns/format'

export interface CompletionGraphData {
  amt: number
  roundedCompletionTime: number
}

export interface CompletionGraph {
  graphData: CompletionGraphData[]
  unit: string
}

export interface StudentWithCompletionData {
  id: string
  first_name: string
  last_name: string
  avatar?: string
  start?: number
  end?: number
  completionTime?: number
  submissionDate?: string
  group_name?: string
}

export interface GroupWithCompletionData {
  id: string
  name: string
  start?: number
  end?: number
  completionTime?: number
  submissionDate?: string
}

export interface AnalyticsAssignmentGroupData {
  id: string
  viewed_at?: string
  group: AnalyticsCompletionGroupData
}

export interface AnalyticsCompletionGroupData {
  id: string
  name: string
  submissions: Submission[]
}

export type CompletionData = StudentWithCompletionData | GroupWithCompletionData

const getCompletionData = (assignmentUser: AnalyticsAssignmentUser) => {
  const submission = assignmentUser?.user?.submissions[0]
  const submittedEventLog = getSubmittedEventLog(submission?.event_logs)

  if (!submittedEventLog || !submission) {
    return {
      start: undefined,
      end: undefined,
      submissionDate: undefined,
      completionTime: undefined,
    }
  }

  const rawStartDate =
    assignmentUser?.viewed_at ||
    getEventLog(submission.event_logs, 'started')?.inserted_at ||
    submission.inserted_at!
  const dateStart = Date.parse(rawStartDate)

  const dateEnd = Date.parse(submittedEventLog.inserted_at)
  const completionTime = differenceInMilliseconds(dateEnd, dateStart)

  return {
    start: dateStart,
    end: dateEnd,
    submissionDate: submittedEventLog.inserted_at,
    completionTime: completionTime,
  }
}

const getGroupCompletionData = (
  assignmentGroup: AnalyticsAssignmentGroupData
) => {
  const submission = assignmentGroup?.group?.submissions[0]
  const submittedEventLog = getSubmittedEventLog(submission?.event_logs)
  const rawStartDate =
    assignmentGroup?.viewed_at ||
    getEventLog(submission.event_logs, 'started')?.inserted_at ||
    submission.inserted_at!
  const dateStart = Date.parse(rawStartDate)

  if (!submittedEventLog) {
    return {
      start: dateStart,
      end: undefined,
      submissionDate: undefined,
      completionTime: undefined,
    }
  }

  const dateEnd = Date.parse(submittedEventLog.inserted_at)
  const completionTime = differenceInMilliseconds(dateEnd, dateStart)

  return {
    start: dateStart,
    end: dateEnd,
    submissionDate: submittedEventLog.inserted_at,
    completionTime: completionTime > 0 ? completionTime : 0,
  }
}

export const buildStudentCompletionData = (
  assignmentUser: AnalyticsAssignmentUser
) => {
  const completionData = getCompletionData(assignmentUser)
  const group_name = assignmentUser?.user?.group_users[0]?.group?.name

  return {
    ...assignmentUser.user,
    ...completionData,
    group_name,
  }
}

export const buildGroupCompletionData = (
  assignmentGroup: AnalyticsAssignmentGroupData
): GroupWithCompletionData => {
  const completionData = getGroupCompletionData(assignmentGroup)
  const group = assignmentGroup?.group

  return {
    id: group?.id,
    name: group?.name,
    ...completionData,
  }
}

export const buildInitialGraphData = (completedStudents: CompletionData[]) => {
  switch (completedStudents.length) {
    case 0:
      return buildTicksFromRange(1, 1 + 9, 'minutes')
    case 1:
      return durationTicksFromOne(completedStudents[0])
    default:
      return durationTicksFromMany(completedStudents)
  }
}

export const durationTicksFromOne = (student: CompletionData) => {
  const completionTime = student.completionTime!
  const minutes = millisecondsToMinutes(completionTime)
  const hours = Math.ceil(minutes / 60)
  const days = Math.ceil(hours / 24)
  const weeks = Math.ceil(days / 7)
  const months = Math.ceil(weeks / 4)

  if (months > 1) {
    return buildTicksFromRange(months, months + 9, 'months')
  } else if (weeks > 1) {
    return buildTicksFromRange(weeks, weeks + 9, 'weeks')
  } else if (days > 1) {
    return buildTicksFromRange(days, days + 9, 'days')
  } else if (hours > 1) {
    return buildTicksFromRange(hours, hours + 9, 'hours')
  } else if (minutes > 1) {
    return buildTicksFromRange(minutes, minutes + 9, 'minutes')
  }
  return buildTicksFromRange(minutes, minutes + 9, 'minutes')
}

export const durationTicksFromMany = (completedStudents: CompletionData[]) => {
  const completionTimes = completedStudents.map(
    (student) => student.completionTime!
  )
  const minCompletionTime = Math.min(...completionTimes)
  const maxCompletionTime = Math.max(...completionTimes)
  const difference = maxCompletionTime - minCompletionTime
  const intervalInMilliseconds = Math.ceil(difference / 9)
  const unit = getUnit(intervalInMilliseconds)

  const formattedMinCompletionTime = convertToUnit(minCompletionTime, unit)
  const formattedMaxCompletionTime = convertToUnit(maxCompletionTime, unit)
  const formattedInterval = Math.ceil(
    (formattedMaxCompletionTime - formattedMinCompletionTime) / 9
  )

  if (formattedInterval === 0) {
    return buildTicksFromRange(
      formattedMinCompletionTime,
      formattedMinCompletionTime + 9,
      unit
    )
  }

  return buildTicksFromInterval(
    formattedMinCompletionTime,
    formattedInterval,
    unit
  )
}

export const getUnit = (interval: number) => {
  const minutes = millisecondsToMinutes(interval)
  const hours = Math.ceil(minutes / 60)
  const days = Math.ceil(hours / 24)
  const weeks = Math.ceil(days / 7)
  const months = Math.ceil(weeks / 4)

  if (months > 1) {
    return 'months'
  } else if (weeks > 1) {
    return 'weeks'
  } else if (days > 1) {
    return 'days'
  } else if (hours > 1) {
    return 'hours'
  }
  return 'minutes'
}

export const convertToUnit = (interval: number, unit: string) => {
  const minutes = millisecondsToMinutes(interval)

  if (unit === 'minutes') {
    return minutes
  }

  const hours = millisecondsToHours(interval)

  if (unit === 'hours') {
    return hours
  }

  const days = Math.ceil(hours / 24)

  if (unit === 'days') {
    return days
  }

  const weeks = Math.ceil(days / 7)

  if (unit === 'weeks') {
    return weeks
  }

  const months = Math.ceil(weeks / 4)

  if (unit === 'months') {
    return months
  }

  return interval
}

export const populateCompletionGraph = (
  completedStudents: CompletionData[],
  completionGraph: CompletionGraph | undefined
) => {
  if (!completionGraph) {
    return
  }

  const populatedGraphData = completedStudents.reduce(
    (graphDataAcc: CompletionGraphData[], student: CompletionData) => {
      const convertedCompletionTime = convertToUnit(
        student.completionTime!,
        completionGraph.unit
      )
      return addToGraph(convertedCompletionTime, graphDataAcc)
    },
    completionGraph?.graphData
  )

  return {
    ...completionGraph,
    graphData: populatedGraphData,
  }
}

const addToGraph = (
  completionTime: number,
  graphData: CompletionGraphData[]
) => {
  return graphData?.map((graphItem, index, currentArray) => {
    const isLessThanRounded = completionTime <= graphItem.roundedCompletionTime
    const previousGraphItem = currentArray[index - 1]
    const isGreaterThanPreviousRounded = !previousGraphItem
      ? true
      : completionTime > currentArray[index - 1].roundedCompletionTime

    if (isLessThanRounded && isGreaterThanPreviousRounded) {
      return {
        ...graphItem,
        amt: graphItem.amt + 1,
      }
    }

    return graphItem
  })
}

export const buildTicksFromRange = (
  start: number,
  end: number,
  unit: string
) => {
  const array = []
  for (let x = start; x <= end; x++) {
    array.push(x)
  }

  const graphData = array.map((tick: number) => {
    return {
      amt: 0,
      roundedCompletionTime: tick,
    }
  })

  return {
    graphData,
    unit: unit,
  }
}

export const buildTicksFromInterval = (
  start: number,
  interval: number,
  unit: string
) => {
  const array = []
  const finalNumber = start + interval * 9

  for (let x = start; x <= finalNumber; x = x + interval) {
    array.push(x)
  }

  const graphData = array.map((tick: number) => {
    return {
      amt: 0,
      roundedCompletionTime: tick,
    }
  })

  return {
    graphData,
    unit: unit,
  }
}

export const getSubmittedEventLog = (eventLogs: EventLog[]) => {
  return getEventLog(eventLogs, 'submitted')
}

export const getEventLog = (eventLogs: EventLog[], key: string) => {
  return (eventLogs || []).find((log: EventLog) => log.content === key)
}

export const formatCompletionTime = (valueInMilliseconds: number) => {
  const rawHours = valueInMilliseconds / 1000 / 60 / 60
  const hours = Math.floor(rawHours)
  const rawMinutes = (rawHours - hours) * 60
  const minutes = Math.floor(rawMinutes)
  const rawSeconds = (rawMinutes - minutes) * 60
  const seconds = Math.floor(rawSeconds)
  const formattedTime = `${hours}h:${minutes}m:${seconds}s`

  return formattedTime
}

const formatSubmissionDate = (submissionDate: string) => {
  return format(new Date(submissionDate), 'MM/dd/yyyy')
}

export const renderCompletionTime = (completionTime: number | undefined) => {
  if (!completionTime && completionTime !== 0) return 'N/A'

  return formatCompletionTime(completionTime)
}

export const renderSubmissionDate = (submissionDate: string | undefined) => {
  if (!submissionDate) return 'N/A'

  return formatSubmissionDate(submissionDate)
}
