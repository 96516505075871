import { Option } from '../types'
import { SubmissionTypes } from '../pages/ClassGrades/ClassGrades'
import { SubmissionType } from '../pages/ClassGrades/StudentClassGrades'

export const gradesSortDisplay = ({ value }: Option): string => {
  return `Sort by ${value}`
}

export const displayStatus = (
  submission: SubmissionTypes | SubmissionType
): string => {
  switch (submission.status) {
    case 'unsubmitted':
      return 'Assigned'
    case 'submitted':
      return 'Turned In'
    default:
      return submission.status
  }
}
