import { createContext, useContext, Dispatch } from 'react'
import { Exam, ExamArrangement } from '../types'
import {
  createQuestion,
  removeQuestion,
  addResponseChoice,
  updateResponseChoice,
  moveResponseChoiceUp,
  moveResponseChoiceDown,
  updateQuestionType,
  updateQuestionText,
  moveQuestionUp,
  moveQuestionDown,
  removeResponseChoice,
} from '../utils/useExamForm'

export type ExamFormContextProps = {
  exam?: Exam
  exam_arrangements?: ExamArrangement[]
  selectedExamArrangement?: ExamArrangement
  setSelectedExamArrangement: Dispatch<ExamArrangement | undefined>
  createQuestion: createQuestion
  removeQuestion: removeQuestion
  addResponseChoice: addResponseChoice
  updateResponseChoice: updateResponseChoice
  moveResponseChoiceUp: moveResponseChoiceUp
  moveResponseChoiceDown: moveResponseChoiceDown
  updateQuestionType: updateQuestionType
  updateQuestionText: updateQuestionText
  moveQuestionUp: moveQuestionUp
  moveQuestionDown: moveQuestionDown
  removeResponseChoice: removeResponseChoice
  removeOnCancel: boolean
  setRemoveOnCancel: (removeOnCancel: boolean) => void
}

export const ExamFormContext = createContext<ExamFormContextProps>({
  exam: undefined,
  exam_arrangements: [],
  selectedExamArrangement: undefined,
  setSelectedExamArrangement: () => {},
  createQuestion: () => {},
  removeQuestion: () => {},
  addResponseChoice: () => {},
  updateResponseChoice: () => {},
  moveResponseChoiceUp: () => {},
  moveResponseChoiceDown: () => {},
  updateQuestionType: () => {},
  updateQuestionText: () => {},
  moveQuestionUp: () => {},
  moveQuestionDown: () => {},
  removeResponseChoice: () => {},
  removeOnCancel: false,
  setRemoveOnCancel: () => {},
})

export const useExamFormContext = () => useContext(ExamFormContext)
