import React from 'react'
import styled from '@emotion/styled'
import {
  Text,
  Box,
  Flex,
  IconButton,
  HStack,
  VStack,
  StackDivider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  Tooltip,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { BsThreeDots } from 'react-icons/bs'
import { RiPencilFill } from 'react-icons/ri'
import { FaTrash } from 'react-icons/fa'
import { ConfirmationModal } from '../Modal'
import { EditClass, EditClassInput } from '../Forms'
import { UPDATE_CLASS, ARCHIVE_CLASS } from '../../client/queries'
import { useMutation } from '@apollo/client'
import {
  useErrorToast,
  useSuccessToast,
  isLargerThanTablet,
  pluralize,
  displayRole,
} from '../../utils'
import { Class } from '../../types'
import { Member, Grades } from '../Icons'

export interface ClassCardProps extends Class {
  memberCount: {
    aggregate: {
      count: number
    }
  }
  role: string
  handleRefetchClasses: () => void
  currentClassNames: string[]
}

export const ClassCard = ({
  id,
  name,
  subject,
  memberCount: {
    aggregate: { count },
  },
  role,
  handleRefetchClasses,
  currentClassNames,
}: ClassCardProps): JSX.Element => {
  const {
    isOpen: showEditClass,
    onOpen: onOpenEditClass,
    onClose: onCloseEditClass,
  } = useDisclosure()
  const {
    isOpen: showDeleteClass,
    onOpen: onOpenDeleteClass,
    onClose: onCloseDeleteClass,
  } = useDisclosure()

  const [updateClass] = useMutation(UPDATE_CLASS)
  const [archiveClass] = useMutation(ARCHIVE_CLASS)

  const handleDeleteClass = async () => {
    try {
      await archiveClass({
        variables: {
          id,
        },
      })

      useSuccessToast({
        title: 'Class deleted',
        message: `${name} was successfully deleted`,
      })
      handleRefetchClasses()
      onCloseDeleteClass()
    } catch (error) {
      console.warn(error)
      useErrorToast({ message: `Class wasn't deleted` })
    }
  }

  const handleUpdateClass = async ({
    className: name,
    subject,
  }: EditClassInput) => {
    try {
      await updateClass({
        variables: {
          id,
          name,
          subject,
        },
      })

      useSuccessToast({
        title: 'Class updated',
        message: `${name} was successfully updated`,
      })
      handleRefetchClasses()
      onCloseEditClass()
    } catch (error) {
      console.warn(error)
      useErrorToast({ message: `Class wasn't updated` })
    }
  }

  return (
    <>
      <EditClass
        isOpen={showEditClass}
        onClose={onCloseEditClass}
        onSubmit={handleUpdateClass}
        name={name}
        subject={subject}
        currentClassNames={currentClassNames}
      />
      <ConfirmationModal
        isOpen={showDeleteClass}
        onClose={onCloseDeleteClass}
        title='Are you sure?'
        message={`${name} will be deleted. This process is irreversible, and all data and content will be removed and no longer accessible.`}
        onSubmit={handleDeleteClass}
      />
      <Box
        bg='white.1'
        shadow='md'
        borderRadius='8px'
        w={{ base: '300px', sm: '216px' }}
        h='216px'
        pt='1rem'
        pb='0.8rem'
        pos='relative'
        css={{
          '&:hover > .class-menu': {
            visibility: 'visible',
          },
        }}
      >
        {role !== 'student' && (
          <Flex
            pos='absolute'
            top={2}
            right={2}
            visibility={isLargerThanTablet() ? 'hidden' : 'visible'}
            className='class-menu'
          >
            <Menu placement='bottom-end' autoSelect={false} variant='hoverMenu'>
              <MenuButton
                as={IconButton}
                aria-label='Class actions'
                icon={<BsThreeDots />}
                variant='icon.blank'
                bg='transparent'
              />
              <MenuList>
                <MenuItem icon={<RiPencilFill />} onClick={onOpenEditClass}>
                  Edit
                </MenuItem>
                {['admin', 'creator'].includes(role) && (
                  <>
                    <MenuDivider />
                    <MenuItem icon={<FaTrash />} onClick={onOpenDeleteClass}>
                      Delete Class
                    </MenuItem>
                  </>
                )}
              </MenuList>
            </Menu>
          </Flex>
        )}
        <Flex direction='column' align='start' w='100%' h='100%'>
          <CustomLink href={`/classes/${id}`}>
            <Tooltip label={name} aria-label={name} placement='top-start'>
              <Text
                textStyle='h4'
                color='primary.1'
                letterSpacing='0px'
                lineHeight='27px'
                w='15ch'
                isTruncated
              >
                {name}
              </Text>
            </Tooltip>
            {subject && subject?.length >= 75 ? (
              <Tooltip
                label={subject}
                aria-label={subject}
                placement='top-start'
              >
                <Text
                  textStyle='caption'
                  color='primary.2'
                  lineHeight='20px'
                  fontSize='16px'
                  letterSpacing='0em'
                  wordBreak='break-word'
                  // h='72px'
                  // overflowX='hidden'
                  isTruncated
                  noOfLines={3}
                >
                  {subject}
                </Text>
              </Tooltip>
            ) : (
              <Text
                textStyle='caption'
                color='primary.2'
                lineHeight='20px'
                fontSize='16px'
                letterSpacing='0em'
                wordBreak='break-word'
                // h='72px'
                // overflowX='hidden'
                isTruncated
                noOfLines={3}
              >
                {subject}
              </Text>
            )}
          </CustomLink>

          <VStack
            divider={
              <StackDivider
                borderColor='#E4E4E4'
                margin='0.5rem 0px !important'
              />
            }
            justify='flex-end'
            w='100%'
            align='start'
            h='100%'
          >
            <HStack spacing={2} color='black.1' px={4} pb='0.25rem'>
              <Member width='20' height='20' viewBox='8 8 24 24' />
              <Text fontSize='16px' lineHeight='24px' letterSpacing='0em'>
                {count} {pluralize('member', count)}
              </Text>
            </HStack>
            <Flex justify='space-between' px={4} w='100%' pt='0.4rem'>
              <Text
                color='black.1'
                textStyle='body.1'
                textTransform='capitalize'
                fontSize='16px'
                lineHeight='24px'
                letterSpacing='0em'
              >
                {displayRole(role)}
              </Text>
              <HStack color='primary.1' spacing={4}>
                {isLargerThanTablet() && (
                  <Tooltip
                    label='View my grades'
                    variant='iconToolTip'
                    placement='bottom-start'
                  >
                    <Link to={`/classes/${id}/grades`}>
                      <Grades width='20' height='20' viewBox='8 8 24 24' />
                    </Link>
                  </Tooltip>
                )}
              </HStack>
            </Flex>
          </VStack>
        </Flex>
      </Box>
    </>
  )
}

const CustomLink = styled.a(() => ({
  padding: '0 16px',
}))

export default ClassCard
