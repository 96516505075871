import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  HStack,
  Button,
  ModalBody,
  Box,
  Text,
  Flex,
  Checkbox,
  CheckboxGroup,
} from '@chakra-ui/react'
import { useState } from 'react'
import { ClassUser } from '../../types'
import { getFullName, getFirstAndLastInitial, displayRole } from '../../utils'
import { AlamAvatar } from '../Avatar'
import { CustomListBox } from '../Styled'

type AddSessionMembersProps = {
  isOpen: boolean
  onClose: () => void
  handleAddMember: (userIds: string[]) => void
  isSubmitting: boolean
  classUsers: ClassUser[]
}

export const AddSessionMembers = ({
  isOpen,
  onClose,
  handleAddMember,
  isSubmitting,
  classUsers,
}: AddSessionMembersProps): JSX.Element => {
  const [userIds, setUserIds] = useState<string[]>([])

  const handleCheckboxChange = (newUserIds: string[]) => {
    setUserIds(newUserIds)
  }

  const handleSubmit = async () => {
    try {
      await handleAddMember(userIds)
      setUserIds([])
    } catch (error) {
      console.warn(error)
    }
  }

  const handleClose = () => {
    setUserIds([])
    onClose()
  }

  return (
    <Modal isCentered isOpen={isOpen} onClose={handleClose} size='sm'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Session Members</ModalHeader>

        <ModalBody>
          <Box
            width='100%'
            borderRadius='12px'
            border='0.5px solid'
            borderColor='rgba(23, 5, 61, 0.32)'
            p='16px'
            bg='#EDECF0'
          >
            <CustomListBox>
              <CheckboxGroup onChange={handleCheckboxChange} value={userIds}>
                {classUsers.length >= 1 ? (
                  classUsers.map(({ role, user }) => {
                    const name = getFullName(user)

                    return (
                      <Box key={`user-${user?.id}`}>
                        <Checkbox size='md' value={user?.id}>
                          <Flex align='center'>
                            <AlamAvatar
                              mr='8px'
                              size='md'
                              name={getFirstAndLastInitial(user)}
                              src={user?.avatar}
                            />
                            <Box>
                              <Text textStyle='body.emphasized'>{name}</Text>
                              <Text
                                fontSize='xs'
                                color='#9B9B9C'
                                textTransform='capitalize'
                              >
                                {displayRole(role)}
                              </Text>
                            </Box>
                          </Flex>
                        </Checkbox>
                      </Box>
                    )
                  })
                ) : (
                  <Flex h='100px' align='center' justify='center'>
                    <Text textStyle='body.big' color='primary.1'>
                      No users remaining
                    </Text>
                  </Flex>
                )}
              </CheckboxGroup>
            </CustomListBox>
          </Box>
        </ModalBody>

        <ModalFooter>
          <HStack spacing={4}>
            <Button
              variant='base.white'
              onClick={handleClose}
              textStyle='button'
            >
              {`I'll Do It Later`}
            </Button>
            <Button
              variant='base.primary'
              type='submit'
              textStyle='button'
              onClick={handleSubmit}
              loadingText='Adding'
              isLoading={isSubmitting}
              isDisabled={userIds.length < 1}
            >
              Add
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default AddSessionMembers
