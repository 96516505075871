import keyBy from 'lodash-es/keyBy'
import transform from 'lodash-es/transform'
import isObject from 'lodash-es/isObject'

export type Obj = Record<string, any>

export function deepOmit(input: Obj, excludes: Array<number | string>) {
  return Object.entries(input).reduce((nextInput, [key, value]) => {
    const shouldExclude = excludes.includes(key)
    if (shouldExclude) return nextInput

    if (Array.isArray(value)) {
      const arrValue = value
      const nextValue = arrValue.map((arrItem) => {
        if (typeof arrItem === 'object') {
          return deepOmit(arrItem, excludes)
        }
        return arrItem
      })
      // @ts-ignore
      if (nextInput[key]) {
        // @ts-ignore
        nextInput[key]
      }
      // @ts-ignore
      nextInput[key] = nextValue
      return nextInput
    } else if (typeof value === 'object') {
      // @ts-ignore
      nextInput[key] = deepOmit(value, excludes)
      return nextInput
    }

    // @ts-ignore
    nextInput[key] = value

    return nextInput
  }, {})
}
