import { Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { FormsType, FormType, ModalState } from './AssignmentModal'

type SideNav = {
  selectedForm: string
  forms: FormsType
}

export const SideNav = ({ selectedForm, forms }: SideNav) => {
  return (
    <Flex
      minW={{ base: '100%', md: '204px' }}
      maxW={{ base: '100%', md: '204px' }}
      direction={{ base: 'row', md: 'column' }}
      bg='assignmentsidenav'
      borderBottomLeftRadius={{ base: '0px', md: '12px' }}
      minH={{ base: '56px', md: '705px' }}
      justify={{ base: 'space-around', md: 'start' }}
      align={{ base: 'center', md: 'start' }}
    >
      <Flex
        pl={{ base: '0px', md: '24px' }}
        pt={{ base: '0px', md: '42px' }}
        mb={{ base: '0px', md: '8px' }}
      >
        <SideNavItem
          form={forms.assignmentReview}
          selectedForm={selectedForm}
        />
      </Flex>

      <Flex
        pl={{ base: '0px', md: '24px' }}
        pt={{ base: '0px', md: '16px' }}
        mb={{ base: '0px', md: '4px' }}
      >
        <SideNavItem form={forms.learningReview} selectedForm={selectedForm} />
      </Flex>
    </Flex>
  )
}

export const SideNavItem = ({
  form: { display, value, showInSideNav },
  selectedForm,
}: {
  form: FormType
  selectedForm: string
}) => {
  if (!showInSideNav) {
    return null
  }

  return selectedForm === value ? (
    <Flex cursor='default'>
      <Text
        textStyle='h5'
        mt='3px'
        mb='4px'
        borderBottom='1px solid'
        borderBottomColor='primary.1'
        lineHeight='24px'
      >
        {display}
      </Text>
    </Flex>
  ) : (
    <Flex cursor='default'>
      <Text my='4px' textStyle='body.big' color='primary.2'>
        {display}
      </Text>
    </Flex>
  )
}

export const OneItemSideNav = ({ selectedForm, forms }: SideNav) => {
  return (
    <Flex
      minW={{ base: '100%', md: '204px' }}
      maxW={{ base: '100%', md: '204px' }}
      direction={{ base: 'row', md: 'column' }}
      bg='assignmentsidenav'
      borderBottomLeftRadius={{ base: '0px', md: '12px' }}
      minH={{ base: '56px', md: '705px' }}
      justify={{ base: 'space-around', md: 'start' }}
      align={{ base: 'center', md: 'start' }}
    >
      <Flex
        pl={{ base: '0px', md: '24px' }}
        pt={{ base: '0px', md: '42px' }}
        mb={{ base: '0px', md: '8px' }}
      >
        <SideNavItem form={forms[selectedForm]} selectedForm={selectedForm} />
      </Flex>
    </Flex>
  )
}
