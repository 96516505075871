import { Flex, Text } from '@chakra-ui/react'
import { Message as MessageType } from '../../types'
import {
  getFullName,
  getFirstAndLastInitial,
  toUTC,
  formatTimeLog,
} from '../../utils'
import { AlamAvatar } from '../Avatar'
import { formatDistance } from 'date-fns'

export type MessageProps = {
  message: MessageType
}

export const Message = ({
  message: { sender, inserted_at, text },
}: MessageProps): JSX.Element => {
  const name = getFullName(sender)

  return (
    <Flex minH='64px' h='auto' p='16px 8px 8px 16px'>
      <AlamAvatar
        size='md'
        src={sender.avatar}
        name={getFirstAndLastInitial(sender)}
      />

      <Flex direction='column' ml='8px'>
        <Text
          color='primary.2'
          textStyle='overline'
          fontWeight='600'
          letterSpacing='0.1rem'
          maxW='191px'
          pb='4px'
          isTruncated
        >
          {formatTimeLog(new Date(inserted_at))}
        </Text>

        <Text
          textStyle='zeroLetterSpacing'
          lineHeight='21px'
          color='black.1'
          maxW='191px'
        >
          {text}
        </Text>
      </Flex>
    </Flex>
  )
}

export default Message
