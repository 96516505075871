import { Flex, Text } from '@chakra-ui/react'
import { Message as MessageType } from '../../types'
import {
  getFullName,
  getFirstAndLastInitial,
  formatTimeLogSession,
} from '../../utils'
import { AlamAvatar } from '../Avatar'

export type MessageProps = {
  message: MessageType
}

export const Message = ({
  message: { sender, inserted_at, text },
}: MessageProps): JSX.Element => {
  const name = getFullName(sender)

  return (
    <Flex h='fit-content' w='100%' p='8px 16px'>
      <AlamAvatar
        size='md'
        src={sender.avatar}
        name={getFirstAndLastInitial(sender)}
      />

      <Flex direction='column' ml='16px'>
        <Flex align='center'>
          <Text
            color='black.4'
            textStyle='subtitle'
            fontWeight='600'
            textTransform='capitalize'
            mr={2}
          >
            {name}
          </Text>
          <Text
            style={{ marginRight: '8px' }}
            color='black.4'
            textStyle='overline'
            fontWeight='500'
          >
            {formatTimeLogSession(new Date(inserted_at))}
          </Text>
        </Flex>

        <Text
          textStyle='caption'
          color='black.1'
          h='fit-content'
          fontSize='14px'
          letterSpacing='0'
          lineHeight='22px'
        >
          {text}
        </Text>
      </Flex>
    </Flex>
  )
}

export default Message
