import React from 'react'
import { Icon } from '@chakra-ui/react'

export const VisualIcon = (props: Record<string, any>): JSX.Element => {
  return (
    <Icon
      width='5'
      height='5'
      viewBox='8 8 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M20 16.5C19.2044 16.5 18.4413 16.8161 17.8787 17.3787C17.3161 17.9413 17 18.7044 17 19.5C17 20.2956 17.3161 21.0587 17.8787 21.6213C18.4413 22.1839 19.2044 22.5 20 22.5C20.7956 22.5 21.5587 22.1839 22.1213 21.6213C22.6839 21.0587 23 20.2956 23 19.5C23 18.7044 22.6839 17.9413 22.1213 17.3787C21.5587 16.8161 20.7956 16.5 20 16.5ZM20 24.5C18.6739 24.5 17.4021 23.9732 16.4645 23.0355C15.5268 22.0979 15 20.8261 15 19.5C15 18.1739 15.5268 16.9021 16.4645 15.9645C17.4021 15.0268 18.6739 14.5 20 14.5C21.3261 14.5 22.5979 15.0268 23.5355 15.9645C24.4732 16.9021 25 18.1739 25 19.5C25 20.8261 24.4732 22.0979 23.5355 23.0355C22.5979 23.9732 21.3261 24.5 20 24.5V24.5ZM20 12C15 12 10.73 15.11 9 19.5C10.73 23.89 15 27 20 27C25 27 29.27 23.89 31 19.5C29.27 15.11 25 12 20 12Z'
        fill='currentColor'
      />
    </Icon>
  )
}

export default VisualIcon
