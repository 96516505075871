import { gql } from '@apollo/client'
import { userDisplayFields } from './index'

export const analyticsQuestionFields = gql`
  ${userDisplayFields}
  fragment analyticsQuestionFields on questions {
    id
    text
    type
    response_choices_arrangements(order_by: { order: asc }) {
      id
      order
      response_choice {
        id
        text
      }
    }
    responses {
      id
      exam_response {
        id
        user {
          ...userDisplayFields
          group_users(
            where: {
              group: { class_id: { _eq: $class_id } }
              archived_at: { _is_null: true }
            }
          ) {
            group {
              id
              name
            }
          }
        }
        group_user {
          id
          user {
            ...userDisplayFields
          }
          group {
            id
            name
          }
        }
      }
      answers(where: { text: { _neq: "" } }) {
        id
        text
        inserted_at
      }
    }
  }
`
