import { useHistory } from 'react-router'
import { Container, Box, Flex, Text } from '@chakra-ui/react'
import { Question } from '../../types'
import { NotEnoughData } from './NotEnoughData'
import { TextQuestionGraph } from './TextQuestionGraph'
import { MultipleChoiceGraph } from './MultipleChoiceGraph'
import { CheckboxGraph } from './CheckboxGraph'

export interface QuestionCardProps {
  order: number
  question: Question
  questionUrl: string
}

export interface QuestionGraphProps {
  question: Question
}

export const QuestionCard = ({
  order,
  question,
  questionUrl,
}: QuestionCardProps): JSX.Element => {
  const history = useHistory()

  const renderQuestionGraph = (question: Question) => {
    switch (question.type) {
      case 'text':
        return <TextQuestionGraph question={question} />

      case 'multiple_choice':
        return <MultipleChoiceGraph question={question} />

      case 'checkbox':
        return <CheckboxGraph question={question} />

      default:
        return <NotEnoughData />
    }
  }

  return (
    <Container
      maxW={{ base: '100%', md: '456px', lg: '500px', xl: '1080px' }}
      px={0}
      mb={6}
    >
      <Box
        bg='white.1'
        borderRadius='12px'
        borderWidth='0.5px'
        borderStyle='solid'
        borderColor='primary.2'
      >
        <Flex
          align='center'
          justify='space-between'
          borderBottomWidth='1px'
          borderStyle='solid'
          borderColor='primary.2'
          py={7}
          px={8}
        >
          <Text textStyle='h5' pr='16px'>
            Q{order + 1}: {question?.text}
          </Text>
          <Text
            textStyle='body.big'
            cursor='pointer'
            onClick={() => history.push(questionUrl)}
            color='primary.1'
          >
            View Report
          </Text>
        </Flex>
        {renderQuestionGraph(question)}
      </Box>
    </Container>
  )
}
