import { createBreakpoints } from '@chakra-ui/theme-tools'

const breakpoints = createBreakpoints({
  base: '0px',
  xs: '320px',
  sm: '544px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  mobile: '650px',
})

export default breakpoints
