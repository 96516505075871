import React from 'react'
import { Layout } from '../../components/Layout'
import {
  Box,
  Flex,
  Heading,
  SimpleGrid,
  useDisclosure,
  Icon,
  useMediaQuery,
} from '@chakra-ui/react'
import { MdAddCircle } from 'react-icons/md'
import { NewCard, GroupCard } from '../../components/Card'
import { GroupModal } from '../../components/Modal'
import {
  GET_CLASS_GROUPS,
  GET_USER_GROUPS,
  GetClassGroupType,
} from '../../client/queries'
import { useQuery } from '@apollo/client'
import { useAlamContext } from '../../client'
import { RenderForUser } from '../../components/SharedComponents'

export type GroupsPageProps = {
  id: string
}

const GroupsPage = ({ id }: GroupsPageProps): JSX.Element => {
  const [isLargerThanMobile] = useMediaQuery('(min-width: 544px)')
  const topNavLeft = [{ type: 'text' as const, text: 'All Groups' }]
  const alamContext = useAlamContext()
  const currentUser = alamContext.user
  const classUser = alamContext.classUser

  const classVariables = {
    variables: { class_id: id },
  }

  const userVariables = {
    variables: { class_id: id, user_id: currentUser?.id },
  }

  const { data, refetch } = useQuery(
    ['admin', 'teacher', 'creator'].includes(classUser?.role)
      ? GET_CLASS_GROUPS
      : GET_USER_GROUPS,
    ['admin', 'teacher', 'creator'].includes(classUser?.role)
      ? classVariables
      : userVariables
  )

  const { groups } = data || { groups: [] }

  const {
    isOpen: isOpenCreateGroup,
    onOpen: onOpenCreateGroup,
    onClose: onCloseCreateGroup,
  } = useDisclosure()

  return (
    <Layout topNavLeft={topNavLeft} showSideNav>
      <GroupModal
        isOpen={isOpenCreateGroup}
        onClose={onCloseCreateGroup}
        refetch={refetch}
        size='md'
      />
      <Flex
        justify='center'
        direction='column'
        px={{ md: 2, xl: '10%' }}
        pt={groups.length >= 4 ? 0 : '121px'}
      >
        <Flex
          margin='auto'
          direction='column'
          justify={groups.length >= 4 ? 'center' : 'flex-start'}
          minH='containerHeight'
          maxW='960px'
          width={{ base: '100%', md: 'auto' }}
          padding={{ base: '24px', md: '0' }}
        >
          <Box mx={{ base: 6, md: 0 }}>
            <Flex justify='space-between' align='center'>
              <Box w='100%'>
                <Heading
                  textStyle='h2'
                  color='primary.1'
                  pb={8}
                  pt={{ base: 5, lg: '0' }}
                  textAlign='left'
                >
                  {groups.length >= 1
                    ? `Groups (${groups.length})`
                    : 'There are no groups yet.'}
                </Heading>
              </Box>

              {!isLargerThanMobile && (
                <RenderForUser roles={['admin', 'teacher', 'creator']}>
                  <Box
                    bg='primary.1'
                    h='100%'
                    p={3}
                    borderRadius='8px'
                    position='relative'
                    onClick={onOpenCreateGroup}
                  >
                    <Icon color='white.1' as={MdAddCircle} fontSize='2xl' />
                  </Box>
                </RenderForUser>
              )}
            </Flex>
          </Box>

          <SimpleGrid
            spacingX={4}
            spacingY={6}
            columns={{ base: 1, sm: 2, md: 3, lg: 4, xl: 4 }}
            w='100%'
            px={{ base: '24px', sm: '0' }}
          >
            <RenderForUser roles={['admin', 'teacher', 'creator']}>
              <Flex justify='center'>
                <NewCard title='Create Group' onClick={onOpenCreateGroup} />
              </Flex>
            </RenderForUser>
            {groups?.map((group: GetClassGroupType) => {
              return (
                <Flex justify='center' key={group.id}>
                  <GroupCard
                    {...group}
                    classId={id}
                    handleRefetchGroups={refetch}
                    role={classUser?.role}
                  />
                </Flex>
              )
            })}
          </SimpleGrid>
        </Flex>
      </Flex>
    </Layout>
  )
}

export default GroupsPage
