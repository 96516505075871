import React, { useMemo } from 'react'
import {
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuDivider,
  MenuItem,
  Checkbox,
  Button,
  Box,
} from '@chakra-ui/react'
import { Topic } from '../../types'
import { IoChevronDown } from 'react-icons/io5'
import TopicFilterItem from './TopicFilterItem'

export interface TopicFilterProps {
  topicFilter: boolean[]
  setTopicFilter: (data: boolean[]) => void
  topics?: Topic[]
  handleTopicFilterOnChange: (checked: boolean, index: number) => void
  isTopicCount?: boolean
  buttonDisplayProps?: Record<string, any>
  indeterminateTitle: string
}

export const TopicFilter = ({
  topicFilter,
  setTopicFilter,
  topics,
  handleTopicFilterOnChange,
  isTopicCount,
  buttonDisplayProps,
  indeterminateTitle,
}: TopicFilterProps) => {
  const isEverythingChecked = useMemo(() => {
    return topicFilter.every((isChecked) => isChecked)
  }, [topicFilter, topics])

  const selectedTopics = useMemo(() => {
    return topicFilter
      .map((isChecked, index) => {
        if (isChecked && topics && topics.length > 0) {
          return topics[index]
        } else {
          return false
        }
      })
      .filter((topic) => !!topic)
  }, [topicFilter, topics])

  const buttonDisplay = useMemo(() => {
    const selectedLength = selectedTopics.length
    if (isEverythingChecked) return 'All topics'
    if (selectedLength === 0) return 'None'
    if (selectedLength === 1)
      return selectedTopics[0] ? selectedTopics[0].name : ''
    // if (selectedLength > 1) {
    //   // return `${selectedTopics[0].name}, ...`
    //   return selectedTopics.map((topic) => (topic ? topic.name : '')).toString()
    // }

    return indeterminateTitle
  }, [topicFilter, topics])

  const showCount =
    selectedTopics?.length > 1 &&
    isTopicCount &&
    selectedTopics.length !== topics?.length

  return (
    <Menu closeOnSelect={false} placement='bottom-end'>
      <MenuButton
        as={Button}
        rightIcon={<IoChevronDown />}
        textStyle='button'
        variant='tab.selector'
        py='8px'
        px='1rem'
        w={{ base: '100%', md: '148px' }}
        layerStyle='card.board-updated-shadow'
        letterSpacing='0.06rem'
      >
        <Text
          isTruncated
          maxWidth={{ base: '250px', md: '100px', lg: '100px' }}
          align='left'
          {...buttonDisplayProps}
        >
          {buttonDisplay}
        </Text>

        {showCount && (
          <Box
            fontSize='14px'
            fontWeight='600'
            w='24px'
            h='24px'
            borderRadius='50%'
            position='absolute'
            top='50%'
            bottom='50%'
            transform='translate(-50%, -50%)'
            right='20px'
            display='flex'
            alignItems='center'
            justifyContent='center'
            color='#fff'
            bg='primary.1'
          >
            {selectedTopics?.length}
          </Box>
        )}
      </MenuButton>

      <MenuList>
        <MenuItem layerStyle='menu.item.checkbox'>
          <Checkbox
            isChecked={topicFilter.every(Boolean)}
            onChange={(e) =>
              setTopicFilter(Array(topicFilter.length).fill(e.target.checked))
            }
          >
            All topics
          </Checkbox>
        </MenuItem>

        {topics?.map((topic: Topic, index: number) => {
          const isTruncated = topic.name.length > 24

          return (
            <React.Fragment key={index}>
              <MenuDivider borderColor='grey.border' />
              <TopicFilterItem
                name={topic.name}
                isTruncated={isTruncated}
                isChecked={!!topicFilter[index]}
                handleChange={(e) =>
                  handleTopicFilterOnChange(e.target.checked, index)
                }
              />
            </React.Fragment>
          )
        })}
      </MenuList>
    </Menu>
  )
}

export default TopicFilter
