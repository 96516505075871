import React from 'react'
import { IconAlamProps } from './Type'

export const Create = ({
  fill,
  width,
  height,
  viewBox,
}: IconAlamProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M28.71 15.0425C29.1 14.6525 29.1 14.0025 28.71 13.6325L26.37 11.2925C26 10.9025 25.35 10.9025 24.96 11.2925L23.12 13.1225L26.87 16.8725L28.71 15.0425ZM11 25.2525V29.0025H14.75L25.81 17.9325L22.06 14.1825L11 25.2525Z'
      fill={fill}
    />
  </svg>
)

Create.defaultProps = {
  fill: '#17053D',
  width: '40',
  height: '40',
  viewBox: '0 0 40 40',
}
