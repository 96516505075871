import { gql } from '@apollo/client'

export const gradesSubmissionFields = gql`
  fragment gradesSubmissionFields on submissions {
    id
    status
    grades(order_by: [{ inserted_at: desc }]) {
      id
      grade
      grader_id
    }
    eventLogs: event_logs(order_by: [{ inserted_at: desc }]) {
      id
      content
      inserted_at
      updated_at
    }
    assignment {
      id
      name
      grading
      updated_at
      due_date
    }
    user_id
    group_id
    group {
      id
      name
      groupUsers: group_users(
        where: {
          user: {
            class_users: {
              class_id: { _eq: $class_id }
              role: { _eq: "student" }
            }
          }
          archived_at: { _is_null: true }
        }
      ) {
        id
      }
    }
  }
`

export const analyticsSubmissionFields = gql`
  fragment analyticsSubmissionFields on submissions {
    id
    status
    inserted_at
    assignment_id
    grades(order_by: { inserted_at: desc }) {
      id
      grade
    }
    event_logs(order_by: { inserted_at: desc }) {
      id
      content
      inserted_at
    }
  }
`
