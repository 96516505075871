import { Box, Text, Container } from '@chakra-ui/react'
import { Question } from '../../types'
import { TextQuestionContent } from './TextQuestionContent'
import { CheckboxPageContent } from './CheckboxPageContent'
import { MultipleChoicePageContent } from './MultipleChoicePageContent'
import { NotEnoughData } from './NotEnoughData'

export interface QuestionPageContentProps {
  question: Question
}

export const QuestionPageContent = ({
  question,
}: QuestionPageContentProps): JSX.Element => {
  switch (question.type) {
    case 'text':
      return <TextQuestionContent question={question} />

    case 'multiple_choice':
      return <MultipleChoicePageContent question={question} />

    case 'checkbox':
      return <CheckboxPageContent question={question} />

    default:
      return (
        <Container
          maxW={{ base: '100%', md: '456px', lg: '500px', xl: '1080px' }}
          px={0}
          mb={6}
          bg='#FFFFFF'
        >
          <NotEnoughData />
        </Container>
      )
  }
}
