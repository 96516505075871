import React from 'react'
import { Flex, Image } from '@chakra-ui/react'
import logo from '../../assets/images/logo.png'

export const Logo = ({ ...props }): JSX.Element => {
  return (
    <Image
      src={logo}
      alt='Alam-edu logo'
      htmlHeight='33.88px'
      htmlWidth='122.98px'
      {...props}
    />
  )
}

export default Logo
