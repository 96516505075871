import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  Text,
  useClipboard,
  useInterval,
} from '@chakra-ui/react'
import { formatDistance } from 'date-fns'
import { useEffect, useState } from 'react'
import { FiLink2 } from 'react-icons/fi'
import { useParams } from 'react-router-dom'
import { Layout } from '../../components/Layout'
import { TopNavItemProps } from '../../components/TopNav'
import { SessionDocument, Session } from '../../types'
import { getFullName, useSuccessToast } from '../../utils'
import { getFileDetails, useGoogleAPI } from '../GoogleAPI'
import { Loader } from '../SharedComponents'
import { SessionChat } from './index'

type DocumentPage = {
  id: string
  topNavLeft: TopNavItemProps[]
  session: Session
}

export const DocumentPage = ({
  id,
  topNavLeft,
  session,
}: DocumentPage): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true)
  const { isAuthorized } = useGoogleAPI()
  const { documentId } = useParams<{ documentId: string }>()
  const [selectedDocument, setSelectedDocument] = useState<SessionDocument>()
  const [documentDetails, setDocumentDetails] = useState<{
    modifiedTime: string
  }>()
  const { onCopy } = useClipboard(selectedDocument?.url || '')

  const updateFileDetails = () => {
    async function fetchFileDetails(fileId: string) {
      const fileDetails = await getFileDetails({
        fileId,
        fields: 'modifiedTime',
      })
      setDocumentDetails(fileDetails.result)
    }

    if (!isAuthorized) {
      return
    }

    const newDocument = session.documents.find(({ id }) => id === documentId)

    if (newDocument) {
      fetchFileDetails(newDocument.file_id)
      setSelectedDocument(newDocument)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    updateFileDetails()
  }, [documentId, session.documents, isAuthorized])

  useInterval(() => {
    updateFileDetails()
  }, 10 * 1000)

  const owner = selectedDocument?.user
    ? getFullName(selectedDocument?.user)
    : ''

  const getLastEdited = () => {
    if (!documentDetails?.modifiedTime) {
      return ''
    }

    const lastEdited = formatDistance(
      new Date(documentDetails?.modifiedTime),
      new Date(),
      {
        includeSeconds: true,
        addSuffix: true,
      }
    )

    return `Last edited: ${lastEdited}`
  }

  const onGoogleClick = () => {
    //window.open(selectedDocument?.url, '_blank', 'noopener,noreferrer')
    onCopy()
    useSuccessToast({ message: `${selectedDocument?.name} Link Copied!` })
  }

  return (
    <Layout showSideNav topNavLeft={topNavLeft}>
      <Loader loaders={[isLoading]}>
        <Flex h='auto'>
          <Box
            w='100%'
            py={12}
            px={{ base: '30px', md: '40px', lg: '50px', xl: '60px' }}
          >
            <Flex align='center' justify='space-between' mb={8}>
              <Box>
                <Text textStyle='h2'>{selectedDocument?.name}</Text>
                <HStack spacing={3} divider={<Box layerStyle='bulletpoint' />}>
                  <Text textStyle='subtitle' color='grey.1'>
                    Owner: {owner}
                  </Text>
                  <Text textStyle='subtitle' color='grey.1'>
                    {getLastEdited()}
                  </Text>
                </HStack>
              </Box>

              <Box>
                <Button
                  leftIcon={<FiLink2 />}
                  variant='icon.button'
                  textStyle='semibold'
                  textTransform='uppercase'
                  boxShadow='0px 4px 8px rgba(23, 5, 61, 0.12)'
                  letterSpacing='0.75px'
                  border='0.5px solid rgba(23, 5, 61, 0.32)'
                  borderRadius='12px'
                  h='48px'
                  w='auto'
                  onClick={onGoogleClick}
                >
                  Google
                </Button>
              </Box>
            </Flex>

            <Flex>
              <Container
                maxW='100%'
                px={0}
                css={{
                  iframe: {
                    width: '100%',
                    'min-height': 'calc(100vh - 269px)',
                    'max-height': 'calc(100vh - 269px)',
                  },
                }}
              >
                <iframe src={selectedDocument?.url} />
              </Container>
            </Flex>
          </Box>

          <SessionChat
            classId={id}
            sessionId={session.id}
            sessionUsers={session.session_users}
            sidenav
          />
        </Flex>
      </Loader>
    </Layout>
  )
}
