import { useState } from 'react'
import {
  Flex,
  Text,
  IconButton,
  Button,
  Textarea,
  VStack,
  StackDivider,
  Spacer,
  Switch,
  FormErrorMessage,
  FormErrorIcon,
  FormControl,
} from '@chakra-ui/react'
import { MdClose } from 'react-icons/md'
import { useForm } from 'react-hook-form'
import debounce from 'lodash-es/debounce'
import { validateSpaceOnly, useAttachment } from '../../utils'
import { Attachment } from '../../types'
import { RenderForUser } from '../SharedComponents'
import { useAlamContext } from '../../client'

export interface CreatePostInput {
  id?: string
  content: string
  files: File[]
  isPinned: boolean
  removedFiles: Attachment[]
}

export type CreatePostProps = {
  id?: string
  content?: string
  isPinned?: boolean
  onClose: () => void
  onSubmit: (data: CreatePostInput) => void
  onDelete?: (postId: string) => void
  isSubmitting: boolean
  files?: Attachment[]
  isGroup?: boolean
}

export const CreatePost = ({
  id = '',
  content = '',
  isPinned = false,
  onClose,
  onDelete: onArchivePost = () => {},
  onSubmit,
  isSubmitting,
  files = [],
  isGroup = false,
}: CreatePostProps): JSX.Element => {
  const {
    classUser: { role },
  } = useAlamContext()
  const [isSubmitClicked, setIsSubmitClicked] = useState(false)
  const {
    register,
    handleSubmit,
    errors,
    trigger,
    formState: { isValid },
  } = useForm<CreatePostInput>({
    mode: 'onChange',
    defaultValues: {
      id,
      content,
    },
  })

  const {
    removedAttachments,
    newAttachments,
    renderFileList,
    renderAttachButton,
    rejections,
    renderAttachmentError,
  } = useAttachment(files)
  const [pinned, setPinned] = useState(isPinned)

  const handleSubmitForm = (data: CreatePostInput) => {
    setIsSubmitClicked(!isSubmitClicked)
    const newData = {
      ...data,
      files: newAttachments,
      isPinned: pinned,
      removedFiles: removedAttachments,
    }
    onSubmit(newData)
  }

  const pinPermissions = isGroup
    ? ['student', 'teacher', 'admin', 'creator']
    : ['teacher', 'admin', 'creator']

  return (
    <Flex
      w='100%'
      borderRadius={{ base: '0', lg: '12px' }}
      layerStyle='card.board-updated-shadow'
      bg='white.1'
      direction='column'
    >
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <VStack
          spacing={2}
          w='100%'
          divider={<StackDivider layerStyle='grey.border' />}
        >
          <Flex w='100%' px={3} pt={3} pb={1} align='center'>
            <IconButton
              variant='icon.button'
              aria-label='Cancel creating post'
              fontSize='24px'
              icon={<MdClose />}
              onClick={onClose}
            />
            <Text
              letterSpacing='0'
              pl='0.25rem'
              py='0.75rem'
              textStyle='h5'
              color='primary.1'
            >
              {id ? 'Edit ' : 'Create '} Post
            </Text>
          </Flex>

          <Flex
            direction='column'
            w='100%'
            justify='space-around'
            px={6}
            pt={3}
            pb={3}
          >
            <VStack spacing={4} align='flex-start'>
              <FormControl isInvalid={!!errors.content?.message}>
                <Textarea
                  placeholder='Type message here'
                  resize='vertical'
                  padding='1rem'
                  fontSize='16px'
                  letterSpacing='0.02em'
                  border='0.5px solid'
                  borderColor='grey.border'
                  maxHeight='200px'
                  h='144px'
                  name='content'
                  ref={register({
                    required: 'Post is a required field',
                    maxLength: {
                      value: 1000,
                      message: 'Post exceeded 1000 maximum characters',
                    },
                    validate: {
                      validateSpaceOnly,
                    },
                  })}
                  onChange={debounce(async () => {
                    await trigger('content')
                  }, 500)}
                />
                {errors.content && (
                  <FormErrorMessage>
                    <FormErrorIcon />
                    {errors.content?.message}
                  </FormErrorMessage>
                )}
              </FormControl>

              <FormControl>{renderAttachButton()}</FormControl>
              {renderAttachmentError()}
            </VStack>
          </Flex>

          {renderFileList({})}

          <Flex direction='column' w='100%' px={6} pt={3} pb={6}>
            <RenderForUser roles={pinPermissions}>
              <Flex w='100%' align='center'>
                <Text letterSpacing='0' textStyle='body.big'>
                  Pin Post to Bulletin Board
                </Text>
                <Spacer />
                <Switch
                  name='isPinned'
                  isChecked={pinned}
                  onChange={(e) => setPinned(e.target.checked)}
                />
              </Flex>
            </RenderForUser>
            <Flex
              w='100%'
              justify='flex-end'
              pt={!pinPermissions.includes(role) ? '0px' : 7}
            >
              {id && (
                <Button
                  variant='base.white'
                  type='button'
                  textStyle='button'
                  minW='72px'
                  onClick={() => onArchivePost(id)}
                >
                  Delete
                </Button>
              )}
              <Spacer />
              <Button
                variant='base.primary'
                type='submit'
                textStyle='button'
                minW='72px'
                disabled={isSubmitClicked || !isValid}
                isLoading={isSubmitting}
                loadingText='Posting'
              >
                Post
              </Button>
            </Flex>
          </Flex>
        </VStack>
      </form>
    </Flex>
  )
}

export default CreatePost
