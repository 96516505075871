import React, { ElementType } from 'react'
import {
  Flex,
  HStack,
  Icon,
  Text,
  VStack,
  Button,
  Tooltip,
} from '@chakra-ui/react'
import { NavLink, useParams, useLocation } from 'react-router-dom'
import { matchPath } from 'react-router'
import classnames from 'classnames'

export interface SideNavCellProps {
  url: string
  icon: ElementType
  text: string
  isSubItem?: boolean
  children?: React.ReactNode
  showSubItems?: boolean
  isLink?: boolean
  onClick?: () => void
  exact?: boolean
  paths?: string[]
  groupId?: string
  display?: string
  sessionId?: string
  isCreateNewSession?: boolean
  isZeroLetterSpacing?: boolean
}

export const SideNavCell = ({
  url,
  icon,
  text,
  isSubItem = false,
  showSubItems,
  children,
  isLink = true,
  onClick = () => {},
  exact = false,
  paths,
  groupId,
  display,
  sessionId,
  isCreateNewSession,
  isZeroLetterSpacing,
}: SideNavCellProps): JSX.Element => {
  const params = useParams<{ group_id: string; sessionId?: string }>()
  const location = useLocation()

  const isActive = (_match: any, location: any) => {
    const pathName = location.pathname

    const isMatched = paths?.some((path: string) => {
      if (
        matchPath(pathName, {
          path,
          exact: true,
        })
      ) {
        return true
      }
    })

    if (groupId && groupId === params.group_id && isMatched) {
      return true
    }

    if (sessionId && sessionId === params.sessionId && isMatched) {
      return true
    }

    if (!groupId && !sessionId && isMatched) {
      return true
    }

    return false
  }

  return (
    <Flex direction='column' w='100%'>
      {isLink ? (
        <Flex
          align='center'
          as={NavLink}
          to={url}
          activeStyle={{
            color: '#17053D',
            backgroundColor: '#ffffff',
          }}
          exact={exact}
          layerStyle='sidenav.inactive'
          direction='row'
          justify='start'
          pl={classnames({
            '12': isSubItem,
            '7': !isSubItem,
          })}
          py={2}
          isActive={isActive}
          display={display}
        >
          <HStack spacing={2} align='center'>
            <Icon
              {...(isSubItem && {
                color: isActive(null, location) ? '#17053D' : 'white.2',
              })}
              as={icon}
              w='1.2rem'
              h='1.2rem'
            />
            <Tooltip label={text} aria-label={text} placement='top-start'>
              <Text
                w='24ch'
                isTruncated
                {...(isSubItem && {
                  color: isActive(null, location) ? '#17053D' : 'white.2',
                })}
                textStyle={
                  isSubItem && !isCreateNewSession
                    ? 'body.2'
                    : isSubItem && isCreateNewSession
                    ? 'body.createNewSession'
                    : !isSubItem && isZeroLetterSpacing
                    ? 'zeroLetterSpacing'
                    : 'subtitle'
                }
                letterSpacing='0'
              >
                {text}
              </Text>
            </Tooltip>
          </HStack>
        </Flex>
      ) : (
        <Flex
          variant='blank'
          align='center'
          as={Button}
          onClick={onClick}
          layerStyle='sidenav.inactive'
          direction='row'
          justify='start'
          pl={classnames({
            '9': isSubItem,
            '7': !isSubItem,
          })}
          py={2}
        >
          <HStack spacing={2} align='center'>
            <Icon as={icon} w='1.2rem' h='1.2rem' />
            <Text
              textStyle={
                isSubItem
                  ? 'body.2'
                  : !isSubItem && isZeroLetterSpacing
                  ? 'zeroLetterSpacing'
                  : 'subtitle'
              }
            >
              {text}
            </Text>
          </HStack>
        </Flex>
      )}

      <VStack w='100%'>{showSubItems && children}</VStack>
    </Flex>
  )
}

export default SideNavCell
