import { useState, useEffect, useMemo } from 'react'
import { Grade, GroupUserForSubmission } from '../../types'
import { AssignmentType } from '../../pages/Assignment/AssignmentPage'
import {
  VStack,
  StackDivider,
  Flex,
  Text,
  Button,
  HStack,
} from '@chakra-ui/react'
import { AlphabeticalGradeInput, NumericalGradeInput } from '../Input'
import { submissionStatusType } from '../../pages/Assignment/submissionReducer'
import { Submission } from '../../types'
import {
  handleUpdateSubmissionProps,
  handleUpdateSubmissionGradeProps,
} from '../../pages/Assignment/Submission'
import { useSuccessToast } from '../../utils'
import { SubmissionHistory } from './SubmissionHistory'
import { FileList } from '../File'
import { useSubmissionContext } from '../../pages/Assignment/SubmissionContext'
import { useAlamContext } from '../../client'
import { useMutation } from '@apollo/client'
import { CREATE_EXAM_RESPONSE } from '../../client/queries'
import { v4 } from 'uuid'

export type TeacherSubmissionBoxProps = {
  assignment?: AssignmentType
  submissionStatus: submissionStatusType
  submission?: Submission
  handleUpdateSubmission: handleUpdateSubmissionProps
  submissionIsUpdating: boolean
  handleUpdateSubmissionGrade: handleUpdateSubmissionGradeProps
  submissionGradeIsUpdating: boolean
}

export const TeacherSubmissionBox = ({
  assignment,
  submissionStatus,
  submission,
  handleUpdateSubmission,
  submissionIsUpdating,
  handleUpdateSubmissionGrade,
  submissionGradeIsUpdating,
}: TeacherSubmissionBoxProps): JSX.Element => {
  const [grade, setGrade] = useState(submission?.grades[0]?.grade)
  const { groupId } = useSubmissionContext()
  const { groups, user } = useAlamContext()

  const [createExamResponse] = useMutation(CREATE_EXAM_RESPONSE)

  const learningReview = assignment?.examsForStudents?.find(
    (exam) => exam.type === 'learning_review'
  )
  useEffect(() => {
    setGrade(submission?.grades[0]?.grade || '')
  }, [submission?.grades[0]?.grade])

  const examResponseForSubmission = useMemo(() => {
    if (!groupId) {
      return learningReview?.exam_responses?.find(
        ({ user_id }) => user_id === submission?.user?.id
      )
    } else {
      return learningReview?.exam_responses?.find((exam_response) => {
        return exam_response?.group_user?.group_id === submission?.group?.id
      })
    }
  }, [submission, learningReview, groupId])

  const handleChangeGrade = (value: string) => setGrade(value)

  const handleGrade = () => {
    handleUpdateSubmissionGrade({ status: 'graded', grade }, () => {
      useSuccessToast({ title: 'Assignment was graded' })
    })
  }

  const handleReturn = async () => {
    handleUpdateSubmission(
      { status: 'returned', gradeId: submission?.grades[0]?.id },
      (response: any) => {
        createLearningReview(response.data.update_submissions_by_pk.grades)
        useSuccessToast({ title: 'Assignment was returned' })
      }
    )
  }

  const createLearningReview = async (grades: Grade[]) => {
    if (grades.length !== 1) {
      return
    }

    if (
      !groupId &&
      submission?.user?.id &&
      learningReview?.exam_responses &&
      !examResponseForSubmission
    ) {
      await createExamResponse({
        variables: {
          params: [
            {
              id: v4(),
              exam_id: learningReview.id,
              inserted_at: 'NOW()',
              updated_at: 'NOW()',
              started_at: 'NOW()',
              user_id: submission.user.id,
            },
          ],
        },
      })
    }

    if (
      groupId &&
      learningReview?.exam_responses &&
      !examResponseForSubmission
    ) {
      const params = submission?.group?.groupUsers?.map(
        ({ id }: GroupUserForSubmission) => ({
          id: v4(),
          exam_id: learningReview.id,
          inserted_at: 'NOW()',
          updated_at: 'NOW()',
          started_at: 'NOW()',
          group_user_id: id,
        })
      )

      await createExamResponse({
        variables: {
          params,
        },
      })
    }
  }

  const currentGrade = submission?.grades[0]
  const isNotGrader = currentGrade && currentGrade.grader_id !== user?.id

  /* Grade button disabled state just depends on grade input because teachers can change the grade at any stage */
  const isGradeButtonDisabled = () => {
    // submission.grade's default val is null
    if (!submission?.grades[0]?.grade && !grade) {
      return true
    }

    if (currentGrade && currentGrade?.grader_id !== user?.id) {
      return true
    }

    if (grade === submission?.grades[0]?.grade) {
      return true
    }

    return false
  }

  const isReturnButtonDisabled = () => {
    return (
      (currentGrade && currentGrade?.grader_id !== user?.id) ||
      !submissionStatus?.can?.returned
    )
  }

  const inputGradeStyles = submissionStatus?.can?.inputGrade
    ? {}
    : { bg: 'grey.2', color: 'black.3' }

  return (
    <VStack w='100%' divider={<StackDivider layerStyle='divider.nospace' />}>
      <Flex
        justify='space-between'
        w='100%'
        py='12px'
        pr='16px'
        pl='24px'
        alignItems='center'
        h='72px'
      >
        <Text textStyle='h4'>Submission</Text>
        <HStack spacing='16px'>
          {submissionStatus?.can?.showReturn && (
            <Button
              variant='base.primary'
              textStyle='button'
              onClick={handleReturn}
              isLoading={submissionIsUpdating}
              disabled={isReturnButtonDisabled()}
            >
              Return
            </Button>
          )}

          <Button
            variant='base.white'
            textStyle='button'
            disabled={isGradeButtonDisabled()}
            onClick={handleGrade}
            isLoading={submissionGradeIsUpdating}
          >
            Grade
          </Button>
        </HStack>
      </Flex>

      {submission?.event_logs && submission.event_logs?.length >= 1 && (
        <SubmissionHistory
          eventLogs={submission?.event_logs}
          grading={assignment?.grading}
          currentUserRole='teacher'
        />
      )}

      {(submission?.attachments.length || 0) >= 1 &&
      submissionStatus?.can?.showSubmission ? (
        <FileList existingFiles={submission?.attachments || []} isReadOnly />
      ) : (
        <Flex w='100%' minH='136px' align='center' justify='center'>
          <Text textStyle='body.big' color='primary.2'>
            The student has not submitted any files.
          </Text>
        </Flex>
      )}

      <Flex
        justify='space-between'
        pl='24px'
        pr='16px'
        py='16px'
        h='72px'
        w='100%'
        align='center'
      >
        <Text textStyle='h5'>Grade</Text>

        {assignment?.rubric === 'numerical' ? (
          <NumericalGradeInput
            grading={assignment?.grading}
            value={grade}
            onChange={handleChangeGrade}
            inputProps={inputGradeStyles}
            disabled={isNotGrader}
          />
        ) : (
          <AlphabeticalGradeInput
            value={grade}
            onChange={handleChangeGrade}
            inputProps={inputGradeStyles}
            disabled={isNotGrader}
          />
        )}
      </Flex>
    </VStack>
  )
}

export default TeacherSubmissionBox
