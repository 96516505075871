import React from 'react'
import { useFormContext, RegisterOptions } from 'react-hook-form'
import {
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react'
import debounce from 'lodash-es/debounce'

export type FormInputProps = {
  name: string
  label: string
  placeholder?: string
  validationProps?: RegisterOptions
  formControlProps?: Record<string, string>
  inputProps?: Record<string, string>
  formLabelProps?: Record<string, string>
}

export const FormInput = ({
  name,
  label,
  placeholder,
  formControlProps = {},
  inputProps = {},
  validationProps,
  formLabelProps = {},
}: FormInputProps) => {
  const { register, trigger, errors } = useFormContext()

  return (
    <FormControl
      css={{
        '& .optional::after': {
          letterSpacing: '0',
        },
      }}
      isInvalid={!!errors[name]?.message}
      {...formControlProps}
    >
      <FormLabel className='optional' {...formLabelProps}>
        {label}
      </FormLabel>
      <Input
        name={name}
        placeholder={placeholder}
        ref={register(validationProps)}
        onChange={debounce(async () => {
          await trigger(name)
        }, 500)}
        fontSize='14px'
        border='.5px solid'
        borderColor='grey.border'
        {...inputProps}
      />
      {errors[name] && (
        <FormErrorMessage
          fontWeight='500'
          marginTop='0.5rem'
          letterSpacing='0.02rem'
        >
          <FormErrorIcon w='14px' h='14px' />
          {errors[name]?.message}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

export default Input
