import React, { useState, useEffect } from 'react'
import { Attachment } from '../types'
import { FileList, AttachButton } from '../components/File'
import { FormErrorMessage, FormErrorIcon, FormControl } from '@chakra-ui/react'
import { FileRejection } from 'react-dropzone'

type renderFileListProps = {
  padding?: string | number
}

export const useAttachment = (attachment: Attachment[]) => {
  // existing attachments
  const [attachments, setAttachments] = useState<Attachment[]>(attachment)
  const [removedAttachments, setRemovedAttachments] = useState<Attachment[]>([])

  // new attachments
  const [newAttachments, setNewAttachments] = useState<File[]>([])
  const [rejections, setRejections] = useState<FileRejection[]>([])

  const handleRemoveAttachment = (removedAttachment: Attachment) => {
    const newAttachments = attachments.filter((attachment) => {
      return attachment.id !== removedAttachment.id
    })

    setAttachments(newAttachments)
    setRemovedAttachments([...removedAttachments, removedAttachment])
  }

  const handleAddNewFiles = (files: File[]) =>
    setNewAttachments([...newAttachments, ...files])

  const handleRemoveNewFile = (index: number) => {
    const newFiles = newAttachments.filter((_, i) => {
      return index !== i
    })

    setNewAttachments(newFiles)
  }

  const renderFileList = ({ padding }: renderFileListProps) => {
    if (attachments.length >= 1 || newAttachments.length >= 1) {
      return (
        <FileList
          newFiles={newAttachments}
          existingFiles={attachments}
          removeNewFile={handleRemoveNewFile}
          removeExisitingFile={handleRemoveAttachment}
          padding={padding}
        />
      )
    }
  }

  const resetAttachments = () => {
    setAttachments(attachment)
    setRemovedAttachments([])
    setNewAttachments([])
    setRejections([])
  }

  const renderAttachmentError = () => {
    const hasRejections = rejections.length >= 1
    if (hasRejections) {
      return (
        <FormControl isInvalid={hasRejections}>
          {hasRejections && (
            <FormErrorMessage>
              <FormErrorIcon />
              Some files could not be uploaded as the file size exceeds 50 MB.
              The maximum upload file size is 50 MB.
            </FormErrorMessage>
          )}
        </FormControl>
      )
    }
  }

  const renderAttachButton = () => {
    return (
      <AttachButton
        addFiles={handleAddNewFiles}
        setRejections={setRejections}
      />
    )
  }

  return {
    attachments,
    removedAttachments,
    handleRemoveAttachment,

    newAttachments,
    handleAddNewFiles,
    handleRemoveNewFile,

    renderFileList,
    renderAttachButton,
    resetAttachments,

    rejections,
    renderAttachmentError,
  }
}
