import { Button } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { BiPaperclip } from 'react-icons/bi'
import { useDropzone, FileRejection } from 'react-dropzone'
import { fileSizeValidation } from '../../utils'

export type AttachButtonProps = {
  addFiles: (files: File[]) => void
  text?: string
  isSubmitting?: boolean
  buttonProps?: Record<string, any>
  setRejections?: (rejections: FileRejection[]) => void
}

export const AttachButton = ({
  addFiles,
  isSubmitting = false,
  buttonProps = {
    w: 'auto',
    leftIcon: <BiPaperclip fontSize='16px' />,
    fontSize: '14px',
  },
  text = 'Attach',
  setRejections = (rejections) => {},
}: AttachButtonProps): JSX.Element => {
  const { getInputProps, getRootProps, fileRejections } = useDropzone({
    noDragEventsBubbling: true,
    validator: fileSizeValidation,
    onDropAccepted: (files) => addFiles(files),
    onDropRejected: (rejections) => setRejections(rejections),
  })

  useEffect(() => {
    if (fileRejections.length === 0) {
      setRejections([])
    }
  }, [fileRejections])

  return (
    <React.Fragment>
      <input name='files' {...getInputProps()} />
      <Button
        {...getRootProps({
          variant: 'base.white',
          textStyle: 'button',
          component: 'span',
          ...buttonProps,
          isLoading: isSubmitting,
        })}
      >
        {text}
      </Button>
    </React.Fragment>
  )
}

export default AttachButton
