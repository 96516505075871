import React from 'react'
import { useParams } from 'react-router-dom'
import PretopicPage from './PretopicPage'

const Pretopic = (): JSX.Element => {
  const { id, pretopicId } = useParams<{
    id: string
    pretopicId: string
  }>()
  return (
    <div>
      <PretopicPage id={id} pretopicId={pretopicId} />
    </div>
  )
}

export default Pretopic
