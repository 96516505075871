import React from 'react'
import { Material as MaterialType } from '../../types'
import { Material } from './index'

export interface MaterialsProps {
  materials: MaterialType[]
  context?: string
  materialAssignmentRefetch: () => void
  groupId?: string
}

export const Materials = ({
  materials,
  context = 'classwork',
  materialAssignmentRefetch,
  groupId,
}: MaterialsProps): JSX.Element => {
  return (
    <>
      {materials?.map((material) => {
        return (
          <React.Fragment key={material.id}>
            <Material
              material={material}
              context={context}
              materialAssignmentRefetch={materialAssignmentRefetch}
              groupId={groupId}
            />
          </React.Fragment>
        )
      })}
    </>
  )
}

export default Materials
