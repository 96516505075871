import {
  Box,
  Button,
  IconButton,
  Icon,
  Flex,
  Menu,
  MenuButton,
  Text,
  HStack,
  Container,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from '@chakra-ui/react'
import { Group } from '../../components/Icons'
import { NotEnoughData } from '../../components/Analytics'
import {
  StudentAnalyticsTableHeading,
  renderGroupCellContent,
  AnalyticsGroupData,
  usePagination,
} from '../../utils'

export interface GroupAnalyticsTable {
  groups: AnalyticsGroupData[]
  headings: StudentAnalyticsTableHeading[]
}

export const GroupAnalyticsTable = ({
  groups,
  headings,
}: GroupAnalyticsTable): JSX.Element => {
  const {
    page,
    setPage,
    totalPages,
    currentPageContent,
    totalItems,
    renderAnalyticsPagination,
  } = usePagination(groups)

  const tableStyle = {
    borderWidth: '0.5px',
    borderStyle: 'solid',
    borderColor: 'primary.2',
  }

  return (
    <>
      <Table display='inline-block' bg='white.1' overflow='auto'>
        <Thead>
          <Th {...tableStyle}>
            <Text
              fontWeight='700'
              fontSize='18px'
              lineHeight='23.23px'
              letterSpacing='-0.01em'
              color='black.1'
              textTransform='none'
            >
              Students
            </Text>
          </Th>
          {headings.map((tableHeading) => {
            const { topic, text, subtext } = tableHeading
            return (
              <Th {...tableStyle}>
                <Flex direction='column' maxW='400px' minW='128px'>
                  {topic && (
                    <Text
                      maxW='400px'
                      minW='128px'
                      isTruncated
                      fontWeight='600'
                      fontSize='8px'
                      lineHeight='15px'
                      letterSpacing='0.01em'
                      color='rgba(206, 16, 50, 1)'
                      textTransform='none'
                    >
                      {topic.name}
                    </Text>
                  )}
                  <Text
                    maxW='400px'
                    minW='128px'
                    isTruncated
                    fontWeight='600'
                    fontSize='12px'
                    lineHeight='16px'
                    letterSpacing='-0.01em'
                    color='black.6'
                    textTransform='none'
                  >
                    {text}
                  </Text>
                  {subtext && (
                    <Text
                      maxW='400px'
                      minW='128px'
                      isTruncated
                      fontWeight='400'
                      fontSize='8px'
                      lineHeight='15px'
                      letterSpacing='0.01em'
                      color='black.3'
                      textTransform='none'
                    >
                      {subtext}
                    </Text>
                  )}
                </Flex>
              </Th>
            )
          })}
        </Thead>
        <Tbody>
          {currentPageContent.map((group: AnalyticsGroupData) => {
            return (
              <Tr>
                <Td {...tableStyle}>
                  <Flex align='center' w='258px'>
                    <Group />
                    <Text
                      textStyle='smallstate'
                      textTransform='uppercase'
                      letterSpacing='0.02em'
                      fontSize='14px'
                      color='primary.1'
                    >
                      {group.name}
                    </Text>
                  </Flex>
                </Td>
                {headings.map((tableHeading) => {
                  return (
                    <Td {...tableStyle}>
                      {renderGroupCellContent(group, tableHeading)}
                    </Td>
                  )
                })}
              </Tr>
            )
          })}
        </Tbody>
        {totalItems === 0 && (
          <NotEnoughData
            containerProps={{
              h: '124px',
              w: '100%',
              bg: 'white.1',
            }}
            display='No data'
          />
        )}
      </Table>
      {renderAnalyticsPagination()}
    </>
  )
}
