// @ts-nocheck
import { forwardRef } from 'react'
import DatePicker from 'react-datepicker'
import { RiCalendar2Fill } from 'react-icons/ri'
import { Icon, Input, InputGroup, InputRightElement } from '@chakra-ui/react'

export type EditableDatePickerProps = {
  value?: Date
  onChange: (date: Date) => void
  placeholderText?: string
}

export const EditableDatePicker = ({
  value,
  onChange,
  placeholderText,
}: EditableDatePickerProps): JSX.Element => {
  const CustomDateInput = forwardRef((props, ref) => {
    const { value, onChange, onClick, placeholder } = props
    return (
      <InputGroup
        onClick={onClick}
        ref={ref}
        width='100%'
        outline='none'
        display='flex'
        justifyContent='space-between'
        border='none'
        pr='0px'
        fontSize='14px'
      >
        <Input
          placeholder={placeholder}
          type='text'
          onChange={onChange}
          value={value}
          background='#EDECF0'
          textStyle='body.1'
          color='primary.1'
          letterSpacing='0'
        />
        <InputRightElement
          children={<Icon as={RiCalendar2Fill} color='primary.1' />}
        />
      </InputGroup>
    )
  })

  return (
    <DatePicker
      selected={value}
      onChange={onChange}
      showTimeInput
      dateFormat='MMM dd, yyyy'
      minDate={new Date()}
      placeholderText={placeholderText}
      customInput={<CustomDateInput />}
    />
  )
}

export default EditableDatePicker
