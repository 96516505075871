import { useContext } from 'react'
import { Box, VStack } from '@chakra-ui/react'
import { StudentSubmissionBox } from './index'
import { useAlamContext } from '../../client'
import SubmissionContext from '../../pages/Assignment/SubmissionContext'
import { useSubscription, useMutation } from '@apollo/client'
import {
  GET_USER_SUBMISSION,
  GET_GROUP_SUBMISSION,
  CREATE_SUBMISSION_COMMENT,
  CREATE_ATTACHMENT,
  DELETE_ATTACHMENT,
  GET_GROUP_STUDENT_SUBMISSION_COMMENTS,
} from '../../client/queries'
import {
  useErrorToast,
  useSuccessToast,
  isLargerThanTablet,
  useFilename,
} from '../../utils'
import { v4 as uuid } from 'uuid'
import { Attachment } from '../../types'
import { CommentsSection } from './index'

export const StudentSubmission = (): JSX.Element => {
  const { user, groupUser } = useAlamContext()
  const {
    assignmentId,
    groupId,
    assignment,
    selectedSubmission,
    submissionStatus,
    handleUpdateSubmission,
    submissionIsUpdating,
    setStatus,
    setSelectedSubmission,
  } = useContext(SubmissionContext)
  const comments = selectedSubmission?.comments || []

  if (groupId) {
    useSubscription(GET_GROUP_SUBMISSION, {
      variables: {
        assignment_id: assignmentId,
        group_id: groupId,
      },
      onSubscriptionData: ({
        subscriptionData: {
          data: { submissions },
        },
      }) => {
        if (submissions[0]) {
          setSelectedSubmission(submissions[0])
          setStatus(submissions[0].status)
        }
      },
    })
  } else {
    useSubscription(GET_USER_SUBMISSION, {
      variables: {
        assignment_id: assignmentId,
        user_id: user?.id,
      },
      onSubscriptionData: ({
        subscriptionData: {
          data: { submissions },
        },
      }) => {
        if (submissions[0]) {
          setSelectedSubmission(submissions[0])
          setStatus(submissions[0].status)
        }
      },
    })
  }

  const {
    data: { comments: groupUserComments } = { comments: [] },
  } = useSubscription(GET_GROUP_STUDENT_SUBMISSION_COMMENTS, {
    variables: {
      submission_id: selectedSubmission?.id,
      group_user_id: groupUser?.id,
    },
  })

  const isPrivateCommentsDisabled = () => {
    if (submissionStatus?.status === 'submitted') {
      return false
    }
    // since teacher can return submitted assignment without giving a grade, allow private comments when status is returned
    if (submissionStatus?.can?.privateComments) {
      return false
    }
    // else only allow when grade has been given
    if (selectedSubmission?.grades[0]?.grade) {
      return false
    }
    return true
  }

  const [createComment, { loading: commentIsSubmitting }] = useMutation(
    CREATE_SUBMISSION_COMMENT,
    {
      onCompleted: () => {
        useSuccessToast({ message: 'Comment was created' })
      },
      onError: () => {
        useErrorToast({})
      },
    }
  )

  const handleSubmitComment = ({ content }: { content: string }) => {
    createComment({
      variables: {
        id: uuid(),
        content,
        user_id: user?.id,
        submission_id: selectedSubmission?.id,
      },
    })
  }

  const handleSubmitGroupUserComment = ({ content }: { content: string }) =>
    createComment({
      variables: {
        id: uuid(),
        content,
        user_id: user?.id,
        submission_id: selectedSubmission?.id,
        group_user_id: groupUser?.id,
      },
    })

  const [
    createSubmissionAttachment,
    { loading: attachmentIsSubmitting },
  ] = useMutation(CREATE_ATTACHMENT, {
    onCompleted: ({ createAttachment: attachment }) => {
      const { filename, extension } = useFilename(attachment.file)
      useSuccessToast({
        title: `${decodeURIComponent(filename)}.${extension}`,
        message: 'File upload successful',
      })
    },
  })

  const [deleteAttachment, { loading: attachmentIsDeleting }] = useMutation(
    DELETE_ATTACHMENT,
    {
      onError: () => useErrorToast({}),
      onCompleted: () =>
        useSuccessToast({ message: 'File successfully deleted' }),
    }
  )

  const handleDeleteAttachment = ({ id }: Attachment) => {
    deleteAttachment({
      variables: { id },
    })
  }

  const handleUploadAttachments = async (files: File[]) => {
    try {
      await Promise.all(
        files.map((file) =>
          createSubmissionAttachment({
            variables: {
              submissionId: selectedSubmission?.id,
              file,
            },
          })
        )
      )
    } catch (err) {
      useErrorToast({})
    }
  }

  const isPretopic = assignment?.type === 'pretopic'

  const boxLayerStyle = isLargerThanTablet() ? 'card.board' : 'card.board-flat'

  return (
    <VStack spacing={6}>
      <Box w='100%' layerStyle={boxLayerStyle} bg='white.1' minH='256px'>
        <StudentSubmissionBox
          assignment={assignment}
          submissionStatus={submissionStatus}
          submission={selectedSubmission}
          handleUpdateSubmission={handleUpdateSubmission}
          submissionIsUpdating={submissionIsUpdating}
          attachmentIsUpdating={attachmentIsDeleting || attachmentIsSubmitting}
          handleUploadAttachments={handleUploadAttachments}
          handleDeleteAttachment={handleDeleteAttachment}
        />
      </Box>

      {!isPretopic && (
        <CommentsSection
          title={groupId ? 'Group Comments' : 'Private Comments'}
          comments={comments}
          user={user}
          disabled={isPrivateCommentsDisabled()}
          handleSubmitComment={handleSubmitComment}
          isSubmitting={commentIsSubmitting}
          emptyCommentsPlaceholder={
            groupId && 'No comments have been made yet.'
          }
        />
      )}

      {!isPretopic && groupId && (
        <CommentsSection
          title='Private Comments'
          comments={groupUserComments}
          user={user}
          disabled={isPrivateCommentsDisabled()}
          handleSubmitComment={handleSubmitGroupUserComment}
          isSubmitting={commentIsSubmitting}
        />
      )}
    </VStack>
  )
}

export default StudentSubmission
