import { useSubscription } from '@apollo/client'
import { GET_ORGANIZATION_FROM_USER } from '../client/queries'
import { logout } from '../components/Layout/SideNavLayout'
import { useErrorContext } from '../context'

export interface CheckOrganizationStatusProps {
  user_id?: string
}

export const checkOrganizationStatus = ({
  user_id,
}: CheckOrganizationStatusProps) => {
  const { setErrorMessage } = useErrorContext()

  useSubscription(GET_ORGANIZATION_FROM_USER, {
    variables: {
      user_id: user_id,
    },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      const organization = data?.organizations[0]
      if (!organization) {
        setErrorMessage({
          title: 'Organization Code Revoked',
          message:
            'The access code for Alam-edu is invalid, please contact the admin in your organization for the access code.',
          callback: () => {
            logout()
          },
        })
      }
    },
  })
}
