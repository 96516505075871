import { createContext, useContext } from 'react'
import { ClassUser, Topic, User } from '../../types'

export type ClassWorkState = {
  topics: Topic[]
  classUsers: ClassUser[]
  setTopics: React.Dispatch<any>
  getClassTopics: () => void
  groups: Array<{ id: string; name: string }>
  materialAssignmentRefetch: () => void
}

const ClassWorkContext = createContext<ClassWorkState>({
  topics: [],
  classUsers: [],
  setTopics: () => {},
  getClassTopics: () => {},
  groups: [],
  materialAssignmentRefetch: () => {},
})

export const useClassWorkContext = () => useContext(ClassWorkContext)

export default ClassWorkContext
