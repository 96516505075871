import React from 'react'
import { Flex, VStack, StackDivider } from '@chakra-ui/react'
import { Assessment as AssessmentType } from '../../types'
import Assessment from './Assessment'

export interface AssessmentsProps {
  assessments?: AssessmentType[]
}

export const Assessments = ({
  assessments = [],
}: AssessmentsProps): JSX.Element => {
  return (
    <>
      <VStack w='100%' direction='column'>
        {assessments.length > 0 &&
          assessments?.map((assessment) => {
            return (
              <React.Fragment key={assessment.id}>
                <Assessment assessment={assessment} />
              </React.Fragment>
            )
          })}
      </VStack>
    </>
  )
}

export default Assessments
