import React, { useMemo } from 'react'
import {
  Topic as TopicType,
  Assignment as AssignmentType,
  Attachment,
  Exam,
} from '../../types'
import {
  Heading,
  Flex,
  Box,
  StackDivider,
  VStack,
  MenuItem,
  useDisclosure,
  MenuDivider,
  Tooltip,
} from '@chakra-ui/react'
import { HoverMenu, parentHoverCss } from '../Menu'
import { RiPencilFill } from 'react-icons/ri'
import { FaTrash } from 'react-icons/fa'
import { TopicForm, TopicInput } from '../Forms'
import { useMutation } from '@apollo/client'
import {
  UPDATE_TOPIC,
  GET_CLASS_TOPICS,
  EDIT_ASSIGNMENT,
  CREATE_ASSIGNMENT,
  UPDATE_ASSIGNMENT,
  CREATE_ATTACHMENT,
  DELETE_ATTACHMENT,
  generateAssignmentUsers,
  generateStudentSubmissions,
} from '../../client/queries'
import { useAlamContext } from '../../client'
import { useClassWorkContext } from '../../pages/ClassWork/ClassWorkContext'
import { useSuccessToast, useErrorToast, filterMaterials } from '../../utils'
import { ConfirmationModal } from '../Modal'
import { Assignments } from '../../components/Assignment'
import { Pretopic } from './Pretopic'
import { Materials } from '../../components/Material'
import { RenderForUser } from '../SharedComponents'
import { handleTopicOnCreateProps } from '../../pages/ClassWork/ClassWorkPage'
import { v4 as uuid } from 'uuid'

export interface TopicProps extends TopicType {
  groupId?: string
  preference?: string[]
  pendingExams: Exam[]
  topicIndex?: number | undefined
}

export const Topic = ({
  name,
  id,
  topicIndex,
  assignments,
  context = 'classwork',
  materials,
  materialAssignmentRefetch = () => {},
  preference = ['visual', 'aural', 'read', 'kinesthetic'],
  groupId,
  pendingExams,
}: TopicProps): JSX.Element => {
  const { classUser, user } = useAlamContext()
  const { getClassTopics } = useClassWorkContext()

  const refetch = () => {
    getClassTopics()
    materialAssignmentRefetch()
  }

  const {
    isOpen: editTopicIsOpen,
    onClose: editTopicOnClose,
    onOpen: editTopicOnOpen,
  } = useDisclosure()

  const {
    isOpen: deleteTopicIsOpen,
    onClose: deleteTopicOnClose,
    onOpen: deleteTopicOnOpen,
  } = useDisclosure()

  const typeAssignments = useMemo(
    () => assignments.filter(({ type }) => type === 'assignment'),
    [assignments]
  )
  const typePretopic = useMemo(
    () => assignments.find(({ type }) => type === 'pretopic'),
    [assignments]
  )

  const [updateTopic, { loading: topicIsUpdating }] = useMutation(UPDATE_TOPIC)

  const [editAssignment, { loading: editAssignmentIsUpdating }] = useMutation(
    EDIT_ASSIGNMENT
  )
  const [createAssignment, { loading: assignmentIsSubmitting }] = useMutation(
    CREATE_ASSIGNMENT
  )

  const [createAttachment, { loading: attachmentIsSubmitting }] = useMutation(
    CREATE_ATTACHMENT
  )

  const [deleteAttachment, { loading: attachmentIsDeleting }] = useMutation(
    DELETE_ATTACHMENT
  )

  const [updateAssignment, { loading: assignmentIsUpdating }] = useMutation(
    UPDATE_ASSIGNMENT
  )

  const submitAssignmentAttachments = async (
    assignmentId: string,
    files: File[]
  ) => {
    try {
      return Promise.all(
        files?.map((file) =>
          createAttachment({
            variables: {
              assignmentId,
              file,
            },
          })
        )
      )
    } catch (err) {
      useErrorToast({ message: 'Upload failed' })
      return err
    }
  }

  const handleDeleteAttachments = (removedFiles: Attachment[]) => {
    try {
      return Promise.all(
        removedFiles?.map(({ id }) =>
          deleteAttachment({
            variables: { id },
          })
        )
      )
    } catch (err) {
      return err
    }
  }

  const getEditMutations = (
    data: handleTopicOnCreateProps,
    typePretopic: AssignmentType | undefined
  ) => {
    const { topic, assignment } = data

    const newAssignmentId = uuid()

    if (!assignment && !typePretopic) {
      return [
        updateTopic({
          variables: {
            id,
            changes: {
              name: topic.name,
            },
          },
        }),
      ]
    }

    if (typePretopic && !assignment) {
      return [
        updateTopic({
          variables: {
            id,
            changes: {
              name: topic.name,
            },
          },
        }),
        editAssignment({
          variables: {
            id: typePretopic?.id,
            changes: {
              archived_at: 'NOW()',
            },
          },
        }),
      ]
    }

    if (assignment && !typePretopic) {
      return [
        updateTopic({
          variables: {
            id,
            changes: {
              name: topic.name,
            },
          },
        }),
        createAssignment({
          variables: {
            id: newAssignmentId,
            name: assignment?.name,
            description: assignment?.description,
            schedule: assignment?.schedule,
            due_date: assignment?.dueDateStr,
            rubric: assignment?.rubric,
            grading: assignment?.grading,
            topic_id: id,
            is_visible: assignment?.isVisible,
            user_id: user?.id,
            assignment_users: generateAssignmentUsers(
              assignment?.assignmentUsers
            ),
            add_new_users: true,
            add_new_groups: false,
            assignment_groups: [],
            submissions: [
              ...generateStudentSubmissions(
                assignment?.assignmentUsers,
                newAssignmentId
              ),
            ],
            class_id: classUser?.class?.id,
            type: assignment?.type,
          },
        }),
        submitAssignmentAttachments(newAssignmentId, assignment?.files),
      ]
    }

    if (assignment && typePretopic) {
      return [
        updateTopic({
          variables: {
            id,
            changes: {
              name: topic.name,
            },
          },
        }),
        submitAssignmentAttachments(typePretopic.id, assignment?.files),
        handleDeleteAttachments(assignment?.removedFiles),
        updateAssignment({
          variables: {
            id: typePretopic.id,
            changes: {
              name: assignment?.name,
              description: assignment?.description,
              rubric: assignment?.rubric,
              grading: assignment?.grading,
            },
            assignment_users: [],
            assignment_groups: [],
            submissions: [],
            removed_users: [],
            removed_groups: [],
          },
        }),
      ]
    }

    return []
  }

  const handleEditTopic = async (data: handleTopicOnCreateProps) => {
    try {
      const mutations = getEditMutations(data, typePretopic)

      Promise.all(mutations).then(() => {
        useSuccessToast({
          title: 'Topic updated',
          message: 'Topic was successfully updated',
        })
        refetch()
        editTopicOnClose()
      })
    } catch (error) {
      console.warn(error)
      useErrorToast({ message: `Topic wasn't updated` })
    }
  }

  const handleDeleteTopic = async () => {
    try {
      if (typePretopic) {
        await editAssignment({
          variables: {
            id: typePretopic?.id,
            changes: {
              archived_at: 'NOW()',
            },
          },
        })
      }

      await updateTopic({
        variables: {
          id,
          changes: {
            archived_at: 'NOW()',
          },
        },
      })

      useSuccessToast({
        title: 'Topic deleted',
        message: 'Topic was successfully deleted',
      })
      refetch()
      deleteTopicOnClose()
    } catch (error) {
      console.warn(error)
      useErrorToast({ message: `Topic wasn't deleted` })
    }
  }

  return (
    <>
      <TopicForm
        title='Edit Topic'
        onClose={editTopicOnClose}
        onSubmit={handleEditTopic}
        isOpen={editTopicIsOpen}
        isSubmitting={topicIsUpdating}
        submitText='Save'
        submittingText='Saving'
        name={name}
        assignment={typePretopic}
      />
      <ConfirmationModal
        isOpen={deleteTopicIsOpen}
        onClose={deleteTopicOnClose}
        title={`Are you sure you want to delete ${name}?`}
        message={`All comments and data will also be deleted.`}
        onSubmit={handleDeleteTopic}
      />
      <Flex w='100%' justify='start' css={parentHoverCss}>
        <RenderForUser
          roles={['admin', 'teacher', 'creator']}
          context={context}
        >
          <HoverMenu
            top={topicIndex === 0 ? 10 : -1}
            right={0}
            menuButtonVariant='blank'
          >
            <>
              <MenuItem icon={<RiPencilFill />} onClick={editTopicOnOpen}>
                Edit
              </MenuItem>
              <MenuDivider />
              <MenuItem icon={<FaTrash />} onClick={deleteTopicOnOpen}>
                Delete
              </MenuItem>
            </>
          </HoverMenu>
        </RenderForUser>

        <VStack
          w='100%'
          spacing={2}
          divider={<StackDivider layerStyle='divider' />}
          align='flex-start'
          mt={topicIndex === 0 ? '2.5rem' : '0'}
        >
          <Tooltip label={name} aria-label={name} placement='top-start'>
            <Heading
              textStyle='h3'
              color='black.1'
              isTruncated
              w={name.length >= 60 ? '90%' : '100%'}
            >
              {name}
            </Heading>
          </Tooltip>

          <Flex w='100%' direction='column'>
            <VStack spacing={3} pt={2}>
              {typePretopic && (
                <Pretopic
                  pretopic={typePretopic}
                  materialAssignmentRefetch={materialAssignmentRefetch}
                />
              )}
              <Assignments
                assignments={typeAssignments}
                materialAssignmentRefetch={materialAssignmentRefetch}
                groupId={groupId}
                pendingExams={pendingExams}
              />
              <Materials
                materials={filterMaterials(materials, preference)}
                context={context}
                materialAssignmentRefetch={materialAssignmentRefetch}
                groupId={groupId}
              />
            </VStack>
          </Flex>
        </VStack>
      </Flex>
    </>
  )
}

export default Topic
