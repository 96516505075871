import { gql } from '@apollo/client'

export const GET_TOKEN_FROM_CODE = gql`
  query get_token($token_code: String!) {
    getTokenFromCode(tokenCode: $token_code) {
      id
      userId: userId
      classUser: classUser {
        userId: userId
        classId: classId
      }
      type
    }
  }
`

export const USE_TOKEN = gql`
  mutation use_token($token_id: ID!, $type: String!) {
    useToken(tokenId: $token_id, type: $type) {
      id
      userId: userId
      classUser: classUser {
        userId: userId
        classId: classId
      }
      type
    }
  }
`
