import { Box, Flex, Text, Tooltip, Icon } from '@chakra-ui/react'
import { MdMessage } from 'react-icons/md'
import { ClassUser, User } from '../../types'
import {
  getFullName,
  getFirstAndLastInitial,
  getClassUserRole,
} from '../../utils'
import { AlamAvatar } from '../Avatar'

export type ClassUserItemProps = {
  classUser: ClassUser
  isOnline: boolean
  handleSelectClassUser: (user?: ClassUser) => void
  isLast: boolean
}

export const ClassUserItem = ({
  classUser,
  isOnline,
  handleSelectClassUser,
  isLast,
}: ClassUserItemProps): JSX.Element => {
  const { user, role } = classUser
  const name = getFullName(user)
  const groupUsers = user?.group_users || []
  return (
    <Flex
      pl='16px'
      pr='8px'
      h='56px'
      minH='56px'
      align='center'
      cursor='pointer'
      onClick={() => handleSelectClassUser(classUser)}
      mb={isLast ? '16px' : '0'}
    >
      <AlamAvatar
        size='md'
        src={user?.avatar}
        name={getFirstAndLastInitial(user)}
        isOnline={isOnline}
      />

      <Flex direction='column' ml='8px'>
        <Tooltip label={name} aria-label={`chat with ${name}`}>
          <Text
            maxW='120px'
            color={isOnline ? 'primary.1' : 'primary.2'}
            textStyle={isOnline ? 'body.emphasized' : 'body.1'}
            isTruncated
          >
            {name}
          </Text>
        </Tooltip>

        <Flex color='grey.1' h='100%' align='center'>
          <Text textTransform='capitalize' textStyle='caption'>
            {getClassUserRole(classUser)}
          </Text>

          {role !== 'teacher' && groupUsers[0]?.group?.name && (
            <>
              <Box layerStyle='bulletpoint' mx='7px' />
              <Text textStyle='caption' color='grey.1' maxW='80px' isTruncated>
                {groupUsers[0]?.group?.name}
              </Text>
            </>
          )}
        </Flex>
      </Flex>

      <UnreadMessages user={classUser.user} />
    </Flex>
  )
}

export interface UnreadMessages {
  user?: User
}

export const UnreadMessages = ({
  user,
}: UnreadMessages): JSX.Element | null => {
  const count = user?.unreadMessages?.aggregate?.count || 0

  return count >= 1 ? (
    <Flex align='center' justify='flex-end' w='100%'>
      <Text color='primary.1' textStyle='body.semibold' pr='8px'>
        {count}
      </Text>
      <Icon as={MdMessage} color='primary.1' />
    </Flex>
  ) : null
}
