import { VStack } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import { TeacherExamIndicator } from './'
import { Exam } from '../../types'
import { AssignmentType } from '../../pages/Assignment/AssignmentPage'
import { useSubscription } from '@apollo/client'
import { GET_EXAMS_FOR_TEACHERS } from '../../client/queries'

export type TeacherExamIndicatorsProps = {
  assignment: AssignmentType
}

export const TeacherExamIndicators = ({
  assignment,
}: TeacherExamIndicatorsProps): JSX.Element => {
  const [exams, setExams] = useState<Exam[]>([])

  useSubscription(GET_EXAMS_FOR_TEACHERS, {
    variables: { assignment_id: assignment.id },
    onSubscriptionData: ({
      subscriptionData: {
        data: { exams },
      },
    }) => {
      if (exams) {
        setExams(exams)
      }
    },
  })

  return (
    <>
      {exams.length > 0 ? (
        <VStack spacing='8px' mt='16px'>
          {exams?.map((exam) => (
            <React.Fragment key={exam.id}>
              <TeacherExamIndicator exam={exam} assignment={assignment} />
            </React.Fragment>
          ))}
        </VStack>
      ) : null}
    </>
  )
}
