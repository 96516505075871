import React from 'react'
import AnalyticsPage from './AnalyticsPage'
import { useParams } from 'react-router-dom'

const Analytics = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  return <AnalyticsPage classId={id} />
}

export default Analytics
