import { useState } from 'react'

interface Type {
  id?: string
}

export function useSelectedItem<T extends Type>(): {
  selectedItem?: T
  handleSelectItem: (item?: T) => void
  handleUnselectItem: () => void
  updateSelectedItem: (items: T[]) => T | null
} {
  const [selectedItem, setSelectedItem] = useState<T>()

  const handleSelectItem = (item?: T) => setSelectedItem(item)

  const handleUnselectItem = () => setSelectedItem(undefined)

  // Prevents selectedItem from going stale
  // Takes an updated list of items and finds the selectedItem and updates it
  // Used for updating selectedItem when subscription gives a new data
  const updateSelectedItem = (items: T[]): T | null => {
    if (selectedItem && 'id' in selectedItem) {
      const selectedId = selectedItem.id
      const newSelected = items.find(({ id }) => id === selectedId)

      if (newSelected) {
        setSelectedItem(newSelected)
        return newSelected
      }
    }

    return null
  }

  return {
    selectedItem,
    handleSelectItem,
    handleUnselectItem,
    updateSelectedItem,
  }
}
