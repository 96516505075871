import { Text } from '@chakra-ui/react'

interface RespondentCountProps {
  respondentCount: number
  totalCount: number
}

export const RespondentCount = ({
  respondentCount,
  totalCount,
}: RespondentCountProps): JSX.Element => {
  return (
    <Text fontSize='16px' fontWeight='400' color='rgba(1, 0, 2, 0.56)'>
      {respondentCount} respondent{respondentCount > 1 ? 's' : ''} out of
      {' ' + totalCount.toString()} student{totalCount > 1 ? 's' : ''}
    </Text>
  )
}
