import { Spinner, Flex } from '@chakra-ui/react'

export type Loader = {
  loaders?: Array<boolean>
  errors?: Array<boolean>
  children: any
}

export const Loader = ({ loaders = [], errors, children }: Loader) => {
  const isLoading = loaders.every((loading) => loading)

  if (isLoading) {
    return (
      <Flex align='center' justify='center' h='100%' minH='100vh' w='100%'>
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='primary.2'
          color='primary.1'
          size='xl'
        />
      </Flex>
    )
  } else {
    return <>{children}</>
  }
}
