// @ts-nocheck
import { intervalToDuration, format } from 'date-fns'

// Converts to UTC String then removes timezone before wrapping to date once again
// new Date(date.toISOString()) still returns local time bec of timezone
export const toUTC = (date: Date | string): Date => {
  const utcString = new Date(date).toISOString()
  const noTimeZone = utcString.slice(0, -5)
  return new Date(noTimeZone)
}

export const toUTCString = (date: Date | string): string => {
  const utcString = new Date(date).toISOString()
  return utcString.slice(0, -5)
}

export const formatTimeLog = (insertedAt: Date | string): string => {
  const insertedDate = new Date(insertedAt)
  const now = toUTC(new Date())

  const duration: Duration = intervalToDuration({
    start: insertedDate,
    end: now,
  }) || { years: 0, months: 0, days: 0, hours: 0, minutes: 0, seconds: 0 }

  if (duration?.years > 0) {
    return `${duration?.years} ${pluralize('year', duration?.years)} ago`
  }

  if (duration?.months > 0) {
    return `${duration?.months} ${pluralize('month', duration?.months)} ago`
  }

  if (duration?.days > 0) {
    return `${duration?.days} ${pluralize('day', duration?.days)} ago`
  }

  if (duration?.hours > 0) {
    return `${duration?.hours} ${pluralize('hour', duration?.hours)} ago`
  }

  if (duration?.minutes > 0) {
    return `${duration?.minutes} ${pluralize('minute', duration?.minutes)} ago`
  }

  if (duration?.seconds < 5) return 'Just now'

  return `${duration?.seconds} ${pluralize('second', duration?.seconds)} ago`
}

export const formatTimeLogSession = (insertedAt: Date | string): string => {
  const insertedDate = new Date(insertedAt)
  const now = toUTC(new Date())

  const duration: Duration = intervalToDuration({
    start: insertedDate,
    end: now,
  }) || { years: 0, months: 0, days: 0, hours: 0, minutes: 0, seconds: 0 }

  if (duration?.days >= 1) {
    return format(new Date(`${insertedDate} UTC`), 'h:m a')
  }

  if (duration?.hours > 6) {
    return format(new Date(insertedDate), 'h:m a')
  }

  if (duration?.hours > 0) {
    return `${duration?.hours} ${pluralize('hour', duration?.hours)} ago`
  }

  if (duration?.minutes > 0) {
    return `${duration?.minutes} ${pluralize('minute', duration?.minutes)} ago`
  }

  if (duration?.seconds < 5) return 'Just now'

  return `${duration?.seconds} ${pluralize('second', duration?.seconds)} ago`
}

export const pluralize = (
  word: string,
  count: number | undefined | null
): string => {
  if (!count || count <= 1) {
    return word
  } else {
    return word + 's'
  }
}

export const formatDateToTimestamp = (date: Date): string => {
  const timestampDateFormat = format(date, 'Y-MM-dd')
  return `${timestampDateFormat}T00:00:00`
}

export const formatNumber = (number: number): string | number => {
  return number < 10 ? `0${number}` : number
}

export const formatDaysWithinToTimestamp = (
  days: number,
  now: Date
): string => {
  const last = new Date(now.getTime() + days * 24 * 60 * 60 * 1000)
  const lastDay = last.getDate()
  const formatLastDay = formatNumber(lastDay)
  const lastMonth = last.getMonth() + 1
  const formatLastMonth = formatNumber(lastMonth)
  const lastYear = last.getFullYear()

  return `${lastYear}-${formatLastMonth}-${formatLastDay}T00:00:00`
}

export const dateInPast = (firstDate: Date, secondDate: Date): boolean => {
  if (
    firstDate.getFullYear() === secondDate.getFullYear() &&
    firstDate.getMonth() === secondDate.getMonth() &&
    firstDate.getDate() === secondDate.getDate()
  )
    return false

  return firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)
}

export const userTimeZone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const utcToLocale = (date: string): Date => {
  return new Date(date + 'Z')
}
