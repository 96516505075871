import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useAlamContext } from '../../client'
import { GET_CLASS_FROM_CODE, JOIN_CLASS } from '../../client/queries'
import { Class as ClassType } from '../../types'
import { useQuery, useMutation } from '@apollo/client'
import { useSuccessToast, useErrorToast } from '../../utils'
import { v4 as uuid } from 'uuid'
import { Loader } from '../../components/SharedComponents/Loader'
import { ShowMessageModal, ShowMessageModalProps } from '../../components/Modal'

export const InviteCode = () => {
  const { inviteCode } = useParams<{ inviteCode: string }>()
  const { user } = useAlamContext()
  const [joinClass] = useMutation(JOIN_CLASS)
  const history = useHistory()
  const [
    errorMessage,
    setErrorMessage,
  ] = useState<ShowMessageModalProps | null>(null)

  const { data: classData, loading: getClassIsLoading, error } = useQuery(
    GET_CLASS_FROM_CODE,
    {
      variables: {
        invite_code: inviteCode,
      },
    }
  )

  const redirectToHome = () => {
    history.push('/')
  }

  useEffect(() => {
    if (user?.id && !getClassIsLoading && classData && classData.classes[0]) {
      const classFromCode = classData.classes[0]
      if (!classFromCode?.can_send_invite) {
        setErrorMessage({
          title: classFromCode?.name,
          message:
            'We can’t seem to find this classroom! Please contact the person who invited you to the classroom for more details.',
          isOpen: true,
          onClose: redirectToHome,
        })

        return
      }

      joinClass({
        variables: {
          user_id: user.id,
          class_id: classFromCode?.id,
          role: 'student',
          send_invite: false,
        },
      })
        .then(({ data: { createClassUser: mutationData } }) => {
          if (!mutationData) {
            useErrorToast({
              message: 'You are an existing member of the class.',
            })
          } else {
            useSuccessToast({
              title: 'Join Class',
              message: 'Joined class succesfully',
            })
          }
          history.push(`/classes/${classFromCode.id}`)
        })
        .catch((error) => {
          useErrorToast({
            message: 'Join class failed',
          })
          history.push('/')
        })
    } else if (user?.id && !getClassIsLoading && !classData.classes[0]) {
      useErrorToast({
        message: 'Invalid invite code',
      })
      history.push('/')
    }
  }, [classData, getClassIsLoading, user])

  return (
    <>
      {errorMessage && <ShowMessageModal {...errorMessage} />}
      <Loader loaders={[true]}>
        <></>
      </Loader>
    </>
  )
}

export default InviteCode
