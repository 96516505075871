import React, { useState } from 'react'
import { Input, Tooltip, useDisclosure } from '@chakra-ui/react'
import { hasNumber } from '../../utils'

export type AlphabeticalGradeInputProps = {
  value?: string
  onChange: (value: string) => void
  disabled?: boolean
  readOnly?: boolean
  inputProps?: Record<string, any>
}

export const AlphabeticalGradeInput = ({
  value,
  onChange,
  disabled = false,
  readOnly,
  inputProps = {},
}: AlphabeticalGradeInputProps): JSX.Element => {
  const {
    isOpen: isWarningOpen,
    onOpen: onWarningOpen,
    onClose: onWarningClose,
  } = useDisclosure()

  const flashWarning = () => {
    onWarningOpen()

    setTimeout(onWarningClose, 2000)
  }

  return (
    <Tooltip label='Please input letters only.' isOpen={isWarningOpen}>
      <Input
        w='104px'
        value={value}
        variant='grade'
        textStyle='body.1'
        disabled={disabled}
        readOnly={readOnly}
        onChange={(e) => {
          const newValue = e.target.value
          if (newValue.length >= 10) {
            return
          }

          if (hasNumber(newValue)) {
            flashWarning()
            return
          }

          onChange(newValue)
        }}
        {...inputProps}
      />
    </Tooltip>
  )
}
