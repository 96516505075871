import { useState } from 'react'

export const useFormState = (initialState: Record<string, string>) => {
  const [formState, setFormState] = useState(initialState)

  const handleInputChange = (field: string, value: string) => {
    setFormState({ ...formState, [field]: value })
  }

  const resetFormState = () => {
    setFormState(initialState)
  }

  return { formState, handleInputChange, resetFormState }
}

export default useFormState
