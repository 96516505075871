import React, { useState } from 'react'
import {
  Flex,
  Text,
  IconButton,
  Button,
  Textarea,
  VStack,
  StackDivider,
  Spacer,
  Switch,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormErrorIcon,
  Input,
} from '@chakra-ui/react'
import { MdClose } from 'react-icons/md'
import { useForm } from 'react-hook-form'
import debounce from 'lodash-es/debounce'
import { validateSpaceOnly, useAttachment, useDatePicker } from '../../utils'
import 'react-datepicker/dist/react-datepicker.css'
import { Attachment } from '../../types'
import { EditableDatePicker } from './index'
import { RenderForUser } from '../SharedComponents'
import { useAlamContext } from '../../client'

export type CreateAnnouncementInput = {
  postId?: string
  title: string
  content?: string
  isPinned: boolean
  postSchedule?: string
  files: File[]
  removedFiles: Attachment[]
}

export type CreateAnnouncementProps = {
  id?: string
  title?: string
  content?: string
  schedule?: string
  isPinned?: boolean
  onClose: () => void
  onDelete?: (postId: string) => void
  onSubmit: (data: CreateAnnouncementInput) => void
  isSubmitting: boolean
  files?: Attachment[]
}

export const CreateAnnouncement = ({
  id = '',
  content = '',
  title = '',
  isPinned = false,
  schedule = '',
  onClose,
  onDelete: onArchivePost = () => {},
  onSubmit,
  isSubmitting,
  files = [],
}: CreateAnnouncementProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    errors,
    trigger,
    formState: { isValid },
  } = useForm<CreateAnnouncementInput>({ mode: 'onChange' })

  const {
    removedAttachments,
    newAttachments,
    renderFileList,
    renderAttachButton,
    renderAttachmentError,
  } = useAttachment(files)

  const { date: postSchedule, handleChangeDate } = useDatePicker(schedule)
  const [pinned, setPinned] = useState(isPinned)

  const handleSubmitForm = (data: CreateAnnouncementInput) => {
    const newData = {
      ...data,
      files: newAttachments,
      isPinned: pinned,
      postSchedule: postSchedule.toISOString(),
      removedFiles: removedAttachments,
    }
    onSubmit(newData)
  }

  return (
    <Flex
      w='100%'
      layerStyle='card.board-updated-shadow'
      borderRadius={{ base: '0', lg: '12px' }}
      bg='white.1'
      direction='column'
    >
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <VStack
          spacing={2}
          w='100%'
          divider={<StackDivider layerStyle='divider' />}
        >
          <Flex w='100%' px={3} pt={3} pb={1} align='center'>
            <IconButton
              variant='icon.button'
              aria-label='Cancel creating announcement'
              fontSize='24px'
              icon={<MdClose />}
              onClick={onClose}
            />
            <Text
              letterSpacing='0'
              pl='0.25rem'
              py='0.75rem'
              textStyle='h5'
              color='primary.1'
            >
              {id ? 'Edit ' : 'Create '} Announcement
            </Text>
          </Flex>

          <Flex
            direction='column'
            w='100%'
            justify='space-around'
            px={6}
            py={3}
          >
            <VStack spacing={4} align='flex-start'>
              <FormControl isInvalid={!!errors.title?.message}>
                <FormLabel
                  variant='required'
                  fontWeight='600'
                  letterSpacing='0.1rem'
                >
                  Title
                </FormLabel>
                <Input
                  name='title'
                  defaultValue={title}
                  border='.5px solid'
                  borderColor='grey.border'
                  ref={register({
                    required: 'Title is a required field',
                    maxLength: {
                      value: 30,
                      message: 'Title exceeded 30 maximum characters',
                    },
                    validate: {
                      validateSpaceOnly,
                    },
                  })}
                  onChange={debounce(async () => {
                    await trigger('title')
                  }, 500)}
                />
                {errors?.title && (
                  <FormErrorMessage>
                    <FormErrorIcon />
                    {errors.title?.message}
                  </FormErrorMessage>
                )}
              </FormControl>
              {id && (
                <Input
                  name='postId'
                  defaultValue={id}
                  ref={register()}
                  type='hidden'
                />
              )}

              <FormControl
                isInvalid={!!errors.content?.message}
                css={{
                  '& .optional::after': {
                    letterSpacing: '0',
                  },
                }}
              >
                <FormLabel
                  variant='optional'
                  className='optional'
                  fontWeight='600'
                >
                  Description
                </FormLabel>
                <Textarea
                  resize='vertical'
                  padding='1rem'
                  fontSize='16px'
                  letterSpacing='0.02em'
                  maxHeight='200px'
                  border='.5px solid'
                  borderColor='grey.border'
                  defaultValue={content}
                  h='144px'
                  name='content'
                  ref={register({
                    maxLength: {
                      value: 1000,
                      message: 'Description exceeded 1000 maximum characters',
                    },
                    validate: {
                      validateSpaceOnly,
                    },
                  })}
                  onChange={debounce(async () => {
                    await trigger('content')
                  }, 500)}
                />
                {errors?.content && (
                  <FormErrorMessage>
                    <FormErrorIcon />
                    {errors.content?.message}
                  </FormErrorMessage>
                )}
              </FormControl>

              {renderAttachButton()}

              {renderAttachmentError()}
            </VStack>
          </Flex>

          {renderFileList({})}

          <Flex direction='column' w='100%' px={6} pt={3} pb={6}>
            <VStack spacing={7}>
              <FormControl>
                <FormLabel fontWeight='600' letterSpacing='0.1rem'>
                  Schedule
                </FormLabel>
                <EditableDatePicker
                  value={postSchedule}
                  onChange={handleChangeDate}
                />
              </FormControl>

              <RenderForUser roles={['admin', 'teacher', 'creator']}>
                <Flex w='100%' align='center'>
                  <Text textStyle='body.big'>
                    Pin Announcement to Bulletin Board
                  </Text>
                  <Spacer />
                  <Switch
                    name='isPinned'
                    isChecked={pinned}
                    onChange={(e) => setPinned(e.target.checked)}
                  />
                </Flex>
              </RenderForUser>

              <Flex w='100%' justify='flex-end'>
                {id && (
                  <Button
                    variant='base.white'
                    type='button'
                    textStyle='button'
                    minW='72px'
                    onClick={() => onArchivePost(id)}
                  >
                    Delete
                  </Button>
                )}
                <Spacer />
                <Button
                  variant='base.primary'
                  type='submit'
                  textStyle='button'
                  minW='72px'
                  disabled={!isValid}
                  isLoading={isSubmitting}
                  loadingText='Posting'
                >
                  Post
                </Button>
              </Flex>
            </VStack>
          </Flex>
        </VStack>
      </form>
    </Flex>
  )
}

export default CreateAnnouncement
