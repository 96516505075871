import { useQuery, useSubscription } from '@apollo/client'
import { useEffect, useState } from 'react'
import { BiChevronLeft } from 'react-icons/bi'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'
import { useAlamContext } from '../../client'
import { GET_SESSION_DETAILS } from '../../client/queries'
import {
  createFolderStructure,
  createShortcut,
  getFileDetails,
  getPermissions,
  listFiles,
  permissionType,
  useGoogleAPI,
} from '../../components/GoogleAPI'
import {
  DocumentPage,
  SessionInfoPage,
  SessionSpacePage,
} from '../../components/Session'
import { Session, SessionDocument } from '../../types'
import NotFound from '../NotFound'
import { Loader } from '../../components/SharedComponents'
import { useErrorContext } from '../../context'

export type SessionPageProps = {
  id: string
  sessionId: string
}

interface SessionState extends Session {
  notFound?: boolean
}

export interface documentPermission extends SessionDocument {
  permissions: permissionType[]
}

export const SessionPage = ({
  id,
  sessionId,
}: SessionPageProps): JSX.Element => {
  const { isAuthorized, tokenIsExpired } = useGoogleAPI()
  const { classUser, user } = useAlamContext()
  const className = classUser?.class?.name
  const initialSessionState = {
    id: '',
    name: '',
    description: '',
    user_id: '',
    session_users: [],
    documents: [],
    links: [],
    attachments: [],
  }
  const [session, setSession] = useState<SessionState>(initialSessionState)
  const { name, documents } = session
  const [sessionFolderId, setSessionFolderId] = useState<string>('')
  const refetch = () => {}
  const { data, loading, error } = useSubscription(GET_SESSION_DETAILS, {
    variables: { session_id: sessionId, class_id: id },
  })

  const history = useHistory()
  const { setErrorMessage } = useErrorContext()

  const [documentsPermissions, setDocumentsPermissions] = useState<
    documentPermission[]
  >([])

  const getDocumentsPermissions = async () => {
    try {
      if (!isAuthorized || tokenIsExpired()) {
        return
      }

      const newDocumentPermissions = await Promise.all(
        documents.map(async (document) => {
          const response = await getPermissions({ fileId: document.file_id })
          const permissions: permissionType[] = response.result.permissions

          return {
            ...document,
            permissions,
          }
        })
      )

      setDocumentsPermissions(newDocumentPermissions)
    } catch (error) {
      return error
    }
  }

  useEffect(() => {
    if (data && data?.session !== null) {
      setSession(data?.session)
    }
    if (data && data?.session === null) {
      setErrorMessage({
        title: 'Session Error',
        message:
          "We can't seem to find this session! It may have been deleted or does not exist. Please contact the person who invited you to the session for more details.",
        callback: () => {
          setSession(initialSessionState)
          history.push(`/classes/${id}`)
        },
      })
    }
  }, [data, error])

  useEffect(() => {
    if (documents.length > 0) {
      getDocumentsPermissions()
    }
  }, [isAuthorized, documents])

  // TODO: Rename this function
  // - Creates the folder structure if it doesn't exist
  // - Sets the sessionFolderId
  // - Checks session documents if it exists in user's folder structure and creates a shortcut if not
  const getSessionFolderId = async () => {
    if (!className || !name || !isAuthorized || !user || tokenIsExpired()) {
      return
    }

    // Create folder structure/get file ids
    const folderIds = await createFolderStructure({
      folderNames: ['Alam-edu', className, name],
    })
    const folderId = folderIds[folderIds.length - 1]
    // session folder id will be used as parent of documents
    setSessionFolderId(folderId)

    // check every document in session if it already exists under session folder
    return documents.map(async ({ file_id, ...document }) => {
      if (document.user.id === user?.id) {
        return 'User already owns the document'
      }

      const fileDetails = await getFileDetails({
        fileId: file_id,
        fields: 'name',
      })

      // search for shortcut pointing to file_id
      const res = await listFiles({
        query: `mimeType = 'application/vnd.google-apps.shortcut' and trashed = false and shortcutDetails.targetId = '${file_id}'`,
      })

      // loop through results and check if parents include folderID
      const isNested = res?.result?.files?.some((file: any) =>
        file.parents.includes(folderId)
      )

      if (!isNested) {
        return await createShortcut({
          targetId: file_id,
          parents: [folderId],
          name: fileDetails.result.name,
        })
      }

      return "Shortcut already exists in user's folder"
    })
  }

  useEffect(() => {
    if (documents.length > 0) {
      getSessionFolderId()
    }
  }, [className, name, isAuthorized, documents, user])

  const { path, url } = useRouteMatch()
  const topNavLeft = [{ type: 'text' as const, text: session?.name }]
  const topNavCenter = [
    { type: 'link' as const, url, text: 'Session Space' },
    {
      type: 'link' as const,
      url: `${url}/info`,
      text: 'Session Info',
    },
  ]
  const documentTopNavLeft = [
    {
      type: 'textWithIconButton' as const,
      text: session?.name,
      icon: <BiChevronLeft />,
      onClick: () => history.push(url),
    },
  ]

  if (!session?.id && session?.notFound) {
    return <NotFound />
  }

  return (
    <>
      <Route path={`${path}/document/:documentId`} exact>
        <DocumentPage
          id={id}
          topNavLeft={documentTopNavLeft}
          session={session}
        />
      </Route>
      <Route path={`${path}/info`} exact>
        <SessionInfoPage
          id={id}
          sessionId={sessionId}
          topNavLeft={topNavLeft}
          topNavCenter={topNavCenter}
          session={session}
          refetch={refetch}
          setSession={setSession}
          documentsPermissions={documentsPermissions}
          setDocumentsPermissions={setDocumentsPermissions}
        />
      </Route>
      <Route path={`${path}`} exact>
        <SessionSpacePage
          id={id}
          sessionId={sessionId}
          topNavLeft={topNavLeft}
          topNavCenter={topNavCenter}
          session={session}
          refetch={refetch}
          setSession={setSession}
          sessionFolderId={sessionFolderId}
          setSessionFolderId={setSessionFolderId}
        />
      </Route>
    </>
  )
}

export default SessionPage
