import React from 'react'
import { Exam, Topic as TopicType } from '../../types'
import { Topic } from './index'
import { Flex, VStack } from '@chakra-ui/react'

export interface TopicsProps {
  topics: TopicType[]
  context?: string
  materialAssignmentRefetch: () => void
  topicFilter: boolean[]
  preference?: string[]
  groupId?: string
  pendingExams?: Exam[]
}

export const Topics = ({
  topics,
  context = 'classwork',
  topicFilter,
  materialAssignmentRefetch,
  preference,
  groupId,
  pendingExams = [],
}: TopicsProps): JSX.Element => {
  return (
    <Flex w='100%' direction='column' mt='1.5rem !important'>
      <VStack spacing={10}>
        {topics?.map((topic, index) => {
          if (topicFilter[index]) {
            return (
              <Topic
                {...topic}
                context={context}
                materialAssignmentRefetch={materialAssignmentRefetch}
                key={topic.id}
                preference={preference}
                groupId={groupId}
                pendingExams={pendingExams}
                topicIndex={index}
              />
            )
          }
        })}
      </VStack>
    </Flex>
  )
}

export default Topics
