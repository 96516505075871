import React from 'react'
import ClassWorkPage from './ClassWorkPage'
import { useParams } from 'react-router-dom'

const Classwork = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()

  return <ClassWorkPage id={id} />
}

export default Classwork
