import { VStack } from '@chakra-ui/react'
import React from 'react'
import { ExamIndicator } from '.'
import { Exam } from '../../types'

export type ExamIndicatorsProps = {
  exams?: Exam[]
  containerProps?: Record<string, any>
}

export const ExamIndicators = ({
  exams,
  containerProps = {},
}: ExamIndicatorsProps): JSX.Element => (
  <VStack spacing='8px' mb='16px' {...containerProps}>
    {exams &&
      exams?.map((exam) => (
        <React.Fragment key={exam.id}>
          <ExamIndicator exam={exam} />
        </React.Fragment>
      ))}
  </VStack>
)
