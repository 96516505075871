const textStyles = {
  logo: {
    fontFamily: "'Source Sans Pro', san-serif",
    fontWeight: '700',
    fontStyle: 'normal',
    fontSize: '22px',
    lineHeight: '27px',
    letterSpacing: '-0.01em',
  },
  h1: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: '800',
    lineHeight: '44px',
    letterSpacing: '-0.01em',
  },
  h2: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontStyle: 'normal',
    fontWeight: '800',
    letterSpacing: '-0.01em',
    fontSize: '24px',
    lineHeight: '37px',
  },
  h3: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontStyle: 'normal',
    fontWeight: '800',
    letterSpacing: '-0.01em',
    fontSize: '20px',
    lineHeight: '27px',
  },
  h4: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontStyle: 'normal',
    fontWeight: '700',
    letterSpacing: '-0.01em',
    fontSize: '18px',
    lineHeight: '23px',
  },
  h5: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontStyle: 'normal',
    fontWeight: '700',
    letterSpacing: '-0.01em',
    fontSize: '16px',
    lineHeight: '20px',
  },
  h6: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontStyle: 'normal',
    fontWeight: '700',
    letterSpacing: '-0.01em',
    fontSize: '14px',
    lineHeight: '18px',
  },

  body: {
    big: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontStyle: 'normal',
      fontWeight: '400',
      letterSpacing: '-0.01em',
      fontSize: '16px',
      lineHeight: '24px',
    },
    bold1: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontStyle: 'normal',
      fontWeight: '700',
      letterSpacing: '-0.01em',
      fontSize: '14px',
      lineHeight: '21px',
    },
    1: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontStyle: 'normal',
      fontWeight: '400',
      letterSpacing: '-0.01em',
      fontSize: '14px',
      lineHeight: '21px',
    },
    // emphasized is same as semibold
    emphasized: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontStyle: 'normal',
      fontWeight: '600',
      letterSpacing: '-0.01em',
      fontSize: '14px',
      lineHeight: '22px',
    },
    semibold: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontStyle: 'normal',
      fontWeight: '600',
      letterSpacing: '-0.01em',
      fontSize: '14px',
      lineHeight: '22px',
    },
    2: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontStyle: 'normal',
      fontWeight: '400',
      letterSpacing: '-0.01em',
      fontSize: '12px',
      lineHeight: '20px',
    },
    createNewSession: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontStyle: 'normal',
      fontWeight: '400',
      letterSpacing: '0',
      fontSize: '12px',
      lineHeight: '22px',
    },
  },

  subtitle: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: '-0.01em',
    fontSize: '14px',
    lineHeight: '22px',
  },
  zeroLetterSpacing: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: '0',
    fontSize: '14px',
    lineHeight: '22px',
  },
  button: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontStyle: 'normal',
    fontWeight: '600',
    letterSpacing: '.75px',
    fontSize: '14px',
    lineHeight: '19px',
    textTransform: 'uppercase',
  },
  caption: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: '-0.01em',
    fontSize: '12px',
    lineHeight: '16px',
  },
  overline: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontStyle: 'normal',
    fontWeight: '700',
    letterSpacing: '1.5px',
    fontSize: '10px',
    lineHeight: '16px',
    textTransform: 'uppercase',
  },
  smallstate: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontStyle: 'normal',
    fontWeight: '600',
    letterSpacing: '0.02em',
    fontSize: '12px',
    lineHeight: '16px',
  },
  // designer using random fonts in new designs
  text: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '25px',
    letterSpacing: '-0.01em',
  },
  text2: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '27.28px',
    letterSpacing: '-0.01em',
  },
  analyticsTableCell: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '-0.01em',
  },
}

export default textStyles
