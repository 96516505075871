import React, { useState, useEffect } from 'react'
import {
  Container,
  Flex,
  VStack,
  HStack,
  IconButton,
  ButtonGroup,
  Box,
  Tooltip,
} from '@chakra-ui/react'
import { Layout } from '../../components/Layout'
import { MdRemoveRedEye } from 'react-icons/md'
import { IoEarOutline } from 'react-icons/io5'
import {
  AuralIcon,
  Kinesthetic,
  Read,
  VisualIcon,
} from '../../components/Icons'
import { Topics, TopicFilter } from '../../components/Topic'
import { Materials } from '../../components/Material'
import { Assignments } from '../../components/Assignment'
import { useLazyQuery, useSubscription } from '@apollo/client'
import { useAlamContext } from '../../client'
import {
  GET_GROUP_TOPICS,
  GET_GROUP_MATERIALS,
  GET_GROUP_ASSIGNMENTS,
  GET_GROUP_PENDING_CLASSWORK_REVIEWS,
} from '../../client/queries'
import { filterMaterials } from '../../utils'
import { routes } from '../../constants'
import { ExamIndicators } from '../../components/Exam'

export type GroupWorkProps = {
  id: string
  classId: string
}

const GroupWorkPage = ({ id, classId }: GroupWorkProps): JSX.Element => {
  const [topics, setTopics] = useState([])
  const [preference, setPreference] = useState<string[]>([])
  const { user, classUser, groups, groupUser } = useAlamContext()
  const [group] = groups
  const [getGroupTopics] = useLazyQuery(GET_GROUP_TOPICS, {
    variables: { group_id: id },
    onCompleted: (data) => {
      const topics = data?.topics?.length || 0
      const newTopicFilter = Array(topics).fill(true)
      setTopicFilter(newTopicFilter)

      setTopics(data?.topics || [])
    },
    fetchPolicy: 'cache-and-network',
  })

  const { data: groupMaterialsData } = useSubscription(GET_GROUP_MATERIALS, {
    variables: { group_id: id },
  })

  const { data: groupAssignmentsData } = useSubscription(
    GET_GROUP_ASSIGNMENTS,
    {
      variables: { group_id: id },
    }
  )

  const { materials } = groupMaterialsData || { materials: [] }
  const { assignments } = groupAssignmentsData || { assignments: [] }

  const materialAssignmentRefetch = () => {}

  const topNavLeft = [{ type: 'text' as const, text: group?.name }]
  const topNavCenter = [
    {
      type: 'link' as const,
      url: `/classes/${classId}/groups/${id}`,
      text: 'Home',
    },
    {
      type: 'link' as const,
      url: `/classes/${classId}/groups/${id}/groupwork`,
      text: 'Group Work',
    },
  ]

  const { data: groupPendingClassReviews } = useSubscription(
    GET_GROUP_PENDING_CLASSWORK_REVIEWS,
    {
      variables: { class_id: classId, group_user_id: groupUser?.id },
    }
  )

  const [topicFilter, setTopicFilter] = useState<boolean[]>([])

  useEffect(() => {
    getGroupTopics()
  }, [])

  useEffect(() => {
    const initialPreference = (classUser?.role === 'student' &&
      user?.learningStyle &&
      user?.learningStyle?.length > 1 &&
      user?.learningStyle) || ['visual', 'aural', 'read', 'kinesthetic']
    setPreference(initialPreference)
  }, [user, classUser])

  const handleTopicFilterOnChange = (checked: boolean, index: number): void => {
    const newTopicFilter = [...topicFilter]
    newTopicFilter[index] = checked
    setTopicFilter(newTopicFilter)
  }

  const handlePreferenceChange = (buttonPreference: string) => {
    if (preference.includes(buttonPreference)) {
      setPreference(preference.filter((value) => value != buttonPreference))
    } else {
      setPreference([...preference, buttonPreference])
    }
  }

  const isPreferenceActive = (buttonPreference: string): string => {
    return preference.includes(buttonPreference)
      ? 'icon.button'
      : 'icon.button.inactive'
  }

  return (
    <Layout
      showSideNav
      topNavLeft={topNavLeft}
      topNavCenter={topNavCenter}
      redirectNonExistingClass
      hasRightNav={routes.groupwork.hasRightNav}
    >
      <Container
        maxW={{ base: '100%', md: '456px', lg: '500px', xl: '668px' }}
        py={6}
        h='auto'
      >
        <Flex direction='column' w='100%' h='100%'>
          {groupPendingClassReviews &&
            groupPendingClassReviews.exams.length > 0 && (
              <ExamIndicators
                exams={groupPendingClassReviews.exams}
                containerProps={{
                  mb: '40px',
                }}
              />
            )}

          <VStack spacing={10} h='100%'>
            <Flex
              w='100%'
              align='center'
              justify='center'
              direction={{ base: 'column', xl: 'row' }}
            >
              <HStack spacing={4} w={{ base: '100%', lg: 'auto' }}>
                <Flex direction={{ base: 'column', sm: 'row' }} w='100%'>
                  <Box
                    py='4px'
                    bg='white.1'
                    layerStyle='card.board'
                    mb={{ base: '16px', md: '0' }}
                    mr={{ base: '0', sm: '16px' }}
                  >
                    <ButtonGroup isAttached size='lg' w='100%'>
                      <Tooltip label='Visual' variant='iconToolTip'>
                        <IconButton
                          aria-label='Select visual learning preference'
                          icon={<VisualIcon />}
                          variant={isPreferenceActive('visual')}
                          borderRight='1px solid'
                          borderColor='primary.2'
                          fontSize='20px'
                          w={{ base: '100%', sm: 'calc(288px/4)' }}
                          h='40px'
                          onClick={() => handlePreferenceChange('visual')}
                        />
                      </Tooltip>
                      <Tooltip label='Aural' variant='iconToolTip'>
                        <IconButton
                          aria-label='Select aural learning preference'
                          icon={<AuralIcon />}
                          variant={isPreferenceActive('aural')}
                          fontSize='20px'
                          borderRight='1px solid'
                          borderColor='primary.2'
                          w={{ base: '100%', sm: 'calc(288px/4)' }}
                          h='40px'
                          onClick={() => handlePreferenceChange('aural')}
                        />
                      </Tooltip>
                      <Tooltip label='Read/Write' variant='iconToolTip'>
                        <IconButton
                          aria-label='Select read learning preference'
                          icon={<Read />}
                          variant={isPreferenceActive('read')}
                          fontSize='20px'
                          borderRight='1px solid'
                          borderColor='primary.2'
                          w={{ base: '100%', sm: 'calc(288px/4)' }}
                          h='40px'
                          onClick={() => handlePreferenceChange('read')}
                        />
                      </Tooltip>
                      <Tooltip label='Kinesthetic' variant='iconToolTip'>
                        <IconButton
                          aria-label='Select kinesthetic learning preference'
                          icon={<Kinesthetic />}
                          variant={isPreferenceActive('kinesthetic')}
                          fontSize='20px'
                          w={{ base: '100%', sm: 'calc(288px/4)' }}
                          h='40px'
                          onClick={() => handlePreferenceChange('kinesthetic')}
                        />
                      </Tooltip>
                    </ButtonGroup>
                  </Box>

                  <TopicFilter
                    topicFilter={topicFilter}
                    setTopicFilter={setTopicFilter}
                    topics={topics}
                    handleTopicFilterOnChange={handleTopicFilterOnChange}
                    indeterminateTitle='Topics'
                    isTopicCount
                  />
                </Flex>
              </HStack>
            </Flex>

            <Flex w='100%' direction='column'>
              <VStack spacing={4} pt={2}>
                <Assignments
                  assignments={assignments}
                  context='groupwork'
                  materialAssignmentRefetch={materialAssignmentRefetch}
                  groupId={id}
                  pendingExams={groupPendingClassReviews?.exams}
                />
                <Materials
                  materials={filterMaterials(materials, preference)}
                  context='groupwork'
                  materialAssignmentRefetch={materialAssignmentRefetch}
                  groupId={id}
                />
              </VStack>
            </Flex>
            <Topics
              topics={topics}
              context='groupwork'
              topicFilter={topicFilter}
              materialAssignmentRefetch={materialAssignmentRefetch}
              preference={preference}
              groupId={id}
              pendingExams={groupPendingClassReviews?.exams}
            />
          </VStack>
        </Flex>
      </Container>
    </Layout>
  )
}

export default GroupWorkPage
