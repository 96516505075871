import { Fragment } from 'react'
import { useHistory } from 'react-router'
import { Container, Box, Flex, Text } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_REVIEW_QUESTIONS_REPORT } from '../../client/queries'
import { Layout } from '../../components/Layout'
import { TopNavItemProps } from '../../components/TopNav'
import { QuestionCard } from '../../components/Analytics'
import { ExamArrangement } from '../../types'
import { RespondentCount } from '../../components/Analytics'
import { buildAnalyticsCrumbs, buildQuestionLinks } from '../../utils'
import { useAlamContext } from '../../client'

const AssignmentAssignmentReviewPage = () => {
  const history = useHistory()
  const params = useParams<{
    id: string
    topic_id: string
    assignment_id: string
  }>()

  const { id, topic_id, assignment_id } = params
  const { classUser } = useAlamContext()

  const { data: reviewQuestionsData, refetch } = useQuery(
    GET_REVIEW_QUESTIONS_REPORT,
    {
      variables: {
        assignment_id: assignment_id,
        type: 'assignment_review',
        class_id: id,
      },
    }
  )

  const {
    totalStudentCount: {
      aggregate: { count: totalCount },
    },
    respondentCount: {
      aggregate: { count: completedCount },
    },
    reviewReport,
    assignment,
  } = reviewQuestionsData || {
    totalStudentCount: { aggregate: { count: 0 } },
    respondentCount: { aggregate: { count: 0 } },
    reviewReport: [],
    assignment: undefined,
  }

  const review = reviewReport[0]
  const exam_arrangements = review?.exam_arrangements || []

  const topNavLeft: TopNavItemProps[] = [
    {
      type: 'breadcrumbs',
      crumbs: buildAnalyticsCrumbs(
        params,
        {
          topicName: assignment?.topic?.name,
          assignmentName: assignment?.name,
          className: classUser?.class?.name,
        },
        'assignment-review'
      ),
    },
  ]

  return (
    <Layout showSideNav topNavLeft={topNavLeft}>
      <Box px={6} pt={6} mb={8}>
        <Text textStyle='h2' color='black.1'>
          Assignment Review
        </Text>
        <RespondentCount
          respondentCount={completedCount}
          totalCount={totalCount}
        />
      </Box>
      {exam_arrangements.map((arrangement: ExamArrangement) => {
        return (
          <QuestionCard
            order={arrangement?.order}
            question={arrangement?.question}
            questionUrl={buildQuestionLinks(
              {
                ...params,
                question_id: arrangement?.question?.id,
              },
              'assignment-review'
            )}
          />
        )
      })}
    </Layout>
  )
}

export default AssignmentAssignmentReviewPage
