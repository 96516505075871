import { useMutation } from '@apollo/client'
import {
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { v4 } from 'uuid'
import { COMPLETE_EXAM_RESPONSE, UPSERT_RESPONSE } from '../../client/queries'
import {
  Answer,
  Exam,
  ExamArrangement,
  Response,
  ExamResponse,
} from '../../types'
import ResponseInput from './ResponseInput'
import { isSpaceOnly, useErrorToast, useSuccessToast } from '../../utils'
import { dbTimestamp } from '../../constants'
import { useAlamContext } from '../../client'

export type ResponseFormProps = {
  isOpen: boolean
  onClose: () => void
  exam?: Exam
}

export const ResponseForm = ({ isOpen, onClose, exam }: ResponseFormProps) => {
  const { user, groupUser } = useAlamContext()
  const [
    currentExamArrangement,
    setCurrentExamArrangement,
  ] = useState<ExamArrangement>()
  const [currentResponse, setCurrentResponse] = useState<Response>()
  const [currentAnswers, setCurrentAnswers] = useState<string[]>([''])
  const handleInputChange = (values: string[]) => setCurrentAnswers(values)

  if (!exam) {
    return null
  }

  const { exam_arrangements, exam_responses } = exam
  // bug in subscription returns exam_responses of other users
  const examResponse = exam_responses?.find(
    (exam_response) =>
      exam_response.user_id === user?.id ||
      exam_response.group_user_id === groupUser?.id
  ) || {
    id: '',
    started_at: '',
    completed_at: '',
    exam_id: '',
    user_id: '',
    group_user_id: '',
    responses: [],
  }

  const [upsertResponse, { loading }] = useMutation(UPSERT_RESPONSE)
  const [completeExamResponse] = useMutation(COMPLETE_EXAM_RESPONSE)

  // Initialize form state
  useEffect(() => {
    if (!examResponse.id || !isOpen) {
      return
    }

    // no responses shown yet
    if (
      examResponse.responses.length === 0 &&
      exam_arrangements.length >= 1 &&
      !currentExamArrangement
    ) {
      createNewResponse(0)
    } else if (exam_arrangements.length >= 1 && !currentExamArrangement) {
      // only get the last answered exam if no exam_arrangement is selected (on mount)
      findNextExamArrangement(1, true)
    }
  }, [isOpen, examResponse, currentExamArrangement, exam_arrangements])

  const createNewResponse = (index: number) => {
    setCurrentExamArrangement(exam_arrangements[index])
    setCurrentResponse({
      id: v4(),
      exam_response_id: examResponse.id,
      question_id: exam_arrangements[index].question.id || '',
      answers: [],
      ...dbTimestamp,
    })
    updateCurrentAnswers([])
  }

  function findNextExamArrangement(index: number, skipAnswered: boolean) {
    const lastIndex = exam_arrangements.length - 1
    if (index > lastIndex && skipAnswered) {
      const questionId = exam_arrangements[lastIndex].question.id
      const existingResponse = examResponse.responses.find(
        (response) => response?.question_id === questionId
      )
      setCurrentExamArrangement(exam_arrangements[lastIndex])
      if (!existingResponse) {
        createNewResponse(lastIndex)
      } else {
        setCurrentResponse(existingResponse)
      }
      updateCurrentAnswers(existingResponse?.answers || [])
    }

    if (index > lastIndex && skipAnswered) {
      return null
    }

    if (!exam_arrangements[index]) {
      return null
    }

    const questionId = exam_arrangements[index].question.id
    const existingResponse = examResponse.responses.find(
      (response) => response?.question_id === questionId
    )

    if (!skipAnswered && existingResponse) {
      setCurrentExamArrangement(exam_arrangements[index])
      setCurrentResponse(existingResponse)
      updateCurrentAnswers(existingResponse.answers)
    }

    if (!skipAnswered && !existingResponse) {
      setCurrentExamArrangement(exam_arrangements[index])
      createNewResponse(index)
      setCurrentAnswers([''])
    }

    if (skipAnswered && existingResponse) {
      findNextExamArrangement(index + 1, skipAnswered)
    }

    if (skipAnswered && !existingResponse) {
      setCurrentExamArrangement(exam_arrangements[index])
      createNewResponse(index)
      setCurrentAnswers([''])
    }
  }

  const buildAnswers = (answers: string[], response_id?: string) => {
    return answers.map((text) => ({
      id: v4(),
      text,
      response_id,
      ...dbTimestamp,
    }))
  }

  const handleSubmitResponse = async () => {
    try {
      if (!currentExamArrangement) {
        return
      }

      if (!currentResponse) {
        return
      }

      const newAnswers = buildAnswers(currentAnswers, currentResponse?.id)

      // remove nested answers from response as we're using separate mutations for response and answers
      // also remove typename
      const { answers, __typename, ...response } = currentResponse

      await upsertResponse({
        variables: {
          response: {
            ...response,
            ...dbTimestamp,
            archived_at: null,
          },
          exam_response_id: examResponse.id,
          user_id: user?.id,
          question_id: currentResponse?.question_id,
          response_id: currentResponse?.id,
          answers: newAnswers,
        },
      })

      const isFinished = findNextExamArrangement(
        currentExamArrangement?.order + 1,
        false
      )

      if (isFinished === null) {
        await completeExamResponse({
          variables: { exam_response_id: examResponse.id },
        })
        onClose()
        useSuccessToast({
          title: 'Review complete',
          message: 'Thank you for completing the review',
        })
      }
    } catch (error) {
      useErrorToast({ error })
    }
  }

  const handleBack = (index: number) => {
    if (index < 0) {
      onClose()
      return null
    }

    if (!exam_arrangements[index]) {
      return null
    }

    const questionId = exam_arrangements[index].question.id
    const existingResponse = examResponse.responses.find(
      (response) => response?.question_id === questionId
    )

    if (existingResponse) {
      setCurrentExamArrangement(exam_arrangements[index])
      setCurrentResponse(existingResponse)
      updateCurrentAnswers(existingResponse.answers)
    } else {
      // should not happen
      createNewResponse(index)
    }
  }

  // Transforms Answers to string[] for form state
  const updateCurrentAnswers = (answers: Answer[]) => {
    const newCurrentAnswers = answers.map(({ text }) => text)
    setCurrentAnswers(newCurrentAnswers)
  }

  const withErrors = () => {
    if (!currentExamArrangement) {
      return
    }
    const {
      question: { type },
    } = currentExamArrangement

    if (type === 'text') {
      return !currentAnswers[0] || isSpaceOnly(currentAnswers[0])
    }

    if (['checkbox', 'multiple_choice'].includes(type)) {
      return currentAnswers.length === 0
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      isCentered
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent w='688px' maxW='688px' p='48px 32px 32px 32px'>
        {currentExamArrangement && (
          <Flex direction='column' w='100%' h='100%'>
            <Flex justify='space-between' w='100%'>
              <Text textStyle='h3' color='primary.1'>{`Question ${
                currentExamArrangement?.order + 1
              }`}</Text>

              <Text textStyle='text2' color='primary.1'>{`${
                currentExamArrangement?.order + 1
              }/${exam_arrangements.length}`}</Text>
            </Flex>

            <Flex my='24px' minH='72px'>
              <Text textStyle='body.big' maxH='316px' overflowY='auto'>
                {currentExamArrangement.question.text}
              </Text>
            </Flex>

            <ResponseInput
              key={currentExamArrangement.order}
              question={currentExamArrangement.question}
              answers={currentAnswers}
              onChange={handleInputChange}
            />

            <Flex mt='24px' justify='flex-end'>
              <Button
                onClick={() => handleBack(currentExamArrangement.order - 1)}
                variant='base.white'
                textStyle='button'
                mr='10px'
                // disabled={currentExamArrangement.order === 0}
              >
                Back
              </Button>

              <Button
                onClick={handleSubmitResponse}
                variant='base.primary'
                textStyle='button'
                isLoading={loading}
                disabled={
                  currentExamArrangement.order > exam_arrangements.length - 1 ||
                  withErrors()
                }
              >
                {currentExamArrangement.order === exam_arrangements.length - 1
                  ? 'Finish'
                  : 'Next'}
              </Button>
            </Flex>
          </Flex>
        )}
      </ModalContent>
    </Modal>
  )
}

export default ResponseForm
