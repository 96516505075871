import React, { useState, useEffect, useRef } from 'react'
import { Input, Tooltip, useDisclosure } from '@chakra-ui/react'
import classnames from 'classnames'

export type NumericalGradeInput = {
  grading?: string
  value?: string
  onChange: (value: string) => void
  disabled?: boolean
  readOnly?: boolean
  inputProps?: Record<string, any>
}

export const NumericalGradeInput = ({
  grading = '',
  value = '',
  onChange,
  disabled = false,
  readOnly,
  inputProps = {},
}: NumericalGradeInput) => {
  const [isFocused, setIsFocused] = useState(false)
  const display = `${value}/${grading}`
  const gradeInputEl = useRef<HTMLInputElement>(null)
  const {
    isOpen: isWarningOpen,
    onOpen: onWarningOpen,
    onClose: onWarningClose,
  } = useDisclosure()

  const resetSelectionRange = () => {
    gradeInputEl?.current?.setSelectionRange(0, value.length)
  }

  useEffect(() => {
    if (isFocused) {
      resetSelectionRange()
    }
  }, [isFocused])

  const flashWarning = () => {
    onWarningOpen()
    resetSelectionRange()

    setTimeout(() => {
      onWarningClose()
      resetSelectionRange()
    }, 2000)
  }

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value
    const grade = newValue.split('/')[0]
    const isValidGrade = validateGrade(grade)

    if (isValidGrade) {
      onChange(grade)
    } else {
      flashWarning()
    }
  }

  const validateGrade = (grade: string) => {
    if (grade === '') {
      return true
    }

    // Just make sure it's actually a number
    return Number(grade) >= 0
  }

  const handleFocus = () => {
    setIsFocused(true)
  }

  return (
    <Tooltip label='Please input numbers only.' isOpen={isWarningOpen}>
      <Input
        pl={classnames({
          '34px': value.length <= 1,
          '29px': value.length === 2,
          '24px': value.length === 3,
          '15px': value.length >= 4,
        })}
        id='numeric-grade'
        ref={gradeInputEl}
        value={display}
        variant='grade'
        onChange={handleOnChange}
        onFocus={handleFocus}
        textStyle='body.1'
        disabled={disabled}
        readOnly={readOnly}
        {...inputProps}
      />
    </Tooltip>
  )
}
