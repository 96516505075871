import React from 'react'
import {
  Center,
  Flex,
  Text,
  VStack,
  Button,
  useMediaQuery,
} from '@chakra-ui/react'
import { PlusCircle } from '../Icons'

export interface NewCardProps {
  title: string
  onClick: () => void
}

export const NewCard = ({ title, onClick }: NewCardProps): JSX.Element => {
  const [isLargerThanMobile] = useMediaQuery('(min-width: 544px)')

  return (
    <>
      {isLargerThanMobile && (
        <Center
          h='216px'
          w={{ base: '100%', sm: '216px' }}
          backgroundImage={`url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23333' stroke-width='1' stroke-dasharray='10%2c 12' stroke-dashoffset='40' stroke-linecap='butt'/%3e%3c/svg%3e");
          border-radius: 8px;`}
          as={Button}
          onClick={onClick}
          variant='blank'
          _hover={{
            backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23333' stroke-width='1' stroke-dasharray='10%2c 12' stroke-dashoffset='40' stroke-linecap='butt'/%3e%3c/svg%3e");
          border-radius: 8px;`,
          }}
        >
          <Flex direction='column'>
            <VStack>
              <PlusCircle />
              <Text
                textStyle='h5'
                color='primary.1'
                letterSpacing='0'
                mt='0 !important'
              >
                {title}
              </Text>
            </VStack>
          </Flex>
        </Center>
      )}
    </>
  )
}

export default NewCard
