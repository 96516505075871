import { Box, Text, Icon, Flex } from '@chakra-ui/react'
import { IconType } from 'react-icons/lib'

export type SidebarHeadingProps = {
  title: string
  bg?: string
  color?: string
  padding?: string
  titleWithIcon?: boolean
  icon?: IconType
  ariaLabel?: string
  onClick?: () => void
  margin?: string
  containerProps?: Record<string, any>
  iconProps?: Record<string, any>
  titleProps?: Record<string, any>
}

export const SidebarHeading = ({
  title,
  bg = 'grey.2',
  color = 'primary.1',
  padding = '8px 16px',
  titleWithIcon = false,
  icon,
  ariaLabel = 'class invite',
  onClick,
  margin = '0',
  containerProps = {},
  iconProps = {},
  titleProps = {},
}: SidebarHeadingProps): JSX.Element => (
  <Box p={padding} bg={bg} m={margin} {...containerProps}>
    {titleWithIcon ? (
      <Flex
        align='center'
        onClick={onClick}
        cursor={titleWithIcon ? 'pointer' : 'auto'}
      >
        <Icon
          as={icon}
          aria-label={ariaLabel}
          maxW='14px'
          minW='14px'
          h='14px'
          mr={3}
          {...iconProps}
        />
        <Text textStyle='caption' color={color} {...titleProps}>
          {title}
        </Text>
      </Flex>
    ) : (
      <Text textStyle='overline' color={color} {...titleProps}>
        {title}
      </Text>
    )}
  </Box>
)

export default SidebarHeading
