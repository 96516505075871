import React, { useState, useEffect, useMemo, Fragment } from 'react'
import {
  Flex,
  Container,
  Menu,
  MenuButton,
  Button,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react'
import { Layout } from '../../components/Layout'
import { IoChevronDown } from 'react-icons/io5'
import { TopNavItemProps } from '../../components/TopNav'
import { GradeCell } from '../../components/Grades'
import { AlamAvatar } from '../../components/Avatar'
import {
  UPDATE_SUBMISSION,
  UPDATE_SUBMISSION_GRADE,
  CREATE_EXAM_RESPONSE,
} from '../../client/queries'
import { useMutation } from '@apollo/client'
import {
  handleUpdateSubmissionGradeProps,
  handleUpdateSubmissionProps,
} from '../Assignment/Submission'
import {
  useErrorToast,
  useSuccessToast,
  getFullName,
  getFirstAndLastInitial,
  gradesSortDisplay,
  checkDueDateFilters,
} from '../../utils'
import { v4 as uuid } from 'uuid'
import { useAlamContext } from '../../client'
import { Grade as GradeType, EventLog } from '../../types'
import { Select } from '../../components/Input'
import { format } from 'date-fns'
import { dateFormat } from '../../constants'
import { Topic } from '../../types'
import { DropdownCheckbox } from '../../components/Input'
import { SubmissionTypes, AssignmentTypes, UserTypes } from './ClassGrades'

export type ClassGradesProps = {
  assignments: AssignmentTypes[]
  users: UserTypes[]
  usersRefetch: () => void
  topics: Topic[]
}

export type Options = {
  display: string
  value: string
}

const TeacherClassGrades = ({
  assignments,
  topics,
  users,
  usersRefetch,
}: ClassGradesProps): JSX.Element => {
  const { classUser, user } = useAlamContext()
  const dueDateOptions = [
    {
      display: 'With Due Date',
      value: 'with_due_date',
    },
    {
      display: 'No Due Date',
      value: 'without_due_date',
    },
    {
      display: 'Past Due Date',
      value: 'past_due',
    },
  ]
  const [selectedSubmission, setSelectedSubmission] = useState<any>()
  const [grade, setGrade] = useState('')
  const [sortBy, setSortBy] = useState('a-z')
  const [topicFilter, setTopicFilter] = useState<Options[]>([])
  const [checkedTopics, setCheckedTopics] = useState<string[]>([])
  const [dueDateFilter, setDueDateFilter] = useState<Options[]>([])
  const [checkedDueDateOptions, setCheckedDueDateOptions] = useState<string[]>(
    []
  )

  const [createExamResponse] = useMutation(CREATE_EXAM_RESPONSE)

  useEffect(() => {
    const topicOptions = topics.map((topic) => ({
      display: topic.name,
      value: topic.id,
    }))

    const initialDueDateOptions = dueDateOptions.map(({ value }) => value)
    const initialTopics = topics.map(({ id }) => id)

    setDueDateFilter(dueDateOptions)
    setTopicFilter(topicOptions)
    setCheckedTopics(initialTopics)
    setCheckedDueDateOptions(initialDueDateOptions)
  }, [topics])

  const learningReview = useMemo(() => {
    if (selectedSubmission) {
      return selectedSubmission?.assignment?.examsForStudents?.find(
        (exam: any) => exam.type === 'learning_review'
      )
    }
    return undefined
  }, [selectedSubmission])

  const examResponseForSubmission = useMemo(() => {
    if (selectedSubmission && learningReview) {
      return learningReview?.exam_responses?.find((exam_response: any) => {
        return exam_response?.user_id === selectedSubmission?.user_id
      })
    }
  }, [learningReview])

  const applyFilters = (assignments: any) => {
    return assignments.filter(
      (assignment: any) =>
        ((assignment.topic && checkedTopics.includes(assignment.topic.id)) ||
          assignment.topic === null) &&
        checkDueDateFilters(assignment, checkedDueDateOptions)
    )
  }

  const applySubmissionFilters = (submissions: SubmissionTypes[]) => {
    return submissions.filter(
      (submission: any) =>
        applyFilters(assignments).includes(submission.assignment) ||
        applyFilters(assignments).length === assignments.length
    )
  }

  const createLearningReview = async (gradeId: string) => {
    if (!gradeId) {
      return
    }

    await createExamResponse({
      variables: {
        params: [
          {
            id: uuid(),
            exam_id: learningReview.id,
            inserted_at: 'NOW()',
            updated_at: 'NOW()',
            started_at: 'NOW()',
            user_id: selectedSubmission?.user_id,
          },
        ],
      },
    })
  }

  useEffect(() => {
    setGrade(selectedSubmission?.grades[0]?.grade || '')
  }, [selectedSubmission?.grades[0]?.grade])

  useEffect(() => {
    updateSelectedSubmission(users)
  }, [users])

  const [updateSubmission, { loading: submissionIsUpdating }] = useMutation(
    UPDATE_SUBMISSION
  )

  const [
    updateSubmissionGrade,
    { loading: submissionGradeIsUpdating },
  ] = useMutation(UPDATE_SUBMISSION_GRADE)

  const handleUpdateSubmission: handleUpdateSubmissionProps = ({
    status,
    gradeId,
  }) => {
    updateSubmission({
      variables: {
        submission_id: selectedSubmission?.id,
        status,
        event_log_id: uuid(),
        user_id: user?.id,
        grade_id: gradeId ? gradeId : null,
      },
    })
      .then(() => {
        if (gradeId && learningReview && !examResponseForSubmission) {
          createLearningReview(gradeId)
        }
        usersRefetch()
        useSuccessToast({ message: 'Assignment was successfully returned' })
      })
      .catch(() => {
        useErrorToast({})
      })
  }

  const handleUpdateSubmissionGrade: handleUpdateSubmissionGradeProps = ({
    grade,
    status,
  }) => {
    updateSubmissionGrade({
      variables: {
        submission_id: selectedSubmission?.id,
        status,
        event_log_id: uuid(),
        user_id: user?.id,
        grade,
        grade_id: uuid(),
      },
    })
      .then(() => {
        usersRefetch()
        useSuccessToast({ message: 'Assignment was successfully graded' })
      })
      .catch(() => {
        useErrorToast({})
      })
  }

  const updateSelectedSubmission = (users: UserTypes[]): void => {
    if (selectedSubmission) {
      const newSubmissions = users.map((user) => user.submissions).flat()
      const updatedSubmission = newSubmissions.find((submission) => {
        return submission.id == selectedSubmission?.id
      })
      setSelectedSubmission(updatedSubmission)
    }
  }
  const currentGrade = selectedSubmission?.grades[0]

  const isGradeButtonDisabled = () => {
    if (!currentGrade?.grade && !grade) {
      return true
    }

    if (currentGrade && currentGrade?.grader_id !== user?.id) {
      return true
    }

    if (grade === currentGrade?.grade) {
      return true
    }

    if (grade === '') return true

    return false
  }

  const isReturnButtonDisabled = () => {
    if (
      !selectedSubmission ||
      ['returned', 'unsubmitted', 'not assigned'].includes(
        selectedSubmission.status
      ) ||
      (currentGrade && currentGrade?.grader_id !== user?.id)
    ) {
      return true
    }
    return false
  }

  const sortFunction = (users: UserTypes[], sortBy: string): UserTypes[] => {
    const alphabeticalUsers = [...users].sort((user1, user2) => {
      if (user1.lastName < user2.lastName) return -1
      if (user1.lastName > user2.lastName) return 1
      return 0
    })

    if (sortBy == 'a-z') return alphabeticalUsers
    if (sortBy == 'z-a') return alphabeticalUsers.reverse()
    return alphabeticalUsers
  }

  const sortOptions = [
    { value: 'a-z', display: 'Alphabetical A-Z' },
    { value: 'z-a', display: 'Alphabetical Z-A' },
  ]

  return (
    <Fragment>
      <Container maxW='100%' py={4}>
        <Flex align='center' justify='space-between'>
          <Flex>
            <HStack spacing={4}>
              <DropdownCheckbox
                isTopicDropdown
                isTruncated
                onChange={setCheckedTopics}
                allTitle='All Topics'
                emptyTitle='None'
                indeterminateTitle='Topics'
                options={topicFilter}
                values={checkedTopics}
                {...{
                  menuButtonProps: {
                    textAlign: 'left',
                    textStyle: 'button',
                    letterSpacing: '0.06rem',
                    w: 'auto',
                    height: '48px',
                    padding: '8px 12px',
                    color: 'primary.1',
                    variant: 'tab.selector',
                    layerStyle: 'card.module',
                  },
                }}
              />

              <Select
                value={sortBy}
                onChange={(value) => setSortBy(value)}
                options={sortOptions}
                menuButtonProps={{
                  textStyle: 'button',
                  variant: 'tab.selector',
                  textAlign: 'left',
                  py: '8px',
                  pr: '8px',
                  w: '196px',
                  layerStyle: 'card.module',
                }}
                menuItemProps={{
                  textStyle: 'body.1',
                  color: 'primary.1',
                  w: '196px',
                  _hover: { bg: 'hover.2', color: 'white.1' },
                }}
                displayTransform={gradesSortDisplay}
              />

              <DropdownCheckbox
                isTopicDropdown
                onChange={setCheckedDueDateOptions}
                allTitle='All Dates'
                emptyTitle='None'
                indeterminateTitle='Due Dates'
                options={dueDateOptions}
                values={checkedDueDateOptions}
                {...{
                  menuButtonProps: {
                    textStyle: 'button',
                    textAlign: 'left',
                    w: 'auto',
                    height: '48px',
                    padding: '8px 12px',
                    color: 'primary.1',
                    variant: 'tab.selector',
                    layerStyle: 'card.module',
                  },
                  selectedDisplayProps: {
                    paddingRight: '16px',
                  },
                }}
              />
            </HStack>
          </Flex>
          <Flex>
            <HStack spacing={4}>
              <Button
                variant='base.primary'
                textStyle='button'
                disabled={isReturnButtonDisabled()}
                isLoading={submissionIsUpdating}
                onClick={() =>
                  handleUpdateSubmission({
                    status: 'returned',
                    gradeId: selectedSubmission?.grades[0]?.id,
                  })
                }
              >
                Return
              </Button>
              <Button
                variant='base.white.disabled'
                textStyle='button'
                disabled={isGradeButtonDisabled()}
                isLoading={submissionGradeIsUpdating}
                onClick={() =>
                  handleUpdateSubmissionGrade({ status: 'graded', grade })
                }
              >
                Save
              </Button>
            </HStack>
          </Flex>
        </Flex>
      </Container>
      <Container w='100%' minW='100%' p='0'>
        <Table bg='white.1' display='inline-block' overflow='auto'>
          <Thead>
            <Tr>
              <Th
                w='258px'
                borderWidth='0.2px'
                borderStyle='solid'
                borderColor='primary.2-7'
                px={4}
                py={6}
                textStyle='h4'
                textTransform='initial'
                color='black.1'
              >
                Students
              </Th>

              {applyFilters(assignments).map((assignment: AssignmentTypes) => (
                <Th
                  borderWidth='0.2px'
                  borderStyle='solid'
                  borderColor='primary.2-7'
                  px={4}
                  py={2}
                  key={assignment.id}
                  maxW='auto'
                >
                  <Flex direction='column'>
                    <Text fontSize='10px' fontWeight='400' color='black.3'>
                      {format(new Date(assignment.updated_at), dateFormat)}
                    </Text>
                    <Text
                      textStyle='smallstate'
                      color='black.2'
                      textTransform='initial'
                      fontSize='14px'
                      lineHeight='20px'
                      letterSpacing='0'
                    >
                      {assignment.name}
                    </Text>
                    <Text
                      fontSize='10px'
                      fontWeight='400'
                      color='black.3'
                      visibility={
                        assignment.rubric === 'numerical' ? 'visible' : 'hidden'
                      }
                    >
                      Out of {assignment.grading}
                    </Text>
                  </Flex>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {sortFunction(users, sortBy).map((user: UserTypes) => (
              <Tr key={user.id}>
                <Td
                  w='258px'
                  borderWidth='0.2px'
                  borderStyle='solid'
                  borderColor='primary.2-7'
                  p={4}
                  h='64px'
                >
                  <Flex align='center'>
                    <HStack spacing={2}>
                      <AlamAvatar
                        size='md'
                        src={user.avatar}
                        name={getFirstAndLastInitial(user)}
                      />
                      <Text textStyle='body.semibold' color='black.1'>
                        {user.firstName} {user.lastName}
                      </Text>
                    </HStack>
                  </Flex>
                </Td>
                {applySubmissionFilters(user.submissions).map(
                  (submission: SubmissionTypes) => (
                    <GradeCell
                      key={submission.id}
                      submission={submission}
                      selectedSubmission={selectedSubmission}
                      setSelectedSubmission={setSelectedSubmission}
                      grade={grade}
                      setGrade={setGrade}
                      onUpdateSubmission={handleUpdateSubmission}
                      link={`/classes/${classUser?.class?.id}/assignments/${submission?.assignment?.id}/submissions`}
                    />
                  )
                )}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Container>
    </Fragment>
  )
}

export default TeacherClassGrades
