import React from 'react'
import ClassPage from './ClassPage'
import { useParams } from 'react-router-dom'

const Class = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  return <ClassPage id={id} />
}

export default Class
