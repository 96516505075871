import { useRef, useEffect, useMemo } from 'react'
import { useSubscription, useMutation } from '@apollo/client'
import { useAlamContext } from '../../client'
import { GET_CLASS_CHAT, READ_MESSAGES, GET_PEOPLE } from '../../client/queries'
import { ClassUser } from '../../types'
import { useSelectedItem, isLargerThanTablet } from '../../utils'

import { Chat } from './Chat'
import { Flex, useTheme, useMediaQuery } from '@chakra-ui/react'

export const ClassChat = (): JSX.Element => {
  const { classUser, user } = useAlamContext()

  const {
    selectedItem: selectedClassUser,
    handleSelectItem: handleSelectClassUser,
    handleUnselectItem: handleUnselectClassUser,
    updateSelectedItem: updateSelectedClassUser,
  } = useSelectedItem<ClassUser>()

  const { breakpoints } = useTheme()
  const [isMobileDevice] = useMediaQuery(`(max-width: ${breakpoints.mobile})`)

  const { data: getPeopleData } = useSubscription(GET_PEOPLE, {
    variables: {
      class_id: classUser?.class?.id,
    },
  })

  const {
    managePeopleStudents,
    managePeopleTeachers,
    managePeopleAdmins,
  } = getPeopleData?.class || {
    managePeopleStudents: [],
    managePeopleTeachers: [],
    managePeopleAdmins: [],
  }

  const { data: getClassChatData } = useSubscription(GET_CLASS_CHAT, {
    variables: { class_id: classUser?.class?.id, current_user_id: user?.id },
  })

  const {
    rawOnlineTeachers,
    rawOnlineStudents,
    currentUser,
    offlineClassUsers,
  } = getClassChatData?.class || {
    rawOnlineTeachers: [],
    rawOnlineStudents: [],
    currentUser: [],
    offlineClassUsers: [],
  }

  const onlineStudents = useMemo(() => {
    if (currentUser[0]?.role === 'student') {
      return [...currentUser, ...rawOnlineStudents]
    }
    return rawOnlineStudents
  }, [currentUser, rawOnlineStudents])

  const onlineTeachers = useMemo(() => {
    if (currentUser[0]?.role !== 'student') {
      return [...currentUser, ...rawOnlineTeachers]
    }
    return rawOnlineTeachers
  }, [currentUser, rawOnlineTeachers])

  useEffect(() => {
    updateSelectedClassUser(offlineClassUsers) ||
      updateSelectedClassUser(onlineStudents) ||
      updateSelectedClassUser(onlineTeachers)

    scrollToBottom(selectedClassUser)
  }, [offlineClassUsers, onlineStudents, onlineTeachers])

  const [handleReadMessages] = useMutation(READ_MESSAGES)

  const messagesEndRef = useRef<HTMLDivElement>(null)
  const scrollHook = <Flex className='scroll-hook' ref={messagesEndRef} />

  const scrollToBottom = (selectedUser?: ClassUser) => {
    if (selectedUser) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })

      handleReadMessages({
        variables: {
          sender_id: selectedUser?.user?.id,
          current_user_id: user?.id,
          class_id: classUser?.class?.id,
        },
      })
    }
  }

  return (
    <>
      {!isMobileDevice && (
        <Chat
          onlineTeachers={onlineTeachers}
          onlineStudents={onlineStudents}
          offlineUsers={offlineClassUsers}
          managePeopleStudents={managePeopleStudents}
          managePeopleTeachers={managePeopleTeachers}
          managePeopleAdmins={managePeopleAdmins}
          selectedClassUser={selectedClassUser}
          updateSelectedClassUser={updateSelectedClassUser}
          scrollToBottom={scrollToBottom}
          handleUnselectClassUser={handleUnselectClassUser}
          handleSelectClassUser={handleSelectClassUser}
          scrollHook={scrollHook}
        />
      )}
    </>
  )
}

export default ClassChat
