import React from 'react'
import { IconAlamProps } from './Type'

export const Pin = ({
  fill,
  width,
  height,
  viewBox,
}: IconAlamProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M24 20V12H25V10H15V12H16V20L14 22V24H19.2V30H20.8V24H26V22L24 20Z'
      fill={fill}
    />
  </svg>
)

Pin.defaultProps = {
  fill: '#17053D',
  width: '40',
  height: '40',
  viewBox: '0 0 40 40',
}
