import { gql } from '@apollo/client'
import { v4 as uuid } from 'uuid'
import { sessionFields, userDisplayFields } from './fragments'

export const GET_USER_SESSIONS = gql`
  query get_class_sessions($class_id: uuid!, $user_id: uuid!) {
    sessions(
      where: {
        class_id: { _eq: $class_id }
        session_users: { user_id: { _eq: $user_id } }
      }
    ) {
      id
      name
    }
  }
`

export const CREATE_SESSION = gql`
  mutation create_session(
    $id: uuid!
    $name: String!
    $description: String
    $class_id: uuid!
    $user_id: uuid!
    $session_user_id: uuid!
  ) {
    insert_sessions_one(
      object: {
        id: $id
        name: $name
        description: $description
        class_id: $class_id
        user_id: $user_id
        inserted_at: "NOW()"
        updated_at: "NOW()"
      }
    ) {
      id
    }

    insert_session_users_one(
      object: {
        id: $session_user_id
        user_id: $user_id
        session_id: $id
        inserted_at: "NOW()"
        updated_at: "NOW()"
      }
    ) {
      id
    }
  }
`

export const INSERT_SESSION_USERS = gql`
  mutation insert_session_users(
    $session_users: [session_users_insert_input!]!
  ) {
    insert_session_users(objects: $session_users) {
      returning {
        id
        user {
          email
          provider
        }
      }
    }
  }
`

// class_id is used inside a nested where
export const GET_SESSION_DETAILS = gql`
  ${sessionFields}
  subscription get_session($session_id: uuid!, $class_id: uuid!) {
    session: sessions_by_pk(id: $session_id) {
      ...sessionFields
    }
  }
`

export const CREATE_LINK = gql`
  mutation create_link(
    $link_id: uuid!
    $name: String!
    $url: String!
    $session_id: uuid!
  ) {
    insert_links_one(
      object: {
        id: $link_id
        name: $name
        url: $url
        session_id: $session_id
        inserted_at: "NOW()"
        updated_at: "NOW()"
      }
    ) {
      id
      name
      url
    }
  }
`

export const UPDATE_LINK = gql`
  mutation update_link($link_id: uuid!, $changes: links_set_input) {
    update_links_by_pk(pk_columns: { id: $link_id }, _set: $changes) {
      id
      name
      url
    }
  }
`

export const SEND_SESSION_MESSAGE = gql`
  mutation send_session_message(
    $id: uuid!
    $class_id: uuid!
    $session_id: uuid!
    $sender_id: uuid!
    $text: String!
    $inserted_at: timestamp
  ) {
    insert_messages_one(
      object: {
        id: $id
        class_id: $class_id
        session_id: $session_id
        sender_id: $sender_id
        text: $text
        inserted_at: $inserted_at
        updated_at: $inserted_at
      }
    ) {
      id
    }
  }
`

export const GET_SESSION_MESSAGES = gql`
  ${userDisplayFields}
  query get_session_messages(
    $session_id: uuid!
    $last_receieved_ts: timestamp!
    $last_received_id: uuid!
    $current_user_id: uuid!
  ) {
    messages(
      where: {
        session_id: { _eq: $session_id }
        inserted_at: { _gte: $last_receieved_ts }
        id: { _neq: $last_received_id }
        sender_id: { _neq: $current_user_id }
      }
      order_by: { inserted_at: asc }
    ) {
      id
      text
      sender {
        ...userDisplayFields
      }
      inserted_at
    }
  }
`

export const SUBSCRIBE_SESSION_MESSAGE = gql`
  subscription subscribe_to_new_session_messages($session_id: uuid!) {
    messages(
      where: { session_id: { _eq: $session_id } }
      order_by: { inserted_at: desc }
      limit: 1
    ) {
      id
    }
  }
`

export const UPDATE_SESSION = gql`
  mutation update_session($session_id: uuid!, $changes: sessions_set_input) {
    update_sessions_by_pk(pk_columns: { id: $session_id }, _set: $changes) {
      id
    }
  }
`

export const DELETE_SESSION_USER = gql`
  mutation delete_session_user($session_user_id: uuid!) {
    delete_session_users_by_pk(id: $session_user_id) {
      id
      user {
        id
        email
        provider
      }
    }
  }
`

export const ARCHIVE_SESSION = gql`
  mutation archive_session($session_id: uuid!) {
    update_sessions_by_pk(
      pk_columns: { id: $session_id }
      _set: { archived_at: "NOW()" }
    ) {
      id
    }
  }
`

export const LEAVE_SESSION = gql`
  mutation leave_session($session_id: uuid!, $user_id: uuid!) {
    delete_session_users(
      where: { user_id: { _eq: $user_id }, session_id: { _eq: $session_id } }
    ) {
      affected_rows
    }
  }
`

export const CREATE_DOCUMENT = gql`
  mutation create_document($document: documents_insert_input!) {
    insert_documents_one(object: $document) {
      id
    }
  }
`

export const DELETE_DOCUMENT = gql`
  mutation delete_document($document_id: uuid!) {
    delete_documents_by_pk(id: $document_id) {
      id
    }
  }
`

export const buildSessionUsers = (userIds: string[], session_id?: string) =>
  userIds.map((user_id) => ({
    id: uuid(),
    session_id,
    user_id,
    inserted_at: 'NOW()',
    updated_at: 'NOW()',
  }))
