import { useEffect } from 'react'
import {
  Flex,
  IconButton,
  Input,
  Text,
  Tooltip,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'
import { MdClose, MdSend } from 'react-icons/md'
import { ClassUser, Message } from '../../types'
import {
  getFullName,
  validateSpaceOnly,
  getFirstAndLastInitial,
  displayRole,
} from '../../utils'
import { AlamAvatar } from '../Avatar'
import { AccountCard } from '../Icons'
import Messages from './Messages'
import { useForm } from 'react-hook-form'
import { compareAsc } from 'date-fns'
import debounce from 'lodash-es/debounce'

type consolidateMessagesProps = {
  receivedMessages?: Message[]
  sentMessages?: Message[]
}

const consolidateMessages = ({
  receivedMessages = [],
  sentMessages = [],
}: consolidateMessagesProps) => {
  const consolidatedMessages = [...receivedMessages, ...sentMessages]

  return consolidatedMessages.sort((messageA, messageB) =>
    compareAsc(new Date(messageA.inserted_at), new Date(messageB.inserted_at))
  )
}

export type MessageInput = {
  text: string
}

export type ChatroomProps = {
  classUser: ClassUser
  handleUnselectClassUser: () => void
  handleSendMessage: (data: MessageInput) => void
  isSubmitting: boolean
  defaultValues?: { text: string }
  scrollToBottom: (selectedUser: ClassUser) => void
  scrollHook: JSX.Element
  userProfileOnOpen: () => void
}

export const Chatroom = ({
  classUser,
  handleUnselectClassUser,
  handleSendMessage,
  isSubmitting = false,
  defaultValues = { text: '' },
  scrollToBottom,
  scrollHook,
  userProfileOnOpen,
}: ChatroomProps): JSX.Element => {
  const { user, role } = classUser
  const name = getFullName(user)

  const {
    register,
    handleSubmit,
    reset,
    trigger,
    formState: { isSubmitSuccessful, isValid },
  } = useForm({
    defaultValues,
    mode: 'onChange',
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ ...defaultValues })
    }
  }, [reset, isSubmitSuccessful, defaultValues])

  useEffect(() => {
    scrollToBottom(classUser)
  }, [])

  return (
    <Flex direction='column' h='auto'>
      <Flex
        w='100%'
        h='64px'
        align='center'
        pl='4px'
        borderBottomColor='grey.border'
        borderBottomStyle='solid'
        borderBottomWidth='.5px'
        pos='sticky'
        top='0'
        zIndex='banner'
        bg='white.1'
      >
        <IconButton
          icon={<MdClose />}
          aria-label='Go back to chat user list'
          variant='icon.button'
          fontSize='1.2rem'
          color='primary.1'
          pr='8px'
          onClick={handleUnselectClassUser}
        />

        <AlamAvatar
          size='md'
          name={getFirstAndLastInitial(user)}
          src={user?.avatar}
          isOnline={!!user?.user_online}
        />

        <Flex ml='8px' direction='column'>
          <Tooltip label={name} aria-label={name}>
            <Text
              maxW='100px'
              minW='100px'
              isTruncated
              color='black.1'
              textStyle='body.1'
            >
              {name}
            </Text>
          </Tooltip>

          <Text textStyle='caption' color='grey.1' textTransform='capitalize'>
            {displayRole(role)}
          </Text>
        </Flex>

        <IconButton
          icon={<AccountCard />}
          aria-label={`View ${name}'s user profile`}
          variant='icon.button'
          color='primary.1'
          justify='flex-end'
          w='100%'
          onClick={userProfileOnOpen}
        />
      </Flex>

      <Messages
        messages={consolidateMessages({
          receivedMessages: user?.messagesReceived,
          sentMessages: user?.messagesSent,
        })}
      />

      {scrollHook}

      <Flex maxW='248px' w='100%' position='fixed' bottom='0px'>
        <form
          style={{ width: '100%' }}
          onSubmit={handleSubmit(handleSendMessage)}
        >
          <InputGroup>
            <Input
              name='text'
              ref={register({
                required: 'Message is a required field',
                validate: {
                  validateSpaceOnly,
                },
              })}
              placeholder='Type your message here...'
              borderColor='grey.border'
              borderWidth='.5px'
              size='lg'
              pl='22px'
              textStyle='body.1'
              w='100%'
              fontSize='14px'
              borderRadius='0'
              h='56px'
              background='rgb(255, 255, 255) none repeat scroll 0% 0%'
              _focus={{
                borderColor: 'grey.border',
                borderWidth: '.5px',
                bg: 'white.1',
              }}
              onChange={debounce(async () => {
                await trigger('text')
              }, 500)}
            />

            <InputRightElement
              h='100%'
              alignItems='center'
              className='right-element'
            >
              <IconButton
                className='icon-button'
                type='submit'
                visibility={isValid ? 'visible' : 'hidden'}
                aria-label='submit post'
                variant='icon.button'
                bg='grey.2'
                icon={<MdSend />}
                isLoading={isSubmitting}
              />
            </InputRightElement>
          </InputGroup>
        </form>
      </Flex>
    </Flex>
  )
}
