import { useEffect } from 'react'
import {
  HStack,
  Box,
  InputGroup,
  Input,
  FormControl,
  FormErrorMessage,
  FormErrorIcon,
  InputRightElement,
  IconButton,
  Textarea,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { User } from '../../types'
import {
  getFullName,
  validateSpaceOnly,
  getFirstAndLastInitial,
} from '../../utils'
import { useForm } from 'react-hook-form'
import { AlamAvatar } from '../Avatar'
import { Send } from '../Icons'

export interface CommentFormProps {
  avatarSize?: string
  placeholder?: string
  user: User
  onSubmit: (data: CommentInput) => void
  isSubmitting?: boolean
  disabled?: boolean
  defaultValues?: CommentInput
  formWrapperProps?: Record<string, any>
  hStackProps?: Record<string, any>
  inputProps?: Record<string, any>
  iconButtonProps?: Record<string, any>
  inputRightElementProps?: Record<string, any>
  formErrorMessageProps?: Record<string, any>
  showAvatar?: boolean
}

export type CommentInput = {
  content: string
}

export const CommentForm = ({
  avatarSize = 'md',
  placeholder = 'Write a comment...',
  user,
  onSubmit,
  isSubmitting = false,
  disabled = false,
  defaultValues = { content: '' },
  showAvatar = true,
  formWrapperProps = {},
  hStackProps = {},
  inputProps = {},
  iconButtonProps = {},
  inputRightElementProps = {},
  formErrorMessageProps = {},
}: CommentFormProps) => {
  const {
    register,
    handleSubmit,
    reset,
    errors,
    watch,
    clearErrors,
    formState: { isSubmitSuccessful },
  } = useForm<CommentInput>({
    mode: 'onChange',
    defaultValues,
  })
  const name = getFullName(user)

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ ...defaultValues })
    }
  }, [reset, isSubmitSuccessful, defaultValues])

  return (
    <FormWrapper {...formWrapperProps}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={!!errors.content?.message}>
          <HStack spacing='16px' {...hStackProps}>
            {showAvatar && (
              <AlamAvatar
                size={avatarSize}
                src={user.avatar}
                name={getFirstAndLastInitial(user)}
                lineHeight='24px'
                letterSpacing='0.04rem'
              />
            )}
            <InputGroup>
              <Textarea
                name='content'
                ref={register({
                  required: 'Content is a required field',
                  maxLength: {
                    value: 1000,
                    message: 'Comment exceeded 1000 maximum characters',
                  },
                  validate: {
                    validateSpaceOnly,
                  },
                })}
                placeholder={placeholder}
                borderColor='grey.border'
                minH='48px'
                h='48px'
                maxH='48px'
                size='lg'
                w='100%'
                css={{
                  '&:focus + .right-element > .icon-button': {
                    visibility: 'visible',
                    background: '#FFFFFF',
                  },
                }}
                fontSize='16px'
                overflow='hidden'
                disabled={disabled}
                lineHeight='48px'
                p='0px 16px'
                resize='none'
                onBlur={() => {
                  clearErrors('content')
                }}
                {...inputProps}
              ></Textarea>

              <InputRightElement
                h='100%'
                pr={4}
                alignItems='center'
                className='right-element'
                {...inputRightElementProps}
              >
                <IconButton
                  className='icon-button'
                  type='submit'
                  visibility='hidden'
                  bg='grey.2'
                  aria-label='submit post'
                  variant='icon.button'
                  icon={<Send width='16' height='16' viewBox='8 8 24 24' />}
                  isLoading={isSubmitting}
                  disabled={disabled}
                  {...iconButtonProps}
                />
              </InputRightElement>
            </InputGroup>
          </HStack>

          {errors.content && (
            <FormErrorMessage ml='48px' {...formErrorMessageProps}>
              <FormErrorIcon />
              {errors.content?.message}
            </FormErrorMessage>
          )}
        </FormControl>
      </form>
    </FormWrapper>
  )
}

export const FormWrapper = styled(Box)`
  width: 100%;
  .form {
    width: 100%;
  }
`
