import { useState, useEffect } from 'react'
import { QuestionPageContentProps } from './QuestionPageContent'
import {
  constructTextQuestionData,
  getFullName,
  usePagination,
  TextQuestionData,
  renderSubmissionDate,
  sortStudentTextQuestionData,
  renderQuestionDate,
} from '../../utils'
import {
  Container,
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  Text,
  HStack,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react'
import { NotEnoughData } from './NotEnoughData'
import { AlamAvatar } from '../../components/Avatar'
import { format } from 'date-fns'

export const TextQuestionContent = ({
  question,
}: QuestionPageContentProps): JSX.Element => {
  const [selectedData, setSelectedData] = useState<
    TextQuestionData | undefined
  >(undefined)
  const textQuestionData = constructTextQuestionData(question)

  const sortedData = sortStudentTextQuestionData(textQuestionData, 'a-z')

  const isEmpty = sortedData?.length === 0

  const onCloseModal = () => {
    setSelectedData(undefined)
  }

  return (
    <>
      <Modal
        onClose={onCloseModal}
        size='2xl'
        isOpen={!!selectedData}
        isCentered
        variant='textQuestionModal'
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction='column'>
              <Flex align='center' mb='24px'>
                <AlamAvatar size='lg' mr={2} src={selectedData?.user?.avatar} />
                <Box>
                  <Text
                    letterSpacing='0.01em'
                    fontSize='16px'
                    color='primary.1'
                  >
                    {getFullName(selectedData?.user)}
                  </Text>
                  <Box>
                    <HStack
                      spacing={2}
                      divider={<Box layerStyle='bulletpoint3' />}
                    >
                      <Text
                        textStyle='subtitle'
                        color='grey.1'
                        fontSize='14px'
                        lineHeight='21px'
                      >
                        Student
                      </Text>
                      {selectedData?.group && (
                        <Text
                          textStyle='subtitle'
                          color='grey.1'
                          fontSize='14px'
                          lineHeight='21px'
                        >
                          {selectedData?.group?.name}
                        </Text>
                      )}
                      <Text
                        textStyle='subtitle'
                        color='grey.1'
                        fontSize='14px'
                        lineHeight='21px'
                      >
                        {renderQuestionDate(
                          selectedData?.answers[0]?.inserted_at
                        )}
                      </Text>
                    </HStack>
                  </Box>
                </Box>
              </Flex>
              <Text
                fontSize='14px'
                lineHeight='21px'
                letterSpacing='0.01em'
                color='black.1'
              >
                {selectedData?.answers[0]?.text}
              </Text>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box>
        <Flex
          w='100%'
          bg='white.1'
          borderWidth='0.5px'
          borderColor='primary.2'
          css={{
            '& > :nth-child(2)': {
              borderLeftWidth: '0.5px',
              borderRightWidth: '0.5px',
              borderColor: 'rgba(23, 5, 61, 0.32)',
            },
          }}
        >
          <Box py={3} px={6} w='258px'>
            <Text
              textStyle='smallstate'
              textTransform='uppercase'
              color='primary.2'
              letterSpacing='0.02em'
            >
              students
            </Text>
          </Box>
          <Box py={3} px={6} w='192px'>
            <Text
              textStyle='smallstate'
              textTransform='uppercase'
              color='primary.2'
              letterSpacing='0.02em'
            >
              submission date
            </Text>
          </Box>
          <Box py={3} px={6} w='auto'>
            <Text
              textStyle='smallstate'
              textTransform='uppercase'
              color='primary.2'
              letterSpacing='0.02em'
            >
              response
            </Text>
          </Box>
        </Flex>

        {isEmpty && (
          <NotEnoughData
            containerProps={{
              w: '100%',
              bg: 'white.1',
              borderColor: 'primary.2',
              borderWidth: '0.5px',
              borderTopWidth: '0px',
              justify: 'center',
              align: 'center',
              h: '416px',
              css: {
                '& > :nth-child(2)': {
                  borderLeftWidth: '0.5px',
                  borderRightWidth: '0.5px',
                  borderColor: 'rgba(23, 5, 61, 0.32)',
                },
              },
            }}
          />
        )}
        {!isEmpty &&
          sortedData?.map((data: TextQuestionData) => (
            <Flex
              w='100%'
              bg='white.1'
              key={data?.id}
              borderColor='primary.2'
              borderWidth='0.5px'
              borderTopWidth='0px'
              css={{
                '& > :nth-child(2)': {
                  borderLeftWidth: '0.5px',
                  borderRightWidth: '0.5px',
                  borderColor: 'rgba(23, 5, 61, 0.32)',
                },
              }}
              onClick={() => {
                setSelectedData(data)
              }}
            >
              <Box py={3} px={6} w='258px'>
                <Flex align='center'>
                  <AlamAvatar size='lg' mr={2} src={data?.user?.avatar} />
                  <Box>
                    <Text
                      textStyle='smallstate'
                      textTransform='uppercase'
                      letterSpacing='0.02em'
                      mb={1}
                      fontSize='14px'
                      color='primary.1'
                    >
                      {getFullName(data?.user)}
                    </Text>
                    <Box>
                      <HStack
                        spacing={2}
                        divider={<Box layerStyle='bulletpoint' />}
                      >
                        <Text
                          textStyle='subtitle'
                          color='grey.1'
                          fontSize='12px'
                        >
                          Student
                        </Text>
                        {data?.group && (
                          <Text
                            textStyle='subtitle'
                            color='grey.1'
                            fontSize='12px'
                          >
                            {data?.group?.name}
                          </Text>
                        )}
                      </HStack>
                    </Box>
                  </Box>
                </Flex>
              </Box>
              <Flex py={3} px={6} w='192px' align='center'>
                <Text
                  textStyle='smallstate'
                  textTransform='uppercase'
                  letterSpacing='0.02em'
                  color='black.1'
                  fontSize='14px'
                >
                  {renderQuestionDate(data?.answers[0]?.inserted_at)}
                </Text>
              </Flex>
              <Flex py={3} px={6} w='auto' flex='1' align='center'>
                <Text
                  textStyle='smallstate'
                  letterSpacing='0.02em'
                  noOfLines={2}
                  color='black.1'
                  fontSize='14px'
                  fontWeight='400'
                >
                  {data?.answers[0]?.text}
                </Text>
              </Flex>
            </Flex>
          ))}
      </Box>
    </>
  )
}
