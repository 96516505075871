import { useCallback } from 'react'
import { useBreakpoint } from '../../utils'

type size = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export type RenderForDevice = {
  sizes?: size[]
  children: any
}

export const RenderForDevice = ({ sizes = [], children }: RenderForDevice) => {
  const breakpoints = useBreakpoint()
  const { xs, sm, md, lg, xl } = breakpoints

  const canRender = useCallback(() => {
    return sizes.find((size) => breakpoints[size])
  }, [xs, sm, md, lg, xl])

  return canRender() ? <>{children}</> : null
}
