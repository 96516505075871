import React, { ReactElement, useState, useEffect } from 'react'
import { Text, IconButton, Flex, VStack, useDisclosure } from '@chakra-ui/react'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import { SideNavCellProps } from './SideNavCell'

export interface GroupHeaderProps {
  children: ReactElement<SideNavCellProps> | ReactElement<SideNavCellProps>[]
  collapsable?: boolean
  text?: string
  isOpen?: boolean
  onOpen?: () => void
  onClose?: () => void
  textFontWeight?: string
  textLetterSpacing?: string
  adjustPaddingBottom?: string | null
  adjustPaddingRight?: number
}

export const GroupHeading = ({
  children,
  collapsable,
  text = '',
  isOpen = true,
  onOpen = () => {},
  onClose = () => {},
  textFontWeight,
  textLetterSpacing = '1.5px',
  adjustPaddingBottom,
  adjustPaddingRight,
}: GroupHeaderProps): JSX.Element => {
  const [previousIsOpen] = useState(isOpen)

  useEffect(() => {
    if (previousIsOpen) {
      onOpen()
    } else {
      onClose()
    }
  }, [])

  return (
    <Flex direction='column' w='100%'>
      {(text || collapsable) && (
        <Flex
          justify='space-between'
          align='center'
          pb={adjustPaddingBottom ? adjustPaddingBottom : 4}
          w='100%'
          pl={5}
          pr={adjustPaddingRight ? adjustPaddingRight : 5}
        >
          {text && (
            <Text
              textStyle='overline'
              textTransform='uppercase'
              color='grey.1'
              fontSize='10px'
              fontWeight={textFontWeight}
              letterSpacing={textLetterSpacing}
            >
              {text}
            </Text>
          )}

          {collapsable && isOpen && (
            <IconButton
              icon={<MdKeyboardArrowUp />}
              colorScheme='ghost'
              color='white.1'
              aria-label='collapse items'
              onClick={onClose}
            />
          )}

          {collapsable && !isOpen && (
            <IconButton
              icon={<MdKeyboardArrowDown />}
              colorScheme='ghost'
              color='white.1'
              aria-label='collapse items'
              onClick={onOpen}
            />
          )}
        </Flex>
      )}

      <VStack w='100%' align='start'>
        {React.Children.map(
          children,
          (child: React.ReactElement<SideNavCellProps>) => {
            return React.cloneElement(child, { showSubItems: isOpen })
          }
        )}
      </VStack>
    </Flex>
  )
}

export default GroupHeading
