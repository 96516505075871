import React, { useRef, useState } from 'react'
import {
  Avatar,
  Box,
  Flex,
  HStack,
  Button,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  StackDivider,
  VStack,
  useDisclosure,
} from '@chakra-ui/react'
import { PostHeading } from '../Heading'
import { Assessment as AssessmentType } from '../../types'
import { useAlamContext } from '../../client'
import { getFullName, useErrorToast, useSuccessToast } from '../../utils'
import styled from '@emotion/styled'
import {
  MdSend,
  MdChatBubbleOutline,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from 'react-icons/md'
import { CommentList } from './../../components/Post'
import { CommentForm } from './../../components/Forms'
import { RenderForUser } from '../SharedComponents'
import { useMutation } from '@apollo/client'
import { CREATE_FEEDBACK_COMMENT } from '../../client/queries'
import { v4 as uuid } from 'uuid'

export interface AssessmentProps {
  assessment: AssessmentType
}

const Assessment = ({ assessment }: AssessmentProps) => {
  const currentUser = useAlamContext().user
  const { user, inserted_at } = assessment
  const { firstName, lastName, avatar } = user
  const fullName = getFullName({ firstName, lastName })
  // const inputRef = useRef<HTMLInputElement>(null)
  const [createComment, { loading: commentIsSubmitting }] = useMutation(
    CREATE_FEEDBACK_COMMENT
  )

  const {
    isOpen: repliesIsOpen,
    onClose: repliesOnClose,
    onOpen: repliesOnOpen,
  } = useDisclosure()

  const handleSubmitComment = ({ content }: { content: string }) => {
    createComment({
      variables: {
        id: uuid(),
        content,
        user_id: currentUser.id,
        feedback_id: assessment.id,
      },
    })
      .then(() => {
        useSuccessToast({ message: 'Comment was created' })
      })
      .catch((error) => {
        useErrorToast({ message: 'Creating comment failed' })
      })
  }

  const FormWrapper = styled(Box)`
    width: 100%;
    .form {
      width: 100%;
    }
  `

  const commentNumber = assessment.comments?.length
  const allowedRoles = assessment.is_anonymous
    ? ['teacher', 'admin', 'creator']
    : ['teacher', 'admin', 'student', 'creator']

  return (
    <>
      <RenderForUser roles={allowedRoles} userIds={[user.id]}>
        <Flex
          w='100%'
          paddingTop='16px'
          direction='column'
          borderTop='1px solid'
          borderColor='grey.border'
          marginTop='0px !important'
        >
          <Flex position='relative' px={4} align='center'>
            {assessment.is_anonymous ? (
              <PostHeading type='anonymous' inserted_at={inserted_at} />
            ) : (
              <PostHeading
                type='comment'
                firstName={user.firstName}
                lastName={user.lastName}
                avatar={user.avatar}
                inserted_at={inserted_at}
              />
            )}
          </Flex>
          <Flex maxWidth='100%' pl={6} pt={2} pr={4} pb={4}>
            <Text textStyle='subtitle' maxWidth='100%'>
              {assessment.content}
            </Text>
          </Flex>
          {!repliesIsOpen ? (
            <Flex w='100%' direction='row' pl='16px' pr='8px'>
              {commentNumber > 0 && (
                <Button
                  leftIcon={
                    <Icon as={MdKeyboardArrowDown} width='16px' height='16px' />
                  }
                  variant='icon.button'
                  textStyle='overline'
                  textTransform='uppercase'
                  fontSize='10px'
                  fontWeight='600'
                  letterSpacing='0.1rem'
                  onClick={repliesOnOpen}
                >
                  {`show ${commentNumber} comment${
                    commentNumber > 1 ? 's' : ''
                  }`}
                </Button>
              )}
              <Box flexGrow={1}></Box>
              <Button
                rightIcon={<Icon as={MdChatBubbleOutline} boxSize='12px' />}
                variant='icon.button'
                textStyle='overline'
                textTransform='uppercase'
                fontSize='10px'
                onClick={repliesOnOpen}
              >
                Reply
              </Button>
            </Flex>
          ) : (
            <Flex
              w='100%'
              direction='column'
              bg='rgb(246, 246, 246) none repeat scroll 0% 0%'
            >
              {commentNumber === 0 && (
                <Flex
                  w='100%'
                  pt={2}
                  px='16px'
                  borderTop='1px solid'
                  borderColor='grey.border'
                  marginTop='0px !important'
                >
                  <Button
                    leftIcon={<MdKeyboardArrowUp fontSize='1rem' />}
                    variant='icon.button'
                    background='rgb(246, 246, 246) none repeat scroll 0% 0%'
                    _hover={{
                      bg: 'rgb(246, 246, 246) none repeat scroll 0% 0%',
                    }}
                    textStyle='overline'
                    textTransform='uppercase'
                    pl={3}
                    onClick={repliesOnClose}
                    fontSize='10px'
                    fontWeight='600'
                    letterSpacing='0.1rem'
                  >
                    hide chat
                  </Button>
                </Flex>
              )}
              <Flex direction='column'>
                {assessment.comments?.length >= 1 && (
                  <Flex w='100%' marginTop='0px !important'>
                    <CommentList
                      comments={assessment.comments}
                      isExpanded={repliesIsOpen}
                      customOnClose={repliesOnClose}
                      textProps={{ fontSize: '14px', paddingLeft: 2 }}
                      leftIconProps={{ fontSize: '1rem' }}
                      buttonProps={{
                        paddingLeft: '0.25rem',
                        fontWeight: '600',
                        letterSpacing: '0.1rem',
                      }}
                    />
                  </Flex>
                )}
                <CommentForm
                  user={currentUser}
                  onSubmit={handleSubmitComment}
                  isSubmitting={commentIsSubmitting}
                  showAvatar={false}
                  placeholder='Write a comment.'
                  iconButtonProps={{
                    visibility: 'visible',
                    bg: 'none',
                  }}
                  inputRightElementProps={{
                    h: '100%',
                    alignItems: 'center',
                    className: 'right-element',
                    pr: '0px',
                    mr: '-6.8px',
                  }}
                  inputProps={{
                    background: 'rgb(246, 246, 246) none repeat scroll 0% 0%',
                    border: 'none',
                    overflow: 'hidden',
                    fontSize: '16px',
                    lineHeight: '24px',
                    minH: '24px',
                    h: '24px',
                    maxH: '24px',
                    p: '0px',
                    css: {
                      '&[aria-invalid="true"]:focus': {
                        outline: 'none',
                        border: 'none',
                        background:
                          'rgb(246, 246, 246) none repeat scroll 0% 0%',
                      },
                      '&:focus + .right-element > .icon-button': {
                        visibility: 'visible',
                        background: 'none',
                      },
                      '&:focus': {
                        outline: 'none',
                        border: 'none',
                        background:
                          'rgb(246, 246, 246) none repeat scroll 0% 0%',
                      },
                    },
                  }}
                  hStackProps={{
                    spacing: 4,
                    w: '100%',
                  }}
                  formWrapperProps={{
                    padding: '12px 8px 12px 16px',
                    borderTop: '1px solid',
                    borderColor: 'rgba(23, 5, 61, 0.1)',
                    marginTop: '0px !important',
                  }}
                  formErrorMessageProps={{
                    ml: '0px',
                  }}
                />
              </Flex>
            </Flex>
          )}
        </Flex>
      </RenderForUser>
    </>
  )
}

export default Assessment
