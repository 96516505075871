import {
  HeaderData,
  StudentAnalyticsTableHeading,
  AnalyticsUserData,
  AnalyticsAssignmentData,
  AnalyticsTopicData,
  AnalyticsMaterialData,
  AnalyticsExamData,
  AnalyticsGroupData,
  constructMaterialHeading,
  constructAssignmentHeadings,
  formatTopicData,
  formatDuration,
  getAssignmentUser,
  getAssignmentGroup,
  getSubmission,
  getGroupSubmission,
} from './studentAnalytics'
import {
  getSubmittedEventLog,
  getEventLog,
  renderCompletionTime,
} from './completionAnalytics'
import { differenceInMilliseconds } from 'date-fns'

export const constructCSVTableHeadings = (
  headerData: Partial<HeaderData>
): StudentAnalyticsTableHeading[] => {
  if (!headerData) {
    return []
  }

  const { materials = [], assignments = [], topics = [] } = headerData

  const noTopicMaterialHeadings = materials.map(
    (material): StudentAnalyticsTableHeading =>
      constructCSVMaterialHeading(material, undefined)
  )

  const noTopicAssignmentHeadings = assignments.flatMap((assignment) =>
    constructCSVAssignmentHeadings(assignment, undefined)
  )

  const topicHeadings = topics.flatMap((topic) => {
    const { id, name, pretopics, materials, assignments } = topic
    const pretopic = pretopics[0]
    const pretopicHeading = pretopic
      ? constructCSVPretopicHeading(pretopic, topic)
      : []

    const topicMaterialHeadings = materials.map((material) =>
      constructCSVMaterialHeading(material, topic)
    )

    const topicAssignmentHeadings = assignments.flatMap((assignment) =>
      constructCSVAssignmentHeadings(assignment, topic)
    )

    return [
      ...pretopicHeading,
      ...topicMaterialHeadings,
      ...topicAssignmentHeadings,
    ]
  })

  return [
    ...noTopicMaterialHeadings,
    ...noTopicAssignmentHeadings,
    ...topicHeadings,
  ]
}

export const formatCSVHeading = (
  headings: StudentAnalyticsTableHeading[]
): any => {
  const titleArray = headings.map(({ text }) => `"${text}"`)
  return titleArray.join(',')
}

const constructCSVAssignmentHeadings = (
  assignment: AnalyticsAssignmentData,
  topic?: AnalyticsTopicData
): StudentAnalyticsTableHeading[] => {
  const {
    id,
    name,
    rubric,
    grading,
    exams,
    assignment_users_aggregate,
    assignment_groups_aggregate,
  } = assignment
  const hasGroup = assignment_groups_aggregate.aggregate.count > 0
  const hasIndividual = assignment_users_aggregate.aggregate.count > 0
  const assignmentReview = exams.find(
    (exam) => exam.type === 'assignment_review'
  )
  const learningReview = exams.find((exam) => exam.type === 'learning_review')

  const assignmentCompletionHeading = {
    id,
    type: 'ASSIGNMENT_COMPLETION_TIME',
    text: `${topic ? topic.name + ' | ' : ''}${name} Completion Time`,
    topic: formatTopicData(topic),
    subtext: 'HOURS',
    hasGroup,
    hasIndividual,
    assignment,
  }

  const assignmentScoreHeading = {
    id,
    type: 'ASSIGNMENT_SCORE',
    text: `${topic ? topic.name + ' | ' : ''}${name} Score`,
    topic: formatTopicData(topic),
    subtext: rubric === 'numerical' ? `OUT OF ${grading}` : undefined,
    hasGroup,
    hasIndividual,
    assignment,
  }

  const assignmentReviewHeading = assignmentReview
    ? [
        {
          id,
          type: 'ASSIGNMENT_REVIEW',
          text: `${topic ? topic.name + ' | ' : ''}${name} Review`,
          topic: formatTopicData(topic),
          subtext: 'FEEDBACK',
          assignment,
        },
      ]
    : []

  const learningReviewHeading = learningReview
    ? [
        {
          id,
          type: 'LEARNING_REVIEW',
          text: `${topic ? topic.name + ' | ' : ''}${name} Review`,
          topic: formatTopicData(topic),
          subtext: 'FEEDBACK',
          assignment,
        },
      ]
    : []

  return [
    assignmentCompletionHeading,
    assignmentScoreHeading,
    ...assignmentReviewHeading,
    ...learningReviewHeading,
  ]
}

export const constructCSVMaterialHeading = (
  {
    id,
    title,
    material_users_aggregate,
    material_groups_aggregate,
  }: AnalyticsMaterialData,
  topic?: AnalyticsTopicData
): StudentAnalyticsTableHeading => {
  const hasGroup = material_groups_aggregate.aggregate.count > 0
  const hasIndividual = material_users_aggregate.aggregate.count > 0
  return {
    id,
    type: 'MATERIAL_COMPLETION_TIME',
    text: `${topic ? topic.name + ' | ' : ''}${title} View Time`,
    topic: formatTopicData(topic),
    subtext: 'HOURS',
    hasGroup,
    hasIndividual,
  }
}

const constructCSVPretopicHeading = (
  pretopic: AnalyticsAssignmentData,
  topic: AnalyticsTopicData
): StudentAnalyticsTableHeading[] => {
  const { id, name, rubric, grading } = pretopic
  const assignmentCompletionHeading = {
    id,
    type: 'ASSIGNMENT_COMPLETION_TIME',
    text: `${topic.name} Pretopic | ${name} Completion Time`,
    topic: formatTopicData(topic),
    subtext: 'HOURS',
    hasGroup: false,
    hasIndividual: true,
    assignment: pretopic,
  }

  const assignmentScoreHeading = {
    id,
    type: 'ASSIGNMENT_SCORE',
    text: `${topic.name} Pretopic | ${name} Score`,
    topic: formatTopicData(topic),
    subtext: rubric === 'numerical' ? `OUT OF ${grading}` : undefined,
    hasGroup: false,
    hasIndividual: true,
    assignment: pretopic,
  }

  return [assignmentCompletionHeading, assignmentScoreHeading]
}

export const renderCSVStudentCellContent = (
  student: AnalyticsUserData,
  heading: StudentAnalyticsTableHeading
): string => {
  switch (heading.type) {
    case 'ASSIGNMENT_COMPLETION_TIME':
      return buildCSVCompletionTimeCellContent(student, heading)
    case 'ASSIGNMENT_SCORE':
      return buildCSVScoreCellContent(student, heading)
    case 'ASSIGNMENT_REVIEW':
      return buildCSVReviewContent(student, heading)
    case 'LEARNING_REVIEW':
      return buildCSVReviewContent(student, heading)
    case 'MATERIAL_COMPLETION_TIME':
      return buildCSVMaterialCompletionTimeCellContent(student, heading)
    default:
      return 'Not Assigned'
  }
}

export const buildCSVReviewContent = (
  student: AnalyticsUserData,
  heading: StudentAnalyticsTableHeading
): string => {
  const assignmentUser = getAssignmentUser(student, heading)

  if (!assignmentUser) {
    return 'Not Assigned'
  }

  const { topic, id, assignment } = heading

  const examType = heading.type.toLowerCase().replace('_', '-')

  const reviewURL = topic
    ? `/classes/${assignment?.class_id}/analytics/class/topic/${topic?.id}/assignment/${assignment?.id}/${examType}`
    : `/classes/${assignment?.class_id}/analytics/class/assignment/${assignment?.id}/${examType}`

  return `"${window.location.host + reviewURL}"`
}

export const buildCSVMaterialCompletionTimeCellContent = (
  student: AnalyticsUserData,
  heading: StudentAnalyticsTableHeading
): string => {
  const materialUser = student?.material_users?.find(({ material_id }) => {
    return heading.id === material_id
  })

  if (!materialUser) {
    return 'Not Assigned'
  }

  const viewTime = materialUser?.view_time

  return `"${formatDuration(viewTime)}"`
}

export const buildCSVScoreCellContent = (
  student: AnalyticsUserData,
  heading: StudentAnalyticsTableHeading
): string => {
  const assignmentUser = getAssignmentUser(student, heading)

  if (!assignmentUser) {
    return 'Not Assigned'
  }

  const userSubmission = getSubmission(student, heading)
  const grade = userSubmission?.grades[0]?.grade

  if (!grade) {
    return 'N/A'
  }

  return `"${grade}"`
}

export const buildCSVCompletionTimeCellContent = (
  student: AnalyticsUserData,
  heading: StudentAnalyticsTableHeading
): string => {
  const assignmentUser = getAssignmentUser(student, heading)

  if (!assignmentUser) {
    return 'Not Assigned'
  }

  const userSubmission = getSubmission(student, heading)
  if (!userSubmission) {
    return 'N/A'
  }
  const submittedEventLog = getSubmittedEventLog(userSubmission?.event_logs)

  if (!submittedEventLog) {
    return 'N/A'
  }

  const rawStartDate =
    assignmentUser?.viewed_at ||
    getEventLog(userSubmission.event_logs, 'started')?.inserted_at ||
    userSubmission.inserted_at!

  const dateEnd = Date.parse(submittedEventLog.inserted_at)
  const dateStart = Date.parse(rawStartDate)
  const completionTime = differenceInMilliseconds(dateEnd, dateStart)

  return `"${formatDuration(completionTime)}"`
}

export const renderCSVGroupCellContent = (
  group: AnalyticsGroupData,
  heading: StudentAnalyticsTableHeading
): string => {
  switch (heading.type) {
    case 'ASSIGNMENT_COMPLETION_TIME':
      return buildCSVCompletionTimeGroupCellContent(group, heading)
    case 'ASSIGNMENT_SCORE':
      return buildCSVScoreGroupCellContent(group, heading)
    case 'ASSIGNMENT_REVIEW':
      return buildCSVReviewGroupCellContent(group, heading)
    case 'LEARNING_REVIEW':
      return buildCSVReviewGroupCellContent(group, heading)
    case 'MATERIAL_COMPLETION_TIME':
      return buildCSVMaterialCompletionTimeGroupCellContent(group, heading)
    default:
      return 'Not Assigned'
  }
}

export const buildCSVReviewGroupCellContent = (
  group: AnalyticsGroupData,
  heading: StudentAnalyticsTableHeading
): string => {
  const assignmentGroup = getAssignmentGroup(group, heading)

  if (!assignmentGroup) {
    return 'Not Assigned'
  }

  const { topic, id, assignment } = heading

  const examType = heading.type.toLowerCase().replace('_', '-')

  const reviewURL = topic
    ? `/classes/${assignment?.class_id}/analytics/class/topic/${topic?.id}/assignment/${assignment?.id}/${examType}`
    : `/classes/${assignment?.class_id}/analytics/class/assignment/${assignment?.id}/${examType}`

  return `"${window.location.host + reviewURL}"`
}

export const buildCSVMaterialCompletionTimeGroupCellContent = (
  group: AnalyticsGroupData,
  heading: StudentAnalyticsTableHeading
): string => {
  const materialGroup = group?.material_groups?.find(({ material_id }) => {
    return heading.id === material_id
  })

  if (!materialGroup) {
    return 'Not Assigned'
  }

  const viewTime = materialGroup?.view_time

  return `"${formatDuration(viewTime)}"`
}

export const buildCSVScoreGroupCellContent = (
  group: AnalyticsGroupData,
  heading: StudentAnalyticsTableHeading
): string => {
  const assignmentGroup = getAssignmentGroup(group, heading)

  if (!assignmentGroup) {
    return 'Not Assigned'
  }

  const groupSubmission = getGroupSubmission(group, heading)

  const grade = groupSubmission?.grades[0]?.grade

  if (!grade) {
    return 'N/A'
  }

  return `"${grade}"`
}

export const buildCSVCompletionTimeGroupCellContent = (
  group: AnalyticsGroupData,
  heading: StudentAnalyticsTableHeading
): string => {
  const assignmentGroup = getAssignmentGroup(group, heading)

  if (!assignmentGroup) {
    return 'Not Assigned'
  }

  const groupSubmission = getGroupSubmission(group, heading)
  if (!groupSubmission) {
    return 'N/A'
  }
  const submittedEventLog = getSubmittedEventLog(groupSubmission?.event_logs)

  if (!submittedEventLog) {
    return 'N/A'
  }

  const rawStartDate =
    assignmentGroup?.viewed_at ||
    getEventLog(groupSubmission.event_logs, 'started')?.inserted_at ||
    groupSubmission.inserted_at!

  if (!rawStartDate) {
    return 'N/A'
  }

  const dateEnd = Date.parse(submittedEventLog.inserted_at)
  const dateStart = Date.parse(rawStartDate)
  const completionTime = differenceInMilliseconds(dateEnd, dateStart)

  return `"${formatDuration(completionTime)}"`
}
