import React, { useEffect, useState } from 'react'
import { ShowMessageModal } from './index'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_CLASS } from '../../client/queries'

export type NonExistentClassProps = {
  id: string
}

export const NonExistentClass = ({
  id,
}: NonExistentClassProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  const history = useHistory()
  const { data, loading } = useQuery(GET_CLASS, {
    variables: { class_id: id },
  })
  const currentClass = data?.classes[0]

  useEffect(() => {
    if (!currentClass && !loading) {
      setIsOpen(true)
    }
  }, [currentClass, loading])

  const redirectToHomePage = () => {
    history.push('/')
  }

  return (
    <ShowMessageModal
      isOpen={isOpen}
      onClose={redirectToHomePage}
      title='Class does not exist'
      message={`We can't seem to find this classroom!\nPlease contact the person who invited you to the classroom for details`}
    />
  )
}

export default NonExistentClass
