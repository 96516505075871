import { useEffect } from 'react'
import {
  Flex,
  Modal,
  ModalHeader,
  ModalContent,
  ModalBody,
  ModalOverlay,
  ModalFooter,
  FormControl,
  Checkbox,
  Stack,
  HStack,
  VStack,
  StackDivider,
  Button,
  Text,
} from '@chakra-ui/react'
import { useAlamContext } from '../../client'
import {
  getFullName,
  getFirstAndLastInitial,
  getClassUserRole,
} from '../../utils'
import { SmallAvatarWrapper } from '../../components/Styled'
import { AlamAvatar } from '../Avatar'
import { GET_CLASS_USERS } from '../../client/queries'
import { useForm } from 'react-hook-form'
import { useSubscription } from '@apollo/client'

export type GroupMembersModalProps = {
  isOpen: boolean
  classId: string
  groupId: string
  onSubmit: (data: any) => void
  onClose: () => void
}

export const GroupMembersModal = ({
  isOpen,
  classId,
  onSubmit,
  onClose,
  groupId,
}: GroupMembersModalProps) => {
  const { user: currentUser } = useAlamContext()
  const { data } = useSubscription(GET_CLASS_USERS, {
    variables: {
      class_id: classId || '',
      group_id: groupId,
    },
  })

  const users =
    data?.class_users.filter(
      (classUser: any) => classUser.user.id !== currentUser.id
    ) || []
  const { handleSubmit, register, watch } = useForm<any>()

  const isGroupMembersEmpty = () => {
    const data = watch()

    return !data?.user_ids
  }

  const isChoicesEmpty = () => {
    return users.length === 0
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size='md' isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textStyle='h3'>Add Group Members</ModalHeader>

          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody padding='8px 24px 12px'>
              <Stack
                bg='grey.2'
                borderRadius='12px'
                justify='start'
                padding='16px'
                height={274}
                divider={<StackDivider borderColor='grey.border' />}
                overflow='auto'
                css={{
                  '&::-webkit-scrollbar': {
                    width: '0px',
                  },
                }}
              >
                {users.length >= 1 ? (
                  users.map((classUser: any) => {
                    const name = getFullName(classUser.user)
                    return (
                      <Checkbox
                        h={46}
                        name='user_ids'
                        ref={register()}
                        value={classUser.user.id}
                        padding='16px'
                        key={classUser.user.id}
                      >
                        <HStack h='32px'>
                          <AlamAvatar
                            size='md'
                            src={classUser.user.avatar}
                            name={getFirstAndLastInitial(classUser.user)}
                          />
                          <Stack spacing='0'>
                            <Text textStyle='body.semibold'>{name}</Text>
                            <Text
                              textTransform='capitalize'
                              textStyle='caption'
                              color='grey.1'
                            >
                              {getClassUserRole(classUser)}
                            </Text>
                          </Stack>
                        </HStack>
                      </Checkbox>
                    )
                  })
                ) : (
                  <Flex
                    textStyle='body.big'
                    textAlign='center'
                    h='100%'
                    px={2}
                    align='center'
                    color='black.3'
                  >
                    You have no instructors/students in your classroom to assign
                    to a group yet.
                  </Flex>
                )}
              </Stack>
            </ModalBody>
            <ModalFooter pb={6}>
              <HStack spacing={4}>
                <Button
                  variant='base.white'
                  onClick={onClose}
                  textStyle='button'
                >
                  Cancel
                </Button>
                <Button
                  variant='base.primary'
                  type='submit'
                  textStyle='button'
                  loadingText='Inviting'
                  isDisabled={isGroupMembersEmpty() || isChoicesEmpty()}
                >
                  Add
                </Button>
              </HStack>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
}
