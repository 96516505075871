import React, { useState, useEffect } from 'react'
import { useSubscription, useMutation } from '@apollo/client'
import {
  GET_ASSIGNMENT,
  UPDATE_SUBMISSION,
  GET_GROUP_PENDING_ASSIGNMENT_REVIEWS,
  GET_PENDING_ASSIGNMENT_REVIEWS,
  UPDATE_INDIVIDUAL_VIEWED_AT,
  UPDATE_GROUP_VIEWED_AT,
} from '../../client/queries'
import { BiChevronLeft } from 'react-icons/bi'
import FeedbackPage from './Feedback'
import SubmissionPage from './Submission'
import Assignment from './Assignment'
import { Route, useRouteMatch } from 'react-router'
import { v4 as uuid } from 'uuid'
import { useHistory } from 'react-router-dom'
import {
  Exam,
  Assignment as AssignmentOriginalType,
  User,
  Comment,
  Submission,
  Attachment,
  Assessment,
} from '../../types'
import { useAlamContext } from '../../client'

export type AssignmentPageProps = {
  id: string
  assignmentId: string
  groupId?: string
}

export interface AssignmentType {
  id: string
  name: string
  description?: string
  schedule: string
  dueDate?: string
  isVisible: boolean
  rubric: string
  type?: string
  grading: string
  user: User
  inserted_at: string
  comments: Comment[]
  assessments: Assessment[]
  submissions?: Submission[]
  class: {
    subject?: string
    name: string
    id: string
  }
  topic?: {
    name: string
  }
  attachments: Attachment[]
  examsForStudents?: Exam[]
  examsForGroups?: Exam[]
  examsForTeachers?: Exam[]
}

const AssignmentPage = ({
  id,
  assignmentId,
  groupId,
}: AssignmentPageProps): JSX.Element => {
  const { groupUser, user, classUser } = useAlamContext()
  if (groupId) {
    const [updateGroupViewedAt] = useMutation(UPDATE_GROUP_VIEWED_AT)

    updateGroupViewedAt({
      variables: {
        assignment_id: assignmentId,
        group_id: groupId,
      },
    })
  } else {
    const [updateIndividualViewedAt] = useMutation(UPDATE_INDIVIDUAL_VIEWED_AT)

    updateIndividualViewedAt({
      variables: {
        assignment_id: assignmentId,
        user_id: user?.id,
      },
    })
  }
  const [pendingExams, setPendingExams] = useState<Exam[]>([])
  const [submission, setSubmission] = useState<Submission>()
  const [assignment, setAssignment] = useState<AssignmentType>({
    id: '',
    name: '',
    description: '',
    user: {
      id: '',
      email: '',
      firstName: '',
      lastName: '',
      avatar: '',
    },
    submissions: [],
    schedule: '',
    isVisible: true,
    inserted_at: '',
    comments: [],
    class: {
      subject: '',
      name: '',
      id: '',
    },
    assessments: [],
    grading: '',
    rubric: '',
    topic: {
      name: '',
    },
    attachments: [],
    examsForStudents: [],
    examsForGroups: [],
    examsForTeachers: [],
  })

  const { loading, error } = useSubscription(GET_ASSIGNMENT, {
    variables: { id: assignmentId, user_id: user.id },
    onSubscriptionData: ({
      subscriptionData: {
        data: { assignments_by_pk },
      },
    }) => {
      setAssignment(assignments_by_pk)
      setSubmission(assignments_by_pk.submissions[0])
    },
  })
  const [updateSubmission] = useMutation(UPDATE_SUBMISSION)

  useEffect(() => {
    if (submission && submission?.event_logs?.length < 1) {
      const status = 'started'
      updateSubmission({
        variables: {
          submission_id: submission?.id,
          status,
          event_log_id: uuid(),
          user_id: user?.id,
          group_id: groupId,
        },
      })
    }
  }, [])

  const backUrl = groupId
    ? `/classes/${id}/groups/${groupId}/groupwork`
    : `/classes/${id}/classwork`

  const history = useHistory()
  const topNavLeft = [
    {
      type: 'textWithIconButton' as const,
      text: assignment?.topic?.name || assignment?.name,
      icon: <BiChevronLeft />,
      onClick: () => history.push(backUrl),
    },
  ]
  const { path, url } = useRouteMatch()
  const assignmentNav = [
    {
      type: 'link' as const,
      url,
      text: 'Assignment',
    },
    {
      type: 'link' as const,
      url: `${url}/submissions`,
      text: 'Submissions',
    },
    {
      type: 'link' as const,
      url: `${url}/feedback`,
      text: 'Feedback',
    },
  ]

  if (groupId) {
    useSubscription(GET_GROUP_PENDING_ASSIGNMENT_REVIEWS, {
      variables: { assignment_id: assignmentId, group_user_id: groupUser?.id },
      onSubscriptionData: ({
        subscriptionData: {
          data: { exams },
        },
      }) => {
        if (exams) {
          setPendingExams(exams)
        }
      },
    })
  } else {
    useSubscription(GET_PENDING_ASSIGNMENT_REVIEWS, {
      variables: { assignment_id: assignmentId, user_id: user.id },
      onSubscriptionData: ({
        subscriptionData: {
          data: { exams },
        },
      }) => {
        if (exams) {
          setPendingExams(exams)
        }
      },
    })
  }

  return (
    <>
      <Route path={path} exact>
        <Assignment
          assignment={assignment}
          topNavLeft={topNavLeft}
          assignmentNav={assignmentNav}
          loading={[loading]}
          pendingExams={pendingExams}
        />
      </Route>

      <Route path={`${path}/submissions`}>
        <SubmissionPage
          assignment={assignment}
          assignmentId={assignmentId}
          classId={id}
          assignmentNav={assignmentNav}
          groupId={groupId}
          loading={[loading]}
          pendingExams={pendingExams}
        />
      </Route>

      <Route path={`${path}/feedback`}>
        <FeedbackPage
          assignmentId={assignmentId}
          assignment={assignment}
          topNavLeft={topNavLeft}
          assignmentNav={assignmentNav}
          loading={[loading]}
          pendingExams={pendingExams}
        />
      </Route>
    </>
  )
}

export default AssignmentPage
