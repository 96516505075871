import { Material } from '../types'
import { Kinesthetic, Read, Visual, Aural } from '../components/Icons'

export const filterMaterials = (
  materials: Material[],
  preference: string[]
): Material[] => {
  return materials.filter(({ learningStyle }) => {
    return preference.some((value) => learningStyle.includes(value))
  })
}

export const formatLearningStyle = (learningStyle: string[]): string => {
  const content = learningStyle.reduce((acc, style, index) => {
    return index == 0 ? acc + style : acc + ',' + style
  }, '')

  return '{' + content + '}'
}

export const getPreferenceIcon = (preference: string): any => {
  if (preference === 'visual') return Visual
  if (preference === 'aural') return Aural
  if (preference === 'read') return Read
  if (preference === 'kinesthetic') return Kinesthetic
  return Visual
}

export const displayPreference = (preference: string): string => {
  if (preference === 'read') return 'Read/Write'
  return preference
}
