import { Route } from 'react-router-dom'
import { routes } from '../../constants'
import pickBy from 'lodash-es/pickBy'
import omit from 'lodash-es/omit'
import { ClassChat } from './index'
import { useAlamContext } from '../../client'

export const ClassChatRoute = (): JSX.Element => {
  const { classUser } = useAlamContext()
  const classRoutes = pickBy(routes, (route) => route.classChat)

  const exceptions = () => {
    if (['teacher', 'admin', 'creator'].includes(classUser?.role)) {
      return ['pretopicSubmission']
    }
    return []
  }
  const filteredClassRoutes = omit(classRoutes, exceptions())
  const paths = Object.keys(filteredClassRoutes).map(
    (routeName: string) => routes[routeName].path
  )
  return (
    <Route path={paths} exact>
      <ClassChat />
    </Route>
  )
}

export default ClassChatRoute
