import { Fragment } from 'react'
import {
  Container,
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  Text,
  HStack,
  IconButton,
} from '@chakra-ui/react'
import { IoChevronDown } from 'react-icons/io5'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { AlamAvatar } from '../../components/Avatar'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import { Layout } from '../../components/Layout'
import { GET_QUESTION_REPORT } from '../../client/queries'
import { buildAnalyticsCrumbs, constructTextQuestionData } from '../../utils'
import {
  RespondentCount,
  QuestionPageContent,
} from '../../components/Analytics'
import { TopNavItemProps } from '../../components/TopNav'

const AssignmentReviewQuestionPage = () => {
  const params = useParams<{
    id: string
    topic_id: string
    assignment_id: string
    question_id: string
  }>()

  const { data } = useQuery(GET_QUESTION_REPORT, {
    variables: {
      question_id: params.question_id,
      class_id: params.id,
    },
  })

  const question = data?.questions_by_pk
  const exam_arrangement = question?.exam_arrangements[0]
  const assignment = exam_arrangement?.exam?.assignment
  const { order, exam } = exam_arrangement || {
    order: undefined,
    exam: undefined,
  }
  const questionNumber = order === 0 || order ? exam_arrangement?.order + 1 : 0

  const respondentCount = exam?.exam_responses_aggregate?.aggregate?.count || 0
  const totalCount =
    assignment?.assignment_users_aggregate?.aggregate?.count || 0

  const topNavLeft: TopNavItemProps[] = [
    {
      type: 'breadcrumbs',
      crumbs: buildAnalyticsCrumbs(
        params,
        {
          topicName: assignment?.topic?.name,
          assignmentName: assignment?.name,
          className: assignment?.class?.name,
          questionOrder: `Q${questionNumber}`,
        },
        'assignment-review'
      ),
    },
  ]

  return (
    <Layout showSideNav topNavLeft={topNavLeft}>
      <Box px={6} pt={6} mb={8}>
        <Text textStyle='h2' color='black.1'>
          Q{questionNumber}: {question?.text}
        </Text>
        <RespondentCount
          respondentCount={respondentCount}
          totalCount={totalCount}
        />
      </Box>
      {question && <QuestionPageContent question={question} />}
    </Layout>
  )
}

export default AssignmentReviewQuestionPage
