import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
} from '@chakra-ui/react'

export type ShowMessageModalProps = {
  title: string
  message: string
  isOpen: boolean
  onClose: () => void
  size?: string
  buttonText?: string
  hideCloseButton?: boolean
  textProps?: Record<string, string>
}

export const ShowMessageModal = ({
  title,
  message,
  isOpen,
  onClose,
  size = 'xs',
  buttonText = 'ok',
  hideCloseButton = false,
  textProps = {},
}: ShowMessageModalProps) => {
  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size={size}>
        <ModalOverlay />
        <ModalContent marginBottom='3.75rem !important'>
          <ModalHeader>{title}</ModalHeader>

          {!hideCloseButton && <ModalCloseButton />}

          <ModalBody>
            <Text
              textStyle='body.1'
              color='modal.text'
              textAlign='center'
              whiteSpace='pre-wrap'
              {...textProps}
            >
              {message}
            </Text>
          </ModalBody>

          <ModalFooter p={6}>
            <Button
              onClick={onClose}
              variant='base.primary'
              textStyle='button'
              isFullWidth
            >
              {buttonText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ShowMessageModal
