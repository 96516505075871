import React from 'react'
import { Flex, Text, HStack, Box } from '@chakra-ui/react'
import { getFullName, getFirstAndLastInitial } from '../../utils'
import { format } from 'date-fns'
import { dateFormat } from '../../constants'
import classnames from 'classnames'
import { AlamAvatar } from '../Avatar'
import { BsDot } from 'react-icons/bs'

export type PostHeadingProps = {
  firstName?: string
  lastName?: string
  inserted_at: Date
  avatar?: string
  type: 'post' | 'comment' | 'smallComment' | 'work' | 'anonymous'
  text?: string
  textNameProps?: Record<string, any>
  textDateProps?: Record<string, any>
}

export const PostHeading = ({
  firstName,
  lastName,
  inserted_at,
  avatar,
  type,
  text,
  textNameProps,
  textDateProps,
}: PostHeadingProps): JSX.Element => {
  const name =
    type === 'anonymous' ? 'Anonymous' : getFullName({ firstName, lastName })
  return (
    <Flex w='100%' align='center'>
      <HStack
        w='100%'
        spacing={3}
        textStyle={classnames({
          caption: ['smallComment', 'anonymous'].includes(type),
          subtitle: ['comment', 'post'].includes(type),
          'body.2': type === 'work',
        })}
        divider={
          <Box border='0' mx='0 !important' px={1}>
            <BsDot />
          </Box>
        }
      >
        <Flex align='center' maxWidth='70%'>
          {type === 'anonymous' ? (
            <AlamAvatar size='sm' bg='primary.1' />
          ) : (
            <AlamAvatar
              size={classnames({
                md: type === 'post' || type === 'work',
                sm: type === 'comment' || type === 'smallComment',
              })}
              src={avatar}
              name={getFirstAndLastInitial({ firstName, lastName })}
            />
          )}

          <Text
            pl={2}
            fontWeight='700'
            color={classnames({
              'black.1': [
                'post',
                'comment',
                'smallComment',
                'anonymous',
              ].includes(type),
              'grey.1': type === 'work',
            })}
            fontSize='16px'
            isTruncated
            letterSpacing='0'
            {...textNameProps}
          >
            {name}
          </Text>
        </Flex>

        {text && (
          <Text
            color={classnames({
              'black.1': [
                'post',
                'comment',
                'smallComment',
                'anonymous',
              ].includes(type),
              'grey.1': type === 'work',
            })}
            fontSize='16px'
          >
            {text}
          </Text>
        )}

        <Text
          color='grey.1'
          letterSpacing='0'
          fontSize='16px'
          {...textDateProps}
        >
          {format(new Date(inserted_at), dateFormat)}
        </Text>
      </HStack>
    </Flex>
  )
}

export default PostHeading
