import React from 'react'
import {
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuDivider,
  MenuList,
  useMediaQuery,
  useTheme,
} from '@chakra-ui/react'
import {
  TopNavItemProps,
  renderLink,
  renderIconButton,
  renderReactElement,
  renderText,
  renderMobileLink,
  renderTextWithIconButton,
  renderLinkWithBorder,
  renderBreadcrumbs,
} from './index'
import { getFullName, getFirstAndLastInitial } from '../../utils'
import { useAlamContext } from '../../client'
import { AlamAvatar } from '../Avatar'
import { useLocation, useHistory, matchPath } from 'react-router-dom'
import { MdSettings, MdInput } from 'react-icons/md'
import { logout } from '../Layout/SideNavLayout'

export interface TopNavProps {
  avatar?: string
  left?: TopNavItemProps[]
  right?: TopNavItemProps[]
  center?: TopNavItemProps[]
  homeSettingsNav?: TopNavItemProps[]
  assignmentNav?: TopNavItemProps[]
  isPretopic?: boolean
  bg?: string
}

export const TopNav = ({
  avatar,
  left,
  right,
  center,
  homeSettingsNav,
  assignmentNav,
  isPretopic = false,
  bg = 'primary.1',
}: TopNavProps): JSX.Element => {
  const { breakpoints } = useTheme()
  const [tabletDevice, largeDevice] = useMediaQuery([
    `(min-width: ${breakpoints.md})`,
    `(min-width: ${breakpoints.lg})`,
  ])
  const { user, classUser } = useAlamContext()

  const location = useLocation()
  const history = useHistory()

  const homeSettingsPaths = [
    '/',
    '/home/account-settings/',
    '/home/account-settings/profile-info',
    '/home/account-settings/account',
    '/home/account-settings/password',
    '/home/account-settings/notifications',
  ]

  const shouldGoToHomeSettings = () => {
    return homeSettingsPaths.some((path: string) => {
      return !!matchPath(location.pathname, {
        path,
        exact: true,
      })
    })
  }

  const goToSettings = () => {
    const settingsPath = shouldGoToHomeSettings()
      ? '/home/account-settings/profile-info'
      : `/classes/${classUser?.class?.id}/account-settings/profile-info`
    history.push(settingsPath)
  }

  return (
    <Flex
      direction='column'
      w='100%'
      filter='drop-shadow(0px 4px 16px rgba(23, 5, 61, 0.08))'
    >
      <Flex
        justify='space-between'
        w='100%'
        h='64px'
        bg={bg}
        px={4}
        align='center'
      >
        {left && (
          <Flex align='center' justify='start' w='100%'>
            <HStack spacing={3}>
              {left.map((item, index) => {
                switch (item.type) {
                  case 'link':
                    return renderLink(item, index)
                  case 'iconButton':
                    return renderIconButton(item, index)
                  case 'reactElement':
                    return renderReactElement(item, index)
                  case 'text':
                    return renderText(item, index)
                  case 'textWithIconButton':
                    return renderTextWithIconButton(item, index)
                  case 'breadcrumbs':
                    return renderBreadcrumbs(item, index)
                }
              })}
            </HStack>
          </Flex>
        )}

        {center && ((tabletDevice && center.length <= 2) || largeDevice) && (
          <Flex align='center' justify='center' minWidth='400px'>
            <HStack spacing={6}>
              {center.map((item, index) => {
                switch (item.type) {
                  case 'link':
                    return renderLink(item, index)
                  case 'iconButton':
                    return renderIconButton(item, index)
                  case 'reactElement':
                    return renderReactElement(item, index)
                  case 'text':
                    return renderText(item, index)
                }
              })}
            </HStack>
          </Flex>
        )}

        <Flex align='center' justify='flex-end' w='100%'>
          <HStack spacing={3}>
            {right &&
              right.map((item, index) => {
                switch (item.type) {
                  case 'link':
                    return renderLink(item, index)
                  case 'iconButton':
                    return renderIconButton(item, index)
                  case 'reactElement':
                    return renderReactElement(item, index)
                  case 'text':
                    return renderText(item, index)
                }
              })}
            <Menu placement='bottom-start' autoSelect={false} offset={[0, 16]}>
              <MenuButton>
                <AlamAvatar
                  ml={3}
                  src={user.avatar}
                  name={getFirstAndLastInitial(user)}
                  size='lg'
                />
              </MenuButton>
              <MenuList>
                <MenuItem
                  icon={<Icon as={MdSettings} boxSize='16px' />}
                  onClick={goToSettings}
                >
                  Account settings
                </MenuItem>
                <MenuDivider />
                <MenuItem
                  icon={<Icon as={MdInput} boxSize='16px' />}
                  onClick={logout}
                >
                  Log Out
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </Flex>
      </Flex>

      {center && (!tabletDevice || (center.length > 2 && !largeDevice)) && (
        <Flex w='100%' h='56px' align='center' justify='center' bg='#D1CDD8'>
          <HStack spacing={6}>
            {center.map((item, index) => {
              switch (item.type) {
                case 'link':
                  return renderMobileLink(item, index)
                case 'iconButton':
                  return renderIconButton(item, index)
                case 'reactElement':
                  return renderReactElement(item, index)
                case 'text':
                  return renderText(item, index)
              }
            })}
          </HStack>
        </Flex>
      )}

      {homeSettingsNav && (
        <Flex w='100%' h='56px' align='center' justify='center' bg='white.1'>
          <HStack spacing={6}>
            {homeSettingsNav.map((item, index) => {
              switch (item.type) {
                case 'link':
                  return renderMobileLink(item, index)
                case 'iconButton':
                  return renderIconButton(item, index)
                case 'reactElement':
                  return renderReactElement(item, index)
                case 'text':
                  return renderText(item, index)
              }
            })}
          </HStack>
        </Flex>
      )}

      {assignmentNav && (
        <Flex justify='center' w='100%' bg='#D1CDD8'>
          <Flex
            h='64px'
            w='100%'
            px={4}
            align='center'
            justify='space-around'
            bg='#D1CDD8'
          >
            {assignmentNav.map((item, index) => {
              return renderLinkWithBorder(item, index)
            })}
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}

export default TopNav
