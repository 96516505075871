import React from 'react'
import styled from '@emotion/styled'
import { Box, Flex, Text, HStack, Icon } from '@chakra-ui/react'
import { getFullName, useErrorToast, useSuccessToast } from '../../utils'
import { format } from 'date-fns'
import { dateFormat } from '../../constants'
import { GoPin } from 'react-icons/go'
import classnames from 'classnames'
import Linkify from 'react-linkify'
import { DecoratedLink } from '../Styled'
import { UPDATE_POST } from '../../client/queries'
import { useMutation } from '@apollo/client'
import { IoMdDocument } from 'react-icons/io'
import { BsDot } from 'react-icons/bs'
import { RenderForUser } from '../SharedComponents'
import { Announcement, NewMessage } from '../Icons'

export type AnnouncementHeadingProps = {
  title?: string
  postId?: string
  firstName?: string
  lastName?: string
  inserted_at: Date
  comments?: number
  editMode?: boolean
  showCommentsCount?: boolean
  type: string
  showCommentIcon?: boolean
  titleProps?: Record<string, any>
  id?: string
  isBulletinBoard?: boolean
}

export const PinWrapper = styled(Box)`
  transform: rotate(-45deg);
`

export const AnnouncementHeading = ({
  postId = '',
  editMode,
  title,
  firstName,
  lastName,
  inserted_at,
  comments = 0,
  showCommentsCount = false,
  type,
  showCommentIcon = true,
  titleProps = {},
  id: userId = '',
  isBulletinBoard,
}: AnnouncementHeadingProps): JSX.Element => {
  const name = getFullName({ firstName, lastName })

  const [unpinPost, { called: unpinPostWasCalled }] = useMutation(UPDATE_POST, {
    variables: {
      id: postId,
      changes: {
        is_pinned: false,
      },
    },
    onCompleted: () => {
      useSuccessToast({
        title: 'Post unpinned',
        icon: IoMdDocument,
      })
    },
    onError: (error) => {
      console.warn(error)
      useErrorToast({ message: 'Unpin Post failed' })
    },
  })

  const handleUnpinPost = () => {
    if (!unpinPostWasCalled) {
      unpinPost()
    }
  }

  return (
    <Flex w='100%' cursor='pointer'>
      <Flex
        w={classnames({
          '90%': showCommentsCount,
          '100%': !showCommentsCount,
        })}
        direction='column'
      >
        {isBulletinBoard && (
          <Text
            fontSize='10px'
            lineHeight='14px'
            letterSpacing='0.08em'
            textTransform='uppercase'
            color='secondary.1'
          >
            Announcement
          </Text>
        )}

        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <DecoratedLink target='_blank' href={decoratedHref} key={key}>
              {decoratedText}
            </DecoratedLink>
          )}
        >
          <Text
            layerStyle='body.1'
            fontWeight='700'
            pb={1}
            w='100%'
            {...titleProps}
          >
            {title}
          </Text>
        </Linkify>

        <HStack
          w='100%'
          pl={classnames({
            '2': type === 'announcement' && !isBulletinBoard,
            '0': type !== 'announcement',
          })}
          spacing={3}
        >
          {type === 'announcement' && !isBulletinBoard && (
            <Announcement width='18' height='18' viewBox='8 8 24 24' />
          )}
          <HStack
            w='100%'
            divider={
              <Box border='0' mx='0 !important' px={1}>
                <BsDot />
              </Box>
            }
          >
            <Text
              textStyle='subtitle'
              color='grey.1'
              maxWidth='70%'
              isTruncated
              letterSpacing='0'
              fontSize='16px'
            >
              {name}
            </Text>
            <Text
              textStyle='subtitle'
              color='grey.1'
              letterSpacing='0'
              fontSize='16px'
            >
              {format(new Date(inserted_at), dateFormat)}
            </Text>
          </HStack>
        </HStack>
      </Flex>

      {editMode ? (
        <RenderForUser
          roles={['teacher', 'admin', 'creator']}
          userIds={[userId]}
        >
          <Flex
            align='center'
            justify='flex-end'
            w='10%'
            onClick={handleUnpinPost}
          >
            <Text color='accent.1' fontSize='12px' pr={1} fontWeight='700'>
              UNPIN
            </Text>
            <PinWrapper>
              <Icon as={GoPin} color='accent.1' />
            </PinWrapper>
          </Flex>
        </RenderForUser>
      ) : (
        <>
          {showCommentIcon && (
            <Flex align='center' justify='flex-end' w='10%'>
              <Text color='primary.1' textStyle='body.semibold' pr={1}>
                {comments}
              </Text>
              <NewMessage width='16' height='16' viewBox='8 8 24 24' />
            </Flex>
          )}
        </>
      )}
    </Flex>
  )
}

export default AnnouncementHeading
