import React, { useState, useMemo } from 'react'
import {
  Button,
  VStack,
  HStack,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  Textarea,
  FormErrorMessage,
  FormErrorIcon,
  Spacer,
  Switch,
  ModalBody,
  SimpleGrid,
  StackDivider,
  ModalFooter,
  MenuDivider,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import {
  MaterialUser,
  Attachment,
  MaterialGroup,
  Group,
  ClassUser,
} from '../../types'
import { useAlamContext } from '../../client'
import {
  Select,
  MultiDropdown,
  MultiDropdownItem,
  DropdownCheckbox,
} from '../Input'
import {
  isLargerThanTablet,
  isMobileDevice,
  validateSpaceOnly,
  formatLearningStyle,
  useDatePicker,
  useFormState,
  useAttachment,
  useDropdownCheckbox,
  getFullName,
  constructStudentOption,
} from '../../utils'
import { EditableDatePicker } from './index'

export type MaterialInput = {
  id?: string
  title?: string
  description?: string
  content?: string
  learningStyle: string
  topicId: string
  classId?: string
  assignTo?: string
  schedule: string
  materialUsers: Array<string>
  isShown?: boolean
  addNewUsers?: boolean
  addNewGroups?: boolean
  files: File[]
  removedFiles: Attachment[]
  materialGroups: string[]
}

interface Material
  extends Omit<
    MaterialInput,
    | 'materialUsers'
    | 'userId'
    | 'files'
    | 'removedFiles'
    | 'materialGroups'
    | 'learningStyle'
  > {
  materialUsers?: MaterialUser[]
  attachments?: Attachment[]
  materialGroups?: MaterialGroup[]
  learningStyle: string[]
}

export type MaterialFormProps = {
  students?: ClassUser[]
  learningStyles: Array<{ display: string | JSX.Element; value: string }>
  topics: Array<{ display: string; value: string }>
  material: Material
  onSubmit: (data: MaterialInput) => void
  createMaterialIsSubmitting: boolean
  updateMaterialIsSubmitting: boolean
  groups: Group[]
}

export const MaterialForm = ({
  topics,
  learningStyles,
  students = [],
  onSubmit,
  createMaterialIsSubmitting,
  updateMaterialIsSubmitting,
  material = {
    title: '',
    description: '',
    schedule: '',
    learningStyle: ['visual', 'aural', 'read', 'kinesthetic'],
    classId: '',
    topicId: '',
    isShown: true,
    addNewUsers: true,
    addNewGroups: true,
    materialUsers: [],
    attachments: [],
    materialGroups: [],
  },
  groups,
}: MaterialFormProps): JSX.Element => {
  const { classUser } = useAlamContext()

  const {
    register,
    handleSubmit,
    errors,
    formState: { isValid },
  } = useForm<MaterialInput>({
    mode: 'onBlur',
    defaultValues: {
      title: material.title,
      description: material.description,
      id: material.id,
      classId: classUser?.class?.id,
    },
  })

  const {
    date: schedule,
    handleChangeDate: handleChangeSchedule,
    resetDate: resetSchedule,
  } = useDatePicker(material.schedule)

  const {
    removedAttachments,
    newAttachments,
    renderFileList,
    renderAttachButton,
    resetAttachments,
    renderAttachmentError,
  } = useAttachment(material?.attachments || [])

  const {
    formState: { topicId },
    handleInputChange,
  } = useFormState({
    topicId: material.topicId,
  })

  const [isShown, setIsShown] = useState(material.isShown)
  const [addNewUsers, setAddNewUsers] = useState(material.addNewUsers)
  const [addNewGroups, setAddNewGroups] = useState(material.addNewGroups)

  const [learningStyleValue, setLearningStyleValue] = useState(
    material.learningStyle
  )

  const {
    values: materialGroups,
    dropdownItemProps: groupsDropdownItem,
    dropdownWrapperProps: groupsDropdownWrapper,
    resetValues: resetMaterialGroups,
  } = useDropdownCheckbox({
    allTitle: 'All Groups',
    emptyTitle: 'No Groups',
    indeterminateTitle: 'Groups',
    isCollapsable: true,
    collapseTitle: 'GROUPS',
    options: groups.map(({ id, name }) => ({
      display: name,
      value: id,
      isDisabled: false,
      subtext: '',
    })),
    initialValues: useMemo(() => {
      // edit
      if (Array.isArray(material?.materialGroups) && material?.title) {
        return material?.materialGroups.map(({ group_id }) => group_id)
      }

      // create
      return groups.map(({ id }) => id)
    }, [groups]),
  })

  const studentOptions = useMemo(() => {
    const assignedGroups = groups.filter((group) => {
      const selectedGroups: string[] = materialGroups
      return selectedGroups.includes(group.id)
    })

    const options = students.map((student) => {
      return constructStudentOption(student, assignedGroups)
    })

    return options
  }, [materialGroups, groups, students])

  const {
    values: materialUsers,
    dropdownItemProps: studentsDropdownItem,
    dropdownWrapperProps: studentsDropdownWrapper,
    resetValues: resetMaterialUsers,
  } = useDropdownCheckbox({
    allTitle: 'All Students',
    emptyTitle: 'No Students',
    indeterminateTitle: 'students',
    isCollapsable: true,
    collapseTitle: 'STUDENTS',
    options: studentOptions,
    initialValues: useMemo(() => {
      // edit
      if (Array.isArray(material?.materialUsers) && material?.title) {
        return material?.materialUsers.map(({ user_id }) => user_id)
      }

      return students.map(({ user }) => user?.id || '')
    }, [students]),
  })

  const handleSubmitMaterialForm = (data: MaterialInput) => {
    const newData = {
      ...data,
      materialUsers,
      materialGroups,
      files: newAttachments,
      removedFiles: removedAttachments,
      isShown,
      addNewUsers,
      addNewGroups,
      topicId,
      learningStyle: formatLearningStyle(learningStyleValue),
      schedule: schedule.toISOString(),
    }

    if (newData.materialUsers.length === 1 && newData.materialUsers[0] === '') {
      newData.materialUsers = []
    }

    onSubmit(newData)

    if (!material.id) {
      resetSchedule()
      resetMaterialGroups()
      resetMaterialUsers()
      resetAttachments()
    }
  }

  return (
    <form onSubmit={handleSubmit(handleSubmitMaterialForm)}>
      <ModalBody>
        <VStack spacing={4} w='100%'>
          <Input name='classId' ref={register()} type='hidden' />
          {material.id && <Input name='id' ref={register()} type='hidden' />}

          <VStack
            spacing={4}
            w='100%'
            divider={<StackDivider layerStyle='divider' />}
          >
            <Flex direction='column' w='100%' justify='space-around'>
              <VStack spacing={4} align='flex-start'>
                <FormControl isInvalid={!!errors.title?.message}>
                  <FormLabel variant='required'>Title</FormLabel>
                  <Input
                    name='title'
                    ref={register({
                      required: 'Title is a required field',
                      maxLength: {
                        value: 100,
                        message: 'Title exceeded 100 maximum characters',
                      },
                      validate: {
                        validateSpaceOnly,
                      },
                    })}
                    fontSize='14px'
                  />
                  {errors?.title && (
                    <FormErrorMessage>
                      <FormErrorIcon />
                      {errors.title?.message}
                    </FormErrorMessage>
                  )}
                </FormControl>

                <FormControl
                  isInvalid={!!errors.description?.message}
                  css={{
                    '& .optional::after': {
                      letterSpacing: '0',
                    },
                  }}
                >
                  <FormLabel variant='optional' className='optional'>
                    Description
                  </FormLabel>
                  <Textarea
                    resize='vertical'
                    textStyle='body.1'
                    maxHeight='200px'
                    h='144px'
                    name='description'
                    fontSize='14px'
                    ref={register({
                      maxLength: {
                        value: 1000,
                        message: 'Description exceeded 1000 maximum characters',
                      },
                      validate: {
                        validateSpaceOnly,
                      },
                    })}
                  />
                  {errors.description && (
                    <FormErrorMessage>
                      <FormErrorIcon />
                      {errors.description?.message}
                    </FormErrorMessage>
                  )}
                </FormControl>

                {renderAttachButton()}
                {renderAttachmentError()}
              </VStack>
            </Flex>

            {renderFileList({ padding: '0px' })}

            <VStack spacing={8} w='100%'>
              <SimpleGrid columns={{ base: 1, md: 3 }} w='100%'>
                <FormControl w={isLargerThanTablet() ? '172px' : '100%'}>
                  <FormLabel color='primary.1' whiteSpace='nowrap'>
                    Learning Preferences
                  </FormLabel>

                  <DropdownCheckbox
                    onChange={setLearningStyleValue}
                    allTitle='All'
                    emptyTitle='None'
                    indeterminateTitle='Multiple'
                    options={learningStyles}
                    values={learningStyleValue}
                    menuButtonProps={{
                      textStyle: 'body.1',
                      color: 'primary.1',
                      w: isLargerThanTablet() ? '172px' : '100%',
                      whiteSpace: 'no-wrap',
                    }}
                    menuItemProps={{
                      fontSize: '14px',
                      fontWeight: '400',
                      lineHeight: '21px',
                      color: 'primary.1',
                      align: 'center',
                      padding: '0px',
                      margin: '0px',
                    }}
                  />
                </FormControl>
              </SimpleGrid>

              <SimpleGrid spacing={8} columns={{ base: 1, md: 3 }} w='100%'>
                <FormControl color='primary.1'>
                  <FormLabel>Topic</FormLabel>
                  <Select
                    value={topicId}
                    onChange={(value) => handleInputChange('topicId', value)}
                    options={topics}
                    menuButtonProps={{
                      textStyle: 'body.1',
                      color: 'primary.1',
                      w: isLargerThanTablet() ? '172px' : '100%',
                      whiteSpace: 'no-wrap',
                    }}
                    menuItemProps={{
                      textStyle: 'body.1',
                      color: 'primary.1',
                    }}
                    placeholder='No Topic'
                  />
                </FormControl>

                <FormControl>
                  <FormLabel color='primary.1'>Assign to</FormLabel>
                  <MultiDropdown
                    {...{
                      menuButtonProps: {
                        w: '100%',
                        textStyle: 'body.1',
                      },
                      itemProps: [
                        studentsDropdownWrapper,
                        groupsDropdownWrapper,
                      ],
                      allTitle: 'All',
                      indeterminateTitle: 'Students and Groups',
                      emptyTitle: 'Not assigned',
                    }}
                  >
                    <MultiDropdownItem {...studentsDropdownItem} />
                    {groups.length > 0 && (
                      <>
                        <MenuDivider />
                        <MultiDropdownItem {...groupsDropdownItem} />
                      </>
                    )}
                  </MultiDropdown>
                </FormControl>

                <FormControl>
                  <FormLabel color='primary.1'>Schedule</FormLabel>
                  <EditableDatePicker
                    value={schedule}
                    onChange={handleChangeSchedule}
                  />
                </FormControl>
              </SimpleGrid>
              <VStack spacing='24px' w='100%'>
                <Flex w='100%' align='center'>
                  <Text textStyle='body.1'>Auto-assign new students</Text>
                  <Spacer />
                  <Switch
                    isChecked={addNewUsers}
                    name='addNewUsers'
                    onChange={(e) => setAddNewUsers(e.target.checked)}
                  />
                </Flex>

                <Flex w='100%' align='center'>
                  <Text textStyle='body.1'>Auto-assign new groups</Text>
                  <Spacer />
                  <Switch
                    isChecked={addNewGroups}
                    name='addNewGroups'
                    onChange={(e) => setAddNewGroups(e.target.checked)}
                  />
                </Flex>

                <Flex w='100%' align='center'>
                  <Text textStyle='body.1'>Show in class</Text>
                  <Spacer />
                  <Switch
                    name='isShown'
                    isChecked={isShown}
                    onChange={(e) => setIsShown(e.target.checked)}
                  />
                </Flex>
              </VStack>
            </VStack>
          </VStack>
        </VStack>
      </ModalBody>

      <ModalFooter>
        <HStack w='100%' spacing={4}>
          <Flex justify='flex-end' w='100%'>
            <Button
              variant='base.primary'
              type='submit'
              textStyle='button'
              minW='72px'
              disabled={!isValid}
              isLoading={
                material.id
                  ? updateMaterialIsSubmitting
                  : createMaterialIsSubmitting
              }
              loadingText={
                !material.id && isMobileDevice() ? 'Creating' : 'Posting'
              }
            >
              {!material.id && isMobileDevice() ? 'Create' : 'Post'}
            </Button>
          </Flex>
        </HStack>
      </ModalFooter>
    </form>
  )
}
