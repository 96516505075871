import React from 'react'
import { Icon } from '@chakra-ui/react'

export const Warning = (props: Record<string, any>): JSX.Element => {
  return (
    <Icon
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M2 0H16C16.5304 0 17.0391 0.210714 17.4142 0.585786C17.7893 0.960859 18 1.46957 18 2V16C18 16.5304 17.7893 17.0391 17.4142 17.4142C17.0391 17.7893 16.5304 18 16 18H2C1.46957 18 0.960859 17.7893 0.585786 17.4142C0.210714 17.0391 0 16.5304 0 16V2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0ZM10 10V4H8V10H10ZM10 14V12H8V14H10Z'
        fill='currentColor'
      />
    </Icon>
  )
}

export default Warning
