import React, { Fragment } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import { Text, IconButton, Flex, Box, Link, Tooltip } from '@chakra-ui/react'
import { BreadcrumbArrow } from '../Icons'

export interface TopNavItemProps {
  type:
    | 'link'
    | 'iconButton'
    | 'reactElement'
    | 'text'
    | 'textWithIconButton'
    | 'breadcrumbs'
  url?: string
  onClick?: () => void
  icon?: any
  text?: string
  ariaLabel?: string
  textStyle?: string
  children?: JSX.Element
  display?: string
  isExact?: boolean
  crumbs?: Crumbs[]
  textProps?: Record<string, any>
}

export interface Crumbs {
  text: string
  url: string
}

export const renderText = (
  { text }: TopNavItemProps,
  index: number
): JSX.Element => {
  return (
    <>
      {text !== undefined && text.length >= 24 ? (
        <Tooltip label={text} aria-label={text} placement='bottom-start'>
          <Text w='24ch' isTruncated textStyle='h5' key={index}>
            {text}
          </Text>
        </Tooltip>
      ) : (
        <Text textStyle='h5' key={index}>
          {text}
        </Text>
      )}
    </>
  )
}

export const renderTextWithIconButton = (
  {
    text,
    onClick = () => {},
    icon,
    ariaLabel = 'TopNav Icon Button',
  }: TopNavItemProps,
  index: number
): JSX.Element => {
  return (
    <Fragment key={index}>
      <IconButton
        fontSize='20px'
        aria-label={ariaLabel}
        variant='icon.button'
        icon={icon}
        onClick={onClick}
      />
      <Text textStyle='h5'>{text}</Text>
    </Fragment>
  )
}

export const renderLink = (
  { url = '/', text, isExact = true }: TopNavItemProps,
  index: number
): JSX.Element => {
  const exactProp = isExact ? { exact: true } : {}

  return (
    <Link
      to={url}
      as={RouterLink}
      textStyle='h5'
      key={index}
      layerStyle='nav.text.inactive'
      activeStyle={{
        color: '#17053D',
      }}
      {...exactProp}
    >
      {text}
    </Link>
  )
}

export const renderLinkWithBorder = (
  { url = '/', text }: TopNavItemProps,
  index: number
): JSX.Element => {
  return (
    <Link
      to={url}
      as={RouterLink}
      textStyle='h5'
      key={index}
      exact
      layerStyle='nav.text.inactive'
      activeStyle={{
        color: '#17053D',
        borderBottom: '1px solid',
        borderColor: 'primary.1',
      }}
      whiteSpace='nowrap'
    >
      {text}
    </Link>
  )
}

export const renderMobileLink = (
  { url = '/', text }: TopNavItemProps,
  index: number
): JSX.Element => {
  return (
    <Link
      to={url}
      as={RouterLink}
      textStyle='body.bold1'
      key={index}
      exact
      layerStyle='nav.text.inactive'
      activeStyle={{
        color: '#17053D',
        textDecoration: 'underline',
      }}
    >
      {text}
    </Link>
  )
}

export const renderIconButton = (
  {
    onClick = () => {},
    icon,
    ariaLabel = 'TopNav Icon Button',
    display,
  }: TopNavItemProps,
  index: number
): JSX.Element => {
  return (
    <IconButton
      fontSize='20px'
      key={index}
      aria-label={ariaLabel}
      variant='icon.button'
      icon={icon}
      onClick={onClick}
      display={display}
    />
  )
}

export const renderReactElement = ({ children = <div /> }, index: number) => {
  if (children) {
    return <React.Fragment key={index}>{children && children}</React.Fragment>
  }
}

export const renderBreadcrumbs = (
  {
    crumbs,
    textProps = {
      color: 'primary.1',
    },
  }: TopNavItemProps,
  index: number
): JSX.Element => {
  const crumbsLength = crumbs?.length || 0
  return (
    <Flex direction='row' align='center' justify='start'>
      {crumbs?.map((crumb, index) => {
        return renderCrumb(crumb, index, index !== crumbsLength - 1, textProps)
      })}
    </Flex>
  )
}

export const renderCrumb = (
  { text, url }: Crumbs,
  index: number,
  showArrow: boolean,
  textProps: Record<string, any>
): JSX.Element => {
  return (
    <>
      <Link
        to={url}
        as={RouterLink}
        textStyle='h5'
        key={index}
        layerStyle='nav.text.active'
      >
        <Text textStyle='h5' noOfLines={2} {...textProps}>
          {text}
        </Text>
      </Link>
      {showArrow && (
        <Flex mx='8px' w='40px' h='40px' align='center' justify='center'>
          <BreadcrumbArrow color='#17035D' width='7.42px' height='12px' />
        </Flex>
      )}
    </>
  )
}
