import React from 'react'
import { IconAlamProps } from './Type'

export const Link = ({
  fill,
  width,
  height,
  viewBox,
}: IconAlamProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.9 20C11.9 18.29 13.29 16.9 15 16.9H19V15H15C12.24 15 10 17.24 10 20C10 22.76 12.24 25 15 25H19V23.1H15C13.29 23.1 11.9 21.71 11.9 20ZM16 21H24V19H16V21ZM25 15H21V16.9H25C26.71 16.9 28.1 18.29 28.1 20C28.1 21.71 26.71 23.1 25 23.1H21V25H25C27.76 25 30 22.76 30 20C30 17.24 27.76 15 25 15Z'
      fill={fill}
    />
  </svg>
)

Link.defaultProps = {
  fill: '#17053D',
  width: '40',
  height: '40',
  viewBox: '0 0 40 40',
}
