import React from 'react'
import styled from '@emotion/styled'
import { Avatar, AvatarBadge, Box } from '@chakra-ui/react'

type AlamAvatarProps = {
  size: string
  src?: string
  name?: string
  isOnline?: boolean
  [key: string]: string | undefined | number | boolean
}

export const AlamAvatar = ({
  size,
  isOnline = false,
  ...props
}: AlamAvatarProps): JSX.Element => {
  return (
    <Wrapper size={size}>
      <Avatar
        bg='#FAC4C4'
        {...props}
        layerStyle={`avatar.${size}`}
        letterSpacing='0'
      >
        {isOnline && (
          <AvatarBadge
            bg='greenbase'
            h='9px'
            w='9px'
            boxShadow='0px 4px 8px rgba(47, 168, 79, 0.16), 0px 2px 4px rgba(47, 168, 79, 0.24)'
            borderColor='greenbase'
            mb='2px'
            mr='2px'
          />
        )}
      </Avatar>
    </Wrapper>
  )
}

const Wrapper = styled(Box)(({ size }: { size: string }) => ({
  '.chakra-avatar > .chakra-avatar__initials': {
    'font-size':
      (size === 'sm' && '12px') ||
      (size === 'md' && '14px') ||
      (size === 'lg' && '19.2px'),
    'letter-spacing': '0',
  },
}))

export default AlamAvatar
