import { useState, useEffect, useMemo } from 'react'
import { Attachment, GroupUser } from '../../types'
import { AssignmentType } from '../../pages/Assignment/AssignmentPage'
import {
  Box,
  VStack,
  StackDivider,
  Flex,
  Text,
  Button,
  HStack,
  useDisclosure,
} from '@chakra-ui/react'
import { AlphabeticalGradeInput, NumericalGradeInput } from '../Input'
import { submissionStatusType } from '../../pages/Assignment/submissionReducer'
import { handleUpdateSubmissionProps } from '../../pages/Assignment/Submission'
import { Submission } from '../../types'
import { ConfirmationModal } from '../Modal/ConfirmationModal'
import { useSuccessToast, useFilename, useErrorToast } from '../../utils'
import { SubmissionHistory } from './SubmissionHistory'
import { AttachButton, FileList } from '../File'
import { ResponseForm } from '../Exam'
import { CREATE_EXAM_RESPONSE } from '../../client/queries'
import { useMutation } from '@apollo/client'
import { v4 } from 'uuid'
import { useSubmissionContext } from '../../pages/Assignment/SubmissionContext'
import { useAlamContext } from '../../client'
import { FileRejection } from 'react-dropzone'

export type StudentSubmissionBoxProps = {
  assignment?: AssignmentType
  submissionStatus: submissionStatusType
  submission?: Submission
  handleUpdateSubmission: handleUpdateSubmissionProps
  submissionIsUpdating: boolean
  attachmentIsUpdating: boolean
  handleDeleteAttachment: (attachment: Attachment) => void
  handleUploadAttachments: (files: File[]) => void
}

export const StudentSubmissionBox = ({
  assignment,
  submissionStatus,
  submission,
  handleUpdateSubmission,
  submissionIsUpdating,
  attachmentIsUpdating,
  handleUploadAttachments,
  handleDeleteAttachment,
}: StudentSubmissionBoxProps): JSX.Element => {
  const [grade, setGrade] = useState('')
  const [rejections, setRejections] = useState<FileRejection[]>([])
  const { groupId } = useSubmissionContext()
  const { groups, user, groupUser } = useAlamContext()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const {
    isOpen: reviewConfirmIsOpen,
    onClose: reviewConfirmOnClose,
    onOpen: reviewConfirmOnOpen,
  } = useDisclosure()
  const {
    isOpen: assignmentReviewIsOpen,
    onClose: assignmentReviewOnClose,
    onOpen: assignmentReviewOnOpen,
  } = useDisclosure()

  const assignmentReviewForStudents = assignment?.examsForStudents?.find(
    (exam) => exam.type === 'assignment_review'
  )
  const assignmentReviewForGroups = assignment?.examsForGroups?.find(
    (exam) => exam.type === 'assignment_review'
  )

  const examResponseForUser = useMemo(() => {
    if (!groupId && assignmentReviewForStudents) {
      return assignmentReviewForStudents?.exam_responses?.find(
        ({ user_id }) => user_id === user?.id
      )
    }

    if (groupId && assignmentReviewForGroups) {
      return assignmentReviewForGroups?.exam_responses?.find(
        ({ group_user_id }) => group_user_id === groupUser?.id
      )
    }

    return undefined
  }, [assignmentReviewForStudents, assignmentReviewForGroups])

  const examResponseForGroup = useMemo(() => {
    return assignmentReviewForGroups?.exam_responses?.find(
      ({ group_user_id }) => {
        const [group] = groups

        return group?.group_users?.some(({ id }) => {
          return id === group_user_id
        })
      }
    )
  }, [assignmentReviewForGroups, groupUser])

  const [createExamResponse] = useMutation(CREATE_EXAM_RESPONSE)

  useEffect(() => {
    setGrade(submission?.grades[0]?.grade || '')
  }, [submission?.grades[0]?.grade])

  useEffect(() => {
    rejections.forEach((rejection) => {
      const { filename, extension } = useFilename(rejection.file.name)
      useErrorToast({
        title: `${decodeURIComponent(filename)}.${extension}`,
        message: 'Upload failed - File exceed upload limit of 50MB.',
      })
    })
  }, [rejections])

  const handleChangeGrade = (value: string) => setGrade(value)

  const openReviewConfirmation = async () => {
    // submit student assignment
    if (!groupId && assignmentReviewForStudents && !examResponseForUser) {
      await createExamResponse({
        variables: {
          params: [
            {
              id: v4(),
              exam_id: assignmentReviewForStudents.id,
              inserted_at: 'NOW()',
              updated_at: 'NOW()',
              started_at: 'NOW()',
              user_id: user?.id,
            },
          ],
        },
      })

      reviewConfirmOnOpen()
    }

    // submit group assignment
    if (
      groupId &&
      assignmentReviewForGroups?.exam_responses &&
      !examResponseForGroup
    ) {
      const [group] = groups
      const params = group?.group_users?.map(({ id }: GroupUser) => ({
        id: v4(),
        exam_id: assignmentReviewForGroups.id,
        inserted_at: 'NOW()',
        updated_at: 'NOW()',
        started_at: 'NOW()',
        group_user_id: id,
      }))

      await createExamResponse({
        variables: {
          params,
        },
      })

      reviewConfirmOnOpen()
    }

    // existing (unfinished) student review
    if (
      !groupId &&
      assignmentReviewForStudents?.exam_responses &&
      examResponseForUser &&
      !examResponseForUser.completed_at
    ) {
      reviewConfirmOnOpen()
    }

    // existing (unfinished) group review
    if (
      groupId &&
      assignmentReviewForGroups?.exam_responses &&
      examResponseForUser &&
      !examResponseForUser.completed_at
    ) {
      reviewConfirmOnOpen()
    }
  }

  const handleOpenAssignmentReview = () => {
    reviewConfirmOnClose()
    assignmentReviewOnOpen()
  }

  const handleSubmit = () => {
    handleUpdateSubmission({ status: 'submitted' }, () => {
      useSuccessToast({ title: 'Assignment was submitted' })
      openReviewConfirmation()
    })
  }

  const handleUnsubmit = () => {
    handleUpdateSubmission({ status: 'unsubmitted' }, () => {
      useSuccessToast({ title: 'Assignment was unsubmitted' })
      onClose()
    })
  }

  return (
    <>
      <ResponseForm
        isOpen={assignmentReviewIsOpen}
        onClose={assignmentReviewOnClose}
        exam={groupId ? assignmentReviewForGroups : assignmentReviewForStudents}
      />
      <ConfirmationModal
        isOpen={reviewConfirmIsOpen}
        message='Please take a moment to complete the feedback for the assignment. Your feedback will help the instructor improve the course and will not affect your grades.'
        confirmButtonText='START'
        onClose={reviewConfirmOnClose}
        cancelButtonText="I'LL DO IT LATER"
        title='Assignment Review'
        onSubmit={handleOpenAssignmentReview}
        size='sm'
      />
      <ConfirmationModal
        isOpen={isOpen}
        message='Unsubmitting will enable you te edit your submission. Please remember to submit after changes have been made.'
        onClose={onClose}
        title='Are you sure?'
        onSubmit={handleUnsubmit}
      />
      <VStack w='100%' divider={<StackDivider layerStyle='divider.nospace' />}>
        <Flex
          justify='space-between'
          w='100%'
          py='12px'
          pr='16px'
          pl='24px'
          alignItems='center'
          h='72px'
        >
          <Text textStyle='h4'>Submission</Text>
          <HStack spacing='16px'>
            {submissionStatus?.can?.unsubmitted && (
              <Button variant='base.white' textStyle='button' onClick={onOpen}>
                Unsubmit
              </Button>
            )}

            {submissionStatus?.can?.submitted && (
              <AttachButton
                isSubmitting={attachmentIsUpdating}
                addFiles={handleUploadAttachments}
                buttonProps={{
                  w: 'auto',
                  leftIcon: null,
                }}
                text='Upload'
                setRejections={setRejections}
              />
            )}

            {submissionStatus?.can?.submitted && (
              <Button
                variant='base.primary'
                textStyle='button'
                onClick={handleSubmit}
                isLoading={submissionIsUpdating}
                loadingText='Submit'
              >
                Submit
              </Button>
            )}
          </HStack>
        </Flex>

        {submission?.event_logs && submission.event_logs?.length >= 1 && (
          <SubmissionHistory
            eventLogs={submission?.event_logs}
            grading={assignment?.grading}
            currentUserRole='student'
          />
        )}

        {(submission?.attachments.length || 0) >= 1 ? (
          <FileList
            existingFiles={submission?.attachments || []}
            removeExisitingFile={handleDeleteAttachment}
          />
        ) : (
          <Flex w='100%' minH='136px' align='center' justify='center'>
            <Text textStyle='body.big' color='primary.2'>
              You have not submitted any files.
            </Text>
          </Flex>
        )}

        <Flex
          justify='space-between'
          pl='24px'
          pr='16px'
          py='16px'
          h='72px'
          w='100%'
          align='center'
        >
          <Text textStyle='h5'>Grade</Text>

          {assignment?.rubric === 'numerical' ? (
            <NumericalGradeInput
              grading={assignment?.grading}
              value={grade}
              onChange={handleChangeGrade}
              readOnly
              disabled={grade === ''}
            />
          ) : (
            <AlphabeticalGradeInput
              value={grade}
              onChange={handleChangeGrade}
              readOnly
            />
          )}
        </Flex>
      </VStack>
    </>
  )
}

export default StudentSubmissionBox
