import { Layout } from '../../components/Layout'
import {
  Container,
  Box,
  Flex,
  HStack,
  VStack,
  StackDivider,
  Icon,
  Text,
} from '@chakra-ui/react'
import { FaClipboardList } from 'react-icons/fa'
import { format } from 'date-fns'
import { dateFormat } from '../../constants'
import { CommentList } from '../../components/Post'
import { getFullName, useErrorToast, useSuccessToast } from '../../utils'
import { v4 as uuid } from 'uuid'
import { useAlamContext } from '../../client'
import { CREATE_ASSIGNMENT_COMMENT } from '../../client/queries'
import { useMutation } from '@apollo/client'
import { CommentForm } from '../../components/Forms'
import { Exam } from '../../types'
import { TopNavItemProps } from '../../components/TopNav'
import { FileList } from '../../components/File'
import { Loader, RenderForUser } from '../../components/SharedComponents'
import { ExamIndicators, TeacherExamIndicators } from '../../components/Exam'
import { AssignmentType } from './AssignmentPage'

export type Assignment = {
  assignment: AssignmentType
  topNavLeft: TopNavItemProps[]
  assignmentNav: TopNavItemProps[]
  loading: boolean[]
  pendingExams: Exam[]
}

const Assignment = ({
  assignment,
  topNavLeft,
  assignmentNav,
  loading,
  pendingExams,
}: Assignment): JSX.Element => {
  const [createComment, { loading: commentIsSubmitting }] = useMutation(
    CREATE_ASSIGNMENT_COMMENT
  )
  const { user: currentUser, classUser } = useAlamContext()
  const { firstName, lastName, avatar } = currentUser
  const name = getFullName({
    firstName: assignment?.user?.firstName,
    lastName: assignment?.user?.lastName,
  })

  const handleSubmitComment = ({ content }: { content: string }) => {
    createComment({
      variables: {
        id: uuid(),
        content,
        user_id: currentUser.id,
        assignment_id: assignment.id,
      },
    })
      .then(() => {
        useSuccessToast({ message: 'Comment was created' })
      })
      .catch(() => {
        useErrorToast({ message: 'Creating comment failed' })
      })
  }

  const isPretopic = assignment?.type === 'pretopic'

  return (
    <Layout
      showSideNav
      topNavLeft={topNavLeft}
      assignmentNav={assignmentNav}
      redirectNonExistingClass
      hasRightNav={isPretopic}
      isPretopic={isPretopic}
    >
      <Loader loaders={loading}>
        <Container
          maxWidth={{ base: '100%', md: '456px', lg: '500px', xl: '668px' }}
          py={6}
          px={{ base: 0, lg: 4 }}
          h='auto'
        >
          {pendingExams.length > 0 && <ExamIndicators exams={pendingExams} />}

          <VStack
            bg='white.1'
            layerStyle='card.module'
            w='100%'
            divider={<StackDivider layerStyle='divider.nospace' />}
            borderRadius={{ base: '0', lg: '12px' }}
          >
            <Box
              bg='white.1'
              layerStyle='card.expanded'
              w='100%'
              p={4}
              borderBottomRadius={
                isPretopic &&
                assignment?.attachments?.length === 0 &&
                !assignment?.description
                  ? '12px'
                  : '0px'
              }
            >
              <HStack spacing={4} h='100%' w='100%'>
                <Icon as={FaClipboardList} color='primary.1' fontSize='xl' />

                <Flex direction='column'>
                  <Text textStyle='h5'>{assignment?.name}</Text>
                  <HStack
                    spacing={3}
                    divider={<Box layerStyle='bulletpoint' />}
                  >
                    <Text
                      textStyle='body.1'
                      color='grey.1'
                      maxWidth={isPretopic ? '60%' : '50%'}
                      isTruncated
                    >
                      {name}
                    </Text>

                    {!isPretopic && (
                      <Text textStyle='body.1' color='grey.1'>
                        {assignment?.comments?.length > 1
                          ? `${assignment?.comments?.length} comments`
                          : `${assignment?.comments?.length} comment`}
                      </Text>
                    )}

                    <Text textStyle='body.1' color='grey.1'>
                      {assignment?.inserted_at &&
                        format(new Date(assignment?.inserted_at), dateFormat)}
                    </Text>
                  </HStack>
                </Flex>
              </HStack>
            </Box>
            {assignment?.description && (
              <Box
                w='100%'
                p={4}
                textStyle='body.1'
                fontSize='16px'
                lineHeight='24px'
              >
                {assignment?.description}
              </Box>
            )}

            {assignment?.attachments?.length >= 1 && (
              <FileList existingFiles={assignment.attachments} isReadOnly />
            )}

            {!isPretopic && (
              <Box w='100%'>
                {assignment?.comments?.length >= 1 ? (
                  <CommentList comments={assignment?.comments} />
                ) : (
                  <Text textStyle='body.1' color='grey.1' px={4} py={2}>
                    0 comment
                  </Text>
                )}
              </Box>
            )}

            {!isPretopic && (
              <Flex w='100%' p='8px 16px 16px'>
                <CommentForm
                  user={currentUser}
                  isSubmitting={commentIsSubmitting}
                  onSubmit={handleSubmitComment}
                  avatarSize='sm'
                />
              </Flex>
            )}
          </VStack>

          <RenderForUser roles={['admin', 'teacher', 'creator']}>
            <TeacherExamIndicators assignment={assignment} />
          </RenderForUser>
        </Container>
      </Loader>
    </Layout>
  )
}

export default Assignment
