import React from 'react'
import { Icon } from '@chakra-ui/react'

export const AccountSwitch = (props: Record<string, any>): JSX.Element => {
  return (
    <Icon
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      xmlSpace='preserve'
      {...props}
    >
      <path
        d='M9.40039 5.20039C10.7984 5.20039 13.6004 5.90239 13.6004 7.30039V8.80039H10.0004V7.30039C10.0004 6.40039 9.51439 5.73439 8.82439 5.23039L9.40039 5.20039ZM4.60039 5.20039C5.99839 5.20039 8.80039 5.90239 8.80039 7.30039V8.80039H0.400391V7.30039C0.400391 5.90239 3.20239 5.20039 4.60039 5.20039ZM4.60039 4.00039C4.123 4.00039 3.66516 3.81075 3.3276 3.47318C2.99003 3.13562 2.80039 2.67778 2.80039 2.20039C2.80039 1.723 2.99003 1.26516 3.3276 0.927598C3.66516 0.590033 4.123 0.400391 4.60039 0.400391C5.07778 0.400391 5.53562 0.590033 5.87318 0.927598C6.21075 1.26516 6.40039 1.723 6.40039 2.20039C6.40039 2.67778 6.21075 3.13562 5.87318 3.47318C5.53562 3.81075 5.07778 4.00039 4.60039 4.00039ZM9.40039 4.00039C8.923 4.00039 8.46516 3.81075 8.1276 3.47318C7.79003 3.13562 7.60039 2.67778 7.60039 2.20039C7.60039 1.723 7.79003 1.26516 8.1276 0.927598C8.46516 0.590033 8.923 0.400391 9.40039 0.400391C9.87778 0.400391 10.3356 0.590033 10.6732 0.927598C11.0107 1.26516 11.2004 1.723 11.2004 2.20039C11.2004 2.67778 11.0107 3.13562 10.6732 3.47318C10.3356 3.81075 9.87778 4.00039 9.40039 4.00039ZM5.20039 9.85039V11.2004H8.80039V9.85039L10.7504 11.8004L8.80039 13.7504V12.4004H5.20039V13.7504L3.25039 11.8004L5.20039 9.85039Z'
        fill='currentColor'
      />
    </Icon>
  )
}

export default AccountSwitch
