import React from 'react'
import { IconAlamProps } from './Type'

export const Test = ({
  fill,
  width,
  height,
  viewBox,
}: IconAlamProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M17.25 10C17.44 10 17.61 10.11 17.69 10.26L19.91 14.22L20 14.5L19.91 14.78L17.69 18.74C17.61 18.89 17.44 19 17.25 19H12.75C12.56 19 12.39 18.89 12.31 18.74L10.09 14.78L10 14.5L10.09 14.22L12.31 10.26C12.39 10.11 12.56 10 12.75 10H17.25ZM17.25 21C17.44 21 17.61 21.11 17.69 21.26L19.91 25.22L20 25.5L19.91 25.78L17.69 29.74C17.61 29.89 17.44 30 17.25 30H12.75C12.56 30 12.39 29.89 12.31 29.74L10.09 25.78L10 25.5L10.09 25.22L12.31 21.26C12.39 21.11 12.56 21 12.75 21H17.25ZM26.5 15.5C26.69 15.5 26.86 15.61 26.94 15.76L29.16 19.72L29.25 20L29.16 20.28L26.94 24.24C26.86 24.39 26.69 24.5 26.5 24.5H22C21.81 24.5 21.64 24.39 21.56 24.24L19.34 20.28L19.25 20L19.34 19.72L21.56 15.76C21.64 15.61 21.81 15.5 22 15.5H26.5Z'
      fill={fill}
    />
  </svg>
)

Test.defaultProps = {
  fill: '#17053D',
  width: '40',
  height: '40',
  viewBox: '0 0 40 40',
}
