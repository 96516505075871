import { Fragment, useState, useMemo, useEffect } from 'react'
import {
  Container,
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  Text,
  HStack,
  IconButton,
} from '@chakra-ui/react'
import {
  BarChart,
  CartesianGrid,
  CartesianAxis,
  YAxis,
  XAxis,
  Bar,
  ResponsiveContainer,
  LabelList,
  Label,
} from 'recharts'
import { IoChevronDown } from 'react-icons/io5'
import { AlamAvatar } from '../../components/Avatar'
import { Select } from '../../components/Input'
import { Layout } from '../../components/Layout'
import { TopNavItemProps } from '../../components/TopNav'
import { RespondentCount } from '../../components/Analytics'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import { Assignment, Submission, Topic } from '../../types'
import { useQuery } from '@apollo/client'
import { GET_PRETOPIC_REPORT } from '../../client/queries'
import { useParams, useRouteMatch } from 'react-router-dom'
import { format } from 'date-fns'
import textStyles from '../../theme/textStyles'
import {
  gradesSortDisplay,
  usePagination,
  buildStudentGradesData,
  StudentWithGradesData,
  getGradesData,
  renderSubmissionDate,
  sortStudentData,
} from '../../utils'

type StudentTypes = {
  id: string
  first_name: string
  last_name: string
  submissionDate: string
  grade: string
  avatar?: string
}

const PreCourseTestPage = () => {
  const [sortBy, setSortBy] = useState('a-z')
  const { id, topic_id } = useParams<{ id: string; topic_id: string }>()
  const { data } = useQuery(GET_PRETOPIC_REPORT, {
    variables: { topic_id: topic_id },
  })

  const sortOptions = [
    { value: 'a-z', display: 'Alphabetical A-Z' },
    { value: 'z-a', display: 'Alphabetical Z-A' },
  ]

  const pretopic = data?.assignments[0]

  const assignmentStudents =
    pretopic?.assignment_users?.map(
      (assignmentUser: any) => assignmentUser.user
    ) || []

  const studentData = assignmentStudents.map((student: any) => {
    return buildStudentGradesData(student)
  })

  const gradesGraphData = getGradesData(studentData, pretopic?.rubric)

  const respondentCount =
    studentData?.filter(({ grade }: StudentWithGradesData) => grade).length || 0
  const studentCount = studentData?.length || 0

  const sortedStudentData = useMemo(() => {
    return sortStudentData(studentData, sortBy)
  }, [studentData, sortBy])

  const {
    page,
    setPage,
    totalPages,
    currentPageContent,
    totalItems,
  } = usePagination(sortedStudentData)

  useEffect(() => {
    setPage(1)
  }, [sortBy])

  const topNavLeft: TopNavItemProps[] = [
    {
      type: 'breadcrumbs',
      crumbs: [
        {
          text: pretopic?.topic?.name || 'Topic',
          url: `/classes/${id}/analytics/class/topic/${topic_id}`,
        },
        {
          text: pretopic?.name || 'Pretopic',
          url: `/classes/${id}/analytics/class/topic/${topic_id}/pre-course-test`,
        },
      ],
    },
  ]

  return (
    <Layout showSideNav topNavLeft={topNavLeft}>
      <Box px={6} pt={6} mb={8}>
        <Text textStyle='h2' color='black.1'>
          Pre-topic Test
        </Text>
        <RespondentCount
          respondentCount={respondentCount}
          totalCount={studentCount}
        />
      </Box>
      <Container
        maxW={{ base: '100%', md: '456px', lg: '500px', xl: '1080px' }}
        px={0}
        mb={6}
      >
        <Box
          h='496px'
          bg='white.1'
          borderRadius='12px'
          borderWidth='0.5px'
          borderStyle='solid'
          borderColor='primary.2'
          py={7}
          pr={5}
          pl={8}
        >
          {pretopic && gradesGraphData ? (
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart
                data={gradesGraphData}
                layout='horizontal'
                margin={{ left: -15, bottom: 30 }}
              >
                <CartesianGrid strokeDasharray='1' vertical={false} />
                <YAxis
                  dataKey='students'
                  type='number'
                  style={{
                    ...textStyles.body['2'],
                    fontWeight: 400,
                    fill: '#0100028f',
                  }}
                  tickLine={false}
                >
                  <Label
                    value='No. of students'
                    position='insideTopLeft'
                    angle={-90}
                    style={{
                      ...textStyles.body['2'],
                      fontWeight: 400,
                      fontSize: '14px',
                      fill: '#0100028f',
                    }}
                    offset={15}
                    dy={85}
                  />
                </YAxis>

                <XAxis
                  type='category'
                  dataKey='score'
                  style={{
                    ...textStyles.body['2'],
                    fontWeight: 400,
                    color: '#0100028f',
                  }}
                  tickLine={false}
                >
                  <Label
                    value='Scores'
                    position='insideBottomRight'
                    style={{
                      ...textStyles.body['2'],
                      fontWeight: 400,
                      fontSize: '14px',
                      fill: '#0100028f',
                    }}
                    offset={0}
                    dy={8}
                  />
                </XAxis>
                <Bar dataKey='students' barSize={40} fill='#CE1032' />
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <Flex align='center' justify='center' w='100%' h='100%'>
              <Text
                textStyle='body.2'
                color='black.3'
                fontSize='16px'
                fontWeight='600'
              >
                Not enough data
              </Text>
            </Flex>
          )}
        </Box>
      </Container>
      <Box px={6} py={4}>
        <Select
          value={sortBy}
          onChange={(value) => setSortBy(value)}
          options={sortOptions}
          menuButtonProps={{
            textStyle: 'button',
            variant: 'tab.selector',
            textAlign: 'left',
            py: '8px',
            pr: '8px',
            w: '196px',
            layerStyle: 'card.board',
            color: 'primary.1',
          }}
          menuItemProps={{
            textStyle: 'body.1',
            color: 'primary.1',
            w: '196px',
            _hover: { bg: 'hover.2', color: 'white.1' },
          }}
          displayTransform={gradesSortDisplay}
        />
      </Box>
      <Box
        mx={6}
        my={4}
        css={{
          '& > :last-child': {
            borderRadius: '0px 0px 8px 8px',
          },
          '& > :first-child': {
            borderRadius: '8px 8px 0px 0px',
          },
        }}
      >
        <Flex
          w='100%'
          bg='white.1'
          borderWidth='0.5px'
          borderColor='primary.2'
          css={{
            '& > :nth-child(2)': {
              borderLeftWidth: '0.5px',
              borderRightWidth: '0.5px',
              borderColor: 'rgba(23, 5, 61, 0.32)',
            },
          }}
        >
          <Box py={3} px={6} w='40%'>
            <Text
              textStyle='smallstate'
              textTransform='uppercase'
              color='primary.2'
              letterSpacing='0.02em'
            >
              students
            </Text>
          </Box>
          <Box py={3} px={6} w='30%'>
            <Text
              textStyle='smallstate'
              textTransform='uppercase'
              color='primary.2'
              letterSpacing='0.02em'
            >
              submission date
            </Text>
          </Box>
          <Box py={3} px={6} w='30%'>
            <Text
              textStyle='smallstate'
              textTransform='uppercase'
              color='primary.2'
              letterSpacing='0.02em'
            >
              score
            </Text>
          </Box>
        </Flex>

        {currentPageContent?.map((student: StudentWithGradesData) => (
          <Flex
            w='100%'
            bg='white.1'
            key={student.id}
            borderColor='primary.2'
            borderWidth='0.5px'
            borderTopWidth='0px'
            css={{
              '& > :nth-child(2)': {
                borderLeftWidth: '0.5px',
                borderRightWidth: '0.5px',
                borderColor: 'rgba(23, 5, 61, 0.32)',
              },
            }}
          >
            <Box py={3} px={6} w='40%'>
              <Flex align='center'>
                <AlamAvatar size='lg' mr={2} src={student.avatar} />
                <Box>
                  <Text
                    textStyle='smallstate'
                    textTransform='uppercase'
                    letterSpacing='0.02em'
                    mb={1}
                    fontSize='14px'
                    color='primary.1'
                  >
                    {student.first_name} {student.last_name}
                  </Text>
                  <Box>
                    <HStack
                      spacing={2}
                      divider={<Box layerStyle='bulletpoint' />}
                    >
                      <Text textStyle='subtitle' color='grey.1' fontSize='12px'>
                        Student
                      </Text>
                      {student.group_name && (
                        <Text
                          textStyle='subtitle'
                          color='grey.1'
                          fontSize='12px'
                        >
                          {student.group_name}
                        </Text>
                      )}
                    </HStack>
                  </Box>
                </Box>
              </Flex>
            </Box>
            <Flex py={3} px={6} w='30%' align='center'>
              <Text
                textStyle='smallstate'
                textTransform='uppercase'
                letterSpacing='0.02em'
              >
                {renderSubmissionDate(student.submissionDate)}
              </Text>
            </Flex>
            <Flex py={3} px={6} w='30%' flex='1' align='center'>
              <Text
                textStyle='smallstate'
                textTransform='uppercase'
                letterSpacing='0.02em'
              >
                {student.grade || 'N/A'}
              </Text>
            </Flex>
          </Flex>
        ))}
      </Box>
      <Flex align='center' justify='space-between' py={7} mx={6} mb={20}>
        <Text textStyle='body.1' color='rgba(1, 0, 2, 0.56)'>
          Showing {currentPageContent.length} of {totalItems}
        </Text>
        <Flex align='center'>
          <Text textStyle='body.1' color='rgba(1, 0, 2, 0.56)'>
            Page {page} of {totalPages}
          </Text>
          {(page != 1 || page < totalPages) && (
            <Box ml={6}>
              {page !== 1 && (
                <IconButton
                  icon={<MdChevronLeft />}
                  aria-label='previous'
                  variant='icon.button'
                  mr={2}
                  borderWidth='0.5px'
                  borderStyle='solid'
                  borderColor='primary.2'
                  onClick={() => setPage(page - 1)}
                />
              )}
              {page < totalPages && (
                <IconButton
                  icon={<MdChevronRight />}
                  aria-label='next'
                  variant='icon.button'
                  borderWidth='0.5px'
                  borderStyle='solid'
                  borderColor='primary.2'
                  onClick={() => setPage(page + 1)}
                />
              )}
            </Box>
          )}
        </Flex>
      </Flex>
    </Layout>
  )
}

export default PreCourseTestPage
