import { AiFillFile } from 'react-icons/ai'
import {
  FaFilePdf,
  FaFileAudio,
  FaFileArchive,
  FaFileCsv,
  FaFileImage,
  FaFileVideo,
} from 'react-icons/fa'
import {
  RiFileExcel2Fill,
  RiFilePpt2Fill,
  RiFileWord2Fill,
} from 'react-icons/ri'
import { Icon } from '@chakra-ui/react'
import { Image } from '../Icons'

export type BasicFileIconProps = {
  extension: string
  iconProps?: Record<string, any>
}

export const BasicFileIcon = ({
  extension,
  iconProps = {},
}: BasicFileIconProps): JSX.Element => {
  switch (extension) {
    case 'doc':
    case 'docx':
      return <Icon as={RiFileWord2Fill} color='wordblue' {...iconProps} />
    case 'xls':
    case 'xlsx':
      return <Icon as={RiFileExcel2Fill} color='excelgreen' {...iconProps} />
    case 'ppt':
    case 'pptx':
    case 'ppsx':
      return <Icon as={RiFilePpt2Fill} color='pptorange' {...iconProps} />
    case 'pdf':
      return <Icon as={FaFilePdf} color='pdfred' {...iconProps} />
    case 'mp3':
    case 'mp4':
      return <Icon as={FaFileAudio} color='primary.1' {...iconProps} />
    case 'zip':
    case 'tar':
    case 'gz':
    case '7z':
      return <Icon as={FaFileArchive} color='primary.1' {...iconProps} />
    case 'csv':
      return <Icon as={FaFileCsv} color='primary.1' {...iconProps} />
    case 'png':
    case 'svg':
    case 'jpeg':
    case 'jpg':
    case 'gif':
      return <Image width='22' height='22' viewBox='8 8 24 24' />
    case 'webm':
    case 'mkv':
    case 'flv':
    case 'mov':
    case 'avi':
    case 'wmv':
    case 'mpeg':
    case 'mpg':
      return <Icon as={FaFileVideo} color='primary.1' {...iconProps} />
    default:
      return <Icon as={AiFillFile} color='primary.1' {...iconProps} />
  }
}

export default BasicFileIcon
