import styled from '@emotion/styled'

export const CustomListBox = styled.a(() => ({
  '> :not(:last-of-type)': {
    boxSizing: 'border-box',
    paddingBottom: '16px',
    paddingTop: '16px',
    borderBottomWidth: '0.5px',
    borderStyle: 'solid',
    borderColor: 'primary.2',
  },
  '> :first-of-type': {
    paddingTop: '0',
  },
  '> :last-of-type': {
    paddingTop: '16px',
  },
  '> :only-of-type': {
    padding: '0',
  },
}))
