import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormErrorIcon,
  Textarea,
  Button,
  IconButton,
  VStack,
  StackDivider,
  Text,
} from '@chakra-ui/react'
import { MdClose } from 'react-icons/md'
import { useForm } from 'react-hook-form'
import { validateSpaceOnly } from '../../utils'
import debounce from 'lodash-es/debounce'

type EditCommentInput = {
  id: string
  content: string
}

type EditCommentModalProps = {
  content: string
  isOpen: boolean
  onClose: () => void
  onSubmit: (data: EditCommentInput, e: any) => void
}

export const EditCommentModal = ({
  content,
  isOpen,
  onClose,
  onSubmit,
}: EditCommentModalProps) => {
  const {
    register,
    handleSubmit,
    errors,
    trigger,
    formState: { isValid },
  } = useForm<EditCommentInput>({ mode: 'onBlur' })

  const disableButton = (e: any) => {
    e.target.disabled = !isValid
  }

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size='xl'>
        <ModalOverlay />
        <ModalContent>
          <Flex
            w='100%'
            layerStyle='card.board'
            bg='white.1'
            direction='column'
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <VStack
                spacing={2}
                w='100%'
                divider={<StackDivider layerStyle='divider' />}
              >
                <Flex w='100%' px={3} pt={3} align='center'>
                  <IconButton
                    variant='icon.button'
                    aria-label='edit comment'
                    fontSize='24px'
                    icon={<MdClose />}
                    onClick={onClose}
                  />
                  <Text textStyle='h5' color='primary.1'>
                    Edit comment
                  </Text>
                </Flex>

                <Flex
                  direction='column'
                  w='100%'
                  justify='space-around'
                  px={6}
                  py={3}
                >
                  <VStack>
                    <FormControl isInvalid={!!errors.content?.message}>
                      <Textarea
                        resize='vertical'
                        defaultValue={content}
                        textStyle='body.1'
                        maxHeight='200px'
                        h='144px'
                        name='content'
                        ref={register({
                          maxLength: {
                            value: 1000,
                            message: 'Comment exceeded 1000 maximum characters',
                          },
                          validate: {
                            validateSpaceOnly,
                            changesMade: (value) => {
                              return value !== content && value !== ''
                            },
                          },
                        })}
                        onChange={debounce(async () => {
                          await trigger('content')
                        }, 500)}
                      />
                      {errors.content && (
                        <FormErrorMessage>
                          <FormErrorIcon />
                          {errors.content?.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>

                    <Flex w='100%' justify='flex-end'>
                      <Button
                        variant='base.primary'
                        type='submit'
                        onClick={disableButton}
                        textStyle='button'
                        minW='72px'
                        disabled={!isValid}
                      >
                        Save
                      </Button>
                    </Flex>
                  </VStack>
                </Flex>
              </VStack>
            </form>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  )
}
