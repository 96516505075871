import { useLazyQuery, useMutation, useSubscription } from '@apollo/client'
import { Box, Flex, Heading, useMediaQuery } from '@chakra-ui/react'
import {
  GET_SESSION_MESSAGES,
  SEND_SESSION_MESSAGE,
  SUBSCRIBE_SESSION_MESSAGE,
} from '../../client/queries'
import { ChatInput } from '../Forms'
import { v4 as uuid } from 'uuid'
import { useAlamContext } from '../../client'
import { toUTCString } from '../../utils'
import { useEffect, useRef, useState } from 'react'
import { Message, SessionUser } from '../../types'
import { Messages } from './index'
import { ancientTimestamp } from '../../constants'

export type SessionChatProps = {
  sessionId: string
  classId: string
  sidenav?: boolean
  sessionUsers: SessionUser[]
}

export const SessionChat = ({
  sessionId,
  classId,
  sidenav = false,
  sessionUsers,
}: SessionChatProps): JSX.Element => {
  const [messages, setMessages] = useState<Message[]>([])
  const [lastReceivedTime, setLastReceivedTime] = useState<string>(
    ancientTimestamp
  )
  const [lastReceivedId, setLastReceivedId] = useState<string>()
  const [isLargerThanMobile] = useMediaQuery('(min-width: 544px)')
  const { user } = useAlamContext()

  const [getMessages] = useLazyQuery(GET_SESSION_MESSAGES, {
    onCompleted: (data) => {
      const newMessages = data.messages

      if (newMessages.length >= 1) {
        const lastMessage = newMessages[newMessages.length - 1]
        setLastReceivedTime(lastMessage.inserted_at)
        setLastReceivedId(lastMessage.id)

        setMessages([...messages, ...newMessages])
      }

      scrollToBottom()
    },
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    setLastReceivedId(undefined)
    setMessages([])

    // Fetch all messages initially by passing an ancient date
    // Pass a random user uuid so messages are not filtered by user_id
    // Pass a random message uuid
    getMessages({
      variables: {
        session_id: sessionId,
        last_receieved_ts: ancientTimestamp,
        current_user_id: uuid(),
        last_received_id: uuid(),
      },
    })
  }, [sessionId, classId])

  const [sendMessage, { loading: messageIsSubmitting }] = useMutation(
    SEND_SESSION_MESSAGE
  )

  const handleSendMessage = async ({ text }: { text: string }) => {
    const timestamp = toUTCString(new Date())

    const message = {
      id: uuid(),
      class_id: classId,
      session_id: sessionId,
      sender_id: user?.id,
      text,
      inserted_at: timestamp,
      updated_at: timestamp,
    }

    try {
      await sendMessage({ variables: message })

      // optimisitic rendering
      setMessages([...messages, { ...message, sender: user }])
      scrollToBottom()
    } catch (error) {
      console.warn(error)
    }
  }

  // Subscription is only used for event notification
  // As soon as event occurs, useLazyQuery is called with timestamp of last recieved message and last message id
  // messages sent by the current user is filtered out because we already add the messages in the array manually for optimistic rendering
  // https://hasura.io/blog/building-a-realtime-chat-app-with-graphql-subscriptions-d68cd33e73f/
  useSubscription(SUBSCRIBE_SESSION_MESSAGE, {
    variables: {
      session_id: sessionId,
    },
    // Don't run subscription until getMessages is run compleletly once to avoid race condition
    skip: !lastReceivedId,
    onSubscriptionData: () => {
      getMessages({
        variables: {
          session_id: sessionId,
          last_receieved_ts: lastReceivedTime,
          current_user_id: user?.id,
          last_received_id: lastReceivedId,
        },
      })
    },
  })

  const messagesEndRef = useRef<HTMLDivElement>(null)
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }
  const scrollHook = (
    <Flex className='session-scroll-hook' ref={messagesEndRef} />
  )

  return (
    <>
      {isLargerThanMobile && (
        <Flex
          direction='column'
          maxW={sidenav ? '248px' : '100%'}
          minW={sidenav ? '248px' : '100%'}
          bg='white.1'
          minH='calc(100vh - 64px)'
          maxH='calc(100vh - 64px)'
          pos='sticky'
          top='64px'
        >
          <Box borderBottomWidth='0.5px' borderStyle='solid'>
            <Heading textStyle='h3' p='24px' w='100%' color='primary.1'>
              Session Chat
            </Heading>
          </Box>

          <Messages
            messages={messages}
            scrollHook={scrollHook}
            scrollToBottom={scrollToBottom}
          />

          <ChatInput
            isSubmitting={messageIsSubmitting}
            onSubmit={handleSendMessage}
            iconButtonProps={{
              visibility: 'visible',
              bg: 'white.1',
              sx: {
                ':disabled': {
                  color: 'primary.2',
                  opacity: '1',
                },
              },
            }}
            inputGroupProps={{
              sx: {
                'input + .right-element > .icon-button': {
                  color: 'primary.2',
                },
                'input:invalid + .right-element > .icon-button': {
                  color: 'primary.2',
                },
                'input:focus + .right-element > .icon-button': {
                  color: 'primary.1',
                },
                'input:focus + .right-element > .icon-button:disabled': {
                  color: 'primary.2',
                },
              },
            }}
            inputProps={{
              bg: 'white.1',
              _focus: {
                bg: 'white.1',
                borderWidth: '.5px',
              },
              _hover: {
                bg: 'white.1',
                borderWidth: '.5px',
              },
            }}
          />
        </Flex>
      )}
    </>
  )
}

export default SessionChat
