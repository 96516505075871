import React from 'react'
import { Icon } from '@chakra-ui/react'

export const Kinesthetic = (props: Record<string, any>): JSX.Element => {
  return (
    <Icon
      viewBox='0 0 13 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M8.12 9.8H13V8H9.38L7.38 4.67C7.08 4.17 6.54 3.83 5.92 3.83C5.74 3.83 5.58 3.86 5.42 3.91L0 5.6V10.8H1.8V7.13L3.91 6.47L0 21.8H1.8L4.67 13.69L7 16.8V21.8H8.8V15.39L6.31 10.85L7.04 7.98L8.12 9.8ZM8 3.6C9 3.6 9.8 2.8 9.8 1.8C9.8 0.8 9 2.98023e-09 8 2.98023e-09C7 2.98023e-09 6.2 0.8 6.2 1.8C6.2 2.8 7 3.6 8 3.6Z'
        fill='currentColor'
      />
    </Icon>
  )
}

export default Kinesthetic
