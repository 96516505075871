import React from 'react'
import styled from '@emotion/styled'
import {
  Text,
  Box,
  Flex,
  Icon,
  IconButton,
  HStack,
  VStack,
  StackDivider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  Tooltip,
} from '@chakra-ui/react'
import { MdPerson, MdFolderOpen, MdAssignmentTurnedIn } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { BsThreeDots } from 'react-icons/bs'
import { RiPencilFill } from 'react-icons/ri'
import { FaTrash } from 'react-icons/fa'
import { ConfirmationModal } from '../Modal'
import { EditGroup, EditGroupInput } from '../Forms'
import { useAlamContext } from '../../client'
import { UPDATE_GROUP, ARCHIVE_GROUP } from '../../client/queries'
import { useMutation } from '@apollo/client'
import {
  useErrorToast,
  useSuccessToast,
  isLargerThanTablet as isBiggerThanTablet,
  pluralize,
} from '../../utils'
import { Group } from '../../types'
import { RenderForUser } from '../SharedComponents'
import { Grades, Member } from '../Icons'

export interface GroupCardProps extends Group {
  memberCount: {
    aggregate: {
      count: number
    }
  }
  role?: string
  classId: string
  handleRefetchGroups: () => void
}

export const GroupCard = ({
  id,
  name,
  description,
  classId,
  memberCount: {
    aggregate: { count },
  },
  role = 'student',
  handleRefetchGroups,
}: GroupCardProps): JSX.Element => {
  const { classUser } = useAlamContext()
  const gradesPagePath =
    classUser?.role === 'student'
      ? `/classes/${classId}/grades/`
      : `/classes/${classId}/grades/group`
  const {
    isOpen: showEditGroup,
    onOpen: onOpenEditGroup,
    onClose: onCloseEditGroup,
  } = useDisclosure()
  const {
    isOpen: showDeleteGroup,
    onOpen: onOpenDeleteGroup,
    onClose: onCloseDeleteGroup,
  } = useDisclosure()

  const [updateGroup] = useMutation(UPDATE_GROUP)
  const [archiveGroup] = useMutation(ARCHIVE_GROUP)

  const handleDeleteGroup = () => {
    try {
      archiveGroup({
        variables: {
          id,
        },
      }).then(() => {
        useSuccessToast({
          title: 'Group deleted',
          message: `${name} was successfully deleted`,
        })
        handleRefetchGroups()
        onCloseDeleteGroup()
      })
    } catch (error) {
      console.warn(error)
      useErrorToast({ message: `Group wasn't deleted` })
    }
  }

  const handleUpdateGroup = ({
    groupName: name,
    description,
  }: EditGroupInput) => {
    try {
      updateGroup({
        variables: {
          id,
          changes: {
            name,
            description,
          },
        },
      }).then(() => {
        useSuccessToast({
          title: 'Group updated',
          message: `${name} was successfully updated`,
        })
        handleRefetchGroups()
        onCloseEditGroup()
      })
    } catch (error) {
      console.warn(error)
      useErrorToast({ message: `Group wasn't updated` })
    }
  }

  const isLargerThanTablet = isBiggerThanTablet()

  return (
    <>
      <EditGroup
        isOpen={showEditGroup}
        onClose={onCloseEditGroup}
        onSubmit={handleUpdateGroup}
        name={name}
        description={description}
      />
      <ConfirmationModal
        isOpen={showDeleteGroup}
        onClose={onCloseDeleteGroup}
        title='Are you sure?'
        message={`${name} will be deleted. This process is irreversible, and all data and content will be removed and no longer accessible.`}
        onSubmit={handleDeleteGroup}
      />
      <Box
        bg='white.1'
        shadow='md'
        borderRadius='8px'
        w={{ base: '100%', sm: '216px' }}
        h='216px'
        pt={5}
        pb='0.8rem'
        pos='relative'
        css={{
          '&:hover > .class-menu': {
            visibility: 'visible',
          },
        }}
      >
        {role !== 'student' && (
          <Flex
            pos='absolute'
            top={2}
            right={2}
            visibility={isLargerThanTablet ? 'hidden' : 'visible'}
            className='class-menu'
          >
            <Menu placement='bottom-end' autoSelect={false} variant='hoverMenu'>
              <MenuButton
                as={IconButton}
                aria-label='Group actions'
                icon={<BsThreeDots />}
                variant='icon.button'
              />
              <MenuList>
                <MenuItem icon={<RiPencilFill />} onClick={onOpenEditGroup}>
                  Edit
                </MenuItem>
                <MenuDivider />
                <MenuItem icon={<FaTrash />} onClick={onOpenDeleteGroup}>
                  Delete Group
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        )}
        <Flex direction='column' align='start' w='100%' h='100%'>
          <CustomLink href={`/classes/${classId}/groups/${id}`}>
            <Tooltip label={name} aria-label={name} placement='top-start'>
              <Text
                w='15ch'
                isTruncated
                textStyle='h4'
                color='primary.1'
                lineHeight='27px'
              >
                {name}
              </Text>
            </Tooltip>
            <Tooltip
              label={description}
              aria-label={description}
              placement='bottom'
            >
              <Text
                textStyle='caption'
                color='primary.2'
                fontSize='16px'
                lineHeight='20px'
                letterSpacing='0'
                wordBreak='break-word'
                isTruncated
                noOfLines={3}
              >
                {description}
              </Text>
            </Tooltip>
          </CustomLink>

          <RenderForUser roles={['admin', 'teacher', 'creator']}>
            <VStack
              divider={<StackDivider borderColor='grey.border' spacing='8px' />}
              justify='flex-end'
              w='100%'
              align='start'
              h='100%'
            >
              <HStack spacing={2} color='primary.1' px={4} pb='0.25rem'>
                <Member width='20' height='20' viewBox='8 8 24 24' />
                <Text>
                  {count} {pluralize('member', count)}
                </Text>
              </HStack>
              <Flex justify='flex-end' px={4} w='100%'>
                <HStack color='primary.1' spacing={4}>
                  <Link to={gradesPagePath}>
                    <Grades width='20' height='20' viewBox='8 8 24 24' />
                  </Link>
                </HStack>
              </Flex>
            </VStack>
          </RenderForUser>

          <RenderForUser roles={['student']}>
            <VStack justify='flex-end' w='100%' align='start' h='100%'>
              <HStack spacing={2} color='primary.1' px={4} pb='0.25rem'>
                <Member width='20' height='20' viewBox='8 8 24 24' />
                <Text>
                  {count} {pluralize('member', count)}
                </Text>
              </HStack>
            </VStack>
          </RenderForUser>
        </Flex>
      </Box>
    </>
  )
}

const CustomLink = styled.a(() => ({
  padding: '0 16px',
}))

export default GroupCard
