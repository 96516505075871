import { ClassUser, Message } from '../../types'
import { UserProfile } from '../Modal'
import { useDisclosure } from '@chakra-ui/react'
import { v4 as uuid } from 'uuid'
import {
  ChatSideBar,
  ClassUserItems,
  Chatroom,
  MessageInput,
  ChatHeading,
} from './index'
import { SidebarHeading } from '../Heading'
import { toUTCString } from '../../utils'
import { SEND_MESSAGE } from '../../client/queries'
import { useAlamContext } from '../../client'
import { useMutation } from '@apollo/client'
import { ManagePeople, ManagePeopleGroup } from '../ManagePeople'

export type ChatProps = {
  onlineStudents: ClassUser[]
  onlineTeachers: ClassUser[]
  offlineUsers: ClassUser[]
  managePeopleStudents: ClassUser[]
  managePeopleTeachers: ClassUser[]
  managePeopleAdmins: ClassUser[]
  selectedClassUser?: ClassUser
  updateSelectedClassUser: (classUser: ClassUser[]) => ClassUser | null
  scrollToBottom: (selectedClassUser?: ClassUser) => void
  handleUnselectClassUser: () => void
  handleSelectClassUser: (classUser?: ClassUser) => void
  scrollHook: JSX.Element
  title?: string
  type?: 'group' | 'class'
}

export const Chat = ({
  onlineTeachers,
  onlineStudents,
  offlineUsers,
  managePeopleStudents,
  managePeopleTeachers,
  managePeopleAdmins,
  selectedClassUser,
  updateSelectedClassUser,
  scrollToBottom,
  handleUnselectClassUser,
  handleSelectClassUser,
  scrollHook,
  title = 'Classroom',
  type = 'class',
}: ChatProps): JSX.Element => {
  const { classUser, user, managePeopleIsOpen } = useAlamContext()

  const {
    isOpen: userProfileIsOpen,
    onOpen: userProfileOnOpen,
    onClose: userProfileOnClose,
  } = useDisclosure()

  const [sendMessage, { loading: messageIsSubmitting }] = useMutation(
    SEND_MESSAGE
  )

  const handleSendMessage = async ({ text }: MessageInput) => {
    const timestamp = toUTCString(new Date())
    const message = {
      id: uuid(),
      class_id: classUser?.class?.id,
      sender_id: user?.id,
      receiver_id: selectedClassUser?.user?.id,
      text,
      inserted_at: timestamp,
      updated_at: timestamp,
    }

    sendMessage({
      variables: message,
    })

    // optimisitc rendering
    appendSentMessage({ ...message, sender: user }, selectedClassUser)

    scrollToBottom(selectedClassUser)
  }

  const appendSentMessage = (
    sentMessage: Message,
    selectedClassUser?: ClassUser
  ) => {
    if (selectedClassUser && selectedClassUser.user) {
      const newSelectedUser = {
        ...selectedClassUser,
        user: {
          ...selectedClassUser.user,
          messagesSent: [
            ...(selectedClassUser.user?.messagesSent || []),
            sentMessage,
          ],
        },
      }

      updateSelectedClassUser([newSelectedUser])
    }
  }

  if (selectedClassUser) {
    return (
      <>
        <UserProfile
          user={selectedClassUser?.user}
          isOpen={userProfileIsOpen}
          onClose={userProfileOnClose}
        />
        <ChatSideBar isChatroom>
          <Chatroom
            classUser={selectedClassUser}
            handleUnselectClassUser={handleUnselectClassUser}
            handleSendMessage={handleSendMessage}
            isSubmitting={messageIsSubmitting}
            scrollToBottom={scrollToBottom}
            scrollHook={scrollHook}
            userProfileOnOpen={userProfileOnOpen}
          />
        </ChatSideBar>
      </>
    )
  }

  if (managePeopleIsOpen && type === 'class') {
    return (
      <ChatSideBar>
        <ManagePeople
          managePeopleStudents={managePeopleStudents}
          managePeopleTeachers={managePeopleTeachers}
          managePeopleAdmins={managePeopleAdmins}
        />
      </ChatSideBar>
    )
  }

  if (managePeopleIsOpen && type === 'group') {
    return (
      <ChatSideBar>
        <ManagePeopleGroup
          managePeopleStudents={managePeopleStudents}
          managePeopleTeachers={managePeopleTeachers}
          managePeopleAdmins={managePeopleAdmins}
        />
      </ChatSideBar>
    )
  }

  return (
    <ChatSideBar>
      <ChatHeading title={title} />

      <SidebarHeading
        title='online'
        margin={
          onlineTeachers?.length === 0 && onlineStudents?.length === 0
            ? '0'
            : '0 0 16px'
        }
        titleProps={{ fontWeight: '600', letterSpacing: '0.1rem' }}
      />
      <ClassUserItems
        classUsers={onlineTeachers}
        title='Instructors'
        isOnline
        handleSelectClassUser={handleSelectClassUser}
      />
      <ClassUserItems
        classUsers={onlineStudents}
        title='Students'
        isOnline
        handleSelectClassUser={handleSelectClassUser}
      />

      <SidebarHeading title='offline' containerProps={{ margin: '0 0 4px' }} />
      <ClassUserItems
        classUsers={offlineUsers}
        handleSelectClassUser={handleSelectClassUser}
      />
    </ChatSideBar>
  )
}
