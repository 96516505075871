import React, { useState } from 'react'
import { Layout } from '../../components/Layout'
import {
  Container,
  Button,
  Flex,
  HStack,
  VStack,
  IconButton,
  useTheme,
  useMediaQuery,
  useDisclosure,
  Box,
  Text,
} from '@chakra-ui/react'
import { BulletinBoard } from '../../components/BulletinBoard'
import {
  RenderForUser,
  Loader,
  Indicator,
} from '../../components/SharedComponents'
import { BsPencilSquare } from 'react-icons/bs'
import { Posts } from '../../components/Post'
import { AlamAvatar } from '../../components/Avatar'
import {
  GET_GROUP_POSTS,
  CREATE_POST,
  GET_GROUP_BULLETIN_POSTS,
  UPDATE_POST,
  GET_PENDING_GROUP_REVIEWS,
  CREATE_ATTACHMENT,
  GET_GROUPS_ASSIGNMENTS_DUE_DATE,
} from '../../client/queries'
import { useMutation, useSubscription } from '@apollo/client'
import { v4 as uuid } from 'uuid'
import {
  getFullName,
  getFirstAndLastInitial,
  useErrorToast,
  useSuccessToast,
  formatDateToTimestamp,
  formatDaysWithinToTimestamp,
} from '../../utils'
import { MdSend } from 'react-icons/md'
import { CreatePost, CreatePostInput } from '../../components/Forms/'
import { useAlamContext } from '../../client'
import { routes } from '../../constants'
import { useHistory, useRouteMatch } from 'react-router-dom'
import HashLinkObserver from 'react-hash-link'
import { GroupAssignmentDueDate } from '../../types'
import { PencilBox } from '../../components/Icons'

export type GroupPageProps = {
  id: string
  classId: string
}

const GroupPage = ({ id, classId }: GroupPageProps) => {
  const { breakpoints } = useTheme()
  const [groupAssignmentsDueDate, setGroupAssignmentsDueDate] = useState<
    GroupAssignmentDueDate[]
  >([])
  const formattedAssignments = (
    groupAssignmentsDueDate: GroupAssignmentDueDate[]
  ) => {
    const result = groupAssignmentsDueDate.map((group) => {
      return {
        id: group.assignment.id,
        name: group.assignment.name,
        dueDateStr: group.assignment.dueDateStr,
      }
    })

    return result
  }

  const [mobileDevice] = useMediaQuery([`(max-width: ${breakpoints.sm})`])
  const [pendingReviewCount, setPendingReviewCount] = useState(0)
  const { user, groups, groupUser } = useAlamContext()
  const [group] = groups
  const { firstName, lastName } = user
  const history = useHistory()
  const { url } = useRouteMatch()
  const name = getFullName({ firstName, lastName })

  const now = new Date()
  const days = 7

  useSubscription(GET_GROUPS_ASSIGNMENTS_DUE_DATE, {
    variables: {
      group_id: id,
      current_date: formatDateToTimestamp(now),
      after_seven_days: formatDaysWithinToTimestamp(days, now),
    },
    onSubscriptionData: ({
      subscriptionData: {
        data: { groups },
      },
    }) => {
      setGroupAssignmentsDueDate(groups.assignment_groups)
    },
  })

  const [createPost, { data, loading: postIsSubmitting, error }] = useMutation(CREATE_POST)
  const topNavLeft = [{ type: 'text' as const, text: group?.name }]
  const topNavCenter = [
    {
      type: 'link' as const,
      url: `/classes/${classId}/groups/${id}`,
      text: 'Home',
    },
    {
      type: 'link' as const,
      url: `/classes/${classId}/groups/${id}/groupwork`,
      text: 'Group Work',
    },
  ]

  const [updatePost, { loading: postIsUpdating }] = useMutation(UPDATE_POST)

  const [
    createPostAttachments,
    { loading: attachmentIsSubmitting },
  ] = useMutation(CREATE_ATTACHMENT)

  const { data: postData, loading: postIsLoading } = useSubscription(
    GET_GROUP_POSTS,
    {
      variables: {
        group_id: id,
        class_id: classId,
      },
    }
  )

  const { data: bulletinData, loading: bulletinIsLoading } = useSubscription(
    GET_GROUP_BULLETIN_POSTS,
    {
      variables: { group_id: id },
    }
  )

  const posts = postData?.posts || []
  const bulletin = bulletinData?.bulletin || []

  const {
    isOpen: postFormIsOpen,
    onClose: postFormOnClose,
    onOpen: postFormOnOpen,
  } = useDisclosure()
  const {
    isOpen: announcementFormIsOpen,
    onClose: announcementFormOnClose,
    onOpen: announcementFormOnOpen,
  } = useDisclosure()

  const handlePostFormOpen = () => {
    announcementFormOnClose()
    postFormOnOpen()
  }

  type incompleteExamsCount = {
    incompleteExamsCount: {
      aggregate: {
        count: number
      }
    }
  }

  useSubscription(GET_PENDING_GROUP_REVIEWS, {
    variables: {
      class_id: classId,
      group_user_id: groupUser?.id,
    },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      const count = data.class.assignments?.reduce(
        (count: number, assignment: incompleteExamsCount) => {
          return assignment.incompleteExamsCount.aggregate.count + count
        },
        0
      )

      setPendingReviewCount(count)
    },
  })

  const handleDeletePost = async (
    postId: string,
    onCloseDeletePost: () => void
  ) => {
    try {
      await updatePost({
        variables: {
          id: postId,
          changes: {
            archived_at: 'NOW()',
          },
        },
      })

      useSuccessToast({
        title: 'Post Deleted',
      })
      onCloseDeletePost()
    } catch (error) {
      console.warn(error)
      useErrorToast({ message: 'Delete Post failed' })
    }
  }

  const onSubmitPost = async ({
    content,
    isPinned: is_pinned,
    files,
  }: CreatePostInput) => {
    const postId = uuid()
    const promises = [
      createPost({
        variables: {
          id: postId,
          content,
          is_pinned,
          user_id: user.id,
          group_id: id,
          is_loading: true,
        },
      }),
    ]

    if (files && files.length >= 1) {
      promises.push(submitPostAttachments(postId, files))
    }

    Promise.all(promises)
      .then(() => {
        return updatePost({
          variables: {
            id: postId,
            changes: {
              is_loading: false,
            },
          },
        })
      })
      .then(() => {
        postFormOnClose()
        useSuccessToast({ message: 'Post created' })
      })
      .catch((err) => {
        console.warn(err)
        postFormOnClose()
        useErrorToast({ message: 'Post creation failed' })
      })
  }

  const submitPostAttachments = async (postId: string, files: File[]) => {
    try {
      return Promise.all(
        files?.map((file) =>
          createPostAttachments({
            variables: {
              postId,
              file,
            },
          })
        )
      )
    } catch (err) {
      useErrorToast({ message: 'Upload failed' })
      return err
    }
  }

  const renderForms = () => {
    if (postFormIsOpen) {
      return (
        <CreatePost
          onClose={postFormOnClose}
          onSubmit={onSubmitPost}
          onDelete={() => {}}
          isSubmitting={
            postIsSubmitting || attachmentIsSubmitting || postIsUpdating
          }
          isGroup={true}
        />
      )
    }
  }
  return (
    <Layout
      showSideNav
      topNavLeft={topNavLeft}
      topNavCenter={topNavCenter}
      hasRightNav={routes.group.groupChat}
      redirectNonExistingClass
    >
      <Loader loaders={[postIsLoading]}>
        <Container
          maxWidth={{ base: '100%', md: '456px', lg: '500px', xl: '668px' }}
          py={6}
          px={{ base: 0, lg: 4 }}
          h='auto'
        >
          <Flex direction='column' w='100%' h='100%'>
            <VStack spacing={6} h='100%'>
              <VStack spacing='16px' w='100%'>
                {pendingReviewCount >= 1 && (
                  <Indicator
                    onClick={() => {
                      history.push(`${url}/groupwork`)
                    }}
                    title={`To Complete (${pendingReviewCount})`}
                    subtitle='Please review the assignments or materials for the class'
                  />
                )}

                <BulletinBoard
                  classId={classId}
                  posts={bulletin}
                  title='Bulletin Board'
                  isGroup={true}
                  assignmentsDueDate={formattedAssignments(
                    groupAssignmentsDueDate
                  )}
                />
              </VStack>

              {mobileDevice ? (
                <RenderForUser roles={['admin', 'teacher', 'creator']}>
                  <HStack
                    spacing={5}
                    mt='0 !important'
                    w='100%'
                    display={
                      postFormIsOpen || announcementFormIsOpen ? 'none' : 'flex'
                    }
                    px={{ base: 4, lg: 0 }}
                  >
                    <IconButton
                      variant='base.primary'
                      icon={
                        <PencilBox
                          fill='#ffffff'
                          width='24'
                          height='24'
                          viewBox='8 8 24 24'
                        />
                      }
                      aria-label='Create post button'
                      flexGrow={1}
                      fontSize='20px'
                      onClick={handlePostFormOpen}
                    />
                  </HStack>
                </RenderForUser>
              ) : (
                <RenderForUser roles={['admin', 'teacher', 'creator']}>
                  <HStack
                    spacing={4}
                    w='100%'
                    display={
                      postFormIsOpen || announcementFormIsOpen
                        ? 'none'
                        : 'block'
                    }
                  >
                    <Button
                      variant='base.primary'
                      leftIcon={
                        <PencilBox
                          fill='#ffffff'
                          width='20'
                          height='20'
                          viewBox='8 8 24 24'
                        />
                      }
                      textStyle='body.1'
                      fontWeight='bold'
                      onClick={handlePostFormOpen}
                      w='146px'
                    >
                      Create Post
                    </Button>
                  </HStack>
                </RenderForUser>
              )}

              <RenderForUser roles={['student']}>
                <Box
                  layerStyle='card.board'
                  bg='white.1'
                  w='100%'
                  px={4}
                  py={3}
                  onClick={handlePostFormOpen}
                  display={postFormIsOpen ? 'none' : 'block'}
                  cursor='pointer'
                >
                  <Flex w='100%'>
                    <HStack spacing={4} w='100%'>
                      <AlamAvatar
                        size='sm'
                        src={user.avatar}
                        name={getFirstAndLastInitial(user)}
                      />

                      <Flex align='center' justify='space-between' w='100%'>
                        <Text color='grey.1' textStyle='body.1'>
                          Share something with the class.
                        </Text>

                        <IconButton
                          className='icon-button'
                          aria-label='submit post'
                          bg='none'
                          _hover={{
                            bg: 'none',
                          }}
                          icon={<MdSend />}
                        />
                      </Flex>
                    </HStack>
                  </Flex>
                </Box>
              </RenderForUser>

              {renderForms()}

              <HashLinkObserver isPageLoading={postIsLoading} />
              <Posts
                posts={posts}
                postsFor='Group'
                handleDeletePost={handleDeletePost}
              />
            </VStack>
          </Flex>
        </Container>
      </Loader>
    </Layout>
  )
}

export default GroupPage
