import { useMutation } from '@apollo/client'
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  FormControl,
  FormErrorMessage,
  FormErrorIcon,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { v4 as uuid } from 'uuid'
import { useAlamContext } from '../../client'
import {
  CREATE_ATTACHMENT,
  CREATE_DOCUMENT,
  CREATE_LINK,
} from '../../client/queries'
import { ClassUser, Session, SessionDocument, SessionUser } from '../../types'
import { useErrorToast, useSuccessToast, useFilename } from '../../utils'
import { AttachButton } from '../File'
import {
  createFolderStructure,
  buildFileLink,
  buildSessionMemberPermissions,
  createDriveDocument,
  createPermissions,
  useGoogleAPI,
} from '../GoogleAPI'
import { GoogleDocs } from '../Icons'
import { Documents } from './index'
import { logout } from '../Layout/SideNavLayout'
import { useDropzone, FileRejection } from 'react-dropzone'
import { FaLink, FaFileImage } from 'react-icons/fa'
import { fileSizeValidation } from '../../utils'
import { CreateLinkForm, CreateLinkInput } from '../Forms'
import { hasuraErrorSignature } from '../../utils/toast'

export type SessionFile = {
  session: Session
  refetch: () => void
  setSession: (session: Session) => void
  sessionFolderId?: string
  setSessionFolderId: (folderId: string) => void
}

export const Files = ({
  session,
  refetch,
  setSession,
  sessionFolderId,
  setSessionFolderId,
}: SessionFile) => {
  const [fileIsCreating, setFileIsCreating] = useState(false)
  const [rejections, setRejections] = useState<FileRejection[]>([])
  const { isAuthorized, tokenIsExpired } = useGoogleAPI()
  const { user, teachers, classUser } = useAlamContext()
  const className = classUser?.class?.name
  const { documents, session_users } = session
  const {
    isOpen: showAddLink,
    onOpen: onOpenAddLink,
    onClose: onCloseAddLink,
  } = useDisclosure()

  const [createDocument] = useMutation(CREATE_DOCUMENT)
  const [createAttachment, { loading: createAttachmentLoading }] = useMutation(
    CREATE_ATTACHMENT,
    {
      onCompleted: ({ createAttachment: attachment }) => {
        const { filename, extension } = useFilename(attachment.file)
        useSuccessToast({
          title: `${decodeURIComponent(filename)}.${extension}`,
          message: 'File upload successful',
        })

        setSession({
          ...session,
          attachments: [
            ...session.attachments,
            {
              ...attachment,
              file: decodeURIComponent(attachment.file),
            },
          ],
        })
      },
    }
  )
  const [createLink, { loading: createLinkLoading }] = useMutation(CREATE_LINK)

  const handleShareFile = async ({
    sessionMembers,
    teachers,
    fileId,
  }: {
    sessionMembers: SessionUser[]
    teachers: ClassUser[]
    fileId: string
  }) => {
    try {
      const permissions = buildSessionMemberPermissions([
        ...sessionMembers,
        ...teachers,
      ])

      await createPermissions({
        fileId: fileId,
        permissions,
      })
    } catch (error) {
      return error
    }
  }

  const handleCreateOnClick = () => {
    if (user?.provider && user?.provider === 'google') {
      handleCreateDriveDoc()
    }
  }

  const createDriveFolders = async () => {
    if (
      !className ||
      !session.name ||
      !isAuthorized ||
      !user ||
      tokenIsExpired()
    ) {
      return
    }
    const folderIds = await createFolderStructure({
      folderNames: ['Alam-edu', className, session.name],
    })
    const folderId = folderIds[folderIds.length - 1]
    setSessionFolderId(folderId)
    return folderId
  }

  const handleCreateDriveDoc = async () => {
    if (!isAuthorized) {
      useErrorToast({
        message: 'Google access token expired. Please login again',
      })
      return
    }

    const folderId = sessionFolderId || (await createDriveFolders())

    setFileIsCreating(true)
    const index = getNewIndex(documents)
    const name = `Document${index}`
    const parents = [folderId]

    try {
      // Create google doc
      const file_id = await createDriveDocument({ name, parents })

      // share google doc
      handleShareFile({
        sessionMembers: session_users,
        teachers,
        fileId: file_id,
      })

      // Store in db
      await handleCreateDocument({
        file_id,
        index,
        name,
        url: buildFileLink(file_id),
      })

      refetch()

      useSuccessToast({ message: 'Document successfully created' })
    } catch (error) {
      const hasuraError = error as hasuraErrorSignature

      useErrorToast({ error: hasuraError })
    }
    setFileIsCreating(false)
  }

  // documents are sorted by index in asc order
  // loop til you find an index without a document and use that as the new index
  const getNewIndex = (documents: SessionDocument[]) => {
    let newIndex
    let currentIndex = 1
    // array indexes start at 0
    let arrayIndex = currentIndex - 1

    while (!newIndex) {
      if (!documents[arrayIndex]) {
        newIndex = currentIndex
        return newIndex
      }

      if (documents[arrayIndex].index === currentIndex) {
        currentIndex += 1
        arrayIndex += 1
      } else {
        newIndex = currentIndex
      }
    }

    return newIndex
  }

  // store document details in db
  const handleCreateDocument = (params: {
    file_id: string
    index: number
    name: string
    url: string
  }) => {
    const id = uuid()

    const document = {
      ...params,
      id,
      inserted_at: 'NOW()',
      session_id: session.id,
      updated_at: 'NOW()',
      user_id: user?.id,
    }

    setSession({
      ...session,
      documents: [...session.documents, { ...document, user: user }],
    })

    return createDocument({
      variables: { document },
    })
  }

  const handleUploadFile = (files: File[]) => {
    Promise.all(
      files.map((file) => {
        createAttachment({
          variables: {
            sessionId: session.id,
            file,
          },
        })
      })
    ).catch((error) => {
      console.warn(error)
    })
  }

  const { getInputProps, getRootProps, fileRejections } = useDropzone({
    noDragEventsBubbling: true,
    validator: fileSizeValidation,
    onDropAccepted: (files) => handleUploadFile(files),
    onDropRejected: (rejections) => setRejections(rejections),
  })

  useEffect(() => {
    rejections.forEach((rejection) => {
      const { filename, extension } = useFilename(rejection.file.name)
      useErrorToast({
        title: `${decodeURIComponent(filename)}.${extension}`,
        message: 'Upload failed - File exceed upload limit of 50MB.',
      })
    })
  }, [rejections])

  useEffect(() => {
    if (fileRejections.length === 0) {
      setRejections([])
    }
  }, [fileRejections])

  const handleCreateLink = async ({ name, url }: CreateLinkInput) => {
    try {
      const linkId = uuid()

      await createLink({
        variables: {
          link_id: linkId,
          name,
          url,
          session_id: session.id,
        },
      })

      useSuccessToast({ message: 'Link was successfully created' })
      onCloseAddLink()
    } catch (error) {
      console.warn(error)
      useErrorToast({ message: 'Link name already exists' })
    }
  }

  return (
    <Box width='100%' minH='calc(100vh - 64px)' p='24px'>
      <Heading textStyle='h2' w='100%' mb='4px' color='primary.1'>
        Files
      </Heading>
      <Text
        textStyle='body.1'
        mb='16px'
        color='grey.session'
        letterSpacing='0.02em'
      >
        Session members can collaborate together on the same google files.
      </Text>

      <Documents
        documents={session.documents}
        refetch={refetch}
        attachments={session.attachments}
        links={session.links}
      />

      {/*
        <Flex mb='24px'>
          <FormControl isInvalid={hasRejections}>
          {hasRejections &&
            <FormErrorMessage>
            <FormErrorIcon />
            Some files could not be uploaded as the file size exceeds 50 MB. The maximum upload file size is 50 MB.
              </FormErrorMessage>
          }
            </FormControl>
          </Flex>
      */}
      <Flex justify='flex-end'>
        <Menu closeOnSelect>
          <MenuButton
            as={Button}
            aria-label='Document Create'
            variant='base.primary'
            textStyle='button'
            loadingText='Creating'
            isLoading={
              createAttachmentLoading || createLinkLoading || fileIsCreating
            }
          >
            Add File
          </MenuButton>
          <MenuList>
            <MenuItem
              as={Button}
              icon={<FaLink />}
              variant='icon.button.md'
              onClick={onOpenAddLink}
            >
              URL Link
            </MenuItem>
            <input name='files' {...getInputProps()} />
            <MenuItem
              {...getRootProps({
                as: Button,
                icon: <FaFileImage />,
                variant: 'icon.button.md',
              })}
            >
              File From Desktop
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>

      <CreateLinkForm
        isOpen={showAddLink}
        onClose={onCloseAddLink}
        onSubmit={handleCreateLink}
        isSubmitting={createLinkLoading}
      />
    </Box>
  )
}

export default Files
