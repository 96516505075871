import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { AssignmentDueDate, Post } from '../../types'
import {
  Flex,
  VStack,
  StackDivider,
  Box,
  Text,
  MenuItem,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react'
import { HoverMenu, parentHoverCss } from '../Menu'
import { BulletinItem } from './BulletinItem'
import { RenderForUser } from '../SharedComponents'
import { format } from 'date-fns'
import { useAlamContext } from '../../client'

import { RiPencilFill } from 'react-icons/ri'
import { Assignment, ChevronDown, ChevronUp } from '../Icons'
import { utcToLocale } from '../../utils'

export type BulletinBoardProps = {
  classId?: string
  posts: Post[]
  title: string
  isGroup?: boolean
  assignmentsDueDate?: AssignmentDueDate[]
}

export const BulletinBoard = ({
  classId,
  posts,
  title,
  isGroup = false,
  assignmentsDueDate,
}: BulletinBoardProps): JSX.Element => {
  const [editMode, setEditMode] = useState(false)
  const [newTitle, setTitle] = useState(title)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { classUser } = useAlamContext()

  const toggleEditMode = () => {
    setEditMode(!editMode)

    if (!editMode) {
      setTitle('Manage Bulletin Board')
    } else {
      setTitle('Bulletin Board')
    }
  }

  const editPermissions = isGroup
    ? ['student', 'teacher', 'admin', 'creator']
    : ['teacher', 'admin', 'creator']

  const renderHoverMenu = () => {
    if (posts.length >= 1) {
      return (
        <HoverMenu top={4} right={4}>
          <MenuItem
            icon={<RiPencilFill />}
            onClick={() => {
              toggleEditMode()
            }}
          >
            Edit
          </MenuItem>
        </HoverMenu>
      )
    }
  }

  const renderDoneMenu = () => {
    return (
      <Flex
        pos='absolute'
        cursor='pointer'
        top={6}
        right={5}
        color='primary.1'
        onClick={() => toggleEditMode()}
        fontSize='12px'
        fontWeight='700'
      >
        DONE
      </Flex>
    )
  }

  return (
    <Box
      w='100%'
      layerStyle='card.board'
      borderRadius={{ base: '0', lg: '12px' }}
      bg='white.1'
      css={parentHoverCss}
      mb={6}
      boxShadow='rgb(0 0 0 / 10%) 0px 4px 6px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px'
      borderColor='grey.border'
    >
      <RenderForUser roles={editPermissions}>
        {!editMode ? renderHoverMenu() : renderDoneMenu()}
      </RenderForUser>

      <VStack
        spacing={2}
        w='100%'
        divider={<StackDivider layerStyle='divider.nospace' />}
        css={{
          a: {
            width: '100%',
          },
        }}
      >
        <Flex textStyle='h4' w='100%' px={6} pt={6} pb={4}>
          {newTitle}
        </Flex>

        {assignmentsDueDate && classUser?.role === 'student' && (
          <React.Fragment>
            <Flex
              w='100%'
              pr={6}
              pl={2}
              py={2}
              bg='primary.1'
              color='white.1'
              align='center'
            >
              {isOpen ? (
                <IconButton
                  w='20px'
                  h='20px'
                  icon={
                    <ChevronUp
                      fill='#ffffff'
                      width='20'
                      height='20'
                      viewBox='8 8 24 24'
                    />
                  }
                  colorScheme='ghost'
                  color='white.1'
                  aria-label='collapse items'
                  onClick={onClose}
                />
              ) : (
                <IconButton
                  w='20px'
                  h='20px'
                  icon={
                    <ChevronDown
                      fill='#ffffff'
                      width='20'
                      height='20'
                      viewBox='8 8 24 24'
                    />
                  }
                  colorScheme='ghost'
                  color='white.1'
                  aria-label='collapse items'
                  onClick={onOpen}
                />
              )}
              <Box textStyle='body.1' fontWeight='600'>
                Upcoming ({assignmentsDueDate?.length})
              </Box>
            </Flex>

            {isOpen &&
              assignmentsDueDate?.map((assignment) => (
                <Link to={`/classes/${classId}/assignments/${assignment.id}`}>
                  <Flex
                    w='100%'
                    px={6}
                    py={2}
                    bg='grey.2'
                    justify='space-between'
                    align='center'
                    color='primary.1'
                    borderBottom='1px solid'
                    borderColor='grey.border'
                  >
                    <Flex align='center'>
                      <Box mr={2}>
                        <Assignment
                          width='16'
                          height='16'
                          viewBox='8 8 24 24'
                        />
                      </Box>
                      <Text textStyle='body.1' fontSize='12px'>
                        {assignment.name}
                      </Text>
                    </Flex>
                    <Box textStyle='body.1' fontSize='12px'>
                      Due date:{' '}
                      {format(utcToLocale(assignment?.dueDateStr), 'MMM d, y')},{' '}
                      {format(utcToLocale(assignment?.dueDateStr), 'k:mm')} UTC
                    </Box>
                  </Flex>
                </Link>
              ))}
          </React.Fragment>
        )}

        {posts.length === 0 && (
          <Flex w='100%' minH='192px' align='center' justify='center'>
            <Text textStyle='body.big' color='primary.2'>
              There are no pinned posts
            </Text>
          </Flex>
        )}

        {posts.map((post) => {
          return <BulletinItem post={post} editMode={editMode} />
        })}
      </VStack>
    </Box>
  )
}

export default BulletinBoard
