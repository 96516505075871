import { Fragment, useState, useMemo, useEffect } from 'react'
import {
  Container,
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  Text,
  HStack,
  IconButton,
} from '@chakra-ui/react'
import { IoChevronDown } from 'react-icons/io5'
import { AlamAvatar } from '../../components/Avatar'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import {
  BarChart,
  CartesianGrid,
  CartesianAxis,
  YAxis,
  XAxis,
  Bar,
  ResponsiveContainer,
  LabelList,
  Label,
} from 'recharts'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import format from 'date-fns/format'
import { GET_ASSIGNMENT_REPORT } from '../../client/queries'
import {
  StudentWithGradesData,
  GroupWithGradesData,
  buildStudentGradesData,
  buildGroupGradesData,
  getGradesData,
  gradesSortDisplay,
  usePagination,
  renderSubmissionDate,
  sortStudentData,
  buildAnalyticsCrumbs,
} from '../../utils'
import textStyles from '../../theme/textStyles'
import { Select } from '../../components/Input'
import { Layout } from '../../components/Layout'
import { TopNavItemProps } from '../../components/TopNav'
import {
  RespondentCount,
  IndividualScoreAnalyticsTable,
  GroupScoreAnalyticsTable,
} from '../../components/Analytics'
import { useAlamContext } from '../../client'

const AssignmentScorePage = () => {
  const [sortBy, setSortBy] = useState('a-z')
  const [dataDisplay, setDataDisplay] = useState<string>('students')
  const params = useParams<{
    id: string
    topic_id?: string
    assignment_id: string
  }>()
  const { id, topic_id, assignment_id } = params
  const { classUser } = useAlamContext()

  const sortOptions = [
    { value: 'a-z', display: 'Alphabetical A-Z' },
    { value: 'z-a', display: 'Alphabetical Z-A' },
  ]
  const history = useHistory()
  const tableHeading = ['a', 'b', 'c']
  const { data, refetch } = useQuery(GET_ASSIGNMENT_REPORT, {
    variables: { assignment_id: assignment_id },
  })
  const assignment = data?.assignments_by_pk
  const assignmentStudents =
    data?.assignments_by_pk?.assignment_users?.map(
      (assignmentUser: any) => assignmentUser.user
    ) || []
  const assignmentGroups =
    data?.assignments_by_pk?.assignment_groups?.map(
      (assignmentGroup: any) => assignmentGroup.group
    ) || []

  const studentData = assignmentStudents.map((student: any) => {
    return buildStudentGradesData(student)
  })

  const groupData = assignmentGroups.map((group: any) => {
    return buildGroupGradesData(group)
  })

  const gradedStudentCount =
    studentData?.filter(({ grade }: StudentWithGradesData) => grade).length || 0
  const gradedGroupCount =
    groupData?.filter(({ grade }: GroupWithGradesData) => grade).length || 0

  const respondentCount = gradedStudentCount + gradedGroupCount

  const studentCount = studentData?.length || 0
  const groupCount = groupData?.length || 0
  const totalCount = studentCount + groupCount

  const gradesGraphData = getGradesData(
    [...studentData, ...groupData],
    assignment?.rubric
  )

  const topNavLeft: TopNavItemProps[] = [
    {
      type: 'breadcrumbs',
      crumbs: buildAnalyticsCrumbs(
        params,
        {
          topicName: assignment?.topic?.name,
          className: classUser?.class?.name,
          assignmentName: assignment?.name,
        },
        'score'
      ),
    },
  ]

  const dataOption = [
    {
      value: 'students',
      display: 'Class',
    },
    {
      value: 'groups',
      display: 'Groups',
    },
  ]

  return (
    <Layout showSideNav topNavLeft={topNavLeft}>
      <Box px={6} pt={6} mb={8}>
        <Text textStyle='h2' color='black.1'>
          Scores
        </Text>
        <RespondentCount
          respondentCount={respondentCount}
          totalCount={totalCount}
        />
      </Box>
      <Container
        maxW={{ base: '100%', md: '456px', lg: '500px', xl: '1080px' }}
        px={0}
        mb={6}
      >
        <Box
          h='496px'
          bg='white.1'
          borderRadius='12px'
          borderWidth='0.5px'
          borderStyle='solid'
          borderColor='primary.2'
          py={7}
          pr={5}
          pl={8}
        >
          {assignment && gradesGraphData ? (
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart
                data={gradesGraphData}
                layout='horizontal'
                margin={{ left: -15, bottom: 30 }}
              >
                <CartesianGrid strokeDasharray='1' vertical={false} />
                <YAxis
                  dataKey='students'
                  type='number'
                  style={{
                    ...textStyles.body['2'],
                    fontWeight: 400,
                    fill: '#0100028f',
                  }}
                  tickLine={false}
                >
                  <Label
                    value='No. of students'
                    position='insideTopLeft'
                    angle={-90}
                    style={{
                      ...textStyles.body['2'],
                      fontWeight: 400,
                      fontSize: '14px',
                      fill: '#0100028f',
                    }}
                    offset={15}
                    dy={85}
                  />
                </YAxis>

                <XAxis
                  type='category'
                  dataKey='score'
                  style={{
                    ...textStyles.body['2'],
                    fontWeight: 400,
                    color: '#0100028f',
                  }}
                  tickLine={false}
                >
                  <Label
                    value='Scores'
                    position='insideBottomRight'
                    style={{
                      ...textStyles.body['2'],
                      fontWeight: 400,
                      fontSize: '14px',
                      fill: '#0100028f',
                    }}
                    offset={0}
                    dy={8}
                  />
                </XAxis>
                <Bar dataKey='students' barSize={40} fill='#CE1032' />
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <Flex align='center' justify='center' w='100%' h='100%'>
              <Text
                textStyle='body.2'
                color='black.3'
                fontSize='16px'
                fontWeight='600'
              >
                Not enough data
              </Text>
            </Flex>
          )}
        </Box>
      </Container>
      <Box px={6} py={4}>
        <Select
          value={dataDisplay}
          options={dataOption}
          onChange={setDataDisplay}
          menuButtonProps={{
            as: Button,
            rightIcon: <IoChevronDown />,
            textStyle: 'button',
            variant: 'tab.selector',
            p: '8px 12px',
            textAlign: 'left',
            letterSpacing: '0.06rem',
            w: 'auto',
            textTransform: 'uppercase',
            layerStyle: 'card.module',
            mr: 4,
            color: 'primary.1',
          }}
          menuItemProps={{
            textStyle: 'body.1',
            color: 'primary.1',
            textTransform: 'uppercase',
            w: '196px',
            _hover: { bg: 'hover.2', color: 'white.1' },
          }}
          adjustRightIconPadding='1rem'
        />
        <Select
          value={sortBy}
          onChange={(value) => setSortBy(value)}
          options={sortOptions}
          menuButtonProps={{
            textStyle: 'button',
            variant: 'tab.selector',
            textAlign: 'left',
            py: '8px',
            pr: '8px',
            w: '196px',
            layerStyle: 'card.module',
            color: 'primary.1',
          }}
          menuItemProps={{
            textStyle: 'body.1',
            color: 'primary.1',
            w: '196px',
            _hover: { bg: 'hover.2', color: 'white.1' },
          }}
          displayTransform={gradesSortDisplay}
        />
      </Box>
      {dataDisplay === 'students' && (
        <IndividualScoreAnalyticsTable students={studentData} sortBy={sortBy} />
      )}
      {dataDisplay === 'groups' && (
        <GroupScoreAnalyticsTable groups={groupData} sortBy={sortBy} />
      )}
    </Layout>
  )
}

export default AssignmentScorePage
