import React, { useRef, useState, useMemo } from 'react'
import { Layout } from '../../components/Layout'
import styled from '@emotion/styled'
import {
  Box,
  IconButton,
  Flex,
  HStack,
  VStack,
  StackDivider,
  Input,
  InputGroup,
  InputRightElement,
  Container,
  Text,
  useDisclosure,
  useMediaQuery,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react'
import { Assessments } from '../../components/Assessment'
import { useMutation } from '@apollo/client'
import { CREATE_ASSESSMENT } from '../../client/queries'
import { v4 as uuid } from 'uuid'
import { useAlamContext } from '../../client'
import {
  getFullName,
  useErrorToast,
  useSuccessToast,
  isLargerThanTablet,
  getFirstAndLastInitial,
} from '../../utils'
import { MdSend } from 'react-icons/md'
import { Exam } from '../../types'
import { AssignmentType } from './AssignmentPage'
import { TopNavItemProps } from '../../components/TopNav'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import { AlamAvatar } from '../../components/Avatar'
import { Loader, RenderForUser } from '../../components/SharedComponents'
import { FeedbackForm } from '../../components/Forms/'
import { ExamIndicators } from '../../components/Exam'
import { IoChevronDown } from 'react-icons/io5'

export type FeedbackPage = {
  assignmentId: string
  assignment?: AssignmentType
  topNavLeft: TopNavItemProps[]
  assignmentNav: TopNavItemProps[]
  loading: boolean[]
  pendingExams: Exam[]
}

const FeedbackPage = ({
  assignment,
  assignmentId,
  topNavLeft,
  assignmentNav,
  loading,
  pendingExams,
}: FeedbackPage): JSX.Element => {
  const [isAnonymous, setIsAnonymous] = useState(false)
  const { user: currentUser, classUser } = useAlamContext()
  const { firstName, lastName, avatar } = currentUser
  const fullName = getFullName({ firstName, lastName })
  const initials = getFirstAndLastInitial({ firstName, lastName })
  const inputRef = useRef<HTMLInputElement>(null)
  const [createFeedback, { loading: feedbackIsSubmitting }] = useMutation(
    CREATE_ASSESSMENT
  )
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleSubmitFeedback = ({ content }: any) => {
    createFeedback({
      variables: {
        id: uuid(),
        content: content,
        user_id: currentUser.id,
        assignment_id: assignmentId,
        is_anonymous: isAnonymous,
      },
    })
      .then(() => {
        onClose()
        useSuccessToast({ message: 'Feedback created' })
      })
      .catch(() => {
        useErrorToast({ message: 'Creating feedback failed' })
      })
  }

  const viewableAssessments = useMemo(() => {
    if (classUser?.role === 'student') {
      return assignment?.assessments?.filter((assessment) => {
        return (
          assessment?.user?.id === currentUser?.id || !assessment.is_anonymous
        )
      })
    }

    return assignment?.assessments
  }, [assignment?.assessments, classUser])

  return (
    <Layout
      showSideNav
      topNavLeft={topNavLeft}
      assignmentNav={assignmentNav}
      redirectNonExistingClass
    >
      <Loader loaders={loading}>
        <Container
          maxWidth={isLargerThanTablet() ? '768px' : '100%'}
          py={6}
          pt={{ base: '16px', sm: '40px' }}
          h='auto'
          px={{ base: '0px', sm: '50px' }}
        >
          <Flex direction='column' h='100%' w='100%'>
            {pendingExams.length > 0 && <ExamIndicators exams={pendingExams} />}

            <Flex marginBottom={{ base: 'none', sm: '8px' }}>
              <Box
                layerStyle='card.board'
                bg='white.1'
                w='100%'
                pt={3}
                pr={2}
                pb={3}
                pl={4}
                display={isOpen ? 'none' : 'block'}
                cursor='pointer'
                borderRadius={{ base: 'none', sm: '12px' }}
                border='0.5px solid #e4e4e4'
                boxShadow={{
                  base: 'none',
                  sm:
                    'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
                }}
              >
                <Flex w='100%'>
                  <HStack spacing={4} w='100%'>
                    <RenderForUser roles={['student']}>
                      <Menu placement='bottom-start'>
                        <MenuButton
                          as={Button}
                          aria-label='create feedback as'
                          variant='icon.button'
                          rightIcon={<IoChevronDown />}
                        >
                          {isAnonymous ? (
                            <AlamAvatar size='md' bg='primary.1' />
                          ) : (
                            <AlamAvatar
                              size='md'
                              name={initials}
                              src={avatar}
                            />
                          )}
                        </MenuButton>
                        <MenuList>
                          <MenuItem onClick={() => setIsAnonymous(false)}>
                            <AlamAvatar
                              size='sm'
                              name={initials}
                              src={avatar}
                              mr='14px'
                            />
                            {fullName}
                          </MenuItem>
                          <MenuItem onClick={() => setIsAnonymous(true)}>
                            <AlamAvatar size='sm' mr='14px' bg='primary.1' />
                            Anonymous
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </RenderForUser>

                    <RenderForUser roles={['admin', 'teacher', 'creator']}>
                      <AlamAvatar size='md' name={initials} src={avatar} />
                    </RenderForUser>

                    <Flex
                      align='center'
                      justify='space-between'
                      w='100%'
                      onClick={onOpen}
                    >
                      <Text color='grey.1' textStyle='body.big'>
                        Share feedback with class.
                      </Text>

                      <IconButton
                        className='icon-button'
                        aria-label='submit post'
                        bg='none'
                        _hover={{
                          bg: 'none',
                        }}
                        icon={<MdSend />}
                      />
                    </Flex>
                  </HStack>
                </Flex>
              </Box>
            </Flex>

            {isOpen && (
              <FeedbackForm
                onClose={onClose}
                onSubmit={handleSubmitFeedback}
                isSubmitting={feedbackIsSubmitting}
                isAnonymous={isAnonymous}
              />
            )}

            <Flex
              marginTop='16px'
              layerStyle='card.base'
              overflow='hidden'
              borderRadius={{ base: 'none', sm: '12px' }}
              border='0.5px solid #e4e4e4'
              boxShadow={{
                base: 'none',
                sm: '0px 4px 16px rgba(23, 5, 61, 0.05)',
              }}
            >
              <Flex spacing={2} w='100%' direction='column'>
                <Flex textStyle='h4' w='100%' pt={6} pr={4} pb={6} pl={6}>
                  Feedback
                </Flex>
                <Flex w='100%'>
                  {viewableAssessments?.length === 0 || !viewableAssessments ? (
                    <Flex
                      w='100%'
                      minH='192px'
                      align='center'
                      justify='center'
                      direction='column'
                      borderTop='1px solid'
                      borderColor='grey.border'
                      marginTop='0px !important'
                    >
                      <Text textStyle='body.big' color='primary.2'>
                        There are no feedbacks yet.
                      </Text>
                    </Flex>
                  ) : (
                    <Assessments assessments={assignment?.assessments} />
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Container>
      </Loader>
    </Layout>
  )
}

const FormWrapper = styled(Box)`
  width: 100%;
  .form {
    width: 100%;
  }
`

export default FeedbackPage
