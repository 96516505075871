import React from 'react'
import { useMutation } from '@apollo/client'
import {
  CREATE_ASSIGNMENT,
  CREATE_ATTACHMENT,
  generateAssignmentUsers,
  generateNewAssignmentGroups,
  generateStudentSubmissions,
  generateGroupSubmissions,
  DELETE_ATTACHMENT,
  generateInsertAssignmentUsers,
  UPDATE_ASSIGNMENT,
  ARCHIVE_EXAM,
} from '../../client/queries'
import { useAlamContext } from '../../client'
import { useErrorToast, useSuccessToast } from '../../utils'
import { AssignmentForm, AssignmentInput } from '../Forms'
import {
  Assignment,
  AssignmentGroup,
  AssignmentUser,
  Attachment,
  Exam,
} from '../../types'
import { useMediaQuery } from '@chakra-ui/react'
import { useClassWorkContext } from '../../pages/ClassWork/ClassWorkContext'

export type AssignmentInfoFormProps = {
  onClose: () => void
  type: 'create' | 'edit'
  assignment?: Assignment
  onAssignmentInfoCreate: (params: {
    withLearningReview: boolean
    withAssignmentReview: boolean
  }) => void
  newAssignmentId: string
  assignmentReview?: Exam
  learningReview?: Exam
}

export const AssignmentInfoForm = ({
  onClose,
  type,
  assignment,
  onAssignmentInfoCreate,
  newAssignmentId,
  assignmentReview,
  learningReview,
}: AssignmentInfoFormProps): JSX.Element => {
  const {
    topics,
    classUsers,
    getClassTopics,
    groups,
    materialAssignmentRefetch,
  } = useClassWorkContext()
  const { user, classUser } = useAlamContext()

  const [createAssignment, { loading: assignmentIsSubmitting }] = useMutation(
    CREATE_ASSIGNMENT
  )
  const [createAttachment, { loading: attachmentIsSubmitting }] = useMutation(
    CREATE_ATTACHMENT
  )
  const [updateAssignment, { loading: assignmentIsUpdating }] = useMutation(
    UPDATE_ASSIGNMENT
  )
  const [deleteAttachment, { loading: attachmentIsDeleting }] = useMutation(
    DELETE_ATTACHMENT
  )
  const [archiveExam] = useMutation(ARCHIVE_EXAM)

  const handleSubmitAssignment = async (params: AssignmentInput) => {
    // Reassign array of empty string to empty array for proper processing
    if (params.assignmentUsers.length === 1 && params.assignmentUsers[0] === '') {
      params.assignmentUsers = []
    }
    if (assignment?.id) {
      await handleUpdateAssignment(params)
    } else {
      await handleCreateAssignment(params)
    }
  }

  // Create assignment
  const handleCreateAssignment = async ({
    name,
    description,
    schedule,
    dueDateStr: due_date,
    rubric,
    grading,
    topicId,
    isVisible: is_visible,
    addNewUsers: add_new_users,
    addNewGroups: add_new_groups,
    type,
    assignmentUsers,
    classId: class_id,
    files,
    assignmentGroups,
    withLearningReview,
    withAssignmentReview,
  }: AssignmentInput) => {
    console.log("handleCreateAssignment assignmentUsers", assignmentUsers)
    try {
      const assignmentId = newAssignmentId
      const topic_id = topicId ? topicId : null
      const assignment_users = generateAssignmentUsers(assignmentUsers)
      const assignment_groups = generateNewAssignmentGroups(
        assignmentGroups,
        assignmentId
      )
      const studentSubmissions = generateStudentSubmissions(
        assignmentUsers,
        assignmentId
      )
      const groupSubmissions = generateGroupSubmissions(
        assignmentGroups,
        assignmentId
      )

      await Promise.all([
        createAssignment({
          variables: {
            id: assignmentId,
            name,
            description,
            schedule,
            due_date,
            type,
            rubric,
            grading,
            topic_id,
            is_visible,
            assignment_users,
            user_id: user.id,
            class_id,
            submissions: [...studentSubmissions, ...groupSubmissions],
            assignment_groups,
            add_new_users,
            add_new_groups,
          },
        }),
        submitAssignmentAttachments(assignmentId, files),
      ])

      onAssignmentInfoCreate({ withAssignmentReview, withLearningReview })

      getClassTopics()
      materialAssignmentRefetch()
      useSuccessToast({ message: 'Assignment was successfully created' })
    } catch (error) {
      useErrorToast({ error })
      return error
    }
  }

  const submitAssignmentAttachments = async (
    assignmentId: string,
    files: File[]
  ) => {
    try {
      return Promise.all(
        files?.map((file) =>
          createAttachment({
            variables: {
              assignmentId,
              file,
            },
          })
        )
      )
    } catch (err) {
      useErrorToast({ message: 'Upload failed' })
      return err
    }
  }

  // Edit assignment
  const getAssignedUsersDiff = (
    currentAssigneedUsers: AssignmentUser[],
    newAssignedUsers: Array<string>
  ) => {
    const currentAssignees = currentAssigneedUsers.map(({ user_id }) => user_id)

    const removedUsers = currentAssignees.filter((id) => {
      return !newAssignedUsers.includes(id)
    })

    const newAssignees = newAssignedUsers.filter((id) => {
      return !currentAssignees.includes(id)
    })

    return { removedUsers, newAssignees }
  }

  const getAssignedGroupsDiff = (
    currentAssigneedGroups: AssignmentGroup[],
    newAssignedGroups: Array<string>
  ) => {
    const currentAssignees = currentAssigneedGroups.map(
      ({ group_id }) => group_id
    )

    const removedGroups = currentAssignees.filter((id) => {
      return !newAssignedGroups.includes(id)
    })

    const newGroups = newAssignedGroups.filter((id) => {
      return !currentAssignees.includes(id)
    })

    return { removedGroups, newGroups }
  }

  const handleUpdateAssignment = async ({
    name,
    description,
    schedule,
    dueDateStr: due_date,
    rubric,
    grading,
    topicId,
    isVisible: is_visible,
    addNewUsers: add_new_users,
    addNewGroups: add_new_groups,
    assignmentUsers,
    classId: class_id,
    files,
    removedFiles,
    assignmentGroups,
    withLearningReview,
    withAssignmentReview,
  }: AssignmentInput) => {
    try {
      if (!assignment) {
        return
      }
      const topic_id = topicId ? topicId : null
      // Get newly assigned and unassigned users
      const {
        removedUsers: removed_users,
        newAssignees,
      } = getAssignedUsersDiff(assignment?.assignmentUsers, assignmentUsers)
      // Get newly assigned and unassigned groups
      const {
        removedGroups: removed_groups,
        newGroups,
      } = getAssignedGroupsDiff(assignment?.assignmentGroups, assignmentGroups)

      const assignment_users = generateInsertAssignmentUsers(
        newAssignees,
        assignment.id
      )
      const assignment_groups = generateNewAssignmentGroups(
        newGroups,
        assignment.id
      )

      const studentSubmissions = generateStudentSubmissions(
        newAssignees,
        assignment.id
      )
      const groupSubmissions = generateGroupSubmissions(
        newGroups,
        assignment.id
      )

      await Promise.all([
        submitAssignmentAttachments(assignment?.id, files),
        handleDeleteAttachments(removedFiles),
        updateAssignment({
          variables: {
            id: assignment.id,
            changes: {
              name,
              description,
              schedule,
              due_date,
              rubric,
              grading,
              topic_id,
              is_visible,
              class_id,
              add_new_users,
              add_new_groups,
            },
            assignment_users,
            assignment_groups,
            user_submissions: studentSubmissions,
            group_submissions: groupSubmissions,
            removed_users,
            removed_groups,
          },
        }),
        handleUpdateAssignmentReview({ withAssignmentReview }),
        handleUpdateLearningReview({ withLearningReview }),
      ])

      getClassTopics()
      materialAssignmentRefetch()
      onAssignmentInfoCreate({ withAssignmentReview, withLearningReview })
      useSuccessToast({
        title: 'Assignment updated',
        message: 'Assignment was successfully updated',
      })
    } catch (error) {
      useErrorToast({ message: 'Failed to update assignment', error })
    }
  }

  const handleUpdateAssignmentReview = async ({
    withAssignmentReview,
  }: {
    withAssignmentReview: boolean
  }) => {
    try {
      if (assignmentReview && !withAssignmentReview) {
        return await archiveExam({
          variables: { exam_id: assignmentReview?.id },
        })
      }
    } catch (error) {
      useErrorToast({ error })
      return error
    }
  }

  const handleUpdateLearningReview = async ({
    withLearningReview,
  }: {
    withLearningReview: boolean
  }) => {
    try {
      if (learningReview && !withLearningReview) {
        return await archiveExam({
          variables: { exam_id: learningReview?.id },
        })
      }
    } catch (error) {
      useErrorToast({ error })
      return error
    }
  }

  const handleDeleteAttachments = (removedFiles: Attachment[]) => {
    try {
      return Promise.all(
        removedFiles?.map(({ id }) =>
          deleteAttachment({
            variables: { id },
          })
        )
      )
    } catch (err) {
      return err
    }
  }

  const [isLargerThanMobile] = useMediaQuery('(min-width: 544px)')

  const { submitText, submittingText, isSubmitting } =
    type === 'create'
      ? {
          submitText: 'Assign',
          submittingText: 'Assigning',
          isSubmitting: assignmentIsSubmitting || attachmentIsSubmitting,
        }
      : {
          submitText: 'Save',
          submittingText: 'Saving',
          isSubmitting:
            assignmentIsUpdating ||
            attachmentIsDeleting ||
            attachmentIsSubmitting,
        }

  return (
    <AssignmentForm
      submitText={submitText}
      submittingText={submittingText}
      isSubmitting={isSubmitting}
      onSubmit={handleSubmitAssignment}
      topicsOption={topics}
      students={classUsers}
      classId={classUser?.class?.id}
      groups={groups}
      assignment={assignment}
      isCreate={type === 'create'}
    />
  )
}

export default AssignmentInfoForm
