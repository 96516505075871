import { gql } from '@apollo/client'
import { userDisplayFields } from './index'

export const classUserChatFields = gql`
  ${userDisplayFields}
  fragment classUserChatFields on class_users {
    id
    role
    user {
      bio
      learningStyle: learning_style
      ...userDisplayFields
      group_users(
        where: {
          group: { class_id: { _eq: $class_id } }
          archived_at: { _is_null: true }
        }
        limit: 1
      ) {
        group {
          name
        }
      }

      user_online {
        id
      }

      messagesReceived(
        where: {
          class_id: { _eq: $class_id }
          sender_id: { _eq: $current_user_id }
        }
      ) {
        id
        text
        inserted_at
        sender {
          ...userDisplayFields
        }
      }

      messagesSent(
        where: {
          class_id: { _eq: $class_id }
          receiver_id: { _eq: $current_user_id }
        }
      ) {
        id
        text
        sender {
          ...userDisplayFields
        }
        inserted_at
      }

      unreadMessages: messagesSent_aggregate(
        where: {
          class_id: { _eq: $class_id }
          receiver_id: { _eq: $current_user_id }
          read_at: { _is_null: true }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`
