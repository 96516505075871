import React, { useState } from 'react'
import {
  Container,
  Box,
  Text,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  FormErrorMessage,
  FormErrorIcon,
  useMediaQuery,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useAlamContext } from '../../client'
import { useMutation } from '@apollo/client'
import { CHANGE_PASSWORD } from '../../client/queries'
import { useErrorToast, useSuccessToast } from '../../utils'
import { TopNavItemProps } from '../../components/TopNav'
import { MdRemoveRedEye } from 'react-icons/md'
import { IoMdEyeOff } from 'react-icons/io'

type PasswordPageProps = {
  topNavLeft: TopNavItemProps[]
  topNavCenter: TopNavItemProps[]
}

type PasswordFormInput = {
  oldPassword: string
  newPassword: string
}

const PasswordPage = ({
  topNavLeft,
  topNavCenter,
}: PasswordPageProps): JSX.Element => {
  const { user } = useAlamContext()
  const [showOldPassword, setShowOldPassword] = useState(false)
  const handleShowOldPassword = () => setShowOldPassword(!showOldPassword)

  const [showNewPassword, setShowNewPassword] = useState(false)
  const handleShowNewPassword = () => setShowNewPassword(!showNewPassword)

  const [mobileDevice] = useMediaQuery([`(max-width: 650px)`])

  const {
    register,
    handleSubmit,
    errors,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
  })

  const [changePassword, { loading: changePasswordIsSubmitting }] = useMutation(
    CHANGE_PASSWORD
  )

  const onSubmit = ({ oldPassword, newPassword }: PasswordFormInput) => {
    changePassword({
      variables: {
        old_password: oldPassword,
        new_password: newPassword,
        user_id: user.id,
      },
    })
      .then(() => {
        useSuccessToast({ message: 'Password successfully changed' })
      })
      .catch(() => {
        useErrorToast({})
      })
  }

  return (
    <Container
      maxW={{ base: '100%', md: '456px', lg: '500px', xl: '668px' }}
      py={12}
      pt={{ base: 4, md: 8, xl: 14 }}
      px='0px'
      h='auto'
    >
      <Box
        w='100%'
        layerStyle={mobileDevice ? '' : 'card.board-updated-shadow'}
        boxShadow='rgb(0 0 0 / 10%) 0px 4px 6px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px'
        bg='white.1'
        px={mobileDevice ? 4 : 8}
        py={10}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex align='center' justify='space-between' mb={10}>
            <Text textStyle='h2' color='primary.1'>
              Password
            </Text>
            <Button
              variant='base.primary'
              textStyle='button'
              type='submit'
              disabled={!isValid}
              isLoading={changePasswordIsSubmitting}
            >
              Change Password
            </Button>
          </Flex>
          <Flex direction='column'>
            <FormControl mb={4} isInvalid={!!errors.oldPassword?.message}>
              <FormLabel fontWeight='600' letterSpacing='0.1rem'>
                Old Password
              </FormLabel>

              <InputGroup size='md'>
                <Input
                  name='oldPassword'
                  type={showOldPassword ? 'text' : 'password'}
                  ref={register()}
                  border='0.5px solid'
                  borderColor='grey.border'
                />
                <InputRightElement width='48px'>
                  <IconButton
                    icon={showOldPassword ? <IoMdEyeOff /> : <MdRemoveRedEye />}
                    aria-label='show-password'
                    bg='transparent'
                    color='primary.1'
                    _hover={{
                      bg: 'transparent',
                    }}
                    _active={{
                      bg: 'transparent',
                    }}
                    onClick={handleShowOldPassword}
                    fontSize='1.25rem'
                  />
                </InputRightElement>
              </InputGroup>
              {errors?.oldPassword && (
                <FormErrorMessage>
                  <FormErrorIcon />
                  {errors.oldPassword?.message}
                </FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={!!errors.newPassword?.message}>
              <FormLabel fontWeight='600' letterSpacing='0.1rem'>
                New Password
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  name='newPassword'
                  type={showNewPassword ? 'text' : 'password'}
                  ref={register({
                    required: 'New password is required',
                    pattern: {
                      value: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
                      message:
                        'Password must contain lowercase and uppercase characters, and a number',
                    },
                    minLength: {
                      value: 9,
                      message: 'Password should contain at least 9 characters',
                    },
                  })}
                  border='0.5px solid'
                  borderColor='grey.border'
                />
                <InputRightElement width='48px'>
                  <IconButton
                    icon={showNewPassword ? <IoMdEyeOff /> : <MdRemoveRedEye />}
                    aria-label='show-password'
                    bg='transparent'
                    color='primary.1'
                    _hover={{
                      bg: 'transparent',
                    }}
                    _active={{
                      bg: 'transparent',
                    }}
                    onClick={handleShowNewPassword}
                    fontSize='1.25rem'
                  />
                </InputRightElement>
              </InputGroup>
              {errors?.newPassword && (
                <FormErrorMessage mt='1rem' fontSize='14px' lineHeight='21px'>
                  <FormErrorIcon w='16px' h='16px' />
                  {errors.newPassword?.message}
                </FormErrorMessage>
              )}
            </FormControl>
          </Flex>
        </form>
      </Box>
    </Container>
  )
}

export default PasswordPage
