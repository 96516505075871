import { NotEnoughData } from './NotEnoughData'
import { QuestionGraphProps } from './QuestionCard'
import {
  Container,
  Box,
  Flex,
  VStack,
  StackDivider,
  Text,
} from '@chakra-ui/react'
import {
  ResponsiveContainer,
  BarChart,
  YAxis,
  XAxis,
  Bar,
  LabelList,
  Label,
  CartesianGrid,
  Tooltip,
} from 'recharts'
import {
  getChoices,
  constructChoiceStudentData,
  populateChoiceStudentData,
  ChoiceGraph,
  ChoiceStudentData,
  renderPercentageCustomLabel,
  transformCountToPercentage,
  PercentageXAxisTick,
  TruncatedChoicesTick,
  CheckboxTooltip,
} from '../../utils'
import textStyles from '../../theme/textStyles'

export const CheckboxGraph = ({
  question,
}: QuestionGraphProps): JSX.Element => {
  const initialGraph = getChoices(question)
  const studentData = constructChoiceStudentData(question)
  const populatedGraph = populateChoiceStudentData(initialGraph, studentData)

  const totalCount = studentData.length

  const finalGraph = transformCountToPercentage(populatedGraph, totalCount)

  return (
    <>
      {totalCount === 0 && <NotEnoughData />}
      {totalCount > 0 && (
        <Flex p='40px 32px' justify='center' align='center'>
          <ResponsiveContainer width={750} height={264}>
            <BarChart
              width={350}
              height={264}
              data={finalGraph}
              barCategoryGap='30%'
              barGap={8}
              layout='vertical'
              margin={{ left: 50, right: 25 }}
            >
              <CartesianGrid strokeDasharray='0' horizontal={false} />
              <YAxis
                dataKey='name'
                type='category'
                style={{
                  ...textStyles.body['2'],
                  fontWeight: 400,
                  fill: 'rgba(1, 0, 2, 0.56)',
                }}
                interval={0}
                tick={<TruncatedChoicesTick />}
                tickLine={false}
                minTickGap={10}
                padding={{ bottom: -2 }}
              />
              <XAxis
                type='number'
                axisLine={false}
                tickLine={false}
                style={{
                  ...textStyles.body['2'],
                  fontWeight: 400,
                  fontSize: '12px',
                }}
                tick={<PercentageXAxisTick />}
                ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                dataKey='count'
              ></XAxis>
              <Tooltip
                content={<CheckboxTooltip totalCount={totalCount} />}
                cursor={false}
              />
              <Bar
                dataKey='count'
                barSize={40}
                fill='#CE1032'
                isAnimationActive={false}
              >
                <LabelList content={renderPercentageCustomLabel} />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Flex>
      )}
    </>
  )
}
