import React from 'react'
import { Flex, SimpleGrid } from '@chakra-ui/react'
import { Attachment } from '../../types'
import { FileItem } from './index'
import { useFilename } from '../../utils'

export type FileListProps = {
  newFiles?: File[]
  existingFiles: Attachment[]
  removeNewFile?: (index: number) => void
  removeExisitingFile?: (file: Attachment) => void
  isReadOnly?: boolean
  padding?: string | number
  removeBorder?: boolean
  iconProps?: Record<string, any>
}

export const FileList = ({
  newFiles = [],
  existingFiles,
  removeNewFile = () => {},
  removeExisitingFile = () => {},
  isReadOnly = false,
  padding = '24px',
  removeBorder,
  iconProps,
}: FileListProps): JSX.Element => {
  const handleFileClick = (url: string) => {
    window.open(url, '_blank')
  }

  return (
    <Flex w='100%' p={padding}>
      <SimpleGrid
        columns={{ base: 1, sm: 2 }}
        spacingX='24px'
        spacingY='16px'
        w='100%'
      >
        {existingFiles.map((attachment) => {
          const { id, file, url } = attachment
          const { filename, extension } = useFilename(file)

          return (
            <React.Fragment key={id}>
              <FileItem
                filename={filename}
                extension={extension}
                removeFile={() => removeExisitingFile(attachment)}
                isReadOnly={isReadOnly}
                handleFileClick={handleFileClick}
                url={url}
                removeBorder={removeBorder}
                iconProps={iconProps}
              />
            </React.Fragment>
          )
        })}

        {newFiles.map(({ name, lastModified }, index) => {
          const { filename, extension } = useFilename(name)

          return (
            <React.Fragment key={`${name}-${lastModified}`}>
              <FileItem
                filename={filename}
                extension={extension}
                removeFile={() => removeNewFile(index)}
                isReadOnly={isReadOnly}
                removeBorder={removeBorder}
                iconProps={iconProps}
              />
            </React.Fragment>
          )
        })}
      </SimpleGrid>
    </Flex>
  )
}

export default FileList
