import React from 'react'
import AccountSettingsPage from './AccountSettingsPage'

export interface AccountSettingsProps {
  fromHome?: boolean
}

const AccountSettings = ({
  fromHome = false,
}: AccountSettingsProps): JSX.Element => {
  return <AccountSettingsPage fromHome={fromHome} />
}

export default AccountSettings
