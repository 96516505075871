import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  FormErrorIcon,
  HStack,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { validateClassCode } from '../../utils'

export type JoinClassProps = {
  isOpen: boolean
  onClose: () => void
  onSubmit: (data: JoinClassInput) => void
  existingClassCodes: string[]
}

export type JoinClassInput = {
  inviteCode: string
}

export const JoinClass = ({
  isOpen,
  onClose,
  onSubmit,
  existingClassCodes,
}: JoinClassProps): JSX.Element => {
  const { register, handleSubmit, errors, watch } = useForm<JoinClassInput>({
    mode: 'onChange',
  })
  const inviteCode = watch('inviteCode')

  const handleClassCodeValidation = (inviteCode: string) => {
    return validateClassCode(inviteCode, existingClassCodes)
  }

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size='md'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Join Class</ModalHeader>

          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
              <FormControl isInvalid={!!errors.inviteCode?.message}>
                <FormLabel
                  variant='required'
                  fontWeight='600'
                  letterSpacing='0.1rem'
                >
                  Invite code
                </FormLabel>
                <Input
                  name='inviteCode'
                  ref={register({
                    required: 'Invite code is a required field',
                    validate: {
                      handleClassCodeValidation,
                    },
                  })}
                  border='.5px solid'
                  borderColor='grey.border'
                />
                {errors.inviteCode && (
                  <FormErrorMessage>
                    <FormErrorIcon />
                    {errors.inviteCode?.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </ModalBody>

            <ModalFooter padding='1.5rem'>
              <HStack spacing={4}>
                <Button
                  textStyle='button'
                  onClick={onClose}
                  variant='base.white'
                >
                  Cancel
                </Button>
                <Button
                  type='submit'
                  variant='base.primary'
                  textStyle='button'
                  disabled={!inviteCode}
                >
                  Join
                </Button>
              </HStack>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
}

export default JoinClass
