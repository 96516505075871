import React, { useState, useEffect, useMemo } from 'react'
import {
  Container,
  Box,
  Text,
  Flex,
  Icon,
  IconButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  FormErrorIcon,
  useMediaQuery,
  Textarea,
  Switch,
  Link,
  useDisclosure,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useAlamContext } from '../../client'
import { useMutation } from '@apollo/client'
import { EDIT_PROFILE_INFO } from '../../client/queries'
import { AlamAvatar } from '../../components/Avatar'
import { FaTrash } from 'react-icons/fa'
import { TopNavItemProps } from '../../components/TopNav'
import { VarkModal } from '../../components/Modal'
import {
  useErrorToast,
  useSuccessToast,
  getPreferenceIcon,
  displayPreference,
} from '../../utils'
import { useDropzone } from 'react-dropzone'
import { Create } from '../../components/Icons'

type ProfileInfoPageProps = {
  topNavLeft: TopNavItemProps[]
  topNavCenter: TopNavItemProps[]
}

type ProfileInfoFormInput = {
  firstName: string
  lastName: string
  bio: string
}

const ProfileInfoPage = ({
  topNavLeft,
  topNavCenter,
}: ProfileInfoPageProps): JSX.Element => {
  const { user, setUser } = useAlamContext()
  const [mobileDevice] = useMediaQuery([`(max-width: 650px)`])
  const [showIconDeletePhoto, setShowIconDeletePhoto] = useState(false)
  const [imgSrc, setImgSrc] = useState(user.avatar)
  const [photo, setPhoto] = useState<File | null>(null)
  const [isPreferencePublic, setIsPreferencePublic] = useState<boolean>(
    user.is_preference_public || false
  )
  const [deletePhoto, setDeletePhoto] = useState(false)

  const { getInputProps, getRootProps } = useDropzone({
    noDragEventsBubbling: true,
    onDrop: (files) => setPhoto(files[0]),
    maxFiles: 1,
    accept: 'image/*',
  })

  const {
    isOpen: varkModalIsOpen,
    onOpen: varkModalOnOpen,
    onClose: varkModalOnClose,
  } = useDisclosure()

  useEffect(() => {
    if (photo) {
      setImgSrc(URL.createObjectURL(photo))
      setDeletePhoto(false)
    }
  }, [photo])

  useEffect(() => {
    if (imgSrc) {
      setShowIconDeletePhoto(true)
    } else {
      setShowIconDeletePhoto(false)
    }
  }, [imgSrc])

  const handleDeleteOnClick = () => {
    if (photo) {
      setPhoto(null)
      setImgSrc(user.avatar)
    } else {
      setImgSrc('')
      setDeletePhoto(true)
    }
  }

  const {
    register,
    handleSubmit,
    errors,
    watch,
    formState: { isValid },
  } = useForm<ProfileInfoFormInput>({
    mode: 'onChange',
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      bio: user.bio || '',
    },
  })

  const [editProfileInfo, { loading: editProfileIsSubmitting }] = useMutation(
    EDIT_PROFILE_INFO
  )

  const onSubmit = (data: ProfileInfoFormInput) => {
    editProfileInfo({
      variables: {
        first_name: data.firstName,
        last_name: data.lastName,
        bio: data.bio,
        photo: photo,
        user_id: user.id,
        delete_photo: deletePhoto,
        is_preference_public: isPreferencePublic,
      },
    })
      .then(({ data: { editProfileInfo: user } }) => {
        localStorage.setItem('user', JSON.stringify(user))
        setUser(user)
        useSuccessToast({ message: 'Profile successfully edited' })
        setPhoto(null)
        setDeletePhoto(false)
      })
      .catch(() => {
        useErrorToast({})
      })
  }

  const formValues = watch()

  const hasChanged = useMemo((): boolean => {
    const result = Object.keys(formValues).some((key) => {
      const formProp = formValues[key as keyof ProfileInfoFormInput]
      const userProp = user[key as keyof ProfileInfoFormInput]
      if (!formProp && !userProp) return false
      return formProp !== userProp
    })
    return (
      result ||
      !!photo ||
      deletePhoto ||
      user.is_preference_public !== isPreferencePublic
    )
  }, [user, formValues])

  const isFormValid = useMemo((): boolean => {
    return isValid && hasChanged
  }, [isValid, hasChanged])

  return (
    <>
      <VarkModal
        isOpen={varkModalIsOpen}
        onOpen={varkModalOnOpen}
        onClose={varkModalOnClose}
      />
      <Container
        maxW={{ base: '100%', md: '456px', lg: '500px', xl: '668px' }}
        py={12}
        pt={{ base: 4, md: 8, xl: 14 }}
        px='0px'
        h='auto'
      >
        <Box
          w='100%'
          layerStyle={mobileDevice ? '' : 'card.board'}
          boxShadow='rgb(0 0 0 / 10%) 0px 4px 6px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px'
          bg='white.1'
          px={mobileDevice ? 4 : 8}
          py={10}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex align='center' justify='space-between' mb={8}>
              <Text textStyle='h2' color='primary.1'>
                Profile Info
              </Text>
              <Button
                variant='base.primary'
                textStyle='button'
                type='submit'
                disabled={!isFormValid}
                isLoading={editProfileIsSubmitting}
              >
                Save
              </Button>
            </Flex>
            <Flex
              direction='column'
              align='center'
              justify='center'
              width='100%'
              mb={7}
            >
              <Box position='relative'>
                <AlamAvatar size='2xl' mb={5} src={imgSrc} />
                {showIconDeletePhoto && (
                  <IconButton
                    icon={<FaTrash />}
                    aria-label='delete-photo'
                    bg='white.1'
                    boxShadow='0px 4px 8px rgba(23, 5, 61, 0.12)'
                    borderRadius='50%'
                    position='absolute'
                    bottom='16px'
                    right='8px'
                    onClick={handleDeleteOnClick}
                  ></IconButton>
                )}
              </Box>
              <input {...getInputProps()} />
              <Link
                {...getRootProps({
                  textStyle: 'body.semibold',
                  color: 'primary.1',
                })}
                textStyle='body.semibold'
                lineHeight='24px'
                fontSize='16px'
                _hover={{
                  color: 'hover.2',
                }}
              >
                {user.avatar ? 'Change Profile Photo' : 'Upload Profile Photo'}
              </Link>
            </Flex>
            <Flex direction='column'>
              <FormControl mb={4} isInvalid={!!errors.firstName?.message}>
                <FormLabel letterSpacing='0.1rem' fontWeight='600'>
                  First Name
                </FormLabel>
                <Input
                  name='firstName'
                  ref={register({
                    required: 'First name is required',
                  })}
                  border='0.5px solid'
                  borderColor='grey.border'
                />
                {errors?.firstName && (
                  <FormErrorMessage>
                    <FormErrorIcon />
                    {errors.firstName?.message}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl mb={4} isInvalid={!!errors.lastName?.message}>
                <FormLabel letterSpacing='0.1rem' fontWeight='600'>
                  Last Name
                </FormLabel>
                <Input
                  name='lastName'
                  ref={register({
                    required: 'Last name is required',
                  })}
                  border='0.5px solid'
                  borderColor='grey.border'
                />
                {errors?.lastName && (
                  <FormErrorMessage>
                    <FormErrorIcon />
                    {errors.lastName?.message}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={!!errors.bio?.message}>
                <FormLabel letterSpacing='0.1rem' fontWeight='600'>
                  Bio
                </FormLabel>
                <Textarea
                  resize='vertical'
                  textStyle='body.1'
                  maxHeight='200px'
                  h='112px'
                  name='bio'
                  ref={register({
                    required: false,
                    maxLength: {
                      value: 255,
                      message: 'Bio exceeded 255 maximum characters',
                    },
                  })}
                  border='0.5px solid'
                  borderColor='grey.border'
                  fontSize='16px'
                  letterSpacing='0'
                  lineHeight='24px'
                  p='1rem'
                />
                {errors?.bio && (
                  <FormErrorMessage>
                    <FormErrorIcon />
                    {errors.bio?.message}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl mt='24px'>
                <Flex
                  direction='row'
                  justify='space-between'
                  alignItems='center'
                  alignContent='center'
                >
                  <FormLabel letterSpacing='0.1rem' fontWeight='600'>
                    Learning Preferences
                  </FormLabel>
                  <FormLabel
                    _hover={{
                      color: 'hover.2',
                    }}
                    onClick={varkModalOnOpen}
                    textTransform='uppercase'
                    display='flex'
                    flow='row nowrap'
                    alignItems='flex-start'
                    alignContent='flex-start'
                    justify='center'
                    textStyle='body.semibold'
                    letterSpacing='0.02rem'
                    fontSize='10px'
                    fontWeight='700'
                    lineHeight='16px'
                    color='primary.1'
                    cursor='pointer'
                    mr='0px'
                  >
                    <Flex
                      alignItems='center'
                      alignContent='center'
                      justify='center'
                      mr='10px'
                    >
                      <Create width='16' height='16' viewBox='8 8 24 24' />
                    </Flex>
                    Edit
                  </FormLabel>
                </Flex>
                <Flex
                  bg='grey.2'
                  p='8px 16px'
                  borderRadius='8px'
                  align='center'
                  color='primary.1'
                  minHeight='48px'
                  wrap='wrap'
                  justify={
                    user?.learningStyle?.length !== 4 &&
                    user?.learningStyle?.length !== 3
                      ? 'flex-start'
                      : 'space-between'
                  }
                >
                  {user?.learningStyle?.map((preference) => {
                    return (
                      <Flex
                        align='center'
                        alignContent='space-between'
                        minHeight='40px'
                        width='auto'
                        flex={
                          user?.learningStyle?.length !== 4 &&
                          user?.learningStyle?.length !== 3
                            ? 1
                            : 0
                        }
                      >
                        <Icon
                          as={getPreferenceIcon(preference)}
                          ml='8px'
                          mr='16px'
                          color='black.1'
                          w='1.2rem'
                          h='1.2rem'
                        />
                        <Text
                          textStyle='body.1'
                          textTransform='capitalize'
                          mr='16px'
                          color='black.1'
                          fontSize='16px'
                          lineHeight='24px'
                          flex='1'
                        >
                          {displayPreference(preference)}
                        </Text>
                      </Flex>
                    )
                  })}
                </Flex>
                <Flex
                  justify='space-between'
                  h='48px'
                  align='center'
                  pt='16px'
                  mt='8px'
                >
                  <Text
                    textStyle='body.1'
                    align='center'
                    color='black.1'
                    fontSize='16px'
                    lineHeight='24px'
                    letterSpacing='0'
                  >
                    Users can view your bio and learning style
                  </Text>
                  <Switch
                    isChecked={isPreferencePublic}
                    onChange={() => {
                      setIsPreferencePublic(!isPreferencePublic)
                    }}
                  />
                </Flex>
              </FormControl>
            </Flex>
          </form>
        </Box>
      </Container>
    </>
  )
}

export default ProfileInfoPage
