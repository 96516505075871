import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormErrorMessage,
  FormErrorIcon,
  Button,
  HStack,
  VStack,
} from '@chakra-ui/react'
import { useForm, FormProvider } from 'react-hook-form'
import { FormInput, FormButton } from '../Input'
import { uniqueClassNameValidation, validateSpaceOnly } from '../../utils'

export interface CreateModalProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (data: CreateClassInput) => void
  isSubmitting: boolean
  currentClassNames: string[]
}

export type CreateClassInput = {
  className: string
  subject?: string
}

export const CreateClass = ({
  isOpen,
  onClose,
  onSubmit,
  isSubmitting,
  currentClassNames,
}: CreateModalProps): JSX.Element => {
  const methods = useForm<CreateClassInput>({
    mode: 'onChange',
    defaultValues: { className: '', subject: '' },
  })

  const {
    errors,
    formState: { isValid },
  } = methods

  const validateClassName = (className: string) => {
    return uniqueClassNameValidation(className, currentClassNames)
  }

  return (
    <FormProvider {...methods}>
      <Modal isCentered isOpen={isOpen} onClose={onClose} variant='createClass'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Class</ModalHeader>

          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalBody>
              <VStack spacing={4}>
                <FormInput
                  name='className'
                  label='Class Name'
                  formLabelProps={{
                    variant: 'required',
                    fontWeight: '600',
                    letterSpacing: '0.1rem',
                  }}
                  inputProps={{ fontSize: '16px' }}
                  validationProps={{
                    required: 'Class name is a required field',
                    maxLength: {
                      value: 255,
                      message: 'Name exceeded 255 maximum characters',
                    },
                    validate: {
                      validateClassName,
                      validateSpaceOnly,
                    },
                  }}
                />
                {errors.className && (
                  <FormErrorMessage>
                    <FormErrorIcon w='14px' h='14px' />
                    {errors.className?.message}
                  </FormErrorMessage>
                )}
                <FormInput
                  name='subject'
                  label='Subject'
                  formLabelProps={{
                    variant: 'optional',
                    fontWeight: '600',
                    letterSpacing: '0.1rem',
                  }}
                  inputProps={{ fontSize: '16px' }}
                />
              </VStack>
            </ModalBody>

            <ModalFooter padding='1.5rem'>
              <HStack spacing={4}>
                <Button
                  variant='base.white'
                  onClick={onClose}
                  textStyle='button'
                >
                  Cancel
                </Button>
                <FormButton
                  text='Create'
                  isSubmitting={isSubmitting}
                  buttonProps={{
                    variant: 'base.primary',
                    textStyle: 'button',
                    disabled: !isValid,
                  }}
                  submittingText='Creating'
                />
              </HStack>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </FormProvider>
  )
}

export default CreateClass
