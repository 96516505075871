import React from 'react'
import { SessionUser, ClassUser } from '../../types'

export type drivePermission = {
  type: string
  role: string
  emailAddress: string
}

export interface createPermissionsProps {
  fileId: string
  permissions: drivePermission[]
}

export const createPermissions = async ({
  fileId,
  permissions,
}: createPermissionsProps) => {
  return Promise.all(
    permissions.map(async (permission: drivePermission) => {
      try {
        // @ts-ignore
        const response = await window.gapi.client.drive.permissions.create({
          resource: permission,
          fileId,
          fields: 'emailAddress,role,id,type,displayName,deleted',
          supportsAllDrives: true,
        })
        return response
      } catch (error) {
        return error
      }
    })
  )
}

export const buildSessionMemberPermissions = (
  sessionMembers: (SessionUser | ClassUser)[]
): drivePermission[] =>
  sessionMembers
    .filter(({ user }) => user?.provider === 'google')
    .map(({ user }) => ({
      type: 'user',
      role: 'writer',
      emailAddress: user?.email || '',
    }))

export interface permissionType {
  id: string
  emailAddress: string
  role: string
  type: string
  deleted: boolean
  displayName: string
}

export const getPermissions = async ({ fileId }: { fileId: string }) => {
  try {
    // @ts-ignore
    const response = await window.gapi.client.drive.permissions.list({
      fileId,
      fields: 'permissions(emailAddress,role,id,type,displayName,deleted)',
      supportsAllDrives: true,
    })
    return response
  } catch (error) {
    return error
  }
}

export const deletePermission = async ({
  fileId,
  permissionId,
}: {
  fileId: string
  permissionId: string
}) => {
  try {
    // @ts-ignore
    const response = await window.gapi.client.drive.permissions.delete({
      fileId,
      permissionId,
      supportsAllDrives: true,
    })
    return response
  } catch (error) {
    return error
  }
}
