import React from 'react'
import { Flex } from '@chakra-ui/react'
import Logo from '../Logo/Logo'
import { useHistory } from 'react-router'

export interface SideNavProps {
  children?: React.ReactNode
  isMobile: boolean
}

export const SideNav = ({ children, isMobile }: SideNavProps): JSX.Element => {
  const history = useHistory()
  const redirectToHome = () => {
    history.push('/')
  }

  return isMobile ? (
    <Flex
      direction='column'
      bg='primary.1'
      py='5vh'
      minW='240px'
      align='start'
      display='flex'
      h='100vh'
      top='0'
    >
      <Flex
        justify='start'
        pb={8}
        pl={5}
        cursor='pointer'
        onClick={redirectToHome}
      >
        <Logo htmlWidth='130.67px' htmlHeight='36px' />
      </Flex>
      {children}
    </Flex>
  ) : (
    <Flex
      direction='column'
      bg='primary.1'
      py='5vh'
      h='100vh'
      minW='240px'
      align='start'
      top='0'
      position='sticky'
      overflow='hidden'
      display={{ base: 'none', md: 'flex' }}
    >
      <Flex
        justify='start'
        pb={8}
        pl={5}
        cursor='pointer'
        onClick={redirectToHome}
      >
        <Logo htmlWidth='130.67px' htmlHeight='36px' />
      </Flex>
      {children}
    </Flex>
  )
}

export default SideNav
