import React from 'react'
import { Icon } from '@chakra-ui/react'

export const Class = (props: Record<string, any>): JSX.Element => {
  return (
    <Icon
      width='20'
      height='20'
      viewBox='8 8 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M29 12.5C27.89 12.15 26.67 12 25.5 12C23.55 12 21.45 12.4 20 13.5C18.55 12.4 16.45 12 14.5 12C12.55 12 10.45 12.4 9 13.5V28.15C9 28.4 9.25 28.65 9.5 28.65C9.6 28.65 9.65 28.6 9.75 28.6C11.1 27.95 13.05 27.5 14.5 27.5C16.45 27.5 18.55 27.9 20 29C21.35 28.15 23.8 27.5 25.5 27.5C27.15 27.5 28.85 27.8 30.25 28.55C30.35 28.6 30.4 28.6 30.5 28.6C30.75 28.6 31 28.35 31 28.1V13.5C30.4 13.05 29.75 12.75 29 12.5ZM29 26C27.9 25.65 26.7 25.5 25.5 25.5C23.8 25.5 21.35 26.15 20 27V15.5C21.35 14.65 23.8 14 25.5 14C26.7 14 27.9 14.15 29 14.5V26Z'
        fill='currentColor'
      />
    </Icon>
  )
}

export default Class
