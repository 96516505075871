import { useRouteMatch } from 'react-router'
import { Flex } from '@chakra-ui/react'
import { useAlamContext } from '../../client'

export type ChatSideBarProps = {
  children?: any
  isChatroom?: boolean
}

export const ChatSideBar = ({
  children,
  isChatroom = false,
}: ChatSideBarProps): JSX.Element => {
  const { chatIsOpen } = useAlamContext()

  const match = useRouteMatch('/classes/:classId/pretopic/:page')

  return (
    <Flex
      direction='column'
      maxH={isChatroom ? 'chatroomHeight' : 'sideNavHeight'}
      maxW={{ base: '100%', sm: '248px' }}
      minW={{ base: '100%', sm: '248px' }}
      pos='fixed'
      right='0'
      top={match ? '128px' : '64px'}
      bg='white.1'
      overflowY='auto'
      display={chatIsOpen ? 'block' : 'none'}
    >
      <Flex h='100%' w='100%' top='0' direction='column'>
        {children}
      </Flex>
    </Flex>
  )
}

export default ChatSideBar
