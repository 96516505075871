import { Flex, Text, Tooltip } from '@chakra-ui/react'
import { FileIcon } from './index'
import { HoverClose, parentHoverCss } from '../Button'
import React from 'react'

export type FileItemProps = {
  filename: string
  extension: string
  removeFile: () => void
  isReadOnly?: boolean
  handleFileClick?: (url: string) => void
  url?: string
  removeBorder?: boolean
  iconProps?: Record<string, any>
}

export const FileItem = ({
  filename,
  extension,
  removeFile,
  isReadOnly = false,
  url = '',
  handleFileClick = () => {},
  removeBorder,
  iconProps,
}: FileItemProps): JSX.Element => {
  
  console.log(`FRONTEND URL IN FILEITEM FOR FILENAME ${filename} = ${url}`)

  return (
    <Flex
      borderWidth='.5px'
      borderStyle='solid'
      borderColor='grey.border'
      borderRadius='8px'
      css={parentHoverCss}
      cursor='pointer'
      overflow='hidden'
    >
      <Flex
        onClick={() => handleFileClick(url)}
        maxWidth={`calc(100% - ${isReadOnly ? '0px' : '50px'})`}
        width={`calc(100% - ${isReadOnly ? '0px' : '50px'})`}
        py='12px'
        px='24px'
      >
        <FileIcon
          extension={extension}
          removeBorder={removeBorder}
          iconProps={iconProps}
        />

        <Flex direction='column' pl='8px' maxWidth='calc(100% - 50px)'>
          <Tooltip
            label={filename}
            aria-label={`attachment ${filename}`}
            minWidth='100%'
          >
            <Text
              textStyle='body.semibold'
              letterSpacing='-0.01rem'
              color='black.2'
              isTruncated
            >
              {filename}
            </Text>
          </Tooltip>
          <Tooltip
            label={getExtension(extension)}
            aria-label={`${getExtension(extension)} file type`}
          >
            <Text textStyle='caption' color='grey.1' isTruncated>
              {getExtension(extension)}
            </Text>
          </Tooltip>
        </Flex>
      </Flex>

      {!isReadOnly && (
        <Flex justify='flex-end' w='50px' pr='8px' py='12px' zIndex={1}>
          <HoverClose onClick={removeFile} />
        </Flex>
      )}
    </Flex>
  )
}
const getExtension = (extension: string) => {
  switch (extension) {
    case 'doc':
    case 'docx':
      return 'Word Doc'
    case 'xls':
    case 'xlsx':
      return 'Excel'
    default:
      return extension.toUpperCase()
  }
}

export default FileItem
