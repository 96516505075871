import { useContext, useMemo } from 'react'
import {
  Box,
  Icon,
  IconButton,
  HStack,
  VStack,
  StackDivider,
  Flex,
  Text,
  MenuItem,
  MenuDivider,
  Button,
  Spacer,
  useDisclosure,
} from '@chakra-ui/react'
import { FaClipboardList } from 'react-icons/fa'
import { Exam } from '../../types'
import { format, isAfter } from 'date-fns'
import { dateFormat } from '../../constants'
import { HoverMenu, parentHoverCss } from '../Menu'
import { RiPencilFill } from 'react-icons/ri'
import { FaTrash } from 'react-icons/fa'
import { useErrorToast, useSuccessToast } from '../../utils'
import { ConfirmationModal } from '../Modal'
import { useMutation } from '@apollo/client'
import { ARCHIVE_EXAM } from '../../client/queries'
import { AssignmentType } from '../../pages/Assignment/AssignmentPage'
import { ExamModal } from './'

export interface ExamWithTimestamp extends Exam {
  inserted_at?: string
  updated_at?: string
}

export interface TeacherExamIndicatorProps {
  exam: ExamWithTimestamp
  assignment: AssignmentType
}

export const TeacherExamIndicator = ({
  exam,
  assignment,
}: TeacherExamIndicatorProps): JSX.Element => {
  const {
    isOpen: deleteConfirmationIsOpen,
    onClose: deleteConfirmationOnClose,
    onOpen: deleteConfirmationOnOpen,
  } = useDisclosure()

  const {
    isOpen: examModalIsOpen,
    onClose: examModalOnClose,
    onOpen: examModalOnOpen,
  } = useDisclosure()

  const [archiveExam] = useMutation(ARCHIVE_EXAM, {
    variables: {
      exam_id: exam.id,
    },
  })

  const questionCount = exam.exam_arrangements.length

  const insertedAt = new Date(exam.inserted_at!)
  const updatedAt = new Date(exam.updated_at!)

  const dateDisplay = isAfter(updatedAt, insertedAt)
    ? `Edited ${format(updatedAt, dateFormat)}`
    : `Created ${format(insertedAt, dateFormat)}`

  const handleDeleteExam = () => {
    archiveExam()
      .then(() => {
        useSuccessToast({
          message: `${assignment.name} ${
            exam.type === 'assignment_review'
              ? 'assignment review'
              : 'learning review'
          } was successfully deleted`,
        })
        deleteConfirmationOnClose()
      })
      .catch((error) => {
        console.warn(error)
        useErrorToast({})
        deleteConfirmationOnClose()
      })
  }

  return (
    <>
      <ConfirmationModal
        isOpen={deleteConfirmationIsOpen}
        onClose={deleteConfirmationOnClose}
        title='Are you sure?'
        message={`All ${
          exam?.type === 'learning_review' ? 'learning' : 'assignment'
        } review data for ${
          assignment.name
        } such as questions, responses and analytics will be removed. `}
        onSubmit={handleDeleteExam}
        confirmButtonText='Remove'
      />
      <ExamModal
        isOpen={examModalIsOpen}
        onClose={examModalOnClose}
        assignment={assignment}
        exam={exam}
      />
      <Box
        layerStyle='card.module'
        borderRadius={{ base: '0', lg: '12px' }}
        borderColor='none'
        borderWidth='.5px'
        bg='white.1'
        w='100%'
        h='72px'
        p={4}
        css={parentHoverCss}
      >
        <Flex h='100%' w='100%'>
          <Flex direction='column' onClick={examModalOnOpen} cursor='pointer'>
            <Text textStyle='h5'>
              {exam.type === 'assignment_review'
                ? 'Assignment Review'
                : 'Learning Review'}
            </Text>
            <HStack spacing={3} divider={<Box layerStyle='bulletpoint' />}>
              <Text textStyle='body.1' color='grey.1'>
                {questionCount > 1
                  ? `${questionCount} questions`
                  : `${questionCount} question`}
              </Text>

              <Text textStyle='body.1' color='grey.1'>
                {dateDisplay}
              </Text>
            </HStack>
          </Flex>
          <Spacer />
          <Flex align='center'>
            <HStack spacing='8px'>
              <IconButton
                aria-label='Edit Question'
                icon={<Icon as={RiPencilFill} boxSize='18px' />}
                onClick={examModalOnOpen}
                variant='icon.button.question'
              />
              <IconButton
                aria-label='Delete Question'
                icon={<Icon as={FaTrash} boxSize='14.4px' />}
                onClick={deleteConfirmationOnOpen}
                variant='icon.button.question'
              />
            </HStack>
          </Flex>
        </Flex>
      </Box>
    </>
  )
}

export default TeacherExamIndicator
