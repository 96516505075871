import React, { useState, useMemo, useEffect } from 'react'
import {
  Container,
  Box,
  Text,
  Flex,
  VStack,
  StackDivider,
  FormControl,
  FormLabel,
  Switch,
  useMediaQuery,
} from '@chakra-ui/react'
import { TopNavItemProps } from '../../components/TopNav'
import {
  GET_CLASS_USER_SUBSCRIPTIONS,
  EDIT_CLASS_USER,
} from '../../client/queries'
import { useSubscription, useMutation } from '@apollo/client'
import { useAlamContext } from '../../client'
import { useSearchParams, useErrorToast, useSuccessToast } from '../../utils'

type NotificationsPageProps = {
  topNavLeft: TopNavItemProps[]
  topNavCenter: TopNavItemProps[]
}

interface SubscriptionData {
  is_subscribed_posts: boolean
  is_subscribed_announcements: boolean
  is_subscribed_comments: boolean
  is_subscribed_group_posts: boolean
  is_subscribed_group_announcements: boolean
  is_subscribed_group_comments: boolean
}

const NotificationsPage = ({
  topNavLeft,
  topNavCenter,
}: NotificationsPageProps): JSX.Element => {
  const { classUser } = useAlamContext()
  const [classSubscription, setClassSubscription] = useState<SubscriptionData>({
    is_subscribed_posts: false,
    is_subscribed_announcements: false,
    is_subscribed_comments: false,
    is_subscribed_group_posts: false,
    is_subscribed_group_announcements: false,
    is_subscribed_group_comments: false,
  })

  const [mobileDevice] = useMediaQuery([`(max-width: 650px)`])

  const query = useSearchParams()

  useSubscription(GET_CLASS_USER_SUBSCRIPTIONS, {
    variables: {
      class_user_id: classUser?.id,
    },
    onSubscriptionData: ({
      subscriptionData: {
        data: {
          class_users_by_pk: { notification_settings: classSubscriptionData },
        },
      },
    }) => {
      setClassSubscription(classSubscriptionData)
    },
  })

  const [editClassUser, { loading }] = useMutation(EDIT_CLASS_USER)

  const handleNotificationSwitch = (
    settingsData: Partial<SubscriptionData>
  ) => {
    setClassSubscription({ ...classSubscription, ...settingsData })
    editClassUser({
      variables: {
        id: classUser?.id,
        data: {
          notification_settings: { ...classSubscription, ...settingsData },
        },
      },
    })
  }

  const isSubscribedToAny = useMemo(() => {
    return Object.entries(classSubscription).some((entry) => {
      if (typeof entry[1] === 'boolean') {
        return entry[1]
      } else {
        return false
      }
    })
  }, [classSubscription])

  useEffect(() => {
    if (classUser?.id && query.get('unsubscribe') === 'true') {
      const unsubscribeData = {
        is_subscribed_posts: false,
        is_subscribed_announcements: false,
        is_subscribed_comments: false,
        is_subscribed_group_posts: false,
        is_subscribed_group_announcements: false,
        is_subscribed_group_comments: false,
      }

      setClassSubscription({ ...unsubscribeData })

      editClassUser({
        variables: {
          id: classUser?.id,
          data: {
            notification_settings: unsubscribeData,
          },
        },
      })
        .then(() => {
          useSuccessToast({
            title: 'Unsubscribed',
            message: 'You have been successfully unsubscribed',
          })
        })
        .catch((error) => {
          useErrorToast({})
        })
    }
  }, [classUser?.id])

  return (
    <Container
      maxW={{ base: '100%', md: '456px', lg: '500px', xl: '668px' }}
      py={12}
      pt={{ base: 4, md: 8, xl: 14 }}
      px='0px'
      h='auto'
    >
      <Box
        w='100%'
        layerStyle={mobileDevice ? '' : 'card.board-updated-shadow'}
        boxShadow='rgb(0 0 0 / 10%) 0px 4px 6px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px'
        bg='white.1'
        px={mobileDevice ? 4 : 8}
        py={10}
      >
        <VStack
          divider={<StackDivider layerStyle='grey.border' />}
          textAlign='center'
          display='flex'
          align='flex-start'
        >
          <Box>
            <Text textStyle='h2' color='primary.1'>
              Notifications
            </Text>
          </Box>
          <Flex direction='column' align='flex-start' w='100%' py={7}>
            <Text
              textStyle='h5'
              color='primary.1'
              mb={3}
              letterSpacing='0.02rem'
            >
              Email
            </Text>
            <FormControl
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              w='100%'
            >
              <FormLabel
                htmlFor='email-notifs'
                mb='0'
                textStyle='body.1'
                textTransform='capitalize'
                fontSize='16px'
                lineHeight='24px'
                letterSpacing='0'
              >
                Receive Email Notifications
              </FormLabel>
              <Switch
                isChecked={isSubscribedToAny}
                onChange={() => {
                  if (isSubscribedToAny) {
                    handleNotificationSwitch({
                      is_subscribed_posts: false,
                      is_subscribed_announcements: false,
                      is_subscribed_comments: false,
                      is_subscribed_group_posts: false,
                      is_subscribed_group_announcements: false,
                      is_subscribed_group_comments: false,
                    })
                  } else {
                    handleNotificationSwitch({
                      is_subscribed_posts: true,
                      is_subscribed_announcements: true,
                      is_subscribed_comments: true,
                      is_subscribed_group_posts: true,
                      is_subscribed_group_announcements: true,
                      is_subscribed_group_comments: true,
                    })
                  }
                }}
              />
            </FormControl>
          </Flex>
          <Flex direction='column' align='flex-start' w='100%' py={7}>
            <Text
              textStyle='h5'
              color='primary.1'
              mb={3}
              letterSpacing='0.02rem'
            >
              Class
            </Text>
            <FormControl
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              w='100%'
              mb={3}
            >
              <FormLabel
                htmlFor='email-notifs'
                mb='0'
                textStyle='body.1'
                textTransform='capitalize'
                fontSize='16px'
                lineHeight='24px'
                letterSpacing='0'
              >
                Announcements
              </FormLabel>
              <Switch
                isChecked={classSubscription.is_subscribed_announcements}
                onChange={() => {
                  handleNotificationSwitch({
                    is_subscribed_announcements: !classSubscription.is_subscribed_announcements,
                  })
                }}
              />
            </FormControl>
            <FormControl
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              w='100%'
              mb={3}
            >
              <FormLabel
                htmlFor='email-notifs'
                mb='0'
                textStyle='body.1'
                textTransform='capitalize'
                fontSize='16px'
                lineHeight='24px'
                letterSpacing='0'
              >
                New Posts
              </FormLabel>
              <Switch
                isChecked={classSubscription.is_subscribed_posts}
                onChange={() => {
                  handleNotificationSwitch({
                    is_subscribed_posts: !classSubscription.is_subscribed_posts,
                  })
                }}
              />
            </FormControl>
            <FormControl
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              w='100%'
              mb={3}
            >
              <FormLabel
                htmlFor='email-notifs'
                mb='0'
                textStyle='body.1'
                textTransform='capitalize'
                fontSize='16px'
                lineHeight='24px'
                letterSpacing='0'
              >
                New Comments
              </FormLabel>
              <Switch
                isChecked={classSubscription.is_subscribed_comments}
                onChange={() => {
                  handleNotificationSwitch({
                    is_subscribed_comments: !classSubscription.is_subscribed_comments,
                  })
                }}
              />
            </FormControl>
          </Flex>
          <Flex direction='column' align='flex-start' w='100%' py={7}>
            <Text
              textStyle='h5'
              color='primary.1'
              mb={3}
              letterSpacing='0.02rem'
            >
              Group
            </Text>
            <FormControl
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              w='100%'
              mb={3}
            >
              <FormLabel
                htmlFor='email-notifs'
                mb='0'
                textStyle='body.1'
                textTransform='capitalize'
                fontSize='16px'
                lineHeight='24px'
                letterSpacing='0'
              >
                New Posts
              </FormLabel>
              <Switch
                isChecked={classSubscription.is_subscribed_group_posts}
                onChange={() => {
                  handleNotificationSwitch({
                    is_subscribed_group_posts: !classSubscription.is_subscribed_group_posts,
                  })
                }}
              />
            </FormControl>
            <FormControl
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              w='100%'
              mb={3}
            >
              <FormLabel
                htmlFor='email-notifs'
                mb='0'
                textStyle='body.1'
                textTransform='capitalize'
                fontSize='16px'
                lineHeight='24px'
                letterSpacing='0'
              >
                New Comments
              </FormLabel>
              <Switch
                isChecked={classSubscription.is_subscribed_group_comments}
                onChange={() => {
                  handleNotificationSwitch({
                    is_subscribed_group_comments: !classSubscription.is_subscribed_group_comments,
                  })
                }}
              />
            </FormControl>
          </Flex>
        </VStack>
      </Box>
    </Container>
  )
}

export default NotificationsPage
