import { Box, Flex, Button } from '@chakra-ui/react'
import React from 'react'

export type CustomFooterProps = {
  isSubmitting?: boolean
  submitText: string
  handleSubmit: () => void
  showCancel?: boolean
  cancelText?: string
  handleCancel?: () => void
  submitDisabled?: boolean
}

export const CustomFooter = ({
  isSubmitting = false,
  submitText,
  handleSubmit,
  showCancel = false,
  cancelText = 'Cancel',
  handleCancel = () => {},
  submitDisabled = false,
}: CustomFooterProps): JSX.Element => {
  return (
    <Box
      p='16px 16px 16px 0'
      borderTop='.5px solid'
      borderTopColor='primary.2'
      w='100%'
    >
      <Flex justify='flex-end'>
        {showCancel && (
          <Button
            onClick={handleCancel}
            variant='base.white'
            textStyle='button'
            mr='10px'
          >
            {cancelText}
          </Button>
        )}

        <Button
          onClick={handleSubmit}
          variant='base.primary'
          textStyle='button'
          isLoading={isSubmitting}
          disabled={submitDisabled}
        >
          {submitText}
        </Button>
      </Flex>
    </Box>
  )
}

export default CustomFooter
