import { Flex, IconButton, Text, Tooltip } from '@chakra-ui/react'
import { BsArrowBarRight } from 'react-icons/bs'
import { useAlamContext } from '../../client'
import { ManagePeople } from '../Icons'
import { RenderForUser } from '../SharedComponents'

export type ChatHeadingProps = {
  title: string
}

export const ChatHeading = ({ title }: ChatHeadingProps): JSX.Element => {
  const {
    chatIsOpen,
    setChatIsOpen,
    managePeopleIsOpen,
    setManagePeopleIsOpen,
  } = useAlamContext()

  const handleCollapseChatSidebar = () => {
    return setChatIsOpen(!chatIsOpen)
  }

  const handleOpenManagePeople = () => {
    return setManagePeopleIsOpen(true)
  }

  const handleCloseManagePeople = () => {
    return setManagePeopleIsOpen(false)
  }

  return (
    <Flex h='56px' pl='16px' pr='24px' align='center' w='100%' bg='white.1'>
      <IconButton
        icon={<BsArrowBarRight />}
        aria-label='Toggle side chatbar/people management bar'
        variant='icon.button'
        color='primary.1'
        borderColor='primary.1'
        borderRadius='5px'
        border='.9px solid'
        maxW='28px'
        minW='28px'
        h='28px'
        onClick={handleCollapseChatSidebar}
      />

      <Tooltip label={title} aria-label={title}>
        <Text
          pl='8px'
          color='black.1'
          minW='150px'
          maxW='150px'
          isTruncated
          textStyle='h5'
        >
          {title}
        </Text>
      </Tooltip>

      <RenderForUser roles={['admin', 'teacher', 'creator']}>
        {managePeopleIsOpen ? (
          <Text
            cursor='pointer'
            onClick={handleCloseManagePeople}
            w='100%'
            textAlign='right'
            textStyle='overline'
          >
            Done
          </Text>
        ) : (
          <Tooltip label='Manage people in classroom' variant='iconToolTip'>
            <IconButton
              icon={<ManagePeople width='26' height='26' viewBox='0 4 24 24' />}
              aria-label='Toggle people management'
              variant='icon.button'
              color='primary.1'
              justifyContent='flex-end'
              w='100%'
              fontSize='1.2rem'
              onClick={handleOpenManagePeople}
            />
          </Tooltip>
        )}
      </RenderForUser>
    </Flex>
  )
}

export default ChatHeading
